<mat-slider
    #ngSlider
    showTickMarks
    class="mhe-a11y rdrx-slider"
    [min]="min"
    [max]="max$ | async"
    [step]="1"
>
    <input
        #ngSliderThumb="matSliderThumb"
        matSliderThumb
        [value]="val$ | async"
        [formControl]="scrubberControl"
        [mheTooltip]="activeTitle$ | async"
        (input)="onSliderChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
    />
</mat-slider>
