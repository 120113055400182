import { Injectable, NgZone, OnDestroy, Renderer2 } from '@angular/core';

import { AnchorType, Book, SpineItem } from '../models/epub';
import { TransformStore } from '@mhe/reader/state/transform';
import { anchorClick } from '../state/transform/transform.actions';
import { Next, Transformer } from './transformer';

@Injectable()
export class AnchorTransformer implements Transformer, OnDestroy {
  private unlisteners: Array<() => void> = [];

  constructor(
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly transformStore: TransformStore,
  ) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // TODO(styles): This should go to a css selector
    Array.from(
      content.querySelectorAll<HTMLAnchorElement>(
        `a[*|type="${AnchorType.GLOSSARY}"]`,
      ),
    ).forEach((a) => (a.style.textDecoration = 'underline'));

    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // do any cleanup from previous renders first
    this.resetUnlisteners();

    const anchorElements = content.querySelectorAll('a');
    anchorElements.forEach((anchor) =>
      this.unlisteners.push(
        this.renderer.listen(
          anchor,
          'click',
          // Because this event comes from the iframe, which is not in the Angular zone, any code triggered from here is
          //   run outside the zone. In this case it was causing interpolation and other Angular features not to work in
          //   the large image component
          (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            this.zone.run(() =>
              this.transformStore.dispatch(anchorClick({ anchor })),
            );
          },
        ),
      ),
    );

    return await next(content);
  }

  private resetUnlisteners(): void {
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unlisteners = [];
  }

  ngOnDestroy(): void {
    this.resetUnlisteners();
  }
}
