import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import * as glossaryQuery from '@mhe/reader/global-store/glossary/glossary.selectors';
import * as glossaryActions from '@mhe/reader/global-store/glossary/glossary.actions';
import * as footnoteQuery from '@mhe/reader/global-store/footnote/footnote.selectors';
import * as footnoteActions from '@mhe/reader/global-store/footnote/footnote.actions';
import { SimpleMessageModalComponent } from '@mhe/reader/components/modals/simple-message-modal';
import { GlobalWirisComponent } from '@mhe/reader/components/global-wiris';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { AnchorType, FootnoteItem, GlossaryItem } from '@mhe/reader/models';
import { getAnchorType } from '@mhe/reader/utils';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { GlossaryModalComponent } from '@mhe/reader/components/modals/glossary-modal';
import { ReferenceModalComponent } from '@mhe/reader/components/modals/reference-modal';
import { MediatorUtils } from './mediator-utils';
import { ReaderStore } from '@mhe/reader/components/reader/state';

export const replaceRelativeImgSrcToAbsolute = (text: string, urlObject: URL): string => {
  if (text.includes('<img')) {
    if (/src="(.*?)"/g.test(text) && text.includes('../')) {
      const [, srcImgContent] = /src="(.*?)"/g.exec(text) as RegExpExecArray;
      return text.replace(srcImgContent, new URL(srcImgContent, urlObject).href);
    }
  }
  return text;
};

@Injectable()
export class IframeActivityMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;

  constructor(
    private readonly transformStore: TransformStore,
    private readonly navigationStore: NavigationStore,
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly mediatorUtils: MediatorUtils,
    private readonly readerStore: ReaderStore,
  ) {
    super();
  }

  private readonly internalLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.INTERNAL_LINK;
      }),
      withLatestFrom(
        this.readerStore.navigationHistory$,
        this.navigationStore.index$,
        this.navigationStore.map$,
        this.readerStore.spine$,
      ),
      tap(([anchor, navigationHistory, index, indexMap, spines]) => {
        const { origin, pathname, hash } = new URL(anchor.getAttribute('data-href-url') as string);
        const url = `${origin}${pathname}`;

        // Add navigation action back to internal link in history if going to another page
        const spineIndex = this.mediatorUtils.getSpineIndexFromUrl(url, indexMap, spines);
        if (spineIndex !== index) {
          const currentHash = this.mediatorUtils.getParentIdFromAnchor(anchor);
          this.mediatorUtils.addHashToHistory(navigationHistory, currentHash, index);
        }

        this.navigationStore.dispatch(navigationActions.navigateBySpineUrl({ url, hash }));
      }),
      logCatchError('internalLinks$'),
    ),
  );

  private readonly referenceLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const isReferenceLink = getAnchorType(anchor) === AnchorType.REFERENCE_LINK;
        return isReferenceLink;
      }),
      exhaustMap((anchor) => {
        const href = anchor.getAttribute('data-href');
        const hrefUrl = anchor.getAttribute('data-href-url');
        const title = anchor.text;
        const dialog$ = this.dialog
          .open(ReferenceModalComponent, {
            data: {
              href,
              hrefUrl,
              title,
            },
            ariaLabelledBy: 'reference-modal-h2',
            height: '90%',
            width: '85%',
            minWidth: '350px',
            maxWidth: '97%',
          })
          .afterClosed()
          .pipe(
            // Grab the action from the modal and trigger here
            // The change comes after the actions trigger in modals stop work
            filter((action) => !!action),
            tap((action) => this.transformStore.dispatch(action)),
          );

        return dialog$.pipe(tap((_) => anchor.focus()));
      }),

      logCatchError('referenceLinks$'),
    ),
  );

  private readonly externalLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.EXTERNAL_LINK;
      }),
      tap((anchor) => window.open(anchor.href, '_blank')),
      logCatchError('externalLinks$'),
    ),
  );

  private readonly mailtoLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.MAILTO_LINK;
      }),
      tap((anchor) => window.open(anchor.href)),
      logCatchError('mailtoLinks$'),
    ),
  );

  private readonly telLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.TEL_LINK;
      }),
      tap((anchor) => window.open(anchor.href)),
      logCatchError('telLinks$'),
    ),
  );

  private readonly creditLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.CREDIT_LINK;
      }),
      exhaustMap((anchor) => {
        // get the target of the credit link
        const urlPart = anchor.getAttribute('data-href') as string;
        const id = urlPart.slice(1);

        // get the contents of the credit link
        const doc = anchor.ownerDocument;
        const creditLinkContents = doc.getElementById(id);

        if (!creditLinkContents) {
          return;
        }

        // open the dialog
        const dialog$ = this.dialog
          .open(SimpleMessageModalComponent, {
            data: {
              message$: of({ text: creditLinkContents.innerHTML }),
              title: this.translate.instant('dialog.title.credit'),
            },
            ariaLabelledBy: 'simple-message-modal-h2',
          })
          .afterClosed();

        // move focus to the close button of the dialog
        return dialog$.pipe(tap((_) => anchor.focus())) as any;
      }),
      logCatchError('creditLinks$'),
    ),
  );

  private readonly longDescriptionLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.LONG_DESCRIPTION;
      }),
      exhaustMap((anchor) => {
        const longDescriptionText = anchor.getAttribute('data-long-description');

        const dialog$ = this.dialog
          .open(SimpleMessageModalComponent, {
            data: {
              message$: of({ text: longDescriptionText }),
              title: this.translate.instant('dialog.title.long_description'),
            },
            ariaLabelledBy: 'simple-message-modal-h2',
          })
          .afterClosed();

        return dialog$.pipe(tap((_) => anchor.focus()));
      }),
      logCatchError('longDescriptionLinks$'),
    ),
  );

  private readonly glossaryLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.GLOSSARY;
      }),
      exhaustMap((anchor) => {
        const urlObject = new URL(anchor.getAttribute('data-href-url') as string);
        const { pathname, origin, hash } = urlObject;
        const id = hash.slice(1);
        const url = `${origin}${pathname}`;

        const glossaryItem$ = this.store.pipe(
          select(glossaryQuery.getGlossaryItemById, { url, id }),
          filter(Boolean),
          map((item: GlossaryItem) => ({
            ...item,
            definitionNodes: item.definitionNodes.map((itemNode) => {
              itemNode.innerHTML = replaceRelativeImgSrcToAbsolute(itemNode.innerHTML, urlObject);
              return itemNode;
            }),
          })),
        );
        const error$ = this.store.pipe(select(glossaryQuery.getGlossaryLoadingError, { url }));
        const isLoading$ = this.store.pipe(select(glossaryQuery.isGlossaryLoading, { url }));
        const dialog$ = this.dialog
          .open(GlossaryModalComponent, {
            data: {
              glossaryItem$,
              error$,
              isLoading$,
            },
            ariaLabelledBy: 'glossary-modal-h2',
          })
          .afterClosed();

        this.store.dispatch(glossaryActions.fetchGlossary({ url: urlObject }));
        return dialog$.pipe(tap((_) => anchor.focus()));
      }),
      logCatchError('glossaryLinks$'),
    ),
  );

  private readonly footnoteLinks$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map((action) => action.anchor),
      this.mediatorUtils.filterLinks,
      filter((anchor) => {
        const anchorType = getAnchorType(anchor);
        return anchorType === AnchorType.FOOTNOTE;
      }),
      exhaustMap((anchor) => {
        const urlObject = new URL(anchor.getAttribute('data-href-url') as string);
        const { pathname, origin, hash } = urlObject;
        const id = hash.slice(1);
        const url = `${origin}${pathname}`;

        const message$ = this.store.pipe(
          select(footnoteQuery.getFootnoteItemById, { url, id }),
          filter(Boolean),
          map((item: FootnoteItem) => ({
            ...item,
            text: replaceRelativeImgSrcToAbsolute(item.text, urlObject),
          })),
        );
        const error$ = this.store.pipe(select(footnoteQuery.getFootnoteLoadingError, { url }));
        const isLoading$ = this.store.pipe(select(footnoteQuery.isFootnoteLoading, { url }));
        const dialog$ = this.dialog
          .open(SimpleMessageModalComponent, {
            data: {
              message$,
              error$,
              isLoading$,
              title: this.translate.instant('dialog.title.footnote'),
            },
            ariaLabelledBy: 'simple-message-modal-h2',
          })
          .afterClosed();

        this.store.dispatch(footnoteActions.fetchFootnote({ url: urlObject }));
        return dialog$.pipe(tap((_) => anchor.focus()));
      }),
      logCatchError('footnoteLinks$'),
    ),
  );

  // Large image links are handled in large-image-navigator.mediator.ts

  private readonly globalWirisRequest$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.wirisRequest),
      tap((action) => {
        action.toolbar = action.toolbar.replace(
          /autoDigitSeparatorsAsMN/g,
          'autoDigitSeparatorsMode',
        );

        // open the global wiris component dialog
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const dialog$ = this.dialog.open(GlobalWirisComponent, {
          data: {
            title: this.translate.instant('dialog.title.wiris_editor'),
            mathMLContent: action.mathMLContent,
            requestId: action.requestId,
            toolbar: action.toolbar,
            callingWidgetManager: action.callingWidgetManager,
          },
          ariaLabelledBy: 'global-wiris-modal-h1',
        });
      }),
      logCatchError('globalWirisRequest$'),
    ),
  );
}
