/* eslint-disable max-len */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  AnchorType,
  ApiAnnotation,
  HighlightColorClass,
  HighlightShapeClass,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable, defer, from, merge, of } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pairwise,
  skip,
  skipUntil,
  switchMap,
  switchMapTo,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MediatorUtils } from './mediator-utils';
import {
  GoogleAnalyticsEvent,
  GoogleAnalyticsPageview,
  GoogleAnalyticsService,
  GoogleAnalyticsTimingEvent,
} from '@mhe/reader/features/analytics';
import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import * as readerActions from '@mhe/reader/components/reader/state/reader.actions';
import * as toolbarActions from '@mhe/reader/components/reader/state/toolbar.actions';
import * as assignmentActions from '@mhe/reader/components/reader/state/assignment.actions';
import * as analyticsActions from '@mhe/reader/components/reader/state/analytics.actions';
import {
  HighlightListStore,
  NoteListStore,
  PlacemarkListStore,
} from '@mhe/reader/components/annotation-lists';
import * as highlightListActions from '@mhe/reader/components/annotation-lists/highlight-list/state/highlight-list.actions';
import * as noteListActions from '@mhe/reader/components/annotation-lists/note-list/state/note-list.actions';
import * as placemarkListActions from '@mhe/reader/components/annotation-lists/placemark-list/state/placemark-list.actions';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { TTSStore } from '@mhe/reader/components/text-to-speech';
import * as ttsActions from '@mhe/reader/components/text-to-speech/state/tts.actions';
import { AnnotationsContextMenuStore } from '@mhe/reader/components/annotations-context-menu';
import * as annotationContextMenuActions from '@mhe/reader/components/annotations-context-menu/state/annotations-context-menu.actions';
import { SearchStore } from '@mhe/reader/components/search';
import * as searchActions from '@mhe/reader/components/search/state/search.actions';
import { getAnchorType } from '@mhe/reader/utils';

@Injectable()
export class GoogleAnalyticsMediator extends ComponentEffects {
  private readonly annotationContextActions$ =
    this.annotationContextStore.actions$;

  private readonly highlightListActions$ = this.highlightListStore.actions$;
  private readonly noteListActions$ = this.noteListStore.actions$;
  private readonly placemarkListActions$ = this.placemarkListStore.actions$;
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly searchActions$ = this.searchStore.actions$;
  private readonly ttsActions$ = this.ttsStore.actions$;
  private readonly transformActions$ = this.transformStore.actions$;

  private readonly anchorClick$ = this.transformActions$.pipe(
    ofType(transformActions.anchorClick),
  );

  private readonly epubTitle$ = this.readerStore.title$;

  constructor(
    protected readonly window: Window,
    private readonly annotationContextStore: AnnotationsContextMenuStore,
    private readonly configStore: ReaderConfigStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly highlightListStore: HighlightListStore,
    private readonly location: Location,
    private readonly noteListStore: NoteListStore,
    private readonly placemarkListStore: PlacemarkListStore,
    private readonly readerStore: ReaderStore,
    private readonly searchStore: SearchStore,
    private readonly transformStore: TransformStore,
    private readonly ttsStore: TTSStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /** init / loading */
  private readonly _gaEpubInit$ = this.effect(() => {
    const init$ = this.readerActions$.pipe(
      ofType(readerActions.initComplete),
      take(1),
    );
    return init$.pipe(
      this.util.tapGaTimingWithTitle({
        timingCategory: 'ePub',
        timingVar: 'ePub Loaded',
      }),
      logCatchError('_gaEpubInit$'),
    );
  });

  // subsequent inits from leveld content selection
  private readonly _gaEpubSecondaryInit$ = this.effect(() => {
    const { performance } = this.window;
    if (!performance) return EMPTY;

    const { init, initComplete } = readerActions;
    const { perf, perfStart, perfEnd } = this.createPerformanceMarks(
      'placeholder_dispaly',
    );

    const init$ = this.readerActions$.pipe(ofType(init), skip(1));
    const initComplete$ = defer(() =>
      this.readerActions$.pipe(ofType(initComplete)),
    );

    const event: Omit<GoogleAnalyticsTimingEvent, 'timingValue'> = {
      timingCategory: 'ePub',
      timingVar: 'Leveled Content Loaded',
    };

    return init$.pipe(
      tap(() => performance.mark(perfStart)),
      switchMap(() => initComplete$.pipe(tap(() => performance.mark(perfEnd)))),
      this.mapToPerformanceTime(perf, perfStart, perfEnd),
      map(
        (timingValue): GoogleAnalyticsTimingEvent => ({ ...event, timingValue }),
      ),
      this.util.tapGaTimingWithTitle(),
    );
  });

  private readonly _gaEpubPageview$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(readerActions.initComplete),
      mapTo(this.location.path()),
      withLatestFrom(this.epubTitle$),
      map(([page, title]): GoogleAnalyticsPageview => ({ page, title })),
      tap((event) => this.ga.pageview(event)),
      logCatchError('_gaEpubPageview$'),
    );
  });

  private readonly _gaPlaceholderRemvoed$ = this.effect(() => {
    const { performance } = this.window;
    if (!performance) return EMPTY;

    const { perf, perfStart, perfEnd } = this.createPerformanceMarks(
      'placeholder_dispaly',
    );

    const event: Omit<GoogleAnalyticsTimingEvent, 'timingValue'> = {
      timingCategory: 'ePub',
      timingVar: 'Placeholder removed',
    };
    const loadingHistory$ = this.readerStore.loading$.pipe(
      distinctUntilChanged(),
      pairwise(),
    );

    const initialLoad$ = loadingHistory$.pipe(
      take(1),
      this.util.tapGaTiming(event),
    );
    const subsequentLoads$ = loadingHistory$.pipe(
      skip(1),
      tap(([p, c]) => {
        if (!p && c) {
          performance.mark(perfStart);
        }
      }),
      filter(([_, c]) => !c),
      tap(() => performance.mark(perfEnd)),
      this.mapToPerformanceTime(perf, perfStart, perfEnd),
      map(
        (timingValue): GoogleAnalyticsTimingEvent => ({ ...event, timingValue }),
      ),
      this.util.tapGaTiming(),
    );

    return merge(initialLoad$, subsequentLoads$).pipe(
      logCatchError('_gaPlaceholderRemvoed$'),
    );
  });

  private readonly _gaDevicePerf$ = this.effect(() => {
    const { navigator } = this.window as any;
    if (!navigator) return EMPTY;

    const connection = navigator.connection;

    const networkEffectiveType = connection?.effectiveType ?? 'NA';
    const networkSaveData = connection?.saveData ?? 'NA';
    const deviceMemory = navigator.deviceMemory ?? 'NA';
    const hardwareConcurrency = navigator.hardwareConcurrency ?? 'NA';

    const eventLabel = `${networkEffectiveType}-${networkSaveData}-${deviceMemory}-${hardwareConcurrency}`;
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'Performance',
      eventAction: 'Performance Tracking',
      eventLabel,
    };

    return of(event).pipe(this.util.tapGaEvent());
  });

  /** annotations */
  private readonly _gaAnnotationChanges$ = this.effect(() => {
    const { trackAnnotationChagnes, trackAnnotationDeleted } = analyticsActions;

    const compareAnnotation$ = this.readerActions$.pipe(
      ofType(trackAnnotationChagnes),
      map(({ annotation, prevAnnotation }): [ApiAnnotation, ApiAnnotation] => [
        annotation,
        prevAnnotation as ApiAnnotation,
      ]),
    );
    const deleteAnnotation$ = this.readerActions$.pipe(
      ofType(trackAnnotationDeleted),
    );

    const colorMessage = {
      'yellow-highlight': 'Yellow',
      'blue-highlight': 'Blue',
      'green-highlight': 'Green',
      'pink-highlight': 'Pink',
    };

    const shapeMessage = {
      'epr-shape-square-filled': 'Square filled',
      'epr-shape-square-outline': 'Square outline',
      'epr-shape-round-outline': 'Round outline',
      'epr-shape-underline': 'Underline',
    };

    const annotationAdded$ = compareAnnotation$.pipe(
      filter(([_, prev]) => prev === undefined),
      concatMap(([annotation]) => {
        let events = ['Annotation Created'];

        const { highlight, shape, color, note, placemarkText } = annotation;

        if (highlight && shape) {
          events = [...events, `Highlight shape ${shapeMessage[shape]} Added`];
        }

        if (highlight && color) {
          events = [...events, `Highlight color ${colorMessage[color]} Added`];
        }

        if ((note as string)?.length > 0) {
          events = [...events, 'Note Added'];
        }

        if ((placemarkText as string)?.length > 0) {
          events = [...events, 'Placemark Added'];
        }

        return from(events);
      }),
    );

    const annotationChanges$ = compareAnnotation$.pipe(
      filter(([, prev]) => prev !== undefined),
      mergeMap(([annotation, p]) => {
        let events: string[] = [];

        const { highlight, shape, color, note, placemarkText } = annotation;

        if (highlight && p.highlight) {
          if (shape !== p.shape) {
            events = [
              ...events,
              `Highlight Shape Changed from ${
                shapeMessage[p.shape as HighlightShapeClass]
              } to ${shapeMessage[shape as HighlightShapeClass]}`,
            ];
          }

          if (color !== p.color) {
            events = [
              ...events,
              `Highlight Color Changed from ${
                colorMessage[p.color as HighlightColorClass]
              } to ${colorMessage[color as HighlightColorClass]}`,
            ];
          }
        } else if (!highlight && p.highlight) {
          if (p.color) {
            events = [...events, `Highlight ${colorMessage[p.color]} Removed`];
          }
          if (p.shape) {
            events = [...events, `Highlight ${shapeMessage[p.shape]} Removed`];
          }
        } else if (highlight && !p.highlight) {
          events = [...events, 'Highlight Added'];
        }

        if ((note as string)?.length > 0) {
          if (!p.note || p.note?.length === 0) {
            events = [...events, 'Note Added'];
          } else if (note !== p.note) {
            events = [...events, 'Note Changed'];
          }
        } else if (!note || note?.length === 0) {
          if ((p.note?.length as number) > 0) {
            events = [...events, 'Note Removed'];
          }
        }

        if ((placemarkText as string)?.length > 0) {
          if (!p.placemarkText || p.placemarkText?.length === 0) {
            events = [...events, 'Placemark Added'];
          } else if (placemarkText !== p.placemarkText) {
            events = [...events, 'Placemark Changed'];
          }
        } else if (!placemarkText || placemarkText?.length === 0) {
          if ((p.placemarkText?.length as number) > 0) {
            events = [...events, 'Placemark Removed'];
          }
        }

        return from(events);
      }),
    );

    const annotationDeleted$ = deleteAnnotation$.pipe(
      concatMap(({ annotation }) => {
        let events = ['Annotation Deleted'];

        const { highlight, shape, color, note, placemarkText } = annotation;

        if (highlight && shape && color) {
          events = [...events, 'Highlight Removed'];
          if (color) {
            events = [...events, `Highlight ${colorMessage[color]} Removed`];
          }
          if (shape) {
            events = [...events, `Highlight ${shapeMessage[shape]} Removed`];
          }
        }

        if ((note as string)?.length > 0) {
          events = [...events, 'Note Removed'];
        }

        if ((placemarkText as string)?.length > 0) {
          events = [...events, 'Placemark Removed'];
        }

        return from(events);
      }),
    );

    return merge(annotationAdded$, annotationChanges$, annotationDeleted$).pipe(
      map(
        (eventAction): GoogleAnalyticsEvent => ({
          eventCategory: 'Annotation Context Menu',
          eventAction,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaAnnotationChanges$'),
    );
  });

  /** contents menu */
  private readonly _gaContentsMenuOpen$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(readerActions.setLeftDrawer),
      filter(({ open }) => open),
      this.util.tapGaEvent({
        eventCategory: 'Contents Menu',
        eventAction: 'Open',
      }),
      logCatchError('_gaContentsMenuOpen$'),
    );
  });

  private readonly _gaAnnotationListItemSelected$ = this.effect(() => {
    const highlightSelected$ = this.highlightListActions$.pipe(
      ofType(highlightListActions.annotationSelected),
      mapTo('Highlight'),
    );

    const placemarkSelected$ = this.placemarkListActions$.pipe(
      ofType(placemarkListActions.annotationSelected),
      mapTo('Placemark'),
    );

    const noteSelected$ = this.noteListActions$.pipe(
      ofType(noteListActions.annotationSelected),
      mapTo('Note'),
    );

    return merge(highlightSelected$, placemarkSelected$, noteSelected$).pipe(
      map(
        (eventLabel): GoogleAnalyticsEvent => ({
          eventCategory: 'Annotation List',
          eventAction: 'Annotation Selected',
          eventLabel,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaAnnotationListItemSelected$'),
    );
  });

  private readonly _gaAnnotationListItemDelete$ = this.effect(() => {
    const highlightDelete$ = this.highlightListActions$.pipe(
      ofType(highlightListActions.highlightDelete),
      mapTo('Highlight'),
    );

    const placemarkDelete$ = this.placemarkListActions$.pipe(
      ofType(placemarkListActions.placemarkDelete),
      mapTo('Placemark'),
    );

    const noteDelete$ = this.noteListActions$.pipe(
      ofType(noteListActions.noteDelete),
      mapTo('Note'),
    );

    return merge(highlightDelete$, placemarkDelete$, noteDelete$).pipe(
      map(
        (eventLabel): GoogleAnalyticsEvent => ({
          eventCategory: 'Annotation List',
          eventAction: 'Annotation Delete',
          eventLabel,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaAnnotationListItemDelete$'),
    );
  });

  private readonly _gaAnnotationListExport$ = this.effect(() => {
    const highlightsExport$ = this.highlightListActions$.pipe(
      ofType(highlightListActions.exportHighlights),
      mapTo('Highlight'),
    );

    const notesExport$ = this.noteListActions$.pipe(
      ofType(noteListActions.exportNotes),
      mapTo('Note'),
    );

    return merge(highlightsExport$, notesExport$).pipe(
      map(
        (eventLabel): GoogleAnalyticsEvent => ({
          eventCategory: 'Annotation List',
          eventAction: 'Export',
          eventLabel,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaAnnotationListExport$'),
    );
  });

  /** credit links */
  private readonly _gaCreditLinks$ = this.effect(() => {
    const { CREDIT_LINK } = AnchorType;

    const anchorClick$ = this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
    );
    const creditLink$ = anchorClick$.pipe(
      filter(({ anchor }) => getAnchorType(anchor) === CREDIT_LINK),
    );

    const creditLinkEvent$ = creditLink$.pipe(
      map(({ anchor }) => this.getCreditLinkType(anchor)),
      map(
        (eventAction: string): GoogleAnalyticsEvent => ({
          eventCategory: 'Credit Link',
          eventAction,
        }),
      ),
    );

    return creditLinkEvent$.pipe(
      this.util.tapGaEventWithTitle(),
      logCatchError('_gaCreditLinks$'),
    );
  });

  /** dialogs */
  private readonly _gaAssignmentSubmit$ = this.effect(() => {
    const { submitAssignmentSuccess, submitAssignmentError } =
      assignmentActions;

    const success$ = this.readerActions$.pipe(
      ofType(submitAssignmentSuccess),
      mapTo('Assignment Success'),
    );
    const error$ = this.readerActions$.pipe(
      ofType(submitAssignmentError),
      mapTo('Assignment Error'),
    );

    return merge(success$, error$).pipe(
      map(
        (eventAction): GoogleAnalyticsEvent => ({
          eventCategory: 'Dialog',
          eventAction,
        }),
      ),
      this.util.tapGaEventWithTitle(),
      logCatchError('_gaAssignmentSubmit$'),
    );
  });

  /** footnotes */
  private readonly _gaFootnoteLinks$ = this.effect(() => {
    const { FOOTNOTE } = AnchorType;
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'Footnote',
      eventAction: 'Open',
    };

    const footnoteLink$ = this.anchorClick$.pipe(
      filter(({ anchor }) => getAnchorType(anchor) === FOOTNOTE),
    );

    return footnoteLink$.pipe(
      this.util.tapGaEventWithTitle(event),
      logCatchError('_gaFootnoteLinks$'),
    );
  });

  /** glossary */
  private readonly _gaGlossaryLinks$ = this.effect(() => {
    const { GLOSSARY } = AnchorType;
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'Glossary',
      eventAction: 'Open',
    };

    const glossaryLink$ = this.anchorClick$.pipe(
      filter(({ anchor }) => getAnchorType(anchor) === GLOSSARY),
    );

    return glossaryLink$.pipe(
      this.util.tapGaEventWithTitle(event),
      logCatchError('_gaGlossaryLinks$'),
    );
  });

  /** image viewer */
  private readonly _gaImageViewer$ = this.effect(() => {
    const { LARGE_IMAGE } = AnchorType;
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'Image Preview',
      eventAction: 'Open',
    };

    const imgClick$ = this.anchorClick$.pipe(
      filter(({ anchor }) => getAnchorType(anchor) === LARGE_IMAGE),
    );

    return imgClick$.pipe(
      this.util.tapGaEventWithTitle(event),
      logCatchError('_gaImageViewer$'),
    );
  });

  /** lti */
  private readonly _gaLtiParams$ = this.effect(() => {
    type LtiGaEvent = Omit<GoogleAnalyticsEvent, 'eventCategory'>;
    const { roles$, oauthConsumerKey$ } = this.configStore;

    const gaRole$ = roles$.pipe(
      filter((val) => Boolean(val)),
      map((eventLabel): LtiGaEvent => ({ eventAction: 'roles', eventLabel })),
    );

    const gaOAuth$ = oauthConsumerKey$.pipe(
      filter((val) => Boolean(val)),
      map(
        (eventLabel): LtiGaEvent => ({
          eventAction: 'oauth_consumer_key',
          eventLabel,
        }),
      ),
    );

    return merge(gaRole$, gaOAuth$).pipe(
      map(
        (event): GoogleAnalyticsEvent => ({ eventCategory: 'LTI', ...event }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaLtiParams'),
    );
  });

  /** navigation */
  private readonly _gaBavigationHistoryBack$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(toolbarActions.navigationHistoryBack),
      this.util.tapGaEvent({
        eventCategory: 'Navigation',
        eventAction: 'History Back',
      }),
      logCatchError('_gaBavigationHistoryBack$'),
    );
  });

  /** overflow menu */
  private readonly _gaOverflowDeleteAnnotation$ = this.effect(() => {
    const { deleteAllAnnotations, deletePageAnnotations } = toolbarActions;

    const all$ = this.readerActions$.pipe(
      ofType(deleteAllAnnotations),
      mapTo('Clear All Highlights'),
    );
    const pg$ = this.readerActions$.pipe(
      ofType(deletePageAnnotations),
      mapTo('Clear Page Highlights'),
    );

    return merge(all$, pg$).pipe(
      map(
        (eventAction): GoogleAnalyticsEvent => ({
          eventCategory: 'Overflow Menu',
          eventAction,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaOverflowDeleteAnnotation$'),
    );
  });

  private readonly _gaOverflowQuickAnnotation$ = this.effect(() => {
    const init$ = this.readerActions$.pipe(ofType(readerActions.initComplete));

    return this.readerStore.quickAnnotationEnabled$.pipe(
      skipUntil(init$),
      map((enabled) => `Quick Annotation ${enabled ? 'Enabled' : 'Disabled'}`),
      map(
        (eventAction): GoogleAnalyticsEvent => ({
          eventCategory: 'Overflow Menu',
          eventAction,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaOverflowQuickAnnotation$'),
    );
  });

  private readonly _gaOverflowTeacherContent$ = this.effect(() => {
    const init$ = this.readerActions$.pipe(ofType(readerActions.initComplete));

    return this.readerStore.teacherContentEnbled$.pipe(
      skipUntil(init$),
      map((enabled) => `Teacher Content ${enabled ? 'Enabled' : 'Disabled'}`),
      map(
        (eventAction): GoogleAnalyticsEvent => ({
          eventCategory: 'Overflow Menu',
          eventAction,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaOverflowTeacherContent$'),
    );
  });

  /** search */
  private readonly _gaSearchOpen$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(readerActions.setRightDrawer),
      filter(({ open }) => open),
      this.util.tapGaEvent({ eventCategory: 'Search', eventAction: 'Open' }),
      logCatchError('_gaSearchOpen$'),
    );
  });

  private readonly _gaSearchContentLoad$ = this.effect(() => {
    const { performance } = this.window;
    if (!performance) return EMPTY;

    const { perf, perfStart, perfEnd } = this.createPerformanceMarks(
      'search_content_load',
    );
    const event: Omit<GoogleAnalyticsTimingEvent, 'timingValue'> = {
      timingCategory: 'Search',
      timingVar: 'Search Content Loaded',
    };

    const searchReady$ = this.searchStore.isReady$.pipe(
      filter((ready) => ready),
    );

    return this.searchActions$.pipe(
      ofType(searchActions.setSearchContent),
      tap(() => performance.mark(perfStart)),
      switchMapTo(searchReady$),
      tap(() => performance.mark(perfEnd)),
      this.mapToPerformanceTime(perf, perfStart, perfEnd),
      map(
        (timingValue): GoogleAnalyticsTimingEvent => ({ ...event, timingValue }),
      ),
      this.util.tapGaTimingWithTitle(),
      logCatchError('_gaSearchContentLoad$'),
    );
  });

  private readonly _gaSearchQuery$ = this.effect(() => {
    return this.searchActions$.pipe(
      ofType(searchActions.search),
      map(({ value }) => value),
      withLatestFrom(this.searchStore.min$, this.readerStore.title$),
      filter(([val, min]) => val?.length >= min),
      map(([query, _, title]) => `"${query}" :in: ${title}`),
      map(
        (eventLabel): GoogleAnalyticsEvent => ({
          eventCategory: 'Search',
          eventAction: 'Query',
          eventLabel,
        }),
      ),
      this.util.tapGaEvent(),
      logCatchError('_gaSearchQuery$'),
    );
  });

  private readonly _gaSearchQueryPerf$ = this.effect(() => {
    const { performance } = this.window;
    if (!performance) return EMPTY;

    const { perf, perfStart, perfEnd } =
      this.createPerformanceMarks('search_results');
    const event: Omit<GoogleAnalyticsTimingEvent, 'timingValue'> = {
      timingCategory: 'Search',
      timingVar: 'Search Results Returned',
    };

    const query$ = this.searchActions$.pipe(
      ofType(searchActions.search),
      map(({ value }) => value),
      withLatestFrom(this.searchStore.min$),
      filter(([val, min]) => val?.length >= min),
      map(([val]) => val),
    );

    const results$ = defer(() => {
      return this.searchStore.searching$.pipe(
        pairwise(),
        filter(([_, c]) => !c),
        take(1),
      );
    });

    return query$.pipe(
      tap(() => performance.mark(perfStart)),
      switchMap((query) => {
        return results$.pipe(
          tap(() => performance.mark(perfEnd)),
          this.mapToPerformanceTime(perf, perfStart, perfEnd),
          map((timingValue) => ({ query, timingValue })),
        );
      }),
      withLatestFrom(this.readerStore.title$),
      map(([{ query, timingValue }, title]) => ({
        timingLabel: `"${query}" :in: ${title}`,
        timingValue,
      })),
      map((args): GoogleAnalyticsTimingEvent => ({ ...event, ...args })),
      this.util.tapGaTiming(),
      logCatchError('_gaSearchQueryPerf$'),
    );
  });

  /** tts */
  private readonly _gaTtsReadSelected$ = this.effect(() => {
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'TTS',
      eventAction: 'Read Selected',
    };

    return this.annotationContextActions$.pipe(
      ofType(annotationContextMenuActions.loadTextAudio),
      this.util.tapGaEvent(event),
      logCatchError('_gaTtsReadSelected$'),
    );
  });

  private readonly _gaTtsReadPage$ = this.effect(() => {
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'TTS',
      eventAction: 'Read Page',
    };

    return this.ttsActions$.pipe(
      ofType(ttsActions.togglePageTTSControls),
      filter(({ val }) => val),
      this.util.tapGaEvent(event),
      logCatchError('_gaTtsReadPage$'),
    );
  });

  private readonly _gaTtsSpeedChange$ = this.effect(() => {
    const event: GoogleAnalyticsEvent = {
      eventCategory: 'TTS',
      eventAction: 'Speed Change',
    };

    return this.ttsActions$.pipe(
      ofType(ttsActions.updatePlaybackRate),
      map(({ val: eventValue }) => ({ ...event, eventValue })),
      this.util.tapGaEvent(),
      logCatchError('_gaTtsSpeedChange$'),
    );
  });

  /**
   * helper operators
   */
  private mapToPerformanceTime<T>(
    measure: string,
    start?: string,
    end?: string,
  ) {
    return (source: Observable<T>) => {
      return source.pipe(
        map(() => {
          const m = this.coercePerformanceMeasure(measure, start, end);
          return Math.floor(m.duration);
        }),
      );
    };
  }

  /**
   * helpers
   */
  private getCreditLinkType(anchor: HTMLAnchorElement): string | undefined {
    const urlPart = anchor.getAttribute('data-href');
    const id = urlPart?.slice(1);

    // get the contents of the credit link
    const doc = anchor.ownerDocument;
    const creditLinkContents = doc.getElementById(id as string);

    if (!creditLinkContents) {
      return;
    }

    // generic modal title
    let creditType = 'Other';

    // modal title specific to the asset type
    if (creditLinkContents.classList.contains('paragraph-asset-credits')) {
      creditType = 'Paragraph';
    } else if (creditLinkContents.classList.contains('audio-asset-credits')) {
      creditType = 'Audio';
    } else if (creditLinkContents.classList.contains('video-asset-credits')) {
      creditType = 'Video';
    }

    return creditType;
  }

  private coercePerformanceMeasure(
    measure: string,
    start?: string,
    end?: string,
  ): PerformanceMeasure {
    return this.window.performance.measure(
      measure,
      start,
      end,
    ) as unknown as PerformanceMeasure;
  }

  private createPerformanceMarks(key: string): {
    perf: string
    perfStart: string
    perfEnd: string
  } {
    const perf = key;
    const perfStart = `${perf}_start`;
    const perfEnd = `${perf}_end`;

    return { perf, perfStart, perfEnd };
  }
}
