import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';

import { LastLocation } from './models';
import * as actions from './last-location.actions';

@Injectable()
export class LastLocationStore extends ExtendedComponentStore<
{ location: LastLocation | undefined },
actions.LastLocationActions
> {
  constructor() {
    super({ location: undefined });
  }

  /** selectors */
  readonly lastLocation$ = this.select(({ location }) => location);

  /** updaters */
  readonly setLastLocation = this.updater((state, location: LastLocation) => {
    // the API is currently returning the string 'null' for last location due to a Firebase migration bug
    if (location.location === 'null') {
      location.location = null;
    }

    return { ...state, location };
  });
}
