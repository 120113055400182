import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EpubViewerComponent } from './epub-viewer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EpubViewerComponent],
  exports: [EpubViewerComponent],
})
export class EpubViewerModule {}
