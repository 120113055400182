import { Footnote } from '@mhe/reader/models';

export const FOOTNOTE_STATE_KEY = 'footnote';

export interface FootnoteState {
  footnote: Footnote

  // loading
  loading: boolean
  error?: Error
}

export type FootnoteDictionaryState = Record<string, FootnoteState>;

export interface PartialFootnoteState {
  [FOOTNOTE_STATE_KEY]: FootnoteDictionaryState
}

export const initialState: FootnoteDictionaryState = {};
