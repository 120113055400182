import { createAction, props } from '@ngrx/store';
import {
  TTSFloatingTextRequest,
  WidgetData,
  WidgetInstance,
} from '@mhe/reader/models';

// actions are per-widget in this phase of integration
// individual WidgetManager objects will trigger actions
export const widgetDataSearch = createAction(
  '[Widget Data] Search for widget data',
  props<{ widgetUuid: string }>(),
);

export const widgetDataSave = createAction(
  '[Widget Data] Save widget data',
  props<{ widgetUuid: string, dataStringified: string }>(),
);

export const saveWidgetDataSuccess = createAction(
  '[Widget Data] Save widget data - success',
);
export const saveWidgetDataError = createAction(
  '[Widget Data] Save widget data - error',
);

export const resetActivitiesConfirm = createAction(
  '[Widget Data] Ask to confirm reset activities',
  props<{ scope: 'all' | 'exhibit' }>(),
);

export const resetActivities = createAction(
  '[Widget Data] Reset activities',
  props<{ scope: 'all' | 'exhibit' }>(),
);

export const resetIframes = createAction(
  '[Widget Instance] Reset/reload the active widget iframes',
);

export const widgetInstanceResetIframe = createAction(
  '[Widget Instance] Reset/reload a widget iframe',
  props<{ widgetUuid: string, widgetIframeReset: null | 'pending' | 'reset' }>(),
);

export const widgetInstanceAdd = createAction(
  '[Widget Instance] Add Instance',
  props<{ widgetInstance: WidgetInstance }>(),
);

export const widgetInstanceRemove = createAction(
  '[Widget Instance] Remove Instance',
  props<{ widgetUuid: string }>(),
);

export const widgetInstanceUpdateWidgetData = createAction(
  '[Widget Instance] Update widget data on widget intance',
  props<{ widgetUuid: string, widgetData: WidgetData }>(),
);

export const widgetInstanceSelectByWidgetUuid = createAction(
  '[Widget Instance] Get instance by wigetUuid',
  props<{ widgetUuid: string }>(),
);

export const widgetInstanceSetWidgetSearch = createAction(
  '[Widget Instance] Set widgetSearchRequested state',
  props<{ widgetUuid: string, isRequested: boolean }>(),
);

export const widgetInstanceSetPreRender = createAction(
  '[Widget Instance] Set preRendered state',
  props<{ widgetUuid: string, isPreRendered: boolean }>(),
);

export const widgetInstanceSetPostRender = createAction(
  '[Widget Instance] Set postRendered state',
  props<{ widgetUuid: string, isPostRendered: boolean }>(),
);

export const widgetInstanceIframeDomLoaded = createAction(
  '[Widget Instance] Set iframeDomLoaded state',
  props<{ widgetUuid: string, isLoaded: boolean }>(),
);

export const widgetInstanceSetDataRestored = createAction(
  '[Widget Instance] Set dataRestoredOnLoad state',
  props<{ widgetUuid: string, isRestored: boolean }>(),
);

export const widgetInstanceIsSaving = createAction(
  '[Widget Instance] Set widgetDataSaving state',
  props<{
    widgetUuid: string
    isSaving: null | 'saving' | 'saved' | 'not-saved'
  }>(),
);

export const widgetTextToSpeech = createAction(
  '[Widget] Call Readspeaker service for text-to-speech',
  props<{
    ePubIframe: HTMLIFrameElement
    textToSpeechRequest: TTSFloatingTextRequest
  }>(),
);

export type WidgetDataActions = ReturnType<
| typeof widgetDataSearch
| typeof widgetDataSave
| typeof saveWidgetDataSuccess
| typeof saveWidgetDataError
| typeof widgetInstanceAdd
| typeof widgetInstanceRemove
| typeof widgetInstanceSelectByWidgetUuid
| typeof widgetInstanceSetPreRender
| typeof widgetInstanceSetPostRender
| typeof widgetInstanceIsSaving
| typeof widgetTextToSpeech
>;
