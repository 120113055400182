/* eslint-disable @typescript-eslint/quotes */
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { AnnotationType } from '../../annotation.data';

@Component({
  selector: 'rdrx-annotations-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    // cdk
    A11yModule,
    OverlayModule,
    // material
    MatMenuModule,
  ],
  template: `
    <div [ngSwitch]="type">
      <ng-template [ngSwitchCase]="AnnotationType.HIGHLIGHT">
        <button
          mat-button
          [matMenuTriggerFor]="beforeMenu"
          aria-label="Dropdown Menu"
          class="btn btn-icon kebab-button"
        >
          <span class="kebab-menu-icon"></span>
        </button>
        <mat-menu class="toc-header-dropdown" #beforeMenu="matMenu" xPosition="before">
          <button
            mat-menu-item
            (click)="editModeMenuSelected.emit(true)"
          >
            {{ 'contents.menu_edit_highlights' | translate }}
          </button>
          <!-- // TODO move the whole functionality -->
          <button
            mat-menu-item
            (click)="handleExportBtn($event)"
          >
          {{ 'contents.menu_export_highlights' | translate }}
          </button>
        </mat-menu>
      </ng-template>

      <ng-template [ngSwitchCase]="AnnotationType.NOTE">
        <button
          mat-button
          [matMenuTriggerFor]="beforeMenu"
          aria-label="Dropdown Menu"
          class="btn btn-icon kebab-button"
        >
          <span class="kebab-menu-icon"></span>
        </button>
        <mat-menu class="toc-header-dropdown" #beforeMenu="matMenu" xPosition="before">
          <button
            mat-menu-item
            (click)="editModeMenuSelected.emit(true)"
          >
            {{ 'contents.menu_edit_notes' | translate }}
          </button>
          <!-- // TODO move the whole functionality -->
          <button
            mat-menu-item
            (click)="handleExportBtn($event)"
          >
          {{ 'contents.menu_export_notes' | translate }}
          </button>
        </mat-menu>
      </ng-template>

      <ng-template [ngSwitchCase]="AnnotationType.PLACEMARK">
        <button
          mat-button
          [matMenuTriggerFor]="beforeMenu"
          aria-label="Dropdown Menu"
          class="btn btn-icon kebab-button"
        >
          <span class="kebab-menu-icon"></span>
        </button>
        <mat-menu class="toc-header-dropdown" #beforeMenu="matMenu" xPosition="before">
          <button
            mat-menu-item
            (click)="editModeMenuSelected.emit(true)"
          >
            {{ 'contents.menu_edit_placemarks' | translate }}
          </button>
        </mat-menu>
      </ng-template>
    </div>
  `,
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationListMenuComponent {
  AnnotationType = AnnotationType;
  @Input({ required: true }) type!: AnnotationType;
  @Output() editModeMenuSelected = new EventEmitter<boolean>();
  @Output() exportAnnotationsSelected = new EventEmitter();

  handleExportBtn(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.exportAnnotationsSelected.emit();
  }
}
