import { Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import {
  WidgetData,
  WidgetInstance,
  defaultWidgetInstance,
} from '../models/widgets';
import { WidgetsManagerTransformer } from './widgets-manager.transformer';

export class WidgetManager {
  // used with Global WIRIS feature
  responseRequestId: string;

  // will be our selector to access props on the widget instance in the store
  private readonly widgetInstanceItem$: Observable<WidgetInstance>;

  // will be our way to unsubsribe all over the place for cleanup
  private readonly widgetManagerUnload$ = new Subject();

  // performance timers
  private lazyloadObserver: IntersectionObserver;
  private loadStart: number;
  private loadEnd: number;

  /*
   * Between the preRender and postRender cycles the contentDocument
   * used to access the element will change.  The WidgetsManager automatically
   * updates the references for use internally here, hence they are public
   */
  // TODO are we doing doc block stuff?
  constructor(
    private readonly renderer: Renderer2,
    public widgetIframe: HTMLIFrameElement,
    public cloIframeContentDoc: HTMLDocument,
    private readonly parent: WidgetsManagerTransformer,
  ) {
    // get a selector for our widget instance
    this.widgetInstanceItem$ = this.parent.getWidgetInstance(this.widgetUuid);
  }

  public preRender_init(wasReset?: boolean): void {
    // defer load of widget iframes in the pre render cycle
    // we might do other mutations in the future before the element
    // hits the clo-iframe content document for rendering
    this.widgetLoadDefer();

    // Add wrapping tabbable div around widget iframe for a11y
    this.widgetLoadWrapDiv();

    // add preload hints for the widget iframe
    this.widgetPreloadHint();

    const defaultData = defaultWidgetInstance(this.widgetUuid);

    if (wasReset) {
      // flag if this was a reset
      defaultData.widgetIframeReset = 'reset';
    }

    // store the initial widget instance
    this.parent.setWidgetInstance(defaultData);

    // start loading potential user data from api
    this.dataSearchRequest();

    // flag that preRender init is complete
    this.parent.setPreRender(this.widgetUuid, true);
  }

  public postRender_init(): void {
    this.handlePerfStart();

    // listen for the load event
    const unlistenLoad = this.renderer.listen(this.widgetIframe, 'load', () => {
      // delegate to another function
      this.widgetLoadedEvent();

      // TODO there may be other things to do in here such as metrics reporting

      // the load event is here, but there is not an accessible content document
      // due to cross-domain security and widget design, we cannot/should not
      // interact directly inside the iframe

      // only need it once
      unlistenLoad();
    });

    // setup the UI for saving/saved chip
    this.widgetSavingUI();

    // flag that postRender init is complete
    this.parent.setPostRender(this.widgetUuid, true);

    // watch for reset action
    this.widgetReset();
  }

  public get widgetUuid(): string {
    return this.widgetIframe?.id;
  }

  public get loggingPrefix(): string {
    return '[WM ' + this.widgetUuid.substr(-4, 4) + ']';
  }

  private widgetReset(): void {
    this.widgetInstanceItem$
      .pipe(
        takeUntil(this.widgetManagerUnload$),
        filter(
          (widgetInstance: WidgetInstance) =>
            widgetInstance && widgetInstance.widgetIframeReset === 'pending',
        ),
        tap(() => {
          // reload widget iframe
          void this.parent.resetWidgetIframe(this.widgetUuid);
        }),
      )
      .subscribe();
  }

  /*
   * Data Saving UI
   *
   * This UI displays a visual chip to the user when there is widget being
   * saved to the backend API.  The chip displays on save, and dismisses
   * after a given amount of time.
   *
   * The UI is NOT a11y nor i18n in legacy.
   *
   * TODO update this UI to improve pitfalls of current implementation
   */
  private widgetSavingUI(): void {
    let dismissTimeout;

    // wait for saving to be indicated
    this.widgetInstanceItem$
      .pipe(
        takeUntil(this.widgetManagerUnload$),
        filter(
          (widgetInstance: WidgetInstance) =>
            widgetInstance?.widgetDataSaving !== null &&
            widgetInstance?.widgetDataSaving !== undefined,
        ),
        tap((widgetInstance: WidgetInstance) => {
          // defaul time to display the chip
          let dismissAfter = 3000;

          switch (widgetInstance.widgetDataSaving) {
            case 'saving':
              // true, a save started
              this.widgetSavingUi_saving();
              break;
            case 'saved':
              // false, a saved completed
              this.widgetSavingUi_saved();
              break;
            case 'not-saved':
              // null, could not save
              this.widgetSavingUi_notSaved();

              // not-saved chip does not dismiss (until subsequent successful save)
              dismissAfter = 0;
              break;
            default:
              return;
          }

          if (dismissTimeout) {
            clearTimeout(dismissTimeout);
          }

          if (dismissAfter) {
            dismissTimeout = setTimeout(() => {
              this.widgetSavingUi_remove();
            }, dismissAfter);
          }
        }),
      )
      .subscribe();
  }

  // TODO we need improved UX at some point in place of this generic CSS display
  private widgetSavingUi_saving(): void {
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saved');
    this.renderer.addClass(this.widgetIframe.parentNode, 'saving');
  }

  private widgetSavingUi_saved(): void {
    this.renderer.removeClass(this.widgetIframe.parentNode, 'not-saved');
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saving');
    this.renderer.addClass(this.widgetIframe.parentNode, 'saved');
  }

  private widgetSavingUi_notSaved(): void {
    this.renderer.addClass(this.widgetIframe.parentNode, 'not-saved');
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saving');
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saved');
  }

  private widgetSavingUi_remove(): void {
    this.renderer.removeClass(this.widgetIframe.parentNode, 'not-saved');
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saving');
    this.renderer.removeClass(this.widgetIframe.parentNode, 'saved');
  }

  /*
   * Widget startup will be deferred until a local state machine
   * determines all items are present such as user data and other app level features.
   */

  private widgetLoadDefer(): void {
    // defer loading
    this.renderer.setAttribute(
      this.widgetIframe,
      'data-deferred-src',
      this.widgetIframe.getAttribute('src') as string,
    );
    this.renderer.removeAttribute(this.widgetIframe, 'src');

    // add a placeholder loading animation
    this.widgetLoadDeferAnimation_add();

    // wait for data load
    this.widgetInstanceItem$
      .pipe(
        takeUntil(this.widgetManagerUnload$),
        filter((widgetInstance) => {
          return (widgetInstance?.preRendered &&
            widgetInstance?.postRendered &&
            widgetInstance?.widgetSearchRequested) as boolean;
        }),
        take(1),
        tap(() => this.widgetLoadStart()),
      )
      .subscribe();
  }

  /*
   * While the widget is in the deferred startup state we can add preload
   * links to the <head> of the ePUB iframe document so that the root file is
   * prefetched over the network and read-ier to load when the user scrolls
   * to the widget element.
   */

  private widgetPreloadHint(): void {
    // parse the widget iframe to get the base path
    const widgetSrc = this.widgetIframe.getAttribute('data-deferred-src');

    if (!widgetSrc) {
      return;
    }

    // split by query string or hash values
    // eslint-disable-next-line no-useless-escape
    const widgetParts = widgetSrc.split(/[\#\?]/);

    // check if a preload link already exists
    const existingLink = this.cloIframeContentDoc.querySelector(
      'link[rel="preload"][href="' + widgetParts[0] + '"]',
    );

    if (existingLink) {
      return;
    }

    // <link rel="preload" href="…" as="document" />
    const linkEle = this.cloIframeContentDoc.createElement('link');
    this.renderer.setAttribute(linkEle, 'rel', 'preload');
    this.renderer.setAttribute(linkEle, 'href', widgetParts[0]);
    this.renderer.setAttribute(linkEle, 'as', 'document');

    // add the preload link to the epub iframe
    this.renderer.appendChild(this.cloIframeContentDoc.head, linkEle);
  }

  /*
   * While the widget is in the deferred startup state Reader adds it's own
   * UI animation as an immediate prior sibling to let the user know some
   * action is pending.
   */

  private widgetLoadDeferAnimation_id(): string {
    return 'loader--' + this.widgetUuid;
  }

  // TODO we need improved UX at some point in place of this generic CSS animation
  private widgetLoadDeferAnimation_add(): void {
    // prepend a loading indicator
    const loaderEle: HTMLDivElement = this.renderer.createElement('div');

    // set an id matching the widget iframe since widgets may share a parent
    // the id is to facilitate tracking for e2e testing and problem troubleshooting
    this.renderer.setAttribute(
      loaderEle,
      'id',
      this.widgetLoadDeferAnimation_id(),
    );

    // TODO we need a CFI-ignore class to avoid possibly breaking annotations
    this.renderer.addClass(loaderEle, 'loader-bar');

    // markup corresponding to our plain CSS animation
    loaderEle.innerHTML =
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';

    // add the loading animation just before the widget iframe
    this.renderer.insertBefore(
      this.widgetIframe.parentNode,
      loaderEle,
      this.widgetIframe,
    );
  }

  private widgetLoadDeferAnimation_remove(): void {
    // remove loading indicator if present
    const loaderEle = this.cloIframeContentDoc.getElementById(
      this.widgetLoadDeferAnimation_id(),
    );
    if (loaderEle) {
      // TODO why does Render2 delay removal of the element, perhaps need to force change detection?
      // this.renderer.removeChild(loaderEle.parentNode, loaderEle);
      loaderEle.parentNode?.removeChild(loaderEle);
    }
  }

  private widgetLoadWrapDiv(): void {
    const div = this.renderer.createElement('div');
    div.setAttribute('tabindex', '0');
    div.setAttribute('aria-labelledby', this.widgetIframe.id);
    this.renderer.appendChild(
      div,
      this.widgetIframe.parentNode?.replaceChild(div, this.widgetIframe),
    );
  }

  public widgetLoadStart(): void {
    // set the src attribute to start loading the iframe
    this.renderer.setAttribute(
      this.widgetIframe,
      'src',
      this.widgetIframe.getAttribute('data-deferred-src') as string,
    );
    this.renderer.removeAttribute(this.widgetIframe, 'data-deferred-src');

    // wait for search and iframe load before sending data
    this.widgetInstanceItem$
      .pipe(
        takeUntil(this.widgetManagerUnload$),
        filter(
          (widgetInstance) =>
            (widgetInstance?.preRendered &&
              widgetInstance?.postRendered &&
              widgetInstance?.iframeDomLoaded &&
              widgetInstance?.widgetSearchRequested) as boolean,
        ),
        take(1),
        tap((widgetInstance: WidgetInstance) => {
          // TODO very important to send undefined to the widget if there is no prior data
          this.dataRestore(widgetInstance.widgetData);

          // flag that data was restored on the iframe load event
          this.parent.setIsRestored(this.widgetUuid, true);
        }),
      )
      .subscribe();
  }

  private widgetLoadedEvent(): void {
    // remove the placeholder loading animation
    this.widgetLoadDeferAnimation_remove();

    // flag that iframe load has happened
    this.parent.setIframeLoaded(this.widgetUuid, true);

    this.performanceEnd();
  }

  // uisng async and promise so that we can "hold up" if needed in the future
  async widgetUnloadedEvent(): Promise<boolean> {
    this.lazyloadObserver?.disconnect();

    // remove the widget instance from store
    this.parent.removeWidgetInstance(this.widgetUuid);

    // clean up local subscriptions
    this.widgetManagerUnload$.next(null);
    this.widgetManagerUnload$.complete();

    // immediately resolve for now
    return await Promise.resolve(true);
  }

  /*
   * Message handling functions make more sense here, but note that they are received
   * in the parent WidgetsManager and sent to individual WidgetManager instances based on the source.
   */

  public handleMessage(evt: MessageEvent): void {
    // TODO move scripting to message-type-specific handling functions, possibly separate scripts

    if (evt.data.type === 'view' && evt.data.method === 'set') {
      // this is the same as the size:set message
      // the original inkling spec suggested both types be sent
      // we ignore it here, since size handling is handled elsewhere
      return;
    }

    if (evt.data.type === 'size' && evt.data.method === 'set') {
      // our epubs have a habitat_platform.js file in each exhibit
      // that script does size handling for us so we do not need to handle it here
      // TODO set the inline height and width on the iframe element
      return;
    }

    if (evt.data.type === 'link' && evt.data.method === 'open') {
      // links from inside widgets are not explicitly supported in Reader
      // widget authoring does not support it
      // some events may be `javascript:void(...)` triggered from CKE
      // do nothing
      return;
    }

    if (evt.data.type === 'message' && evt.data.method === 'publish') {
      // TODO determine if Reader does anything with this message
      return;
    }

    if (evt.data.type === 'data' && evt.data.method === 'save') {
      // request a save
      this.parent.saveWidgetData(
        this.widgetUuid,
        JSON.stringify(evt.data.data),
      );

      return;
    }

    if (evt.data.action === 'widget-caliper-event') {
      // TODO determine if caliper is going to be a feature of Reader X
      // TODO implement caliper external service
      return;
    }

    if (evt.data.action === 'widget-text-to-speech') {
      // console.log(this.loggingPrefix + ' message to call text-to-speech service, from button');
      // console.dir(evt.data.properties);

      // get the position of the widget iframe
      const iframeBounds = this.widgetIframe.getBoundingClientRect();

      // need to account for scrolling position of the ePUB iframe within the viewport
      const sLeft = this.cloIframeContentDoc.scrollingElement?.scrollLeft;
      const sTop = this.cloIframeContentDoc.scrollingElement?.scrollTop;

      this.parent.textToSpeechRequest({
        id: this.widgetUuid,
        text: evt.data.properties.text,
        location: {
          x: Math.floor(
            evt.data.properties.location.x + iframeBounds.x + sLeft + 10,
          ),
          y: Math.floor(
            evt.data.properties.location.y + iframeBounds.y + sTop + 16,
          ),
        },
        autoplay: true,
      });

      return;
    }

    if (evt.data.action === 'widget-highlight-event') {
      // console.log(this.loggingPrefix + ' message to call text-to-speech service, from highlight');
      // console.dir(evt.data.properties);

      // get the position of the widget iframe
      const iframeBounds = this.widgetIframe.getBoundingClientRect();

      // need to account for scrolling position of the ePUB iframe within the viewport
      const sLeft = this.cloIframeContentDoc.scrollingElement?.scrollLeft;
      const sTop = this.cloIframeContentDoc.scrollingElement?.scrollTop;

      this.parent.textToSpeechRequest({
        id: this.widgetUuid,
        text: evt.data.properties.text,
        location: {
          x: Math.floor(
            evt.data.properties.location.x + iframeBounds.x + sLeft + 10,
          ),
          y: Math.floor(
            evt.data.properties.location.y + iframeBounds.y + sTop + 16,
          ),
        },
        autoplay: false,
      });

      return;
    }

    if (evt.data.action === 'widget-request-supported-features') {
      // tell the widget what features are available
      this.sendMessageToWidget({
        action: 'widget-response-supported-features',
        properties: {
          // there is no feature flag for this; so always true
          isGlobalWirisSupported: true,

          // TODO update this when Readspeaker feature is available
          isReadspeakerSupported: true,

          // TODO get these values from config
          playerType: 'rdrx',
          playerVersion: '0.0.0',
        },
      });

      return;
    }

    if (evt.data.action === 'widget-request-global-wiris') {
      // a widget will not emit this message unless the widget-request-supported-features message
      // has been sent to the widget iframe and indicated global wiris is available

      let mathml = '';
      let toolbar = '';

      // optional for a widget to pass an existing formula into the wiris editor
      if (evt.data.properties.event.mathMLContent) {
        mathml = evt.data.properties.event.mathMLContent;
      }

      // optional for a widget to pass a specific toolbar configuration into the wiris editor
      if (evt.data.properties.event.toolbar) {
        toolbar = evt.data.properties.event.toolbar;
      }

      // save this for tracking against the response
      this.responseRequestId = evt.data.properties.event.requestId;

      // proxy the request out to the iframe mediator
      this.parent.globalWirisRequest(
        this.responseRequestId,
        mathml,
        toolbar,
        this,
      );

      return;
    }

    if (evt.data.action === 'widget-resend-data') {
      // the widget asked for a resend of widget data but we cannot send the
      // response message until we have completed the search action
      // a widget can request resend data at any time after it loads
      // this is distinct and different from the automatic data message which
      // happens on widget iframe load event
      this.widgetInstanceItem$
        .pipe(
          takeUntil(this.widgetManagerUnload$),
          filter(
            (widgetInstance) =>
              (widgetInstance?.preRendered &&
                widgetInstance?.postRendered &&
                widgetInstance?.iframeDomLoaded &&
                widgetInstance?.widgetSearchRequested) as boolean,
          ),
          take(1),
          tap((widgetInstance: WidgetInstance) => {
            // TODO very important to send undefined to the widget if there is no prior data
            this.dataRestore(widgetInstance.widgetData);
          }),
        )
        .subscribe();
    }

    if (evt.data.type === 'MH_TOKEN') {
      this.sendMhTokenToWidget(evt.origin);
    }

    // console.log(this.loggingPrefix + ' unhandled message received in widget manager');
    // console.dir(evt.data);
  }

  public dataSearchRequest(): void {
    // only request if it has not already been requested
    this.widgetInstanceItem$
      .pipe(
        // only while active
        takeUntil(this.widgetManagerUnload$),

        // only when a search has not yet been requested
        filter(
          (widgetInstance) => widgetInstance?.widgetSearchRequested === null,
        ),

        // use the parent to trigger the search action
        tap(() => this.parent.searchWidgetData(this.widgetUuid)),
      )
      .subscribe();
  }

  public dataRestore(widgetData: WidgetData): void {
    let data;

    if (widgetData?.data) {
      data = JSON.parse(widgetData.data);
    }

    const restoreToWidget = {
      type: 'data',
      method: 'restore',
      data,
      payload: undefined,
    };

    // send a message into the widget
    this.sendMessageToWidget(restoreToWidget);
  }

  public wirisResponse(mathMLResponse): void {
    // the Global WIRIS editor has been closed
    // send a response back to the widget iframe
    const reponseToWidget = {
      action: 'widget-response-global-wiris',
      properties: {
        requestId: this.responseRequestId,
        mathMLContent: mathMLResponse,
      },
    };

    this.sendMessageToWidget(reponseToWidget);
  }

  // TODO consider a message object class with schema enforcement
  private sendMessageToWidget(msg: object, origin = '*'): void {
    // access to the content window for postMessage is valid
    // even in the case of cross-domain security restrictions
    // because postMessage is THE standard method for
    // cross frame and cross domain communication
    this.widgetIframe.contentWindow?.postMessage(msg, origin);
  }

  /** analytics / performance */
  private handlePerfStart(): void {
    const widget$ = this.widgetInstanceItem$.pipe(
      take(1),
      takeUntil(this.widgetManagerUnload$),
    );
    const isReset$ = widget$.pipe(map((w) => w.widgetIframeReset === 'reset'));

    isReset$.subscribe((reset) => {
      if (!reset) {
        this.performanceLazyLoadStart();
      } else {
        this.lazyloadObserver?.disconnect();
        this.loadStart = performance.now();
      }
    });
  }

  private performanceLazyLoadStart(): void {
    this.lazyloadObserver = new IntersectionObserver((entries, obsvr) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadStart = performance.now();
          obsvr.disconnect();
        }
      });
    });

    this.lazyloadObserver.observe(this.widgetIframe);
  }

  private performanceEnd(): void {
    this.loadEnd = performance.now();

    const timingValue = Math.floor(this.loadEnd - this.loadStart);
    const timingLabel = this.getWidgetLabel();

    this.parent.ga.timing({
      timingCategory: 'Widgets',
      timingVar: 'Load',
      timingValue,
      timingLabel,
    });
  }

  private getWidgetLabel(): string {
    const ignore = ['widget', 'widget-loading'];
    const classes = Array.from(this.widgetIframe.classList);

    const labels = classes.filter((c) => !ignore.includes(c));
    const label = labels.join(' ');

    return label;
  }

  private sendMhTokenToWidget(origin = '*'): void {
    const MH_TOKEN = localStorage.getItem('MH_TOKEN');
    if (!MH_TOKEN) {
      console.warn('No MH_TOKEN found in local storage');
      return;
    }
    this.sendMessageToWidget({
      type: 'MH_TOKEN',
      body: MH_TOKEN,
    }, origin);
  }
}
