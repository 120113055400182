/* eslint-disable max-len */
import { Component, ViewChild, ElementRef, signal, OnDestroy } from '@angular/core';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import * as errorsQuery from '@mhe/reader/global-store/errors/errors.selectors';
import * as annotationsQuery from '@mhe/reader/global-store/annotations/annotations.selectors';
import {
  AnnotationListBonsaiNode,
  ApiAnnotation,
  ROOT_BONSAI_NODE,
} from '@mhe/reader/models';
import { BonsaiTreeComponent } from '@mhe/ngx-bonsai-reader';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  annotationSelected,
  exportHighlights,
  getAnnotationsForLabel,
  highlightBatchDelete,
  highlightDelete,
} from './state/highlight-list.actions';
import { HighlightListStore } from './state/highlight-list.store';
import { fetchAnnotationsForAllSpinesByType } from '../../../global-store/annotations/annotations.actions';
import { setAnnotationAriaLabel } from '../utils/set-annotation-aria-label';
import { AnnotationType, DropdownState } from '../annotation.data';
import { BatchDeleteState } from '../utils/annotation-list.state.abstract';

@Component({
  selector: 'rdrx-highlight-list',
  templateUrl: './highlight-list.component.html',
  styleUrls: ['./highlight-list.component.scss'],
})
export class HighlightListComponent implements OnDestroy {
  constructor(
    readonly higlightListStore: HighlightListStore,
    readonly store: Store,
  ) {}

  @ViewChild(BonsaiTreeComponent, { read: ElementRef }) tree: ElementRef;
  readonly rootNodeId = ROOT_BONSAI_NODE;
  readonly truncateLimit = 100;
  ButtonPurpose = ButtonPurpose;
  AlertType = AlertType;
  readonly destroy$ = new Subject<boolean>();
  readonly sessionEnded$ = this.store.pipe(select(errorsQuery.hasSessionEnded));
  readonly emptyTree$ = combineLatest([
    this.higlightListStore.tree$,
    this.sessionEnded$,
  ]).pipe(
    map(
      ([tree, hasSessionEnded]) =>
        !hasSessionEnded && Object.keys(tree).length === 1,
    ), // one because the root node
  );

  readonly userActionBatchDelete$ = this.higlightListStore.batchDeleteState$.pipe(
    takeUntil(this.destroy$),
    tap(state => {
      if (state === BatchDeleteState.SUCCESS) {
        this.handleCancelClick();
      }
    }),
  ).subscribe();

  treeDataCopy = {};

  nodeTree$ = this.higlightListStore.tree$.pipe(
    tap(treeData => (this.treeDataCopy = treeData)),
    tap(() => {
      if (this.allAnnotationsSelected) {
        this.highlightsSelected = [];
        this.handleAllAnnotationsSelected(true);
      }
    }),
  );

  exportStatus$ = this.store.select(annotationsQuery.annotationsExportStatus);
  isExportNotesAnnotationOpen = false;

  highlightDropDownState = signal<DropdownState>(DropdownState.ALL);
  isEditMode = false;
  highlightsSelected: string[] = [];
  headerType = AnnotationType.HIGHLIGHT;
  allAnnotationsSelected = false;

  get isFooterBtnDisabled(): boolean {
    return !!this.highlightsSelected.length;
  }

  handleNodeClick(node: AnnotationListBonsaiNode): void {
    // Select the annotation if not selection mode
    if (this.isEditMode) {
      return;
    }
    if (node.annotation) {
      this.higlightListStore.dispatch(
        annotationSelected({ annotation: node.annotation }),
      );
    } else {
      this.higlightListStore.dispatch(
        getAnnotationsForLabel({ spineID: node.id }),
      );
      this.higlightListStore.toggleExpandedNode(node.id);
    }
  }

  delete(annotation: ApiAnnotation): void {
    if (annotation) {
      this.higlightListStore.dispatch(highlightDelete({ annotation }));
    }
  }

  toggleNodeExpanded(nodeId: string): void {
    this.higlightListStore.dispatch(
      getAnnotationsForLabel({ spineID: nodeId }),
    );
    this.higlightListStore.toggleExpandedNode(nodeId);
  }

  isCollapsible(node: AnnotationListBonsaiNode): boolean {
    return node.collapsible ?? true;
  }

  isLoading(node: AnnotationListBonsaiNode): boolean {
    return node.childIds?.length === 0;
  }

  beginExport(): void {
    this.store.dispatch(
      fetchAnnotationsForAllSpinesByType({ annotationType: 'highlight' }),
    );
    this.isExportNotesAnnotationOpen = true;
  }

  completeExport(): void {
    this.higlightListStore.dispatch(exportHighlights());
    this.isExportNotesAnnotationOpen = false;
  }

  cancelExport(): void {
    this.isExportNotesAnnotationOpen = false;
  }

  // Set aria label on bonsai treeitem tag
  handleTextChange(text: string, node: AnnotationListBonsaiNode): void {
    if (node.annotation) {
      setAnnotationAriaLabel(this.tree, text, node);
    }
  }

  handleDropdownState(state: DropdownState): void {
    this.highlightDropDownState.set(state);
  }

  handleCheckAnnotation({ id, collapsible, childIds }: AnnotationListBonsaiNode): void {
    // Handle Chapter Level
    if (collapsible && childIds) {
      if (this.highlightsSelected.some(annotationId => annotationId === id)) {
        this.highlightsSelected = this.highlightsSelected.filter(hlId => !childIds.includes(hlId));
        this.highlightsSelected = this.highlightsSelected.filter(hlId => hlId !== id);
        return;
      }
      this.highlightsSelected = [
        ...this.highlightsSelected,
        ...childIds.map(childId => this.treeDataCopy[childId].id),
        id,
      ];
      return;
    }
    // Handle individuals
    if (this.highlightsSelected.find(hlId => hlId === id)) {
      this.highlightsSelected = this.highlightsSelected.filter(hlId => hlId !== id);
      return;
    }
    this.highlightsSelected = [
      ...this.highlightsSelected,
      id,
    ];
  }

  handleEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.higlightListStore.batchDeleteIdle();
  }

  handleCancelClick(): void {
    this.isEditMode = false;
    this.allAnnotationsSelected = false;
    this.highlightsSelected = [];
  }

  handleDeleteClick(): void {
    if (this.highlightsSelected.length > 0) {
      this.higlightListStore.batchDeleteStart();

      this.higlightListStore.dispatch(
        highlightBatchDelete({
          data: {
            annotations: this.highlightsSelected.map(id => this.treeDataCopy[id].annotation).filter(Boolean),
            type: AnnotationType.HIGHLIGHT,
          },
        }),
      );
    }
  }

  isNodeChecked({ id }: AnnotationListBonsaiNode): boolean {
    return !!this.highlightsSelected.find(hlId => hlId === id);
  }

  handleAllAnnotationsSelected(isChecked: boolean): void {
    this.allAnnotationsSelected = isChecked;
    this.highlightDropDownState() === DropdownState.ALL
      ? this.highlightSelectAll(isChecked)
      : this.highlightSelectDetached(isChecked);
  }

  highlightSelectAll(isChecked: boolean): void {
    if (!isChecked) {
      this.highlightsSelected = [];
      return;
    }
    this.highlightsSelected = Object.keys(this.treeDataCopy)
      .map(key => this.treeDataCopy[key].id)
      .filter(Boolean);
  }

  highlightSelectDetached(isChecked: boolean): void {
    if (!isChecked) {
      this.highlightsSelected = [];
      return;
    }
    this.highlightsSelected = Object
      .keys(this.treeDataCopy)
      .filter(key => Boolean(this.treeDataCopy[key].annotation?.orphan))
      .map(key => this.treeDataCopy[key].id);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
