import { Book, DoubleSpineItem, FlatTocItem } from '@mhe/reader/models';

export const EPUB_STATE_KEY = 'epub';

export interface EpubState {
  book?: Book
  flatToc: Record<number, FlatTocItem>
  /**
   * TODO: mapping properties set in epub.service:ePubProcess to state (somehow) so they can be accessed.
   * pieces of this may need to be moved to reader.component-state, depending on scope
   */
  // metadata
  doubleSpines?: DoubleSpineItem[]
  isDoubleSpread: boolean
  isFixedLayout: boolean
  isScaled: boolean
  lackSpineId: boolean

  // loading
  loading: boolean
  error?: Error
}

export const initialEpubState: EpubState = {
  book: undefined,
  flatToc: {},
  // metadata
  doubleSpines: undefined,
  isDoubleSpread: false,
  isFixedLayout: false,
  isScaled: true,
  lackSpineId: false,
  // loading
  loading: false,
};

export type EpubDictionaryState = Record<string, EpubState>;

export interface PartialEpubState {
  [EPUB_STATE_KEY]: EpubDictionaryState
}

export const initialState: EpubDictionaryState = {};
