import { Provider } from '@angular/core';
import { IframeBuilderService } from './iframe-builder.service';
import { IframeCoreService } from './iframe-core.service';
import { IframeInjectorService } from './iframe-injector.service';
import { MockAppConfigService } from './mockAppConfig.service';
import { MockAuthService } from './mockAuth.service';
import { MockLocalizedCssService } from './mockLocalizedCss.service';

export const IFRAME_PROVIDERS: Provider[] = [
  IframeBuilderService,
  IframeCoreService,
  IframeInjectorService,
  MockAppConfigService,
  MockAuthService,
  MockLocalizedCssService,
];
