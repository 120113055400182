import { Injectable, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';
import { ReaderConfigStore } from '../components/reader/state';
import { firstValueFrom } from 'rxjs';

export enum AttributeName {
  Alt = 'alt',
  AriaLabel = 'aria-label',
  DataHref = 'data-href',
  DataHrefUrl = 'data-href-url',
  DataLongDescription = 'data-long-description',
  EpubType = 'epub:type',
  Href = 'href',
  Id = 'id',
  Label = 'label',
  Style = 'style',
  Target = 'target',
  Title = 'title',
}

export enum AttributeValue {
  VoidUrl = 'javascript:void(0);',
  _Blank = '_blank',
}

enum PropertyName {
  Display = 'display',
}

enum PropertyValue {
  Block = 'block',
  None = 'none',
}

export enum ReferenceType {
  Glossary = 'glossref',
  Footnote = 'noteref',
}

export enum TagName {
  A = 'a',
  Body = 'body',
  Dfn = 'dfn',
  Div = 'div',
  Figure = 'figure',
  Img = 'img',
}

@Injectable()
export class EnhanceLinksTransformer implements Transformer {
  constructor(
    protected window: Window,
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService,
    private readonly readerConfigStore: ReaderConfigStore,
  ) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: Document,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<Document> {
    const links = await firstValueFrom(this.readerConfigStore.links$);
    this.enhanceLinks(content, spineItem, links);
    return await next(content);
  }

  enhanceLinks(document: Document, spineItem: SpineItem, links: boolean): void {
    this.getAnchors(document).forEach((anchor: HTMLAnchorElement) => {
      if (!links) {
        this.renderer.addClass(anchor, 'disable-link');
      }

      if (this.hasHref(anchor)) {
        this.handleAnchorWithHref(anchor, spineItem);
      }

      if (this.isCreditLink(anchor)) {
        return this.enhanceCreditLink(anchor);
      }

      if (this.isFootnoteLink(this.getEpubType(anchor) as string)) {
        return this.enhanceFootnoteLink(anchor);
      }

      if (this.isGlossaryLink(this.getEpubType(anchor) as string)) {
        return this.enhanceGlossaryLink(anchor);
      }

      if (this.isLongDescriptionLink(anchor)) {
        return this.enhanceLongDescriptionLink(anchor, document);
      }
    });
  }

  hasHref(anchor: HTMLAnchorElement): boolean {
    return anchor.hasAttribute(AttributeName.Href);
  }

  handleAnchorWithHref(
    anchor: HTMLAnchorElement,
    spineItem: SpineItem,
  ): HTMLAnchorElement | undefined {
    const originalHref = anchor.getAttribute(AttributeName.Href) as string;
    const newHref = anchor.getAttribute(AttributeName.Href);

    // default the URL to be non-operational in case the URL is invalid
    let originalHrefUrl: string | AttributeValue.VoidUrl =
      AttributeValue.VoidUrl;

    try {
      const url = new URL(newHref as string, spineItem.url);
      originalHrefUrl = url.href;
    } catch (error) {
      // @todo might make sense to have a NewRelic service in the future :)
      if ((this.window as any).newrelic) {
        (this.window as any).newrelic.addPageAction('reader-broken-link', {
          spineId: spineItem.id,
          spineIndex: spineItem.index,
          spineUrl: spineItem.url,
          brokenLink: originalHref,
        });
      }
    }

    this.renderer.setAttribute(anchor, AttributeName.DataHref, originalHref);
    this.renderer.setAttribute(
      anchor,
      AttributeName.DataHrefUrl,
      originalHrefUrl,
    );

    if (this.isExternalLink(originalHref)) {
      return this.enhanceExternalLink(anchor);
    }

    this.enhanceInternalLink(anchor);
  }

  getAnchors(document: Document): HTMLAnchorElement[] {
    return Array.from(document.getElementsByTagName(TagName.A));
  }

  isExternalLink(originalHref: string): boolean {
    return Boolean(originalHref.match(/^https?:\/\/|\/\/|^(.*):/));
  }

  enhanceExternalLink(anchor: HTMLAnchorElement): HTMLAnchorElement {
    this.renderer.setAttribute(
      anchor,
      AttributeName.Target,
      AttributeValue._Blank,
    );
    this.renderer.setAttribute(
      anchor,
      AttributeName.AriaLabel,
      `${anchor.innerText} ${this.translateService.instant(
        'link.open_external',
      )}`,
    );

    return anchor;
  }

  enhanceInternalLink(anchor: HTMLAnchorElement): void {
    this.renderer.setAttribute(
      anchor,
      AttributeName.Href,
      AttributeValue.VoidUrl,
    );
  }

  getEpubType(anchor: HTMLAnchorElement): string | null {
    return anchor.getAttribute(AttributeName.EpubType);
  }

  isCreditLink(anchor: HTMLAnchorElement): boolean {
    return Boolean(anchor.className.match(/^mhe..*credit?(s|.)-link$/gi));
  }

  enhanceCreditLink(anchor: HTMLAnchorElement): void {
    const widgetCreditLinkLabel =
      this.getCreditLinkLabelFromWidgetTitle(anchor);
    this.renderer.setAttribute(
      anchor,
      AttributeName.AriaLabel,
      widgetCreditLinkLabel
        ? `${widgetCreditLinkLabel} ${this.getAnnouncementText(anchor)}`
        : this.getAnnouncementText(anchor),
    );
    this.renderer.removeStyle(anchor, PropertyName.Display);
  }

  isFootnoteLink(epubType: string): boolean {
    return epubType === ReferenceType.Footnote;
  }

  enhanceFootnoteLink(anchor: HTMLAnchorElement): void {
    this.renderer.setAttribute(
      anchor,
      AttributeName.Title,
      this.translateService.instant('link.open_modal'),
    );
  }

  isGlossaryLink(epubType: string): boolean {
    return epubType === ReferenceType.Glossary;
  }

  enhanceGlossaryLink(anchor: HTMLAnchorElement): void {
    this.renderer.setAttribute(
      anchor,
      AttributeName.AriaLabel,
      this.getAnnouncementText(anchor),
    );

    const definitionElements = anchor.getElementsByTagName(TagName.Dfn);

    Array.from(definitionElements).forEach((definitionElement) => {
      definitionElement.removeAttribute(AttributeName.Title);
    });
  }

  isLongDescriptionLink(anchor: HTMLAnchorElement): boolean {
    return Boolean(
      anchor.className.match(/^mhe..*long-description?(s|.)-link$/gi),
    );
  }

  enhanceLongDescriptionLink(
    anchor: HTMLAnchorElement,
    document?: Document,
  ): void {
    const anchorLabel = this.longDescriptionLabelFromImgInsideFigure(anchor);
    this.renderer.setAttribute(
      anchor,
      AttributeName.AriaLabel,
      anchorLabel
        ? `${anchorLabel} ${this.getAnnouncementText(anchor)}`
        : this.getAnnouncementText(anchor),
    );
    this.renderer.setStyle(anchor, PropertyName.Display, PropertyValue.Block);

    const textNodeId = anchor
      .getAttribute(AttributeName.DataHref)
      ?.substring(1) as string;
    const textNode = document?.getElementById(textNodeId);

    if (textNode) {
      const text = textNode.innerHTML;
      this.renderer.setStyle(
        textNode,
        PropertyName.Display,
        PropertyValue.None,
      );
      this.renderer.setAttribute(
        anchor,
        AttributeName.DataLongDescription,
        text,
      );
    }
  }

  longDescriptionLabelFromImgInsideFigure(
    anchorNode: HTMLElement,
  ): string | undefined {
    const textNodeParent = anchorNode.parentElement as HTMLElement;
    if (textNodeParent.tagName.toLowerCase() !== 'figure') return;

    const figureImage = Array.from(textNodeParent.getElementsByTagName('img'));
    if (!figureImage.length || figureImage.length > 1) return;

    return figureImage.pop()?.alt;
  }

  getCreditLinkLabelFromWidgetTitle(
    anchor: HTMLAnchorElement,
  ): string | null | undefined {
    const relatedWidget = anchor.parentElement?.querySelector('iframe');
    return relatedWidget?.attributes?.getNamedItem('title')
      ? relatedWidget?.attributes?.getNamedItem('title')?.value
      : null;
  }

  getAnnouncementText(anchor: HTMLAnchorElement): string {
    return `${anchor.innerText} ${this.translateService.instant(
      'link.open_modal',
    )}`;
  }
}
