export const OverflowMenuActionsArr = [
  'clear-highlights-all',
  'clear-highlights-pg',
  'clear-widget-data-all',
  'clear-widget-data-pg',
  'teacher-content',
] as const;

export type OverflowMenuActions = typeof OverflowMenuActionsArr[number];

export type OverflowMenuActionsOptions =
  'clear-highlights-all'
  | 'clear-highlights-pg'
  | 'clear-widget-data-all'
  | 'clear-widget-data-pg'
  | 'quick-highlighting'
  | 'teacher-content';

export function isOverflowMenuAction(val: any): val is OverflowMenuActions {
  return OverflowMenuActionsArr.includes(val);
}
