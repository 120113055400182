/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, map, mapTo, tap } from 'rxjs/operators';

import { ReaderStore } from '@mhe/reader/components/reader/state';
import { ReadiatorBridgeService } from '@mhe/reader/features/readiator-bridge';
import * as readerActions from '../components/reader/state/reader.actions';
import {
  EpubViewerFontSizeStore,
  EpubViewerSearchHighlightStore,
  EpubViewerStore,
} from '@mhe/reader/components/epub-viewer';
import { FontResizerStore } from '@mhe/reader/components/font-resizer';
import * as fontResizerActions from '@mhe/reader/components/font-resizer/state/font-resizer.actions';
import { SearchStore } from '@mhe/reader/components/search';
import * as searchActions from '@mhe/reader/components/search/state/search.actions';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { UserSettingsStore } from '@mhe/reader/features/user-settings';
import * as userSettingsActions from '@mhe/reader/features/user-settings/state/user-settings.actions';
import { isSpineFpl } from '@mhe/reader/utils';
import { TTSStore } from '@mhe/reader/components/text-to-speech';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';

@Injectable()
export class RenderEnhancementMediator extends ComponentEffects {
  private readonly fontResizerActions$ = this.fontResizerStore.actions$;
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly searchActions$ = this.searchStore.actions$;
  private readonly navigationActions$ = this.navigationStore.actions$;

  constructor(
    private readonly epubViewerFontSizeStore: EpubViewerFontSizeStore,
    private readonly epubViewerSearchHighlightStore: EpubViewerSearchHighlightStore,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly fontResizerStore: FontResizerStore,
    private readonly navigationStore: NavigationStore,
    private readonly readerStore: ReaderStore,
    private readonly readiatorBridge: ReadiatorBridgeService,
    private readonly searchStore: SearchStore,
    private readonly ttsStore: TTSStore,
    private readonly userSettingsStore: UserSettingsStore,
  ) {
    super();
  }

  /** font size */
  private readonly fontsize$ = this.effect(() =>
    this.fontResizerActions$.pipe(
      ofType(fontResizerActions.fontsizeChanged),
      tap(({ fontsize }) => this.epubViewerFontSizeStore.setFontSize(fontsize)),
      tap(({ fontsize }) =>
        this.epubViewerStore.dispatch(
          epubViewerActions.applyFontSize({ fontsize }),
        ),
      ),
      tap(({ fontsize }) => {
        this.userSettingsStore.dispatch(
          userSettingsActions.setUserSettings({
            userSettings: { fontSize: Number(fontsize) },
          }),
        );
      }),
      logCatchError('fontsize$'),
    ),
  );

  private readonly fixedPageFeatureToggles$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setSpineItem),
      map(({ spineItem }) => isSpineFpl(spineItem)),
      tap((fpl) => {
        this.fontResizerStore.setDisabled(fpl);
        this.epubViewerFontSizeStore.setDisabled(fpl);
        this.ttsStore.setDisabled(fpl);
        this.epubViewerStore.setIsFplSpine(fpl);
      }),
      logCatchError('fixedPageFeatureToggles$'),
    ),
  );

  /** search text */
  private readonly _highlightSearchText$ = this.effect(() =>
    this.searchActions$.pipe(
      ofType(searchActions.resultSelected),
      delay(50), // avoid conflicts with clear on navigation (_clearHighlightSearchText$)
      tap(({ result }) =>
        this.epubViewerSearchHighlightStore.setHighlightResult(result),
      ),
      map(({ result }) => epubViewerActions.highlightSearchResult({ result })),
      tap((action) => this.epubViewerStore.dispatch(action)),
      logCatchError('_highlightSearchText$'),
    ),
  );

  private readonly _clearHighlightSearchText$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(
        navigationActions.navigateTo,
        navigationActions.navigateDoubleSpreadTo,
      ),
      mapTo(epubViewerActions.highlightSearchResultReset()),
      tap((action) => this.epubViewerStore.dispatch(action)),
      logCatchError('_clearHighlightSearchText$'),
    ),
  );

  /** double spread */
  private readonly _isDoubleSpreadChanged$ = this.effect(() =>
    this.readerStore.isDoubleSpread$.pipe(
      tap((isDoubleSpread) =>
        this.epubViewerStore.setDoubleSpread(isDoubleSpread),
      ),
      logCatchError('_isDoubleSpreadChanged$'),
    ),
  );

  /** font-resizer readiator */
  private readonly _fontresizerReadiatorDisabled$ = this.effect(() => {
    const epubFpl$ = combineLatest([
      this.readerStore.isDoubleSpread$,
      this.readerStore.isFixedLayout$,
    ]).pipe(
      map(([isDoubleSpread, isFixedLayout]) => isDoubleSpread || isFixedLayout),
    );

    const spineFpl$ = this.readerActions$.pipe(
      ofType(readerActions.setSpineItem),
      map(({ spineItem }) => isSpineFpl(spineItem)),
    );

    return combineLatest([epubFpl$, spineFpl$]).pipe(
      map(([epubFpl, spineFpl]) => epubFpl || spineFpl),
      distinctUntilChanged(),
      tap((fpl) => this.readiatorBridge.onFontResizerDisabled(fpl)),
      logCatchError('_fontresizerReadiatorDisabled'),
    );
  });
}
