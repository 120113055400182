import { Provider } from '@angular/core';

import { ReadiatorBridgeService } from './readiator-bridge.service';
import { SeamlessBridgeService } from './seamless-bridge.service';
import { ReadiatorAwdIconService } from './readiator-awd-icon-service';
import { AleksLogoutService } from './aleks-logout-service';

export const READIATOR_BRIDGE_PROVIDERS: Provider[] = [
  ReadiatorBridgeService,
  SeamlessBridgeService,
  ReadiatorAwdIconService,
  AleksLogoutService,
];
