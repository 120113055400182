import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '../common';
import { PrintModalComponent } from '../components/modals/print-modal';

import { ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, mergeMap, of, switchMap, tap, throwError, withLatestFrom }
  from 'rxjs';
import { GoogleAnalyticsEvent, GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { MatDialog } from '@angular/material/dialog';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as printActions from '../components/reader/state/print.actions';
import { TocStore } from '../components/toc';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent, ConfirmationModalData }
  from '../components/modals/confirmation-modal';
import { PrintService } from '@mhe/reader/core/reader-api';
import { MediatorUtils } from './mediator-utils';
import { PdfRequest } from '@mhe/reader/models';

@Injectable()
export class PrintMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly tocStore: TocStore,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly printService: PrintService,
    private readonly utils: MediatorUtils,
    private readonly ga: GoogleAnalyticsService,
  ) {
    super();
  }

  private readonly printButtonClicked$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(printActions.printButtonClicked),
      withLatestFrom(this.tocStore.rootNodes$),
      exhaustMap(([_, nodes]) => {
        // Show chapter selection modal
        return this.dialog
          .open(PrintModalComponent, {
            data: {
              chapters: nodes.map((node) => {
                return { id: node.tocItem?.id, name: node.title };
              }),
            },
            height: '80%',
            width: '80%',
            ariaLabelledBy: 'print-modal-h2',
          })
          .afterClosed();
      }),
      filter((chapter) => chapter),
      exhaustMap((chapter) => {
        // Show generate pdf confirmation modal
        const confirmData: ConfirmationModalData = {
          closeText: this.translate.instant('shared.cancel'),
          confirmText: this.translate.instant('shared.ok'),
          content: this.translate.instant('dialog.generating_pdf_message'),
          title: this.translate.instant('dialog.title.generating_pdf'),
        };

        const confirmDialog$ = this.dialog
          .open(ConfirmationModalComponent, {
            data: confirmData,
          })
          .afterClosed();
        return confirmDialog$.pipe(
          mergeMap((ok) => {
            return of({ confirmed: ok, chapter });
          }),
        );
      }),
      filter(({ confirmed }) => confirmed),
      withLatestFrom(this.utils.readerApi$, this.utils.requestContext$,
        this.readerConfigStore.fallbackToDefaultPublish$),
      switchMap(([{ confirmed, chapter }, url,
        requestContext, fallbackToDefaultPublish]) => {
        const { contextID, platform, userID, epubReleaseUUID, epubVersion } = requestContext;

        let pdfRequest: Partial<PdfRequest> = {
          contextID,
          platform,
          userID,
          chapterId: chapter.id,
          chapterName: chapter.name,
          printedAt: new Date().toISOString(),
        };

        if (!fallbackToDefaultPublish) {
          pdfRequest = {
            ...pdfRequest,
            epubReleaseUUID,
            epubVersion,
          };
        }

        // Set print icon to loading spinner
        this.readerStore.setPdfLoading(true);

        // GA event
        const gaEvent: GoogleAnalyticsEvent = {
          eventCategory: 'Print ePub',
          eventAction: 'Generate PDF',
          eventLabel: `User: ${userID}, ePub: ${contextID}, Platform: ${platform}, ` +
            `ReleaseUUID: ${epubReleaseUUID}, Version: ${epubVersion}, ` +
            `chapterName: ${chapter.name}, chapterId: ${chapter.id}`,
        };

        // Call backend api service with chapter title
        return this.printService.getPdf(url, pdfRequest)
          .pipe(
            tap((pdfResponse) => {
              // write event to GA on success w/ value 1
              this.ga.event({ ...gaEvent, eventValue: 1 });
              this.readerStore.setPdfLoading(false);
              this.readerStore.setPdfUrl({
                url: pdfResponse.preSignedURL,
                title: chapter.name,
              });
            }),
            catchError(() => {
              // write event to GA on error w/ value 0
              this.ga.event({ ...gaEvent, eventValue: 0 });
              this.readerStore.setPdfLoading(false);
              this.readerStore.addAlert({
                alertType: 'alert-warning',
                translateKey: 'print.error',
              });
              return throwError(() => new Error('pdf generation error'));
            }),
          );
      }),
      logCatchError('printButtonClicked$'),
    ),
  );

  private readonly handlePrintAlertClose$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(printActions.showCloseAlertModal),
      exhaustMap(() => {
        const confirmData: ConfirmationModalData = {
          closeText: this.translate.instant('shared.cancel'),
          confirmText: this.translate.instant('shared.ok'),
          content: this.translate.instant(
            'dialog.are_you_sure.dismiss_print_file',
          ),
          title: this.translate.instant('dialog.title.print'),
        };

        const confirmDialog$ = this.dialog
          .open(ConfirmationModalComponent, {
            data: confirmData,
          })
          .afterClosed();
        return confirmDialog$.pipe(
          filter((ok) => ok),
          tap(() => {
            this.readerStore.setPdfUrl(undefined);
          }),
        );
      }),
      logCatchError('handlePrintAlertClose$'),
    ),
  );
}
