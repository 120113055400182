<ng-container *ngIf="showVoiceOptions">
  <ul
    class="readspeaker-voice-selection"
    [ngClass]="(docTTSControlReq$ | async) ? 'audio-controls-open' : 'audio-controls-closed'"
  >
    <li class="header">Voice Sampler</li>
    <li
      [ngClass]="{
        'readspeaker-voice-selection-opt': (isLoading$ | async) === false,
        'readspeaker-voice-selection-opt-selected': voice.name === (selectedVoice$ | async),
        'readspeaker-voice-selection-disabled': (isLoading$ | async)
      }"
      *ngFor="let voice of ttsVoices"
      (click)="onReadVoicePickClick(voice)"
    >
      {{ voice.display }}
    </li>
  </ul>
</ng-container>
<button
  class="tts-controls-button"
  mhe-button
  [purpose]="ButtonPurpose.Icon"
  data-automation-id="read-aloud-voice-button"
  [mheTooltip]="'readspeaker.read_aloud_voice' | translate"
  [placement]="'bottom'"
  (click)="onReadVoiceClick()"
>
  <i class="dpg-icon dpg-icon-cog"></i>
</button>
