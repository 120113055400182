import { ApiAnnotation, ApiSpine } from '@mhe/reader/models';
import { createReducer, on } from '@ngrx/store';

import {
  addAnnotation,
  addAnnotations,
  addAnnotationsBySpineId,
  clearAllAnnotations,
  exportSuccess,
  fetchDistinctSpineIdsSuccess,
  removeAnnotation,
  removeBatchAnnotations,
  removeHighlight,
  removeNote,
  setSpineItemsToSpineId,
  startExport,
  updateSpinesStatus,
} from './annotations.actions';
import { AnnotationsState, initialState } from './annotations.state';

function addAnnotationsToStateBySpineId(state, spineId, annotations): any {
  return {
    ...state.entitiesBySpineId,
    [spineId]: annotations.reduce(
      (previous, annotation): Record<string, ApiAnnotation> => ({
        ...previous,
        [annotation.resourceUUID]: annotation,
      }),
      { ...state.entitiesBySpineId[spineId] },
    ),
  };
}

function findSpineId(
  spines: Record<string, Record<string, ApiAnnotation>>,
  annotationId: string,
): string | undefined {
  for (const spineId in spines) {
    if (spines[spineId][annotationId]) {
      return spineId;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function annotationType(
  annotation: ApiAnnotation,
): 'placemark' | 'note' | 'highlight' | undefined {
  // if has placemarkText, it's a placemark
  // if has noteText, it's a note
  // if highlight = True
  if (annotation.placemarkText) {
    return 'placemark';
  }
  if (annotation.note) {
    return 'note';
  }
  if (annotation.highlight) {
    return 'highlight';
  }
}

function removeSingleAnnotation(
  state: AnnotationsState,
  spineID: string | undefined,
  resourceUUID: string,
): AnnotationsState {
  const newState = removeAnnotationById(state, spineID, resourceUUID);
  return {
    ...newState,
    spines: fixSpines(
      newState.entitiesBySpineId[spineID as string],
      spineID,
      newState,
    ),
  };
}

function fixSpines(
  annotations: Record<string, ApiAnnotation>,
  spineID,
  state: AnnotationsState,
): ApiSpine[] {
  // go to the spine
  // count annotations group by type
  // if 0, update the flag in state.spines
  let placemarks = 0;
  let notes = 0;
  let highlights = 0;
  for (const annotationId of Object.keys(annotations)) {
    const annotation = annotations[annotationId];
    if (annotation.placemarkText) {
      placemarks++;
    }
    if (annotation.note) {
      notes++;
    }
    if (annotation.highlight) {
      highlights++;
    }
  }
  const oldSpine: ApiSpine = state.spines.find(
    (s) => s.spineID === spineID,
  ) as ApiSpine;
  const newSpine = {
    spineID: oldSpine?.spineID || spineID,
    hasHighlight: highlights > 0,
    hasNote: notes > 0,
    hasPlacemark: placemarks > 0,
    // only add spineDataRequested if present on existing spine
    ...(oldSpine?.spineDataRequested && {
      spineDataRequested: oldSpine.spineDataRequested,
    }),
  };
  return state.spines
    .filter((s) => s.spineID !== spineID)
    .concat(newSpine as ApiSpine);
}

function removeAnnotationById(
  state: AnnotationsState,
  spineID,
  resourceUUID,
): AnnotationsState {
  const spines: Record<string, Record<string, ApiAnnotation>> = state.entitiesBySpineId;
  const annotations = { ...spines[spineID] };
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete annotations[resourceUUID];

  const fixedSpines = fixSpines(annotations, spineID, state);
  const newState = {
    ...state,
    entitiesBySpineId: {
      ...spines,
      [spineID]: annotations,
    },
    spines: fixedSpines,
  };
  return newState;
}

export const annotationsReducer = createReducer(
  initialState,
  on(addAnnotationsBySpineId, (state, { spineId, annotations, loaded }) => ({
    ...state,
    loaded,
    entitiesBySpineId: addAnnotationsToStateBySpineId(
      state,
      spineId,
      annotations,
    ),
  })),
  on(addAnnotations, (state, { annotations, loaded }) => ({
    ...state,
    loaded,
    entitiesBySpineId: annotations.reduce(
      (previous, annotation) => {
        return {
          ...previous,
          [annotation.spineID as string]: {
            ...previous[annotation.spineID as string],
            [annotation.resourceUUID]: annotation,
          },
        };
      },
      { ...state.entitiesBySpineId },
    ),
  })),
  on(addAnnotation, (state, { annotation }) => {
    const annotations = {
      ...state.entitiesBySpineId[annotation.spineID as string],
      [annotation.resourceUUID]: annotation,
    };
    return {
      ...state,
      entitiesBySpineId: {
        ...state.entitiesBySpineId,
        [annotation.spineID as string]: annotations,
      },
      spines: fixSpines(annotations, annotation.spineID, state),
    };
  }),
  on(
    removeAnnotation,
    (state: AnnotationsState, { annotation: { spineID, resourceUUID } }) => {
      return removeSingleAnnotation(state, spineID, resourceUUID);
    },
  ),
  on(
    removeBatchAnnotations,
    (state: AnnotationsState, { annotations }) => {
      let newState = state;
      annotations.forEach(annotation => {
        newState = removeSingleAnnotation(newState, annotation.spineID, annotation.resourceUUID);
      });
      return newState;
    },
  ),
  // TODO: this will probably go to an effect when we are storing to the api.
  on(removeHighlight, removeNote, (state: AnnotationsState, { id }) => {
    const spineId = findSpineId(state.entitiesBySpineId, id);
    const newState = spineId ? removeAnnotationById(state, spineId, id) : state;
    return {
      ...newState,
      spines: fixSpines(
        newState.entitiesBySpineId[spineId as string],
        spineId,
        newState,
      ),
    };
  }),
  on(clearAllAnnotations, (state) => ({
    ...state,
    loaded: false,
    progress: 0,
    spines: [],
    entitiesBySpineId: {},
  })),
  on(fetchDistinctSpineIdsSuccess, (state, { spines }) => ({
    ...state,
    spines,
    loaded: true,
  })),
  on(updateSpinesStatus, (state, { spineId }) => ({
    ...state,
    spines: state.spines.map((spine: ApiSpine) => {
      const cleanSpine = { ...spine };
      if (cleanSpine.spineID === spineId) {
        cleanSpine.spineDataRequested = true;
      }
      return cleanSpine;
    }),
  })),
  on(startExport, (state) => ({
    ...state,
    isExporting: true,
  })),
  on(exportSuccess, (state) => ({
    ...state,
    isExporting: false,
  })),
  on(setSpineItemsToSpineId, (state, { spineId, spineItems }) => {
    const matchSpineId = state.spines.find(spine => spine.spineID === spineId);
    if (!matchSpineId) {
      console.warn('setSpineItemsToSpineId', 'spine not found');
      return { ...state };
    }
    return {
      ...state,
      spines: state.spines.map(spine => {
        if (spine.spineID === spineId) {
          return {
            ...spine,
            spineItems,
          };
        }
        return spine;
      }),
    };
  }),
);
