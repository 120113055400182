import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ContentsMenuComponent } from './contents-menu.component';
import { ReaderMatTabTooltipDirective } from './tab-tooltip.directive';
import { TocModule } from '@mhe/reader/components/toc';
import { TopicsModule } from '@mhe/reader/components/topics';
import {
  HighlightListModule,
  NoteListModule,
  PlacemarkListModule,
} from '@mhe/reader/components/annotation-lists';

@NgModule({
  imports: [
    CommonModule,
    // material
    A11yModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    // ngx-shared
    ButtonModule,
    TooltipModule,
    TranslateModule,
    // reader features
    HighlightListModule,
    PlacemarkListModule,
    NoteListModule,
    TocModule,
    TopicsModule,
  ],
  declarations: [
    ContentsMenuComponent,
    ReaderMatTabTooltipDirective,
  ],
  exports: [ContentsMenuComponent],
})
export class ContentsMenuModule {}
