import {
  AnnotationListState,
  initialAnnotationListState,
} from '../../utils/annotation-list.state.abstract';

export interface NoteListState extends AnnotationListState {}

export const initialNoteListState: NoteListState = {
  ...initialAnnotationListState,
};
