import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBonsaiModule } from '@mhe/ngx-bonsai-reader';
import { AlertModule, ButtonModule, CheckboxModule, ModalModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TranslateModule } from '@ngx-translate/core';

import { NoteListComponent } from './note-list.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AnnotationListFooterComponent,
} from '../annotation-list-footer/annotation-list-footer.component';
import {
  AnnotationListHeaderComponent,
} from '../annotation-list-header/annotation-list-header.component';
import { AnnotationListModule } from '../annotation-list.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
    AlertModule,
    ButtonModule,
    TooltipModule,
    MatProgressSpinnerModule,
    ModalModule,
    CheckboxModule,
    AnnotationListModule,
    AnnotationListHeaderComponent,
    AnnotationListFooterComponent,
  ],
  declarations: [
    NoteListComponent,
  ],
  exports: [NoteListComponent],
})
export class NoteListModule {}
