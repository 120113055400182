import { Injectable, NgZone } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';

@Injectable()
export class BentoDigitalTaggingTransformer implements Transformer {
  constructor(private readonly transformStore: TransformStore, private readonly zone: NgZone) {}

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    this.zone.run(() => {
      this.transformStore.dispatch(transformActions.bentoDigitalTaggingInit({ content }));
    });

    return await next(content);
  }
}
