import { Injectable, NgZone } from '@angular/core';

import { Book, SpineItem } from '../models/epub';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { Next, Transformer } from './transformer';

@Injectable()
export class ReaderRolesTransformer implements Transformer {
  constructor(private readonly transformStore: TransformStore, private readonly zone: NgZone) {}

  async preRender(
    _book: Book,
    _spineItem: SpineItem,
    content: HTMLDocument,
    _iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const launch = transformActions.userRole({ content });
    this.zone.run(() => this.transformStore.dispatch(launch));

    return await next(content);
  }

  async postRender(
    _book: Book,
    _spineItem: SpineItem,
    content: HTMLDocument,
    _iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const teacherContentChange = transformActions.subscribeTeacherContentChange({ content });
    this.zone.run(() => this.transformStore.dispatch(teacherContentChange));

    return await next(content);
  }
}
