import { createAction } from '@ngrx/store';

export const conceptNavPrevBtnTriggered = createAction(
  'Concept Navigator Prev Button Triggered',
);
export const conceptNavCurBtnTriggered = createAction(
  'Concept Navigator Current Button Triggered',
);
export const conceptNavNextBtnTriggered = createAction(
  'Concept Navigator Next Button Triggered',
);

export type conceptNavigatorActions = ReturnType<
| typeof conceptNavPrevBtnTriggered
| typeof conceptNavCurBtnTriggered
| typeof conceptNavNextBtnTriggered
>;
