import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { FORCE_RENDER_HASH, FORCE_REFRESH_HASH } from '@mhe/reader/models';
import { renderDoublePane } from '../epub-viewer.actions';

type doublesource = ReturnType<typeof renderDoublePane>;

export const renderDistinct =
  (withHash: (hash: string, setFocus: boolean, spineItem: any) => void) =>
    (source$) =>
      source$.pipe(
        distinctUntilChanged(
          ({ spineItem: a }, { spineItem: b, hash, setFocus }) => {
          /**
           * This is used to force a rerender of the entire iFrame.
           */
            if (hash === FORCE_REFRESH_HASH) {
              return false;
            }

            const isSame =
            a.idref === b.idref && hash !== `#${FORCE_RENDER_HASH}`;

            if (isSame && hash && withHash) {
              withHash(hash, setFocus as boolean, a);
            }

            return isSame;
          },
        ),
      );

export const renderDoubleDistinct = (
  source$: Observable<doublesource>,
): Observable<doublesource> =>
  source$.pipe(
    distinctUntilChanged(({ doubleSpineItem: a }, { doubleSpineItem: b }) => {
      const { left: al, right: ar } = a;
      const { left: bl, right: br } = b;

      const isSame = al?.idref === bl?.idref && ar?.idref === br?.idref;
      return isSame;
    }),
  );
