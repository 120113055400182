import { createAction, props } from '@ngrx/store';

import { DFASearchParams, Payload, TagLocation } from '@mhe/reader/models';

export enum TopicsActionTypes {
  Init = '[Topics] Init',
  NavigateToTopic = '[Topics] Navigate To Topic',
  EmitReadiatorEvent = '[Reader][Topics] Emit Readiator Event',
  GetLocations = '[Reader][Topics] GetLocations',
  SelectInternalLocation = '[Reader][Topics] Select Internal Location',
  SetSelectedLocation = '[Reader][Topics] Set Selected Location',
  SetTopicInToc = '[Reader][Topics] Set Topic in Toc',
  DFASearchLaunch = '[Reader][Topics] DFA Search Launch',
  SetErrorLoadingSubjects = '[Reader][Topics] Set Error Loading Subjects',
}

export const init = createAction(TopicsActionTypes.Init);
export const navigateToTopic = createAction(TopicsActionTypes.NavigateToTopic);

export const emitReadiatorEvent = createAction(
  TopicsActionTypes.EmitReadiatorEvent,
  props<{ payload: Payload }>(),
);
export const getLocations = createAction(TopicsActionTypes.GetLocations, props<{ uuid: string }>());
export const selectInternalLocation = createAction(
  TopicsActionTypes.SelectInternalLocation,
  props<{ location: any }>(),
);
export const setSelectedLocation = createAction(
  TopicsActionTypes.SetSelectedLocation,
  props<{ location: TagLocation | null }>(),
);
export const setTopicInToc = createAction(
  TopicsActionTypes.SetTopicInToc,
  props<{ location: TagLocation | null }>(),
);
export const dfaSearchLaunch = createAction(
  TopicsActionTypes.DFASearchLaunch,
  props<{ params: DFASearchParams }>(),
);
export const setErrorLoadingSubjects = createAction(
  TopicsActionTypes.SetErrorLoadingSubjects,
);

export type TopicsActions = ReturnType<
| typeof init
| typeof navigateToTopic
| typeof emitReadiatorEvent
| typeof getLocations
| typeof selectInternalLocation
| typeof setSelectedLocation
| typeof setTopicInToc
| typeof dfaSearchLaunch
| typeof setErrorLoadingSubjects
>;
