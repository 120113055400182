import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { Observable } from 'rxjs';
import {
  HighlightColorClass,
  HighlightShapeClass,
  ApiAnnotation,
} from '@mhe/reader/models';

import {
  HighlightColor,
  HighlightShape,
  OptionsPane,
} from '../annotations-context-menu.model';
import { AnnotationsContextMenuActions } from './annotations-context-menu.actions';

import {
  AnnotationsContextMenuState,
  initialAnnoationsContextMenuState,
} from './annotations-context-menu.state';

@Injectable()
export class AnnotationsContextMenuStore extends ExtendedComponentStore<
AnnotationsContextMenuState,
AnnotationsContextMenuActions
> {
  constructor() {
    super(initialAnnoationsContextMenuState);
  }

  /** selectors */
  contextMenu$: Observable<AnnotationsContextMenuState> = this.select(
    (state) => state,
  );

  annotation$: Observable<ApiAnnotation> = this.select(
    ({ annotation }) => annotation as ApiAnnotation,
  );

  // highlighting
  highlightShape$: Observable<HighlightShape> = this.select(
    this.annotation$,
    (annotation) =>
      getHighlightShape(
        annotation?.shape as HighlightShapeClass,
      ) as HighlightShape,
  );

  highlightColor$: Observable<HighlightColor> = this.select(
    this.annotation$,
    (annotation) => getHighlightColor(annotation?.color as HighlightColorClass),
  );

  highlightColorAndShape$: Observable<{
    shape: HighlightShapeClass
    color: HighlightColorClass
  }> = this.select(this.contextMenu$, (state) => ({
      shape: state?.lastHighlightShapeSelected as HighlightShapeClass,
      color: state?.lastOrDefaultHighlightColorSelected,
    }));

  defaultHighlightColor$: Observable<HighlightColorClass> = this.select(
    (state) => state.lastOrDefaultHighlightColorSelected,
  );

  placemarkText$: Observable<string> = this.select(
    this.annotation$,
    (annotation) => annotation?.placemarkText as string,
  );

  note$: Observable<string> = this.select(
    this.annotation$,
    (annotation) => annotation?.note || '',
  );

  noteMaxCharacters$: Observable<number> = this.select(
    (state) => state.noteMaxCharacters,
  );

  colorOptions$: Observable<HighlightColor[]> = this.select(
    (state) => state.colorOptions,
  );

  visibleOptionsPane$: Observable<OptionsPane> = this.select(
    (state) => state.visibleOptionsPane as OptionsPane,
  );

  /** updaters */
  setAnnotation = this.updater((state, annotation: ApiAnnotation) => ({
    ...state,
    annotation,
  }));

  setHighlightColor = this.updater((state, highlightColor: HighlightColor) => {
    const color = getHighlightColorClass(highlightColor) as HighlightColorClass;
    return {
      ...state,
      lastOrDefaultHighlightColorSelected:
        highlightColor === 'none'
          ? state.lastOrDefaultHighlightColorSelected
          : color,
      annotation: {
        ...(state?.annotation as ApiAnnotation),
        color,
        highlight: !!color && !!state.annotation?.shape,
      },
    };
  });

  setHighlightShape = this.updater((state, highlightShape: HighlightShape) => {
    const shape = getHighlightShapeClass(highlightShape);
    return {
      ...state,
      lastHighlightShapeSelected: shape,
      annotation: {
        ...(state?.annotation as ApiAnnotation),
        shape,
        highlight: !!shape && !!state.annotation?.color,
      },
    };
  });

  togglePlacemark = this.updater((state) => {
    if ((state.annotation?.placemarkText as string)?.length > 0) {
      return {
        ...state,
        annotation: {
          ...(state.annotation as ApiAnnotation),
          placemarkText: undefined,
        },
      };
    }
    return {
      ...state,
      annotation: {
        ...(state.annotation as ApiAnnotation),
        placemarkText: state.annotation?.text,
      },
    };
  });

  setNote = this.updater((state, note: string) => ({
    ...state,
    annotation: {
      ...(state.annotation as ApiAnnotation),
      note,
    },
  }));

  showOptionsPane = this.updater((state, visibleOptionsPane: OptionsPane) => ({
    ...state,
    visibleOptionsPane,
  }));

  reset = this.updater(
    ({ lastOrDefaultHighlightColorSelected, lastHighlightShapeSelected }) => ({
      ...initialAnnoationsContextMenuState,
      lastOrDefaultHighlightColorSelected,
      lastHighlightShapeSelected,
    }),
  );

  /** effects */
}

export function getHighlightColorClass(
  color: HighlightColor,
): HighlightColorClass | undefined {
  switch (color) {
    case 'default':
    case 'yellow':
      return 'yellow-highlight';
    case 'pink':
      return 'pink-highlight';
    case 'green':
      return 'green-highlight';
    case 'blue':
      return 'blue-highlight';
  }
  return undefined;
}

export function getHighlightColor(color: HighlightColorClass): HighlightColor {
  switch (color) {
    case 'yellow-highlight':
      return 'yellow';
    case 'pink-highlight':
      return 'pink';
    case 'green-highlight':
      return 'green';
    case 'blue-highlight':
      return 'blue';
  }
  return 'none';
}

export function getHighlightShapeClass(
  shape: HighlightShape,
): HighlightShapeClass | undefined {
  switch (shape) {
    case 'default':
      return 'epr-shape-square-filled';
    case 'circle':
      return 'epr-shape-round-outline';
    case 'box':
      return 'epr-shape-square-outline';
    case 'underline':
      return 'epr-shape-underline';
  }
  return undefined;
}

export function getHighlightShape(
  shapeClass: HighlightShapeClass,
): HighlightShape | undefined {
  switch (shapeClass) {
    case 'epr-shape-square-filled':
      return 'default';
    case 'epr-shape-round-outline':
      return 'circle';
    case 'epr-shape-square-outline':
      return 'box';
    case 'epr-shape-underline':
      return 'underline';
  }
  return undefined;
}
