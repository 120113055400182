import { Manifest, SpineItem } from '@mhe/reader/models';
import { SupportedType } from '../types/supported-types.type';

export function spineItemsByHref(spine: SpineItem[]): Record<string, number> {
  return spine.reduce<Record<string, number>>((byHref, s, i) => {
    byHref[s.href as string] = i;
    return byHref;
  }, {});
}

export function spineItemsById(spine: SpineItem[]): Record<string, number> {
  return spine.reduce<Record<string, number>>((byHref, s, i) => {
    byHref[s.idref] = i;
    return byHref;
  }, {});
}

export function mapSpineItemsByContentType(
  spine: SpineItem[] = [],
  manifest: Manifest,
  types: SupportedType[] = ['application/xhtml+xml'],
): SpineItem[] {
  const spineContent = spine.filter((si) => {
    const mi = manifest[si.idref];
    const acceptedType = Boolean(types.find((t) => t === mi?.type));

    return acceptedType;
  });

  return spineContent;
}

export function isSpineFpl(spine: SpineItem): boolean {
  const { properties = [] } = spine;
  const spinePrePaginated = properties.includes('rendition:layout-pre-paginated');

  return spinePrePaginated;
}
