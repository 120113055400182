import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Tag } from '@mhe/reader/models';

import { ReducedModeUtils } from '@mhe/reader/core/utils';

@Injectable()
export class DFAService {
  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  // DFA establishes the global session (TASS) and provides the JWT during LTI launch (via `custom_user_idm_token`) to Reader.
  // We use the same JWT to talk to any backend APIs that accept a TASS token.
  requestSubjectsOrTags(endpoint: string, parentUuid?: string): Observable<Tag[]> {
    const url = parentUuid ? `${endpoint}${parentUuid}` : endpoint;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.get<Tag[]>(url),
    );
  }
}
