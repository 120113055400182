<div class="viewer-container" role="dialog" aria-labelledby="glossary-modal-h2">
  <rdrx-modal-header (closeEvent)="dialogRef.close()">
    <h2 class="modal-header" id="glossary-modal-h2">{{ 'dialog.title.glossary' | translate }}</h2>
  </rdrx-modal-header>

  <!-- TODO(i18n): Translate -->
  <div class="glossary-loading" *ngIf="isLoading$ | async">Loading your glossary item...</div>
  <div class="body" *ngIf="glossaryItem$ | async as glossaryItem">
    <ng-container *ngIf="glossaryItem.definitionNodes.length > 1">
      <form id="dropdown-form" #inputForm="ngForm" class="glossary-language-dropdown">
        <label
          data-automation-id="language-dropdown-label"
          class="glossary-language-label"
          [for]="languageDropdown.buttonId"
          >{{ 'dialog.glossary_language_label' | translate }}</label
        >
        <mhe-dropdown
          id="language-dropdown"
          #languageDropdown
          [multiple]="false"
          [options]="languageOptions"
          (selectionChange)="changeLanguage($event)"
          [selected]="selectedLanguage"
          [toggleButtonAriaLabel]=""
          [listboxLabel]="'language options'"
        ></mhe-dropdown>
      </form>
    </ng-container>

    <div *ngIf="glossaryItem.definitionNodes.length === 1" class="glossary-term">
      <h3>{{ glossaryItem.term }}</h3>
    </div>
    <div
      class="glossary-definition"
      [innerHTML]="glossaryItem.definitionNodes[translationIndex].innerHTML"
      [attr.lang]="glossaryItem.definitionNodes[translationIndex].getAttribute('lang')"
    ></div>
  </div>

  <div class="glossary-error" *ngIf="error$ | async">
    <mhe-alert [type]="alertType.Error" [closable]="false">{{
      'dialog.failed_glossary_term' | translate
    }}</mhe-alert>
  </div>
</div>
