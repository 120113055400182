import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { enUS } from './en-US';
import { esES } from './es-ES';

@Injectable()
export class LocalTranslateLoader implements TranslateLoader {
  private readonly translationMap = {
    'en-US': enUS,
    'es-ES': esES,
  };

  constructor(private readonly translateService: TranslateService) {}

  getTranslation(lang: string): Observable<any> {
    const locale = this.standardizeLocale(lang);
    const translations = this.translationMap[locale];

    const defaultLang = this.standardizeLocale(this.translateService.getDefaultLang());
    const defaultTranslations = this.translationMap[defaultLang] ?? enUS;

    return of(translations || defaultTranslations);
  }

  standardizeLocale(lang: string): string {
    if (!lang) {
      return 'en-US';
    }

    const defaultRegionMap = {
      en: 'US',
      es: 'ES',
    };
    const availableRegionsMap: Record<string, string[]> = {
      en: ['US'],
      es: ['ES'],
    };
    const [language, region] = lang.replace('_', '-').split('-');
    const defaultRegionForLanguage = defaultRegionMap[(language || '').toLowerCase()] || '';
    const isRegionAvailableForLanguage = availableRegionsMap[language]?.includes(region);
    const r = isRegionAvailableForLanguage ? region : defaultRegionForLanguage;
    return `${language.toLowerCase()}-${r?.toUpperCase()}`;
  }
}
