import { Injectable } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class SetBaseTransformer implements Transformer {
  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const baseElements = content.head.querySelectorAll('base');
    baseElements.forEach((e) => e.remove());
    const newBase = content.createElement('base');
    newBase.href = spineItem.url as string;
    content.head.prepend(newBase);
    return await next(content);
  }
}
