/* eslint-disable max-len */
/* eslint-disable accessor-pairs */
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import { DeviceService, ComponentEffects } from '@mhe/reader/common';
import {
  EpubMoveDirection,
  isReaderAlertType,
  ReaderAlert,
  ReaderAlertGrouped,
  ReaderAlertType,
} from '@mhe/reader/models';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import * as printActions from '@mhe/reader/components/reader/state/print.actions';
import { NavigationStore } from '@mhe/reader/components/navigation/state';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { groupBy } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ANNOTATIONS_CONTEXT_MENU_PROVIDERS } from '@mhe/reader/components/annotations-context-menu';

import { AnnotationsService } from '@mhe/reader/core/reader-api/annotations.service';
import { SpinesService } from '@mhe/reader/core/reader-api/spines.service';
import { DFAService } from '@mhe/reader/core/dfa-api/dfa.service';
import { LastLocationService } from '@mhe/reader/core/reader-api/last-location.service';
import { UserSettingsService } from '@mhe/reader/core/reader-api/user-settings.service';
import { LogService } from '@mhe/reader/core/reader-api/log.service';
import { WidgetsApiService } from '@mhe/reader/core/reader-api/widgets-api.service';
import { PrintService } from '@mhe/reader/core/reader-api/print.service';
import { ConfigurationState } from '@mhe/reader/core/state/configuration';
import { ReducedModeUtils } from '@mhe/reader/core/utils/reduced-mode-utils';
import {
  MEDIATOR_PROVIDERS,
  getMediatorProviders,
  ElasticSearchService,
} from '@mhe/reader/mediators';
import { MobileLaunchService } from '@mhe/reader/features/mobile-launch';
import { CUSTOM_DIALOG_PROVIDERS } from '@mhe/reader/features/reader-overlay/reader-overlay-container.providers';
import { ReaderOverlayContainerService } from '@mhe/reader/features/reader-overlay/reader-overlay-container.service';
import { READIATOR_BRIDGE_PROVIDERS } from '@mhe/reader/features/readiator-bridge';
import { ReaderConfigStore, ReaderStore } from './state';
import * as assignmentActions from './state/assignment.actions';
import * as readerActions from './state/reader.actions';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import {
  EPUB_VIEWER_PROVIDERS,
  EpubViewerComponent,
  EpubViewerStore,
} from '@mhe/reader/components/epub-viewer';
import {
  AudioElementService,
  FloatingTtsControlsService,
  TTSStore,
} from '@mhe/reader/components/text-to-speech';
import { FontResizerStore } from '@mhe/reader/components/font-resizer';
import { IconRegistryService } from '@mhe/reader/features/icons';
import { IFRAME_PROVIDERS } from '@mhe/reader/features/iframe';
import { EPubService } from '@mhe/reader/features/epub-loader';
import {
  HighlightListStore,
  NoteListStore,
  PlacemarkListStore,
} from '@mhe/reader/components/annotation-lists';
import { SEARCH_PROVIDERS } from '@mhe/reader/components/search';
import { ConceptNavigatorStore } from '@mhe/reader/components/concept-navigator';
import { TRANSFORM_PROVIDERS } from '@mhe/reader/transform/transform.providers';
import { GlossaryModalStore } from '@mhe/reader/components/modals/glossary-modal';
import { UserSettingsStore } from '@mhe/reader/features/user-settings';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { LastLocationStore } from '@mhe/reader/features/last-location';
import { TocStore } from '@mhe/reader/components/toc';
import { ANNOTATION_PROVIDERS } from '@mhe/reader/features/annotation';
import { ReaderTokenService } from '@mhe/reader/tokens';

@Component({
  selector: 'reader-core-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss'],
  providers: [
    AnnotationsService,
    SpinesService,
    DFAService,
    ConceptNavigatorStore,
    EPubService,
    ElasticSearchService,
    PrintService,
    FontResizerStore,
    GlossaryModalStore,
    HighlightListStore,
    LastLocationService,
    UserSettingsService,
    LastLocationStore,
    UserSettingsStore,
    LogService,
    NavigationStore,
    NoteListStore,
    Overlay,
    PlacemarkListStore,
    ReducedModeUtils,
    TocStore,
    TopicsStore,
    TTSStore,
    AudioElementService,
    FloatingTtsControlsService,
    MobileLaunchService,
    WidgetsApiService,
    DeviceService,
    ...ANNOTATION_PROVIDERS,
    ...ANNOTATIONS_CONTEXT_MENU_PROVIDERS,
    ...CUSTOM_DIALOG_PROVIDERS,
    ...EPUB_VIEWER_PROVIDERS,
    ...IFRAME_PROVIDERS,
    ...READIATOR_BRIDGE_PROVIDERS,
    ...SEARCH_PROVIDERS,
    ...TRANSFORM_PROVIDERS,
    ...getMediatorProviders(),
  ],
})
export class ReaderComponent implements OnInit, OnDestroy {
  @Input() epubUrl: string | undefined = undefined;
  @Input() initialSpineId: string;
  @Input() initialHash: string;

  @Input() set config(value: ConfigurationState) {
    this.readerConfigStore.setConfig(value);
  }

  @Input() set integrateRouting(value: boolean) {
    this.readerStore.setIntegrateRouting(value);
  }

  @ViewChild(EpubViewerComponent) epubViewer: EpubViewerComponent;

  private readonly destroy$ = new Subject<void>();

  private showDigitalSampleAlert = true;

  readonly book$ = this.readerStore.book$;
  readonly loading$ = this.readerStore.loading$;
  readonly error$ = this.readerStore.error$;
  readonly documentRequestError$ = this.epubViewerStore.documentRequestError$;
  readonly albumMode$ = this.epubViewerStore.albumMode$;
  readonly epubZoomLevel$ = this.epubViewerStore.zoomLevel$;
  readonly increaseEpubZoomEnabled$ =
    this.epubViewerStore.increaseEpubZoomEnabled$;

  readonly decreaseEpubZoomEnabled$ =
    this.epubViewerStore.decreaseEpubZoomEnabled$;

  readonly quickAnnotationEnabled$ = this.readerStore.quickAnnotationEnabled$;
  readonly digitalSample$ = this.readerConfigStore.digitalSample$;

  readonly showFallbackBannerForEvergreen$ = combineLatest([
    this.readerConfigStore.fallbackToDefaultPublish$,
    this.readerConfigStore.epubReleaseUUID$,
  ]).pipe(
    map(([fallbackToDefaultPublish, epubReleaseUUID]) => epubReleaseUUID && fallbackToDefaultPublish),
  );

  /** TEMP: remove with epub-controls.component refactor */
  readonly isDoubleSpread$ = this.readerStore.isDoubleSpread$;
  readonly isFixedLayout$ = this.readerStore.isFixedLayout$;
  readonly isSinglePageViewOnly$ = this.readerConfigStore.isSinglePageViewOnly$;
  /** */

  readonly zoomMoveArrowsEnabled$ = this.epubViewerStore.zoomMoveArrowsEnabled$;
  readonly zoomMoveUpEnabled$ = this.zoomMoveArrowsEnabled$.pipe(
    map(({ upEnabled }) => upEnabled !== false),
  );

  readonly zoomMoveDownEnabled$ = this.zoomMoveArrowsEnabled$.pipe(
    map(({ downEnabled }) => downEnabled !== false),
  );

  readonly zoomMoveLeftEnabled$ = this.zoomMoveArrowsEnabled$.pipe(
    map(({ leftEnabled }) => leftEnabled !== false),
  );

  readonly zoomMoveRightEnabled$ = this.zoomMoveArrowsEnabled$.pipe(
    map(({ rightEnabled }) => rightEnabled !== false),
  );

  // feature flags
  readonly enableTTS$ = this.readerConfigStore.readspeaker$;
  readonly navbar$ = this.readerConfigStore.navbar$;
  readonly scrubber$ = this.readerConfigStore.scrubber$;

  // ai assist items
  readonly aiAssistIsEnabled$ = combineLatest([
    this.readerConfigStore.isAiAssistEnabled$,
    this.readerStore.isFactoryMetadataAiAssistEnabled$,
  ]).pipe(
    map(([configEnabled, factoryEnabled]) => configEnabled && factoryEnabled),
  );

  // TODO add session disabled check
  readonly aiAssistIsOffered$ = combineLatest([
    this.aiAssistIsEnabled$,
    this.readerStore.isAiAssistReady$,
  ]).pipe(
    map(([isOverallEnabled, isReady]) => isOverallEnabled && isReady),
  );

  readonly showAiAssist$ = this.readerStore.showAiAssist$;
  readonly annotationForAiAssist$ = this.readerStore.annotationForAiAssist$;

  // ai reader panel configuration
  readonly aiAssistPanelConfig = combineLatest([
    this.readerConfigStore.runtime$,
    this.readerConfigStore.environment$,
    this.readerTokenService.getToken(),
  ]).pipe(
    map(
      ([runtimeConfig, environmentConfig, sessionToken]) => {
        // only use the session token if it was originally from the custom_user_idm_token LTI param
        // if it is not set that means the session token is a reader token, not usable in the component
        const tokenForComponent = (runtimeConfig.idmToken) ? sessionToken : undefined;

        return {
          // from reader config to component
          environment: environmentConfig.sdlc,
          platform: runtimeConfig.oauthConsumerKey,
          sectionXid: runtimeConfig.sectionXid,
          userId: runtimeConfig.userId,

          // token from reader service
          jwtToken: tokenForComponent,

          // epub knowledge
          contextId: runtimeConfig.contextId,
          releaseUuid: runtimeConfig.epubReleaseUUID,
          versionNum: runtimeConfig.epubVersion,

          // TODO translate this
          title: 'AI Reader',

          // feature toggles
          // TODO enable these as they are integrated by Reader UI
          provideTtsButton: false,
          provideSaveNoteButton: false,
          provideCopyToClipboardButton: false,
        };
      },
    ),
  );

  // This will have more obsvervables as we add more controls.
  readonly showEpubControls$ = combineLatest([
    this.readerStore.isFixedLayout$,
    this.enableTTS$,
    this.readerStore.showAnnotateButton$,
    this.readerConfigStore.isAiAssistEnabled$,
  ]).pipe(
    map(
      ([enableTTS, isFixedLayout, showAnnotateButton, isAiAssistEnabled]) =>
        enableTTS || !!isFixedLayout || showAnnotateButton || isAiAssistEnabled,
    ),
  );

  readonly showReadSpeakerVoicePick$ = combineLatest([
    this.enableTTS$,
    this.readerConfigStore.readSpeakerVoicePick$,
  ]).pipe(
    map(
      ([enableTTS, readSpeakerVoicePick]) => enableTTS && readSpeakerVoicePick,
    ),
  );

  // drawer open states
  readonly searchOpen$ = this.readerStore.rightDrawerOpen$;
  readonly tocOpen$ = this.readerStore.leftDrawerOpen$;

  // navigation
  readonly backToAssignmentButton$ =
    this.navigationStore.backToAssignmentButton$;

  readonly isLastPage$ = this.navigationStore.isLastPage$;

  // assessments | assignments
  readonly assignmentActive$ = combineLatest([
    this.readerConfigStore.assignment$.pipe(map((a) => a === 'active')),
    this.readerStore.assignmentSubmitted$,
  ]).pipe(map(([active, submitted]) => active && !submitted));

  readonly assignmentReview$ = combineLatest([
    this.readerConfigStore.isStudent$,
    this.readerConfigStore.assignment$.pipe(map((a) => a === 'review')),
    this.readerStore.assignmentSubmitted$,
  ]).pipe(
    map(([student, review, submitted]) => student && (review || submitted)),
  );

  readonly k5$ = this.readerConfigStore.k5$;

  readonly submitAssignmentDisabled$ = combineLatest([
    this.readerConfigStore.isAssessment$,
    this.readerConfigStore.interfaceMode$.pipe(map((mode) => mode === 'k5')),
  ]).pipe(
    switchMap(([isAssessment, isK5]) => {
      const { assignmentSubmitting$, assessmentCompleted$, lastPageViewed$ } =
        this.readerStore;
      const pendingCompletion$ = assessmentCompleted$.pipe(
        map((completed) => !completed),
      );
      const assessmentSubmitDisabled$ = combineLatest([
        pendingCompletion$,
        assignmentSubmitting$,
      ]).pipe(
        map(([pending, submitting]) => {
          return pending || submitting;
        }),
      );
      const pendingLastPageView$ = lastPageViewed$.pipe(
        map((viewed) => !viewed),
      );

      if (isAssessment && !isK5) {
        return assessmentSubmitDisabled$;
      } else if (isAssessment && isK5) {
        return combineLatest([
          pendingLastPageView$,
          pendingCompletion$,
          assignmentSubmitting$,
        ]).pipe(
          map(
            ([pendingLastPageView, pendingCompletion, assignmentSubmitting]) =>
              assignmentSubmitting || pendingCompletion || pendingLastPageView,
          ),
        );
      }

      return assignmentSubmitting$;
    }),
  );

  // style flags
  readonly fullWidth$ = this.readerConfigStore.contentWidth$.pipe(
    map((width) => width === 'full'),
  );

  readonly showFooter$ = combineLatest([
    this.assignmentActive$,
    this.scrubber$,
  ]).pipe(map(([assignment, scrubber]) => assignment || scrubber));

  // print
  printLink$ = this.readerStore.pdfUrl$?.pipe(map((pdfUrl) => pdfUrl?.url));
  printTitle$ = this.readerStore.pdfUrl$?.pipe(map((pdfUrl) => pdfUrl?.title));
  /**
   * Indicates whether to show an error if a session was ended (abruptly).
   * Sessions can be disabled as part of a launch config, so the error should
   * be suppressed in the case where a session is intentionally disabled.
   */
  readonly showSessionError$ = this.readerStore.hasSessionEnded$.pipe(
    withLatestFrom(this.readerConfigStore.sessionDisabled$),
    map(
      ([hasSessionEnded, isSessionDisabled]) =>
        !isSessionDisabled && hasSessionEnded,
    ),
  );

  /**
   * Generic bucket for alerts which features can add items into.
   */
  readonly alerts$ = this.readerStore.alerts$.pipe(distinctUntilChanged());
  readonly groupedAlerts$ = this.mapGroupedAlerts$();
  readonly showAlerts$ = this.readerStore.alerts$.pipe(
    withLatestFrom(this.readerConfigStore.sessionDisabled$),
    map(([alerts, isSessionDisabled]) => !isSessionDisabled && alerts.length),
  );

  readonly enableElasticSearch$ = this.readerConfigStore.enableElasticSearch$;
  readonly suggestions$ = this.dfaSearchService.suggestions$;
  readonly topics$ = this.dfaSearchService.topics$;
  readonly quizzes$ = this.dfaSearchService.quizzes$;
  readonly nodes$ = this.dfaSearchService.searchResults$;
  readonly totalResults$ = this.dfaSearchService.totalResults$;

  largeImageZoomLevel = 1;
  largeImgElement: HTMLImageElement;
  showLargeImageMinimap = true;
  showLINCredits = false;

  // expose import ref
  AlertType = AlertType;
  ButtonPurpose = ButtonPurpose;

  constructor(
    private readonly el: ElementRef,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly iconRegistry: IconRegistryService,
    private readonly overlayService: OverlayContainer,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly readerTokenService: ReaderTokenService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly navigationStore: NavigationStore,
    private readonly dfaSearchService: ElasticSearchService,
    private readonly deviceService: DeviceService,
    /**
     * DO NOT REMOVE BELOW DEP
     * ref additional mediators to ensure DI
     * required b/c not explicity called - subscriptions initialized on construction
     */
    @Inject(MEDIATOR_PROVIDERS) _mediators: ComponentEffects[],
  ) {
    (
      this.overlayService as unknown as ReaderOverlayContainerService
    ).setContainerScopeElement(this.el.nativeElement);
  }

  ngOnInit(): void {
    this.deviceService.init();
    if (!this.epubUrl) {
      throw Error('No Epub URL provided to component');
    }

    this.ga.event({ eventCategory: 'ReaderX', eventAction: 'ReaderX Loaded' });

    this.addConfigStyleClasses();
    this.routeNetworkErrors();

    // init
    const { epubUrl, initialSpineId, initialHash } = this;
    this.readerStore.dispatch(
      readerActions.init({
        url: epubUrl,
        spineId: initialSpineId,
        hash: initialHash,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Dismissing epub-alerts
   */
  handleAlertDismiss(key: string): void {
    this.readerStore.removeAlert(key);
  }

  closeDocumentRequestAlert(): void {
    this.epubViewerStore.setDocumentRequestError(false);
  }

  handleDigitalSampleAlertClose(): void {
    this.showDigitalSampleAlert = false;
  }

  closePdfAlert(): void {
    this.readerStore.dispatch(printActions.showCloseAlertModal());
  }

  /** epub view */
  onToggleAlbumMode(): void {
    this.readerStore.dispatch(
      readerActions.toggleAlbumMode({ userRequested: true }),
    );
  }

  onToggleAiAssistPanel(): void {
    this.readerStore.dispatch(readerActions.toggleAiAssistPanel());
  }

  onOpenAiAssistPanel(): void {
    this.readerStore.setAiAssistOpen(true);
  }

  onCloseAiAssistPanel(): void {
    this.readerStore.setAiAssistOpen(false);
  }

  onAiAssistIsReady(isReady: boolean | Observable<boolean>): void {
    this.readerStore.setAiAssistReady(isReady);
  }

  onNavFromAiAssistPanel(event: { epubCfi: string, nodeUuids: string[] }): void {
    this.readerStore.dispatch(
      readerActions.startAiAssistCitation({
        cfi: event.epubCfi,
        nodeUuids: event.nodeUuids,
      }),
    );
  }

  onIncreaseEpubZoomLevel(): void {
    this.readerStore.dispatch(readerActions.increaseEpubZoomLevel());
  }

  onDecreaseEpubZoomLevel(): void {
    this.readerStore.dispatch(readerActions.decreaseEpubZoomLevel());
  }

  onMoveZoomedEpub(direction: EpubMoveDirection): void {
    this.epubViewerStore.dispatch(
      epubViewerActions.moveZoomedEpub({ direction }),
    );
  }

  /** drawers */
  closeLeftDrawer(): void {
    this.readerStore.dispatch(readerActions.setLeftDrawer({ open: false }));
    // Readiator event for https://mcgrawhill.atlassian.net/browse/OLAD-8385
    this.readerStore.dispatch(readerActions.leftDrawerClosed());
  }

  closeRightDrawer(): void {
    this.readerStore.dispatch(readerActions.setRightDrawer({ open: false }));
    // Readiator event for https://mcgrawhill.atlassian.net/browse/OLAD-8385
    this.readerStore.dispatch(readerActions.rightDrawerClosed());
  }

  /** assignments */
  submitAssignment(): void {
    this.readerStore.dispatch(assignmentActions.confirmAssignmentSubmit());
  }

  navigateToPageStart(): void {
    this.readerConfigStore.pageStartCfi$
      .pipe(
        withLatestFrom(this.readerConfigStore.pageEndCfi$),
        tap(([pageStartCfi, pageEndCfi]) => {
          const cfi = pageStartCfi ?? (pageEndCfi as string);
          this.navigationStore.dispatch(
            navigationActions.navigateByCfi({ cfi, setFocus: true }),
          );
        }),
      )
      .subscribe();
  }

  /** config styling */
  private addConfigStyleClasses(): void {
    const { nativeElement } = this.el;

    const cssFullWidth = 'full-width';
    this.fullWidth$.pipe(takeUntil(this.destroy$)).subscribe((isFullWidth) => {
      if (isFullWidth) {
        this.renderer.addClass(nativeElement, cssFullWidth);
      } else {
        this.renderer.removeClass(nativeElement, cssFullWidth);
      }
    });

    const cssK5Theme = 'rdrx-theme-k5';
    this.readerConfigStore.k5$
      .pipe(takeUntil(this.destroy$))
      .subscribe((k5) => {
        if (k5) {
          this.renderer.addClass(nativeElement, cssK5Theme);
          this.iconRegistry.registerK5Icons();
        } else {
          this.renderer.removeClass(nativeElement, cssK5Theme);
        }
      });
  }

  private routeNetworkErrors(): void {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      if (error) {
        void this.router.navigateByUrl(error.status?.toString(), {
          skipLocationChange: true,
        });
      }
    });
  }

  /** alerts */
  trackByAlertKey(_i: number, alertGroup: ReaderAlertGrouped): string {
    return alertGroup.alert.translateKey;
  }

  private mapGroupedAlerts$(): Observable<ReaderAlertGrouped[]> {
    return this.alerts$.pipe(
      map((alerts) =>
        groupBy(
          alerts,
          ({ translateKey, alertType }): [ReaderAlertType, string] => [
            alertType,
            translateKey,
          ],
        ),
      ),
      map((groupedAlerts) => {
        const entries = Object.entries(groupedAlerts);
        const alertsWithCount = entries.reduce<ReaderAlertGrouped[]>(
          (acc, [key, val]) => {
            const [alertType, translateKey] = key.split(',');

            if (isReaderAlertType(alertType)) {
              const alert: ReaderAlert = { alertType, translateKey };
              const count = (val as any[])?.length;
              acc = [...acc, { alert, count }];
            }

            return acc;
          },
          [],
        );

        return alertsWithCount;
      }),
    );
  }

  handleSuggestionClicked(suggestion: { group: string, term: string }): void {
    this.dfaSearchService.handleSearchSubmitted(suggestion);
  }

  handleSearchQueryChanged(query: string): void {
    this.dfaSearchService.handleSearchQueryChange(query);
  }

  handleSearchResultClicked(node: any): void {
    this.dfaSearchService.handleSearchResultClicked(node);
  }
}
