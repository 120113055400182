import { FocusOrigin, FocusableOption } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { OverflowMenuOption } from './overflow-menu-option.model';

@Directive({
  selector: '[rdrxMenuItem]',
  exportAs: 'rdrxMenuItem',
})
export class MenuItemDirective implements FocusableOption {
  @Input('rdrxMenuItem') item: OverflowMenuOption<unknown>;
  disabled: boolean;

  @HostBinding('class.active') get cssActive(): boolean | undefined {
    return this.item.active;
  }

  constructor(private readonly element: ElementRef) {}

  getLabel(): string {
    const strval = String(this.item.value);
    return strval;
  }

  focus(_origin?: FocusOrigin): void {
    this.element.nativeElement.focus();
  }
}
