/* eslint-disable array-callback-return */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { AlertType, ButtonPurpose, MheOption } from '@mhe/ngx-shared';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { GlossaryModalData } from './glossary-modal.model';

import { AnchorType, GlossaryItem } from '@mhe/reader/models';
import { GlossaryModalStore } from './state/glossary-modal.store';
import { MathjaxService } from '@mhe/reader/features/mathjax';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { LargeImageNavigatorComponent } from '@mhe/reader/components/large-image-navigator';
import { getAnchorType } from '@mhe/reader/utils';

@Component({
  selector: 'rdrx-glossary-modal',
  templateUrl: './glossary-modal.component.html',
  styleUrls: ['./glossary-modal.component.scss'],
  providers: [GlossaryModalStore],
})
export class GlossaryModalComponent
implements OnInit, OnDestroy, AfterViewInit {
  glossaryItem$ = this.data.glossaryItem$;
  error$ = this.data.error$;
  isLoading$ = this.data.isLoading$;

  alertType = AlertType;
  buttonPurpose = ButtonPurpose;

  translationIndex = 0;
  triggerProcessHTML$ = new Subject<boolean>();
  private unlisteners: Array<() => void> = [];

  languageOptions: MheOption[] = [];
  selectedLanguage: MheOption[] = [];
  _updateAnnouncementText = '';

  private readonly contentSelector = '.viewer-container';
  private readonly destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: GlossaryModalData,
    public readonly dialogRef: MatDialogRef<GlossaryModalComponent>,
    public readonly mathjaxService: MathjaxService,
    private readonly renderer: Renderer2,
    private readonly glossaryModalStore: GlossaryModalStore,
    private readonly elementRef: ElementRef,
    private readonly translateService: TranslateService,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.resetUnlisteners();
  }

  async changeLanguage(option: MheOption[]): Promise<void> {
    this.translationIndex = option[0]?.value;
    this.selectedLanguage = [...option];

    this.mathjaxService.handleMath(this.contentSelector);
    this.triggerProcessHTML$.next(true);
    await this.updateAnnouncementText();
  }

  /**
   * Adding delay to make sure DOM element is ready before we query select it.
   * Since innerHTML is being used it takes few ms to render DOM content.
   */
  ngOnInit(): void {
    this.isLoading$.pipe(delay(100)).subscribe((status: any) => {
      if (!status) {
        this.processHTMLContent();
      }
    });
    this.triggerProcessHTML$.pipe(delay(100)).subscribe(() => {
      this.processHTMLContent();
    });

    this.glossaryItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe((glossaryItem) => {
        this.initGlossaryItems(glossaryItem);
      });
  }

  ngAfterViewInit(): void {
    this.glossaryItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.mathjaxService.handleMath(this.contentSelector));
  }

  processHTMLContent(): void {
    this.resetUnlisteners();
    this.processGlossaryHTML();
    this.processGlossaryAudioButton();
    this.processGlossaryAria();
  }

  initGlossaryItems(glossaryItem: GlossaryItem): void {
    if (glossaryItem && glossaryItem.definitionNodes.length > 1) {
      this.setLanguageOptions(glossaryItem.definitionNodes);
      this.selectedLanguage = [this.languageOptions[0]];
    }
  }

  /**
   * Fetch all img elements and add Click event listener
   */
  processGlossaryHTML(): void {
    const imgArr = this.elementRef.nativeElement.querySelectorAll(
      '.viewer-container .glossary-images figure img',
    );
    Array.from(imgArr).find((img: HTMLImageElement) => {
      const anchorRef = this.wrapImage(img);
      this.unlisteners.push(
        this.renderer.listen(anchorRef, 'click', () => {
          this.openImage(anchorRef);
        }),
      );
    });
  }

  /**
   * Fetch all audio elements and add Click event listener.
   * Also replace native audio UI with custom audio button
   */
  processGlossaryAudioButton(): void {
    const audioArr = this.elementRef.nativeElement.querySelectorAll(
      '.viewer-container .audio-pronunciation',
    );
    Array.from(audioArr).find((audioEl: any) => {
      const btnEl = this.renderer.createElement('button');
      btnEl.setAttribute('class', 'audio-button btn-icon');
      const iconEl = this.renderer.createElement('i');
      iconEl.setAttribute('class', 'dpg-icon dpg-icon-audioon');
      btnEl.appendChild(iconEl);
      audioEl.parentNode.appendChild(btnEl);
      this.unlisteners.push(
        this.renderer.listen(btnEl, 'click', () => {
          audioEl.play();
        }),
      );
    });
  }

  /**
   * Add combobox role to dropdown button for accessibility
   */
  processGlossaryAria(): void {
    // Check if language options first?
    const languageDropDwnBtn = this.elementRef.nativeElement.querySelector(
      'mhe-dropdown .dropdown-btn',
    );
    if (languageDropDwnBtn) {
      languageDropDwnBtn.setAttribute('role', 'combobox');
    }
  }

  /**
   * newly created Anchor Element
   * Creates a new Anchor element with epubtype as atribute and returns it
   */
  wrapImage(img: HTMLImageElement): HTMLAnchorElement {
    const anchor = this.renderer.createElement('a') as HTMLAnchorElement;
    const alt = img.alt;

    this.renderer.setAttribute(
      img,
      'alt',
      `${alt} large image navigator opens in a modal`,
    );

    anchor.setAttribute('epub:type', AnchorType.LARGE_IMAGE.toString());
    anchor.setAttribute('href', 'javascript:void(0)');
    img.parentNode?.insertBefore(anchor, img);
    anchor.appendChild(img);
    return anchor;
  }

  /**
   * Dispatch event on click of glossary image to open Large Image viewer
   */
  // TODO: DRY up similar logic here and in large-image-navigator.mediator.ts (EPR-10036)
  openImage(anchor: HTMLAnchorElement): void {
    const anchorType = getAnchorType(anchor);
    if (anchorType === AnchorType.LARGE_IMAGE) {
      const img = anchor.querySelector('img');
      const src = img?.src ?? '';
      const alt = img?.alt?.replace(' large image navigator opens in a modal', '') ?? '';
      const credit = anchor.parentElement?.querySelector<HTMLElement>(
        '.mhe-inline-credit',
      )?.innerText ?? '';
      const dialog$ = this.getDialogRef(src, alt, credit);

      dialog$.pipe(tap((_) => anchor.focus()));
    }
  }

  /**
   * Remove event listener attached to the HTML elements
   */
  private resetUnlisteners(): void {
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unlisteners = [];
  }

  /**
   * Convert glossary language options to type MheOption
   */
  setLanguageOptions(definitions: HTMLElement[]): void {
    this.languageOptions = definitions.map((definition, index) => {
      return {
        value: index,
        viewValue: this.translateService.instant(
          `language_code.${definition.getAttribute('lang')}`,
        ),
      } satisfies MheOption;
    });
  }

  // TODO: DRY up similar logic here and in large-image-navigator.mediator.ts (EPR-10036)
  getDialogRef(
    src: string,
    alt: string,
    credit: string,
  ): Observable<LargeImageNavigatorComponent> {
    return this.dialog
      .open(LargeImageNavigatorComponent, {
        data: { src, alt, credit },
        height: '90%',
        width: '85%',
        panelClass: 'rdrx-large-img-dialog',
        maxWidth: '97%',
        ariaLabelledBy: 'large-image-navigator-h2',
      })
      .afterClosed();
  }

  async updateAnnouncementText(): Promise<void> {
    // Set here so live announcer only reads on a language change and not on modal load
    this._updateAnnouncementText = `${this.translateService.instant(
      'dialog.glossary_language_change',
    )} ${this.selectedLanguage[0]?.viewValue}`;
    await this.liveAnnouncer.announce(this._updateAnnouncementText, 'polite');
  }
}
