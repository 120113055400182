/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AudioControlsComponent } from './components/audio-controls/audio-controls.component';
import { MatSliderModule } from '@angular/material/slider';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { AlertModule, ButtonModule } from '@mhe/ngx-shared';
import { PageTTSControlsComponent } from './components/page-tts-controls/page-tts-controls.component';
import { FloatingTTSControlsComponent } from './components/floating-tts-controls/floating-tts-controls.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { TtsVoicePickerComponent } from './components/tts-voice-picker/tts-voice-picker.component';

@NgModule({
  imports: [
    CommonModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    TranslateModule,
    TooltipModule,
    ButtonModule,
    AlertModule,
  ],
  declarations: [
    FloatingTTSControlsComponent,
    PageTTSControlsComponent,
    AudioControlsComponent,
    TtsVoicePickerComponent,
  ],
  exports: [
    FloatingTTSControlsComponent,
    PageTTSControlsComponent,
    AudioControlsComponent,
    TtsVoicePickerComponent,
  ],
})
export class TTSModule {}
