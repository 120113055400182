import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavigationStore } from './state/navigation.store';
import { AllowedInterfaceMode, AllowedNavigationType } from './types';
import * as actions from './state/navigation.actions';

@Component({
  selector: 'reader-core-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnChanges {
  @Input() interfaceMode: AllowedInterfaceMode = 'default';
  @Input() location: boolean;
  @Input() navigation: boolean;
  @Input() navigationType: AllowedNavigationType = 'paginate';

  // pg
  readonly pageNumber$ = this.navigationStore.index$.pipe(
    map((index) => index + 1),
  );

  readonly positionUpper$ = this.navigationStore.maxIndex$.pipe(
    map((i) => i + 1),
  );

  // previous
  readonly previousChapterLabel$ = this.navigationStore.previousChapterLabel$;
  readonly disablePrevious$ = this.navigationStore.index$.pipe(
    map((index) => index === 0),
  );

  // next
  readonly nextChapterLabel$ = this.navigationStore.nextChapterLabel$;
  readonly disableNext$ = combineLatest([
    this.navigationStore.index$,
    this.navigationStore.maxIndex$,
  ]).pipe(map(([index, max]) => index === max));

  showControls: boolean;
  showPosition: boolean;

  constructor(private readonly navigationStore: NavigationStore) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { location, navigation, navigationType } = changes;

    if (navigation || navigationType) {
      this.setShowControls(
        navigation.currentValue,
        navigationType.currentValue,
      );
    }

    if (location || navigationType) {
      this.setShowPosition(location.currentValue, navigationType.currentValue);
    }
  }

  navigateByStep(step: number): void {
    this.navigationStore.dispatch(actions.navigateByStep({ step }));
  }

  private setShowControls(
    navigation: boolean,
    navigationType: AllowedNavigationType,
  ): void {
    this.showControls = navigation || navigationType === 'presentation';
  }

  private setShowPosition(
    location: boolean,
    navigationType: AllowedNavigationType,
  ): void {
    this.showPosition = location && navigationType !== 'presentation';
  }
}
