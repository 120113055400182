<div class="rdrx-content-container">
  <!-- contents -->
  <ng-container *ngIf="config.hasContentsMenuTab$ | async">
    <button
      placement="bottom"
      data-automation-id="contents-toggle"
      [mheTooltip]="'contents.contents' | translate"
      mhe-button
      [purpose]="ButtonPurpose.Icon"
      (click)="toggleLeftDrawer()"
    >
      <i class="dpg-icon dpg-icon-listview"></i>
    </button>
  </ng-container>

  <!-- navigation -->
  <reader-core-navigation
    [interfaceMode]="config.interfaceMode$ | async"
    [location]="config.location$ | async"
    [navigation]="config.navigation$ | async"
    [navigationType]="config.navigationType$ | async"
  ></reader-core-navigation>

  <div *ngIf="
    !(singleRange$ | async).isSingleRange &&
    (config.assignment$ | async) !== 'review' &&
    (pageStartIndex$ | async) &&
    (pageEndIndex$ | async)
  " class="assignment-pages">
    {{ 'navigation.assignment_pages' | translate }}:
    <strong data-automation-id="assigned-page-range" class="page-range">{{ pageStartIndex$ | async
      }} - {{ pageEndIndex$ | async }}</strong>
  </div>

  <div class="actions">
    <div
      *ngIf="
        (singleRange$ | async).isSingleRange &&
        (config.assignment$ | async) !== 'review' &&
        ((pageStartIndex$ | async) || (pageEndIndex$ | async))
      "
      class="assignment-pages"
    >
      {{ 'navigation.assignment_page' | translate }}:
      <strong class="page-range">{{ (singleRange$ | async).pageRange }}</strong>
    </div>

    <ng-container *ngIf="customButton$ | async as customButton">
      <button
        placement="bottom"
        class="custom-button"
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        [mheTooltip]="customButton.ariaLabel"
        (click)="customButtonClicked()"
      >
        <img [src]="customButton.icon" aria-hidden="true" />
        <div class="badge-counter" *ngIf="customButton?.counter > 0">{{ customButton?.counter }}</div>
      </button>
    </ng-container>
    <!-- search -->
    <ng-container *ngIf="config.search$ | async">
      <button
        placement="bottom"
        [mheTooltip]="'search.search' | translate"
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        (click)="toggleRightDrawer()"
      >
        <i class="dpg-icon dpg-icon-system-search"></i>
      </button>
    </ng-container>

    <!-- font resizer -->
    <ng-container *ngIf="(config.fontResizer$ | async) && (renderModeAllowsFontResize$ | async)">
      <reader-core-font-resizer></reader-core-font-resizer>
    </ng-container>

    <!-- return -->
    <ng-container *ngIf="config.backButton$ | async">
      <button
        placement="bottom"
        data-automation-id="go-to-previous-location"
        [mheTooltip]="'navigation.back_button' | translate"
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        [disabled]="(readerStore.canNavigateBack$ | async) === false"
        (click)="previousLocation()"
      >
        <i class="dpg-icon dpg-icon-undo"></i>
      </button>
    </ng-container>

    <!--print button-->
    <ng-container *ngIf="config.print$ | async">
      <div *ngIf="readerStore.pdfLoading$ | async; else printIcon" class="pdf-loading-spinner">
        <mat-progress-spinner
        data-automation-id="pdf-loading-spinner"
        mode="indeterminate"
        [strokeWidth]="2"
        [diameter]="25"
        class="rdrx-primary"
        color="primary"
      ></mat-progress-spinner>
      </div>

      <ng-template #printIcon>
        <button
          placement="bottom"
          data-automation-id="print-button"
          [mheTooltip]="'print.print' | translate"
          mhe-button
          [purpose]="ButtonPurpose.Icon"
          (click)="showPrintModal()"
        >
          <i class="dpg-icon dpg-icon-printer"></i>
        </button>
      </ng-template>
    </ng-container>

    <!-- overflow menu (kebob) -->
    <rdrx-overflow-menu></rdrx-overflow-menu>
  </div>
</div>
