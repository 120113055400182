import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ModalHeaderComponent } from './modal-header.component';

@NgModule({
  imports: [ButtonModule, TranslateModule, CommonModule, TooltipModule],
  declarations: [ModalHeaderComponent],
  exports: [ModalHeaderComponent],
})
export class ReaderModalHeaderModule {}
