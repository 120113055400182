import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { ConceptNavigatorComponent } from './concept-navigator.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@mhe/ngx-shared';

@NgModule({
  imports: [CommonModule, TooltipModule, TranslateModule, ButtonModule],
  declarations: [ConceptNavigatorComponent],
  exports: [ConceptNavigatorComponent],
})
export class ConceptNavigatorModule {}
