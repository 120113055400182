import { createAction, props } from '@ngrx/store';
import { Book, ParserOptions, RuntimeConfiguration } from '@mhe/reader/models';

/** loading epub */
// check if epub is in store - if not, dispatch laod
export const fetchEpub = createAction(
  '[Reader] Fetch Epub',
  props<{
    url: string
    url_leveled: string
    readerConfig: RuntimeConfiguration
    parserOptions?: ParserOptions
  }>(),
);
// loadEpub - typical http load pattern | init, success, error
export const loadEpub = createAction(
  '[Reader] Load Epub',
  props<{
    url: string
    url_leveled: string
    readerConfig: RuntimeConfiguration
    parserOptions?: ParserOptions
  }>(),
);
export const loadEpubSuccess = createAction(
  '[Reader] Load Epub Success',
  props<{ url: string, url_leveled: string, book: Book }>(),
);
export const loadEpubError = createAction(
  '[Reader] Load Epub Error',
  props<{ url: string, url_leveled: string, error: Error }>(),
);

export const loadFactoryMetadata = createAction(
  '[Reader] Load Factory Metadata',
  props<{ url: string }>(),
);
export const loadFactoryMetadataSuccess = createAction(
  '[Reader] Load Factory Metadata Success',
  props<{ url: string, metadataJson: JSON | undefined }>(),
);
export const loadFactoryMetadataError = createAction(
  '[Reader] Load Factory Metadata Error',
  props<{ url: string, error: Error }>(),
);
