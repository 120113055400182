import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { NavigationStore } from '@mhe/reader/components/navigation';
import { ReaderConfigStore, ReaderStore } from '../reader/state';
import * as readerActions from '../reader/state/reader.actions';
import * as toolbarActions from '../reader/state/toolbar.actions';
import * as printActions from '../reader/state/print.actions';
import { CustomButton } from '@mhe/reader/types';

@Component({
  selector: 'reader-core-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  readonly pageStartIndex$ = this.navigationStore.rangeStartIndex$.pipe(
    filter((rangeStartIndex) => undefined !== rangeStartIndex),
    map((rangeStartIndex) => rangeStartIndex),
  );

  readonly pageEndIndex$ = this.navigationStore.rangeEndIndex$.pipe(
    filter((rangeEndIndex) => undefined !== rangeEndIndex),
    map((rangeEndIndex) => rangeEndIndex),
  );

  readonly isDoubleSpread$ = this.readerStore.isDoubleSpread$;
  readonly isFixedLayout$ = this.readerStore.isFixedLayout$;
  readonly customButton$ = this.readerStore.customButton$.pipe(
    map((cb) => this.transformCustomButtonIconToTrustedUrl(cb as CustomButton)),
  );

  readonly singleRange$: Observable<{
    isSingleRange: boolean
    pageRange: number
  }> = combineLatest([
      this.navigationStore.rangeStartIndex$,
      this.navigationStore.rangeEndIndex$,
    ]).pipe(
      map(([pageStartIndex, pageEndIndex]) => {
        if (pageStartIndex === pageEndIndex) {
          return { isSingleRange: true, pageRange: pageStartIndex as number };
        }

        if (pageStartIndex && undefined === pageEndIndex) {
          return { isSingleRange: true, pageRange: pageStartIndex };
        }

        if (undefined === pageStartIndex && pageEndIndex) {
          return { isSingleRange: true, pageRange: pageEndIndex };
        }

        return { isSingleRange: false, pageRange: 0 };
      }),
    );

  renderModeAllowsFontResize$: Observable<boolean> = combineLatest([
    this.isDoubleSpread$,
    this.isFixedLayout$,
  ]).pipe(
    map(([isDoubleSpread, isFixedLayout]) => !(isDoubleSpread || isFixedLayout)),
  );

  // expose import ref
  ButtonPurpose = ButtonPurpose;

  constructor(
    readonly config: ReaderConfigStore,
    readonly readerStore: ReaderStore,
    private readonly sanitizer: DomSanitizer,
    private readonly navigationStore: NavigationStore,
  ) {}

  previousLocation(): void {
    this.readerStore.dispatch(toolbarActions.navigationHistoryBack());
  }

  toggleRightDrawer(): void {
    this.readerStore.dispatch(readerActions.toggleRightDrawer());
  }

  toggleLeftDrawer(): void {
    this.readerStore.dispatch(readerActions.toggleLeftDrawer());
  }

  customButtonClicked(): void {
    this.readerStore.dispatch(toolbarActions.customButtonClicked());
  }

  showPrintModal(): void {
    this.readerStore.dispatch(printActions.printButtonClicked());
  }

  private transformCustomButtonIconToTrustedUrl(
    customButton: CustomButton,
  ): CustomButton {
    if (!customButton?.icon) {
      return customButton;
    }

    return {
      ...customButton,
      icon: this.sanitizer.bypassSecurityTrustUrl(
        `data:image/svg+xml;base64, ${customButton?.icon}`,
      ) as string,
    };
  }
}
