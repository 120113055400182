import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AnnotationCssService {
  locals: Record<string, string> = {
    'context.highlight.yellow': 'Yellow',
    'context.highlight.blue': 'Blue',
    'context.highlight.green': 'Green',
    'context.highlight.pink': 'Pink',
    'annotation.note': 'Note',
    'annotation.placemark': 'Placemark',
    'annotation.epr-shape-square-filled': 'Highlight',
    'annotation.epr-shape-square-outline': 'Box',
    'annotation.epr-shape-round-outline': 'Circle',
    'annotation.epr-shape-underline': 'Underline',
    'shared.with': 'with',
    'shared.and': 'and',
    'annotation.keyboard_activate_message': 'Press Enter to open the annotation modal.',
    'annotation.begin': 'Begin',
    'annotation.end': 'End',
    'teaching_notes.answer': 'Answer',
    'teaching_notes.diff_resource': 'Differentiated Resource',
    'teaching_notes.example': 'Example',
    'teaching_notes.teach_notes': 'Teaching Notes',
  };

  auth;

  get data(): { teacherCss: string, annotationCss: string } {
    return {
      teacherCss: this.getTeacherCss(),
      annotationCss: this.getAnnotationCss(),
    };
  }

  constructor(private readonly translate: TranslateService) {
    this.auth = {
      isReadiator: () => false,
    };
  }

  translateLocals(): void {
    for (const key of Object.keys(this.locals)) {
      const translated = this.translate.instant(key);
      if (translated !== key) {
        this.locals[key] = translated;
      }
    }
  }

  getAnnotationCss(): string {
    this.translateLocals();
    const annotationClassNames = {
      positionName: [
        'annotation-start',
        'annotation-end',
        'annotation-start-end',
      ],
      highlight: [
        'yellow-highlight',
        'blue-highlight',
        'green-highlight',
        'pink-highlight',
      ],
      shape: [
        'epr-shape-underline',
        'epr-shape-round-outline',
        'epr-shape-square-outline',
        'epr-shape-square-filled',
      ],
      lrClasses: ['has-lr'],
      note: ['note'],
      placemark: ['placemark'],
    };
    const [beforeCssContent, afterCssContent] =
      this.getAnnotationBeforeCssContent(annotationClassNames);
    const basicSelectors =
      this.getAnnotationBasicSelectors(annotationClassNames);
    const beforeCssFont = this.getAnnotationBeforeCssFont(basicSelectors);
    const afterCss = this.getAnnotationAfterCss(basicSelectors);
    const annotationCss = [
      beforeCssContent,
      beforeCssFont,
      afterCss,
      afterCssContent,
    ].join('');

    return annotationCss;
  }

  getAnnotationBasicSelectors(
    annotationClassNames,
    additionalClass = '',
  ): string[] {
    const selectors: string[] = [];
    const allClassNames: any[] = [].concat(
      annotationClassNames.positionName,
      annotationClassNames.highlight,
      annotationClassNames.note,
      annotationClassNames.placemark,
      annotationClassNames.shape,
      annotationClassNames.lrClasses,
    );

    // angular.forEach(allClassNames, function(className) {
    allClassNames.forEach((className) => {
      if (additionalClass) {
        className += '.' + additionalClass;
      }
      if (className.indexOf('highlight') > -1) {
        selectors.push('.annotation.highlight.' + className);
      } else {
        selectors.push('.annotation.' + className);
      }
    });

    return selectors;
  }

  getAnnotationBeforeCssContent(annotationClassNames): string[] {
    let beforeCssContent = '';
    let afterCssContent = '';
    const positionNames = [''].concat(annotationClassNames.positionName);
    const highlightNames = [''].concat(annotationClassNames.highlight);
    const noteNames = [''].concat(annotationClassNames.note);
    const placemarkNames = [''].concat(annotationClassNames.placemark);
    const shapeNames = [''].concat(annotationClassNames.shape);
    const lrClasses = [''].concat(annotationClassNames.lrClasses);
    positionNames.forEach((posName) => {
      highlightNames.forEach((hName) => {
        noteNames.forEach((nName) => {
          placemarkNames.forEach((pName) => {
            shapeNames.forEach((sName) => {
              lrClasses.forEach((lrClass) => {
                const tempBefore = this.getCssPseudoEachAnnotation(
                  'before',
                  posName,
                  hName,
                  nName,
                  pName,
                  sName,
                  lrClass,
                );
                if (tempBefore) {
                  beforeCssContent += tempBefore;
                }
                const tempAfter = this.getCssPseudoEachAnnotation(
                  'after',
                  posName,
                  hName,
                  nName,
                  pName,
                  sName,
                  lrClass,
                );
                if (tempAfter) {
                  afterCssContent += tempAfter;
                }
              });
            });
          });
        });
      });
    });

    return [beforeCssContent, afterCssContent];
  }

  getCssPseudoEachAnnotation(
    pseudoType,
    positionName,
    colorHighlight,
    note,
    placemark,
    shape,
    lrClass,
  ): string | undefined {
    if (pseudoType === 'after') {
      if (
        !positionName ||
        ![
          'annotation-end',
          'annotation-start-end',
          'annotation-start',
        ].includes(positionName)
      ) {
        return;
      }
    }

    if (pseudoType === 'before') {
      if (
        !positionName ||
        ![
          'annotation-start',
          'annotation-middle',
          'annotation-start-end',
        ].includes(positionName)
      ) {
        return;
      }
    }
    let selectors = ['.annotation'];
    let contentPieces: string[] = [];
    let cssString = '';
    let linker = this.locals['shared.with'];

    if (colorHighlight) {
      const colorHighlightKey = 'context.' + colorHighlight.replace('-', '_');
      selectors = selectors.concat(['highlight', colorHighlight]);
      contentPieces.push(this.locals[colorHighlightKey]);
    }

    if (shape) {
      const shapeType = 'annotation.' + shape;
      selectors.push(shape);
      contentPieces.push(this.locals[shapeType]);
    }

    if (note) {
      selectors.push('note');
      if (contentPieces.length > 0) {
        contentPieces.push(linker);
        linker = this.locals['shared.and'];
      }
      contentPieces.push(this.locals['annotation.note']);
    }

    if (placemark) {
      selectors.push('placemark');
      if (contentPieces.length > 0) {
        contentPieces.push(linker);
      }
      contentPieces.push(this.locals['annotation.placemark']);
    }

    if (lrClass === 'has-lr' && this.auth.isReadiator()) {
      selectors.push(lrClass);
    }

    if (positionName) {
      selectors.push(positionName);
      if (
        pseudoType === 'before' &&
        ['annotation-start', 'annotation-start-end'].includes(positionName)
      ) {
        contentPieces.unshift(this.locals['annotation.begin']);
        if (!this.auth.isReadiator()) {
          contentPieces.push(
            this.locals['annotation.keyboard_activate_message'],
          );
        } else {
          if (selectors.includes('has-lr')) {
            contentPieces.push(this.locals['annotation.keyboard_lr_message']);
          }
        }
      }

      if (pseudoType === 'after' && positionName === 'annotation-start') {
        contentPieces = [];
      }

      if (
        pseudoType === 'after' &&
        ['annotation-end', 'annotation-start-end'].includes(positionName)
      ) {
        contentPieces.unshift(this.locals['annotation.end']);
        if (this.auth.isReadiator()) {
          if (selectors.includes('has-lr')) {
            contentPieces.push(this.locals['annotation.keyboard_lr_message']);
          } else {
            contentPieces.push('');
          }
        } else {
          contentPieces.push(
            this.locals['annotation.keyboard_activate_message'],
          );
        }
      }
    }

    if (contentPieces.length > 0) {
      cssString = [
        selectors.join('.'),
        ':',
        pseudoType,
        '{content: "',
        contentPieces.join(' '),
        '";} ',
      ].join('');
    }

    return cssString;
  }

  getAnnotationBeforeCssFont(selectors): string {
    let cssString = '';

    if (Array.isArray(selectors) && selectors.length > 0) {
      const selectorString = selectors.join(':before,') + ':before';

      cssString = this.createMarkCssString(selectorString);
    }

    return cssString;
  }

  getAnnotationAfterCss(selectors): string {
    let cssString = '';
    let selectorString;
    const notSelectors = ':not(iframe):not(img):not(svg):not(audio):not(video)';

    if (Array.isArray(selectors) && selectors.length > 0) {
      if (this.auth.isReadiator() && selectors.join().includes('has-lr')) {
        selectorString =
          selectors.join(notSelectors + ':after,') + notSelectors + ':after';
      } else {
        selectorString = selectors.join(':after,') + ':after';
      }

      cssString = this.createMarkCssString(selectorString);
    }

    return cssString;
  }

  getTeacherCss(): string {
    const teacherClasses = [
      'answer',
      'diff-resource',
      'example',
      'teach-notes',
    ];
    let cssString = '';

    teacherClasses.forEach((teacherClass) => {
      const translationKey = 'teaching_notes.' + teacherClass.replace('-', '_');
      const selector = '.teacher.' + teacherClass + ':before';
      const css = [
        selector,
        '{content: "',
        this.locals[translationKey],
        '";} ',
      ].join('');
      cssString += css;
    });

    return cssString;
  }

  createMarkCssString(selectorString: string): string {
    // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark#accessibility_concerns for styling choice
    const clipPath = 'clip-path: inset(100%);';
    const clip = 'clip: rect(0.1px, 0.1px, 0.1px, 0.1px);';
    const height = 'height: 0.1px;';
    const overflow = 'overflow: hidden;';
    const position = 'position: absolute;';
    const whiteSpace = 'white-space: nowrap;';
    const width = 'width: 0.1px;';

    return [
      selectorString,
      '{',
      clipPath,
      clip,
      height,
      overflow,
      position,
      whiteSpace,
      width,
      '} ',
    ].join('');
  }
}
