import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertModule, ButtonModule, DropdownModule } from '@mhe/ngx-shared';
import { ReaderModalModule } from '../modal.module';
import { TranslateModule } from '@ngx-translate/core';

import { GlossaryModalComponent } from './glossary-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    AlertModule,
    DropdownModule,
    ReaderModalModule,
    TranslateModule,
    FormsModule,
  ],
  declarations: [GlossaryModalComponent],
  exports: [GlossaryModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GlossaryModalModule {}
