import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { EPubLoaderService } from '@mhe/reader/features/epub-loader';
import * as actions from './glossary.actions';
import * as query from './glossary.selectors';

@Injectable()
export class GlossaryEffects {
  readonly fetchGlossary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchGlossary),
      map(({ url: { origin, pathname } }) => `${origin}${pathname}`),
      concatMap((url) =>
        of(url).pipe(
          withLatestFrom(this.store.pipe(select(query.getGlossary, { url }))),
        ),
      ),
      filter(([_, glossary]) => !glossary),
      map(([url]) => actions.loadGlossary({ url })),
    ),
  );

  readonly loadGlossary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadGlossary),
      mergeMap(({ url }) => {
        return this.epubLoaderService
          .getContent$(url, 'application/xhtml+xml')
          .pipe(
            map((glossaryDocument) =>
              actions.loadGlossarySuccess({ url, glossaryDocument }),
            ),
            catchError((error) => of(actions.loadGlossaryError({ url, error }))),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly epubLoaderService: EPubLoaderService,
    private readonly store: Store,
  ) {}
}
