import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';

@Directive({ selector: '[rdrxBonsaiNodeSelect]' })
export class BonsaiNodeSelectDirective implements OnDestroy, AfterViewInit {
  @Input() rdrxBonsaiNodeSelectKeyboard = true;
  @Input() targetClass = 'node-wrapper';

  private readonly keyboardEvents = ['Enter', 'Space'];

  private nodeWrapper: HTMLElement;
  private unlisteners: Array<() => void> = [];

  constructor(
    private readonly el: ElementRef<HTMLAnchorElement>,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    const { nativeElement } = this.el;
    const selector = `.${this.targetClass}`;

    this.nodeWrapper = nativeElement.closest(selector) as HTMLElement;
    if (this.nodeWrapper) this.nodeEventHandler();
  }

  ngOnDestroy(): void {
    this.unlisteners?.forEach((unlisten) => unlisten?.());
  }

  private nodeEventHandler(): void {
    let keyboardListeners: any[] = [];
    let toggleListener!: () => void;

    const clickListener = this.renderer.listen(
      this.nodeWrapper,
      'click',
      ($event) => this.eventHandler($event),
    );

    if (this.rdrxBonsaiNodeSelectKeyboard) {
      keyboardListeners = this.keyboardEvents.map((event) => {
        const keydown = `keydown.${event}`;

        return this.renderer.listen(this.nodeWrapper, keydown, ($event) =>
          this.eventHandler($event),
        );
      });
    }

    const toggle = this.nodeWrapper.querySelector('.toggle-children-wrapper');
    if (toggle) {
      toggleListener = this.renderer.listen(toggle, 'click', ($event) =>
        $event.stopPropagation(),
      );
    }

    this.unlisteners = [clickListener, ...keyboardListeners, toggleListener];
  }

  private eventHandler(event: MouseEvent | KeyboardEvent): void {
    event.preventDefault();
    this.el.nativeElement.click();
  }
}
