import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  DoubleSpineItem,
  FORCE_RENDER_HASH,
  SpineItem,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ReaderStore, ReaderConfigStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';

@Injectable()
export class EpubControlsMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;

  constructor(
    private readonly epubViewerStore: EpubViewerStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /** toggle album mode */
  private readonly toggleAlbumMode$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.toggleAlbumMode),
      withLatestFrom(
        this.epubViewerStore.albumMode$,
        this.readerStore.linearSpine$,
        this.readerStore.doubleSpine$,
        this.readerStore.spineItem$,
        this.readerStore.doubleSpineItem$,
        this.readerConfigStore.cfi$,
        this.readerConfigStore.ribac$,
      ),
      tap(
        ([
          { userRequested },
          albumMode,
          linearSpine,
          doubleSpine,
          spineItem,
          doubleSpineItem,
          cfi,
          ribac,
        ]) => {
          const updatedAlbumMode = !albumMode;
          if (updatedAlbumMode) {
            const linearIndex =
              this.util.getSingleSpineIndexFromDoubleSpineItem(
                doubleSpineItem as DoubleSpineItem,
                linearSpine as SpineItem[],
                { cfi, ribac },
              );
            this.util.navigate(linearIndex, FORCE_RENDER_HASH);
          } else {
            const doubleIndex = this.util.getDoubleSpineIndexFromSpineItem(
              spineItem as SpineItem,
              doubleSpine as DoubleSpineItem[],
            );
            this.util.navDoubleSpread(doubleIndex);
          }
          this.epubViewerStore.toggleAlbumMode({ userRequested });
        },
      ),
      logCatchError('toggleAlbumMode$'),
    ),
  );

  private readonly increaseEpubZoomLevel$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.increaseEpubZoomLevel),
      tap(() => this.epubViewerStore.increaseZoomLevel()),
      logCatchError('increaseEpubZoomLevel$'),
    ),
  );

  private readonly decreaseEpubZoomLevel$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.decreaseEpubZoomLevel),
      tap(() => this.epubViewerStore.decreaseZoomLevel()),
      logCatchError('decreaseEpubZoomLevel$'),
    ),
  );

  // When the window width is narrow, we want to force
  // album mode for double spread ePubs. This effect will
  // also toggle album mode on initial load for narrow windows.
  private readonly autoAlbumMode$ = this.effect(() =>
    combineLatest([
      this.epubViewerStore.isAlbumModeForced$,
      this.epubViewerStore.isDoubleSpread$,
      this.readerStore.doubleSpineItem$, // need to wait for album to be toggle-able
    ]).pipe(
      filter(
        ([_, isDoubleSpread, doubleSpineItem]) =>
          isDoubleSpread === true && doubleSpineItem !== undefined,
      ),
      map((args) => args[0]),
      distinctUntilChanged(),
      withLatestFrom(
        this.epubViewerStore.albumMode$,
        this.epubViewerStore.albumModeUserReq$,
        this.readerConfigStore.ribac$,
        this.readerConfigStore.isSinglePageViewOnly$,
      ),
      tap(([isAlbumModeForced, albumMode, albumModeUserReq, ribac, isSinglePageViewOnly]) => {
        isAlbumModeForced = isAlbumModeForced || ribac || isSinglePageViewOnly;

        if (isAlbumModeForced && !albumMode) {
          // force the user into album mode
          this.readerStore.dispatch(
            readerActions.toggleAlbumMode({ userRequested: false }),
          );
        } else if (!isAlbumModeForced && albumMode !== albumModeUserReq) {
          // need to restore album mode to the user's request
          this.readerStore.dispatch(
            readerActions.toggleAlbumMode({ userRequested: false }),
          );
        }
      }),
      logCatchError('autoAlbumMode$'),
    ),
  );
}
