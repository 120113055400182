import { CFI, SpineItem, Step } from '@mhe/reader/models';

export function cfiFromSpineItem(spineItem: SpineItem): string {
  // Get Chapter Component
  const chapterComponent = generateChapterComponent(2, spineItem.index, spineItem.idref);
  // Return Base CFI
  return `epubcfi(${chapterComponent}!/4/)`;
}

function generateChapterComponent(spineIndex: number, position: number, id: string): string {
  const idPortion = id ? `[${id}]` : '';
  // Add one to spineIndex
  spineIndex = (spineIndex + 1) * 2;
  return `/${spineIndex}/${spineIndex}${idPortion}`;
}

function generatePathComponent(steps): string {
  // Allocate parts array
  const parts: string[] = [];

  // Loop through steps
  const nSteps = steps.length;
  for (let i = 0; i < nSteps; i++) {
    // Current step
    const currentStep = steps[i];
    // Allocate segment array
    const segment: Array<string | number> = [];

    // Push calculated index
    segment.push((currentStep.index + 1) * 2);

    // Push part id
    if (currentStep.id) {
      // Push Step to segment
      segment.push(['[', currentStep.id, ']'].join(''));
    }

    // Push segment to step
    parts.push(segment.join(''));
  }

  // Join parts
  const joinedParts = parts.join('/');

  // return parts
  return (parts.length > 0) ? '/' + joinedParts : joinedParts;
}

export function cfiFromNode(node, chapter): string {
  // Get Steps
  const steps = generatePathToNode(node);

  // Get Path Component
  const path = generatePathComponent(steps);

  // Get Chapter Component
  const chapterComponent = generateChapterComponent(2, chapter.index, chapter.idref);

  // Compile CFI
  if (!path.length) {
    // Chapter Start
    return ['epubcfi(', chapterComponent, '!/4/)'].join('');
  } else {
    // Starting text node
    return ['epubcfi(', chapterComponent, '!', path, '/1:0)'].join('');
  }
}

function generatePathToNode(node): any[] {
  // Allocate stack array
  const stack: any[] = [];

  // Loop node and push children into stack
  while (node && node.parentNode !== null && node.parentNode.nodeType !== 9) {
    // Allocate children Variable
    const children = node.parentNode.children;

    // Add to start of stack
    stack.unshift({
      id: node.id,
      tagName: node.tagName,
      index: children ? Array.prototype.indexOf.call(children, node) : 0,
    });

    // Set node to its parent node
    node = node.parentNode;
  }

  // Return Stack
  return stack;
}

function getChapterComponent(cfiString: string): string {
  // Split the CFI string
  const splitCfi = cfiString.split('!');

  // return chapter part
  return splitCfi[0];
}

function getPathComponent(cfiString: string): string {
  // Split the CFI string
  const splitCfi = cfiString.split('!');

  // Split Path Component
  return splitCfi[1] ? splitCfi[1].split(':')[0] : '';
}

function getCharacterOffsetComponent(cfiString: string): string {
  // Split the CFI string
  const splitCfi = cfiString.split(':');

  // Return the Character offset
  return splitCfi[1] || '';
}

export function parseCfi(rawCfiString: string): CFI {
  // Slice off epubcfi() if found on string
  const cfiString =
    rawCfiString.startsWith('epubcfi(') && rawCfiString.endsWith(')')
      ? rawCfiString.slice(8, rawCfiString.length - 1)
      : rawCfiString;
  // Object is built up through mutation in this function
  const parsedCfi: CFI = {
    spinePos: -1,
    string: rawCfiString,
  };

  // Get chapter Component
  const chapterComponent = getChapterComponent(cfiString);

  // Get Path Component
  const pathComponent = getPathComponent(cfiString);

  // Get Chapter segment
  const chapterSegment = chapterComponent.split('/')[2] || null;

  // Return if no segment was Found
  if (!chapterSegment) {
    return parsedCfi;
  }

  // Parse Spine Position
  parsedCfi.spinePos = parseInt(chapterSegment, 10) / 2 - 1 || 0;

  // Get chapter Id
  const chapterId = chapterSegment.match(/\[(.*)\]/);
  parsedCfi.spineId = chapterId ? chapterId[1] : null;

  // Get Character offset
  const characterOffset = getCharacterOffsetComponent(cfiString);

  // Return if there is no offset
  if (!characterOffset) {
    return parsedCfi;
  }

  // Get Steps
  parsedCfi.steps = getPathSteps(pathComponent);

  // Get xPath
  parsedCfi.xPath = xPathFromSteps(parsedCfi.steps);

  // Get CSS selector
  parsedCfi.cssSelector = cssSelectorFromSteps(parsedCfi.steps);

  // Calculate assertions
  const assertion = characterOffset.match(/\[(.*)\]/);
  if (assertion?.[1]) {
    parsedCfi.characterOffset = parseInt(characterOffset.split('[')[0], 10);
    parsedCfi.textAssertionOffset = assertion[1];
  } else {
    parsedCfi.characterOffset = parseInt(characterOffset, 10);
  }

  // Return Parsed CFI
  return parsedCfi;
}

function getPathSteps(pathComponent: string): Step[] {
  const path = pathComponent.split('/');
  const pathEnd = path.pop() as string;

  const steps: Step[] = path.filter((s) => Boolean(s)).map((s) => parseStep(s));

  // Deal with the path end
  const endInt = parseInt(pathEnd, 10);
  if (!isNaN(endInt)) {
    // Check if the value is even
    if (endInt % 2 === 0) {
      steps.push(parseStep(pathEnd));
    } else {
      steps.push(parseStep(pathEnd, 'text'));
    }
  }

  return steps;
}

function parseStep(step: string, type: 'text' | 'element' = 'element'): Step {
  // Get ID
  let id: string | null = null;
  const hasBrackets = step.match(/\[(.*)\]/);
  if (hasBrackets?.[1]) {
    id = hasBrackets[1];
  }

  return {
    type,
    index: type === 'text' ? (parseInt(step, 10) - 1) / 2 : parseInt(step, 10) / 2 - 1,
    id,
  };
}

function xPathFromSteps(steps: readonly Step[]): string {
  // Define xPath
  const xPath = ['.', '*'];

  // Loop though Steps
  const nSteps = steps.length;
  for (let i = 0; i < nSteps; i++) {
    const step = steps[i];

    // Get position
    const position = step.index + 1;

    // Define Query
    let query: string | null = null;
    if (step.id) {
      query = ['*[position()=', position, ' and @id=', step.id, ']'].join('"');
    } else if (step.type === 'text') {
      query = 'text()[' + position + ']';
    } else {
      query = '*[' + position + ']';
    }
    xPath.push(query);
  }

  return xPath.join('/');
}

function cssSelectorFromSteps(steps: readonly Step[]): string {
  const selector = ['html'];

  // Loop though Steps
  const nSteps = steps.length;
  for (let i = 0; i < nSteps; i++) {
    const step = steps[i];

    // Get position
    const position = step.index + 1;

    let query: string | null = null;
    if (step.id) {
      query = '#' + step.id;
    } else if (step.type === 'text') {
      continue;
    } else {
      query = '*:nth-child(' + position + ')';
    }
    selector.push(query);
  }

  return selector.join(' > ');
}
