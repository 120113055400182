import { Component, inject, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as readerConfigQuery from '@mhe/reader/core/state/configuration/configuration.selectors';
import { PlayerApiClient } from '@mhe/reader/core/player-api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reader-ui-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent implements OnInit {
  readonly $runtime = this.store.pipe(select(readerConfigQuery.runtime));
  private readonly activatedRoute = inject(ActivatedRoute);

  queryParams$ = this.activatedRoute.queryParams;

  constructor(private readonly store: Store, private readonly playerApiClient: PlayerApiClient) {}
  ngOnInit(): void {
    this.playerApiClient.logout().subscribe((resp) => {});
  }
}
