import { BonsaiExpandedNodesMap, SearchResult, SearchSubject } from '@mhe/reader/models';

export interface SearchState {
  downloadPercent: number
  expandedNodes: BonsaiExpandedNodesMap
  minSearchLength: number
  ready: boolean
  results: SearchResult[]
  searchContent: SearchSubject[]
  searching: boolean
}

export const initialSearchState: SearchState = {
  downloadPercent: 0,
  expandedNodes: {},
  minSearchLength: 2,
  ready: false,
  results: [],
  searchContent: [],
  searching: false,
};
