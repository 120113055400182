import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonPurpose } from '@mhe/ngx-shared';

@Component({
  selector: 'rdrx-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.scss'],
})
export class PrintModalComponent {
  selectedChapter: string;

  readonly ButtonPurpose = ButtonPurpose;
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    public readonly dialogRef: MatDialogRef<PrintModalComponent>,
  ) {}

  updateSelection(event: any): void {
    this.selectedChapter = event.value;
  }
}
