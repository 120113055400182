import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@mhe/ngx-shared/button';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontResizerComponent } from './font-resizer.component';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    // cdk
    A11yModule,
    // material
    MatDialogModule,
    // ngx-shared
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
  declarations: [FontResizerComponent],
  exports: [FontResizerComponent],
})
export class FontResizerModule {}
