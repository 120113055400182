import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
export class AleksLogoutService implements OnDestroy {
  protected initialized = false;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly win: Window) {}

  public init(): Subscription | undefined {
    if (this.initialized) {
      return;
    }
    return fromEvent(this.win, 'message')
      .pipe(
        tap((msg) => this.onReceiveMessage(msg)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private onReceiveMessage(event): void {
    /**
     * @todo add some checks for event.origin to confirm coming from ALEKS
     */
    if (typeof event === 'object' && typeof event.data === 'object') {
      if (event.data.type === 'READER.ALEKS.LOGOUT') {
        /**
         * ALEKS hides the URL from the user so we can just do
         * a local redirect to the session timeout route
         */
        this.win.location.href = '/401';
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
