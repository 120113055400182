import { createSelector } from '@ngrx/store';
import { getGlobalState } from '../global-store.selectors';
import { ErrorsState, ERRORS_STATE_KEY } from './errors.state';

const getState = createSelector(
  getGlobalState,
  (state) => state[ERRORS_STATE_KEY],
);

export const hasSessionEnded = createSelector(
  getState,
  (s: ErrorsState): boolean => s.hasSessionEnded,
);
