/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  AwdBridgeAttributes,
  ReadiatorHighlightId,
  ReadiatorAnnotationData,
} from '@mhe/reader/models';
import { ReadiatorBridgeService } from './readiator-bridge.service';

@Injectable()
export class ReadiatorAwdIconService {
  protected readiatorBridgeService: ReadiatorBridgeService;

  public setReadiatorBridge(
    readiatorBridgeService: ReadiatorBridgeService,
  ): void {
    this.readiatorBridgeService = readiatorBridgeService;
  }

  public addAllIcons(
    items: Record<string, ReadiatorAnnotationData>,
    doc: Document,
  ): void {
    const keys = Object.keys(items);
    keys.forEach((key) => {
      const data = items[key];
      this.addIcons(data, doc);
    });
  }

  public addIcons(annotation: ReadiatorAnnotationData, doc: Document): void {
    if (annotation.hasHighlightIcon) {
      this.prependHighlightIcon(annotation, doc);
    }
    if (annotation.hasLR) {
      this.appendLrIcon(annotation, doc);
    }
  }

  public removeAllIcons(doc: Document): void {
    const highlightIcons = doc.querySelectorAll(
      'span[' + AwdBridgeAttributes.HIGHLIGHT_ICON_ID + ']',
    );
    highlightIcons.forEach((highlightIcon) => {
      const highlightIconParent = highlightIcon.parentElement as HTMLElement;
      highlightIconParent.removeChild(highlightIcon);
    });
    const lrIcons = doc.querySelectorAll(
      'button[' + AwdBridgeAttributes.LR_ICON_ID + ']',
    );
    lrIcons.forEach((lrIcon) => {
      const lrIconParent = lrIcon.parentElement as HTMLElement;
      lrIconParent.removeChild(lrIcon);
    });
  }

  public removeIcons(annotation: ReadiatorAnnotationData, doc: Document): void {
    if (annotation.hasHighlightIcon) {
      const highlightIcons = doc.querySelectorAll(
        'span[' +
          AwdBridgeAttributes.HIGHLIGHT_ICON_ID +
          '="' +
          annotation.$id +
          '"]',
      );
      highlightIcons.forEach((highlightIcon) => {
        const highlightIconParent = highlightIcon.parentElement as HTMLElement;
        highlightIconParent.removeChild(highlightIcon);
      });
    }

    if (annotation.hasLR) {
      const lrIcons = doc.querySelectorAll(
        'button[' +
          AwdBridgeAttributes.LR_ICON_ID +
          '="' +
          annotation.$id +
          '"]',
      );
      lrIcons.forEach((lrIcon) => {
        const lrIconParent = lrIcon.parentElement as HTMLElement;
        lrIconParent.removeChild(lrIcon);
      });
    }
  }

  public setFocusOnHiglightIcon(
    data: ReadiatorHighlightId,
    doc: Document,
  ): void {
    const highlightIcon = doc.querySelector(
      'a[' + AwdBridgeAttributes.HIGHLIGHT_ICON_ID + '="' + data.$id + '"]',
    ) as HTMLAnchorElement;
    if (highlightIcon) {
      highlightIcon.focus();
    }
  }

  public setFocusOnLrIcon(data: ReadiatorHighlightId, doc: Document): void {
    const lrIcon = doc.querySelector(
      'button[' + AwdBridgeAttributes.LR_ICON_ID + '="' + data.$id + '"]',
    ) as HTMLAnchorElement;
    if (lrIcon) {
      lrIcon.focus();
    }
  }

  protected prependHighlightIcon(
    annotation: ReadiatorAnnotationData,
    doc: Document,
  ): void {
    // if Highlight icon already exists exist early
    const highlightIconSelector =
      'span[' +
      AwdBridgeAttributes.HIGHLIGHT_ICON_ID +
      '="' +
      annotation.$id +
      '"]';
    const highlightIcons = doc.querySelector(highlightIconSelector);
    if (highlightIcons) {
      return;
    }

    const highlightSelector =
      'mark[' + AwdBridgeAttributes.HIGHLIGHT_ID + '="' + annotation.$id + '"]';
    const marks = doc.querySelectorAll(highlightSelector);
    if (marks.length) {
      const firstMark = marks[0];
      const textContent = this.getTextContent(marks);
      const highlightIcon = this.buildHighlightIcon(
        annotation,
        doc,
        textContent,
      );

      // prevent event collisions if icon inside link
      const linkAncestor = firstMark.closest('a');
      if (!linkAncestor) {
        const parentElement = firstMark.parentElement as HTMLElement;
        parentElement.insertBefore(highlightIcon, firstMark);
      } else {
        const parentElement = linkAncestor.parentElement as HTMLElement;
        parentElement.insertBefore(highlightIcon, linkAncestor);
      }
    }
  }

  protected buildHighlightIcon(
    annotation: ReadiatorAnnotationData,
    doc: Document,
    annotationTextContent,
  ): HTMLSpanElement {
    const highlightIcon = doc.createElement('span');
    highlightIcon.setAttribute(
      AwdBridgeAttributes.HIGHLIGHT_ICON_ID,
      annotation.$id as string,
    );
    highlightIcon.classList.add('readiator-highlight-icon-container');
    highlightIcon.classList.add('mhe-tts-ignore');

    const highlightIconLink = doc.createElement('a');
    highlightIconLink.classList.add('readiator-highlight-icon');

    // NOTE, the innerHTML below shouldn't contain <div> els
    // otherwise TTS might not read the paragraph (EPR-7722)
    highlightIconLink.innerHTML =
      '<i class="dpg-icon-online-questions"><span class="highlight-icon-tooltip">To Questions<span class="arrow-up"></span></span></i>';
    highlightIconLink.href = 'javascript:void(0);';
    highlightIconLink.setAttribute(
      AwdBridgeAttributes.HIGHLIGHT_ICON_ID,
      annotation.$id as string,
    );

    // custom AWD aria-label logic
    let aria = '';
    if (annotation.highlightBtnAria) {
      aria = annotation.highlightBtnAria;
    } else {
      // check if text string has a period at the end
      if (annotationTextContent.match(/\.\s*$/)) {
        aria =
          'Beginning of highlight.  ' +
          annotationTextContent +
          '  End of highlight.  To Questions.';
      } else {
        aria =
          'Beginning of highlight.  ' +
          annotationTextContent +
          '.  End of highlight.  To Questions.';
      }
    }
    highlightIconLink.setAttribute('aria-label', aria);

    // register listeners
    highlightIconLink.addEventListener('click', (event: MouseEvent) => {
      this.onHighlightIconTriggered(event);
    });
    highlightIconLink.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.code === 'enter' || event.code === 'space') {
        this.onHighlightIconTriggered(event);
      }
    });
    highlightIcon.appendChild(highlightIconLink);

    return highlightIcon;
  }

  protected onHighlightIconTriggered(event: Event): void {
    const target = event.currentTarget as Element;
    const highlightId = target.getAttribute(
      AwdBridgeAttributes.HIGHLIGHT_ICON_ID,
    );
    this.readiatorBridgeService.onHighlightIconTriggered(highlightId as string);
  }

  protected appendLrIcon(
    annotation: ReadiatorAnnotationData,
    doc: Document,
  ): void {
    // if LR icon already exists exist early
    const lrIconSelector =
      'button[' + AwdBridgeAttributes.LR_ICON_ID + '="' + annotation.$id + '"]';
    const lrIcons = doc.querySelector(lrIconSelector);
    if (lrIcons) {
      return;
    }

    const highlightSelector =
      'mark[' + AwdBridgeAttributes.HIGHLIGHT_ID + '="' + annotation.$id + '"]';
    const marks = doc.querySelectorAll(highlightSelector);
    if (marks.length) {
      const lastMark = marks[marks.length - 1];
      const lrIcon = this.buildLrIcon(annotation, doc);

      // prevent event collisions if icon inside link
      const ancestorLink = lastMark.closest('button');
      if (!ancestorLink) {
        const parentElement = lastMark.parentElement as HTMLElement;
        parentElement.insertBefore(lrIcon, lastMark.nextSibling);
      } else {
        const parentElement = ancestorLink.parentElement as HTMLElement;
        parentElement.insertBefore(lrIcon, ancestorLink.nextSibling);
      }
    }
  }

  protected onLRIconTriggered(event: Event): void {
    const target = event.currentTarget as Element;
    const highlightId = target.getAttribute(AwdBridgeAttributes.LR_ICON_ID);
    this.readiatorBridgeService.onLRIconTriggered(highlightId as string);
  }

  protected buildLrIcon(
    annotation: ReadiatorAnnotationData,
    doc: Document,
  ): HTMLButtonElement {
    const lrIcon = doc.createElement('button');
    lrIcon.classList.add('readiator-lr-icon');
    lrIcon.classList.add('mhe-tts-ignore');
    lrIcon.innerHTML =
      '<i class="dpg-icon-module"><span class="lr-icon-tooltip">Concept Resources<span class="arrow-up"></span></span></i>';

    // custom AWD aria-label logic
    if (annotation.lrBtnAria) {
      lrIcon.setAttribute('aria-label', annotation.lrBtnAria);
    }

    // register listeners
    lrIcon.setAttribute(
      AwdBridgeAttributes.LR_ICON_ID,
      annotation.$id as string,
    );
    lrIcon.addEventListener('click', (event) => {
      this.onLRIconTriggered(event);
    });
    lrIcon.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.code === 'enter' || event.code === 'space') {
        this.onLRIconTriggered(event);
      }
    });
    return lrIcon;
  }

  protected getTextContent(items: NodeList): string {
    let textContent = '';
    items.forEach((item) => {
      if (item.textContent) {
        textContent = textContent + item.textContent;
      }
    });
    return textContent;
  }
}
