<div class="error-page-container">
  <div>
    <div class="error-code">
      <span>401</span>
    </div>

    <div
      *ngIf="
        ($runtime | async)?.resourceLinkTitle === 'eBookshelf' &&
        ($runtime | async)?.oauthConsumerKey !== 'aleks'
      "
      class="error-message"
    >
      <!-- Message shown to eBookshelf users -->
      <section>
        <h1>{{ 'errorPage.whoops' | translate }}</h1>
        <h3>{{ 'errorPage.401.title' | translate }}</h3>
      </section>
      <section>
        <h3>{{ 'errorPage.reason' | translate }}</h3>
        <ul [innerHTML]="'errorPage.401.reason_list' | translate"></ul>
      </section>
      <section>
        <h3>{{ 'errorPage.try' | translate }}</h3>
        <ul [innerHTML]="'errorPage.401.try_list' | translate"></ul>
      </section>
      <ng-container *ngIf="queryParams$ | async as queryParams">
        <section class="metadata">
          <!-- Metadata -->
          <ul *ngFor="let param of queryParams | keyvalue">
            <li>{{ param.key }}: {{ param.value }}</li>
          </ul>
        </section>
      </ng-container>
    </div>

    <div
      *ngIf="
        ($runtime | async)?.resourceLinkTitle !== 'eBookshelf' &&
        ($runtime | async)?.oauthConsumerKey === 'aleks'
      "
      class="error-message"
    >
      <!-- Message shown to ALEKS users -->
      <section>
        <h3>{{ 'errorPage.401.aleks' | translate }}</h3>
      </section>
      <ng-container *ngIf="queryParams$ | async as queryParams">
        <section class="metadata">
          <!-- Metadata -->
          <ul *ngFor="let param of queryParams | keyvalue">
            <li>{{ param.key }}: {{ param.value }}</li>
          </ul>
        </section>
      </ng-container>
    </div>

    <div
      *ngIf="
        ($runtime | async)?.resourceLinkTitle !== 'eBookshelf' &&
        ($runtime | async)?.oauthConsumerKey !== 'aleks'
      "
      class="error-message"
    >
      <!-- Default logout message shown (not ALEKS and not eBookshelf) -->
      <section>
        <h1>{{ 'errorPage.sessionTimeout.title' | translate }}</h1>
        <h3>{{ 'errorPage.sessionTimeout.text' | translate }}</h3>
      </section>
      <section>
        <h3>{{ 'errorPage.sessionTimeout.reason' | translate }}</h3>
        <ul [innerHTML]="'errorPage.sessionTimeout.reason_list' | translate"></ul>
      </section>
      <section>
        <h3>{{ 'errorPage.sessionTimeout.try' | translate }}</h3>
        <p>{{ 'errorPage.sessionTimeout.or' | translate }}</p>
        <h3>{{ 'errorPage.sessionTimeout.tryBrowserSettings' | translate }}</h3>
      </section>
      <ng-container *ngIf="queryParams$ | async as queryParams">
        <section class="metadata">
          <!-- Metadata -->
          <ul *ngFor="let param of queryParams | keyvalue">
            <li>{{ param.key }}: {{ param.value }}</li>
          </ul>
        </section>
      </ng-container>
    </div>
  </div>
</div>
