import { createAction, props } from '@ngrx/store';

import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationType } from '../../annotation.data';

export const annotationSelected = createAction(
  'Placemark List Annotation Selected',
  props<{ annotation: ApiAnnotation }>(),
);

export const askForAnnotations = createAction(
  'Placemark Ask for Annotations',
  props<{ spineID: string }>(),
);

export const placemarkDelete = createAction(
  'Placemark List Annotation Delete',
  props<{ annotation: ApiAnnotation }>(),
);

export const getAnnotationsForLabel = createAction(
  'Placemark Get Annotations For Label',
  props<{ spineID: string }>(),
);

export const placemarksBatchDelete = createAction(
  'Placemarks List Annotation Batch Delete',
  props<{ data: { annotations: ApiAnnotation[], type: AnnotationType } }>(),
);

export type PlacemarkListActions = ReturnType<
| typeof annotationSelected
| typeof placemarkDelete
| typeof askForAnnotations
| typeof getAnnotationsForLabel
| typeof placemarksBatchDelete
>;
