import { Injectable } from '@angular/core';

import { Book, SpineItem } from '../models/epub';
import { Next, Transformer } from './transformer';

import { ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';

@Injectable()
export class AiReaderCitationTransformer implements Transformer {
  constructor(
    private readonly readerStore: ReaderStore,
  ) { }

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    this.readerStore.dispatch(readerActions.preRender);
    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    this.readerStore.dispatch(readerActions.postRender);
    return await next(content);
  }
}
