import { Injectable } from '@angular/core';

/**
 * Mocking out the AppConfig service until it gets built.
 * The interface will probably change once implemented.
 */
@Injectable()
export class MockAppConfigService {
  public featureFlags: { lazyLoad: boolean } = {
    lazyLoad: false,
  };

  public apiEndpoint = '';
  public mathJaxCDN = '';
}
