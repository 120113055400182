import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TooltipDirective } from '@mhe/ngx-shared/tooltip';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';

@Directive({ selector: '[rdrxMatTabTooltip]' })
export class ReaderMatTabTooltipDirective implements OnInit, OnDestroy {
  @Input('rdrxMatTabTooltip') tooltip: TooltipDirective;

  private readonly matTabLabelSelector = 'mdc-tab';
  private matTabLabel: HTMLElement;

  constructor(private readonly el: ElementRef, private readonly focusMonitor: FocusMonitor) {}

  ngOnInit(): void {
    const cssClass = `.${this.matTabLabelSelector}`;
    this.matTabLabel = this.el.nativeElement.closest(cssClass);

    if (this.matTabLabel) {
      this.focusMonitor.monitor(this.matTabLabel).subscribe((origin) => {
        const openOrigins: FocusOrigin[] = ['keyboard', 'program'];

        if (!origin) {
          this.tooltip.close();
        } else if (openOrigins.includes(origin)) {
          this.tooltip.open();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.matTabLabel) {
      this.focusMonitor.stopMonitoring(this.matTabLabel);
    }
  }
}
