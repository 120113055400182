import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { Store, select } from '@ngrx/store';
import { Observable, interval, Subject } from 'rxjs';
import {
  exhaustMap,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as readerConfigQuery from '@mhe/reader/core/state/configuration/configuration.selectors';
import { MediatorUtils } from './mediator-utils';
import { PlayerApiClient } from '@mhe/reader/core/player-api/player-api-client.service';
import {
  ConfirmationModalComponent,
  ConfirmationModalData,
} from '@mhe/reader/components/modals/confirmation-modal';
import { ReaderStore } from '@mhe/reader/components/reader/state';
import { ConcurrencyService } from '../core/reader-api/concurrency.service';
import { EBookshelfConcurrencyResponse } from '../models';

@Injectable()
export class EBookshelfMediator extends ComponentEffects {
  constructor(
    protected window: Window,
    private readonly dialog: MatDialog,
    private readonly playerApiClient: PlayerApiClient,
    private readonly concurrencyService: ConcurrencyService,
    private readonly readerStore: ReaderStore,
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  private readonly ebookshelfConcurrencyTimeout$ = this.effect(() => {
    const stopPolling$ = new Subject();

    const envInterval$ = this.util.playerApi$.pipe(
      map((api) => api?.includes('dev')),
      map((isDev) => (isDev ? 60000 : 285000)), // 1min | 4.75min
    );

    const filterEbookshelfResouce$ = <T>(
      source$: Observable<T>,
    ): Observable<T> => {
      const resouceTitle$ = this.store
        .pipe(select(readerConfigQuery.runtime))
        .pipe(map(({ resourceLinkTitle }) => resourceLinkTitle));

      return source$.pipe(
        withLatestFrom(resouceTitle$),
        filter(([_, resourceTitle]) => resourceTitle === 'eBookshelf'),
        map(([source]) => source),
      );
    };

    const pollEbookshelfConcurrency$ = (
      pollInterval: number,
    ): Observable<EBookshelfConcurrencyResponse> => {
      return interval(pollInterval).pipe(
        switchMap(() => {
          return this.concurrencyService.checkEbookshelfConcurrency().pipe(
            filter(({ result }) => result !== 1),
            tap(() => {
              stopPolling$.next(null);
              this.openEbookshelfTimeoutDialog$();
              // TODO: hide background
              this.readerStore.setHideReader(true);
            }),
          );
        }),
      );
    };

    return envInterval$.pipe(
      filterEbookshelfResouce$,
      switchMap((pollInterval) =>
        pollEbookshelfConcurrency$(pollInterval).pipe(takeUntil(stopPolling$)),
      ),
      logCatchError('ebookshelfConcurrencyTimeout$'),
    );
  });

  private readonly openEbookshelfTimeoutDialog$ = this.effect(
    (open$: Observable<void>) => {
      return open$.pipe(
        this.util.tapGaEventWithTitle({
          eventCategory: 'Dialog',
          eventAction: 'eBookshelf Session Timeout',
        }),
        exhaustMap(() => this.ebookshelfTimeoutDialog$().afterClosed()),
        tap(() => this.window.open('', '_self')?.close()),
      );
    },
  );

  private ebookshelfTimeoutDialog$(): MatDialogRef<
  ConfirmationModalComponent,
  ConfirmationModalData
  > {
    const title = this.translate.instant(
      'dialog.bookshelf_session_timeout_warning.title',
    );
    const closeText = this.translate.instant(
      'dialog.bookshelf_session_timeout_warning.buttontext',
    );

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const h3_hdr = this.translate.instant(
      'dialog.bookshelf_session_timeout_warning.text',
    );
    const li = this.translate.instant(
      'dialog.bookshelf_session_timeout_warning.reasonlist',
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const h3_bottomtext = this.translate.instant(
      'dialog.bookshelf_session_timeout_warning.bottomtext',
    );

    const content = `<h3>${h3_hdr}</h3>
                      <ul>${li}</ul>
                      <h3>${h3_bottomtext}</h3>`;

    const data: ConfirmationModalData = { title, content, closeText };

    return this.dialog.open(ConfirmationModalComponent, {
      data,
      disableClose: true,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }
}
