export interface FontResizerState {
  readonly initialSliderValue: number | null
  readonly fontsizeValue: number
  readonly disabled: boolean
}

export const initialFontResizerState: FontResizerState = {
  initialSliderValue: null,
  fontsizeValue: 0,
  disabled: false,
};
