import { Component, ElementRef, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TTSStore } from '../../state/tts.store';
import * as ttsActions from '../../state/tts.actions';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { TTSAudioContext } from '@mhe/reader/models';

@Component({
  selector: 'rdrx-page-tts-controls',
  templateUrl: './page-tts-controls.component.html',
  styleUrls: ['./page-tts-controls.component.scss'],
})
export class PageTTSControlsComponent {
  /**
   * Auto focuses the readAloudButton when
   * the button is defined.
   */
  private autoFocusReadAloudButton = false;
  public readonly TTSAudioContext = TTSAudioContext;

  // eslint-disable-next-line accessor-pairs
  @ViewChild('readAloudButton', { read: ElementRef }) set content(
    readAloudButton: ElementRef,
  ) {
    if (readAloudButton?.nativeElement && this.autoFocusReadAloudButton) {
      readAloudButton.nativeElement.focus();
    }
  }

  readonly ButtonPurpose = ButtonPurpose;
  readonly showLoading$ = combineLatest([
    this.ttsStore.docTTSControlReq$,
    this.ttsStore.isLoading$,
  ]).pipe(map(([controlsReq, isLoading]) => controlsReq && isLoading));

  readonly showDocTTSControl$ = combineLatest([
    this.ttsStore.docTTSControlReq$,
    this.ttsStore.isLoading$,
  ]).pipe(map(([controlsReq, isLoading]) => controlsReq && !isLoading));

  readonly showPageTTSButton$ = combineLatest([
    this.showDocTTSControl$,
    this.showLoading$,
  ]).pipe(
    map(
      ([showDocTTSControl, showLoading]) => !showDocTTSControl && !showLoading,
    ),
  );

  readonly controlDisabled$ = this.ttsStore.disabled$;

  constructor(private readonly ttsStore: TTSStore) {}

  onReadPageClick(): void {
    this.ttsStore.dispatch(ttsActions.togglePageTTSControls({ val: true }));
  }

  onClose(): void {
    this.autoFocusReadAloudButton = true;
    // initiate the panel closing process
    this.ttsStore.dispatch(ttsActions.togglePageTTSControls({ val: false }));
  }
}
