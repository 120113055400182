import { Injectable, Renderer2 } from '@angular/core';

import { AnchorType, Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';
import { firstValueFrom } from 'rxjs';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';

@Injectable()
export class LargeImageNavigatorTransformer implements Transformer {
  constructor(private readonly renderer: Renderer2,
    private readonly readerConfigStore: ReaderConfigStore) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement | null,
    next: Next,
  ): Promise<HTMLDocument> {
    // TODO(styles): move to a common exhibit stylesheet
    const styles = this.renderer.createText('.mhe-inline-credit { display: none }');
    const styleTag = this.renderer.createElement('style');
    this.renderer.appendChild(styleTag, styles);
    this.renderer.appendChild(content.head, styleTag);

    return await next(content);
  }

  // This needs to be postRender because images don't have a size if they're not rendered
  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement | null,
    next: Next,
  ): Promise<HTMLDocument> {
    const links = await firstValueFrom(this.readerConfigStore.links$);
    const clickableImageElements: HTMLImageElement[] = Array.from(content.querySelectorAll('img'));

    // wrap images in an anchor tag which will handle the click events.
    clickableImageElements
      .filter((img) => img.parentElement?.tagName !== 'A')
      .filter((img) => img.clientHeight > 100 && img.clientWidth > 100)
      .filter((img) => img.getAttribute('role') !== 'presentation')
      .filter(this.isNotGif)
      .filter(this.isImageWithLIN)
      .forEach((img) => {
        const imgClone = img.cloneNode() as HTMLImageElement;
        const imgAnchor = this.renderer.createElement('a');
        const imgAlt = img.getAttribute('alt');
        const imgTitle = img.getAttribute('title');

        if (imgTitle) {
          this.renderer.setAttribute(imgClone, 'data-title', imgTitle);
          this.renderer.removeAttribute(imgClone, 'title');
        }

        this.renderer.addClass(imgAnchor, 'mhe-annotations-ignorable');
        this.renderer.addClass(imgAnchor, 'mhe-annotations-image');
        if (!links) {
          this.renderer.addClass(imgAnchor, 'disable-link');
        }
        this.renderer.setAttribute(imgAnchor, 'href', 'javascript:void(0);');
        this.renderer.setAttribute(imgAnchor, 'epub:type', AnchorType.LARGE_IMAGE.toString());
        // TODO(i18n): Needs translation
        this.renderer.setAttribute(
          imgClone,
          'alt',
          `${imgAlt} large image navigator opens in a modal`,
        );
        this.renderer.appendChild(imgAnchor, imgClone);

        img.replaceWith(imgAnchor); // This didn't have a renderer equivalent
      });

    return await next(content);
  }

  isNotGif(image: HTMLImageElement): boolean {
    return !image.src?.endsWith('.gif');
  }

  isImageWithLIN = (image: HTMLImageElement): boolean => image.dataset.mhLinDisable !== 'true';
}
