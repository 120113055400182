import { createAction, props } from '@ngrx/store';

/** loading glossary */
// check if glossary is in store - if not, dispatch laod
export const fetchGlossary = createAction('[Reader] Fetch Glossary', props<{ url: URL }>());
// loadGlossary - typical http load pattern | init, success, error
export const loadGlossary = createAction('[Reader] Load Glossary', props<{ url: string }>());
export const loadGlossarySuccess = createAction(
  '[Reader] Load Glossary Success',
  props<{ url: string, glossaryDocument: XMLDocument }>(),
);
export const loadGlossaryError = createAction(
  '[Reader] Load Glossary Error',
  props<{ url: string, error: Error }>(),
);
