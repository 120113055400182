import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import {
  EBookshelfConcurrencyResponse,
} from '@mhe/reader/models';
import * as readerConfigQuery from '../state/configuration/configuration.selectors';

@Injectable({ providedIn: 'root' })
export class ConcurrencyService {
  private readonly readerApiUrl$: Observable<string | undefined>;

  constructor(store: Store, private readonly http: HttpClient) {
    this.readerApiUrl$ = store.pipe(
      select(readerConfigQuery.getReaderApi),
      filter((s: string | undefined) => typeof s === 'string' && s.length > 0),
      first(),
    );
  }

  checkEbookshelfConcurrency(): Observable<EBookshelfConcurrencyResponse> {
    return this.readerApiUrl$.pipe(
      mergeMap((readerApiUrl) => {
        return this.http.get<EBookshelfConcurrencyResponse>(`${readerApiUrl}/v1/concurrency`, {
          withCredentials: true,
        });
      }),
    );
  }
}
