import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { SimpleMessageModalData } from './simple-message-modal.model';
import { MathjaxService } from '@mhe/reader/features/mathjax';

@Component({
  selector: 'rdrx-simple-message-modal',
  templateUrl: './simple-message-modal.component.html',
  styleUrls: ['./simple-message-modal.component.scss'],
})
export class SimpleMessageModalComponent implements AfterViewInit, OnDestroy {
  message$ = this.data.message$;
  messageText: SafeHtml = {};

  error$ = this.data.error$;
  isLoading$ = this.data.isLoading$;
  title = this.data.title;

  alertType = AlertType;
  buttonPurpose = ButtonPurpose;

  private readonly contentSelector = '.viewer-container';
  private readonly destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: SimpleMessageModalData,
    public readonly dialogRef: MatDialogRef<SimpleMessageModalComponent>,
    public readonly mathjaxService: MathjaxService,
    public readonly sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit(): void {
    this.message$
      .pipe(
        tap((message) => {
          this.messageText = this.sanitizer.bypassSecurityTrustHtml(message.text); // prevent Angular from stripping out HTML
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.mathjaxService.handleMath(this.contentSelector));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
