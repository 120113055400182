import { Injectable, Renderer2 } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import {
  catchError,
  filter,
  switchMapTo,
  take,
  tap,
  withLatestFrom,
  map,
} from 'rxjs/operators';

import { EpubViewerStore } from '../epub-viewer.store';
import {
  EpubViewerFontSizeState,
  initialEpubViewerFontSizeState,
} from './font-size.state';
import * as actions from '../epub-viewer.actions';

@Injectable()
export class EpubViewerFontSizeStore extends ExtendedComponentStore<
EpubViewerFontSizeState,
actions.EpubViewerActions
> {
  private readonly epubViewerActions$ = this.epubViewerStore.actions$;
  private readonly iframe$ = this.epubViewerStore.cloIframe$.pipe(
    filter((iframe) => Boolean(iframe)),
  );

  /** effect helpers */
  private readonly filterDisabled = <T>(
    source$: Observable<T>,
  ): Observable<T> =>
    source$.pipe(
      withLatestFrom(this.disabled$),
      filter(([, disabled]) => !disabled),
      map(([source]) => source),
    );

  constructor(
    private readonly epubViewerStore: EpubViewerStore,
    private readonly renderer: Renderer2,
  ) {
    super(initialEpubViewerFontSizeState);
  }

  /** selectors */
  readonly fontsize$ = this.select(({ fontsize }) => fontsize);
  readonly disabled$ = this.select(({ disabled }) => disabled);

  /** updaters */
  readonly setFontSize = this.updater((state, fontsize: number) => ({
    ...state,
    fontsize,
  }));

  readonly setDisabled = this.updater((state, disabled: boolean) => ({
    ...state,
    disabled,
  }));

  /** effects */
  private readonly _renderFontSize$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(actions.preRenderComplete),
      this.filterDisabled,
      withLatestFrom(this.fontsize$),
      tap(([{ document }, fontsize]) =>
        this.setDocumentFontSize(document, fontsize),
      ),
      catchError(() => EMPTY),
    ),
  );

  private readonly _applyFontSize$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(actions.applyFontSize),
      this.filterDisabled,
      switchMapTo(this.iframe$.pipe(take(1))),
      withLatestFrom(this.fontsize$),
      tap(([{ contentDocument }, fontsize]) =>
        this.setDocumentFontSize(contentDocument as Document, fontsize),
      ),
      catchError(() => EMPTY),
    ),
  );

  /** utils */
  private setDocumentFontSize(document: HTMLDocument, fontsize: number): void {
    const body = document.querySelector('body');
    this.renderer.setStyle(body, 'font-size', `${fontsize}em`);
  }
}
