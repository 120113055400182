/* eslint-disable @typescript-eslint/naming-convention */
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { loadEpub, loadEpubError, loadEpubSuccess } from './epub.actions';
import { epubProcess } from './epub.reducer.utils';
import { EpubDictionaryState, initialState } from './epub.state';

export const epubReducer: ActionReducer<EpubDictionaryState> = createReducer(
  initialState,
  on(loadEpub, (state, { url_leveled }) => ({
    ...state,
    [url_leveled]: { ...state[url_leveled], loading: true, error: undefined },
  })),
  on(loadEpubSuccess, (state, { url_leveled, book }) => ({
    ...state,
    [url_leveled]: { ...epubProcess(book), loading: false },
  })),
  on(loadEpubError, (state, { url_leveled, error }) => ({
    ...state,
    [url_leveled]: { ...state[url_leveled], loading: false, error },
  })),
);
