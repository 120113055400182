import { createAction, props } from '@ngrx/store';

import {
  DocTransform,
  LexileLevel,
  SelectionDetails,
} from '@mhe/reader/models';
import { WidgetManager } from '@mhe/reader/transform/widget-manager';

export const anchorClick = createAction(
  'Anchor Click',
  props<{ anchor: HTMLAnchorElement }>(),
);
export const rangeSelected = createAction(
  'Range Selected',
  props<{
    selection: Selection
    devicePlatform: string
    details?: SelectionDetails
  }>(),
);
export const rangeRemoved = createAction('Range Removed');

// global wiris
export const wirisRequest = createAction(
  'WIRIS Editor Request',
  props<{
    requestId: string
    mathMLContent: string
    toolbar: string
    callingWidgetManager: WidgetManager
  }>(),
);

// assignments
export const launchAssessment = createAction(
  'Launch Assessment',
  props<{ widgetFrame: HTMLIFrameElement }>(),
);

// user roles
export const userRole = createAction(
  'user role',
  props<{ content: HTMLDocument }>(),
);
export const subscribeTeacherContentChange = createAction(
  'Subscribe to Teacher Content Enabled changes',
  props<{ content: HTMLDocument }>(),
);

// lexile levels
export const applyLexileLevel = createAction(
  'Apply active lexile level',
  props<{ content: HTMLDocument }>(),
);
export const subscribeLexileLevelChange = createAction(
  'Subscribe to lexile level changes',
  props<{ content: HTMLDocument }>(),
);
export const setPageLexileLevels = createAction(
  'Set available page Lexile Levels',
  props<{ pgLevels: LexileLevel[] }>(),
);

// bento digital tagging
export const bentoDigitalTaggingInit = createAction(
  'Bento Digital Tagging Init',
  props<{ content: HTMLDocument }>(),
);

// theme park
export const themeParkInit = createAction(
  'Theme Park Init',
  props<{ content: HTMLDocument }>(),
);

// authoring
export const authoringPrerenderStart = createAction(
  'Authoring Prerender Start',
  props<{ dt: DocTransform }>(),
);

export const authoringPrerenderDone = createAction('Authoring Prerender Done');

export const authoringPostrenderStart = createAction(
  'Authoring Postrender Start',
  props<{ dt: DocTransform }>(),
);

export const authoringPostrenderDone = createAction(
  'Authoring Postrender Done',
);

/** type */
export type TransformActions = ReturnType<
| typeof anchorClick
| typeof bentoDigitalTaggingInit
| typeof rangeSelected
| typeof rangeRemoved
| typeof themeParkInit
| typeof wirisRequest
| typeof launchAssessment
// user roles
| typeof userRole
| typeof subscribeTeacherContentChange
// lexile levels
| typeof applyLexileLevel
| typeof subscribeLexileLevelChange
| typeof setPageLexileLevels
// authoring
| typeof authoringPrerenderStart
| typeof authoringPrerenderDone
| typeof authoringPostrenderStart
| typeof authoringPostrenderDone
>;
