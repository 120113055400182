import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';
import {
  DoubleSpineItem,
  FORCE_REFRESH_HASH,
  SpineItem,
} from '@mhe/reader/models';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';

@Injectable()
export class SpineItemMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly navigationActions$ = this.navigationStore.actions$;

  constructor(
    private readonly navigationStore: NavigationStore,
    private readonly readerStore: ReaderStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /** active single spine item */
  private readonly spineItem$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateTo),
      withLatestFrom(this.readerStore.linearSpine$),
      map(([{ index, hash, setFocus }, spine]) => ({
        spineItem: (spine as SpineItem[])[index],
        hash,
        setFocus,
      })),
      tap(({ spineItem, hash, setFocus }) =>
        this.util.setSpineItem(spineItem, hash, setFocus),
      ),
      logCatchError('spineItem$'),
    ),
  );

  private readonly renderSpineItem$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setSpineItem),
      withLatestFrom(this.readerStore.book$),
      tap(([{ spineItem, hash, setFocus }, book]) =>
        this.util.epubRender(book, spineItem, hash, setFocus),
      ),
      logCatchError('renderSpineItem$'),
    ),
  );

  /** active double spine item */
  private readonly doublePageItem$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateDoubleSpreadTo),
      withLatestFrom(this.readerStore.doubleSpine$),
      map(
        ([{ index }, doubleSpine]) => (doubleSpine as DoubleSpineItem[])[index],
      ),
      tap((dsItem) => this.util.setDoubleSpineItem(dsItem)),
      logCatchError('doublePageItem$'),
    ),
  );

  private readonly renderDoubleSpine$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setDoubleSpineItem),
      withLatestFrom(this.readerStore.book$),
      tap(([{ spineItem }, book]) =>
        this.util.epubRenderDouble(book, spineItem),
      ),
      logCatchError('renderDoubleSpine$'),
    ),
  );

  private readonly forceRefreshSpineItem$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.forceRefreshSpineItem),
      withLatestFrom(
        this.readerStore.linearSpine$,
        this.navigationStore.index$,
        this.readerStore.book$,
      ),
      map(([{}, spine, index, book]) => ({
        spineItem: (spine as SpineItem[])[index],
        book,
      })),
      tap(({ spineItem, book }) =>
        this.util.epubRender(book, spineItem, FORCE_REFRESH_HASH),
      ),
      logCatchError('forceRefreshSpineItem$'),
    ),
  );
}
