import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BonsaiTree, AnnotationListBonsaiNode } from '@mhe/reader/models';
import { AnnotationListStore } from '../../utils/annotation-list.store.abstract';
import { groupsToTree } from '../../utils/groups-to-tree';
import { PlacemarkListActions } from './placemark-list.actions';
import {
  PlacemarkListState,
  initialPlacemarkListState,
} from './placemark-list.state';

@Injectable()
export class PlacemarkListStore extends AnnotationListStore<
PlacemarkListState,
PlacemarkListActions
> {
  /** selectors **/
  readonly tree$: Observable<BonsaiTree<AnnotationListBonsaiNode>> =
    this.select(this.groups$, (groups) =>
      groupsToTree(groups, (annotation) => !!annotation.placemarkText),
    );

  constructor() {
    super(initialPlacemarkListState);
  }
}
