<div class="viewer-container">
  <rdrx-modal-header [withClose]="false">
    <h1
      class="dialog-heading"
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
      id="global-wiris-modal-h1"
    >
      {{ title }}
    </h1>
  </rdrx-modal-header>
  <div class="body js-wiris-editor-container wiris-dialog"></div>
  <div class="message-error" *ngIf="error$ | async">
    <mhe-alert [type]="alertType.Error" [closable]="false">
      {{ 'dialog.failed_message' | translate }}
    </mhe-alert>
  </div>
  <rdrx-modal-footer class="action-bar">
    <div class="action-group">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Secondary"
        class="action-item action-button"
        [attr.aria-label]="'dialog.cancel' | translate"
        (click)="closeDialog(false)"
      >
        Cancel
      </button>
      <button
        mhe-button
        [purpose]="ButtonPurpose.Primary"
        class="action-item action-button"
        [attr.aria-label]="'dialog.accept' | translate"
        (click)="closeDialog(true)"
      >
        Accept
      </button>
    </div>
  </rdrx-modal-footer>
</div>
