import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { CONFIG_FEATURE_KEY } from './config/config.state';
import { configReducer } from './config/config.reducer';
import { SESSION_FEATURE_KEY, sessionReducer } from './session';

const metaReducers = environment.production ? [] : [];
const reducers = {
  [CONFIG_FEATURE_KEY]: configReducer,
  [SESSION_FEATURE_KEY]: sessionReducer,
};
const imports = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictActionImmutability: true,
      strictStateImmutability: true,
    },
  }),
];
if (!environment.production) {
  imports.push(StoreDevtoolsModule.instrument());
}

@NgModule({
  imports,
})
export class StateModule {}
