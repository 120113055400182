import { Injectable } from '@angular/core';
import { AnnotationData } from '@mhe/reader/models';

/**
 * This service wraps around the ePubAnnotation.annotationHighlighter sub-libarary
 * Try not to use this low-level library, instead use the HighlighterService when possible.
 * https://github.mheducation.com/MHEducation/DLE-Player-Annotations
 */

@Injectable()
export class EpubLibHighlighterService {
  /**
   * The Highlighter Library from epubAnnotation GLOBAL
   */
  protected epubHighlighter;

  constructor(protected window: Window) {
    // check if ePubAnnotation global exists
    if ((this.window as any).ePubAnnotation === undefined) {
      // @todo how to handle this case?
    } else {
      this.epubHighlighter = new (
        this.window as any
      ).ePubAnnotation.Highlighter('annotation');
    }
  }

  public setBaseCFI(cfi: string): void {
    this.epubHighlighter.setBaseCFI(cfi);
  }

  public addMarksForAnnotation(
    annotation: AnnotationData,
    index: number,
    doc: Document,
    optionalLegacyVersion: string,
    nonTextFlag: boolean,
  ): void {
    this.epubHighlighter.addMarksForAnnotation(
      annotation,
      index,
      doc,
      optionalLegacyVersion,
      nonTextFlag,
      'id',
    );
  }

  public removeMarksForAnnotation(
    doc: Document,
    annotation: AnnotationData,
    nonTextFlag: boolean,
  ): void {
    this.epubHighlighter.removeMarksForAnnotation(
      doc,
      annotation,
      nonTextFlag,
      'id',
    );
  }
}
