import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SearchResult } from '@mhe/reader/models';
import { EpubViewerStore } from '../epub-viewer.store';

@Injectable()
export class EpubViewerSearchHighlightUtils {
  private readonly isDoubleSpread$ = this.epubViewerStore.isDoubleSpread$;
  private readonly albumMode$ = this.epubViewerStore.albumMode$;

  private readonly iframe$ = this.epubViewerStore.cloIframe$.pipe(
    filter((iframe) => Boolean(iframe)),
  );

  private readonly leftIframe$ = this.epubViewerStore.leftIframe$.pipe(
    filter((iframe) => Boolean(iframe)),
  );

  private readonly rightIframe$ = this.epubViewerStore.rightIframe$.pipe(
    filter((iframe) => Boolean(iframe)),
  );

  constructor(private readonly epubViewerStore: EpubViewerStore) {}

  /** operators */
  readonly withLatestDoubleSpreadMode = <T>(
    source$: Observable<T>,
  ): Observable<[T, boolean]> => {
    return source$.pipe(
      withLatestFrom(this.isDoubleSpread$, this.albumMode$),
      map(([source, isDoubleSpread, albumMode]) => {
        const ids = isDoubleSpread && !albumMode;
        return [source, ids] as [T, boolean];
      }),
    );
  };

  readonly mapWithIframe = (
    source$: Observable<SearchResult>,
  ): Observable<{ result: SearchResult, iframe: HTMLIFrameElement }> => {
    return source$.pipe(
      this.withLatestDoubleSpreadMode,
      switchMap((source) => {
        const iframe = this.getHighlightIfame(source);
        return of(source).pipe(withLatestFrom(iframe));
      }),
      map(([[result], iframe]) => ({ result, iframe })),
    );
  };

  readonly getHighlightIfame = ([{ frameSide }, isDoubleSpread]: [
    SearchResult,
    boolean,
  ]): Observable<HTMLIFrameElement> => {
    if (!isDoubleSpread) return this.iframe$;

    switch (frameSide) {
      case 'left':
        return this.leftIframe$;
      case 'right':
        return this.rightIframe$;
      default:
        return this.iframe$;
    }
  };

  readonly withLatestEpubDocuments = <T>(
    source$: Observable<T>,
  ): Observable<[T, Document, Document, Document]> => {
    return source$.pipe(
      withLatestFrom(this.iframe$, this.leftIframe$, this.rightIframe$),
      map(([source, clo, left, right]): [T, Document, Document, Document] => [
        source,
        clo.contentDocument as Document,
        left.contentDocument as Document,
        right.contentDocument as Document,
      ]),
    );
  };
}
