import { InjectionToken } from '@angular/core';

import { AnchorTransformer } from './anchor.transformer';
import { AriaTransformer } from './aria.transformer';
import { AssessmentWidgetsTransformer } from './assessment-widgets.transformer';
import { AudioTransformer } from './audio.transformer';
import { AuthoringTransformer } from './authoring.transformer';
import { BentoDigitalTaggingTransformer } from './bento-digital-tagging.transformer';
import { EnhanceLinksTransformer } from './enhance-links.transformer';
import { EnsureLanguageAttributeTransformer } from './ensure-language-attribute.transformer';
import { EpubIframeStylesTransformer } from './epub-iframe-styles.transformer';
import { GifTransformer } from './gif.transformer';
import { LargeImageNavigatorTransformer } from './large-image-navigator.transformer';
import { LazyLoadIframesTransformer } from './lazy-load-iframes.transformer';
import { LexileLevelTransformer } from './lexile-level.transformer';
import { MathjaxTransformer } from './mathjax.transformer';
import { ReaderRolesTransformer } from './reader-roles.transformer';
import { SelectionTransformer } from './selection.transformer';
import { SetBaseTransformer } from './set-base.transformer';
import { ThemeParkTransformer } from './theme-park.transformer';
import { Transformer, TransformerConstructor } from './transformer';
import { WidgetsManagerTransformer } from './widgets-manager.transformer';
import { PreventCopyTransformer } from './prevent-copy.transformer';
import { AnnotationCssTransformer } from './annotation-css.transformer';
import { AiReaderCitationTransformer } from './ai-reader-citation.transformer';
import { OrphanAnnotationsTransformer } from './orphan-annotations.transformer';

export const defaultTransforms: Array<TransformerConstructor | InjectionToken<Transformer>> = [
  EpubIframeStylesTransformer,
  EnhanceLinksTransformer,
  EnsureLanguageAttributeTransformer,
  SetBaseTransformer,
  PreventCopyTransformer,
  LargeImageNavigatorTransformer,
  GifTransformer,
  LazyLoadIframesTransformer,
  MathjaxTransformer,
  AnchorTransformer,
  AriaTransformer,
  WidgetsManagerTransformer,
  AssessmentWidgetsTransformer,
  BentoDigitalTaggingTransformer,
  SelectionTransformer,
  ReaderRolesTransformer,
  ThemeParkTransformer,
  LexileLevelTransformer,
  AudioTransformer,
  AuthoringTransformer,
  AnnotationCssTransformer,
  AiReaderCitationTransformer,
  OrphanAnnotationsTransformer,
];
