import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import {
  catchError,
  filter,
  map,
  pairwise,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';

import { MediatorUtils } from './mediator-utils';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { ReaderStore } from '../components/reader/state';
import * as toolbarActions from '../components/reader/state/toolbar.actions';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import { EpubLibCFIService, ParsedCfi } from '@mhe/reader/features/annotation';
import { hasActiveItem } from '@mhe/reader/utils';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { TocStore } from '@mhe/reader/components/toc';
import * as tocActions from '@mhe/reader/components/toc';
import { DoubleSpineItem, SpineItem } from '../models';

@Injectable()
export class NavigationMediator extends ComponentEffects {
  private readonly navigationActions$ = this.navigationStore.actions$;
  private readonly tocActions$ = this.tocStore.actions$;
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly albumMode$ = this.epubViewerStore.albumMode$;
  private readonly epubViewerActions$ = this.epubViewerStore.actions$;

  constructor(
    private readonly epubCfiLib: EpubLibCFIService,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly navigationStore: NavigationStore,
    private readonly readerStore: ReaderStore,
    private readonly router: Router,
    private readonly tocStore: TocStore,
    private readonly util: MediatorUtils,
    private readonly config: ReaderConfigStore,
  ) {
    super();
  }

  /** helpers */
  private readonly handleNavigate$ = (
    nav$: Observable<{ index: number, hash?: string, setFocus?: boolean }>,
  ): Observable<
  [
    {
      index: number
      hash?: string | undefined
      setFocus?: boolean | undefined
    },
    boolean,
  ]
  > =>
    nav$.pipe(
      this.util.tapDoubleSpread(
        ({
          index,
          hash,
          setFocus,
        }: {
          index: number
          hash?: string
          setFocus?: boolean
        }) => this.util.navigate(index, hash, setFocus),
        ({
          index,
          setFocus,
        }: {
          index: number
          hash?: string
          setFocus?: boolean
        }) => this.util.navDoubleSpread(index, setFocus),
      ),
    );

  /** navigation */
  private readonly navigate$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(
        navigationActions.navigateTo,
        navigationActions.navigateDoubleSpreadTo,
      ),
      tap(({ index }) => this.navigationStore.setIndex(index)),
      tap(() => this.navigationStore.dispatch(navigationActions.afterNavigation)),
      logCatchError('navigate$'),
    ),
  );

  /**
   * navigation types
   */

  /** step */
  private readonly navigateByStep$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateByStep),
      withLatestFrom(this.navigationStore.index$),
      map(([{ step }, i]) => i + step),
      map((index) => ({ index })),
      this.handleNavigate$,
      logCatchError('navigateByStep$'),
    ),
  );

  private readonly setBackToAssignmentButton$ = combineLatest(
    this.navigationStore.index$,
    this.albumMode$,
    this.config.assignment$,
    this.config.pageStartCfi$,
    this.config.pageEndCfi$,
    this.readerStore.linearSpine$,
    this.readerStore.doubleSpine$,
  ).subscribe(
    ([
      index,
      albumMode,
      assignment,
      pageStartCfi,
      pageEndCfi,
      linearSpine,
      doubleSpine,
    ]) => {
      let startPage;
      let endPage;

      if (
        assignment === 'review' ||
        (undefined === pageStartCfi && undefined === pageEndCfi)
      ) {
        return;
      }

      const currentPage = index + 1;

      if (pageStartCfi) {
        startPage = this.util.findMatchingPageRangeIndex(
          pageStartCfi,
          albumMode,
          linearSpine as SpineItem[],
          doubleSpine as DoubleSpineItem[],
        );
        this.navigationStore.setRangeStartIndex(startPage);
      }

      if (pageEndCfi) {
        endPage = this.util.findMatchingPageRangeIndex(
          pageEndCfi,
          albumMode,
          linearSpine as SpineItem[],
          doubleSpine as DoubleSpineItem[],
        );
        this.navigationStore.setRangeEndIndex(endPage);
      }

      if (startPage === undefined && endPage === undefined) {
        this.navigationStore.setIsLastPage(true);
        return;
      }

      if (startPage && endPage) {
        this.navigationStore.setIsLastPage(currentPage === endPage);
      }

      if (startPage && undefined === endPage) {
        this.navigationStore.setBackToAssignmentButton(
          currentPage !== startPage,
        );
        this.navigationStore.setIsLastPage(currentPage === startPage);
        return;
      }

      if (endPage && undefined === startPage) {
        this.navigationStore.setBackToAssignmentButton(currentPage !== endPage);
        this.navigationStore.setIsLastPage(currentPage === endPage);
        return;
      }

      this.navigationStore.setBackToAssignmentButton(
        currentPage < startPage || currentPage > endPage,
      );
    },
  );

  /** index */
  private readonly navigateBySpineIndex$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateBySpineIndex),
      withLatestFrom(this.navigationStore.map$),
      map(([{ index, hash }, indexMap]) => ({ index: indexMap[index], hash })),
      this.handleNavigate$,
      logCatchError('navigateBySpineIndex$'),
    ),
  );

  /** CFI */
  private readonly navigateByCfi$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateByCfi),
      this.util.filterValidCfi,
      withLatestFrom(this.readerStore.spine$),
      map(([{ cfi, setFocus }, spine]) => {
        // This may seem weird (and it is), but we prioritize the id over the index because that's what legacy did.
        //   Ideally we could trust that the supplied CFI is consistent and accurate but we've leared that this is not the case.
        const {
          spineId,
          spineIndex: parsedSpineIndex,
          elementId,
        } = this.epubCfiLib.parseCFI(cfi, 'v6');
        const spineIndex =
          spine?.find((spineItem) => spineItem.id === spineId)?.index ??
          parsedSpineIndex;
        // Commenting out to avoid clogging up reader-api logs. Leave to not forget this may be needed.
        // if (spineIndex !== parsedSpineIndex) {
        //   this.readerStore.dispatch(
        //     log({ payload: { cfi, parsedSpineIndex, indexOfParsedSpineId: spineIndex } })
        //   );
        // }
        return { spineId, spineIndex, elementId, setFocus };
      }),
      this.util.tapDoubleSpread(
        (cfi) => this.navigateCfi$(cfi as ParsedCfi),
        (cfi) => this.navigateDoubleSpreadCfi$(cfi as ParsedCfi),
      ),
      logCatchError('navigateByCfi$'),
    ),
  );

  private readonly navigateCfi$ = this.effect((cfi$: Observable<ParsedCfi>) => {
    return cfi$.pipe(
      withLatestFrom(this.navigationStore.map$),
      map(([{ spineIndex, elementId, setFocus }, indexMap]) => {
        const index = indexMap[spineIndex];
        const hash = elementId;
        return { index, hash, setFocus };
      }),
      this.handleNavigate$,
    );
  });

  private readonly navigateDoubleSpreadCfi$ = this.effect(
    (cfi$: Observable<ParsedCfi>) => {
      return cfi$.pipe(
        withLatestFrom(this.readerStore.doubleSpine$),
        map(([{ spineIndex }, doubleSpine]) =>
          this.util.getDoubleSpineIndexFromSpineIndex(
            spineIndex,
            doubleSpine as DoubleSpineItem[],
          ),
        ),
        map((index) => ({ index })),
        this.handleNavigate$,
      );
    },
  );

  /** url */
  private readonly navigateBySpineUrl$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateBySpineUrl),
      withLatestFrom(this.readerStore.spine$),
      map(([{ url, hash }, spine]) => ({
        item: spine?.find((item) => item.url === url),
        hash,
      })),
      tap(({ item, hash }) =>
        this.util.navigateToSpineIndex(item?.index as number, hash),
      ),
      logCatchError('navigateBySpineUrl$'),
    ),
  );

  /** spine id */
  private readonly navigateBySpineId$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateBySpineId),
      this.util.tapDoubleSpread<{ id: string, hash?: string }>(
        ({ id, hash }: { id: string, hash: string }) =>
          this.navSpineId$({ id, hash }),
        (id: { id: string, hash: string }) => this.navDoubleSpineId$(id),
      ),
      logCatchError('navigateBySpineId$'),
    ),
  );

  // single
  private readonly navSpineId$ = this.effect(
    (nav$: Observable<{ id: string, hash?: string }>) =>
      nav$.pipe(
        withLatestFrom(this.readerStore.spine$),
        map(([{ id, hash }, spine]) => ({
          item: spine?.find((item) => item.id === id || item.idref === id),
          hash,
        })),
        tap(({ item }) => {
          if (!item) throw new Error('SpineItem not found');
        }),
        tap(({ item, hash }) =>
          this.util.navigateToSpineIndex(item?.index as number, hash),
        ),
        // TODO: move error routing to consumer
        catchError(async(error) => {
          console.error(error);
          await this.router.navigate(['/not-found'], {
            skipLocationChange: true,
          });
          return EMPTY;
        }),
      ),
  );

  // double
  private readonly navDoubleSpineId$ = this.effect(
    (nav$: Observable<{ id: string }>) =>
      nav$.pipe(
        withLatestFrom(this.readerStore.doubleSpine$),
        map(([{ id }, spine]) => {
          const index = spine?.findIndex((ds) => {
            const isLeft = ds.left?.id === id || ds.left?.idref === id;
            const isRight = ds.right?.id === id || ds.right?.idref === id;
            return isLeft || isRight;
          });

          return index;
        }),
        tap((index: number) => {
          if (index < 0) throw new Error('SpineItem not found');
        }),
        tap((index) => this.util.navDoubleSpread(index)),
        // TODO: move error routing to consumer
        catchError(async(error) => {
          console.error(error);
          await this.router.navigate(['/not-found'], {
            skipLocationChange: true,
          });
          return EMPTY;
        }),
      ),
  );

  /** Navigation History **/
  private readonly navigationHistory$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(
        navigationActions.navigateTo,
        navigationActions.navigateDoubleSpreadTo,
      ),
      tap((action) => this.readerStore.pushNavigationHistory(action)),
      logCatchError('navigationHistory$'),
    ),
  );

  private readonly navigationHistoryBack$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(toolbarActions.navigationHistoryBack),
      withLatestFrom(this.readerStore.navigationHistory$),
      tap(([, navigationHistory]) => {
        if (!navigationHistory || navigationHistory.length < 2) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const currentHistoryItem = navigationHistory.pop();
        const previousHistoryItem = navigationHistory.pop();

        this.readerStore.setNavigationHistory(navigationHistory);
        this.navigationStore.dispatch(
          previousHistoryItem as navigationActions.NavigationActions,
        );
      }),
      logCatchError('navigationHistoryBack$'),
    ),
  );

  private readonly navigationError$ = this.effect(() => {
    const { index$ } = this.navigationStore;
    const indexHistory$ = index$.pipe(pairwise());

    return this.epubViewerStore.actions$.pipe(
      ofType(epubViewerActions.renderError),
      withLatestFrom(indexHistory$),
      tap(([_, [prev, _current]]) => {
        this.navigationStore.setIndex(prev);
      }),
    );
  });

  private readonly isLastPageViewed$ = this.effect(() => {
    const events = [
      navigationActions.navigateTo,
      navigationActions.navigateDoubleSpreadTo,
    ];
    const max$ = this.navigationStore.maxIndex$;
    const lastPageViewed$ = this.readerStore.lastPageViewed$;

    return this.navigationActions$.pipe(
      ofType(...events),
      withLatestFrom(lastPageViewed$),
      filter(([_, viewed]) => !viewed),
      map(([{ index }]) => index),
      withLatestFrom(max$),
      filter(([index, max]) => index === max),
      tap(() => this.readerStore.setLastPageViewed(true)),
      logCatchError('isLastPageViewed$'),
    );
  });

  /** labels */
  private readonly setNavigationLabels$ = this.effect(() =>
    this.tocActions$.pipe(
      ofType(tocActions.setActiveTocItem),
      map(({ tocItem }) => tocItem),
      withLatestFrom(this.readerStore.flatToc$, this.readerStore.toc$),
      map(([tocItem, flatToc, toc]) => {
        // chapters (used for Presentation mode)
        const index = toc.findIndex((item) =>
          hasActiveItem(item, tocItem.id as string),
        );
        const prevChapter = toc[index - 1]?.label;
        const nextChapter = toc[index + 1]?.label;

        return { prevChapter, nextChapter };
      }),
      tap((labels) => this.navigationStore.setLabels({ ...labels })),
      logCatchError('setNavigationLabels$'),
    ),
  );

  private readonly albumModeToggled$ = this.effect(() =>
    this.albumMode$.pipe(
      withLatestFrom(
        this.readerStore.linearSpine$,
        this.readerStore.doubleSpine$,
      ),
      tap(([albumMode, linearSpine, doubleSpine]) => {
        const maxIndex = albumMode
          ? (linearSpine?.length as number) - 1
          : (doubleSpine?.length as number) - 1;
        this.navigationStore.setMaxIndex(maxIndex);
      }),
      logCatchError('albumModeToggled$'),
    ),
  );
}
