import {
  AudioChunkCollection,
  ChunkedAudioProgress,
  TTSPlaybackMode,
  TTSAudioSettings,
  Utterance,
  TTSVoice,
} from '@mhe/reader/models';

export const DEFAULT_PLAYBACK_RATE = 1.0;
export const DEFAULT_VOLUME = 1.0;

/**
 * Cache of data to play page or highlight. Could be
 * cached globally in the store.
 */
export interface EPubAudio {
  utterances: Utterance[]
  chunkCollection: AudioChunkCollection
}

/**
 * Information regarding epub page content
 * contained within an iframe.
 */
export interface EPubPage {
  baseCFIPath: string | undefined
  iframe: HTMLIFrameElement | undefined
  language: string
}

/**
 * A model of what the user is currently
 * looking at. Intended to contain all the
 * pages visually displayed so the audio
 * can be constructed.
 */
export interface CurrentView {
  pages: EPubPage[]
  isFixedLayout: boolean | undefined
  progress?: ChunkedAudioProgress
}

export interface TTSState {
  /**
   * A cache of audio chunks, indexed by
   * the CFI to the content.
   */
  audioDictionary: Record<string, Partial<EPubAudio>>

  /**
   * A (CFI) string values used to construct
   * audio based on entries in the audioDictionary.
   */
  activeAudioKeys: string[] | undefined

  /**
   * An enumberable set of values used for
   * enabling/disabling play/pause behaviors.
   */
  playbackMode: TTSPlaybackMode | undefined

  currentView: CurrentView
  docTTSControlReq: boolean
  isLoading: boolean
  currentProgress?: ChunkedAudioProgress
  audioSettings: TTSAudioSettings
  disabled: boolean
  voice: TTSVoice | null
}

export const initialState: TTSState = {
  audioDictionary: {},
  activeAudioKeys: undefined,
  playbackMode: undefined,
  currentView: {
    pages: [],
    isFixedLayout: undefined,
  },
  isLoading: false,
  docTTSControlReq: false,
  currentProgress: undefined,
  audioSettings: {
    volume: DEFAULT_PLAYBACK_RATE,
    playbackRate: DEFAULT_VOLUME,
  },
  disabled: false,
  voice: null,
};
