import { Injectable, Renderer2 } from '@angular/core';
import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class LazyLoadIframesTransformer implements Transformer {
  // any iframe, not just widget iframes
  // e.g. vimeo, viddler, youtube direct embed codes
  iframeSelector = 'iframe';

  constructor(private readonly renderer: Renderer2) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // get iframes in the exhibit
    const iframeElements = this.getIframeElements(content);

    // no iframes to work with
    if (!iframeElements.length) {
      return await next(content);
    }

    // individually manage any discovered widget iframes
    iframeElements.forEach((el: HTMLIFrameElement) => {
      // add the new attribute to support browser-managed lazy loading
      this.addLazyLoadAttr(el);
    });

    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // get iframes in the exhibit
    const iframeElements = this.getIframeElements(content);

    // no iframes to work with
    if (!iframeElements.length) {
      return await next(content);
    }

    // individually manage any discovered widget iframes
    // iframeElements.forEach((el: HTMLIFrameElement) => {
    //   // for logging, we can watch for the element load event (differnet from DOMContentLoad inside the iframe)
    //   // listen for the load event
    //   const unlistenLoad = this.renderer.listen(el, 'load', () => {
    //     console.log('[LLIT] iframe load event for ' + el.id);
    //     // TODO there may be other things to do in here such as metrics reporting

    //     // the load event is here, but there is not an accessible content document
    //     // due to cross-domain security and widget design, we cannot/should not
    //     // interact directly inside the iframe

    //     // only need it once
    //     unlistenLoad();
    //   });
    // });

    return await next(content);
  }

  private getIframeElements(doc: HTMLDocument): NodeListOf<HTMLIFrameElement> {
    // query for all iframes
    return doc.querySelectorAll(this.iframeSelector);
  }

  private addLazyLoadAttr(el: HTMLIFrameElement): void {
    // https://web.dev/iframe-lazy-loading/
    // https://caniuse.com/?search=lazy
    // not well adopted; could feature-detect and do a fallback to scrolling check
    // safari support is coming soon which may be adequate to avoid fallback requirement
    this.renderer.setAttribute(el, 'loading', 'lazy');
  }
}
