<div class="drawer-header">
  <h2 translate data-automation-id="search-header-label">search.search</h2>

  <button
    data-automation-id="search-drawer-close"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    placement="bottom"
    [title]="'shared.close' | translate"
    [mheTooltip]="'shared.close' | translate"
    (click)="closeEvent.emit()"
  >
    <i class="dpg-icon dpg-icon-system-close"></i>
  </button>

  <ng-container *ngIf="loading">
    <div class="searching-indicator">
      <mat-progress-bar class="rdrx-bar" mode="query"></mat-progress-bar>
    </div>
  </ng-container>
</div>

<div class="drawer-body search-container">
  <div class="search-input-container" #searchInputContainer>
    <input
      type="search"
      data-automation-id="search-input-box"
      placeholder="Enter your search term"
      class="search-input form-control has-icon"
      (focus)="handleFocus(searchInput.value)"
      (input)="handleInput(searchInput.value)"
      [attr.aria-activedescendant]="activeDescendantId"
      [attr.aria-owns]="ariaOwns"
      (keydown.arrowDown)="handleArrowDown()"
      (keydown.arrowUp)="handleArrowUp()"
      (keydown.enter)="handleEnter($event)"
      (keydown.escape)="handleEscape($event)"
      #searchInput
    />
    <i aria-hidden="true" class="dpg-icon dpg-icon-search ng-star-inserted"></i>
    <div *ngIf="showResults && !loading && hasSuggestions" class="search-autocomplete-container">
      <ng-container *ngIf="suggestions">
        <ng-container *ngFor="let suggestion of suggestions; let i = index">
          <div
            [ngClass]="{ focus: i === activeDescendant }"
            role="option"
            [id]="'suggestion_' + i"
            class="suggestion"
            (click)="handleSuggestionsClick(suggestion)"
          >
            <span class="sr-only" translate>elastic_search.search_suggestion</span>
            {{ suggestion.term }}
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="topics">
        <div class="suggestion-group" *ngIf="topics.length > 0">
          <strong translate>elastic_search.topics</strong>
        </div>
        <div
          [ngClass]="{ focus: i + suggestions.length === activeDescendant }"
          role="option"
          [id]="'suggestion_' + (i + suggestions.length)"
          class="suggestion"
          *ngFor="let suggestion of topics; let i = index"
          (click)="handleSuggestionsClick(suggestion)"
        >
          <span class="sr-only" translate>elastic_search.launchable_topic</span>
          {{ suggestion.term }}
        </div>
      </ng-container>
      <ng-container *ngIf="quizzes">
        <div class="suggestion-group" *ngIf="quizzes.length > 0">
          <strong translate>elastic_search.quizzes</strong>
        </div>
        <div
          [ngClass]="{ focus: i + suggestions.length + topics.length === activeDescendant }"
          role="option"
          [id]="'suggestion_' + (i + suggestions.length + topics.length)"
          class="suggestion"
          *ngFor="let suggestion of quizzes; let i = index"
          (click)="handleSuggestionsClick(suggestion)"
        >
          <span class="sr-only" translate>elastic_search.launchable_quiz</span>
          {{ suggestion.term }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="result-count" data-automation-id="search-result-count" aria-live="polite">
    <ng-container *ngIf="totalResults > 0; else refine">
      <ng-container *ngIf="totalResults <= 1000; else limitedResults" role="status">
        {{ totalResults }}
        {{ (totalResults === 1 ? 'search.result' : 'search.results') | translate | titlecase }}
      </ng-container>

      <!-- limited results -->
      <ng-template #limitedResults>
        <ng-container>
          <span class="too-many"
            ><span class="sr-only">{{ 'assignment.warning_title' | translate }}</span>
            {{ 'search.limited_results' | translate }}</span
          >
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-template #refine>
      <p *ngIf="nodes && totalResults === 0">
        {{ 'search.refine_search' | translate }}
      </p>
    </ng-template>
  </div>
  <div class="results">
    <ngx-bonsai
      *ngIf="nodes"
      #searchBonsaiDfa
      rdrxBonsaiTabIndex
      class="rdrx"
      [nodesById]="nodes"
      [rootNodeId]="rootNodeId"
      [expandedNodes]="getExpandedNodes()"
      (expanderClick)="toggleNodeExpanded($event)"
    >
      <ng-template #treeNodeTemplate let-node>
        <!-- result -->
        <ng-container *ngIf="node.innerHTML; else group">
          <a
            mheStopPropagation
            rdrxBonsaiNodeSelect
            data-automation-id="search-result-item"
            href="javascript:void(0)"
            class="rdrx-node-content ahe-ui-link"
            tabindex="-1"
            [innerHTML]="node.innerHTML"
            (click)="handleResultClicked(node)"
          ></a>
        </ng-container>
        <!-- group header -->
        <ng-template #group>
          <div>
            <div class="dfa-breadcrumbs" *ngIf="node.breadcrumbs">
              <span class="sr-only">{{node | breadcrumbsDescription}}</span>
              <span class="dfa-breadcrumb" *ngFor="let breadcrumb of node.breadcrumbs" aria-hidden="true">
                {{ breadcrumb }}
                <i class="dpg-icon dpg-icon-right-arrow"></i>
              </span>
            </div>
            <a
              mheStopPropagation
              rdrxBonsaiNodeSelect
              data-automation-id="search-result-group"
              href="javascript:void(0)"
              class="rdrx-node-content ahe-ui-link"
              tabindex="-1"
              [rdrxBonsaiNodeSelectKeyboard]="false"
              (click)="toggleNodeExpanded(node.id)"
            >
              <span aria-hidden="true">{{ node.title }}</span>
            </a>
          </div>
        </ng-template>
      </ng-template>
    </ngx-bonsai>
  </div>
</div>
