/* eslint-disable @typescript-eslint/quotes */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  signal,
} from '@angular/core';
import { ReaderModalModule } from '../modal.module';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonModule, ButtonPurpose, CheckboxModule } from '@mhe/ngx-shared';
import { OrphanAnnotationsModalData } from './orphan-annotations-modal.model';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'reader-ui-orphan-annotations-modal',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReaderModalModule,
    MatDialogModule,
    TranslateModule,
    ButtonModule,
    TooltipModule,
    CheckboxModule,
  ],
  template: `
    <!-- header -->
    <rdrx-modal-header (closeEvent)="dialogRef.close(false)">
      <h2 class="dialog-heading" data-automation-id="dialog-heading" id="confirmation-modal-h2">
        {{ data.title }}
      </h2>
    </rdrx-modal-header>

    <!-- content -->
    <div class="content" data-automation-id="modal-content">
      <p id='p2'>
        {{ 'orphan_annotations.paragraph_step_1' | translate }}
        <img id='unlink-icon' src="/assets/icons/reader/unlink.svg" alt="Unlink Icon">
        {{ 'orphan_annotations.paragraph_step_2' | translate }}
      </p>
      <mhe-checkbox
        id='content-checkbox'
        class="content__checkbox"
        [checked]="disableMessage()"
        (change)="toggleShowMessage()"
      >
      {{ 'orphan_annotations.do_not_show_message_again' | translate }}
      </mhe-checkbox>
    </div>

    <!-- footer -->
    <rdrx-modal-footer class="modal-footer" data-automation-id="modal-footer">
      <button
        id='accept-button'
        data-automation-id="cancel-button"
        class="btn btn-secondary"
        (click)="dialogRef.close(disableMessage())"
      >
        {{ 'orphan_annotations.accept_message' | translate }}
      </button>
    </rdrx-modal-footer>
  `,
  styleUrls: ['./orphan-annotations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrphanAnnotationsModalComponent {
  ButtonPurpose = ButtonPurpose;

  disableMessage = signal(false);

  constructor(
    readonly dialogRef: MatDialogRef<OrphanAnnotationsModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) readonly data: OrphanAnnotationsModalData,
  ) { }

  toggleShowMessage(): void {
    this.disableMessage.set(!this.disableMessage());
  }
}
