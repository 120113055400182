import { Injectable } from '@angular/core';

import { IframeCoreService } from './iframe-core.service';
import { MockAuthService } from './mockAuth.service';
import { MockAppConfigService } from './mockAppConfig.service';

/**
 * This service is involved in injecting styles,
 * scripts, and body classes to the ePub iFrame.
 */
@Injectable()
export class IframeInjectorService {
  constructor(
    private readonly iframeCore: IframeCoreService,
    private readonly mockAuthService: MockAuthService, // @todo replace with real service
    private readonly mockAppConfigService: MockAppConfigService, // @todo replace with real service
  ) {}

  /**
   * Adds the <base> to the <head> of ePub iFrame.
   * The <base> element specifies how all relative URLs are resolved.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/base
   */
  appendBase(baseUrl: string, doc: HTMLDocument): void {
    const base = doc.createElement('base');
    const head = this.iframeCore.getElementByTagName('head', doc) as Element;
    base.setAttribute('href', baseUrl);
    head.insertBefore(base, head.firstChild);
  }

  /**
   * Append external javascript to the <body> of the ePub iFrame.
   */
  appendScript(scriptUrl: string, doc: HTMLDocument): void {
    const scriptNode = doc.createElement('script');
    scriptNode.setAttribute('type', 'text/javascript');
    scriptNode.setAttribute('src', scriptUrl);
    this.iframeCore.getElementByTagName('body', doc)?.appendChild(scriptNode);
  }

  /**
   * Append inline CSS to <head> of the ePub iFrame
   * Will override if id already exists.
   */
  appendStyle(content: string, styleId: string, doc: HTMLDocument): void {
    doc = doc || this.iframeCore.getDocument();
    if (doc) {
      let style = doc.getElementById(styleId);
      if (!style) {
        style = doc.createElement('style');
      }
      style.setAttribute('id', styleId);
      style.setAttribute('type', 'text/css');
      style.textContent = content;
      const head = doc.getElementsByTagName('head');
      if (head.length > 0) {
        head[0].appendChild(style);
      }
    }
    this.iframeCore.setActiveStyle(styleId, content);
  }

  /**
   * Adds a external stylesheet to the ePub iFrame
   *
   * If the style element <link> already exists with given id
   *    Update the URL for the <link> element
   *
   * ELSEIf the style URL is different from the main CSS URL
   *    Add a new <link> element with URL pointing to the CSS file
   */
  appendStylesheet(
    stylesheetUrl: string,
    styleId: string,
    doc: HTMLDocument,
  ): void {
    const headElmt = this.iframeCore.getElementByTagName('head', doc);

    // Does the first stylesheet match the URL, if so ignore it
    const mainCSS = this.getMainStylesheet(doc);
    const sameAsMain =
      mainCSS && mainCSS.getAttribute('href') === stylesheetUrl;

    // Get Previous Link Element for passed ID
    let linkElmt = doc.getElementById(styleId);
    if (linkElmt) {
      const sameStylesheet = linkElmt.getAttribute('href') === stylesheetUrl;
      if (!sameStylesheet && !sameAsMain && stylesheetUrl) {
        linkElmt.setAttribute('href', stylesheetUrl);
      } else if (!stylesheetUrl || sameAsMain) {
        headElmt?.removeChild(linkElmt);
      }
    } else if (!sameAsMain) {
      linkElmt = doc.createElement('link');
      linkElmt.setAttribute('rel', 'stylesheet');
      linkElmt.setAttribute('href', stylesheetUrl);
      linkElmt.setAttribute('id', styleId);
      linkElmt.setAttribute('type', 'text/css');

      this.insertAfterOrAppend(headElmt as Element, linkElmt, mainCSS);
    }
    this.iframeCore.setActiveStyleSheet(styleId, stylesheetUrl);
  }

  /**
   * Adjusts the <audio> elements markup in the ePub iFrame.
   *
   * Loop through <audio> elements:
   * (1) Add class 'enhanced'
   */
  enhanceAudio(doc: HTMLDocument): void {
    const wrappedAudioElmts = this.iframeCore.getElementsByClass(
      'mhe-audio--wrapper',
      doc,
    );
    wrappedAudioElmts.forEach((elmt) => {
      elmt.classList.add('enhanced');
    });
  }

  /**
   * Adjusts the <img> elements markup in the ePub iframe.
   *
   * Loop through images:
   * (1) Add an onerror handler to show broken image placeholder
   * (2) If image inside <a> remove the tab index on the image
   * (3) If lazyloading is enabled, add the lazy-src attribute
   */
  enhanceImage(doc: HTMLDocument): void {
    const oneByOneGif =
      'data:image/gif;base64,' +
      'R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    const showBrokenImage =
      "src='" +
      oneByOneGif +
      "'; className+=' mhe-broken-image nonclickable-image'; style=''";
    const images = this.iframeCore.getElementsByQuery('body img', doc);

    images.forEach((elmt) => {
      elmt.setAttribute('onerror', showBrokenImage);

      if (
        !elmt.getAttribute('tabindex') &&
        elmt.parentElement &&
        elmt.parentElement.nodeName.toLowerCase() !== 'a'
      ) {
        elmt.setAttribute('tabindex', '0');
      }

      if (
        this.mockAppConfigService.featureFlags &&
        this.mockAppConfigService.featureFlags.lazyLoad
      ) {
        elmt.setAttribute('lazy-src', elmt.getAttribute('src') as string);
        elmt.className += ' lazyload nonclickable-image';
        elmt.setAttribute('src', oneByOneGif);
      }
    });
  }

  /**
   * Return the first <link> element
   */
  getMainStylesheet(doc: HTMLDocument): Element {
    return this.iframeCore.getElementByTagName('link', doc) as Element;
  }

  /**
   * If `before` parameter passed:
   *
   *   <parent>
   *       <----   `elmt` inserted here
   *      <before>
   *
   * If `before` parameter not passed
   *
   *   <base>
   *       <----   `elmt` inserted here
   *
   */
  insertAfterOrAppend(base: Element, elmt: Element, before?: Element): void {
    if (before) {
      before.parentElement?.insertBefore(elmt, before.nextSibling);
    } else {
      base.appendChild(elmt);
    }
  }

  /**
   * Removes a stylesheet with a given id attribute
   */
  removeStylesheet(id: string, doc: HTMLDocument): void {
    const linkElement = this.iframeCore.getElementById(id, doc);
    if (linkElement) {
      this.iframeCore
        .getElementByTagName('head', doc)
        ?.removeChild(linkElement);
    }
  }

  /**
   * Adds mhe-context-view-* classes to the body tag
   */
  setContextView(role: string, mode: string, doc: HTMLDocument): void {
    const body = this.iframeCore.getElementByTagName('body', doc);

    if (!body) {
      return;
    }

    // remove existing mhe-context-view-* classes
    body.className = body.className.replace(
      /\s*\bmhe-context-view-.+?\b\s*/g,
      '',
    );

    // add mhe-context-view-* classes
    body.classList.add(
      'mhe-context-view-' + (role === 'administrator') ? 'instructor' : role,
    );
    body.classList.add('mhe-context-view-' + mode);
    if (this.mockAuthService.isAuthoring()) {
      // Avalon authoring styles
      body.classList.add('mhe-context-view-authoring');
    }
  }
}
