/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-throw-literal */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Package, PackageMetadata, Spine, SpineItem, TocItem } from '@mhe/reader/models';
import { IframeBuilderService } from '@mhe/reader/features/iframe';

/**
 * @deprecated
 * - this service useage should be avoided and functionality
 * migrated to `@ngrx/{store, component-store}` layers
 * - files may remain until existing functionlaity has been migrated out
 * - look at preserving or moving actual http calls
 */
@Injectable()
export class EPubService {
  /** @deprecated value should be in state */
  isDoubleSpread = false;
  /** @deprecated value should be in state, and fully typed */
  data: {
    package: Package
    toc: TocItem[]
    metadata: PackageMetadata
    spine: Spine
    doubleSpines?: any[]
    allLevelSpines?: any[]
    lackSpineId: boolean
    flatToc: any
  };

  constructor(
    private readonly http: HttpClient,
    private readonly iframeBuilder: IframeBuilderService,
  ) {}

  getPageDocument(spineItem: SpineItem): Observable<HTMLDocument> {
    return this.http
      .get(spineItem.url as string, {
        withCredentials: true,
        responseType: 'text',
        headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
      })
      .pipe(
        map((response) => new DOMParser().parseFromString(response, 'text/xml')),
        map((xmlDoc) => {
          const parserError = this.iframeBuilder.checkForEmptyXML(xmlDoc);
          if (parserError) {
            throw throwError(() => 'Error parsing XML; no content available. Possibly VPN related');
          }
          return this.iframeBuilder.convertToHtmlDocument(xmlDoc as XMLDocument);
        }),
      );
  }
}
