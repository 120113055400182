<div class="error-page-container">
  <div>
    <div class="error-code">
      <span>403</span>
    </div>

    <div>
      <section>
        <h1>{{ 'errorPage.whoops' | translate }}</h1>
        <h3>{{ 'errorPage.403.title' | translate }}</h3>
      </section>
      <section>
        <h3>{{ 'errorPage.reason' | translate }}</h3>
        <ul [innerHTML]="'errorPage.403.reason_list' | translate"></ul>
      </section>
      <section>
        <h3>{{ 'errorPage.try' | translate }}</h3>
        <ul [innerHTML]="'errorPage.403.try_list' | translate"></ul>
      </section>
      <ng-container *ngIf="queryParams$ | async as queryParams">
        <section class="metadata">
          <!-- Metadata -->
          <ul *ngFor="let param of queryParams | keyvalue">
            <li>{{ param.key }}: {{ param.value }}</li>
          </ul>
        </section>
      </ng-container>
    </div>
  </div>
</div>
