import { Provider } from '@angular/core';
import { TransformStore } from '@mhe/reader/state/transform';

import { AnchorTransformer } from './anchor.transformer';
import { AriaTransformer } from './aria.transformer';
import { AssessmentWidgetsTransformer } from './assessment-widgets.transformer';
import { AudioTransformer } from './audio.transformer';
import { AuthoringTransformer } from './authoring.transformer';
import { BentoDigitalTaggingTransformer } from './bento-digital-tagging.transformer';
import { defaultTransforms } from './default-transforms';
import { EnhanceLinksTransformer } from './enhance-links.transformer';
import { EnsureLanguageAttributeTransformer } from './ensure-language-attribute.transformer';
import { EpubIframeStylesTransformer } from './epub-iframe-styles.transformer';
import { GifTransformer } from './gif.transformer';
import { LargeImageNavigatorTransformer } from './large-image-navigator.transformer';
import { LazyLoadIframesTransformer } from './lazy-load-iframes.transformer';
import { LexileLevelTransformer } from './lexile-level.transformer';
import { MathjaxTransformer } from './mathjax.transformer';
import { ReaderRolesTransformer } from './reader-roles.transformer';
import { SelectionTransformer } from './selection.transformer';
import { SetBaseTransformer } from './set-base.transformer';
import { PreventCopyTransformer } from './prevent-copy.transformer';
import { ThemeParkTransformer } from './theme-park.transformer';
import { TransformService } from './transform.service';
import { Transformer } from './transformer';
import { WidgetsManagerTransformer } from './widgets-manager.transformer';
import { AnnotationCssTransformer } from './annotation-css.transformer';
import { AnnotationCssService } from './annotation-css.service';
import { AiReaderCitationTransformer } from './ai-reader-citation.transformer';
import { OrphanAnnotationsTransformer } from './orphan-annotations.transformer';

export const TRANSFORM_PROVIDERS: Provider[] = [
  SetBaseTransformer,
  PreventCopyTransformer,
  EnhanceLinksTransformer,
  EnsureLanguageAttributeTransformer,
  EpubIframeStylesTransformer,
  AnchorTransformer,
  AriaTransformer,
  SelectionTransformer,
  LargeImageNavigatorTransformer,
  GifTransformer,
  LazyLoadIframesTransformer,
  MathjaxTransformer,
  WidgetsManagerTransformer,
  AssessmentWidgetsTransformer,
  BentoDigitalTaggingTransformer,
  ReaderRolesTransformer,
  ThemeParkTransformer,
  LexileLevelTransformer,
  AudioTransformer,
  AuthoringTransformer,
  AnnotationCssTransformer,
  AiReaderCitationTransformer,
  OrphanAnnotationsTransformer,
  AnnotationCssService,
  {
    provide: TransformService,
    useFactory: transformServiceFactory,
    deps: defaultTransforms,
  },
  TransformStore,
];

export function transformServiceFactory(...transformers: Transformer[]): TransformService {
  return new TransformService(transformers);
}
