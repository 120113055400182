import { Book, DoubleSpineItem, SpineItem } from '@mhe/reader/models';
import { createSelector } from '@ngrx/store';

import { getGlobalState } from '../global-store.selectors';
import { EPUB_STATE_KEY, EpubState, EpubDictionaryState } from './epub.state';
import { mapSpineItemsByContentType } from '@mhe/reader/utils';

// TODO: move with other types?
export type BookLoadingState =
  | 'not requested'
  | 'requested'
  | 'loaded'
  | 'error'
  | undefined;

const getState = createSelector(
  getGlobalState,
  (state) => state[EPUB_STATE_KEY],
);

const getEpubByUrl = createSelector(
  getState,
  (s: EpubDictionaryState, { url }: { url: string }): EpubState => s[url],
);

/** book */
export const getEpubBook = createSelector(
  getEpubByUrl,
  (state): Book => state?.book as Book,
);

export const getEpubPackage = createSelector(
  getEpubBook,
  (book) => book?.package,
);

export const getSpine = createSelector(
  getEpubBook,
  (book): SpineItem[] | undefined => book?.package?.spine,
);

export const getContentSpine = createSelector(
  getEpubPackage,
  (pkg) => mapSpineItemsByContentType(pkg?.spine, pkg?.manifest) ?? [],
);

export const getDoubleSpine = createSelector(
  getEpubByUrl,
  (state): DoubleSpineItem[] | undefined => state?.doubleSpines,
);

export const getLinearSpine = createSelector(
  getContentSpine,
  (spine): SpineItem[] | undefined =>
    spine.filter((item) => item.linear === 'yes'),
);

export const getFlatToc = createSelector(
  getEpubByUrl,
  (state) => state?.flatToc,
);

/** metadata */

export const isDoubleSpread = createSelector(
  getEpubByUrl,
  (state) => state?.isDoubleSpread,
);
export const isFixedLayout = createSelector(
  getEpubByUrl,
  (state) => state?.isFixedLayout,
);

/** loading */
export const getEpubLoadingState = createSelector(
  getEpubByUrl,
  (epub): BookLoadingState => {
    if (epub === undefined) {
      return 'not requested';
    }
    if (epub.loading) {
      return 'requested';
    }
    if (epub.error) {
      return 'error';
    }
    if (epub.book) {
      return 'loaded';
    }
  },
);
export const isEpubLoading = createSelector(
  getEpubLoadingState,
  (loadingState) => loadingState === 'requested',
);

export const getEpubLoadingError = createSelector(
  getEpubByUrl,
  (state): Error | undefined => state?.error,
);
