import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { Observable } from 'rxjs';

@Injectable()
export class LogService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  log(api: string, payload: object): Observable<any> {
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.httpClient.post(`${api}/v1/log`, payload),
    );
  }
}
