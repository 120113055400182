<div class="header go-to-active">
  <h3 translate>contents.topics_tip</h3>
</div>
<div *ngIf="(isTopicsTreeEmpty$ | async) || (errorLoadingSubjects$ | async)">
  <div class="topic-alert" *ngIf="errorLoadingSubjects$ | async; else loadingTopics">
    <mhe-alert
      id="main-topics-tree-error"
      [smallAlert]="true"
      [type]="AlertType.Error"
      [closable]="false"
      data-automation-id="topics-subjects-alert"
    >
      {{ 'contents.topics_menu_alert' | translate }}
    </mhe-alert>
  </div>
  <ng-template #loadingTopics>
    <mat-progress-spinner
      class="rdrx-spinner spine-annotations-loading"
      mode="indeterminate"
      id="main-topics-tree-loading"
      style="margin-top: 1rem"
      [strokeWidth]="2"
      [diameter]="75"
    ></mat-progress-spinner>
  </ng-template>
</div>
<div *ngIf="(isLocationsView$ | async) === false">
  <ngx-bonsai
    rdrxBonsaiTabIndex
    class="rdrx"
    data-automation-id="subject-list"
    [nodesById]="topicsTree$ | async"
    [rootNodeId]="rootNodeId"
    [expandedNodes]="expandedSubjects$ | async"
    (expanderClick)="toggleExpandedSubjects($event)"
    [isLoading]="isLoading"
    [showToggleFn]="showToggleFn"
  >
    <ng-template #loadingTemplate>
      <mat-progress-spinner
        class="rdrx-spinner spine-annotations-loading"
        mode="indeterminate"
        [strokeWidth]="2"
        [diameter]="75"
      ></mat-progress-spinner>
    </ng-template>
    <ng-template #treeNodeTemplate let-node>
      <a
        attr.aria-haspopup
        mheStopPropagation
        rdrxBonsaiNodeSelect
        href="javascript:void(0)"
        class="rdrx-node-content ahe-ui-link show-locations-view"
        [attr.data-automation-id]="'topics-tree-child-' + node.id"
        tabindex="-1"
        [rdrxTopicsActiveNode]="node.id === (activeTopicId$ | async)"
        [rdrxBonsaiNodeSelectKeyboard]="!node.collapsible"
        (click)="showLocationsView(node)"
      >
        {{ node.title }}
      </a>
      <button
        *ngIf="node.type === 'TOPIC_CATEGORY' && node.assessments.length"
        aria-labelledby="{{ node.title }}"
        mheStopPropagation
        class="btn btn-secondary"
        (click)="handleNodeClick(node)"
        translate
      >
        contents.topics_practice
      </button>
    </ng-template>
    <ng-template #emptyTemplate>
      <div class="topic-alert">
        <mhe-alert
          id="expanded-topics-tree-error"
          [smallAlert]="true"
          [type]="AlertType.Error"
          [closable]="false"
          data-automation-id="topics-topic-alert"
        >
          {{ 'contents.topics_menu_alert' | translate }}
        </mhe-alert>
      </div>
    </ng-template>
  </ngx-bonsai>
</div>

<ng-container
  *ngIf="{
    isLocationsView: isLocationsView$ | async,
    locationsView: locationsView$ | async
  } as locationsViewData"
>
  <div *ngIf="locationsViewData.isLocationsView">
    <a
      [attr.aria-label]="
        ('contents.topics_back_to' | translate) + locationsViewData?.locationsView?.currentSubjectNode?.title
      "
      class="header"
      tabindex="0"
      (click)="hideLocationsView()"
      (keydown.Enter)="hideLocationsView()"
      (keydown.Space)="hideLocationsView()"
      data-automation-id="subjectBacklink"
    >
      <i class="dpg-icon dpg-icon-system-left-arrow"></i>
      <div class="header-back">
        <h4 class="header-back_subject">{{ locationsViewData?.locationsView?.currentSubjectNode?.title }}</h4>
        <h5 class="header-back_topic">{{ locationsViewData?.locationsView?.currentTopicNode?.title }}</h5>
      </div>
    </a>
    <div class="topic-alert" *ngIf="showTopicDetailsError">
      <mhe-alert
        id="locations-topics-tree-error"
        [smallAlert]="true"
        [type]="AlertType.Error"
        [closable]="false"
        data-automation-id="topics-details-alert"
      >
        {{ 'contents.topics_menu_alert' | translate }}
      </mhe-alert>
    </div>
    <ngx-bonsai
      rdrxBonsaiTabIndex
      class="rdrx locations-view"
      [nodesById]="locationsViewData?.locationsView?.locationsTree"
      data-automation-id="locations-tree-group"
      [rootNodeId]="rootNodeId"
      [expandedNodes]="{}"
    >
      <ng-template #treeNodeTemplate let-node>
        <a
          attr.aria-haspopup
          mheStopPropagation
          rdrxBonsaiNodeSelect
          href="javascript:void(0)"
          class="location-tree-elem rdrx-node-content ahe-ui-link"
          [attr.data-automation-id]="'topics-location-child-' + node.id"
          tabindex="-1"
          [rdrxBonsaiNodeSelectKeyboard]="!node.collapsible"
          [rdrxTopicsActiveNode]="node.id === (activeLocationId$ | async)"
          (click)="handleNodeClick(node)"
        >
          {{ node.title }}
        </a>
      </ng-template>
    </ngx-bonsai>
    <div id="locations-view-bonsai-wrapper">
      <ngx-bonsai
        rdrxBonsaiTabIndex
        class="rdrx locations-view"
        [nodesById]="locationsViewData?.locationsView?.casesPatternsTree"
        data-automation-id="cases-patterns-tree-group"
        [rootNodeId]="rootNodeId"
        [expandedNodes]="locationsViewData?.locationsView?.expandedCasesPatterns"
        [showToggleFn]="showToggleFn"
        (expanderClick)="toggleExpandedLocations($event)"
      >
        <ng-template #treeNodeTemplate let-nodeLocation>
          <a
            attr.aria-haspopup
            mheStopPropagation
            rdrxBonsaiNodeSelect
            href="javascript:void(0)"
            class="location-cases-patterns-tree-elem rdrx-node-content ahe-ui-link"
            [attr.data-automation-id]="'topics-cases-patterns-child-' + nodeLocation.id"
            tabindex="-1"
            [rdrxBonsaiNodeSelectKeyboard]="!nodeLocation.collapsible"
            (click)="handleNodeClick(nodeLocation)"
            [ngClass]="{
              'locations-header': nodeLocation.type === 'HEADER',
              'topic-locations-header': nodeLocation.id === 'TOPICS',
              'topic-location': nodeLocation.parent === 'TOPICS'
            }"
          >
            {{ nodeLocation.title }}
          </a>
        </ng-template>
      </ngx-bonsai>
    </div>
    <div id="bb-view-bonsai-wrapper" *ngIf="haveBbVideos">
      <ngx-bonsai
        rdrxBonsaiTabIndex
        class="rdrx locations-view"
        [nodesById]="bbVideos"
        data-automation-id="boards-and-beyond-tree-group"
        [rootNodeId]="rootNodeId"
        [expandedNodes]="locationsViewData?.locationsView?.expandedCasesPatterns"
        [showToggleFn]="showToggleFn"
        (expanderClick)="toggleExpandedLocations($event)"
      >
        <ng-template #treeNodeTemplate let-nodeLocation>
          <a
            attr.aria-haspopup
            mheStopPropagation
            rdrxBonsaiNodeSelect
            href="javascript:void(0)"
            class="location-cases-patterns-tree-elem rdrx-node-content ahe-ui-link bb_video"
            [attr.data-automation-id]="'topics-cases-patterns-child-' + nodeLocation.id"
            tabindex="-1"
            [rdrxBonsaiNodeSelectKeyboard]="!nodeLocation.collapsible"
            (click)="handleNodeClick(nodeLocation, true)"
          >
            <figure *ngIf="nodeLocation.header; else elseBlock">
              <img
                class="bb-view-bonsai-wrapper__logo"
                src="/assets/images/b&b/b&b_bg_svg.svg"
                alt="Boards & Beyond Product Logo">
            </figure>
            <ng-template #elseBlock>
                <img src="/assets/icons/b&b/play.svg" alt="Play Icon">
                <span>&nbsp;</span>
                Video: {{ nodeLocation.title }}
            </ng-template>
          </a>
        </ng-template>
      </ngx-bonsai>
    </div>
  </div>
</ng-container>
