import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import { fromEvent } from 'rxjs';
import { GlobalWirisData } from './global-wiris.model';

@Component({
  selector: 'rdrx-global-wiris',
  templateUrl: './global-wiris.component.html',
  styleUrls: ['./global-wiris.component.scss'],
})
export class GlobalWirisComponent implements OnInit, OnDestroy {
  // what the widget will get back as a response
  mathMLResponse: string | null = null;

  // what the widget is requesting
  mathMLContent = this.data.mathMLContent;
  requestId = this.data.requestId;
  toolbar = this.data.toolbar;

  // reference back to the calling widget for sending the response
  callingWidgetManager = this.data.callingWidgetManager;

  // reference to the instance of the WIRIS editor
  editor: any;

  // dialog elements
  alertType = AlertType;
  ButtonPurpose = ButtonPurpose;

  error$ = this.data.error$;
  title = this.data.title;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: GlobalWirisData,
    public readonly dialogRef: MatDialogRef<GlobalWirisComponent>,
  ) {}

  ngOnInit(): void {
    // first ensure the JS lib is loaded
    this.loadWirisLib(() => {
      // now create a WIRIS editor instance and place it into the modal DOM
      this.createWirisEditorInstance();
    });
  }

  ngOnDestroy(): void {
    // tell the calling widget that the editor was closed
    // will return null if closed by cancel/escape/outside click
    // will return MathML if closed by accept
    this.callingWidgetManager.wirisResponse(this.mathMLResponse);
  }

  closeDialog(result): void {
    if (result) {
      // closing in the affirmative, so get any authored formula
      this.mathMLResponse = this.editor.getMathML();
    }

    // now dispose of the modal
    this.dialogRef.close();
  }

  private createWirisEditorInstance(): void {
    // create a JS instance of the WIRIS editor with our configuration
    // https://docs.wiris.com/en/mathtype/mathtype_web/sdk-api/embedding
    // @ts-expect-error err
    this.editor = com.wiris.jsEditor.JsEditor.newInstance({
      language: 'en',
      toolbar: this.toolbar,
      detectHand: 'false',
    });

    // tell the WIRIS editor to insert itself into the modal DOM
    this.editor.insertInto(
      document.querySelector('.js-wiris-editor-container'),
    );

    // populate the WIRIS editor with an existing formula
    if (/^<math.+math>$/.test(this.mathMLContent)) {
      this.editor.setMathML(this.mathMLContent);
    }
  }

  private loadWirisLib(callback: () => any): void {
    const existingLib = document.getElementById('rdrxWirisJsLib');

    // @ts-expect-error err
    if (existingLib && typeof com !== 'undefined') {
      callback();
      return;
    }

    const wirisScriptEle = document.createElement('script');

    // properties to load the library script
    wirisScriptEle.setAttribute('id', 'rdrxWirisJsLib');
    wirisScriptEle.setAttribute('type', 'text/javascript');
    wirisScriptEle.setAttribute(
      'src',
      'https://wiris.assess.prod.mheducation.com/editor',
    );

    // handler for when the js lib is loaded and ready to go
    wirisScriptEle.addEventListener('load', () => {
      callback();
    });

    // handler in case the lib cannot be loaded
    this.error$ = fromEvent<Error>(wirisScriptEle, 'error');
    // wirisScriptEle.addEventListener('error', (err) => {
    //   console.error('Unable to load WIRIS JS lib');
    //   console.dir(err);
    // });

    document.body.appendChild(wirisScriptEle);
  }
}
