import { createAction, props } from '@ngrx/store';

import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationType } from '../../annotation.data';

export const annotationSelected = createAction(
  'Highlight List Annotation Selected',
  props<{ annotation: ApiAnnotation }>(),
);
export const highlightDelete = createAction(
  'Highlight List Annotation Delete',
  props<{ annotation: ApiAnnotation }>(),
);
export const highlightBatchDelete = createAction(
  'Highlight List Annotation Batch Delete',
  props<{ data: { annotations: ApiAnnotation[], type: AnnotationType } }>(),
);
export const getAnnotationsForLabel = createAction(
  'Highlight Get Annotations For Label',
  props<{ spineID: string }>(),
);
export const askForAnnotations = createAction(
  'Highlight Ask for Annotations',
  props<{ spineID: string }>(),
);
export const exportHighlights = createAction('Highlight List Export');

export type HighlightListActions = ReturnType<
| typeof annotationSelected
| typeof exportHighlights
| typeof highlightDelete
| typeof askForAnnotations
| typeof getAnnotationsForLabel
| typeof highlightBatchDelete
>;
