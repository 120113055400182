import { Injectable } from '@angular/core';

import { Book, SpineItem } from '../models/epub';
import { Next, Transformer } from './transformer';
import { Store } from '@ngrx/store';
import * as annotationsActions from '../global-store/annotations/annotations.actions';

@Injectable()
export class OrphanAnnotationsTransformer implements Transformer {
  constructor(
    private readonly store: Store,
  ) { }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: Document,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<Document> {
    const spinesItems = Array
      .from(content.querySelectorAll('[id^="data-uuid-"]'))
      .map(id => id.getAttribute('id')) as string[];
    this.store.dispatch(
      annotationsActions.setSpineItemsToSpineId({ spineId: spineItem.id, spineItems: spinesItems }),
    );
    return await next(content);
  }
}
