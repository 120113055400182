import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reader-ui-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
  private readonly activatedRoute = inject(ActivatedRoute);

  queryParams$ = this.activatedRoute.queryParams;
}
