import { Component, Input } from '@angular/core';

@Component({
  selector: 'reader-core-position-indicator',
  templateUrl: './position-indicator.component.html',
  styleUrls: ['./position-indicator.component.scss'],
})
export class PositionIndicatorComponent {
  @Input() upper: number;
  @Input() lower: number;
}
