import { Injectable, NgZone, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Next, Transformer } from './transformer';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';

import {
  widgetDataSearch,
  widgetDataSave,
  widgetInstanceAdd,
  widgetInstanceRemove,
  widgetInstanceSetPreRender,
  widgetInstanceSetPostRender,
  widgetInstanceIframeDomLoaded,
  widgetInstanceSetDataRestored,
  widgetInstanceResetIframe,
  widgetTextToSpeech,
} from '@mhe/reader/global-store/widgets/widgets.actions';
import { getWidgetsStateByWidgetUuid } from '@mhe/reader/global-store/widgets/widgets.selectors';
import { WidgetManager } from './widget-manager';
import {
  TTSFloatingTextRequest,
  WidgetInstance,
  Book,
  SpineItem,
} from '@mhe/reader/models';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';

@Injectable()
export class WidgetsManagerTransformer implements Transformer {
  // agreement for authoring of epubs
  iframeSelector = 'iframe.widget';
  private readonly blacklist = [
    'widget-mhe-assessment',
    'widget-engrade-assessment',
  ];

  // references to pass into helper functions
  private content: HTMLDocument | null = null;
  private iframe: HTMLIFrameElement | null = null;

  // collection of WidgetManager instances attached to widget iframe elements
  private widgetManagers: WidgetManager[] = [];

  constructor(
    readonly ga: GoogleAnalyticsService,
    readonly store: Store,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly transformStore: TransformStore,
  ) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // there has to be a better way to do this with rxjs
    // need to await for all manager objects to resolve their completion
    const allDone: Array<Promise<boolean>> = [];

    // close out any prior widget managers
    if (this.widgetManagers.length > 0) {
      // ask each manager to close out
      this.widgetManagers.forEach((wm) => {
        // returns a promise which eventually resolves from a faux timer
        allDone.push(wm.widgetUnloadedEvent());
      });
    }

    // wait until all WidgetManager instances report done
    await Promise.all(allDone).then(() => {
      this.widgetManagers = [];
    });

    // query for widget iframes
    const widgetElementNodes: NodeListOf<HTMLIFrameElement> =
      content.querySelectorAll(this.iframeSelector);
    let widgetElements = Array.from(widgetElementNodes);
    widgetElements = widgetElements.filter(
      (widget) => !this.blacklist.some((bl) => widget.classList.contains(bl)),
    );

    // no widgets to work with
    if (widgetElements.length === 0) {
      return await next(content);
    }

    // individually manage any discovered widget iframes
    widgetElements.forEach((el: HTMLIFrameElement) => {
      // create a new widget manager
      const thisWidgetManager = new WidgetManager(
        this.renderer,
        el,
        content,
        this,
      );

      // do the pre render init
      thisWidgetManager.preRender_init();

      // track this widget manager
      this.widgetManagers.push(thisWidgetManager);
    });

    // this does not seem to be needed, but keeping it here just in case we
    // see weirdness with rendering changes in the future and have this reminder
    // this.cdr.detectChanges();

    // continue the promise chain
    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // set references for use in WidgetManager instances
    this.content = content;
    this.iframe = iframe;

    // eventually we'll need some sort of event/trigger for before epub navigation
    // for now it is not needed because we handle is at the start of a new preRender cycle
    // const unlistenBeforeunload = this.renderer.listen(this.iframe?.contentWindow, 'beforeunload', () => {
    //   // only need this event once
    //   unlistenBeforeunload();
    // });

    // no widget iframes being managed which need enhancement
    if (this.widgetManagers.length === 0) {
      return await next(content);
    }

    /*
     * Enhance widget iframes, load was deferred in preRender
     * The content document is not the same pre-to-post render functions.
     */
    this.widgetManagers
      .map((wm: WidgetManager) => ({
        wm,
        widgetIframe: content.getElementById(
          wm.widgetUuid,
        ) as HTMLIFrameElement,
      }))
      .filter(
        ({
          wm,
          widgetIframe,
        }: {
          wm: WidgetManager
          widgetIframe: HTMLIFrameElement
        }) => {
          if (!widgetIframe) {
            // console.log(`Widget id ${wm.widgetUuid} has been removed`);
          }
          return !!widgetIframe;
        },
      )
      .forEach(
        ({
          wm,
          widgetIframe,
        }: {
          wm: WidgetManager
          widgetIframe: HTMLIFrameElement
        }) => {
          // get the widget id and find the rendered widget iframe
          // then update the widget manager with the new element from the new document
          wm.widgetIframe = widgetIframe;

          // update the content document
          wm.cloIframeContentDoc = content;

          // allow the widget iframe to start up
          wm.postRender_init();
        },
      );

    /*
     * Message events are received on the clo-iframe window not on individual widget iframes.
     */
    this.renderer.listen(this.iframe?.contentWindow, 'message', (evt) => {
      // match the message event to a source frame handled by a WidgetManager
      const targetWidgetManager = this.getMessageEventSourceWidgetManager(evt);

      // message must not have come from an iframe we manage
      if (!targetWidgetManager) {
        return;
      }

      // delegate message handling to the WidgetManager
      targetWidgetManager.handleMessage(evt);
    });

    // this does not seem to be needed, but keeping it here just in case we
    // see weirdness with rendering changes in the future and have this reminder
    // this.cdr.detectChanges();

    return await next(content);
  }

  private getMessageEventSourceElement(
    evt: MessageEvent,
  ): HTMLIFrameElement | undefined {
    // the way message events come in for us with cross-frame requires
    // us to match the source element by comparing the window objects
    // this is the same way legacy Reader determined the source widget iframe

    // get all iframes on the exhibit document
    const widgetIframes: NodeListOf<HTMLIFrameElement> =
      this.iframe?.contentDocument?.querySelectorAll(
        this.iframeSelector,
      ) as NodeListOf<HTMLIFrameElement>;

    // must have not come from a frame we manage
    if (!widgetIframes) {
      return;
    }

    // find the one with a matching window object
    return Array.from(widgetIframes).find(
      (el) => el.contentWindow === evt.source,
    );
  }

  private getMessageEventSourceWidgetManager(
    evt: MessageEvent,
  ): WidgetManager | undefined {
    const sourceEle = this.getMessageEventSourceElement(evt);

    // not from a widget iframe
    if (!sourceEle) {
      return;
    }

    // match the source iframe to one attached to a WidgetManager
    const sourceFrameWM = this.widgetManagers.find(
      (wm) => wm.widgetUuid === sourceEle.id,
    );
    return sourceFrameWM;
  }

  public getNumWidgetManagers(): number {
    return this.widgetManagers.length;
  }

  public getWidgetManagerById(id: string): WidgetManager {
    return this.widgetManagers.find(
      (manager) => manager.widgetUuid === id,
    ) as WidgetManager;
  }

  public removeWidgetManagerById(id: string): void {
    this.widgetManagers = this.widgetManagers.filter(
      (wm: WidgetManager) => wm.widgetUuid !== id,
    );
  }

  /**
   * Widget Iframe Reset
   *
   * After the user uses the Reset Activities UI we need to reload the widget
   * iframes so they start at their beginning state again.  The WidgetManager will
   * know when a reset needs to happen, and it will ask for the reset from here
   * so that the full loading process can happen the same as if the user had navigated
   * to the ePUB page.
   */
  public async resetWidgetIframe(id: string): Promise<void> {
    // get the widget iframe in the epub iframe
    const wi = this.content?.getElementById(id) as HTMLIFrameElement;

    // get the widget manager instance
    const wm = this.getWidgetManagerById(id);

    // unload the WidgetManager instance
    await wm.widgetUnloadedEvent();

    // remove the WidgetManager instance
    this.removeWidgetManagerById(id);

    // preRender stuff

    // create a new widget manager
    const newWidgetManager = new WidgetManager(
      this.renderer,
      wi,
      this.content as HTMLDocument,
      this,
    );

    // do the pre render init, and flag that this was a reset
    // this will result in a fresh WidgetInstance in the global store
    newWidgetManager.preRender_init(true);

    // track this widget manager
    this.widgetManagers.push(newWidgetManager);

    // postRender stuf

    // allow the widget iframe to start up
    newWidgetManager.postRender_init();
  }

  /**
   * Action Dispatching
   *
   * Doing this from the Transformer because we need access to the ngZone
   * and it seemed cleaner to keep that here rather than pass a reference
   * into each WidgetManager instance.
   */

  // TODO update this to use the store instead of a callback
  public globalWirisRequest(
    requestId,
    mathMLContent,
    toolbar,
    callingWidgetManager,
  ): void {
    // this is called by an individual WidgetManager object
    // this proxies the request up to the iframe mediator
    // includes a reference back to the calling WidgetManager for response
    this.zone.run(() => {
      this.transformStore.dispatch(
        transformActions.wirisRequest({
          requestId,
          mathMLContent,
          toolbar,
          callingWidgetManager,
        }),
      );
    });
  }

  public searchWidgetData(widgetUuid: string): void {
    this.zone.run(() => {
      this.store.dispatch(widgetDataSearch({ widgetUuid }));
    });
  }

  public saveWidgetData(widgetUuid: string, dataStringified: string): void {
    this.zone.run(() => {
      this.store.dispatch(widgetDataSave({ widgetUuid, dataStringified }));
    });
  }

  public setWidgetInstance(widgetInstance: WidgetInstance): void {
    this.zone.run(() => {
      this.store.dispatch(widgetInstanceAdd({ widgetInstance }));
    });
  }

  public removeWidgetInstance(widgetUuid: string): void {
    this.zone.run(() => {
      this.store.dispatch(widgetInstanceRemove({ widgetUuid }));
    });
  }

  public setPreRender(widgetUuid: string, isPreRendered: boolean): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetInstanceSetPreRender({ widgetUuid, isPreRendered }),
      );
    });
  }

  public setPostRender(widgetUuid: string, isPostRendered: boolean): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetInstanceSetPostRender({ widgetUuid, isPostRendered }),
      );
    });
  }

  public setIframeLoaded(widgetUuid: string, isLoaded: boolean): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetInstanceIframeDomLoaded({ widgetUuid, isLoaded }),
      );
    });
  }

  public setIsRestored(widgetUuid: string, isRestored: boolean): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetInstanceSetDataRestored({ widgetUuid, isRestored }),
      );
    });
  }

  public setIframeReset(
    widgetUuid: string,
    widgetIframeReset: null | 'pending' | 'reset',
  ): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetInstanceResetIframe({ widgetUuid, widgetIframeReset }),
      );
    });
  }

  public textToSpeechRequest(
    textToSpeechRequest: TTSFloatingTextRequest,
  ): void {
    this.zone.run(() => {
      this.store.dispatch(
        widgetTextToSpeech({
          ePubIframe: this.iframe as HTMLIFrameElement,
          textToSpeechRequest,
        }),
      );
    });
  }

  /*
   * Selectors
   *
   * Just the one selector for now, used by WidgetManager to watch
   * for property changes on the WidgetInstance in the store.
   */
  public getWidgetInstance(widgetUuid: string): Observable<WidgetInstance> {
    return this.store.pipe(select(getWidgetsStateByWidgetUuid, { widgetUuid }));
  }
}
