/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Action } from '@ngrx/store';
import { Subject } from 'rxjs';

@Injectable()
export abstract class ExtendedComponentStore<T extends object, K extends Action>
  extends ComponentStore<T>
  implements OnDestroy {
  private readonly privateActions$ = new Subject<K>();
  actions$ = this.privateActions$.asObservable();

  constructor(initialState: T) {
    super(initialState);
  }

  dispatch(action: K): void {
    this.privateActions$.next(action);
  }

  ngOnDestroy(): void {
    this.privateActions$.complete();
  }
}
