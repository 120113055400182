import { InjectionToken } from '@angular/core';
import { combineReducers } from '@ngrx/store';

import {
  CONFIGURATION_FEATURE_KEY,
  configurationReducer,
} from './configuration/configuration.reducer';
import { ConfigurationState } from './configuration/configuration.state';

export const CORE_FEATURE_KEY = 'reader-core';

export interface CoreState {
  readonly [CONFIGURATION_FEATURE_KEY]: ConfigurationState
}

export interface CorePartialState {
  readonly [CORE_FEATURE_KEY]: CoreState
}

export const CoreReducer = combineReducers<CoreState>({
  [CONFIGURATION_FEATURE_KEY]: configurationReducer,
});
// See https://ngrx.io/guide/store/recipes/injecting#injecting-feature-config
export const CORE_FEATURE_REDUCER = new InjectionToken('core feature reducer', {
  factory: () => CoreReducer,
});
