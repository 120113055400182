import { createAction, props } from '@ngrx/store';
import {
  SpineItem,
  FlatTocItem,
  Payload,
  SearchResult,
} from '@mhe/reader/models';

export const init = createAction('Init Search');

export const setSearchContent = createAction(
  'Set Search Content ',
  props<{ spine: SpineItem[], flatToc: Record<number, FlatTocItem> }>(),
);

export const search = createAction(
  'Search by Value',
  props<{ value: string }>(),
);

export const resultSelected = createAction(
  'Search Result Selected',
  props<{ result: SearchResult }>(),
);
export const emitReadiatorEvent = createAction(
  'Emit Readiator Event',
  props<{ payload: Payload }>(),
);
export type SearchActions = ReturnType<
| typeof init
| typeof setSearchContent
| typeof search
| typeof resultSelected
| typeof emitReadiatorEvent
>;
