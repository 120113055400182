import { ActionReducer, createReducer, on } from '@ngrx/store';

import {
  loadFootnote,
  loadFootnoteError,
  loadFootnoteSuccess,
} from './footnote.actions';
import { footnoteProcess } from './footnote.reducer.utils';
import { FootnoteDictionaryState, initialState } from './footnote.state';

export const footnoteReducer: ActionReducer<FootnoteDictionaryState> =
  createReducer(
    initialState,
    on(loadFootnote, (state, { url }) => ({
      ...state,
      [url]: { ...state[url], loading: true, error: undefined },
    })),
    on(loadFootnoteSuccess, (state, { url, footnoteDocument }) => ({
      ...state,
      [url]: {
        footnote: { ...footnoteProcess(footnoteDocument) },
        loading: false,
      },
    })),
    on(loadFootnoteError, (state, { url, error }) => ({
      ...state,
      [url]: { ...state[url], loading: false, error },
    })),
  );
