import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { ConceptNavigatorStore } from './state/concept-navigator.store';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  conceptNavPrevBtnTriggered,
  conceptNavCurBtnTriggered,
  conceptNavNextBtnTriggered,
} from './state/concept-navigator.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reader-core-concept-navigator',
  templateUrl: './concept-navigator.component.html',
  styleUrls: ['./concept-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptNavigatorComponent implements OnDestroy {
  readonly conceptNavEnabled$ = this.store.conceptNavigatorEnabled$;
  readonly prevButtonDisabled$ = this.store.prevConceptButtonEnabled$.pipe(
    map((val) => !val),
  );

  readonly nextButtonDisabled$ = this.store.nextConceptButtonEnabled$.pipe(
    map((val) => !val),
  );

  readonly currentButtonDisabled$ =
    this.store.currentConceptButtonEnabled$.pipe(map((val) => !val));

  ButtonPurpose = ButtonPurpose;
  private readonly destroy = new Subject();

  constructor(
    private readonly store: ConceptNavigatorStore,
    private readonly translate: TranslateService,
  ) {}

  onPrevBtnClicked(): void {
    this.store.dispatch(conceptNavPrevBtnTriggered);
  }

  onCurBtnClicked(): void {
    this.store.dispatch(conceptNavCurBtnTriggered);
  }

  onNextBtnClicked(): void {
    this.store.dispatch(conceptNavNextBtnTriggered);
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
