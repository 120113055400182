<button
  mhe-button
  #fontResizerButton
  placement="bottom"
  [purpose]="ButtonPurpose.Icon"
  [mheTooltip]="'font-resizer.change_size' | translate"
  [disabled]="controlDisabled$ | async"
  (click)="openFontResizerDialog()"
>
  <i class="dpg-icon dpg-icon-keyboard-letters"></i>
</button>

