import { TocBonsaiNode, TocItem, BonsaiExpandedNodesMap, BonsaiTree } from '@mhe/reader/models';

export interface TocState {
  tree: BonsaiTree<TocBonsaiNode>
  expandedNodes: BonsaiExpandedNodesMap
  activeTocItem: TocItem | undefined
}

export const initialState: TocState = {
  tree: {},
  expandedNodes: {},
  activeTocItem: undefined,
};
