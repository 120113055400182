import { ChangeDetectorRef, Injectable } from '@angular/core';
import { logCatchError } from '@mhe/reader/common';
import { Observable, Subject, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { isSpineFpl } from '@mhe/reader/utils';
import { FixedPageService } from '../fixed-page.service';
import { DocTransform } from '@mhe/reader/models';
import { RenderEvents } from './models/render-events.model';
import { EPubService } from '@mhe/reader/features/epub-loader';
import { TransformService } from '@mhe/reader/transform/transform.service';

@Injectable()
export class EpubViewerRenderService {
  private readonly xmls = new XMLSerializer();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly epubService: EPubService,
    private readonly fixedPageService: FixedPageService,
    private readonly transformService: TransformService,
  ) {}

  readonly getDoc$ = ({
    book,
    spineItem,
    iframe,
  }: DocTransform): Observable<DocTransform> =>
    this.epubService
      .getPageDocument(spineItem)
      .pipe(map((doc): DocTransform => ({ book, spineItem, doc, iframe })));

  readonly preRender$ = ({
    book,
    spineItem,
    doc,
    iframe,
  }: DocTransform): Observable<DocTransform> =>
    from(this.transformService.preRender(book, spineItem, doc)).pipe(
      map((predoc): DocTransform => ({ book, spineItem, doc: predoc, iframe })),
      logCatchError('pre render error', false),
    );

  readonly loadDocument$ = ({
    book,
    spineItem,
    doc,
    iframe,
  }: DocTransform): Observable<DocTransform> => {
    const loaded = new Subject<HTMLDocument>();

    const content = this.xmls.serializeToString(doc);
    iframe.srcdoc = content;

    iframe.addEventListener(
      'load',
      () => {
        loaded.next(iframe.contentDocument as Document);
        loaded.complete();
      },
      { once: true },
    );

    return loaded.asObservable().pipe(
      map(
        (renderdoc): DocTransform => ({
          book,
          spineItem,
          doc: renderdoc,
          iframe,
        }),
      ),
      tap(() => this.cdr.markForCheck()),
    );
  };

  readonly postRender$ = ({
    book,
    spineItem,
    doc,
    iframe,
  }: DocTransform): Observable<DocTransform> =>
    from(this.transformService.postRender(book, spineItem, doc, iframe)).pipe(
      map(
        (newDoc) =>
          ({ book, spineItem, doc: newDoc, iframe } satisfies DocTransform),
      ),
      logCatchError('post render error', false),
    );

  readonly render$ = (
    docTransform: DocTransform,
    events?: RenderEvents,
  ): Observable<DocTransform> =>
    this.getDoc$(docTransform).pipe(
      switchMap((dt) => this.preRender$(dt)),
      tap(({ doc }) => events?.preRender?.(doc)),
      this.fixedPageLayout,
      switchMap((dt) => this.loadDocument$(dt)),
      switchMap((dt) => this.postRender$(dt)),
      tap((dt) => events?.postRender?.(dt)),
      this.centerFixedPageLayout,
      tap(() => events?.complete?.()),
    );

  /** fpl */
  readonly fixedPageLayout = (
    source$: Observable<DocTransform>,
  ): Observable<DocTransform> =>
    source$.pipe(
      map(({ book, spineItem, doc, iframe }) => {
        const { layout } = book.metadata;

        const layoutPrePaginated = layout === 'pre-paginated';
        const spineFpl = isSpineFpl(spineItem);

        const isFixedPage = layoutPrePaginated || spineFpl;

        if (isFixedPage) {
          doc = this.fixedPageService.initialize(doc, iframe, spineFpl);
        } else {
          this.fixedPageService.reset(iframe);
        }
        const docTransform: DocTransform = { book, spineItem, doc, iframe };
        return docTransform;
      }),
    );

  readonly centerFixedPageLayout = (
    source$: Observable<DocTransform>,
  ): Observable<DocTransform> =>
    source$.pipe(
      map(({ book, spineItem, doc, iframe }) => {
        const { layout } = book.metadata;
        const layoutPrePaginated = layout === 'pre-paginated';
        const spineFpl = isSpineFpl(spineItem);
        const isFixedPage = layoutPrePaginated || spineFpl;

        if (isFixedPage) {
          this.fixedPageService.centerEpub(iframe);
        }

        const docTransform: DocTransform = { book, spineItem, doc, iframe };
        return docTransform;
      }),
    );

  /**  focus on scroll  */
  readonly scrollListener$ = (iframe: HTMLIFrameElement): void => {
    iframe.contentDocument?.addEventListener('scroll', () => {
      const activeEl: any = document.activeElement;
      if (activeEl && activeEl !== iframe) activeEl.blur();
    });
  };

  /** doc helpers */
  readonly clearDocument$ = (iframe: HTMLIFrameElement): void => {
    iframe.contentDocument?.open();
    iframe.contentDocument?.write('');
    iframe.contentDocument?.close();
  };
}
