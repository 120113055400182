import { DoubleSpineItem, LexileLevel, ReaderAlert, SpineItem } from '@mhe/reader/models';
import { AllowedLevel, CustomButton, PdfUrl } from '@mhe/reader/types';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';

export interface ReaderState {
  epubUrl: string | undefined
  spineItem: SpineItem | undefined
  doubleSpineItem: DoubleSpineItem | undefined

  // drawers
  leftDrawerOpen: boolean
  rightDrawerOpen: boolean

  // config
  integrateRouting: boolean

  // navigation history
  navigationHistory: navigationActions.NavigationActions[]

  // assessments | assignments
  assessmentCompleted: boolean
  assignmentSubmitted: boolean
  assignmentSubmitting: boolean
  lastPageViewed: boolean

  // annotations
  showAnnotateButton: boolean

  // factory metadata
  factoryMetadata: {
    readerAiAssistEnabled: boolean
  }

  // ai reader
  aiAssistIsEnabled: boolean
  aiAssistIsOpen: boolean
  aiAssistIsReady: boolean
  aiAssistAnnotationData: AiAssistUserSelection

  // leveled content
  selectedLevel: AllowedLevel | undefined
  // lexile levels
  selectedLexileLevel: LexileLevel | undefined
  pgLexileLevels: LexileLevel[]
  renderedLexileLevel: LexileLevel | undefined

  // teacher toggles
  quickAnnotationEnabled: boolean
  teacherContentEnabled: boolean | undefined

  // ux alerts
  alerts: ReaderAlert[]

  // eBookshelf concurrency paywall
  hideReader: boolean

  // The custom button in the toolbar. undefined means no custom button
  customButton: CustomButton | undefined

  // print
  pdfLoading: boolean
  pdfUrl: PdfUrl | undefined
}

export interface AiAssistUserSelection {
  selectedText: string
  selectedEpubCfi: string
}

export const initialReaderState: ReaderState = {
  epubUrl: undefined,
  spineItem: undefined,
  doubleSpineItem: undefined,

  // drawers
  leftDrawerOpen: false,
  rightDrawerOpen: false,

  // config
  integrateRouting: false,

  // navigation history
  navigationHistory: [],

  // assessments | assignments
  assessmentCompleted: false,
  assignmentSubmitted: false,
  assignmentSubmitting: false,
  lastPageViewed: false,

  // annotations
  showAnnotateButton: false,

  // factory metadata
  factoryMetadata: {
    readerAiAssistEnabled: false,
  },

  // ai reader
  aiAssistIsEnabled: false,
  aiAssistIsOpen: false,
  aiAssistIsReady: false,
  aiAssistAnnotationData: {
    selectedText: '',
    selectedEpubCfi: '',
  },

  // leveled content
  selectedLevel: undefined,
  // lexile levels
  selectedLexileLevel: undefined,
  pgLexileLevels: [],
  renderedLexileLevel: undefined,

  // teacher toggles
  quickAnnotationEnabled: false,
  teacherContentEnabled: undefined,

  // ux alerts
  alerts: [],

  // eBookshelf concurrency paywall
  hideReader: false,

  customButton: undefined,

  // print
  pdfLoading: false,
  pdfUrl: undefined,
};
