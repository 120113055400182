/* eslint-disable @typescript-eslint/no-misused-promises */
import { Injectable } from '@angular/core';
import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transform, Transformer } from './transformer';

@Injectable()
export class TransformService {
  constructor(private readonly transforms: Transformer[]) {}

  /**
   * Run through pipeline with an HTMLDocument that will be serialized
   */
  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
  ): Promise<HTMLDocument> {
    const transforms = this.transforms
      .filter((t) => t.preRender)
      .map((t) => t.preRender?.bind(t));
    return await this.transform(transforms, book, spineItem, content, null);
  }

  /**
   * Run through pipeline with an HTMLDocument that is already loaded into the iframe
   */
  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
  ): Promise<HTMLDocument> {
    const transforms = this.transforms
      .filter((t) => t.postRender)
      .map((t) => t.postRender?.bind(t));
    return await this.transform(transforms, book, spineItem, content, iframe);
  }

  /**
   * Run through the array of transforms at a constant stack depth
   */
  private async transform(
    transforms: Transform[],
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement | null,
  ): Promise<HTMLDocument> {
    if (transforms.length === 0) {
      return content;
    }
    // Store promises and the associated resolve functions for the output of each layer
    const resultResolves: any[] = [];
    const promisedResultDocs = transforms.map(
      async() =>
        await new Promise<HTMLDocument>((resolve) =>
          resultResolves.push(resolve),
        ),
    );

    // Store promises and the associated resolve functions for the input of each layer
    const inputResolved: any[] = [];
    const promisedInputDocs = transforms.map(
      async() =>
        await new Promise<HTMLDocument>((resolve) =>
          inputResolved.push(resolve),
        ),
    );

    // Construct an array of next functions that connect a layer to the next layer
    const nexts: Next[] = transforms.map((transform, index) => {
      return async(nextDoc: HTMLDocument): Promise<HTMLDocument> => {
        // Terminal case where this is the deepest layer
        // No modifications to the HTMLDocument
        if (index === transforms.length - 1) {
          return nextDoc;
        }

        // Resolve the input HTMLDocument for the next deeper layer
        inputResolved[index + 1](nextDoc);

        // Return reference to results of next deepest layer
        return await promisedResultDocs[index + 1];
      };
    });

    promisedInputDocs.forEach(
      async(inContent, index) =>
        await inContent
          // As each input HTMLDocument is known, pass it to the next layer
          .then(async(c) => {
            return await transforms[index](
              book,
              spineItem,
              c,
              iframe as HTMLIFrameElement,
              nexts[index],
            );
          })
          // After the layer is done transforming, pass the result back out a layer
          .then((c) => {
            resultResolves[index](c);
          }),
    );

    // The outer most layer gets the doc passed in to this method
    inputResolved[0](content);

    // The outer most layer result is the final result of the stack
    return await promisedResultDocs[0];
  }
}
