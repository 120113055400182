import { createReducer, on } from '@ngrx/store';
import { widgetsInitialState } from './widgets.state';
import {
  widgetInstanceAdd,
  widgetInstanceRemove,
  widgetInstanceResetIframe,
  widgetInstanceSetWidgetSearch,
  widgetInstanceUpdateWidgetData,
  widgetInstanceSetPreRender,
  widgetInstanceSetPostRender,
  widgetInstanceIframeDomLoaded,
  widgetInstanceSetDataRestored,
  widgetInstanceIsSaving,
} from './widgets.actions';

export const widgetsReducer = createReducer(
  widgetsInitialState,

  // adding and removing WidgetInstances from global store
  on(widgetInstanceAdd, (state, { widgetInstance }) => ({
    ...state,
    [widgetInstance.widgetUuid]: widgetInstance,
  })),
  on(widgetInstanceRemove, (state, { widgetUuid }) => {
    const newState = { ...state };
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newState[widgetUuid];
    return newState;
  }),

  // updating properties of an existing WidgetInstance in the store
  on(widgetInstanceSetWidgetSearch, (state, { widgetUuid, isRequested }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      widgetSearchRequested: isRequested,
    },
  })),
  on(widgetInstanceUpdateWidgetData, (state, { widgetUuid, widgetData }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      widgetData,
    },
  })),
  on(widgetInstanceSetPreRender, (state, { widgetUuid, isPreRendered }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      preRendered: isPreRendered,
    },
  })),
  on(widgetInstanceSetPostRender, (state, { widgetUuid, isPostRendered }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      postRendered: isPostRendered,
    },
  })),
  on(widgetInstanceIframeDomLoaded, (state, { widgetUuid, isLoaded }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      iframeDomLoaded: isLoaded,
    },
  })),
  on(widgetInstanceSetDataRestored, (state, { widgetUuid, isRestored }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      dataRestoredOnLoad: isRestored,
    },
  })),
  on(widgetInstanceIsSaving, (state, { widgetUuid, isSaving }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      widgetDataSaving: isSaving,
    },
  })),
  on(widgetInstanceResetIframe, (state, { widgetUuid, widgetIframeReset }) => ({
    ...state,
    [widgetUuid]: {
      ...state[widgetUuid],
      widgetIframeReset,
    },
  })),
);
