export interface ReaderAlert {
  alertType: ReaderAlertType
  translateKey: string
}

export interface ReaderAlertGrouped {
  alert: ReaderAlert
  count: number
}

export const ReaderAlertType = [
  'alert-error',
  'alert-warning',
  'alert-success',
  'alert-info',
] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReaderAlertType = (typeof ReaderAlertType)[number];

export function isReaderAlertType(val: any): val is ReaderAlertType {
  return ReaderAlertType.includes(val);
}
