import { ApiAnnotation, HighlightColorClass, HighlightShapeClass } from '@mhe/reader/models';
import { HighlightColor, OptionsPane } from '../annotations-context-menu.model';

export interface AnnotationsContextMenuState {
  colorOptions: HighlightColor[]
  lastOrDefaultHighlightColorSelected: HighlightColorClass
  lastHighlightShapeSelected: HighlightShapeClass | undefined
  annotation: ApiAnnotation | null
  visibleOptionsPane: OptionsPane | null
  noteMaxCharacters: number
}

export const initialAnnoationsContextMenuState: AnnotationsContextMenuState = {
  annotation: null,
  lastOrDefaultHighlightColorSelected: 'yellow-highlight',
  lastHighlightShapeSelected: undefined,
  colorOptions: ['yellow', 'green', 'blue', 'pink', 'none'],
  visibleOptionsPane: null,
  noteMaxCharacters: 3500,
};
