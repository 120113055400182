import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';

import { Book, SpineItem } from '@mhe/reader/models';
import { TransformStore } from '@mhe/reader/state/transform';
import {
  authoringPostrenderDone,
  authoringPostrenderStart,
  authoringPrerenderDone,
  authoringPrerenderStart,
} from '../state/transform/transform.actions';
import { Next, Transformer } from './transformer';
import { first } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

@Injectable()
export class AuthoringTransformer implements Transformer {
  constructor(private readonly transformStore: TransformStore) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // This happens so fast that the the response action comes back before the subscription is set up.
    asyncScheduler.schedule(() =>
      this.transformStore.dispatch(
        authoringPrerenderStart({
          dt: { book, spineItem, doc: content, iframe },
        }),
      ),
    );

    await this.transformStore.actions$
      .pipe(ofType(authoringPrerenderDone), first())
      .toPromise();

    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // This happens so fast that the the response action comes back before the subscription is set up.
    asyncScheduler.schedule(() =>
      this.transformStore.dispatch(
        authoringPostrenderStart({
          dt: { book, spineItem, doc: content, iframe },
        }),
      ),
    );

    await this.transformStore.actions$
      .pipe(ofType(authoringPostrenderDone), first())
      .toPromise();

    return await next(content);
  }
}
