import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { TocBonsaiItem, TocItem } from '@mhe/reader/models';
import { TocStore } from '../state';
import { tocItemSelected } from '../state/toc.actions';
import { mapTreeToArray } from '../utils';
import { TocService } from '../toc.service';

@Component({
  selector: 'reader-core-scrubber',
  templateUrl: './scrubber.component.html',
  styleUrls: ['./scrubber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrubberComponent implements OnInit, OnDestroy {
  private readonly rootnodes$ = this.tocStore.rootNodes$;
  private readonly nodes$ = this.tocStore.tree$.pipe(map((tree) => mapTreeToArray(tree)));

  // internal value for handling i/o events
  private readonly index = new BehaviorSubject<number>(0);
  private readonly index$ = this.index.asObservable().pipe(distinctUntilChanged());

  readonly min = 0;
  readonly max$ = this.rootnodes$.pipe(map((nodes) => nodes.length - 1));

  // form control
  readonly scrubberControl = new FormControl(0);
  readonly val$ = this.scrubberControl.valueChanges.pipe(distinctUntilChanged());

  // active node
  private readonly activeNode$ = combineLatest([this.rootnodes$, this.index$]).pipe(
    map(([nodes, i]) => nodes[i]),
  );

  readonly activeTitle$ = this.activeNode$.pipe(map((node) => node?.title));

  // life cycle
  private readonly destroy$ = new Subject();

  constructor(
    private readonly ga: GoogleAnalyticsService,
    private readonly tocStore: TocStore,
    private readonly scrubberService: TocService,
  ) {}

  ngOnInit(): void {
    this.updateState();
    this.updateControl();

    this.analyticsEmitChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onSliderChange(event): void {
    this.scrubberControl.setValue(event.value);
  }

  private updateState(): void {
    this.val$
      .pipe(
        tap((val) => this.index.next(val as number)),
        debounceTime(300),
        withLatestFrom(this.activeNode$),
        map(([, node]) => node.tocItem),
        map(({ bonsaiId, ...toc }: TocBonsaiItem): TocItem => toc),
        takeUntil(this.destroy$),
      )
      .subscribe((tocItem) => {
        this.tocStore.dispatch(tocItemSelected({ tocItem }));
      });
  }

  private updateControl(): void {
    combineLatest([this.nodes$, this.tocStore.activeTocItem$])
      .pipe(
        map(([nodes, active]) => this.scrubberService.findRootNode(nodes, active?.id as string)),
        withLatestFrom(this.rootnodes$),
        map(([node, nodes]) => nodes.findIndex((n) => n.id === node.id)),
        takeUntil(this.destroy$),
      )
      .subscribe((i) => {
        this.scrubberControl.setValue(i, { emitEvent: false });
        this.index.next(i);
      });
  }

  /** analytics */
  private analyticsEmitChanges(): void {
    const valHistory$ = this.val$.pipe(
      pairwise(),
      filter(([p, _c]) => Boolean(p)),
    );

    const eventAction$ = valHistory$.pipe(
      map(([p, c]: [number, number]) => ({ eventAction: p < c ? 'Increase' : 'Decrease' })),
    );

    eventAction$.pipe(takeUntil(this.destroy$)).subscribe((eventParams) => {
      this.ga.event({ eventCategory: 'Scrubber', ...eventParams });
    });
  }
}
