import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { LastLocation } from '@mhe/reader/features/last-location';
import { Observable } from 'rxjs';

@Injectable()
export class LastLocationService {
  private readonly baseurl = '/v1/locations';

  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  searchLastLocation(
    api: string,
    payload: { userID: string, contextID: string, platform: string },
  ): Observable<LastLocation[]> {
    const url = `${api}${this.baseurl}/search`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<LastLocation[]>(url, payload),
    );
  }

  saveLastLocation(
    api: string,
    lastLocation: LastLocation,
  ): Observable<LastLocation> {
    const url = `${api}${this.baseurl}`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<LastLocation>(url, lastLocation),
    );
  }

  deleteLastLocation(api: string, uuid: string): Observable<any> {
    const url = `${api}${this.baseurl}/${uuid}`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.delete(url),
    );
  }
}
