<ng-container *ngIf="epubUrl$ | async as epubUrl">
  <reader-core-reader
    [config]="config$ | async"
    [epubUrl]="epubUrl"
    [integrateRouting]="true"
    [initialSpineId]="spine$ | async"
    [initialHash]="hash$ | async"
    [ngClass]="{
      'ai-assist-showing': (showAiAssist$ | async) === true,
      'ai-assist-enabled': (aiAssistIsEnabled$ | async) === true
    }"
  ></reader-core-reader>
</ng-container>

<ng-container *ngIf="(hasSession$ | async) && (timeoutDate$ | async)">
  <mhe-timeout-modal
    *ngIf="hasSession$ | async"
    [timeoutTimestamp]="timeoutDate$ | async"
    [showModalForSeconds]="showTimeoutModalFor$ | async"
    (cancel)="endSession()"
    (confirm)="refreshSession()"
    (expired)="endSession()"
    (modalToggled)="onModalToggle($event)"
  >
  </mhe-timeout-modal>
</ng-container>