import { Injectable } from '@angular/core';
import { ExtendedComponentStore, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import {
  BonsaiExpandedNodesMap,
  BonsaiTree,
  ROOT_BONSAI_NODE,
  TocBonsaiNode,
  TocItem,
} from '@mhe/reader/models';
import { mapTreeToArray, treeRootNodes } from '../utils';
import { tocItemsToBonsaiTree } from '../utils/map-bonsai-tree.util';
import { TocState, initialState } from './toc.state';
import * as actions from './toc.actions';
import { TocService } from '../toc.service';

@Injectable()
export class TocStore extends ExtendedComponentStore<
TocState,
actions.TocActions
> {
  constructor(private readonly tocService: TocService) {
    super(initialState);
  }

  /** selectors */
  readonly tree$ = this.select((state) => state.tree);
  // node lists
  readonly nodes$ = this.select(this.tree$, (tree) => mapTreeToArray(tree));
  readonly rootNodes$ = this.select(this.tree$, (tree) => treeRootNodes(tree));
  readonly rootNodeIds$ = this.select(this.rootNodes$, (nodes) =>
    nodes.map((node) => node.id),
  );

  readonly contentNodes$ = this.select(
    this.nodes$,
    this.rootNodes$,
    (nodes, rootnodes) =>
      nodes.filter((n) => !rootnodes.find((rn) => rn.id === n.id)),
  );

  // ui
  readonly expandedNodes$ = this.select((state) => state.expandedNodes);
  readonly activeTocItem$ = this.select(
    ({ activeTocItem }) => activeTocItem,
  ).pipe(filter((item) => Boolean(item)));

  readonly activeBonsaiNodeId$ = this.select(
    this.contentNodes$,
    this.activeTocItem$,
    (contentNodes, activeToc) => {
      const activeNodeLookup = contentNodes.filter(
        ({ tocItem }) => tocItem?.id === activeToc?.id,
      );
      const i = activeNodeLookup?.length - 1;
      const activeNode = activeNodeLookup?.[i];
      return activeNode?.id ?? '';
    },
  );

  /** updaters */
  readonly setTree = this.updater((state, items: TocItem[]) => {
    const tree = tocItemsToBonsaiTree(items);
    const expandedNodes = this.initExpandedNodes(tree);

    return { ...state, tree, expandedNodes } as unknown as TocState;
  });

  readonly setActiveTocItem = this.updater((state, activeTocItem: TocItem) => ({
    ...state,
    activeTocItem,
  }));

  // node expansion
  readonly toggleExpandedNode = this.updater((state, nodeId: string) => {
    const currentExpandedState = state.expandedNodes[nodeId];
    const expandedNodes = {
      ...state.expandedNodes,
      [nodeId]: !currentExpandedState,
    };

    return { ...state, expandedNodes };
  });

  readonly setNodeExpansion = this.updater(
    (state, nodes: BonsaiExpandedNodesMap) => {
      const expandedNodes = { ...state.expandedNodes, ...nodes };

      return { ...state, expandedNodes };
    },
  );

  readonly resetExpandedNodes = this.updater((state) => ({
    ...state,
    expandedNodes: {},
  }));

  /** effects */
  private readonly _setActiveItem$ = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.setActiveTocItem),
      tap(({ tocItem }) => this.setActiveTocItem(tocItem)),
      tap(({ tocItem }) => this.expandActiveNodeRoot$(tocItem)),
      logCatchError('_setActiveItem$'),
    ),
  );

  private readonly expandActiveNodeRoot$ = this.effect(
    (activeNode$: Observable<TocItem>) =>
      combineLatest([this.nodes$, activeNode$]).pipe(
        map(([nodes, { id }]) =>
          this.tocService.findRootNode(nodes, id as string),
        ),
        filter((node) => Boolean(node)),
        withLatestFrom(this.rootNodeIds$),
        tap(([node, rootIds]) => {
          const collapseRootNodes = rootIds.reduce(
            (acc, id) => ({ ...acc, [id]: false }),
            {},
          );
          this.setNodeExpansion(collapseRootNodes);
          this.toggleExpandedNode(node.id);
        }),
        logCatchError('expandActiveNodeRoot$'),
      ),
  );

  readonly nodeClick$ = this.effect((node$: Observable<TocBonsaiNode>) =>
    combineLatest([this.rootNodeIds$, node$]).pipe(
      map(([root, node]) => ({ node, inRoot: root.includes(node.id) })),
      tap(({ node: { id, tocItem }, inRoot }) =>
        inRoot
          ? this.toggleExpandedNode(id)
          : this.dispatch(actions.tocItemSelected({ tocItem })),
      ),
      logCatchError('nodeClick$'),
    ),
  );

  /** utils */
  private initExpandedNodes(
    tree: BonsaiTree<TocBonsaiNode>,
  ): BonsaiTree<TocBonsaiNode> {
    const nodes = mapTreeToArray(tree);
    const rootids = new Set(tree[ROOT_BONSAI_NODE]?.childIds ?? []);

    const expanded = {};
    for (let i = 0; i < nodes.length; i++) {
      if (!rootids.has(nodes[i].id)) {
        expanded[nodes[i].id] = true;
      }
    }

    return expanded;
  }
}
