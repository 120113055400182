import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { TTSFloatingTextRequest } from '@mhe/reader/models';

// These InjectionTokens should be provided via the injector through the cdk ComponentPortal
export const OVERLAY_REF = new InjectionToken<OverlayRef>(
  'Reader Text-to-speech Overlay Ref',
);
export const TTS_REQUEST = new InjectionToken<TTSFloatingTextRequest>(
  'Reader Text-to-speech Request',
);
