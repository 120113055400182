import { v4 as uuid } from 'uuid';
import { Dictionary } from 'lodash';
import { groupBy } from 'lodash-es';

import {
  SearchResult,
  SearchResultBonsaiNode,
  SearchResultsBonsaiTree,
  SEARCH_ROOT_BONSAI_NODE,
} from '@mhe/reader/models';

export function searchResultsToBonsaiTree(
  results: SearchResult[],
): SearchResultsBonsaiTree {
  results = results.slice(0, 1000);

  const ungroupedResults = results.filter((r) => r.sectionName === undefined);
  const titledResults = results.filter((r) => r.sectionName !== undefined);
  const groupedResults = groupBy(titledResults, (r) => r.sectionName || null);

  const { nodes, rootIds } = resultsToBonsaiNodes(
    groupedResults,
    ungroupedResults,
  );
  const tree = mapResultNodesToTree(nodes, rootIds);

  return tree;
}

/** bonsai */
function resultsToBonsaiNodes(
  groupedResults: Dictionary<SearchResult[]>,
  ungroupedResults: SearchResult[],
): { nodes: SearchResultBonsaiNode[], rootIds: string[] } {
  const entries = Object.entries(groupedResults);

  const seed: { nodes: SearchResultBonsaiNode[], rootIds: string[] } = {
    nodes: [],
    rootIds: [],
  };
  let bonsai = entries.reduce((acc, [group, results]) => {
    const resultNodes = (results as any[]).map((result) =>
      mapResultToBonsaiNode(result),
    );
    const resultIds = resultNodes.map((node) => node.id);
    const groupNode = mapResultGroupToBonsaiNode(group, resultIds);

    const nodes = [...acc.nodes, groupNode, ...resultNodes];
    const rootIds = [...acc.rootIds, groupNode.id];

    acc = { nodes, rootIds };
    return acc;
  }, seed);

  ungroupedResults.forEach((result) => {
    const node = mapResultToBonsaiNode(result);

    const { nodes, rootIds } = bonsai;
    bonsai = { nodes: [...nodes, node], rootIds: [...rootIds, node.id] };
  });

  return bonsai;
}

function mapResultNodesToTree(
  nodes: SearchResultBonsaiNode[],
  rootIds: string[],
): SearchResultsBonsaiTree {
  let tree = nodes.reduce<SearchResultsBonsaiTree>(
    (acc, node) => ({ ...acc, [node.id]: node }),
    {},
  );
  tree = setBonsaiRootNode(tree, rootIds);
  return tree;
}

function setBonsaiRootNode(
  tree: SearchResultsBonsaiTree,
  childIds: string[],
): SearchResultsBonsaiTree {
  const rootnode = {
    [SEARCH_ROOT_BONSAI_NODE]: { id: SEARCH_ROOT_BONSAI_NODE, childIds },
  };

  tree = { ...rootnode, ...tree };
  return tree;
}

const mapResultToBonsaiNode = (
  searchResult: SearchResult,
): SearchResultBonsaiNode => ({
  id: uuid(),
  title: searchResult.text,
  searchResult,
});

const mapResultGroupToBonsaiNode = (
  title: string,
  childIds: string[],
): SearchResultBonsaiNode => ({
  id: uuid(),
  title,
  childIds,
});
