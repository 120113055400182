import { createAction, props } from '@ngrx/store';
import { Session } from '@mhe/reader/models';

export const SessionStart = createAction(
  '[Reader][Session] Start',
  props<{ session: Partial<Session> }>(),
);

export const SessionStarted = createAction(
  '[Reader][Session] Started',
  (session: Session) => ({ session }),
);
