import { createAction } from '@ngrx/store';

export const confirmAssignmentSubmit = createAction(
  '[Assignment] Confirm submit',
);

export const submitAssignment = createAction('[Assignment] Submit');
export const handleAssignmentLexileLevel = createAction(
  '[Assignment] Handle Assignment Lexile Level',
);
export const submitAssignmentSuccess = createAction(
  '[Assignment] Submit success',
);
export const submitAssignmentError = createAction('[Assignment] Submit error');

export type AssignmentActions = ReturnType<
| typeof confirmAssignmentSubmit
| typeof submitAssignment
| typeof submitAssignmentSuccess
| typeof submitAssignmentError
| typeof handleAssignmentLexileLevel
>;
