<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" role="img">
  <title>{{ title }}</title>

  <!-- fill highlight -->
  <ng-container *ngIf="shape === 'epr-shape-square-filled'">
    <rect
      height="20"
      width="20"
      [attr.stroke]="border"
      [attr.fill]="fill"
      stroke-width="2"
      x="calc(50% - 10px)"
      y="calc(50% - 10px)"
    />
  </ng-container>

  <!-- box highlight -->
  <ng-container *ngIf="shape === 'epr-shape-square-outline'">
    <rect
      height="20"
      width="20"
      [attr.stroke]="border"
      [attr.fill]="fill"
      stroke-width="2"
      rx="1"
      x="calc(50% - 10px)"
      y="calc(50% - 10px)"
    />
    <rect
      height="16"
      width="16"
      [attr.stroke]="border"
      fill="white"
      stroke-width="2"
      x="calc(50% - 8px)"
      y="calc(50% - 8px)"
    />
  </ng-container>

  <!-- circle highlight -->
  <ng-container *ngIf="shape === 'epr-shape-round-outline'">
    <circle [attr.stroke]="border" [attr.fill]="fill" r="11" stroke-width="2" cx="50%" cy="50%" />
    <circle [attr.stroke]="border" fill="white" r="8" stroke-width="2" cx="50%" cy="50%" />
  </ng-container>

  <!-- underline highlight -->
  <ng-container *ngIf="shape === 'epr-shape-underline'">
    <rect
      height="5"
      width="24"
      [attr.stroke]="border"
      [attr.fill]="fill"
      stroke-width="2"
      x="calc(50% - 12px)"
      y="calc(50% - 2.5px)"
    />
  </ng-container>
</svg>
