import { TTSGender } from '@mhe/reader/models';

export function isAllowedReadspeakerGenderType(type: string): boolean {
  return (
    type === TTSGender.MALE ||
    type === TTSGender.FEMALE ||
    type === undefined ||
    type === null
  );
}
