export enum ToggleBoxActions {
  OPEN_TOGGLEBOX,
  CLOSE_TOGGLEBOX,
  CHECK_IF_INSIDE,
  CHECK,
}

export interface DetailElementAncestor {
  isInsideADetail: boolean
  detailElement: HTMLElement | null
}

export const handleToggleBoxOnParent = (
  element: HTMLElement,
  action: ToggleBoxActions,
): DetailElementAncestor | boolean | undefined => {
  if (!element) return;

  const elementCheck = lookForDetailElementInAncestors(element);

  switch (action) {
    case ToggleBoxActions.OPEN_TOGGLEBOX:
      if (
        elementCheck.isInsideADetail &&
        !elementCheck.detailElement?.attributes?.getNamedItem('open')
      ) {
        elementCheck.detailElement?.setAttribute('open', '');
      }
      break;
    case ToggleBoxActions.CLOSE_TOGGLEBOX:
      if (
        elementCheck.isInsideADetail &&
        elementCheck.detailElement?.attributes?.getNamedItem('open')
      ) {
        elementCheck.detailElement?.removeAttribute('open');
      }
      break;
    case ToggleBoxActions.CHECK_IF_INSIDE:
      return elementCheck.isInsideADetail;
    case ToggleBoxActions.CHECK:
      return elementCheck;
    default:
      return elementCheck;
  }
};

export function lookForDetailElementInAncestors(
  element: HTMLElement,
): DetailElementAncestor {
  if (element.tagName.toLowerCase() === 'reader-ui-root') {
    return { isInsideADetail: false, detailElement: null };
  }
  if (element.tagName.toLowerCase() === 'details') {
    return { isInsideADetail: true, detailElement: element };
  }
  if (element.parentElement) {
    return lookForDetailElementInAncestors(element.parentElement);
  }
  return { isInsideADetail: false, detailElement: null };
}

export const isToggleBoxOpenOnChild = (
  element: Element | HTMLElement,
): boolean => {
  if (!element?.childElementCount) return true;

  const elementChild = element.firstElementChild;

  return !!(
    containsToggleBoxElement(elementChild as Element) &&
    elementChild?.attributes.getNamedItem('open')
  );
};

export const containsToggleBoxElement = <T extends Element | HTMLElement>(
  element: T,
): boolean =>
  element?.classList.contains('dpg--toggle_box--container') ||
  element?.tagName === 'details' ||
  (element.firstElementChild?.classList.contains(
    'dpg--toggle_box--container',
  ) ??
    element.firstElementChild?.tagName === 'details');
