import { Injectable, Inject } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class I18nEffects {
  private readonly doc: Document;

  htmlElement$ = createEffect(
    () =>
      this.translateService.onLangChange.pipe(
        tap((event) => {
          const htmlElement = this.doc.getElementsByTagName('html').item(0);
          if (htmlElement) {
            htmlElement.setAttribute('lang', event.lang);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(private readonly translateService: TranslateService, @Inject(DOCUMENT) _doc: any) {
    // This is because strictMetadataEmit causes issues with this https://github.com/angular/angular/issues/20351
    this.doc = _doc;
  }
}
