import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { CoreState } from '../core.state';
import { getLocale } from '../configuration/configuration.selectors';
import { LocalTranslateLoader } from '../../i18n/local-translate-loader';

@Injectable()
export class I18nEffects {
  // This is to handle the challenges of not lazy loading our Core module.  If this module was lazy, we could simply use the
  //   forChild function on tha TranslateModule to isolate our translations.  Note: naming collisions are won by the consumer.
  appendTranslations$ = createEffect(
    () =>
      this.translateService.onLangChange.pipe(
        mergeMap((event) =>
          combineLatest([
            this.loader.getTranslation(event.lang),
            this.translateService.getTranslation(event.lang),
          ]).pipe(
            tap(([internal, existing]: [object, object]) =>
              this.translateService.setTranslation(event.lang, {
                ...internal,
                ...existing,
              }),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  locale$ = createEffect(
    () =>
      this.store.pipe(
        select(getLocale),
        map(
          (locale) =>
            locale ??
            (this.win.navigator.languages &&
            this.win.navigator.languages.length > 0
              ? this.win.navigator.languages[0]
              : this.win.navigator.language),
        ),
        map((locale) => this.loader.standardizeLocale(locale)),
        mergeMap((locale) =>
          this.translateService
            .use(locale)
            .pipe(
              catchError((e) =>
                this.translateService.use(
                  this.translateService.getDefaultLang(),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly store: Store<CoreState>,
    private readonly translateService: TranslateService,
    private readonly loader: LocalTranslateLoader,
    private readonly win: Window,
  ) {}
}
