import { ElementRef } from '@angular/core';
import { AnnotationListBonsaiNode } from '@mhe/reader/models';

export function setAnnotationAriaLabel(
  bonsaiTree: ElementRef,
  text: string,
  node: AnnotationListBonsaiNode,
): void {
  if (!bonsaiTree) {
    return;
  }
  const annotationNode: HTMLElement = bonsaiTree.nativeElement.querySelector(
    `[id=tree-node-${node.id}]`,
  );
  if (!annotationNode) {
    return;
  }
  let label = '';
  if (node.annotation?.highlight) {
    const highlight = annotationNode.querySelector('title');
    if (highlight) {
      label += `${highlight.innerHTML.trim()} `;
    }
  }
  const date = annotationNode.querySelector('.date');
  if (date) {
    label += `${date.innerHTML.trim()} `;
  }
  label += text;
  annotationNode.setAttribute('aria-label', label);
}
