import { WidgetInstance } from '@mhe/reader/models';

export const WIDGETS_STATE_KEY = 'widgets';

export type WidgetsDictionaryState = Record<string, WidgetInstance>;

export interface PartialWidgetsState {
  [WIDGETS_STATE_KEY]: WidgetsDictionaryState
}

export const widgetsInitialState: WidgetsDictionaryState = {};
