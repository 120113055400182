/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone, Renderer2 } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { AssessmentLaunch } from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AssessmentService } from '@mhe/reader/core/player-api/assessment.service';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import { MediatorUtils } from './mediator-utils';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';

@Injectable()
export class AssessmentsMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;

  constructor(
    protected window: Window,
    private readonly assessmentService: AssessmentService,
    private readonly config: ReaderConfigStore,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly readerStore: ReaderStore,
    private readonly renderer: Renderer2,
    private readonly transformStore: TransformStore,
    private readonly util: MediatorUtils,
    private readonly zone: NgZone,
  ) {
    super();
  }

  /** action effects */
  private readonly _launchAssessment$ = this.effect(() => {
    const ltiPost$ = this.config.assessmentLtiPost$;
    const ltiPostParams$ = this.config.assessmentLtiPostLaunchParams$.pipe(
      filter((params) => Boolean(params)),
      map((params): AssessmentLaunch => JSON.parse(params as string)),
    );

    const refreshLtiPostParams$ = ltiPostParams$.pipe(
      withLatestFrom(this.util.playerApi$),
      switchMap(([params, api]) => this.refreshAssessmentParams(params, api)),
      filter((params) => Boolean(params)),
    );
    const assessmentLaunch$ = combineLatest([ltiPost$, refreshLtiPostParams$]);

    return this.transformActions$.pipe(
      ofType(transformActions.launchAssessment),
      map(({ widgetFrame }) => widgetFrame),
      mergeMap((widgetFrame) => this.studentPreview(widgetFrame)),
      withLatestFrom(assessmentLaunch$),
      tap(([widgetFrame, [post, params]]) => {
        this.launchAssessmentFrame(widgetFrame, post as string, params);
      }),
      logCatchError('_launchAssessment$'),
    );
  });

  private readonly _assessmentCompleteListener$ = this.effect(() => {
    const { cloIframe$ } = this.epubViewerStore;

    return this.transformActions$.pipe(
      ofType(transformActions.launchAssessment),
      withLatestFrom(cloIframe$),
      tap(([, cloIframe]) => {
        const unlisten = this.renderer.listen(
          cloIframe.contentWindow,
          'message',
          ($event: MessageEvent) => {
            const action = $event?.data?.action;
            if (action === 'assessment-completed') {
              unlisten();
              this.zone.run(() =>
                this.readerStore.setAssessmentCompleted(true),
              );
              this.assessmentCompleteRedirect$();
            }
          },
        );
      }),
      logCatchError('_assessmentCompleteListener$'),
    );
  });

  /** effects */
  private readonly assessmentCompleteRedirect$ = this.effect(
    (complete$: Observable<void>) => {
      const assignmentReview$ = this.config.assignment$.pipe(
        map((assn) => assn === 'review'),
      );
      const returnUrl$ = this.config.launchPresentationReturnUrl$;

      return complete$.pipe(
        withLatestFrom(assignmentReview$, returnUrl$),
        filter(([, review, url]) => Boolean(review) && Boolean(url)),
        tap(([, , url]) => this.window.location.replace(url)),
      );
    },
  );

  /** helpers */
  private launchAssessmentFrame(
    widgetFrame: HTMLIFrameElement,
    post: string,
    params: AssessmentLaunch,
  ): void {
    const id = widgetFrame.id;
    const parent = this.renderer.parentNode(widgetFrame);

    // iframe
    const assessmentFrame: HTMLIFrameElement =
      this.renderer.createElement('iframe');
    this.renderer.setAttribute(assessmentFrame, 'id', id);
    this.renderer.setStyle(assessmentFrame, 'height', '700px');

    // form
    const assessmentForm: HTMLFormElement = this.renderer.createElement('form');
    this.renderer.setAttribute(assessmentForm, 'method', 'post');
    this.renderer.setAttribute(assessmentForm, 'action', post);

    const formEntries = Object.entries(params);
    for (const [key, val] of formEntries) {
      const input = this.renderer.createElement('input');
      this.renderer.setAttribute(input, 'type', 'hidden');
      this.renderer.setAttribute(input, 'name', key);
      this.renderer.setAttribute(input, 'value', val);

      this.renderer.appendChild(assessmentForm, input);
    }

    // load
    const frameload = this.renderer.listen(assessmentFrame, 'load', () => {
      assessmentFrame.contentDocument?.write(assessmentForm.outerHTML);
      assessmentFrame.contentDocument?.close();

      const form = assessmentFrame.contentDocument?.querySelector(
        'form',
      ) as HTMLFormElement;
      form.submit();

      frameload();
    });

    // replace
    this.renderer.removeChild(parent, widgetFrame);
    this.renderer.appendChild(parent, assessmentFrame);
  }

  // oauth
  private refreshAssessmentParams(
    params: AssessmentLaunch,
    api: string,
  ): Observable<AssessmentLaunch> {
    const { oauth_timestamp } = params;

    if (!oauth_timestamp) {
      // TODO: some form of notification?
      return EMPTY;
    }

    const oauthTimestamp = Number(oauth_timestamp);
    const expired = this.oauthExpired(oauthTimestamp);

    const refreshParams$ = this.assessmentService.refreshParams(api).pipe(
      catchError((error) => {
        // TODO: notify user?
        console.error('Failed to refresh Assessment LTI params', error);
        return EMPTY;
      }),
    );

    return !expired ? of(params) : refreshParams$;
  }

  private oauthExpired(oauthTimestamp: number): boolean {
    const expirationSeconds = 1770; // from legacy
    const currentTime = Math.floor(Date.now() / 1000);

    const expired = currentTime > oauthTimestamp + expirationSeconds;
    return expired;
  }

  // conditional displays
  private studentPreview(
    widgetFrame: HTMLIFrameElement,
  ): Observable<HTMLIFrameElement> {
    const { isStudent$, previewMode$ } = this.config;

    const showMessage$ = combineLatest([isStudent$, previewMode$]).pipe(
      map(([isStudent, previewMode]) => isStudent && previewMode),
    );

    return showMessage$.pipe(
      tap((show) => {
        if (show) {
          const message = 'Assessment viewable when assigned';
          this.insertMessageDiv(widgetFrame, message);
        }
      }),
      filter((show) => !show),
      mapTo(widgetFrame),
    );
  }

  private insertMessageDiv(frame: HTMLIFrameElement, message: string): void {
    const div = this.renderer.createElement('div');
    this.renderer.setStyle(div, 'background', '#000');
    this.renderer.setStyle(div, 'color', '#fff');
    this.renderer.setStyle(div, 'padding', '0.5em');
    this.renderer.setStyle(div, 'fontSize', '1em');
    this.renderer.setStyle(div, 'fontWeight', 'bolder');
    this.renderer.setStyle(div, 'textAlign', 'center');

    const divMessage = this.renderer.createText(message);
    this.renderer.appendChild(div, divMessage);

    const parent = this.renderer.parentNode(frame);
    this.renderer.removeChild(parent, frame);
    this.renderer.appendChild(parent, div);
  }
}
