import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  DoubleSpineItem,
  SearchParams,
  SearchResult,
  SpineItem,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  switchMapTo,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';
import { SearchStore } from '@mhe/reader/components/search';
import * as searchActions from '@mhe/reader/components/search/state/search.actions';
import { TocStore } from '@mhe/reader/components/toc';
import * as tocActions from '@mhe/reader/components/toc';

@Injectable()
export class SearchMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly searchActions$ = this.searchStore.actions$;
  private readonly tocActions$ = this.tocStore.actions$;

  constructor(
    private readonly config: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly searchStore: SearchStore,
    private readonly util: MediatorUtils,
    private readonly tocStore: TocStore,
  ) {
    super();
  }

  /** action effects */
  private readonly searchInitEvents$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setRightDrawer),
      filter(({ open }) => open),
      switchMapTo(this.searchStore.isReady$),
      filter((ready) => !ready),
      // init search
      tap(() => this.searchStore.dispatch(searchActions.init())),
      logCatchError('searchInitEvents$'),
    ),
  );

  private readonly searchContent$ = this.effect(() =>
    this.searchActions$.pipe(
      ofType(searchActions.init),
      withLatestFrom(this.config.enableElasticSearch$),
      filter(([, enableElasticSearch]) => !enableElasticSearch),
      switchMap(() =>
        combineLatest([this.readerStore.spine$, this.readerStore.flatToc$]),
      ),
      tap(([spine, flatToc]) =>
        this.searchStore.dispatch(
          searchActions.setSearchContent({
            spine: spine as SpineItem[],
            flatToc,
          }),
        ),
      ),
      logCatchError('searchContent$'),
    ),
  );

  private readonly _searchQuery$ = this.effect(() => {
    const searchparams$ = combineLatest([
      this.readerStore.doubleSpine$,
      this.config.isTeacher$,
      this.readerStore.activeLexileLevel$,
    ]).pipe(
      map(([doubleSpine, isTeacher, lexileLevel]) => ({
        doubleSpine,
        isTeacher,
        lexileLevel,
      })),
    );

    return this.searchActions$.pipe(
      ofType(searchActions.search),
      withLatestFrom(this.searchStore.searchContent$, searchparams$),
      filter(([, subjects]) => Boolean(subjects)),
      map(
        ([{ value: query }, subjects, params]): SearchParams => ({
          query,
          subjects,
          ...params,
        }),
      ),
      tap((searchParams) => this.searchStore.search$(searchParams)),
      logCatchError('_searchQuery$'),
    );
  });

  private readonly searchResultSelected$ = this.effect(() =>
    this.searchActions$.pipe(
      ofType(searchActions.resultSelected),
      this.util.tapDoubleSpread(
        ({ result }: { result: SearchResult }) => this.navSearchResult$(result),
        ({ result }: { result: SearchResult }) =>
          this.navSearchResultDoubleSpread$(result),
      ),
      tap(() => this.util.rightDrawer(false)),
      logCatchError('searchResultSelected$'),
    ),
  );

  private readonly _clearSearch$ = this.effect(() => {
    return this.readerStore.activeLexileLevel$.pipe(
      filter((level) => Boolean(level)),
      distinctUntilChanged(),
      tap(() => this.searchStore.setResults([])),
      logCatchError('_clearSearch$'),
    );
  });

  /** effects */
  private readonly navSearchResult$ = this.effect(
    (result$: Observable<SearchResult>) =>
      result$.pipe(
        withLatestFrom(this.readerStore.flatToc$),
        map(([result, toc]) => {
          const { id, spineIndex } = result;
          const item = toc[spineIndex];

          const spineItemId = item?.spineItem?.id;
          const resultNotSpineItem = id !== spineItemId;
          const hash = resultNotSpineItem ? `#${id}` : undefined;
          this.tocStore.dispatch(
            tocActions.setActiveTocItem({ tocItem: item }),
          );
          return { spineIndex, hash };
        }),
        tap(({ spineIndex, hash }) =>
          this.util.navigateToSpineIndex(spineIndex, hash),
        ),
        logCatchError('navSearchResult$'),
      ),
  );

  private readonly navSearchResultDoubleSpread$ = this.effect(
    (result$: Observable<SearchResult>) =>
      result$.pipe(
        withLatestFrom(this.readerStore.doubleSpine$),
        map(([{ spineIndex }, spine]) =>
          this.util.getDoubleSpineIndexFromSpineIndex(
            spineIndex,
            spine as DoubleSpineItem[],
          ),
        ),
        tap((index) => this.util.navDoubleSpread(index)),
        logCatchError('navSearchResultDoubleSpread$'),
      ),
  );
}
