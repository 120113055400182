/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';
import { BonsaiTreeComponent } from '@mhe/ngx-bonsai-reader';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

import {
  SEARCH_ROOT_BONSAI_NODE,
  SearchResult,
  SearchResultBonsaiNode,
} from '@mhe/reader/models';
import { SearchStore } from './state';
import * as searchActions from './state/search.actions';

@Component({
  selector: 'reader-core-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() searchDebounce = 500;

  // eslint-disable-next-line accessor-pairs
  @Input() set minSearchLength(value: number) {
    this.searchStore.setMinSearchLength(value);
  }

  @Output() closeEvent = new EventEmitter<void>();
  @ViewChild('searchInput', { static: true }) searchInput: {
    inputChange: Observable<{ value: string }>
    inputEl: ElementRef
  };

  @ViewChild('searchBonsai', { read: BonsaiTreeComponent })
    searchBonsai: BonsaiTreeComponent;

  readonly ready$ = this.searchStore.isReady$;
  readonly searching$ = this.searchStore.searching$;
  readonly results$ = this.searchStore.results$.pipe(
    tap((results) => this.searchResultsA11y(results.length)),
  );

  readonly tree$ = this.searchStore.resultsTree$;
  readonly expandedNodes$ = this.searchStore.expandedNodes$;
  readonly downloadPercent$ = this.searchStore.downloadPercent$;
  readonly searchLabel$ = this.ready$.pipe(
    map((ready) => (ready ? 'search.enter_search_term' : 'search.loading')),
  );

  searchTerm$: Observable<string>;

  // expose import ref
  readonly btnIcon = ButtonPurpose.Icon;
  readonly ButtonPurpose = ButtonPurpose;
  readonly rootNodeId = SEARCH_ROOT_BONSAI_NODE;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly searchStore: SearchStore,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const { searchDebounce } = this;
    let idSet = false;

    this.ready$.pipe(takeUntil(this.destroy$)).subscribe((ready) => {
      const inputEl = this.searchInput.inputEl.nativeElement as HTMLElement;
      const currentPlaceholder = ready
        ? this.translate.instant('search.enter_search_term')
        : this.translate.instant('search.loading');
      inputEl.setAttribute('aria-label', currentPlaceholder);
      if (ready) {
        const currentId = inputEl.getAttribute('id') || '';
        if (!idSet) {
          inputEl.setAttribute('id', `${currentId}-child`);
          idSet = true;
        }
      }
    });

    this.searchTerm$ = this.searchInput.inputChange.pipe(
      debounceTime(searchDebounce),
      map(({ value }) => value),
      tap((value) => this.searchStore.dispatch(searchActions.search({ value }))),
    );
  }

  selectResult(node: SearchResultBonsaiNode): void {
    this.searchStore.dispatch(
      searchActions.resultSelected({
        result: node.searchResult as SearchResult,
      }),
    );
  }

  toggleNodeExpanded(nodeId: string): void {
    this.searchStore.toggleExpandedNode(nodeId);
  }

  focusResults(): void {
    if (this.searchBonsai) {
      this.searchStore.focusResults$(this.searchBonsai);
    }
  }

  getPageNumberNode(results: SearchResult[]): SearchResultBonsaiNode {
    const pageNumberResult = results.filter((result) => result.isPageNumber)[0];

    const pageNumberNode = {
      id: pageNumberResult.id,
      searchResult: pageNumberResult,
      title: pageNumberResult.text,
    };

    return pageNumberNode;
  }

  searchResultsA11y(resultsLength: number): void {
    if (!resultsLength) {
      // Refine results
      const currentInputValue = this.searchInput.inputEl.nativeElement.value;
      this.translateSearchResult(
        currentInputValue !== ''
          ? 'search.refine_search'
          : 'search.enter_search_term',
        {
          resultsLength,
          resultKind: 'refine',
        },
      );
      return;
    }
    if (resultsLength <= 1000) {
      // Search results in range
      const singleResult = resultsLength === 1;
      this.translateSearchResult(
        singleResult ? 'search.result' : 'search.results',
        {
          resultsLength,
          resultKind: 'results',
        },
      );
      return;
    }
    if (resultsLength > 1000) {
      // Limited results
      this.translateSearchResult('search.limited_results', {
        resultsLength,
        resultKind: 'limited',
      });
    }
  }

  private translateSearchResult(
    searchKey: string,
    {
      resultKind,
      resultsLength,
    }: {
      resultsLength: number
      resultKind: 'refine' | 'results' | 'limited'
    },
  ): void {
    this.getResultTranslation(searchKey).subscribe(
      async(translation: string) =>
        await this.announceTranslation(
          resultKind === 'results' && resultsLength
            ? `${resultsLength} ${translation}`
            : translation,
        ),
    );
  }

  private getResultTranslation(searchKey: string): Observable<any> {
    return this.translate.get(searchKey);
  }

  private async announceTranslation(
    translation: string,
    politeness: 'polite' | 'assertive' = 'polite',
  ): Promise<void> {
    await this.liveAnnouncer.announce(translation, politeness);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
