import {
  TocItem,
  ROOT_BONSAI_NODE,
  BonsaiTree,
  TocBonsaiNode,
} from '@mhe/reader/models';
import { findTocNodes } from './map-bonsai-tree.util';

export function treeRootNodes(
  tree: BonsaiTree<TocBonsaiNode>,
): TocBonsaiNode[] {
  const root = tree[ROOT_BONSAI_NODE];
  const ids = root?.childIds ?? [];

  const nodes = ids.map((id) => tree[id]);

  return nodes;
}

export function mapTocParentTree(
  treeNodes: TocBonsaiNode[],
  toc: TocItem,
): string[] {
  const nodes = findTocNodes(treeNodes, toc);
  const nodeIds = nodes.map((node) => node.id);

  // ensure nodes-self are expanded
  const expandNodes = nodes.filter((node) => node.childIds?.length);
  const expandIds = expandNodes.map((node) => node.id);

  let parentTree = [...expandIds];
  parentTree = [...parentTree, ...mapUpTreeNodes(treeNodes, nodeIds)];

  return [...new Set(parentTree)];
}

export function mapUpTreeNodes(
  treeNodes: TocBonsaiNode[],
  ids: string[],
): string[] {
  let parentTree: string[] = [];

  for (const id of ids) {
    const parent = treeNodes.find((node) => node.childIds?.includes(id));

    if (parent) {
      const pid = parent.id;
      parentTree = [pid, ...mapUpTreeNodes(treeNodes, [pid])];
    }
  }

  return parentTree;
}

export function mapTreeToArray(
  tree: BonsaiTree<TocBonsaiNode>,
  includeBonsaiRoot = false,
): TocBonsaiNode[] {
  const nodes = Object.keys(tree).map((k) => tree[k]);

  if (!includeBonsaiRoot) {
    return nodes.filter((node) => node.id !== ROOT_BONSAI_NODE);
  }

  return nodes;
}
