/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';

import { GoogleAnalyticsEvent } from './ga-event.model';
import { GoogleAnalyticsPageview } from './ga-pageview.model';
import { GoogleAnalyticsTimingEvent } from './ga-timing.model';

const has = Object.prototype.hasOwnProperty;
declare const ga: Function;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  private readonly timingBase: number;

  constructor(protected window: Window) {
    this.call_ga(() =>
      ga('create', 'UA-78008814-1', 'auto', 'rdrxTracker', { sampleRate: 10 }),
    );
    this.timingBase = (this.window as any).gaTimingBase || Date.now();
  }

  // wrapper to ensure 'ga()' is available to call
  private readonly call_ga = (fn: Function): void => {
    if (typeof ga === 'undefined' || !has.call(this.window as any, 'ga')) { return; }
    fn();
  };

  pageview(event: GoogleAnalyticsPageview): void {
    this.call_ga(() => ga('rdrxTracker.send', 'pageview', event));
  }

  event(event: GoogleAnalyticsEvent): void {
    this.call_ga(() => ga('rdrxTracker.send', 'event', { ...event }));
  }

  timing(
    event:
    | GoogleAnalyticsTimingEvent
    | Omit<GoogleAnalyticsTimingEvent, 'timingValue'>,
  ): void {
    if (!has.call(event, 'timingValue')) {
      const timingValue = this.getTimingValue();
      event = { ...event, timingValue };
    }

    this.call_ga(() => ga('rdrxTracker.send', 'timing', { ...event }));
  }

  private getTimingValue(): number {
    const { performance } = this.window as any;

    if (performance) {
      return Math.floor(performance.now());
    } else {
      return Math.min(60000, Date.now() - this.timingBase);
    }
  }
}
