export type AllowedLevel =
  | 'on_level'
  | 'above_level'
  | 'approaching_level'
  | 'english_language_development';

export function isAllowedLevel(level: string): level is AllowedLevel {
  return (
    level === 'on_level' ||
    level === 'above_level' ||
    level === 'approaching_level' ||
    level === 'english_language_development'
  );
}
