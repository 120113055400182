import { ApiAnnotation } from '../../../models/annotations/api-annotation.model';
import { ROOT_BONSAI_NODE } from '../../../models/bonsai/root-keys';
import { BonsaiTree } from '../../../models/bonsai/tree.model';
import { AnnotationGroup, AnnotationListBonsaiNode } from '@mhe/reader/models';
import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en-US');

export function groupsToTree(
  groups: AnnotationGroup[],
  filterFn: (annotation: ApiAnnotation) => boolean = () => true,
  nodeTitleFn: (annotation: ApiAnnotation) => string = (a) => a.text,
): BonsaiTree<AnnotationListBonsaiNode> {
  const tree = {
    [ROOT_BONSAI_NODE]: { id: ROOT_BONSAI_NODE, childIds: [] as string[] },
  };
  for (const group of groups) {
    tree[ROOT_BONSAI_NODE].childIds.push(group.groupId);
    tree[group.groupId] = {
      id: group.groupId,
      title: group.groupLabel,
      childIds: [],
      collapsible: true,
    };
    for (const annotation of group.annotations || []) {
      tree[group.groupId].childIds.push(annotation.resourceUUID);
      if (filterFn(annotation)) {
        tree[annotation.resourceUUID] = {
          id: annotation.resourceUUID,
          title: nodeTitleFn(annotation),
          collapsible: false,
          childIds: [],
          // Generate unique label for accessibility annoucement on trash can
          ariaLabel: getAriaLabel(annotation),
          annotation,
        };
      }
    }
  }
  return tree;
}

function getAriaLabel(annotation: ApiAnnotation): string {
  const date = datePipe.transform(annotation.updatedAt, 'shortDate');
  const text = annotation.note?.trim() ?? annotation.text?.trim();
  return date + ' ' + text?.slice(0, 100);
}
