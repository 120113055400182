import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiSpine } from '@mhe/reader/models';

@Injectable()
export class SpinesService {
  private readonly baseurl = '/v1/spines';

  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  getSpines(
    api: string,
    context: { userID: string, contextID: string, platform: string },
  ): Observable<ApiSpine[]> {
    const url = `${api}${this.baseurl}/search`;
    return this.reducedModeUtils
      .monitorSessionEnded(() =>
        this.http.post<ApiSpine[] | null>(url, context),
      )
      .pipe(map((spinesOrNull) => spinesOrNull ?? []));
  }
}
