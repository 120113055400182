import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';

@Component({
  selector: 'rdrx-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() withClose = true;
  @Output() closeEvent = new EventEmitter();

  ButtonPurpose = ButtonPurpose;

  closeClick(): void {
    this.closeEvent.emit();
  }
}
