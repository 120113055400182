import { Injectable, Renderer2 } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  CSS_DPG_LEXILE_SHOW_PREFIX,
  CSS_LEXILE_BASE,
  CSS_LEXILE_CONTAINER,
  CSS_LEXILE_CONTEXT_PREFIX,
  LexileLevel,
  LexileLevels,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ReaderStore } from '../components/reader/state';
import * as assignmentActions from '../components/reader/state/assignment.actions';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';

@Injectable()
export class LexileLevelMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;

  private readonly activeLevel$ = this.readerStore.activeLexileLevel$;
  private readonly pgLevels$ = this.readerStore.pgLexileLevels$;

  private readonly CSS_SELECT_NONE = 'mhe-user-select-none';

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly renderer: Renderer2,
    private readonly transformStore: TransformStore,
  ) {
    super();
  }

  /** effects */
  private readonly _setPageLexileLevels$ = this.effect(() => {
    return this.transformActions$.pipe(
      ofType(transformActions.setPageLexileLevels),
      tap(({ pgLevels }) => this.readerStore.setPageLexileLevels(pgLevels)),
      logCatchError('_setPageLexileLevels$'),
    );
  });

  private readonly _applyLexileLevel$ = this.effect(() => {
    const { activeLevel$, pgLevels$ } = this;

    return this.transformActions$.pipe(
      ofType(transformActions.applyLexileLevel),
      map(({ content }) => content.querySelector('body')),
      withLatestFrom(activeLevel$, pgLevels$),
      filter(([_, activeLevel]) => Boolean(activeLevel)),
      tap(([body, activeLevel, pgLevels]) => {
        const contextLevel = this.getClosestLexileLevel(
          activeLevel as LexileLevel,
          pgLevels,
        );
        this.applyContextLexileCss(body as HTMLBodyElement, contextLevel);
        this.readerStore.setRenderedLexileLevels(contextLevel);
        this.readerStore.dispatch(
          assignmentActions.handleAssignmentLexileLevel(),
        );
      }),
      logCatchError('_applyLexileLevel$'),
    );
  });

  private readonly _changeLexileLevel$ = this.effect(() => {
    const { pgLevels$ } = this;

    return this.transformActions$.pipe(
      ofType(transformActions.subscribeLexileLevelChange),
      map(({ content }) => content.querySelector('body')),
      switchMap((body) => {
        return this.activeLevel$.pipe(
          filter((activeLevel) => Boolean(activeLevel)),
          distinctUntilChanged(),
          withLatestFrom(pgLevels$),
          tap(([activeLevel, pgLevels]) => {
            const contextLevel = this.getClosestLexileLevel(
              activeLevel as LexileLevel,
              pgLevels,
            );
            this.applyContextLexileCss(body as HTMLBodyElement, contextLevel);
          }),
        );
      }),
      logCatchError('_changeLexileLevel$'),
    );
  });

  /** helpers */
  private getClosestLexileLevel(
    requestedLevel: LexileLevel,
    availableLevels: LexileLevel[],
  ): LexileLevel {
    if (!availableLevels?.length || availableLevels.includes(requestedLevel)) {
      return requestedLevel;
    }

    const availableLevelRanks = this.mapLexileLevelsToRank(availableLevels);
    const requestedRank = this.getLexileLevelRank(requestedLevel);

    const closestRank = availableLevelRanks.reduce((prev, curr) =>
      Math.abs(curr - requestedRank) < Math.abs(prev - requestedRank)
        ? curr
        : prev,
    );

    const displayLevel = LexileLevels[closestRank];
    return displayLevel;
  }

  private mapLexileLevelsToRank(levels: LexileLevel[]): number[] {
    return levels.map((level) => this.getLexileLevelRank(level));
  }

  private getLexileLevelRank(level: LexileLevel): number {
    return LexileLevels.indexOf(level);
  }

  // css management
  private applyContextLexileCss(
    body: HTMLBodyElement,
    level: LexileLevel,
  ): void {
    this.setAnnotationSelectNone(body);
    this.removeExistingContextLexileCss(body);

    const lexileContextCss = `${CSS_LEXILE_CONTEXT_PREFIX}${level}`;
    const lexileDpgShowCss = `${CSS_DPG_LEXILE_SHOW_PREFIX}${level}`;

    this.renderer.addClass(body, lexileContextCss);
    this.renderer.addClass(body, lexileDpgShowCss);
    this.allowActiveLevelSelection(body, level);
  }

  private removeExistingContextLexileCss(body: HTMLBodyElement): void {
    const bodyCss = Object.values(body.classList);
    const bodyLexileContextCss = bodyCss.filter((css) =>
      css.startsWith(CSS_LEXILE_CONTEXT_PREFIX),
    );
    const bodyLexileDpgShowCss = bodyCss.filter((css) =>
      css.startsWith(CSS_DPG_LEXILE_SHOW_PREFIX),
    );

    bodyLexileContextCss.forEach((css) => this.renderer.removeClass(body, css));
    bodyLexileDpgShowCss.forEach((css) => this.renderer.removeClass(body, css));
  }

  // css for annotations
  private setAnnotationSelectNone(body: HTMLBodyElement): void {
    const CONTAINER_SELECTOR = `.${CSS_LEXILE_CONTAINER}`;

    const containers = body.querySelectorAll(CONTAINER_SELECTOR);
    containers.forEach((container) => {
      this.renderer.addClass(container, this.CSS_SELECT_NONE);
    });
  }

  private allowActiveLevelSelection(
    body: HTMLBodyElement,
    level: LexileLevel,
  ): void {
    const CONTAINER_SELECTOR = `.${CSS_LEXILE_CONTAINER}`;
    const LEVEL_CONTAINER_SELECTOR = `.${CSS_LEXILE_BASE}${level}`;

    const selector = `${CONTAINER_SELECTOR}${LEVEL_CONTAINER_SELECTOR}`;
    const containers = body.querySelectorAll(selector);

    containers.forEach((container) => {
      this.renderer.removeClass(container, this.CSS_SELECT_NONE);
    });
  }
}
