/* eslint-disable max-len */
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { LocalTranslateLoader } from './i18n/local-translate-loader';
import { ReaderModule } from '../components/reader/reader.module';
import { CORE_FEATURE_KEY, CORE_FEATURE_REDUCER } from './state/core.state';
import { I18nEffects } from './state/i18n/i18n.effects';
import { GlobalStoreModule } from '@mhe/reader/global-store/global-store.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReaderApiLoggingInterceptor } from './interceptors/reader-api-logging.interceptor';

@NgModule({
  imports: [
    ReaderModule,
    GlobalStoreModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(CORE_FEATURE_KEY, CORE_FEATURE_REDUCER),
    EffectsModule.forFeature([I18nEffects]),
  ],
  providers: [
    LocalTranslateLoader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReaderApiLoggingInterceptor,
      multi: true,
    },
  ],
})
export class ReaderCoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: ReaderCoreModule) {
    if (parentModule) {
      throw new Error(
        'ReaderCoreModule is already imported. This module can only be imported once.  Please consider using the smaller exported modules where applicable or importing this module higher in the hierarchy.',
      );
    }
  }
}
