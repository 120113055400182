export class PlayerApiClientError extends Error {
  constructor(readonly method: string, readonly path: string, message: string) {
    super(
      `Error while communicating with Player API (${method} ${path}): ${message}`,
    );
  }
}

export type LtiParams = Record<string, string>;

export function isLtiParams(subject: any): subject is LtiParams {
  return (
    typeof subject === 'object' &&
    Object.keys(subject).every(
      (key) => typeof key === 'string' && typeof subject[key] === 'string',
    )
  );
}

// Value to pass to readspeaker API for successful call. Display for DNN names is edited in the class
export enum TTSVoiceName {
  JAMES = 'James',
  PAUL = 'Paul',
  ASHLEY = 'Ashley',
  BETH = 'Beth',
  JULIE = 'Julie',
  KATE = 'Kate',
  SOPHIE = 'Sophie',
  JAMESDNN = 'James-DNN',
  ERINDNN = 'Erin-DNN',
  JULIEDNN = 'Julie-DNN',
  MARKDNN = 'Mark-DNN',
  MOLLYDNN = 'Molly',
  ROYDNN = 'Roy-DNN',
  SAMUELDNN = 'Samuel-DNN',
  SOPHIEDNN = 'Sophie-DNN',
}

export enum TTSGender {
  MALE = 'm',
  FEMALE = 'f',
}

export enum TTSMathFlag {
  TRUE = 't',
  FALSE = 'f',
}

export enum TTSJobStatus {
  PENDING = 'pending',
  NOT_FOUND = 'not-found',
  COMPLETE = 'complete',
  STALLED = 'stalled', // determined by UI
}

export enum TTSRawTextType {
  MP3 = 'mp3',
}

export interface TTSRequest {
  content: string
  language: string
  gender: TTSGender
  voice?: TTSVoice
  mathFlag?: TTSMathFlag
}

export interface TTSUtteranceTiming {
  utterances: number[]
  dur: number
  min: number
  max: number
}

export interface ChunkUtteranceBound {
  chunkIndex: number
  max: number
  min: number
}

export interface TTSChunkMetadata {
  chunkCount: 1
  chunkUtteranceBounds: ChunkUtteranceBound[]
  maxUtterances: number
  requestCacheKey: string
  requestGender: TTSGender
  requestLang: string
  requestMathFlag: TTSMathFlag
}

export interface TTSChunk {
  /**
   * The position for this chunk within
   * the text.
   */
  chunkIndex: number

  /**
   * Additional data about the data and request.
   * This contains the requestCacheKey used for
   * fetching the chunk collection.
   */
  metadata: TTSChunkMetadata

  /**
   * A url to obtain the audio file for this
   * chunk.
   */
  mp3: string

  /**
   * Timing data used to align the audio with the
   * written text.
   */
  timing: TTSUtteranceTiming[]
}

export interface TTSChunkCollection {
  /**
   * The total number of chunks in the collection.
   */
  chunkCount: number

  /**
   * An array of chunks within the collection. An element
   * that is undefined means that the chunk has not completed
   * processing.
   */
  chunks: Array<TTSChunk | undefined>

  /**
   * How many chunks have been processed out of
   * the total chunkCount.
   */
  processedCount: number

  /**
   * An indication of whether the collection
   * has completed processing.
   */
  status: TTSJobStatus

  /**
   * Additional data about the data and request.
   */
  meta: TTSChunkMetadata
}

export interface TTSRawTextTranslationResponse {
  /**
   * The audio file URL.
   */
  location?: string
}

export interface TTSRawTextRequest {
  // Readspeaker accepts plain text and HTML strings on this param
  // this is better than the "text" param which would not read HTML entites
  html: string

  language: string
  gender: TTSGender
  voice?: TTSVoiceName
  type: TTSRawTextType
}

export interface EBookshelfConcurrencyResponse {
  result: string | number // API sends multiple data types:  '0' or 1
}

export interface LogoutResponse {
  status: string
}

export class TTSVoice {
  static readonly JAMES = new TTSVoice(
    TTSVoiceName.JAMES,
    TTSGender.MALE,
    true,
  );

  static readonly PAUL = new TTSVoice(TTSVoiceName.PAUL, TTSGender.MALE, false);

  static readonly ASHLEY = new TTSVoice(
    TTSVoiceName.ASHLEY,
    TTSGender.FEMALE,
    true,
  );

  static readonly BETH = new TTSVoice(
    TTSVoiceName.BETH,
    TTSGender.FEMALE,
    false,
  );

  static readonly JULIE = new TTSVoice(
    TTSVoiceName.JULIE,
    TTSGender.FEMALE,
    false,
  );

  static readonly KATE = new TTSVoice(
    TTSVoiceName.KATE,
    TTSGender.FEMALE,
    false,
  );

  static readonly SOPHIE = new TTSVoice(
    TTSVoiceName.SOPHIE,
    TTSGender.FEMALE,
    false,
  );

  static readonly JAMESDNN = new TTSVoice(
    TTSVoiceName.JAMESDNN,
    TTSGender.MALE,
    true,
  );

  static readonly ERINDNN = new TTSVoice(
    TTSVoiceName.ERINDNN,
    TTSGender.FEMALE,
    false,
  );

  static readonly JULIEDNN = new TTSVoice(
    TTSVoiceName.JULIEDNN,
    TTSGender.FEMALE,
    false,
  );

  static readonly MARKDNN = new TTSVoice(
    TTSVoiceName.MARKDNN,
    TTSGender.MALE,
    false,
  );

  static readonly MOLLYDNN = new TTSVoice(
    TTSVoiceName.MOLLYDNN,
    TTSGender.FEMALE,
    false,
    'Child',
  );

  static readonly ROYDNN = new TTSVoice(
    TTSVoiceName.ROYDNN,
    TTSGender.MALE,
    false,
    'newscaster',
  );

  static readonly SAMUELDNN = new TTSVoice(
    TTSVoiceName.SAMUELDNN,
    TTSGender.MALE,
    false,
  );

  static readonly SOPHIEDNN = new TTSVoice(
    TTSVoiceName.SOPHIEDNN,
    TTSGender.FEMALE,
    false,
  );

  // private to disallow creating other instances of this type
  // access as TTSVoice.JAMES.name
  private constructor(
    public readonly name: TTSVoiceName,
    private readonly gender: TTSGender,
    private readonly defaultVoice: boolean,
    private readonly specialText?: string,
  ) {}

  static allVoices(): TTSVoice[] {
    return [
      TTSVoice.JAMES,
      TTSVoice.JAMESDNN,
      TTSVoice.ASHLEY,
      TTSVoice.BETH,
      TTSVoice.ERINDNN,
      TTSVoice.JULIE,
      TTSVoice.JULIEDNN,
      TTSVoice.KATE,
      TTSVoice.MARKDNN,
      TTSVoice.MOLLYDNN,
      TTSVoice.PAUL,
      TTSVoice.ROYDNN,
      TTSVoice.SAMUELDNN,
      TTSVoice.SOPHIE,
      TTSVoice.SOPHIEDNN,
    ];
  }

  get displayName(): string {
    return this.name.replace('-', ' ');
  }

  get display(): string {
    const specialText = this.specialText ? ` (${this.specialText})` : '';
    const defaultVoice = this.defaultVoice ? ' (default)' : '';
    const gender = ` (${this.gender})`;
    return this.displayName + specialText + gender + defaultVoice;
  }
}
