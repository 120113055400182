import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssignmentContext } from '@mhe/reader/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssignmentsService {
  private readonly submitEndpoint = '/assignments';
  private readonly assignmentLexileLevelEndpoint = '/assignments/lexile-level';
  private readonly defaultHeader = new HttpHeaders({ 'X-Skip-Interceptor': '' });

  constructor(private readonly http: HttpClient) {}

  submit(apiBaseUrl: string, assignmentContext: AssignmentContext): Observable<any> {
    const url = `${apiBaseUrl}${this.submitEndpoint}`;

    // clear undefined props
    const context = Object.entries(assignmentContext).reduce((acc, [k, v]) => {
      return v === undefined ? acc : { ...acc, [k]: v };
    }, {});

    const params = new HttpParams({ fromObject: context });
    const headers = this.defaultHeader.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(url, params, { headers, withCredentials: true });
  }

  handleAssignmentLexileLevel(apiBaseUrl: string, lexileLevel: string): Observable<any> {
    const url = `${apiBaseUrl}${this.assignmentLexileLevelEndpoint}`;
    const context = {
      lexile_level: lexileLevel,
    };
    const params = new HttpParams({ fromObject: context });
    const headers = this.defaultHeader.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(url, params, { headers, withCredentials: true });
  }
}
