import { Session } from '@mhe/reader/models';

export interface SessionState {
  readonly session: Partial<Session> | null
  readonly warningThreshold: number
}

export const initialSessionState: SessionState = {
  session: null,
  warningThreshold: 15 * 60 * 1000, // 15 mins
};
