import { Injectable } from '@angular/core';

/**
 * A service so that audio instances
 * can be mocked in specs. Akin to a
 * service wrapper for a 3rd party
 * library.
 */
@Injectable()
export class AudioElementService {
  /**
   * Returns an instance of HTMLAudioElement.
   */
  create(url: string): HTMLAudioElement {
    return new Audio(url);
  }
}
