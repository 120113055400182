import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReaderEnvironmentConfig } from '@mhe/reader/models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private readonly http: HttpClient) {}

  get config$(): Observable<ReaderEnvironmentConfig> {
    return this.http.get<ReaderEnvironmentConfig>(
      `assets/config.json?v=${environment.buildTimestamp}`,
    );
  }
}
