/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import {
  catchError,
  debounceTime,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserSettingsService } from '@mhe/reader/core/reader-api/user-settings.service';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import { MediatorUtils } from './mediator-utils';
import {
  defaultUserSettings,
  UserSettings,
  UserSettingsAPIResponse,
  UserSettingsStore,
} from '@mhe/reader/features/user-settings';
import * as userSettingsActions from '@mhe/reader/features/user-settings/state/user-settings.actions';
import { mapFontSizeValue } from '@mhe/reader/utils';
import { FontResizerStore } from '@mhe/reader/components/font-resizer';

@Injectable()
export class UserSettingsMediator extends ComponentEffects {
  constructor(
    private readonly configStore: ReaderConfigStore,
    private readonly userSettingsService: UserSettingsService,
    private readonly userSettingsStore: UserSettingsStore,
    private readonly readerStore: ReaderStore,
    private readonly fontResizeStore: FontResizerStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /**
   * The most recent value in the Reader API to deduplicate save events
   */
  private recentUserSettings: UserSettings = {};

  /** action effects */
  private readonly _userSettingsInit$ = this.effect(() => {
    return this.userSettingsStore.actions$.pipe(
      ofType(userSettingsActions.init),
      tap(() => {
        this.userSettingsStore.dispatch(userSettingsActions.getUserSettings());
      }),
      logCatchError('_UserSettingsInit$'),
    );
  });

  private readonly _getUserSettings$ = this.effect(() => {
    return this.userSettingsStore.actions$.pipe(
      ofType(userSettingsActions.getUserSettings),
      withLatestFrom(this.util.readerApi$, this.util.requestContext$),
      switchMap(([, api, contextdata]) =>
        this.userSettingsService
          .getUserSettings(api, contextdata.platform, contextdata.userID)
          .pipe(
            tap((userSettingsResponse) => {
              const userSettingsData = userSettingsResponse
                ? (JSON.parse(userSettingsResponse.data) as UserSettings)
                : defaultUserSettings;
              this.recentUserSettings = userSettingsData;
              this.userSettingsStore.setUserSettings(userSettingsData);
              this.fontResizeStore.setInitialSliderValue(
                mapFontSizeValue(userSettingsData.fontSize as number),
              );
              this.readerStore.setTeacherContentEnabled(
                userSettingsData.teacherContentEnabled as boolean,
              );
            }),
            catchError(() => {
              this.userSettingsStore.setUserSettings(defaultUserSettings);
              return EMPTY;
            }),
          ),
      ),
      logCatchError('_getUserSettings$'),
    );
  });

  private readonly _setUserSettings$ = this.effect(() => {
    return this.userSettingsStore.actions$.pipe(
      ofType(userSettingsActions.setUserSettings),
      withLatestFrom(
        this.userSettingsStore.userSettings$,
        this.util.readerApi$,
        this.util.requestContext$,
      ),
      debounceTime(500),
      tap(([actionData, state, api, contextdata]) => {
        const data: UserSettings = {
          ...state,
          ...actionData.userSettings,
        };

        // deduplicate save requests
        if (this.isEquivalent(this.recentUserSettings, data)) {
          return;
        }

        // Save in the API
        this.userSettingsService
          .setUserSettings(api, contextdata.platform, contextdata.userID, data)
          .subscribe((responseData: UserSettingsAPIResponse) => {
            const userSettingsData = responseData
              ? (JSON.parse(responseData.data) as UserSettings)
              : null;

            // store so we can deduplicate save requests
            if (userSettingsData) {
              this.recentUserSettings = userSettingsData;
            }
          });
      }),
      logCatchError('_setUserSettings$'),
    );
  });

  private readonly isEquivalent = function(
    obj1: object,
    obj2: object,
  ): boolean {
    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();

    let isEquivalent = true;
    keys1.forEach(function(key) {
      // loop through keys array
      if (obj2[key] === undefined) {
        isEquivalent = false;
      }
      if (obj1[key] !== obj2[key]) {
        isEquivalent = false;
      }
    });

    keys2.forEach(function(key) {
      if (obj1[key] === undefined) {
        isEquivalent = false;
      }
      if (obj1[key] !== obj2[key]) {
        isEquivalent = false;
      }
    });

    return isEquivalent;
  };
}
