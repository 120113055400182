import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { LargeImageNavigatorDialogData } from './large-image-navigator.model';

@Component({
  selector: 'mh-large-image-navigator',
  templateUrl: './large-image-navigator.component.html',
  styleUrls: ['./large-image-navigator.component.scss'],
})
export class LargeImageNavigatorComponent {
  ButtonPurpose = ButtonPurpose;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    const tooltipElements = document.getElementsByTagName('mhe-tooltip');
    if (!tooltipElements.length) {
      this.dialogRef.close();
    }
  }

  src: string = this.data.src;
  alt: string = this.data.alt ?? '';
  credit: string = this.data.credit ?? '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: LargeImageNavigatorDialogData,
    public readonly dialogRef: MatDialogRef<LargeImageNavigatorComponent>,
  ) {}
}
