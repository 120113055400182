<ng-container *ngIf="conceptNavEnabled$ | async">
  <div class="concept-navigator-container" role="navigation" aria-label="Concept">
    <!-- PREV Concept -->
    <button
      mhe-button
      class="btn-arrow-icon"
      [purpose]="ButtonPurpose.Icon"
      [mheTooltip]="'concept-navigator.button_previous' | translate"
      placement="right"
      [disabled]="prevButtonDisabled$ | async"
      (click)="onPrevBtnClicked()"
    >
      <i class="dpg-icon dpg-icon-up-arrow"></i>
    </button>

    <!-- SKIP to current concept -->
    <span class="current-concept-container">
      <button
        mhe-button
        [purpose]="ButtonPurpose.Icon"
        [mheTooltip]="'concept-navigator.button_current' | translate"
        placement="right"
        [disabled]="currentButtonDisabled$ | async"
        (click)="onCurBtnClicked()"
      >
        <i class="dpg-icon dpg-icon-system-library"></i>
      </button>
    </span>

    <!-- NEXT Concept -->
    <button
      mhe-button
      class="btn-arrow-icon"
      [purpose]="ButtonPurpose.Icon"
      [mheTooltip]="'concept-navigator.button_next' | translate"
      placement="right"
      [disabled]="nextButtonDisabled$ | async"
      (click)="onNextBtnClicked()"
    >
      <i class="dpg-icon dpg-icon-down-arrow"></i>
    </button>
  </div>
</ng-container>
