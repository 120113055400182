export function glossaryProcess(glossaryDocument: XMLDocument, url: string): any {
  const glossaryTerms = glossaryDocument.querySelectorAll('dt[*|type="glossterm"]');
  const glossary = Array.from(glossaryTerms).reduce((prev, termNode) => {
    let defNode = termNode.nextElementSibling as Element;
    processGlossaryMedia(defNode, url);
    const defNodes = [defNode];
    while (defNode.nextElementSibling && defNode.nextElementSibling.tagName === 'dd') {
      defNode = defNode.nextElementSibling;
      processGlossaryMedia(defNode, url);
      defNodes.push(defNode);
    }
    return {
      ...prev,
      [termNode.id]: {
        term: termNode.innerHTML,
        definitionNodes: defNodes,
      },
    };
  }, {});

  return glossary;
}

function processGlossaryMedia(defNode: Element, url: string): void {
  parseMediaURL('.glossary-images figure img', defNode, url);
  parseMediaURL('audio source', defNode, url);
  parseMediaURL('.glossary-images figure video source', defNode, url);
}

function parseMediaURL(selector, defNode, url): void {
  defNode.querySelectorAll(selector).forEach((element) => {
    const mediaLink = element.getAttribute('src');
    const mediaURL = new URL(mediaLink, url);
    element.setAttribute('src', mediaURL.href);
  });
}
