import { createAction } from '@ngrx/store';

export const printButtonClicked = createAction(
  '[RDRX Print] Print Button Clicked',
);

export const showCloseAlertModal = createAction(
  '[RDRX Print] Show Close Alert Modal',
);

export type PrintActions = ReturnType<
  typeof printButtonClicked
| typeof showCloseAlertModal
>;
