<button
  *ngIf="showPageTTSButton$ | async"
  #readAloudButton
  mhe-button
  [purpose]="ButtonPurpose.Icon"
  class="tts-controls-button"
  data-automation-id="read-aloud-button"
  [disabled]="controlDisabled$ | async"
  [mheTooltip]="'readspeaker.read_aloud' | translate"
  placement="left"
  (click)="onReadPageClick()"
>
  <i class="dpg-icon dpg-icon-audioon"></i>
</button>
<mat-progress-spinner
  *ngIf="showLoading$ | async"
  class="rdrx-spinner tts-loading-spinner"
  data-automation-id="read-aloud-spinner"
  [mode]="'indeterminate'"
  [diameter]="30"
  [attr.aria-label]="'readspeaker.buffering' | translate"
></mat-progress-spinner>
<rdrx-audio-controls
  *ngIf="showDocTTSControl$ | async"
  class="tts-rdrx-audio-controls"
  [audioContext]="TTSAudioContext.PAGE"
  (closeEvent)="onClose()"
>
</rdrx-audio-controls>
