export interface GoogleAnalyticsEvent {
  eventCategory: string // Typically the object that was interacted with (e.g. 'Video')
  eventAction: string // The type of interaction (e.g. 'play')
  eventLabel?: string // Useful for categorizing events (e.g. 'Fall Campaign')
  eventValue?: number // A numeric value associated with the event (e.g. 42)
}

export const isGoogleAnalyticsEvent = (e: any): e is GoogleAnalyticsEvent => {
  return 'eventCategory' in e && 'eventAction' in e;
};
