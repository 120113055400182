import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonPurpose } from '@mhe/ngx-shared';

import { ConfirmationModalData } from './confirmation-modal-data.model';

@Component({
  selector: 'rdrx-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  // expose import ref
  ButtonPurpose = ButtonPurpose;

  constructor(
    readonly dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: ConfirmationModalData,
  ) { }
}
