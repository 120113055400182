import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({ selector: '[rdrxTocActiveNode]' })
export class TocActiveNodeDirective implements OnChanges, OnInit {
  @Input('rdrxTocActiveNode') active = false;
  @Input() activeNodeClass = 'node-active';
  @Input() targetClass = 'node-wrapper';

  private readonly announceCurrentLocation = this.translate.instant(
    'contents.toc_current_location',
  );

  private nodeWrapper: HTMLElement;
  private focusListener: () => void;

  constructor(
    private readonly el: ElementRef,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translate: TranslateService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { active } = changes;

    if (active) {
      this.focusListener?.();
      this.setClass(active.currentValue);
    }
  }

  ngOnInit(): void {
    const { nativeElement } = this.el;
    const selector = `.${this.targetClass}`;

    this.nodeWrapper = nativeElement.closest(selector);
    this.setClass(this.active);
  }

  private setClass(isActive: boolean): void {
    const { nodeWrapper, activeNodeClass, announceCurrentLocation } = this;
    if (nodeWrapper) {
      this.renderer.removeClass(nodeWrapper, activeNodeClass);

      if (isActive) {
        this.renderer.addClass(nodeWrapper, activeNodeClass);

        this.focusListener = this.renderer.listen(nodeWrapper, 'focus', () => {
          void this.liveAnnouncer.announce(announceCurrentLocation);
        });
      }
    }
  }
}
