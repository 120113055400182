import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ReaderStore } from '../../reader/state';

@Component({
  selector: 'rdrx-quick-annotation',
  templateUrl: './quick-annotation.component.html',
  styleUrls: ['./quick-annotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAnnotationComponent {
  readerStore = inject(ReaderStore);

  readonly quickAnnotationEnabled$ = this.readerStore.quickAnnotationEnabled$;

  toggleQuickAnnotation(): void {
    this.readerStore.toggleQuickAnnotationEnabled();
  }
}
