import { createSelector } from '@ngrx/store';
import { WIDGETS_STATE_KEY, WidgetsDictionaryState } from './widgets.state';
import { getGlobalState } from '../global-store.selectors';
import { WidgetInstance } from '@mhe/reader/models';

export interface WidgetsStateByWidgetUuid {
  widgetUuid: string
}

const getWidgetsState = createSelector(
  getGlobalState,
  (state) => state[WIDGETS_STATE_KEY] || {},
);

export const getWidgetsStateByWidgetUuid = createSelector(
  getWidgetsState,
  (
    s: WidgetsDictionaryState,
    { widgetUuid }: WidgetsStateByWidgetUuid,
  ): WidgetInstance => s[widgetUuid],
);

export const getAllWidgetInstanceIDs = createSelector(
  getWidgetsState,
  (state): string[] => Object.keys(state || {}),
);

export const getAllWidgets = createSelector(
  getWidgetsState,
  (state): WidgetInstance[] => Object.values(state || {}),
);
