/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';
import {
  ReaderEnvironmentConfig,
  RuntimeConfiguration,
} from '@mhe/reader/models';
import { DevicePlatform } from '@mhe/reader/common';
import { environment } from 'src/environments/environment';

@Injectable()
export class MobileLaunchService {
  private partnerId = '';
  private branchKey = '';
  protected branch;
  constructor(protected window: Window) {
    this.branch = (this.window as any).branch;
  }

  public init(
    runtime: RuntimeConfiguration,
    environment: ReaderEnvironmentConfig,
  ): void {
    const browser = Bowser.parse(this.window.navigator.userAgent);
    const platform = browser.platform.type;

    if (!this.branch || platform === DevicePlatform.Desktop) {
      return;
    }
    if (runtime.readiator) {
      return;
    }

    const branchIoKey = this.selectBranchKey(runtime, environment);

    if (this.branch && branchIoKey) {
      this.branch.init(branchIoKey, null, (_, data: any) => {
        if (data) {
          this.launchBanner(runtime);
        }
      });
    }
  }

  private selectBranchKey(
    runtime: RuntimeConfiguration,
    environment: ReaderEnvironmentConfig,
  ): string | null {
    this.partnerId = runtime.customPartnerId;
    if (!this.partnerId) {
      // check if launch by Connect2 and content is not Content Item/CLO
      if (
        this.isConnect2(runtime.lisOutcomeServiceUrl as string) &&
        !this.isContentItem(runtime.contextLabel, runtime.contextId)
      ) {
        // connect2 will use same ReadAnywhere app like 'con' launches
        this.partnerId = 'con';
      } else {
        return null;
      }
    }

    if (this.partnerId === 'con' || this.partnerId === 'atd') {
      this.branchKey = environment.branchkeys[this.partnerId];
    }

    return this.branchKey;
  }

  // check if lti launched from Connect2 api
  private isConnect2(lisOutcomeServiceUrl: string): boolean | string {
    return lisOutcomeServiceUrl && lisOutcomeServiceUrl.includes('connect2');
  }

  // check if content is Content Item / CLO
  private isContentItem(
    contextLabel: string | false,
    contextId: string,
  ): boolean {
    if (contextLabel && contextLabel.toLowerCase() === 'clo') {
      return true;
    } else if (contextId && contextId.includes('-')) {
      return true;
    }

    return false;
  }

  public launchBanner(runtime: RuntimeConfiguration): void {
    const open = 'Open';
    const download = 'Download';
    const title = 'ReadAnywhere';
    const description = 'McGraw-Hill ';
    const iconImage = `/assets/images/readanywhere.png?v=${environment.buildTimestamp}`;

    this.branch.banner(
      {
        icon: iconImage,
        title,
        description,
        theme: 'light',
        openAppButtonText: open,
        downloadAppButtonText: download,
        sendLinkText: 'Send Link',
        iframe: true,
        disableHide: false,
        forgetHide: true,
        position: 'bottom',
        mobileSticky: false,
        showDesktop: false,
        customCSS:
          '#branch-banner .title { color: #316791; } #branch-banner-close { opacity: 0.9; color: #1b4a77;} #branch-banner .button { border: 1px solid #39729b; color: #39729b;}',
        make_new_link: true,
        open_app: false,
      },
      {
        data: {
          $og_title: 'MHE Reader',
          deeplink_text: 'Launched from banner',
          lti: runtime,
        },
      },
    );
  }
}
