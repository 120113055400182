import {
  AnnotationListState,
  initialAnnotationListState,
} from '../../utils/annotation-list.state.abstract';

export interface PlacemarkListState extends AnnotationListState {}

export const initialPlacemarkListState: PlacemarkListState = {
  ...initialAnnotationListState,
};
