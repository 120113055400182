import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';

import { mapFontSize } from '@mhe/reader/utils';
import {
  FontResizerState,
  initialFontResizerState,
} from './font-resizer.state';
import * as actions from './font-resizer.actions';

@Injectable()
export class FontResizerStore extends ExtendedComponentStore<
FontResizerState,
actions.FontResizerActions
> {
  constructor() {
    super(initialFontResizerState);
  }

  /** selectors */
  readonly value$ = this.select(({ fontsizeValue }) => fontsizeValue);
  readonly fontsize$ = this.select(this.value$, (val) => mapFontSize(val));
  readonly disabled$ = this.select(({ disabled }) => disabled);
  readonly initialSliderValue$ = this.select(
    ({ initialSliderValue }) => initialSliderValue,
  );

  /** updaters */
  readonly setValue = this.updater((state, fontsizeValue: number) => ({
    ...state,
    fontsizeValue,
  }));

  readonly setDisabled = this.updater((state, disabled: boolean) => ({
    ...state,
    disabled,
  }));

  readonly setInitialSliderValue = this.updater(
    (state, initialSliderValue: number) => ({
      ...state,
      initialSliderValue,
    }),
  );

  /** effects */
  readonly valueChange$ = this.effect((value$: Observable<number>) =>
    value$.pipe(
      tap((val) => this.setValue(val)),
      withLatestFrom(this.fontsize$),
      tap(([, fontsize]) => this.dispatch(actions.fontsizeChanged({ fontsize }))),
      catchError(() => EMPTY),
    ),
  );
}
