import { createSelector } from '@ngrx/store';

import { getGlobalState } from '../global-store.selectors';
import {
  GlossaryDictionaryState,
  GlossaryState,
  GLOSSARY_STATE_KEY,
} from './glossary.state';
import { Glossary, GlossaryItem } from '@mhe/reader/models';

// TODO: move with other types?
export type GlossaryLoadingState =
  | 'not requested'
  | 'requested'
  | 'loaded'
  | 'error';

export interface GlossaryStateByUrlProps {
  url: string
}
export interface GlossaryItemByIdProps extends GlossaryStateByUrlProps {
  id: string
}

const getState = createSelector(
  getGlobalState,
  (state) => state[GLOSSARY_STATE_KEY],
);

const getGlossaryStateByUrl = createSelector(
  getState,
  (
    s: GlossaryDictionaryState,
    { url }: GlossaryStateByUrlProps | GlossaryItemByIdProps,
  ): GlossaryState => s[url],
);

/** loading */
export const getGlossaryLoadingState = createSelector(
  getGlossaryStateByUrl,
  (state): GlossaryLoadingState | undefined => {
    if (state === undefined) {
      return 'not requested';
    }
    if (state.loading) {
      return 'requested';
    }
    if (state.error) {
      return 'error';
    }
    if (state.glossary) {
      return 'loaded';
    }
  },
);

export const isGlossaryLoading = createSelector(
  getGlossaryLoadingState,
  (loadingState) => loadingState === 'requested',
);

export const getGlossaryLoadingError = createSelector(
  getGlossaryStateByUrl,
  (state): Error | undefined => state?.error,
);

/** glossary */
export const getGlossary = createSelector(
  getGlossaryStateByUrl,
  (state): Glossary => state?.glossary,
);

export const getGlossaryItemById = createSelector(
  getGlossary,
  (glossary, { url, id }: GlossaryItemByIdProps): GlossaryItem | undefined =>
    glossary?.[
      Object.keys(glossary).filter(
        (key) => key.toLowerCase() === id.toLowerCase(),
      )[0]
    ],
);
