export const CONFIG_FEATURE_KEY = 'config';

export interface ConfigState {
  loaded: boolean
  epub?: Record<string, string> // [id]: url
}

export interface PartialConfigState {
  [CONFIG_FEATURE_KEY]: ConfigState
}

export const configInitialState: ConfigState = {
  loaded: false,
};
