import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { EPubLoaderService } from '@mhe/reader/features/epub-loader';
import * as actions from './footnote.actions';
import * as query from './footnote.selectors';

@Injectable()
export class FootnoteEffects {
  readonly fetchFootnote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchFootnote),
      map(({ url: { origin, pathname } }) => `${origin}${pathname}`),
      concatMap((url) =>
        of(url).pipe(
          withLatestFrom(this.store.pipe(select(query.getFootnote, { url }))),
        ),
      ),
      filter(([_, footnote]) => !footnote),
      map(([url]) => actions.loadFootnote({ url })),
    ),
  );

  readonly loadFootnote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadFootnote),
      mergeMap(({ url }) => {
        return this.epubLoaderService
          .getContent$(url, 'application/xhtml+xml')
          .pipe(
            map((footnoteDocument) =>
              actions.loadFootnoteSuccess({ url, footnoteDocument }),
            ),
            catchError((error) => of(actions.loadFootnoteError({ url, error }))),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly epubLoaderService: EPubLoaderService,
    private readonly store: Store,
  ) {}
}
