import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import * as actions from './glossary-modal.actions';

// This store is primarily for messaging from the transformers. Add state here with caution.
@Injectable()
export class GlossaryModalStore extends ExtendedComponentStore<any, actions.GlossaryModalActions> {
  constructor() {
    super(null);
  }

  /** effects */
}
