/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  OnInit,
} from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { BonsaiExpandedNodesMap, SEARCH_ROOT_BONSAI_NODE } from '@mhe/reader/models';

@Component({
  selector: 'reader-elastic-search',
  templateUrl: './elastic-search.component.html',
  styleUrls: ['./elastic-search.component.scss'],
})
export class ElasticSearchComponent implements OnChanges, OnInit {
  @Input() suggestions: Array<{ group: string, term: string }> = [];
  @Input() topics: Array<{ group: string, term: string }> = [];
  @Input() quizzes: Array<{ group: string, term: string }> = [];
  @Input() loading: boolean;
  @Input() nodes: Record<string, any>;
  @Input() totalResults: number = 0;

  @Output() suggestionClicked = new EventEmitter<{ group: string, term: string }>();
  @Output() searchQueryChanged = new EventEmitter<string>();
  @Output() resultClicked = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<void>();

  // get reference to the input element
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputContainer') searchInputContainer: ElementRef;
  showResults: boolean;
  readonly ButtonPurpose = ButtonPurpose;
  readonly rootNodeId = SEARCH_ROOT_BONSAI_NODE;

  get searchOptions(): any[] {
    return [
      ...(this.suggestions ? this.suggestions : []),
      ...(this.topics ? this.topics : []),
      ...(this.quizzes ? this.quizzes : []),
    ];
  }

  get hasSuggestions(): boolean {
    return this.suggestions && this.suggestions.length > 0;
  }
  expandedNodes: BonsaiExpandedNodesMap = {};
  activeDescendant = -1;
  get ariaOwns(): string {
    return this.searchOptions.map((_, i) => 'suggestion_' + i).join(' ');
  }
  get activeDescendantId(): string {
    return 'suggestion_' + this.activeDescendant;
  }

  constructor(private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    // listen for clicks outside the searchInputContainer or its children
    this.renderer.listen('document', 'click', (event) => {
      if (!this.searchInputContainer.nativeElement.contains(event.target)) {
        this.showResults = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if nodes change, reset the expanded nodes and ensure the first node is expanded
    if (changes.nodes) {
      this.expandedNodes = {};
      const expandedNodeIds = Object.keys(this.expandedNodes);
      if (this.nodes) {
        const rootNode = this.nodes[this.rootNodeId];
        if (rootNode) {
          const childIds = rootNode.childIds;
          if (childIds.length) {
            const id = childIds[0];
            this.expandedNodes = { [id]: id };
          }
        }
      }
    }
  }

  handleSuggestionsClick(suggestion: { group: string, term: string }): void {
    const el: HTMLInputElement = this.searchInput.nativeElement;
    el.value = suggestion.term;
    this.activeDescendant = -1;
    this.showResults = false;
    this.suggestionClicked.next(suggestion);
    if (suggestion.group === 'topics') {
      this.closeEvent.emit();
    }
  }

  handleInput(value: string): void {
    this.searchQueryChanged.next(value);
    // if input is empty (i.e. user cleared the input), hide the results
    this.clearSuggestions(value.length > 0);
    this.activeDescendant = -1;
  }

  handleResultClicked(node: any): void {
    this.resultClicked.next(node);
    this.closeEvent.emit();
  }

  toggleNodeExpanded(id): void {
    if (this.expandedNodes[id]) {
      delete this.expandedNodes[id];
    } else {
      // expand this one but collapse all others
      this.expandedNodes = { [id]: id };
    }
  }

  handleEscape(event: KeyboardEvent): void {
    if (this.showResults) {
      event.stopPropagation();
      this.showResults = false;
      this.activeDescendant = 0;
    }
  }

  handleEnter(event: KeyboardEvent): void {
    if (this.activeDescendant >= 0 && this.activeDescendant < this.searchOptions.length) {
      event.stopPropagation();
      this.handleSuggestionsClick(this.searchOptions[this.activeDescendant]);
    } else if (this.activeDescendant === -1) {
      event.stopPropagation();
      this.showResults = false;
      this.handleSuggestionsClick({ group: 'default', term: this.searchInput.nativeElement.value });
    }
  }

  handleArrowDown(): void {
    this.activeDescendant++;
    if (this.activeDescendant >= this.searchOptions.length) {
      this.activeDescendant = 0;
    }
  }

  handleArrowUp(): void {
    this.activeDescendant--;
    if (this.activeDescendant < 0) {
      this.activeDescendant = this.searchOptions.length - 1;
    }
  }

  getExpandedNodes(): BonsaiExpandedNodesMap {
    if (this.nodes) {
      const expandedNodeIds = Object.keys(this.expandedNodes);
      // remove any nodes that are not in the search results
      for (const id of expandedNodeIds) {
        if (!this.nodes[id]) {
          delete this.expandedNodes[id];
        }
      }
    }
    return this.expandedNodes;
  }

  handleFocus(value: string): void {
    this.clearSuggestions(value.length > 0);
  }

  private clearSuggestions(showResults: boolean): void {
    this.showResults = showResults;
    this.suggestions = [];
  }

  getBreadcrumbsDescription(breadcrumbs: string[], title: string): string {
    return [...breadcrumbs, title].reverse().join(', in ');
  }
}
