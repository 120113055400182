import { Component } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { TTSVoice } from '@mhe/reader/models';
import { TTSStore } from '../../state/tts.store';
import * as ttsActions from '../../state/tts.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'rdrx-tts-voice-picker',
  templateUrl: './tts-voice-picker.component.html',
  styleUrls: ['./tts-voice-picker.component.scss'],
})
export class TtsVoicePickerComponent {
  showVoiceOptions = false;
  selectedVoice$ = this.ttsStore.voiceName$;
  isLoading$ = this.ttsStore.isLoading$;
  docTTSControlReq$ = this.ttsStore.docTTSControlReq$;
  readonly ttsVoices = TTSVoice.allVoices();
  readonly ButtonPurpose = ButtonPurpose;

  constructor(private readonly ttsStore: TTSStore) {}

  onReadVoiceClick(): void {
    this.showVoiceOptions = !this.showVoiceOptions;
  }

  onReadVoicePickClick(voice: TTSVoice): void {
    this.ttsStore.isLoading$.pipe(take(1)).subscribe((isLoading) => {
      if (!isLoading) {
        this.showVoiceOptions = !this.showVoiceOptions;
        this.ttsStore.setVoice(voice);
        this.ttsStore.dispatch(ttsActions.resetVoice);
      }
    });
  }
}
