import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { LargeImageNavigatorComponent } from './large-image-navigator.component';
import { ReaderModalModule } from '@mhe/reader/components/modals/modal.module';
import { LargeImageNavigatorModule as LinModule } from '@mhe/ngx-large-image-navigator';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
    ReaderModalModule,
    LinModule,
  ],
  declarations: [LargeImageNavigatorComponent],
  exports: [LargeImageNavigatorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LargeImageNavigatorModule {}
