<rdrx-modal-header (closeEvent)="matDialogRef.close()">
  <h2 class="modal-header" id="reference-modal-h2">{{ title }}</h2>
  <button
    other-buttons
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    [enableLight]="true"
    placement="top"
    [mheTooltip]="'Jump to page'"
    (click)="handleClick(referenceAnchor)"
  >
    <a
      #referenceAnchor
      tabindex="-1"
      class="link"
      href="javascript:void(0);"
      [attr.data-href]="href"
      [attr.data-href-url]="hrefUrl"
    >
      <i class="ahe-icon dpg-icon-external-link-white"></i>
    </a>
  </button>
</rdrx-modal-header>

<iframe class="body" [src]="safeHrefUrl"> </iframe>
