import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class ReferenceModalService {
  constructor(private readonly domSanitizer: DomSanitizer) {
    this.domSanitizer = domSanitizer;
  }

  getSafeResourceUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
