import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { SpineItem } from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LastLocationService } from '@mhe/reader/core/reader-api/last-location.service';

import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import {
  defaultLastLocation,
  LastLocation,
  LastLocationStore,
} from '@mhe/reader/features/last-location';
import * as lastLocationActions from '@mhe/reader/features/last-location/last-location.actions';

@Injectable()
export class LastLocationMediator extends ComponentEffects {
  private readonly lastLocation$ = this.lastLocationStore.lastLocation$.pipe(
    filter((ll) => Boolean(ll)),
  );

  constructor(
    private readonly cfi: EpubLibCFIService,
    private readonly configStore: ReaderConfigStore,
    private readonly lastLocationService: LastLocationService,
    private readonly lastLocationStore: LastLocationStore,
    private readonly readerStore: ReaderStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /** util */
  private readonly withLatestFeatureEnabled = <T>(
    source$: Observable<T>,
  ): Observable<[T, boolean]> => {
    return source$.pipe(
      withLatestFrom(
        this.configStore.readiator$,
        this.configStore.lastLocation$,
        this.configStore.cfi$,
        this.configStore.assignment$,
        this.configStore.interfaceMode$,
      ),
      map(
        ([source, readiator, lastLocation, cfi, assignment, interfaceMode]): [
          T,
          boolean,
        ] => [
          source,
          (interfaceMode === 'dfa' &&
            lastLocation &&
            assignment !== 'review') ||
            (!readiator && lastLocation && !cfi && assignment !== 'review'),
        ],
      ),
    );
  };

  private readonly filterFeatureEnabled = <T>(
    source$: Observable<T>,
  ): Observable<T> => {
    return source$.pipe(
      this.withLatestFeatureEnabled,
      filter(([_, enabled]) => enabled),
      map(([source]) => source),
    );
  };

  /** action effects */
  private readonly _llInit$ = this.effect(() => {
    return this.lastLocationStore.actions$.pipe(
      ofType(lastLocationActions.init),
      this.withLatestFeatureEnabled,
      tap(([, enabled]) => {
        if (enabled) {
          this.lastLocationStore.dispatch(
            lastLocationActions.searchLastLocation(),
          );
        } else {
          this.lastLocationStore.setLastLocation(defaultLastLocation);
        }
      }),
      logCatchError('_llInit$'),
    );
  });

  private readonly _searchLastLocation$ = this.effect(() => {
    return this.lastLocationStore.actions$.pipe(
      ofType(lastLocationActions.searchLastLocation),
      this.filterFeatureEnabled,
      withLatestFrom(this.util.readerApi$, this.util.requestContext$),
      switchMap(([, api, contextdata]) =>
        this.lastLocationService
          .searchLastLocation(api, { ...contextdata })
          .pipe(
            map((locations) => locations?.[0]),
            tap((location) =>
              this.lastLocationStore.setLastLocation(
                location ?? defaultLastLocation,
              ),
            ),
            catchError(() => {
              this.lastLocationStore.setLastLocation(defaultLastLocation);
              return EMPTY;
            }),
          ),
      ),
      logCatchError('_searchLastLocation$'),
    );
  });

  // spine-item set
  private readonly _llOnSpineItem$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(readerActions.setSpineItem),
      map(({ spineItem }) => spineItem),
      filter((spineItem) => Boolean(spineItem)),
      tap((spineItem) => this.saveLastLocation$(spineItem)),
      logCatchError('_llOnSpineItem$'),
    );
  });

  private readonly _llOnDoubleSpineItem$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(readerActions.setDoubleSpineItem),
      map(({ spineItem }) => spineItem),
      map((doubleSpine) => doubleSpine.left || doubleSpine.right),
      filter((spineItem) => Boolean(spineItem)),
      tap((spineItem) => this.saveLastLocation$(spineItem)),
      logCatchError('_llOnDoubleSpineItem$'),
    );
  });

  /** effects */
  private readonly saveLastLocation$ = this.effect(
    (spineItem$: Observable<SpineItem>) => {
      return spineItem$.pipe(
        this.filterFeatureEnabled,
        this.util.filterAssignmentReview,
        map(({ index, id }) => this.cfi.generateBasePath(index, id)),
        map((cfi) => this.wrapCfi(cfi)),
        switchMap((cfi) =>
          this.lastLocation$.pipe(
            take(1),
            map((lastLocation): [string, LastLocation] => [
              cfi,
              lastLocation as LastLocation,
            ]),
          ),
        ),
        filter(([cfi, { location }]) => cfi !== location),
        withLatestFrom(this.util.requestContext$),
        map(([[cfi, lastLocation], contextdata]) => {
          const { createdAt, updatedAt, ...ll } = lastLocation;

          const location: LastLocation = {
            ...ll,
            ...contextdata,
            location: cfi,
          };
          return location;
        }),
        withLatestFrom(this.util.readerApi$),
        switchMap(([location, api]) =>
          this.lastLocationService.saveLastLocation(api, location).pipe(
            tap((ll) => this.lastLocationStore.setLastLocation(ll)),
            logCatchError('[LastLocation] save', false),
          ),
        ),
        logCatchError('saveLastLocation$'),
      );
    },
  );

  /** helpers */
  private wrapCfi(cfi: string): string {
    return `epubcfi(${cfi})`;
  }
}
