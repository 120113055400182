import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@mhe/ol-platform/auth';
import * as readerConfigQuery from '@mhe/reader/core/state/configuration/configuration.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SessionState } from '../state';
import * as readerSessionQuery from '../state/session/session.selectors';
import * as readerSessionActions from '../state/session/session.actions';
import { TokenResponse } from './reader-token.model';
import { Session } from '@mhe/reader/models';

@Injectable({ providedIn: 'root' })
export class ReaderTokenService implements TokenService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store<SessionState>,
  ) {}

  getToken(): Observable<string> {
    return this.store.pipe(
      select(readerSessionQuery.getToken),
    ) as Observable<string>;
  }

  setToken(token: Session): void {
    this.store.dispatch(readerSessionActions.SessionStarted(token));
  }

  refreshToken(): Observable<Session> {
    return this.store.pipe(
      select(readerConfigQuery.getReaderApi),
      first(),
      withLatestFrom(
        this.store.pipe(
          select(readerConfigQuery.runtime),
          map((runtime) => runtime.userId),
        ),
        this.getToken(),
      ),
      switchMap(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ([api, user_id, token]): Observable<TokenResponse> =>
          // Below we need to skip the token interceptor because
          // otherwise if this refresh request fails due to expired
          // session, it will trigger another refresh request (that
          // will fail) and loop indefinitely.
          this.httpClient.post<TokenResponse>(
            `${api}/v1/auth/global-session`,
            { user_id },
            {
              headers: new HttpHeaders({
                'X-Skip-Interceptor': '',
                Authorization: `Bearer ${token}`,
              }),
            },
          ),
      ),
      map((response): Session => {
        if (response?.token_type !== 'reader-jwt') {
          localStorage.setItem('MH_TOKEN', response.access_token);
        }
        return {
          accessToken: response.access_token,
          clientId: response.client_id,
          issuedAt: response.issued_at,
          sessionTimeout: response.session_timeout,
          expiresIn: response.expires_in,
          tokenType: response.token_type,
        };
      }),
    );
  }

  signout(): void {
    // It was discussed that for reader we don't
    // necessarily want to log the user out, just
    // transition the app into a "reduced functionality"
    // mode allowing the user to continue reading,
    // but prevent features that require backend
    // calls.
    console.warn('signout not implemented');
  }
}
