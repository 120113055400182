import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BonsaiTree, AnnotationListBonsaiNode } from '@mhe/reader/models';
import { AnnotationListStore } from '../../utils/annotation-list.store.abstract';
import { groupsToTree } from '../../utils/groups-to-tree';
import { NoteListActions } from './note-list.actions';
import { NoteListState, initialNoteListState } from './note-list.state';

@Injectable()
export class NoteListStore extends AnnotationListStore<
NoteListState,
NoteListActions
> {
  /** selectors **/
  readonly tree$: Observable<BonsaiTree<AnnotationListBonsaiNode>> =
    this.select(this.groups$, (groups) =>
      groupsToTree(
        groups,
        (annotation) => !!annotation.note,
        (annotation) => annotation.note as string,
      ),
    );

  constructor() {
    super(initialNoteListState);
  }
}
