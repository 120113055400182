import { HighlightColorClass, HighlightShapeClass } from './annotations';

export interface SeamlessMessage {
  data: SeamlessData
}

export enum ReadiatorMessageTypes {
  // DEBUG MODE
  DEBUG_ENABLED = 'READER.ENABLE_DEBUG',

  // LOADING BEACONS
  EPUB_LOADED = 'READER.EPUB.LOADED',
  PAGE_LOADED = 'READER.PAGE.LOADED',

  // ERROR
  ERROR = 'READER.ERROR',

  // TOGGLE VISIBLE UI
  TOC_TOGGLE = 'READER.TOC.TOGGLE',
  SEARCH_TOGGLE = 'READER.SEARCH.TOGGLE',
  OVERFLOW_TOGGLE = 'READER.OVERFLOW.TOGGLE',
  LEFT_DRAWER_CLOSE = 'READER.LEFTDRAWER.CLOSE',
  RIGHT_DRAWER_CLOSE = 'READER.RIGHTDRAWER.CLOSE',

  // NAVIGATION
  NAVIGATION_FORWARD = 'READER.NAVIGATION.FORWARD',
  NAVIGATION_BACK = 'READER.NAVIGATION.BACK',
  BACKBUTTON_TOGGLE = 'READER.BACKBUTTON.TOGGLE',

  // TOC
  TOC_GET = 'READER.TOC.GET',

  // LOCATION
  LOCATION_CURRENT = 'READER.LOCATION.CURRENT',
  SPINE_CURRENT = 'READER.SPINE.CURRENT',
  LOCATION_SET = 'READER.LOCATION.SET',

  // LINK HANDLING
  LINK_NOTIFY = 'READER.LINK.NOTIFY',
  LINK_BEHAVIOR = 'READER.LINK.BEHAVE',

  // FONT SIZE
  FONT_SIZE_CHANGED = 'READER.FONT_SIZE.CHANGED',
  FONT_SIZE_SET = 'READER.FONT_SIZE.SET',
  FONT_RESIZER_DISABLED = 'READER.FONT_RESIZER.DISABLED',

  // HIGHLIGHT
  HIGHLIGHT_CLEAR = 'READER.HIGHLIGHT.CLEAR',
  HIGHLIGHT_DESELECT = 'READER.HIGHLIGHT.DESELECT',
  HIGHLIGHT_SET = 'READER.HIGHLIGHT.SET',
  HIGHLIGHT_GET = 'READER.HIGHLIGHT.GET',
  HIGHLIGHT_SELECT = 'READER.HIGHLIGHT.SELECT', // @deprecated but kept for backwards compatibilty
  RANGE_SELECT = 'READER.RANGE.SELECT',
  HIGHLIGHT_KEYPRESS_DELETE = 'READER.HIGHLIGHT.KEYPRESS.DELETE',
  HIGHLIGHT_SCROLL_INTO_VIEW = 'READER.HIGHLIGHT.SCROLL_INTO_VIEW',

  // DEPRECATED - NO LONGER USED
  HIGHLIGHT_TRIGGER_DESELECT = 'READER.HIGHLIGHT.TRIGGER.DESELECT',
  HIGHLIGHT_TRIGGER_SELECT = 'READER.HIGHLIGHT.TRIGGER.SELECT',

  // AWD SPECIFIC
  CONCEPT_NAV_SET_STATE = 'READER.CONCEPT_NAV.SET_STATE',
  CONCEPT_NAV_GET_STATE = 'READER.CONCEPT_NAV.GET_STATE',
  CONCEPT_NAV_PREV = 'READER.CONCEPT_NAV.PREV',
  CONCEPT_NAV_NEXT = 'READER.CONCEPT_NAV.NEXT',
  CONCEPT_NAV_CURRENT = 'READER.CONCEPT_NAV.CURRENT',
  HIGHLIGHT_ICON_CLICK = 'READER.HIGHLIGHT_ICON.CLICK',
  HIGHLIGHT_ICON_SET_FOCUS = 'READER.HIGHLIGHT_ICON.SET_FOCUS',
  LR_ICON_CLICK = 'READER.LR_ICON.CLICK',
  LR_ICON_SET_FOCUS = 'READER.LR_ICON.SET_FOCUS',
  FORCE_REFRESH = 'READER.FORCE_REFRESH',

  // ASSIGNMENTS
  ASSIGNMENT_SUBMIT = 'READER.ASSIGNMENT.SUBMIT',

  // BENTO DIGITAL TAGGING
  TAGGABLE_SELECT = 'READER.TAGGABLE.SELECT',

  // CUSTOM BUTTON
  CUSTOM_BUTTON = 'READER.CUSTOM.BUTTON',

  // DFA
  ASSESSMENT_LAUNCH = 'READER.ASSESSMENT.LAUNCH',
  ALGORITHM_LAUNCH = 'READER.ALGORITHM.LAUNCH',
  ALGORITHM_LAUNCH_ANALYTICS = 'READER.ALGORITHM.LAUNCH.ANALYTICS',
  CASE_LAUNCH = 'READER.CASE.LAUNCH',
  CASE_LAUNCH_ANALYTICS = 'READER.CASE.LAUNCH.ANALYTICS',
  PATTERN_LAUNCH = 'READER.PATTERN.LAUNCH',
  PATTERN_LAUNCH_ANALYTICS = 'READER.PATTERN.LAUNCH.ANALYTICS',
  TOPIC_LAUNCH = 'READER.TOPIC.LAUNCH',
  TOPIC_LAUNCH_ANALYTICS = 'READER.TOPIC.LAUNCH.ANALYTICS',
  BOARDS_AND_BEYOND_DFA_VIDEO = 'READER.BB.BOARDS_AND_BEYOND_DFA_VIDEO',
}

export enum AwdBridgeAttributes {
  HIGHLIGHT_ID = 'data-highlight-id',
  LR_ICON_ID = 'data-lr-icon-id',
  HIGHLIGHT_ICON_ID = 'data-highlight-icon-id',
}

export interface SeamlessDataQueued {
  type: string
  payload: object | string
}

/**
 * From ReaderX --> Readiator
 */
export interface SeamlessData {
  type: string
  payload: object
  __id: string
}

/**
 * From Readiator --> ReaderX
 */
export interface ReadiatorAnnotationData {
  colorClass: string
  id?: string
  $id?: string

  // properties carried over from core AnnotationData model
  cfi: string
  text: string
  color?: HighlightColorClass
  shape?: HighlightShapeClass

  // readiator specific functionality
  commentColor?: string
  comments?: string
  hasLR?: boolean
  hasHighlightIcon?: boolean
  clickable?: boolean
  focusable?: boolean
  highlightBtnAria?: string
  lrBtnAria?: string

  // NOTE: some integrators (AWD) send over additional properties
}

export interface ReadiatorFontSizeData {
  fontsize: number
}

export interface ReadiatorLocationData {
  cfi: string
  setFocus: boolean
}

export interface ReadiatorPendingFocus {
  cfi?: string
  highlightIcon?: ReadiatorHighlightId
  lrIcon?: ReadiatorHighlightId
  el?: HTMLElement
}

export interface ReadiatorBentoDigitalTaggingData {
  id: string
  cfi: string
  children: string[]
  childrenInDetails?: ReadiatorChildDetails[]
  classList: string[]
  nodeName: string
}

export interface ReadiatorChildDetails {
  id: string
  classList: string[]
  nodeName: string
}

/**
 * From Readiator --> ReaderX
 */
export interface ReadiatorHighlightId {
  $id: string
}
