export interface ConceptNavigatorState {
  readonly conceptNavEnabled: boolean
  readonly prevButtonEnabled: boolean
  readonly currentButtonEnabled: boolean
  readonly nextButtonEnabled: boolean
}

export const initialConceptNavigatorState: ConceptNavigatorState = {
  conceptNavEnabled: false,
  prevButtonEnabled: true,
  currentButtonEnabled: true,
  nextButtonEnabled: true,
};
