import { Pipe, PipeTransform } from '@angular/core';
import { DropdownState } from './annotation.data';
import { BonsaiTree, AnnotationListBonsaiNode } from '@mhe/reader/models';

@Pipe({
  name: 'orphanAnnotationsFilter',
})
export class OrphanAnnotationsFilterPipe implements PipeTransform {
  transform(value: BonsaiTree<AnnotationListBonsaiNode>, filterApplied: DropdownState): any {
    if (filterApplied === DropdownState.ALL) {
      return value;
    }
    // root tree
    const newTree: BonsaiTree<AnnotationListBonsaiNode> = {
      _bonsaiTreeRoot: value._bonsaiTreeRoot,
    };

    // preserve root childIds
    value
      ._bonsaiTreeRoot
      .childIds
      ?.forEach(child => {
        const rootItem = value[child];
        newTree[child] = {
          ...rootItem,
          childIds: [],
        };
        rootItem.childIds?.forEach(childId => {
          if (value[childId]?.annotation?.orphan) {
            newTree[child]?.childIds?.push(childId);
            newTree[childId] = value[childId];
          }
        });
      });

    // remove orphan items
    Object.keys(newTree).forEach(key => {
      if (
        newTree[key]?.id?.startsWith('data-') &&
        newTree[key]?.childIds?.length === 0
      ) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newTree[key];
      }
    });
    return newTree;
  }
}
