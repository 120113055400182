import { Book, DoubleSpineItem, SpineItem } from '@mhe/reader/models';
import { flattenTocDictionary } from '@mhe/reader/utils';
import { EpubState, initialEpubState } from './epub.state';

/**
 * utils - copied from epub.service
 */
export function epubProcess(book: Book): EpubState {
  let state: EpubState = { ...initialEpubState, book };
  state = checkEpubSpineId(state);
  state = populateFlatToc(state);
  state = mapEpubMetadata(state);

  return state;
}

function checkEpubSpineId(state: EpubState): EpubState {
  const { book } = state;

  const spineItems = (book as Book).spine?.spineItems;

  let lackSpineId = false;
  for (const spine of spineItems) {
    if (spine && !spine.id && spine.linear === 'yes') {
      lackSpineId = true;
    }
  }

  state = { ...state, lackSpineId };
  return state;
}

function populateFlatToc(state: EpubState): EpubState {
  const { toc } = state.book as Book;
  const flatToc = flattenTocDictionary(toc);

  state = { ...state, flatToc };
  return state;
}

function mapEpubMetadata(state: EpubState): EpubState {
  const { book } = state;
  const { package: bookpackage, spine } = book as Book;
  const { metadata } = bookpackage;
  const spreadValues = ['both', 'landscape', 'auto'];

  const isFixedLayout = metadata.layout === 'pre-paginated';
  const isDoubleSpread =
    isFixedLayout &&
    Boolean(metadata.spread) &&
    spreadValues.includes(metadata.spread);

  const doubleSpines = isDoubleSpread ? mapDoubleSpines(spine.spineItems) : [];
  const isScaled = !isDoubleSpread;

  state = { ...state, isFixedLayout, isDoubleSpread, isScaled, doubleSpines };

  return state;
}

function mapDoubleSpines(items: SpineItem[]): DoubleSpineItem[] {
  let doubleSpines: DoubleSpineItem[] = [];

  for (let i = 0; i < items.length; i++) {
    const spine = items[i];

    let left!: SpineItem;
    let right!: SpineItem;

    if (isLeft(spine)) {
      left = { ...spine };
    } else if (isRight(spine)) {
      right = { ...spine };
    }

    const next = items[i + 1];

    if (!right && next && isRight(next)) {
      right = { ...next };
      i += 1;
    }

    if (left || right) {
      doubleSpines = [...doubleSpines, { left, right }];
    }
  }

  return doubleSpines || undefined;

  /** privates */
  function isLeft(spine: SpineItem): boolean {
    const isSpreadLeft = spine?.properties.includes('page-spread-left');
    const isOdd = spine?.index % 2 !== 0;

    return (isSpreadLeft || isOdd) ?? false;
  }
  function isRight(spine: SpineItem): boolean {
    const isSpreadRight = spine?.properties.includes('page-spread-right');
    const isEven = spine?.index % 2 === 0;

    return (isSpreadRight || isEven) ?? false;
  }
}
