import { v4 as uuid } from 'uuid';

import {
  TocItem,
  BonsaiTree,
  ROOT_BONSAI_NODE,
  TocBonsaiItem,
  TocBonsaiNode,
} from '@mhe/reader/models';
import { generateToc } from '@mhe/reader/utils';

export function tocItemsToBonsaiTree(
  items: TocItem[],
): BonsaiTree<TocBonsaiNode> {
  const toc = generateToc(items);
  const bonsaiToc = patchBonsaiId(toc);
  const rootIds = bonsaiToc.map(({ bonsaiId }) => bonsaiId);
  const flatItems = flattenBonsaiToc(bonsaiToc);
  const tree = mapTocToTree(flatItems, rootIds);

  return tree;
}

export function findTocNodes(
  treeNodes: TocBonsaiNode[],
  toc: TocItem,
): TocBonsaiNode[] {
  const { id, spinePos } = toc;
  const nodes = treeNodes.filter(({ tocItem }) => {
    const nTocId = tocItem?.id;
    const nTocSpinePos = tocItem?.spinePos;

    return nTocId === id || nTocSpinePos === spinePos;
  });

  return nodes;
}

/**
 * Takes the list from TOC and creates the tree structure required by ngx-bonsai
 * @param toc
 * @param rootIds
 * @returns
 */
function mapTocToTree(
  toc: TocBonsaiItem[],
  rootIds: string[],
): BonsaiTree<TocBonsaiNode> {
  let tree = {};

  for (let i = 0; i < toc.length; i++) {
    const { label, subItems, bonsaiId: id } = toc[i];
    const title = label;
    let childIds: any[] = [];

    if (subItems) {
      childIds = new Array(subItems.length);

      for (let j = 0; j < subItems.length; j++) {
        childIds[j] = subItems[j].bonsaiId;
      }
    }

    const collapsible = isCollapsible(id, rootIds);

    const node: TocBonsaiNode = {
      id,
      title,
      childIds,
      tocItem: toc[i],
      collapsible,
    };

    tree[id] = node;
  }

  tree = setBonsaiRootNode(tree, rootIds);
  return tree;
}

function setBonsaiRootNode(
  tree: BonsaiTree<TocBonsaiNode>,
  childIds: string[],
): BonsaiTree<TocBonsaiNode> {
  const rootnode = {
    [ROOT_BONSAI_NODE]: { id: ROOT_BONSAI_NODE, childIds },
  };

  tree = { ...rootnode, ...tree };
  return tree;
}

function patchBonsaiId(toc: TocItem[]): TocBonsaiItem[] {
  if (!toc) return [];

  const bonsaiToc = toc.map((item) => {
    const bonsaiId = uuid();
    const subItems = patchBonsaiId(item.subItems);

    const bonsaiItem: TocBonsaiItem = { ...item, bonsaiId, subItems };

    return bonsaiItem;
  });

  return bonsaiToc;
}

function flattenBonsaiToc(toc: TocBonsaiItem[]): TocBonsaiItem[] {
  toc = toc.reduce((acc, item) => {
    const sub = flattenBonsaiToc(item.subItems ?? []);
    return [...acc, item, ...sub];
  }, []);

  return toc;
}

function isCollapsible(id: string, rootIds: string[]): boolean {
  return rootIds.includes(id);
}
