/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { FontResizerStore } from './state/font-resizer.store';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FontResizerDialogComponent } from './font-resizer-dialog.component';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'reader-core-font-resizer',
  templateUrl: './font-resizer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontResizerComponent implements OnDestroy {
  @ViewChild('fontResizerButton', { read: ElementRef }) fontResizerButton: ElementRef;

  readonly controlDisabled$ = this.fontResizerStore.disabled$;

  // expose import ref
  ButtonPurpose = ButtonPurpose;

  private dialogRef!: MatDialogRef<FontResizerDialogComponent>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private readonly fontResizerStore: FontResizerStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly translate: TranslateService,
    private readonly window: Window,
  ) { }

  ngOnDestroy(): void {
    this.closedDialog();
  }

  closedDialog(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openFontResizerDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this.dialogRef = this.dialog.open(FontResizerDialogComponent, {
      autoFocus: false,
      ariaModal: true,
      ariaLabel: this.translate.instant('font-resizer.control_label'),
      backdropClass: 'font-resizer-dialog-backdrop',
      minWidth: 'unset',
      panelClass: ['font-resizer-dialog-component', 'mat-elevation-z8'],
      data: {
        // inject our instance of the store into the dialog
        store: this.fontResizerStore,
      },
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.positionDialogComponent();
      this.ga.event({ eventCategory: 'Font Resizer', eventAction: 'Show' });
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.closedDialog();
      this.ga.event({ eventCategory: 'Font Resizer', eventAction: 'Hide' });
    });

    // keep the dialog positioned near the activating button
    fromEvent(this.window, 'resize')
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => this.positionDialogComponent());
  }

  positionDialogComponent(): void {
    if (!this.fontResizerButton || !this.dialogRef) {
      return;
    }

    const rect = this.fontResizerButton.nativeElement.getBoundingClientRect();
    const dialogEl = this.dialogRef.componentRef?.location.nativeElement as HTMLElement;
    const pos = {
      top: `${rect.bottom + window.scrollY}px`,
      left: `${rect.left + window.scrollX - dialogEl.offsetWidth + rect.width}px`,
    };
    this.dialogRef.updatePosition(pos);
  }
}
