export function footnoteProcess(footnoteDocument: XMLDocument): any {
  const footnoteTerms = footnoteDocument.querySelectorAll('aside');
  const footnote = Array.from(footnoteTerms).reduce((prev, termNode) => {
    return {
      ...prev,
      [termNode.id]: {
        text: termNode.innerHTML,
      },
    };
  }, {});

  return footnote;
}
