export enum EpubMoveDirection {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}

export interface EpubMoveArrowStates {
  upEnabled?: boolean
  downEnabled?: boolean
  leftEnabled?: boolean
  rightEnabled?: boolean
}
