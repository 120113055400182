/**
 * The Widget Instance contains data and properties related to a single
 * iframe being tracked in the DOM available to the user.
 *
 * In the future we can consider keeping some of this information in memory
 * in order to speed up page navigations where a user goes back to a prior
 * visited page.
 */
export interface WidgetInstance {
  /**
   * The unique identifier linking the data and DOMelement
   */
  widgetUuid: string

  /**
   * The data that goes back and forth to the server
   */
  widgetData: WidgetData

  /**
   * The preRender function was called in widgets-manager.transformer.ts
   */
  preRendered: boolean

  /**
   * The postRender was called in widgets-manager.transformer.ts
   */
  postRendered: boolean

  /**
   * The browser DOM event occurred for iframe element (not contentDocument load inside iframe)
   */
  iframeDomLoaded: boolean

  /**
   * The data was restored on iframe load event
   */
  dataRestoredOnLoad: boolean

  /**
   * The action for API call to search happened
   *
   * null = no API request
   * false = API request in flight
   * true = API request complete
   */
  widgetSearchRequested: null | boolean

  /**
   * The action for API call to save happened, linked to saving status chip UI
   */
  widgetDataSaving: null | 'saving' | 'saved' | 'not-saved'

  /**
   * The action for whether a widget iframe needs to be reset/reloaded
   */
  widgetIframeReset: null | 'pending' | 'reset'
}

/**
 * The Widget Data is just the data emitted from a widget and peripheral
 * properties related to storage of the data by the Reader API.
 */
export interface WidgetData {
  /**
   * The database primary key value
   */
  resourceUUID?: undefined | string

  /**
   * The database secondary key values
   */
  platform: string
  contextID: string
  userID: string

  /**
   * The ID of iframe DOM element
   */
  instanceID: string

  /**
   * The user generated data
   */
  data: string | undefined

  /**
   * TODO add flag here for when data was reopened by Reader API
   * TODO add data here which may hint to the widget type
   */
  metadata?: string

  /**
   * The timestamps
   */
  clientModifiedAt?: string
  createdAt?: string
  updatedAt?: string
}

/**
 * This represents the minimum amount of data for a first interaction
 * of a single widget instance by a user which would result in being
 * saved in the Reader API.
 *
 * TODO change to a function which fills in the required properties
 */
export const defaultWidgetData: WidgetData = {
  platform: '',
  contextID: '',
  userID: '',

  instanceID: '',

  /**
   * null/false are valid values for widget user data
   * only undefined can represent "no prior widget user data"
   */
  data: undefined,
};

export const defaultWidgetInstance = (widgetUuid: string): WidgetInstance => {
  const defaultData = {
    widgetUuid,
    widgetData: { ...defaultWidgetData },

    preRendered: false,
    postRendered: false,
    iframeDomLoaded: false,
    dataRestoredOnLoad: false,
    widgetSearchRequested: null,
    widgetDataSaving: null,
    widgetIframeReset: null,
  };

  defaultData.widgetData.instanceID = widgetUuid;

  return defaultData;
};
