import { Store } from '@ngrx/store';
import { Book, LtiParams } from '@mhe/reader/models';
import * as readerConfigActions from '@mhe/reader/core/state/configuration/configuration.actions';
import { mapLtiToRuntimeConfig } from '@mhe/reader/lti';
import { PRESENTATION_MODE } from '@mhe/reader/types';

export const handleExplorationResource = (
  store: Store,
  { metadata: { mheMetadata } }: Book,
): void => {
  if (
    mheMetadata?.['mhe:presentation-mode']?.toLowerCase() ===
    PRESENTATION_MODE.ER
  ) {
    store.dispatch(
      readerConfigActions.SetPartialRuntimeFeatures(
        mapLtiToRuntimeConfig({
          custom_toc: 'false',
          custom_navigation: 'false',
          custom_location: 'false',
        } satisfies LtiParams),
      ),
    );
  }
};
