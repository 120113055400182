<div class="epub-viewer-container" [class.double-spread]="isDoubleSpread && !albumMode">
  <div class="spliter-container single-spread-container">
    <div class="spliter" role="main" aria-label="Reading content" id="main-iframe">
      <iframe #cloIframe allowfullscreen id="clo-iframe" title="Main content"></iframe>
    </div>
  </div>

  <div class="spliter-container double-spread-container">
    <div class="spliter left-container" role="main" aria-label="Reading content">
      <iframe
        #leftPageIframe
        allowfullscreen
        id="left-iframe"
        aria-label="Left page content"
        title="Left page content"
      ></iframe>
    </div>

    <div class="spliter right-container" role="main" aria-label="Reading content">
      <iframe
        #rightPageIframe
        allowfullscreen
        id="right-iframe"
        aria-label="Right page content"
        title="Right page content"
      ></iframe>
    </div>
  </div>
</div>
