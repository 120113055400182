import { Injectable, NgZone } from '@angular/core';

import {
  Book,
  isLexileLevel,
  LexileLevel,
  LexileLevels,
  LexileLevelsOption,
  SpineItem,
} from '@mhe/reader/models';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { Next, Transformer } from './transformer';

@Injectable()
export class LexileLevelTransformer implements Transformer {
  private readonly LEXILE_BASE_CSS = 'dpg--lexile--';

  constructor(
    private readonly transformStore: TransformStore,
    private readonly zone: NgZone,
  ) {}

  async preRender(
    _book: Book,
    _spineItem: SpineItem,
    content: HTMLDocument,
    _iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const pgLevels = this.getPageLevels(content);

    const setPageLevels = transformActions.setPageLexileLevels({ pgLevels });
    const applyLexileLevel = transformActions.applyLexileLevel({ content });

    this.zone.run(() => {
      this.transformStore.dispatch(setPageLevels);
      this.transformStore.dispatch(applyLexileLevel);
    });

    return await next(content);
  }

  async postRender(
    _book: Book,
    _spineItem: SpineItem,
    content: HTMLDocument,
    _iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const lexileLevelChange = transformActions.subscribeLexileLevelChange({
      content,
    });
    this.zone.run(() => this.transformStore.dispatch(lexileLevelChange));

    return await next(content);
  }

  private getPageLevels(doc: HTMLDocument): LexileLevelsOption[] {
    let pgLevels: LexileLevel[] = [];

    const { LEXILE_BASE_CSS } = this;
    const CONTAINER_CSS = `.${LEXILE_BASE_CSS}container`;

    const containers = doc.querySelectorAll(CONTAINER_CSS);

    containers.forEach((container) => {
      const classes = Object.values(container.classList);

      const regexLexile = new RegExp(LEXILE_BASE_CSS);
      const levels = classes
        .map((css) => css.replace(regexLexile, ''))
        .filter((css): css is LexileLevel => isLexileLevel(css));

      pgLevels = [...pgLevels, ...levels];
    });

    pgLevels = [...new Set(pgLevels)]; // distinct values
    pgLevels = this.sortLexileLevles(pgLevels);

    return pgLevels;
  }

  private sortLexileLevles(levels: LexileLevel[]): LexileLevelsOption[] {
    const sortedLevels = [...levels].sort(
      (a, b) => LexileLevels.indexOf(a) - LexileLevels.indexOf(b),
    );
    return sortedLevels;
  }
}
