/**
 * An offset (in pixels) so text is not covered by the TTS
 * control panel (with the speed control open).
 */
const PAGE_AUDIO_CONTROLS_HEIGHT_OFFSET = 180;

/**
 * An offset (in pixels)  so text is not covered by the bottom
 * scroll bar.
 */
const WINDOW_HORIZONTAL_SCROLLBAR_HEIGHT = 60;

/**
 * The number of pixels needed to scroll in order for an
 * element to be completely within the viewport.
 *
 * If the element is above the viewport, it will return
 * the distance from the top of the viewport to the top
 * of the element. This value will be negative.
 *
 * If the element is below the viewport, it will return
 * the distance from the bottom of the viewport to the
 * bottom of the element. This value will be positive.
 *
 * If the element is within the viewport, or extends
 * beyond the viewport in both directions, it will return
 * 0.
 *
 * Borrowed losely from https://stackoverflow.com/a/8897628.
 */
export function getScrollDistance(el: Element): number {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
  const rect = el.getBoundingClientRect();
  const topDistanceFromViewPortTop = rect.y;
  const bottomDistanceFromViewPortTop = rect.bottom;

  // if these distances are negative, the element is out of the viewport
  const distanceFromBottomOfScreen =
    window.innerHeight - bottomDistanceFromViewPortTop - WINDOW_HORIZONTAL_SCROLLBAR_HEIGHT;
  const distanceFromTopOfScreen = topDistanceFromViewPortTop - PAGE_AUDIO_CONTROLS_HEIGHT_OFFSET;

  if (distanceFromTopOfScreen < 0 && distanceFromBottomOfScreen < 0) {
    // The element extends out from the top and bottom of the viewport.
    return 0;
  } else if (distanceFromTopOfScreen < 0 && distanceFromBottomOfScreen > 0) {
    // The element's top above the viewport
    return distanceFromTopOfScreen;
  } else if (distanceFromBottomOfScreen < 0 && distanceFromTopOfScreen > 0) {
    // The element's bottom below the viewport
    return -distanceFromBottomOfScreen;
  } else {
    // Both distances have positive values and so the
    // elemement is contained visibly within the viewport.
    return 0;
  }
}

/**
 * Estimate of the number of milliseconds needed
 * to smooth scroll. Multiple scroll events could
 * emit, and there isn't any way to tell whether
 * the scroll events were user or programatically
 * generated. The best we can do is estimate the
 * time and expect the scroll to done at the end.
 *
 * Here we estimate the amount of time needed based
 * on a linear estimate. It was originally based on
 * the observation that 100,000px takes ~2000ms to
 * smooth scroll. The epub sn_54 index page was used
 * to obtain this observation.
 */
export function getTimeNeededToSmoothScroll(el: Element): number {
  return 500 + (1 / 50) * Math.abs(getScrollDistance(el));
}
