import { BonsaiNode } from '@mhe/ngx-bonsai-reader';

import { ReadiatorMessageTypes } from '../readiator.model';

export enum HeaderId {
  ALGORITHMS = 'ALGORITHMS',
  CASES = 'CASES',
  PATTERNS = 'PATTERNS',
  TOPICS = 'TOPICS',
  BB_VIDEO = 'BB_VIDEO',
}

export interface Tag {
  assessments?: Assessment[]
  childIds?: string[]
  id?: string
  collapsible?: boolean
  loading?: boolean
  locations?: TagLocation[]
  name: string
  parent: string
  title?: string
  type: TagType
  uuid: string
}

export interface Assessment {
  instanceId: string
  progress: Progress
}

export interface Progress {
  lastVisited: string
  incorrect: number
  correct: number
  total: number
}

export interface TagLocation {
  anchorUuid: string
  cfi: string
  epub: string
  exhibitId: string
  spineId: string
  tagUuid: string
  title: string
}

export enum TagType {
  ALGORITHM = 'ALGORITHM',
  CASE = 'CASE',
  PATTERN = 'PATTERN',
  SUBJECT = 'TOPIC_CATEGORY',
  TOPIC = 'TOPIC',
  HEADER = 'HEADER',
  BB_VIDEO_H = 'BB_VIDEO_HEADER',
  BB_VIDEO = 'BB_VIDEO',
}

export interface TagNode extends BonsaiNode {
  assessments?: Assessment[]
  locations?: TagLocation[]
  name: string
  parent?: string
  type?: TagType
  uuid: string
  collapsible?: boolean
  loading?: boolean
  videos?: any[]
  cadmoreId?: string
}

export interface Payload {
  event: ReadiatorMessageTypes
  anchorUuid?: string
  assessmentInstanceId?: string
  cfi?: string
  epub?: string
  exhibitId?: string
  spineId?: string
  tagUuid?: string
  cadmoreId?: string
  videoId?: string
  title?: string
  topic?: string
}

export interface DFASearchParams {
  text: string
  spineID: string
  index: number
}
