import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { NgxBonsaiModule } from '@mhe/ngx-bonsai-reader';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TranslateModule } from '@ngx-translate/core';

import { ScrubberComponent } from './scrubber/scrubber.component';
import { TocActiveNodeDirective } from './toc-active-node.directive';
import { TocComponent } from './toc.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    A11yModule,
    MatSliderModule,
    TranslateModule,
    TooltipModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
  ],
  declarations: [
    TocComponent,
    ScrubberComponent,
    TocActiveNodeDirective,
  ],
  exports: [TocComponent, ScrubberComponent],
})
export class TocModule {}
