import { ApiAnnotation, ApiSpine } from '@mhe/reader/models';

export const ANNOTATIONS_STATE_KEY = 'annotations';

export interface AnnotationsState {
  // deprecated
  loaded: boolean
  entitiesBySpineId: Record<string, Record<string, ApiAnnotation>>
  spineIds: string[]
  spines: ApiSpine[]
  isExporting: boolean
}

export const initialState: AnnotationsState = {
  loaded: false,
  entitiesBySpineId: {},
  spineIds: [],
  spines: [],
  isExporting: false,
};
