import { Injectable } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class PreventCopyTransformer implements Transformer {
  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    /**
     * Prevent users from copying content to their clipboards
     * https://jira.mheducation.com/browse/EPR-8543
     */
    content.body.setAttribute('oncopy', 'return false');
    content.body.setAttribute('oncut', 'return false');
    return await next(content);
  }
}
