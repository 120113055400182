export function getIntegratorURL(
  windowRef: Window,
  documentRef: Document,
): string | null {
  let readerIntegratorUrl = '';
  const ancestors = windowRef?.location?.ancestorOrigins;
  if (ancestors?.length > 0) {
    readerIntegratorUrl = ancestors.item(ancestors.length - 1) as string;
  } else if (documentRef?.referrer) {
    readerIntegratorUrl = documentRef?.referrer;
  } else {
    readerIntegratorUrl += 'Unavailable';
  }

  return readerIntegratorUrl;
}
