import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ReaderOverlayContainerService } from './reader-overlay-container.service';

export const CUSTOM_DIALOG_PROVIDERS = [
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      panelClass: 'reader-dialog',
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: true,
      minWidth: '350px',
      maxWidth: '800px',
      role: 'dialog',
    },
  },
  { provide: OverlayContainer, useClass: ReaderOverlayContainerService },
  MatDialog,
];
