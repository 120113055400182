import { Provider } from '@angular/core';

import { FixedPageService } from './fixed-page.service';
import {
  EpubViewerFontSizeStore,
  EpubViewerRenderService,
  EpubViewerSearchHighlightStore,
  EpubViewerSearchHighlightUtils,
  EpubViewerStore,
} from './state';

export const EPUB_VIEWER_PROVIDERS: Provider[] = [
  EpubViewerFontSizeStore,
  EpubViewerRenderService,
  EpubViewerSearchHighlightStore,
  EpubViewerSearchHighlightUtils,
  EpubViewerStore,
  FixedPageService,
];
