/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable, combineLatest, merge } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  SpineItem,
  Tag,
  TagLocation,
  TagNode,
  TocItem,
} from '@mhe/reader/models';

import { MediatorUtils } from './mediator-utils';
import { DFAService } from '@mhe/reader/core/dfa-api/dfa.service';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import * as topicsActions from '@mhe/reader/components/topics/state/topics.actions';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { TocStore } from '@mhe/reader/components/toc';
import * as tocActions from '@mhe/reader/components/toc';
import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import { ElasticSearchService } from './elastic-search.service';

@Injectable()
export class TopicsMediator extends ComponentEffects {
  private readonly topicsActions$ = this.topicsStore.actions$;
  private readonly navigationActions$ = this.navigationStore.actions$;

  constructor(
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly dfaService: DFAService,
    private readonly topicsStore: TopicsStore,
    private readonly readerStore: ReaderStore,
    private readonly navigationStore: NavigationStore,
    private readonly mediatorUtils: MediatorUtils,
    private readonly epubLibCfiService: EpubLibCFIService,
    private readonly tocStore: TocStore,
    private readonly elasticSearchService: ElasticSearchService,
  ) {
    super();
  }

  readonly setSubjects = combineLatest([
    this.readerConfigStore.topicsEndpoint$,
    this.readerConfigStore.idmToken$,
  ]).pipe(
    filter(([_, token]) => Boolean(token)),
    switchMap(([topicsEndpoint]) => {
      return this.dfaService.requestSubjectsOrTags(topicsEndpoint).pipe(
        take(1),
        tap((tags) => {
          this.topicsStore.setSubjects(tags);
        }),
      );
    }),
    withLatestFrom(
      this.readerConfigStore.dfaCategoryUUID$,
      this.readerConfigStore.dfaTopicUUID$,
      this.readerConfigStore.subjectTopicsEndpoint$,
    ),
    switchMap(([_, dfaCategoryUUID, dfaTopicUUID, subjectTopicsEndpoint]) => {
      const shouldRedirect =
        dfaCategoryUUID !== undefined && dfaTopicUUID !== undefined;
      if (shouldRedirect) {
        return this.getDfaLocations(
          subjectTopicsEndpoint,
          dfaCategoryUUID,
          shouldRedirect,
        ).pipe(take(1));
      }
      return EMPTY;
    }),
    logCatchError('setSubjects', false, this.topicsStore.setErrorLoadingSubjects),
  );

  readonly init$ = this.effect(() => {
    return merge(this.topicsActions$).pipe(
      ofType(topicsActions.init),
      withLatestFrom(
        this.readerConfigStore.dfaCategoryUUID$,
        this.readerConfigStore.dfaTopicUUID$,
        this.topicsStore.initialSubjectId$,
      ),
      filter(
        ([, categoryId, subjectId, initialSubjectId]) =>
          !!categoryId && !!subjectId && initialSubjectId === undefined,
      ),
      take(1),
      tap(([, categoryId, subjectId]) => {
        this.topicsStore.updateExpandedSubjects({ id: categoryId as string });
        this.topicsStore.setInitialSubjectId(subjectId);
      }),
      logCatchError('init$'),
    );
  });

  readonly navigateToTopic$ = this.effect(() => {
    return merge(this.topicsActions$).pipe(
      ofType(topicsActions.navigateToTopic),
      withLatestFrom(
        this.topicsStore.topicsTree$,
        this.readerConfigStore.dfaTopicUUID$,
        this.readerConfigStore.cfi$,
      ),
      filter(([, topicsTree]) => Object.values(topicsTree).length > 0),
      tap(([, topicsTree, topicId, cfi]) => {
        const tagNode = topicsTree[topicId as string] as TagNode;
        if (tagNode) {
          this.topicsStore.setCurrentTopicNode({
            node: tagNode,
          });
          const location = tagNode.locations?.find(
            (location) => location.cfi === cfi,
          ) as TagLocation;
          this.topicsStore.dispatch(topicsActions.setTopicInToc({ location }));
        } else {
          console.warn(
            ` - unable to navigate user since there's no tag featuring topicId such as '${topicId}' into topics tree`,
          );
        }
      }),
      logCatchError('navigateToTopic$'),
    );
  });

  getDfaLocations = (
    subjectTopicsEndpoint,
    subjectId,
    shouldRedirect?,
  ): Observable<any> => {
    return this.dfaService.requestSubjectsOrTags(subjectTopicsEndpoint, subjectId).pipe(
      take(1),
      tap((tags) => {
        this.sortDfaTags(tags);
        this.topicsStore.setTopics({ subjectId, tags });
        if (shouldRedirect) { this.topicsStore.dispatch(topicsActions.navigateToTopic()); }
      }),
      catchError((err) => {
        this.topicsStore.setSubjectLoadingState({ subjectId, loading: false });
        return err;
      }),
    );
  };

  readonly getLocations$ = this.effect(() => {
    return merge(this.topicsActions$).pipe(
      ofType(topicsActions.getLocations),
      map((subject) => subject.uuid),
      withLatestFrom(
        this.readerConfigStore.subjectTopicsEndpoint$,
      ),
      switchMap(([subjectId, subjectTopicsEndpoint]) => {
        return this.getDfaLocations(subjectTopicsEndpoint, subjectId);
      }),
      map((t) => t),
      logCatchError('getLocations$'),
    );
  });

  readonly selectInternalLocation$ = this.effect(() =>
    this.topicsActions$.pipe(
      ofType(topicsActions.selectInternalLocation),
      map((action) => action.location),
      tap((location) => {
        this.topicsStore.dispatch(
          topicsActions.setSelectedLocation({ location }),
        );
        this.topicsStore.dispatch(topicsActions.setTopicInToc({ location }));
      }),
      tap(() => this.mediatorUtils.leftDrawer(false)),
      logCatchError('selectInternalLocation$'),
    ),
  );

  readonly setCurrentSpineItem$ = this.effect(() =>
    this.navigationActions$.pipe(
      ofType(navigationActions.navigateTo),
      withLatestFrom(this.readerStore.spineItem$),
      filter(([, spineItem]) => spineItem !== undefined),
      tap(([, spineItem]) => {
        this.topicsStore.setCurrentSpineId((spineItem as SpineItem).id);
        this.topicsStore.setSelectedLocation(null);
      }),
      logCatchError('setCurrentSpineItem$'),
    ),
  );

  readonly setSelectedLocation$ = this.effect(() => {
    return this.topicsActions$.pipe(
      ofType(topicsActions.setSelectedLocation),
      map((action) => action.location),
      withLatestFrom(this.readerStore.spineItem$),
      tap(([location, spineItem]) => {
        this.topicsStore.setCurrentSpineId((spineItem as SpineItem).id);
        this.topicsStore.setSelectedLocation(location);
        this.topicsStore.dispatch(topicsActions.setTopicInToc({ location }));
      }),
      logCatchError('setSelectedLocation$'),
    );
  });

  readonly setTocItem = this.effect(() => {
    return this.topicsActions$.pipe(
      ofType(topicsActions.setTopicInToc),
      map((action) => action.location),
      withLatestFrom(this.readerStore.flatToc$),
      tap(([location, flatToc]) => {
        //  Update TOC with topic location
        const nodeSpineId = this.epubLibCfiService.getSpineId(location?.cfi);
        const tocElement = Object.values(flatToc).find(
          (flatTocItem: { id }) => flatTocItem?.id === nodeSpineId,
        );
        if (!tocElement) {
          console.warn(
            'There is no ToC element for the current CFI:',
            location?.cfi,
          );
          return;
        }
        this.tocStore.dispatch(
          tocActions.setActiveTocItem({ tocItem: tocElement as TocItem }),
        );
      }),
    );
  });

  readonly navigateByDfaSearchLaunch = this.effect(() => {
    return this.topicsActions$.pipe(
      ofType(topicsActions.dfaSearchLaunch),
      map((action) => action.params),
      withLatestFrom(this.readerStore.flatToc$),
      tap(([params, toc]) => {
        const item = Object.values(toc).find(
          (item: TocItem) => item.id === params.spineID,
        ) as TocItem;
        if (item) {
          const node = {
            spineIndex: item.spinePos,
            index: params.index,
            innerHTML: decodeURIComponent(params.text) || '',
          };
          this.elasticSearchService.handleSearchResultClicked(node);
        } else {
          this.mediatorUtils.navigate(0);
        }
      }),
      logCatchError('navigateByDfaSearchLaunch', false),
    );
  });

  // Sort DFA data returned from API
  sortDfaTags(tags: Tag[]): void {
    tags.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
