import {
  AfterViewChecked,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[rdrxBonsaiTabIndex]' })
export class BonsaiTabIndexDirective
implements OnInit, OnDestroy, AfterViewChecked {
  private readonly _overrideTabIndex = new Subject<NodeListOf<Element>>();
  private readonly overrideTabIndex$ = this._overrideTabIndex.asObservable();

  private readonly destroy$ = new Subject<void>();

  private unlisteners: Array<() => void> = [];

  constructor(
    private readonly el: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.overrideTabIndex$
      .pipe(
        debounceTime(150),
        distinctUntilChanged(this.nodeListsAreEqual),
        takeUntil(this.destroy$),
      )
      .subscribe((nodes) => this.overrideTabIndex(nodes));
  }

  ngAfterViewChecked(): void {
    const nodes = this.el.nativeElement.querySelectorAll('.node-wrapper');
    this._overrideTabIndex.next(nodes);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.resetUnlisteners();
  }

  private overrideTabIndex(nodes: NodeListOf<Element>): void {
    this.resetUnlisteners();

    nodes?.forEach((node) => {
      this.renderer.setAttribute(node, 'tabindex', '0');

      const unlistener = this.renderer.listen(node, 'blur', () => {
        this.renderer.setAttribute(node, 'tabindex', '0');
      });

      this.unlisteners = [...this.unlisteners, unlistener];
    });
  }

  private resetUnlisteners(): void {
    this.unlisteners.forEach((unlisten) => unlisten());
    this.unlisteners = [];
  }

  private nodeListsAreEqual(
    list1: NodeListOf<Element>,
    list2: NodeListOf<Element>,
  ): boolean {
    if (list1.length !== list2.length) {
      return false;
    }
    return Array.from(list1).every((node, index) => node === list2[index]);
  }
}
