/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  Book,
  DoubleSpineItem,
  ReaderAlert,
  SpineItem,
  FlatTocItem,
  TocItem,
  ApiSpine,
} from '@mhe/reader/models';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  getSdlc,
  getPlayerApi,
  getReaderApi,
} from '@mhe/reader/core/state/configuration/configuration.selectors';
import { getSpines } from '../global-store/annotations/annotations.selectors';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import {
  GoogleAnalyticsEvent,
  GoogleAnalyticsService,
  GoogleAnalyticsTimingEvent,
  isGoogleAnalyticsEvent,
  isGoogleAnalyticsTimingEvent,
} from '@mhe/reader/features/analytics';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';

@Injectable()
export class MediatorUtils {
  readonly sdlc$ = this.store.pipe(select(getSdlc));
  readonly playerApi$ = this.store.pipe(select(getPlayerApi));
  readonly readerApi$ = this.store.pipe(select(getReaderApi));

  readonly requestContext$ = combineLatest([
    this.configStore.userId$,
    this.configStore.contextId$,
    this.configStore.platform$,
    this.configStore.epubReleaseUUID$,
    this.configStore.epubVersion$,
  ]).pipe(
    map(([userID, contextID, platform,
      epubReleaseUUID, epubVersion]) =>
      ({ userID, contextID, platform, epubReleaseUUID, epubVersion })),
  );

  readonly sessionDisabledConfig$: Observable<boolean>;

  readonly iframes$ = combineLatest([
    this.epubViewerStore.cloIframe$,
    this.epubViewerStore.leftIframe$,
    this.epubViewerStore.rightIframe$,
  ]);

  constructor(
    private readonly configStore: ReaderConfigStore,
    private readonly epubCfiLib: EpubLibCFIService,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly navigationStore: NavigationStore,
    private readonly readerStore: ReaderStore,
    private readonly store: Store,
  ) {
    this.sessionDisabledConfig$ = this.configStore.sessionDisabled$;
  }

  /** operators */
  // double spread
  withLatestDoubleSpread = <T>(
    source$: Observable<T>,
  ): Observable<[T, boolean]> => {
    return source$.pipe(
      withLatestFrom(
        this.readerStore.isDoubleSpread$,
        this.epubViewerStore.albumMode$,
      ),
      map(([source, isDoubleSpread, albumMode]) => {
        const ids = isDoubleSpread && !albumMode;
        return [source, ids] as [T, boolean];
      }),
    );
  };

  tapDoubleSpread =
    <T>(single?: (callback?: T) => void, double?: (callback?: T) => void) =>
      (source$: Observable<T>) => {
        return source$.pipe(
          this.withLatestDoubleSpread,
          tap(([source, ids]) => (ids ? double?.(source) : single?.(source))),
        );
      };

  filterAssignmentReview = <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      withLatestFrom(this.configStore.assignment$),
      filter(([_, assignment]) => assignment !== 'review'),
      map(([source, assignment]) => source),
    );
  };

  filterLinks = <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      withLatestFrom(this.configStore.links$),
      filter(([, links]) => !!links),
      map(([source]) => source),
    );
  };

  // cfi
  filterValidCfi = (
    source$: Observable<{ cfi: string, setFocus: boolean }>,
  ): Observable<{ cfi: string, setFocus: boolean }> => {
    return source$.pipe(filter((action) => this.isValidCfi(action.cfi)));
  };

  coerceInvalidCfi = (
    source$: Observable<string>,
  ): Observable<string | undefined> => {
    return source$.pipe(
      map((cfi) => {
        if (cfi === undefined) return cfi;

        const valid = this.isValidCfi(cfi);
        return valid ? cfi : undefined;
      }),
    );
  };

  // error handling
  catchErrorAlert =
    (
      alert: ReaderAlert,
      options: { passthrough?: boolean, callback?: () => void } = {
        passthrough: false,
      },
    ) =>
      <T>(source$: Observable<T>) => {
        const { passthrough, callback } = options;

        return source$.pipe(
          catchError((err) => {
            callback?.();

            this.readerStore.addAlert(alert);
            return passthrough ? throwError(err) : EMPTY;
          }),
        );
      };

  // google analytics
  tapGaEvent = <T>(event?: GoogleAnalyticsEvent) => {
    return (source$: Observable<T>) => {
      return source$.pipe(
        tap((src) => {
          let ga_event = event as GoogleAnalyticsEvent;
          if (!ga_event && isGoogleAnalyticsEvent(src)) {
            ga_event = src;
          }

          this.ga.event(ga_event);
        }),
      );
    };
  };

  tapGaEventWithTitle = <T>(event?: GoogleAnalyticsEvent) => {
    return (source$: Observable<T>) => {
      return source$.pipe(
        withLatestFrom(this.readerStore.title$),
        mergeMap(([src, eventLabel]) => {
          let ga_event = event;
          if (!ga_event && isGoogleAnalyticsEvent(src)) {
            ga_event = src;
          }

          return of({ ...ga_event, eventLabel }).pipe(
            this.tapGaEvent(),
            mapTo(src),
          );
        }),
      );
    };
  };

  tapGaTiming = <T>(
    event?:
    | GoogleAnalyticsTimingEvent
    | Omit<GoogleAnalyticsTimingEvent, 'timingValue'>,
  ) => {
    return (source$: Observable<T>) => {
      return source$.pipe(
        tap((src) => {
          let ga_event = event as GoogleAnalyticsTimingEvent;
          if (!ga_event && isGoogleAnalyticsTimingEvent(src)) {
            ga_event = src;
          }

          this.ga.timing(ga_event);
        }),
      );
    };
  };

  tapGaTimingWithTitle = <T>(
    event?:
    | GoogleAnalyticsTimingEvent
    | Omit<GoogleAnalyticsTimingEvent, 'timingValue'>,
  ) => {
    return (source$: Observable<T>) => {
      return source$.pipe(
        withLatestFrom(this.readerStore.title$),
        mergeMap(([src, timingLabel]) => {
          let ga_event = event;
          if (!ga_event && isGoogleAnalyticsTimingEvent(src)) {
            ga_event = src;
          }

          return of({ ...ga_event, timingLabel }).pipe(
            this.tapGaTiming(),
            mapTo(src),
          );
        }),
      );
    };
  };

  /** annotation lists */
  getSpinesByLabel = (source$: Observable<string>): Observable<ApiSpine[]> => {
    return source$.pipe(
      withLatestFrom(this.store.select(getSpines)),
      map(([spineID, spines]) => {
        const { groupLabel } = spines.find(
          (spine) => spine.spineID === spineID,
        ) as ApiSpine;
        return spines.filter((spine) => spine.groupLabel === groupLabel);
      }),
    );
  };

  /** action utils */
  navigate(index: number, hash?: string, setFocus?: boolean): void {
    if (hash && !hash?.startsWith('#')) {
      hash = `#${hash}`;
    }
    const nav = navigationActions.navigateTo({ index, hash, setFocus });
    this.navigationStore.dispatch(nav);
  }

  navigateToSpineIndex(index: number, hash?: string): void {
    const nav = navigationActions.navigateBySpineIndex({ index, hash });
    this.navigationStore.dispatch(nav);
  }

  setSpineItem(spineItem: SpineItem, hash?: string, setFocus?: boolean): void {
    if (spineItem) {
      this.readerStore.setSpineItem(spineItem);
      const si = readerActions.setSpineItem({ spineItem, hash, setFocus });
      this.readerStore.dispatch(si);
    }
  }

  // nav double spine
  navDoubleSpread(index: number, setFocus?: boolean): void {
    const nav = navigationActions.navigateDoubleSpreadTo({ index, setFocus });
    this.navigationStore.dispatch(nav);
  }

  setDoubleSpineItem(spineItem: DoubleSpineItem): void {
    this.readerStore.setDoubleSpineItem(spineItem);
    const si = readerActions.setDoubleSpineItem({ spineItem });
    this.readerStore.dispatch(si);
  }

  // get indexes
  getDoubleSpineIndexFromSpineItem(
    spineItem: SpineItem,
    doubleSpine: DoubleSpineItem[],
  ): number {
    const dsItemIndex = doubleSpine
      .map((dsItem) => [dsItem.left?.idref, dsItem.right?.idref])
      .findIndex((itemIds) => itemIds.includes(spineItem.idref));

    return dsItemIndex;
  }

  getSingleSpineIndexFromDoubleSpineItem(
    doubleSpineItem: DoubleSpineItem,
    linearSpine: SpineItem[],
    { cfi, ribac },
  ): number {
    let spineItem = doubleSpineItem.left || doubleSpineItem.right;

    if (ribac && cfi?.includes(doubleSpineItem.right.id)) {
      spineItem = doubleSpineItem.right;
    }

    return linearSpine.findIndex((spItem) => spItem.idref === spineItem.idref);
  }

  getDoubleSpineIndexFromSpineIndex(
    spineIndex: number,
    doubleSpine: DoubleSpineItem[],
  ): number {
    const dsItemIndex = doubleSpine
      .map((dsItem) => [dsItem.left?.index, dsItem.right?.index])
      .findIndex((indexes) => indexes.includes(spineIndex));

    return dsItemIndex;
  }

  public findMatchingPageRangeIndex = (
    cfi: string,
    albumMode: boolean,
    linearSpine: SpineItem[],
    doubleSpine: DoubleSpineItem[],
  ): number | boolean | undefined => {
    if (!cfi) return false;
    const spineId = this.epubCfiLib.parseCFI(cfi, 'v6').spineId;
    if (doubleSpine.length > 0) {
      return this.findMatchingPageRangeIndexFromDoubleSpine(
        spineId,
        albumMode,
        doubleSpine,
      );
    }
    return this.findMatchingPageRangeIndexFromLinearSpine(
      spineId,
      albumMode,
      linearSpine,
    );
  };

  private findMatchingPageRangeIndexFromDoubleSpine(
    spineId: string,
    albumMode: boolean,
    doubleSpine: DoubleSpineItem[],
  ): any {
    const matchingIndex = Object.values(doubleSpine)
      .map((e: any, index) => {
        if (spineId && spineId === e.left?.id) { return albumMode ? e.left.index : index; }
        if (spineId && spineId === e.right?.id) { return albumMode ? e.right.index : index; }
      })
      .filter((e) => undefined !== e);
    if (matchingIndex.length > 0) return matchingIndex.shift() + 1;
  }

  private findMatchingPageRangeIndexFromLinearSpine(
    spineId: string,
    albumMode: boolean,
    linearSpine: SpineItem[],
  ): number | undefined {
    const matchingIndex = Object.values(linearSpine)
      .map((e: any, index): number | undefined => {
        if (spineId && spineId === e.id) return albumMode ? e.index : index;
      })
      .filter((e) => undefined !== e);
    if (matchingIndex.length > 0) return (matchingIndex.shift() as number) + 1;
  }

  // cfi
  isValidCfi(cfi: string): boolean {
    if (!cfi) {
      return false;
    }
    const parsed = this.epubCfiLib.parseCFI(cfi);
    const vals = Object.values(parsed);
    let valid = vals.some((v) => Boolean(v));

    // calling vals.some in the line above evaluates a spineIndex of 0 as falsy
    if (parsed.spineIndex === 0) {
      valid = true;
    }

    if (!valid) {
      console.error('Invalid CFI parsed: ', cfi);
    }

    return valid;
  }

  // render
  epubRender(
    book: Book,
    spineItem: SpineItem,
    hash?: string,
    setFocus?: boolean,
  ): void {
    const render = epubViewerActions.renderSinglePane({
      book,
      spineItem,
      hash,
      setFocus,
    });
    this.epubViewerStore.dispatch(render);
  }

  epubRenderDouble(book: Book, doubleSpineItem: DoubleSpineItem): void {
    const render = epubViewerActions.renderDoublePane({
      book,
      doubleSpineItem,
    });
    this.epubViewerStore.dispatch(render);
  }

  // drawers
  leftDrawer(open: boolean): void {
    const drawer = readerActions.setLeftDrawer({ open });
    this.readerStore.dispatch(drawer);
  }

  rightDrawer(open: boolean): void {
    const drawer = readerActions.setRightDrawer({ open });
    this.readerStore.dispatch(drawer);
  }

  // annotations
  withRelevantSpineItemAndIFrameFromSelection = (
    source$: Observable<{ selection: Selection, devicePlatform: string }>,
  ): Observable<
  [
    { selection: Selection, devicePlatform: string },
    HTMLIFrameElement,
    SpineItem,
  ]
  > =>
    source$.pipe(
      withLatestFrom(
        this.readerStore.spineItem$,
        this.readerStore.doubleSpineItem$,
        this.iframes$,
      ),
      map(
        ([
          source,
          spineItem,
          doubleSpineItem,
          [cloIframe, leftIframe, rightIframe],
        ]): [
          { selection: Selection, devicePlatform: string },
          HTMLIFrameElement,
          SpineItem,
        ] => {
          const selectionDoc = source.selection.focusNode?.ownerDocument;
          if (rightIframe.contentDocument === selectionDoc) {
            return [
              source,
              rightIframe,
              (doubleSpineItem as DoubleSpineItem).right,
            ];
          } else if (leftIframe.contentDocument === selectionDoc) {
            return [
              source,
              leftIframe,
              (doubleSpineItem as DoubleSpineItem).left,
            ];
          }
          return [source, cloIframe, spineItem as SpineItem];
        },
      ),
    );

  private findClosestTocItem(
    flatToc: FlatTocItem[],
    spineIndex: number,
  ): FlatTocItem {
    let maxSoFar = 0;

    flatToc.forEach((toc) => {
      if (toc.spinePos > maxSoFar && toc.spinePos < spineIndex) {
        maxSoFar = toc.spinePos;
      }
    });

    return flatToc.filter((toc) => toc.spinePos === maxSoFar)[0];
  }

  public getSpineIndexFromSpineId(
    flatToc: FlatTocItem[],
    spineId: string,
    spines: SpineItem[],
  ): FlatTocItem {
    const filteredSpines = spines.filter((spine) => spine.id === spineId);
    let spineIndex;
    if (filteredSpines.length > 0) {
      spineIndex = spines.filter((spine) => spine.id === spineId)[0].index;
    } else {
      return this.findClosestTocItem(flatToc, spineIndex);
    }

    const flatTocItems = flatToc.filter((toc) => toc.spinePos === spineIndex);
    if (flatTocItems && flatTocItems.length === 1) {
      return flatTocItems[0];
    }

    return this.findClosestTocItem(flatToc, spineIndex);
  }

  // Use to get labels for topics locations
  public getGroupLabelFromSpineId(
    flatToc: FlatTocItem[],
    spineId: string,
  ): string {
    for (const tocItem of flatToc) {
      const label = this.recursiveGetLowestLabelInTree(spineId, tocItem, '');
      if (label) return label;
    }
    return '';
  }

  public recursiveGetLowestLabelInTree(
    spineId: string,
    tocItem: TocItem,
    currentLabel: string,
  ): string {
    if (tocItem.id === spineId) {
      return tocItem.label;
    }
    if (tocItem.subItems.length > 0) {
      for (const subItem of tocItem.subItems) {
        if (subItem.spineItem.id === spineId) {
          return this.recursiveGetLowestLabelInTree(
            subItem.spineItem.id,
            subItem,
            tocItem.label,
          );
        }
      }
    }
    return currentLabel;
  }

  // iframe-mediator helpers
  public getSpineIndexFromUrl(
    url: string,
    indexMap: Record<number, number>,
    spines: SpineItem[] | undefined): number | null {
    const spineItem = spines?.find(spine => spine.url === url);
    const spineIndex = spineItem ? indexMap[spineItem.index] : null;
    return spineIndex;
  }

  public getParentIdFromAnchor(anchor: HTMLElement): string {
    const currentElement = anchor;

    if (!currentElement?.parentElement) {
      return '';
    }

    const parentElement = currentElement.parentElement;
    const parentId = parentElement.id;
    const regex = /data-uuid-[a-zA-Z\d]+/;

    if (regex.test(parentId)) {
      return parentId;
    }

    return this.getParentIdFromAnchor(parentElement);
  }

  public addHashToHistory(
    navigationHistory: navigationActions.NavigationActions[],
    hash: string,
    index: number): void {
    if (hash) {
      const action = navigationActions.navigateTo({ index, hash: `#${hash}` });
      navigationHistory.push(action);
      this.readerStore.setNavigationHistory(navigationHistory);
    }
  }
}
