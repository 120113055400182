import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WidgetData } from '@mhe/reader/models';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { Observable } from 'rxjs';

@Injectable()
export class WidgetsApiService {
  private readonly baseurl = '/v1/widgets';

  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  searchWidgetData(
    api: string,
    payload: {
      instanceID: string
      userID: string
      contextID: string
      platform: string
    },
  ): Observable<WidgetData[]> {
    const url = `${api}${this.baseurl}/search`;

    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<WidgetData[]>(url, payload),
    );
  }

  upsertWidgetData(api: string, widget: WidgetData): Observable<WidgetData> {
    const isNew = !widget.resourceUUID;
    const url = `${api}${this.baseurl}${
      isNew ? '' : `/${widget.resourceUUID}`
    }`;

    // using post for create and update
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<WidgetData>(url, widget),
    );
  }

  // does delete by instanceID for one widget, or whole ePUB when instanceID is omitted
  deleteWidgetData(
    api: string,
    payload: {
      userID: string
      contextID: string
      platform: string
      instanceID?: string
    },
  ): Observable<any> {
    const url = `${api}${this.baseurl}`;

    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.request('delete', url, { body: payload }),
    );
  }
}
