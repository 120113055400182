import { Injectable } from '@angular/core';

/**
 * Mocking out the localized CSS service.  Should discuss with team if we want to do something different.
 */
@Injectable()
export class MockLocalizedCssService {
  public data = {
    annotationCss: '',
    teacherCss: '',
  };
}
