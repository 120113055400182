import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';

@Injectable()
export class ReaderOverlayContainerService implements OnDestroy {
  protected _containerElement: HTMLElement;
  protected _document: Document;
  private _containerScopeElement: HTMLElement;

  constructor(@Inject(DOCUMENT) document: any, private readonly renderer: Renderer2) {
    this._document = document;
  }

  ngOnDestroy(): void {
    const container = this._containerElement;

    if (container?.parentNode) {
      container.parentNode.removeChild(container);
    }
  }

  setContainerScopeElement(el: HTMLElement): void {
    this._containerScopeElement = el;
  }

  getContainerElement(): HTMLElement {
    if (!this._containerElement) {
      this._createContainer();
    }

    return this._containerElement;
  }

  protected _createContainer(): void {
    const scopedContainer = this._containerScopeElement || this._document.body;

    const containerClass = 'cdk-overlay-container';

    const container = this.renderer.createElement('div');
    this.renderer.addClass(container, containerClass);
    this.renderer.setStyle(container, 'position', 'absolute');

    this.renderer.appendChild(scopedContainer, container);
    this._containerElement = container;
  }
}
