import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { TTSStore } from '../../state/tts.store';
import * as ttsActions from '../../state/tts.actions';
import { OVERLAY_REF, TTS_REQUEST } from './floating-tts-controls.tokens';
import { TTSFloatingTextRequest, TTSRawTextOptions } from '@mhe/reader/models';

@Component({
  selector: 'rdrx-floating-tts-controls',
  templateUrl: './floating-tts-controls.component.html',
  styleUrls: ['./floating-tts-controls.component.scss'],
})
export class FloatingTTSControlsComponent {
  readonly controlDisabled$ = this.ttsStore.disabled$;

  constructor(
    @Inject(OVERLAY_REF) private readonly overlayRef: OverlayRef,
    @Inject(TTS_REQUEST) readonly ttsRequest: TTSFloatingTextRequest,
    private readonly ttsStore: TTSStore,
  ) {
    this.onLoadAudioText();
  }

  onClose(): void {
    this.overlayRef.detach();
  }

  onLoadAudioText(): void {
    const options: TTSRawTextOptions = {
      text: this.ttsRequest.text,
      cfi: this.ttsRequest.id,
      pageLanguage: undefined,
      autoplay: this.ttsRequest.autoplay,
    };

    this.ttsStore.dispatch(ttsActions.loadRawTextAudio({ options }));
  }
}
