import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mapTo,
  pairwise,
  shareReplay,
  switchMap,
  withLatestFrom,
  catchError,
} from 'rxjs/operators';
import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import { HttpClient } from '@angular/common/http';
import {
  ReadiatorMessageTypes,
  SEARCH_ROOT_BONSAI_NODE,
} from '@mhe/reader/models';
import {
  emitReadiatorEvent,
  resultSelected,
} from '@mhe/reader/components/search/state/search.actions';
import { SearchStore } from '@mhe/reader/components/search';
import {
  NavigationStore,
  navigateByCfi,
} from '@mhe/reader/components/navigation';
import { MediatorUtils } from './mediator-utils';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import * as topicsActions from '@mhe/reader/components/topics/state/topics.actions';

const filterGroup =
  <T extends { group: string }>(z: string) =>
    (x: T[]) =>
      x.filter((y: T) => y.group === z);

interface SuggestionsApiItem {
  uuid: string
  name: string
  type: string
  locations: Array<{ cfi: string, epub: string }>
  parent: string
  assessments: string[]
}

enum SuggestionGroup {
  DEFAULT = 'default',
  QUIZZES = 'quizzes',
  TOPICS = 'topics',
}

interface SearchApiItem {
  file_path: any
  spine_id: string
  highlights: Array<{ text: string, index: number }>
  breadcrumbs: string[]
}

interface SearchApiResponse {
  results: SearchApiItem[]
  totalResults: number
  limit: number
}

@Injectable()
export class ElasticSearchService {
  private readonly _searchQuery = new BehaviorSubject({ value: '', length: 0 });
  private readonly _searchSubmitted$ = new Subject<{ value: string }>();
  private readonly _searchQueryDebounceTime: number = 300;
  private readonly minChars$: Observable<number> =
    this.readerConfigStore.elasticSearchMinChars$;

  private readonly searchEndPoint$: Observable<string> =
    this.readerConfigStore.elasticSearchEndPoint$;

  private readonly searchSuggestionsEndPoint$: Observable<string> =
    this.readerConfigStore.elasticSearchSuggestionsEndPoint$;

  epubKey$ = this.readerStore.epubKey$.pipe(
    map((key) => key.split('/').slice(0, -1).pop()),
  );

  searchQuery$: Observable<{ value: string, length: number }> =
    this._searchQuery.asObservable().pipe(
      pairwise(),
      filter(([prev, curr]) => prev.value !== curr.value),
      map(([prev, curr]) => curr),
      withLatestFrom(this.minChars$),
      filter(([query, minChars]) => query.length >= minChars),
      map(([query]) => query),
      debounceTime(this._searchQueryDebounceTime),
    );

  searchSubmitted$ = this._searchSubmitted$.asObservable();

  suggestionsApi$: Observable<Array<{ term: string, group: string }>> = merge(
    this.searchQuery$,
    this.searchSubmitted$.pipe(
      withLatestFrom(this.minChars$),
      filter(([{ value }, minChars]) => value.length >= minChars),
      map(([query]) => query),
    ),
  ).pipe(
    withLatestFrom(this.searchSuggestionsEndPoint$),
    switchMap(([{ value }, baseUrl]) => {
      if (!baseUrl) {
        return of([]);
      }
      const url = `${baseUrl}&query=${encodeURIComponent(value)}`;
      return this.http.get<SuggestionsApiItem[]>(url).pipe(
        map((results) => {
          if (results === null) {
            return [];
          }
          // any topic categories with assessment? -> show as quiz
          const quizzes = results.filter(
            (result) => result.assessments && result.assessments.length > 0,
          );
          // type === 'glossary' -> terms
          const glossary = results.filter(
            (result) => result.type === 'glossary',
          );
          // any topics with location? -> show as topic
          const topics = results.filter(
            (result) =>
              result.type === 'TOPIC' &&
              result.locations &&
              result.locations.length > 0,
          );
          const suggestions: Array<{ term: string, group: string }> = [
            ...glossary.map(({ name, ...result }) => ({
              term: name,
              group: SuggestionGroup.DEFAULT,
              ...result,
            })),
            ...topics.map(({ name, ...result }) => ({
              term: name,
              group: SuggestionGroup.TOPICS,
              ...result,
            })),
            ...quizzes.map(({ name, ...result }) => ({
              term: name,
              group: SuggestionGroup.QUIZZES,
              ...result,
            })),
          ];
          return suggestions;
        }),
        catchError((err) => {
          console.error(err);
          return of([]);
        }),
      );
    }),
    shareReplay(1),
  );

  searchApi$ = this.searchSubmitted$.pipe(
    withLatestFrom(this.searchEndPoint$),
    switchMap(([{ value }, endpoint]) => {
      const url = endpoint + '&query=' + encodeURIComponent(value);
      const response$ = this.http.get<SearchApiResponse>(url);

      return response$.pipe(
        map(({ results, totalResults, limit }) => ({ results, totalResults })),
        catchError((err) => {
          console.error(err);
          return of({ results: [] as SearchApiItem[], totalResults: 0 });
        }),
      );
    }),
    shareReplay(1),
  );

  searchResults$ = this.searchApi$.pipe(
    withLatestFrom(this.readerStore.flatToc$.pipe(map(Object.values))),
    map(([{ results: pages }, toc]) => {
      const nodes = {};
      // all top-level nodes are children of the root node
      nodes[SEARCH_ROOT_BONSAI_NODE] = {};
      const rootChildIds: string[] = [];
      pages.forEach((page) => {
        // upsert a top-level node for each indexed page
        const pageNodeId = page.spine_id;
        const pageNode =
          pageNodeId in nodes ? nodes[pageNodeId] : (nodes[pageNodeId] = {});
        const pageSpine = toc.find((item) => item.id === page.spine_id);
        pageNode.id = pageNodeId;
        pageNode.spineIndex = pageSpine?.spinePos;

        if (page.breadcrumbs && page.breadcrumbs.length > 0) {
          // last breadcrumb is the page title
          pageNode.title = page.breadcrumbs[page.breadcrumbs.length - 1];
          // all other breadcrumbs are parents
          pageNode.breadcrumbs = page.breadcrumbs.slice(0, -1);
        } else {
          pageNode.breadcrumbs = [];
          pageNode.title = pageSpine?.label ?? '';
        }

        const childIds: string[] = (pageNode.childIds = []);
        page.highlights.forEach((highlight, index) => {
          // upsert a bottom-level node for each highlighted fragment
          const highlightNodeId = page.spine_id + ',' + childIds.length;
          const highlightNode =
            nodes[highlightNodeId] ||
            (nodes[highlightNodeId] = { id: highlightNodeId });
          // highlights use a custom template that ignores the title and children
          highlightNode.innerHTML = highlight.text;
          highlightNode.spineId = page.spine_id;
          // spineIndex and hash are used for navigation
          // spineIndex is also used for ordering
          highlightNode.spineIndex = pageSpine?.spinePos;
          highlightNode.hash = '#' + page.spine_id;
          highlightNode.index = childIds.length;
          // link this b-node to parent t-node (as long as we can navigate to it)
          if (highlightNode.spineIndex) {
            childIds.push(highlightNodeId);
          }
        });
        // link this t-node to the root (as long as it has children)
        if (childIds.length > 0) {
          rootChildIds.push(pageNodeId);
        }
      });
      // duplicates here cause the tree to have duplicate nodes and strange behavior
      const rootNode = nodes[SEARCH_ROOT_BONSAI_NODE];
      rootNode.childIds = Array.from(new Set(rootChildIds));

      return nodes;
    }),
  );

  totalResults$ = this.searchApi$.pipe(map(({ totalResults }) => totalResults));

  loading$ = merge(
    this.searchQuery$.pipe(mapTo(true)),
    this.searchSubmitted$.pipe(mapTo(true)),
    this.suggestionsApi$.pipe(mapTo(false)),
  );

  suggestions$ = this.suggestionsApi$.pipe(
    map(filterGroup(SuggestionGroup.DEFAULT)),
  );

  topics$ = this.suggestionsApi$.pipe(map(filterGroup(SuggestionGroup.TOPICS)));
  quizzes$ = this.suggestionsApi$.pipe(
    map(filterGroup(SuggestionGroup.QUIZZES)),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly readerStore: ReaderStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly searchStore: SearchStore,
    private readonly navigationStore: NavigationStore,
    private readonly topicsStore: TopicsStore,
    private readonly utils: MediatorUtils,
    private readonly ngZone: NgZone,
  ) {}

  handleSearchQueryChange(query: string): void {
    this._searchQuery.next({ value: query, length: query.length });
  }

  handleSearchSubmitted(item): void {
    if (item.group === SuggestionGroup.TOPICS) {
      this.navigateToTopic(item);
    } else if (item.group === SuggestionGroup.QUIZZES) {
      this.navigateToQuiz(item);
    } else {
      // use as search query
      this._searchSubmitted$.next({ value: item.term });
    }
  }

  handleSearchResultClicked(node): void {
    this.navigateToSearchResult(node);
  }

  private navigateToTopic(topic): void {
    const location = topic.locations[0];
    // some kind of race condition prevents the drawer from closing
    // if we navigate immediately
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.utils.rightDrawer(false);
      }, 0);
    });
    this.navigationStore.dispatch(
      navigateByCfi({
        cfi: location?.cfi,
        setFocus: true,
      }),
    );
    this.topicsStore.dispatch(
      topicsActions.selectInternalLocation({ location }),
    );
  }

  private navigateToQuiz(quiz): void {
    const event = emitReadiatorEvent({
      payload: {
        event: ReadiatorMessageTypes.ASSESSMENT_LAUNCH,
        assessmentInstanceId: quiz.assessments[0],
      },
    });
    this.searchStore.dispatch(event);
  }

  private navigateToSearchResult(result): void {
    const regex = /<span class="highlighted">(.*?)<\/span>/g;
    const query = result.innerHTML.replace(regex, '$1');
    this.searchStore.dispatch(
      resultSelected({
        result: {
          query,
          spineIndex: result.spineIndex,
          occurrence: result.index,
          id: '',
          href: '',
          sectionName: '',
          frameSide: 'left',
          text: '',
          isLink: false,
          indexRelativeToParent: 0,
          isPageNumber: false,
          parentId: '',
        },
      }),
    );
  }
}
