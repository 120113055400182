/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectorRef,
  Injectable,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { fromEvent, interval, Subject } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { EpubMoveDirection, EpubMoveArrowStates } from '@mhe/reader/models';

class ContentDimensions {
  get widthRatio(): number {
    return this.initialWidth / this.initialHeight;
  }

  get heightRatio(): number {
    return this.initialHeight / this.initialWidth;
  }

  constructor(public initialWidth: number, public initialHeight: number) {}
}

interface FixedPage {
  iframe: HTMLIFrameElement
  dimensions: ContentDimensions
}

@Injectable()
export class FixedPageService implements OnDestroy {
  readonly epubControlsPadding = 140;
  readonly maxEpubWidth = 1200;
  readonly fixedPageClass = 'fixed-page-layout';
  readonly moveOffsetIncrement = 0.1;
  fixedPages: FixedPage[] = [];
  zoomLevel = 1;
  windowResizeDisposal: any;

  // public attributes for unit tests
  directionArrowStatesSubject = new Subject<EpubMoveArrowStates>();
  iframeLoadDisposal: () => void;
  destroy$ = new Subject();
  albumMode: boolean;
  isDoubleSpread: boolean;
  isFplSpine = false; // used to detect FPL pages in CREATE mixed content epubs

  private get windowWidth(): number {
    return window.innerWidth - this.epubControlsPadding;
  }

  directionArrowStates$ = this.directionArrowStatesSubject.asObservable();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) {}

  initialize(
    doc: HTMLDocument,
    iframe: HTMLIFrameElement,
    isSpineFpl: boolean,
  ): HTMLDocument {
    const dimensions = this.getIframeAspectRatio(doc) as ContentDimensions;
    const fixedPage: FixedPage = { iframe, dimensions };

    this.isFplSpine = isSpineFpl;

    if (fixedPage.dimensions) {
      this.renderer.addClass(
        iframe.closest('.spliter-container'),
        this.fixedPageClass,
      );

      this.setFixedPageContentStyles(doc, fixedPage.dimensions);
      this.updateContentDimensions(fixedPage, doc);

      if (!this.windowResizeDisposal) {
        this.windowResizeDisposal = this.renderer.listen(
          window,
          'resize',
          () => {
            this.fixedPages.forEach((page) =>
              this.updateContentDimensions(page),
            );
          },
        );
      }

      this.fixedPages.push(fixedPage);
    }

    this.initializeScrollListeners(iframe);
    return doc;
  }

  setIsDoubleSpread(doubleSpread: boolean): void {
    this.isDoubleSpread = doubleSpread;
  }

  setAlbumMode(mode: boolean): void {
    this.albumMode = mode;
  }

  setZoomLevel(zoom: number): void {
    this.zoomLevel = zoom;
    this.fixedPages.forEach((fixedPage) =>
      this.updateContentDimensions(fixedPage),
    );
  }

  moveZoomedEpub(
    iframe: HTMLIFrameElement,
    direction: EpubMoveDirection,
  ): void {
    const body = iframe.contentDocument?.body as HTMLBodyElement;

    if (
      direction === EpubMoveDirection.LEFT ||
      direction === EpubMoveDirection.RIGHT
    ) {
      this.moveEpubHorizontally(body, direction);
    }

    if (
      direction === EpubMoveDirection.UP ||
      direction === EpubMoveDirection.DOWN
    ) {
      this.moveEpubVertically(body, direction);
    }
  }

  ngOnDestroy(): void {
    this.clearFixedPages();
    this.clearScrollListeners();
  }

  reset(iframe: HTMLIFrameElement): void {
    const container = iframe.closest('.spliter-container');

    this.renderer.removeClass(container, this.fixedPageClass);
    this.renderer.removeStyle(container, 'width');
    this.renderer.removeStyle(container, 'height');

    this.clearFixedPages();
    this.clearScrollListeners();
  }

  clearFixedPages(): void {
    this.disposeWindowResize();
    this.windowResizeDisposal = null;
    this.fixedPages = [];
  }

  centerEpub(iframe: HTMLIFrameElement, doc?: HTMLDocument): void {
    const contentBody = (iframe.contentDocument?.body ??
      doc?.body) as HTMLBodyElement;
    const bodyParent = contentBody.parentElement;

    const contentBodyRect = contentBody.getBoundingClientRect();
    const bodyParentRect = bodyParent?.getBoundingClientRect() as DOMRect;

    const centerXPos = (contentBodyRect.width - bodyParentRect.width) / 2;

    this.moveEpubHorizontally(contentBody, null, -centerXPos);
    this.moveEpubVertically(contentBody, null, 0);
  }

  private setFixedPageContentStyles(
    doc: HTMLDocument,
    dimensions: ContentDimensions,
  ): void {
    const contentBody = doc.body;
    const contentHTMLEl = contentBody.parentElement;

    if (this.shouldHideScrollBars()) {
      this.renderer.setStyle(contentHTMLEl, 'overflow', 'hidden');
      this.renderer.setStyle(contentBody, 'overflow', 'hidden');
    }

    this.renderer.setStyle(
      contentBody,
      'width',
      `${dimensions.initialWidth}px`,
    );
    this.renderer.setStyle(
      contentBody,
      'height',
      `${dimensions.initialHeight}px`,
    );
    this.renderer.setStyle(contentBody, 'transformOrigin', 'left top');
    this.renderer.setStyle(contentBody, 'position', 'absolute');
  }

  private moveEpubHorizontally(
    contentBody: HTMLBodyElement,
    direction: EpubMoveDirection | null,
    forcePosition?: number,
  ): void {
    const xOffset: number =
      direction === EpubMoveDirection.LEFT
        ? this.moveOffsetIncrement
        : -this.moveOffsetIncrement;

    const bodyParent = contentBody.parentElement;

    const contentBodyRect = contentBody.getBoundingClientRect();
    const bodyParentRect = bodyParent?.getBoundingClientRect() as DOMRect;

    const horizontalDiff = Math.round(
      contentBodyRect.width - bodyParentRect.width,
    );
    const allowHorizontalScroll = horizontalDiff > 0;

    let newXOffset: number = !isNaN(forcePosition as number)
      ? (forcePosition as number)
      : contentBodyRect.left + horizontalDiff * xOffset;

    const maxLeftReached = Math.ceil(newXOffset) >= 0;
    const maxRightReached = Math.floor(newXOffset) <= -horizontalDiff;

    newXOffset = maxLeftReached
      ? 0
      : maxRightReached
        ? -horizontalDiff
        : newXOffset;

    if (allowHorizontalScroll || !isNaN(forcePosition as number)) {
      if (this.shouldHideScrollBars()) {
        this.renderer.setStyle(contentBody, 'left', `${newXOffset}px`);
      } else {
        const activeElement = document.activeElement;
        contentBody.ownerDocument.defaultView?.scrollTo({ left: -newXOffset });
        this.restoreActiveElementFocus(activeElement as Element);
      }
    }

    this.directionArrowStatesSubject.next({
      leftEnabled: allowHorizontalScroll && !maxLeftReached,
      rightEnabled: allowHorizontalScroll && !maxRightReached,
    });
  }

  private moveEpubVertically(
    contentBody: HTMLBodyElement,
    direction: EpubMoveDirection | null,
    forcePosition?: number,
  ): void {
    const yOffset =
      direction === EpubMoveDirection.UP
        ? this.moveOffsetIncrement
        : -this.moveOffsetIncrement;

    const bodyParent = contentBody.parentElement;

    const contentBodyRect = contentBody.getBoundingClientRect();
    const bodyParentRect = bodyParent?.getBoundingClientRect() as DOMRect;

    const verticalDiff = contentBodyRect.height - bodyParentRect.height;
    const allowVerticalScroll = verticalDiff > 0;
    let newYOffset: number = !isNaN(forcePosition as number)
      ? (forcePosition as number)
      : contentBodyRect.top + verticalDiff * yOffset;

    const maxUpReached = Math.ceil(newYOffset) >= 0;
    const maxDownReached = Math.floor(newYOffset) <= -verticalDiff;

    newYOffset = maxUpReached ? 0 : maxDownReached ? -verticalDiff : newYOffset;

    if (allowVerticalScroll || !isNaN(forcePosition as number)) {
      if (this.shouldHideScrollBars()) {
        this.renderer.setStyle(contentBody, 'top', `${newYOffset}px`);
      } else {
        const activeElement = document.activeElement;
        contentBody.ownerDocument.defaultView?.scrollTo({ top: -newYOffset });
        this.restoreActiveElementFocus(activeElement as Element);
      }
    }

    this.directionArrowStatesSubject.next({
      upEnabled: allowVerticalScroll && !maxUpReached,
      downEnabled: allowVerticalScroll && !maxDownReached,
    });
  }

  // calling scrollTo on the iframe steals the button focus EPR-8515
  restoreActiveElementFocus(activeElement: Element): void {
    setTimeout(() => {
      (activeElement as HTMLElement).focus();
    }, 100);
  }

  private updateContentDimensions(
    fixedPage: FixedPage,
    doc?: HTMLDocument,
  ): void {
    doc = doc ?? (fixedPage.iframe.contentDocument as Document);
    const iframeContainer = fixedPage.iframe.closest('.spliter-container');
    const isSinglePage = !this.isDoubleSpread || this.albumMode;
    const contentBody = doc.body;

    // return iframe to default dimensions.
    this.renderer.removeStyle(iframeContainer, 'width');
    this.renderer.removeStyle(iframeContainer, 'height');

    const scaledIframeDimensions = this.getScaledIframeDimensions(fixedPage);
    const totalIframeContainerWidth = isSinglePage
      ? scaledIframeDimensions.width
      : scaledIframeDimensions.width * 2;
    const shouldScaleByWidth = totalIframeContainerWidth <= this.windowWidth;

    const scaledWidth = isSinglePage
      ? totalIframeContainerWidth * this.zoomLevel
      : totalIframeContainerWidth;

    if (this.shouldHideScrollBars()) {
      const constrainedWidth = Math.min(
        scaledWidth,
        this.maxEpubWidth - this.epubControlsPadding,
      );
      this.renderer.setStyle(iframeContainer, 'width', `${constrainedWidth}px`);
    }

    const iframeRect = fixedPage.iframe.getBoundingClientRect();

    // calculate the scale using width (instead of height) since width is constrained
    let newScale = iframeRect.width / fixedPage.dimensions.initialWidth;

    if (this.shouldHideScrollBars()) {
      this.renderer.setStyle(
        contentBody.parentElement,
        'width',
        `${iframeRect.width}px`,
      );
      this.renderer.setStyle(
        contentBody.parentElement,
        'height',
        `${iframeRect.height}px`,
      );
    } else {
      // The OCR process for CREATE adds some extra margin and width that causes horizontal scroll bars
      this.renderer.setStyle(contentBody, 'margin', '0');
      newScale =
        (iframeRect.width - 15) / (fixedPage.dimensions.initialWidth + 22);
    }

    if (isSinglePage) {
      newScale = newScale * this.zoomLevel;
    }

    this.renderer.setStyle(contentBody, 'transform', `scale(${newScale})`);

    if (isSinglePage) {
      this.centerEpub(fixedPage.iframe, doc);
    }
  }

  private getIframeAspectRatio(
    doc: HTMLDocument,
  ): ContentDimensions | undefined {
    const metaViewport = doc.querySelector('meta[name="viewport"]') as Element;

    if (metaViewport) {
      const metaViewportContent = metaViewport.getAttribute(
        'content',
      ) as string;

      if (metaViewportContent) {
        const widthStr = (
          metaViewportContent.match(/width=(\d+)/i) as RegExpExecArray
        )[1];
        const heightStr = (
          metaViewportContent.match(/height=(\d+)/i) as RegExpExecArray
        )[1];

        const width = parseInt(widthStr, 10) || null;
        const height = parseInt(heightStr, 10) || null;

        if (!width || !height) {
          return;
        }

        return new ContentDimensions(width, height);
      }
    }
  }

  private getScaledIframeDimensions(fixedPage: FixedPage): {
    width: number
    height: number
  } {
    const iframeContainer = fixedPage.iframe.closest('.spliter-container');
    const iframeContainerRect =
      iframeContainer?.getBoundingClientRect() as DOMRect;
    const width = iframeContainerRect.height * fixedPage.dimensions.widthRatio;
    const height = iframeContainerRect.width * fixedPage.dimensions.heightRatio;

    return { width, height };
  }

  private disposeWindowResize(): void {
    if (this.windowResizeDisposal) {
      this.windowResizeDisposal();
    }
  }

  clearScrollListeners(): void {
    this.destroy$.next(null);
    this.destroy$.complete();

    if (this.iframeLoadDisposal) {
      this.iframeLoadDisposal();
    }
  }

  shouldHideScrollBars(): boolean {
    return !this.isFplSpine && !this.albumMode && this.isDoubleSpread;
  }

  private initializeScrollListeners(iframe: HTMLIFrameElement): void {
    this.clearScrollListeners();

    this.iframeLoadDisposal = this.renderer.listen(iframe, 'load', () => {
      fromEvent(iframe.contentDocument as Document, 'scroll')
        .pipe(
          takeUntil(this.destroy$),
          debounce(() => interval(100)),
        )
        .subscribe(() => this.updateScrollButtonStates(iframe));
    });
  }

  updateScrollButtonStates(iframe: HTMLIFrameElement): void {
    const contentHTMLEl = iframe.contentDocument?.body
      .parentElement as HTMLElement;

    this.directionArrowStatesSubject.next({
      leftEnabled: Math.floor(contentHTMLEl.scrollLeft) > 0,
      rightEnabled:
        contentHTMLEl.scrollWidth - Math.floor(contentHTMLEl.scrollLeft) !==
        contentHTMLEl.clientWidth,
      downEnabled:
        contentHTMLEl.scrollHeight - Math.floor(contentHTMLEl.scrollTop) !==
        contentHTMLEl.clientHeight,
      upEnabled: Math.floor(contentHTMLEl.scrollTop) > 0,
    });

    this.cdr.detectChanges();
  }
}
