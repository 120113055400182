import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BonsaiTree, AnnotationListBonsaiNode } from '@mhe/reader/models';
import { AnnotationListStore } from '../../utils/annotation-list.store.abstract';
import { groupsToTree } from '../../utils/groups-to-tree';
import { HighlightListActions } from './highlight-list.actions';
import {
  HighlightListState,
  initialHighlightListState,
} from './highlight-list.state';

@Injectable()
export class HighlightListStore extends AnnotationListStore<
HighlightListState,
HighlightListActions
> {
  /** selectors **/
  readonly tree$: Observable<BonsaiTree<AnnotationListBonsaiNode>> =
    this.select(this.groups$, (groups) =>
      groupsToTree(
        groups,
        (annotation) => !!annotation.shape && !!annotation.color,
      ),
    );

  constructor() {
    super(initialHighlightListState);
  }
}
