/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { AssignmentContext } from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, merge } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  mergeMap,
  filter,
  map,
  switchMapTo,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AssignmentsService } from '@mhe/reader/core/player-api/assignments.service';
import { ReadiatorBridgeService } from '@mhe/reader/features/readiator-bridge';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as assignmentActions from '../components/reader/state/assignment.actions';

import { MediatorUtils } from './mediator-utils';
import {
  ConfirmationModalComponent,
  ConfirmationModalData,
} from '@mhe/reader/components/modals/confirmation-modal';

@Injectable()
export class AssignmentsMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly runtime$ = this.config.state$.pipe(
    map(({ runtime }) => runtime),
  );

  constructor(
    protected window: Window,
    private readonly assignmentsService: AssignmentsService,
    private readonly config: ReaderConfigStore,
    private readonly dialog: MatDialog,
    private readonly readerStore: ReaderStore,
    private readonly readiator: ReadiatorBridgeService,
    private readonly translate: TranslateService,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  private readonly withLatestContext$ = <T>(
    source$: Observable<T>,
  ): Observable<[T, AssignmentContext]> => {
    return source$.pipe(
      withLatestFrom(this.runtime$),
      map(([source, config]): [T, AssignmentContext] => {
        const {
          userId,
          assnId,
          lisResultSourcedId,
          assignmentXid,
          resourceLinkId,
          sectionXid,
          clId,
        } = config;

        const uid = this.isUserXID(userId) ? undefined : userId;
        const learner_xid = this.isUserXID(userId) ? userId : undefined;
        const assnid =
          assnId ?? this.parseAssignmentId(lisResultSourcedId as string);
        const assignment_xid = assignmentXid ?? (resourceLinkId as string);
        const section_xid = sectionXid;
        const clid = clId;

        const context: AssignmentContext = {
          assignment_xid,
          assnid,
          clid,
          learner_xid,
          section_xid,
          uid,
        };
        return [source, context];
      }),
    );
  };

  /** submit */
  private readonly submitConfirmation$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(assignmentActions.confirmAssignmentSubmit),
      exhaustMap(() => this.confirmationDialog$().afterClosed()),
      filter((rsp) => Boolean(rsp)),
      tap(() => {
        const submitAction = assignmentActions.submitAssignment();
        this.readerStore.dispatch(submitAction);
      }),
      logCatchError('submitConfirmation$'),
    );
  });

  private readonly submitAssignment$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(assignmentActions.submitAssignment),
      tap(() => this.readerStore.setAssignmentSubmitting(true)),
      withLatestFrom(this.util.playerApi$),
      this.withLatestContext$,
      exhaustMap(([[, api], context]) => {
        return this.assignmentsService.submit(api, context).pipe(
          catchError(() => {
            const errorAction = assignmentActions.submitAssignmentError();
            this.readerStore.dispatch(errorAction);
            return EMPTY;
          }),
        );
      }),
      tap(() => {
        const succcessAction = assignmentActions.submitAssignmentSuccess();
        this.readerStore.dispatch(succcessAction);
      }),
      logCatchError('submitAssignment$'),
    );
  });

  private readonly handleAssignmentLexile$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(assignmentActions.handleAssignmentLexileLevel),
      withLatestFrom(
        this.util.playerApi$,
        this.readerStore.renderedLexileLevel$,
        this.config.assignment$,
        this.config.lastReopen$,
      ),
      mergeMap(([, apiBaseURL, lexileLevel, assignment, lastReopen]) => {
        if (assignment === 'active' && !lastReopen) {
          return this.assignmentsService.handleAssignmentLexileLevel(
            apiBaseURL,
            lexileLevel as string,
          );
        }
        return EMPTY;
      }),
      logCatchError('submitAssignmentLexile$'),
    );
  });

  private readonly submitAssignmentSuccess$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(assignmentActions.submitAssignmentSuccess),
      tap(() => this.readerStore.setAssignmentSubmitted(true)),
      tap(() => this.readerStore.setAssignmentSubmitting(false)),
      exhaustMap(() => this.submitSuccessDialog$().afterClosed()),
      switchMapTo(this.postSubmitSuccess()),
      logCatchError('submitAssignmentSuccess$'),
    );
  });

  private readonly submitAssignmentError$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(assignmentActions.submitAssignmentError),
      tap(() => this.readerStore.setAssignmentSubmitting(false)),
      exhaustMap(() => this.submitErrorDialog$().afterClosed()),
      logCatchError('submitAssignmentError$'),
    );
  });

  /** dialog instances */
  private confirmationDialog$(): MatDialogRef<
  ConfirmationModalComponent,
  ConfirmationModalData
  > {
    const title = this.translate.instant('assignment.warning_title');
    const content = this.translate.instant('assignment.warning_content');
    const closeText = this.translate.instant('shared.cancel');
    const confirmText = this.translate.instant('shared.submit');

    const data: ConfirmationModalData = {
      title,
      content,
      closeText,
      confirmText,
    };

    return this.dialog.open(ConfirmationModalComponent, {
      data,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }

  private submitSuccessDialog$(): MatDialogRef<
  ConfirmationModalComponent,
  ConfirmationModalData
  > {
    const title = this.translate.instant('assignment.success_title');
    const content = this.translate.instant('assignment.success_content');
    const closeText = this.translate.instant('assignment.ok_btn');

    const data: ConfirmationModalData = { title, content, closeText };

    return this.dialog.open(ConfirmationModalComponent, {
      data,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }

  private submitErrorDialog$(): MatDialogRef<
  ConfirmationModalComponent,
  ConfirmationModalData
  > {
    const title = this.translate.instant('assignment.error_title');
    const content = this.translate.instant('assignment.error_content');
    const closeText = this.translate.instant('shared.close');

    const data: ConfirmationModalData = { title, content, closeText };

    return this.dialog.open(ConfirmationModalComponent, {
      data,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }

  /** helpers */
  private parseAssignmentId(token: string): any {
    const payload = JSON.parse(atob(token));
    return payload?.assnid;
  }

  private isUserXID(id: string): boolean {
    return id.includes('urn:');
  }

  private postSubmitSuccess(): Observable<string | boolean | undefined> {
    const { launchPresentationReturnUrl$, k5$ } = this.config;

    const k5Submit$ = k5$.pipe(
      take(1),
      filter((k5) => k5),
      tap(() => this.readiator.assignmentSubmit()),
    );

    const returnUrl$ = launchPresentationReturnUrl$.pipe(
      take(1),
      filter((url) => Boolean(url)),
      delay(2000),
      tap((url) => this.window.location.replace(url as string)),
    );

    return merge(k5Submit$, returnUrl$);
  }
}
