import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import * as actions from './concept-navigator.actions';
import {
  ConceptNavigatorState,
  initialConceptNavigatorState,
} from './concept-navigator.state';

@Injectable()
export class ConceptNavigatorStore extends ExtendedComponentStore<
ConceptNavigatorState,
actions.conceptNavigatorActions
> {
  constructor() {
    super(initialConceptNavigatorState);
  }

  /** selectors */
  readonly conceptNavigatorEnabled$ = this.select(
    ({ conceptNavEnabled }) => conceptNavEnabled,
  );

  readonly prevConceptButtonEnabled$ = this.select(
    ({ prevButtonEnabled }) => prevButtonEnabled,
  );

  readonly nextConceptButtonEnabled$ = this.select(
    ({ nextButtonEnabled }) => nextButtonEnabled,
  );

  readonly currentConceptButtonEnabled$ = this.select(
    ({ currentButtonEnabled }) => currentButtonEnabled,
  );

  /** updaters */
  readonly setConceptNavEnabled = this.updater(
    (state, conceptNavEnabled: boolean) => ({
      ...state,
      conceptNavEnabled,
    }),
  );

  readonly setPrevButtonEnabled = this.updater(
    (state, prevButtonEnabled: boolean) => ({
      ...state,
      prevButtonEnabled,
    }),
  );

  readonly setNextButtonEnabled = this.updater(
    (state, nextButtonEnabled: boolean) => ({
      ...state,
      nextButtonEnabled,
    }),
  );

  readonly setCurrentButtonEnabled = this.updater(
    (state, currentButtonEnabled: boolean) => ({
      ...state,
      currentButtonEnabled,
    }),
  );
}
