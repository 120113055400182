import { createAction, props } from '@ngrx/store';

import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationType } from '../../annotation.data';

export const annotationSelected = createAction(
  'Note List Annotation Selected',
  props<{ annotation: ApiAnnotation }>(),
);

export const askForAnnotations = createAction(
  'Note Ask for Annotations',
  props<{ spineID: string }>(),
);

export const exportNotes = createAction('Note List Export');

export const noteDelete = createAction(
  'Note List Annotation Delete',
  props<{ annotation: ApiAnnotation }>(),
);

export const getAnnotationsForLabel = createAction(
  'Note List Get Annotations For Label',
  props<{ spineID: string }>(),
);

export const notesBatchDelete = createAction(
  'Notes List Annotation Batch Delete',
  props<{ data: { annotations: ApiAnnotation[], type: AnnotationType } }>(),
);

export type NoteListActions = ReturnType<
| typeof annotationSelected
| typeof noteDelete
| typeof exportNotes
| typeof askForAnnotations
| typeof getAnnotationsForLabel
| typeof notesBatchDelete
>;
