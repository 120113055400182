import { createReducer, on } from '@ngrx/store';

import {
  loadGlossary,
  loadGlossaryError,
  loadGlossarySuccess,
} from './glossary.actions';
import { glossaryProcess } from './glossary.reducer.utils';
import { initialState } from './glossary.state';

export const glossaryReducer = createReducer(
  initialState,
  on(loadGlossary, (state, { url }) => ({
    ...state,
    [url]: { ...state[url], loading: true, error: undefined },
  })),
  on(loadGlossarySuccess, (state, { url, glossaryDocument }) => ({
    ...state,
    [url]: {
      glossary: { ...glossaryProcess(glossaryDocument, url) },
      loading: false,
    },
  })),
  on(loadGlossaryError, (state, { url, error }) => ({
    ...state,
    [url]: { ...state[url], loading: false, error },
  })),
);
