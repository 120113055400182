import { Injectable, Renderer2 } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class EpubIframeStylesTransformer implements Transformer {
  constructor(private readonly renderer: Renderer2) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // always add the Reader X classname to the body element for styles to work
    this.addReaderXClassnameToBody(content);

    // always inject the compiled styles into the epub iframe
    this.injectEpubIframeStylesheet(content);

    // continue the promise chain
    return await next(content);
  }

  private addReaderXClassnameToBody(content: HTMLDocument): void {
    this.renderer.addClass(content.querySelector('body'), 'rdrx');
  }

  private injectEpubIframeStylesheet(content: HTMLDocument): void {
    // check if styles are already injected
    const existingStyle = content.querySelector<HTMLLinkElement>(
      'link#epub-iframe-styles',
    );
    if (existingStyle) {
      return;
    }

    // create a link to the stylesheet
    const linkStylesheet = content.createElement('link');
    linkStylesheet.id = 'epub-iframe-styles';
    linkStylesheet.rel = 'stylesheet';
    linkStylesheet.type = 'text/css';

    // the bazel build puts a preload link in the app-level head
    // we can use that to get a composed URL with the generated cache buster too
    const appLevelLink = document.querySelector<HTMLLinkElement>(
      'link[href*="epub-iframe-styles"]',
    );

    // accessing href as an element property yields an absolute url
    // accessing href as an element attribute yields the relative value in the source code

    // inside epub-iframe the ePUB content loads from a different domain, so we need
    // to reference the stylesheet from the app's domain
    linkStylesheet.href = appLevelLink?.href as string;

    // add the inline styles to the epub-iframe head
    const cloIframeHead = content.querySelector('head');
    this.renderer.appendChild(cloIframeHead, linkStylesheet);
  }
}
