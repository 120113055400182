<ng-content></ng-content>
<!-- TODO(i18n)-->
<div class="buttons">
  <ng-content select="[other-buttons]"></ng-content>
  <button
    *ngIf="withClose"
    mhe-button
    (click)="closeClick()"
    [purpose]="ButtonPurpose.Icon"
    class="close-button"
    data-automation-id="close-modal"
    aria-label="close modal"
    [enableLight]="true"
  >
    <i class="ahe-icon dpg-icon-system-close-white"></i>
  </button>
</div>
