import { ApiAnnotation } from '@mhe/reader/models';
import { createAction, props } from '@ngrx/store';

export const trackAnnotationChagnes = createAction(
  '[Analytics] Annotation changes',
  props<{ annotation: ApiAnnotation, prevAnnotation?: ApiAnnotation }>(),
);

export const trackAnnotationDeleted = createAction(
  '[GA] Annotation deleted',
  props<{ annotation: ApiAnnotation }>(),
);

export type AnalyticsActions = ReturnType<
  typeof trackAnnotationChagnes | typeof trackAnnotationDeleted
>;
