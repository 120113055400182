import { createAction } from '@ngrx/store';
import { FeatureFlags, ReaderEnvironmentConfig, RuntimeConfiguration } from '@mhe/reader/models';

export const SetAllRuntimeFeatures = createAction(
  '[Reader][Configuration] Set runtime configuration',
  (config: RuntimeConfiguration) => ({ config }),
);

export const SetAllFeatureFlags = createAction(
  '[Reader][Configuration] Set all feature flags',
  (features: FeatureFlags) => ({ features }),
);

export const SetAllEnvironmentConfig = createAction(
  '[Reader][Configuration] Set all environment config',
  (environment: ReaderEnvironmentConfig) => ({ environment }),
);

export const SetPartialRuntimeFeatures = createAction(
  '[Reader][Configuration] Set partial runtime configuration',
  (config: Partial<RuntimeConfiguration>) => ({ config }),
);

export const SetPartialFeatureFlags = createAction(
  '[Reader][Configuration] Set partial feature flags',
  (features: Partial<FeatureFlags>) => ({ features }),
);

export const SetPartialEnvironmentConfig = createAction(
  '[Reader][Configuration] Set partial environment configuration',
  (environment: Partial<ReaderEnvironmentConfig>) => ({ environment }),
);
