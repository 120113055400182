import {
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Renderer2 } from '@angular/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, finalize, first } from 'rxjs/operators';
import { FloatingTTSControlsComponent } from './floating-tts-controls.component';
import { OVERLAY_REF, TTS_REQUEST } from './floating-tts-controls.tokens';
import { TTSStore } from '../../state/tts.store';
import { TTSFloatingTextRequest } from '@mhe/reader/models';
import { DeviceService } from '@mhe/reader/common';

@Injectable()
export class FloatingTtsControlsService {
  constructor(
    private readonly renderer: Renderer2,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly ttsStore: TTSStore,
    private readonly deviceService: DeviceService,
  ) {}

  openContextReadspeaker(
    showAbove: boolean,
    ePubIframe: HTMLIFrameElement,
    ttsRequest: TTSFloatingTextRequest,
  ): Observable<any> {
    // create floating anchor node
    const anchorNode = this.createAnchorNode(
      ttsRequest.location.x,
      ttsRequest.location.y,
    );

    this.renderer.appendChild(
      ePubIframe.contentDocument?.querySelector('body'),
      anchorNode,
    );

    const positionStrategy = this.setupPositionStrategy(
      anchorNode,
      showAbove,
      ePubIframe,
    );
    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: true,
    });

    const tokens = this.createTokenMap(overlayRef, ttsRequest);
    const portal = this.createComponentPortal(tokens);

    overlayRef.attach(portal);
    positionStrategy.apply();

    // no resize event handling because the anchor position is floating/absolute
    // if there is a resize event the user will need to close and re-open the view
    // this is the same as in legacy, it is part of the nature of a floating anchor

    // handle closing
    const onClose$: Observable<any> = this.close$(overlayRef).pipe(
      finalize(() => {
        // do not keep this in the store for reuse
        this.ttsStore.setAudioChunks({
          key: ttsRequest.id,
          chunkCollection: undefined,
        });

        // remove the overlay and floating anchor
        overlayRef.detach();
        anchorNode.remove();
      }),
    );

    return combineLatest([onClose$]);
  }

  // Close the modal on backdrop click, esc key and any other detatchments from elsewhere (like in the component we've launched)
  private close$(overlayRef: OverlayRef): Observable<any> {
    return merge(
      overlayRef.backdropClick(),
      overlayRef
        .keydownEvents()
        .pipe(filter((event) => event.key === 'Escape')),
      overlayRef.detachments(),
    ).pipe(first());
  }

  // Create an anchor node that will be used for the overlay to be "connected" to
  private createAnchorNode(left: number, top: number): HTMLSpanElement {
    const anchorNode: HTMLSpanElement = this.renderer.createElement('span');

    this.renderer.setStyle(anchorNode, 'position', 'absolute');
    this.renderer.setStyle(anchorNode, 'left', left + 'px');
    this.renderer.setStyle(anchorNode, 'top', top + 'px');

    this.renderer.setStyle(anchorNode, 'backgroundColor', 'transparent');
    this.renderer.setStyle(anchorNode, 'width', '1px');
    this.renderer.setStyle(anchorNode, 'height', '1px');

    return anchorNode;
  }

  // Use the flexibleConnectedTo position strategy to open the overlay in relation to the beginning or end of the selection (where the anchor node is inserted)
  private setupPositionStrategy(
    anchorNode: HTMLElement,
    showAbove: boolean,
    iframe: HTMLIFrameElement,
  ): FlexibleConnectedPositionStrategy {
    const defaultPanelClass = 'annotations-overlay-panel';
    const anchorHeight = anchorNode.getBoundingClientRect().height;

    const anchorOffset = showAbove ? anchorHeight * -1 : anchorHeight;
    const offsetY = iframe.getBoundingClientRect().top + anchorOffset;
    const offsetX = iframe.getBoundingClientRect().left;
    const panelClass = showAbove
      ? [defaultPanelClass, `${defaultPanelClass}-arrow-down`]
      : [defaultPanelClass, `${defaultPanelClass}-arrow-up`];

    const overlayY = showAbove ? 'bottom' : 'top';

    const origin = this.getOrigin(anchorNode, anchorHeight);

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withViewportMargin(0)
      .withPush()
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY,
          panelClass,
          offsetY,
          offsetX,
        },
      ]);

    return positionStrategy;
  }

  private createTokenMap(
    overlayRef: OverlayRef,
    ttsRequest: Partial<TTSFloatingTextRequest>,
  ): WeakMap<InjectionToken<any>, any> {
    return new WeakMap<InjectionToken<any>, any>([
      [OVERLAY_REF, overlayRef],
      [TTS_REQUEST, ttsRequest],
    ]);
  }

  // Add the overlay reference to the injector so the launched component can leverage it to close. Much like DialogRef
  private createComponentPortal(
    tokens: WeakMap<InjectionToken<any>, any>,
  ): ComponentPortal<FloatingTTSControlsComponent> {
    const portalInjector = new PortalInjector(this.injector, tokens);
    return new ComponentPortal(
      FloatingTTSControlsComponent,
      null,
      portalInjector,
    );
  }

  getOrigin(
    anchorNode: HTMLElement,
    anchorHeight: number,
  ): HTMLElement | FlexibleConnectedPositionStrategyOrigin {
    if (this.deviceService.browserName === 'Firefox') {
      return {
        x: anchorNode.getBoundingClientRect().x,
        y: anchorNode.getBoundingClientRect().y,
        width: 0,
        height: anchorHeight,
      };
    }

    return anchorNode;
  }
}
