import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as errorsQuery from '@mhe/reader/global-store/errors/errors.selectors';
import * as errorsActions from '@mhe/reader/global-store/errors/errors.actions';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';

/**
 * A set of utils monitor & enforce reduced mode. Reduced mode is
 * when reader has launched without an active session.
 *
 * Supplementary features include: Last Location, Widgets, Notes, Placemarks,
 * Annotations and the context modal.
 */
@Injectable()
export class ReducedModeUtils {
  constructor(
    private readonly store: Store,
    private readonly readerConfigStore: ReaderConfigStore,
  ) {}

  /**
   * A wrapper for certain http requests to monitor for session ending events.
   * This method assumes the app has token (interceptor) logic that will
   * attempt to obtain a valid token for every request.  Once a request fails
   * with a 401, then we know that a token could not be obtained and the
   * user's session must have ended.
   *
   * When a session has ends, all http request that use this utility will
   * not get made (because they are expected to fail).
   *
   * Sessions can end in two scenarios:
   * * the user's token cannot be renewed (unexpectedly)
   * * lti launched with custom_readerx_session=false
   *
   * @example
   * this.reducedModeUtils.monitorSessionEnded(() => {
   *   return this.http.get('/my/session/resource');
   * }).pipe(...);
   */
  monitorSessionEnded<T>(httpCall: () => Observable<T>): Observable<T> {
    return combineLatest([
      this.store.pipe(select(errorsQuery.hasSessionEnded)),
      this.readerConfigStore.sessionDisabled$,
    ]).pipe(
      first(), // first here so this observable is "cold" like http calls
      mergeMap(([hasSessionEnded, isSessionDisabled]) => {
        if (hasSessionEnded) {
          return throwError(
            new SessionEndedError('Session has ended, skipping http call.'),
          );
        } else if (isSessionDisabled) {
          return throwError(
            new SessionDisabledError('Session is disabled, skipping http call.'),
          );
        } else {
          return httpCall().pipe(
            catchError((error) => {
              // This error is emitted after the token interceptor, so this
              // error could be from the token renewal request or the httpCall
              // request. Each app has their own token interceptor and api, so
              // we need to catch the error here for this mechanism to work
              // when reader will be integrated as a component.
              //
              // Below we assume that all token renewal endpoints will respond
              // with a 401 when token cannot be renewed (a session has ended).
              if (error instanceof HttpErrorResponse && error.status === 401) {
                // Our request failed because the token interceptor failed
                // to renew our token, so the session has ended.
                this.store.dispatch(errorsActions.sessionEnded());
              }
              return throwError(error);
            }),
          );
        }
      }),
    );
  }
}

export class SessionEndedError {
  constructor(public message?: string) {}
}

export class SessionDisabledError {
  constructor(public message?: string) {}
}
