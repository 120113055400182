export interface GoogleAnalyticsTimingEvent {
  timingCategory: string // A string for categorizing all user timing variables into logical groups (e.g. 'JS Dependencies').
  timingVar: string // A string to identify the variable being recorded (e.g. 'load').f
  timingValue: number // The number of milliseconds in elapsed time to report to Google Analytics (e.g. 20).
  timingLabel?: string // A string that can be used to add flexibility in visualizing user timings in the reports (e.g. 'Google CDN').
}

export const isGoogleAnalyticsTimingEvent = (e: any): e is GoogleAnalyticsTimingEvent => {
  return 'timingCategory' in e && 'timingVar' in e;
};
