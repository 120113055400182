<div id="placeholderContent">
  <div class="timeline-wrapper">
    <div class="timeline-item">
      <div class="animated-background">
        <div class="header-container"></div>
        <div class="paragraph-container">
          <div class="background-masker content-top"></div>
          <div class="background-masker content-first-end"></div>
          <div class="background-masker content-second-line"></div>
          <div class="background-masker content-second-end"></div>
          <div class="background-masker content-third-line"></div>
          <div class="background-masker content-third-end"></div>
          <div class="background-masker content-fourth-line"></div>
          <div class="background-masker content-fourth-end"></div>
          <div class="background-masker content-bottom"></div>
        </div>

        <div class="header-container"></div>

        <div class="paragraph-container">
          <div class="background-masker content-top"></div>
          <div class="background-masker content-first-end"></div>
          <div class="background-masker content-second-line"></div>
          <div class="background-masker content-second-end"></div>
          <div class="background-masker content-third-line"></div>
          <div class="background-masker content-third-end"></div>
          <div class="background-masker content-fourth-line"></div>
          <div class="background-masker content-fourth-end"></div>
          <div class="background-masker content-bottom"></div>
        </div>

        <div class="header-container"></div>

        <div class="paragraph-container">
          <div class="background-masker content-top"></div>
          <div class="background-masker content-first-end"></div>
          <div class="background-masker content-second-line"></div>
          <div class="background-masker content-second-end"></div>
          <div class="background-masker content-third-line"></div>
          <div class="background-masker content-third-end"></div>
          <div class="background-masker content-fourth-line"></div>
          <div class="background-masker content-fourth-end"></div>
          <div class="background-masker content-bottom"></div>
        </div>
      </div>
    </div>
  </div>
</div>
