import {
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Renderer2 } from '@angular/core';
import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  first,
  map,
  startWith,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationsContextMenuComponent } from '../annotations-context-menu.component';
import { AnnotationsContextMenuConfig } from '../annotations-context-menu.model';
import {
  ANNOTATION,
  ANNOTATIONS_CONTEXT_MENU_CONFIG,
  OVERLAY_REF,
} from '../annotations-context-menu.tokens';
import { AnnotationsContextMenuStore } from '../state/annotations-context-menu.store';
import { DeviceService } from '@mhe/reader/common';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

When this is opened all the way at the edge, it can possibly open outside of the host component.  This is because
  the FlexibleConnectedPositionStrategy uses the document as it's bounding container.  I attempted to initialize
  a strategy manually, like so:
  new FlexibleConnectedPositionStrategy(anchorNode, iframeDoc, ...)
  I was able to make this work consistently (and nicer than it currently works) when overlayY was 'top' (meaning
  the positioning is measured from the top) but when overlayY is 'bottom', the positioning was messed up by
  amounts varying depending on how wide the window was.  I think we could solve this if we wanted to create our own
  position strategy, but that seemed like a time-sink given our needs right now - thus I'm leaving a note.
  ALWAYS leave a note. - J Walter Weatherman

The arrow shown above or below the overlay is fixed to the center of the overlay.  When you open the overlay near
  the edges, the arrow may not point to the beginning or end of the selection as expected because the width of the
  overlay hits the edge of the container

 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

@Injectable()
export class AnnotationsOverlayService {
  private readonly _forcedClose$ = new Subject<void>();

  constructor(
    private readonly renderer: Renderer2,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly annotationStore: AnnotationsContextMenuStore,
    private readonly deviceService: DeviceService,
  ) {}

  openContextMenu(
    annotationSeed: Partial<ApiAnnotation>,
    showAbove: boolean,
    range: Range,
    iframe: HTMLIFrameElement,
    config: AnnotationsContextMenuConfig = {
      highlights: true,
      placemarks: true,
      notes: true,
      readspeaker: true,
      isAiAssistOffered: false,
    },
  ): Observable<ApiAnnotation> {
    const anchorNode = this.createAnchorNode();
    this.insertAnchorNode(range, anchorNode, showAbove);

    const positionStrategy = this.setupPositionStrategy(
      anchorNode,
      showAbove,
      iframe,
    );

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: true,
      backdropClass: 'annotation-context-menu-backdrop',
    });

    const tokens = this.createTokenMap(overlayRef, annotationSeed, config);
    const portal = this.createComponentPortal(tokens);

    overlayRef.attach(portal);
    positionStrategy.apply();

    const updatePositionStrategy$: Observable<any> = fromEvent(
      iframe?.ownerDocument?.defaultView as any,
      'resize',
    ).pipe(
      takeUntil(overlayRef.detachments()),
      startWith({}),
      debounceTime(100),
      tap(() => {
        const newPositionStrategy = this.setupPositionStrategy(
          anchorNode,
          showAbove,
          iframe,
        );
        overlayRef.updatePositionStrategy(newPositionStrategy);
      }),
    );

    const onClose$: Observable<ApiAnnotation> = this.close$(overlayRef).pipe(
      withLatestFrom(this.annotationStore.annotation$),
      map(([, annotation]) => annotation),
      finalize(() => {
        overlayRef.detach();
        anchorNode.remove();
      }),
    );
    return combineLatest([updatePositionStrategy$, onClose$]).pipe(
      map(([, close]: [any, ApiAnnotation]) => close),
      filter((annotation) => !!annotation),
    );
  }

  // Close the modal on backdrop click, esc key and any other detatchments from elsewhere (like in the component we've launched)
  private close$(overlayRef: OverlayRef): Observable<any> {
    return merge(
      overlayRef.backdropClick(),
      overlayRef
        .keydownEvents()
        .pipe(filter((event) => event.key === 'Escape')),
      overlayRef.detachments(),
      this._forcedClose$,
    ).pipe(first());
  }

  close(): void {
    this._forcedClose$.next();
  }

  // Create an anchor node that will be used for the overlay to be "connected" to
  private createAnchorNode(): HTMLSpanElement {
    const anchorNode: HTMLSpanElement = this.renderer.createElement('span');

    return anchorNode;
  }

  // Use the flexibleConnectedTo position strategy to open the overlay in relation to the beginning or end of the selection (where the anchor node is inserted)
  private setupPositionStrategy(
    anchorNode: HTMLElement,
    showAbove: boolean,
    iframe: HTMLIFrameElement,
  ): FlexibleConnectedPositionStrategy {
    const defaultPanelClass = 'annotations-overlay-panel';
    const anchorHeight = anchorNode.getBoundingClientRect().height;

    const anchorOffset = showAbove ? anchorHeight * -1 : anchorHeight;
    const offsetY = iframe.getBoundingClientRect().top + anchorOffset;
    const offsetX = iframe.getBoundingClientRect().left;
    const panelClass = showAbove
      ? [defaultPanelClass, `${defaultPanelClass}-arrow-down`]
      : [defaultPanelClass, `${defaultPanelClass}-arrow-up`];

    const overlayY = showAbove ? 'bottom' : 'top';

    const origin = this.getOrigin(anchorNode, anchorHeight);

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withViewportMargin(0)
      .withPush()
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY,
          panelClass,
          offsetY,
          offsetX,
        },
      ]);

    return positionStrategy;
  }

  private createTokenMap(
    overlayRef: OverlayRef,
    annotation: Partial<ApiAnnotation>,
    config: AnnotationsContextMenuConfig,
  ): WeakMap<InjectionToken<any>, any> {
    return new WeakMap<InjectionToken<any>, any>([
      [OVERLAY_REF, overlayRef],
      [ANNOTATION, annotation],
      [ANNOTATIONS_CONTEXT_MENU_CONFIG, config],
    ]);
  }

  // Add the overlay reference to the injector so the launched component can leverage it to close. Much like DialogRef
  private createComponentPortal(
    tokens: WeakMap<InjectionToken<any>, any>,
  ): ComponentPortal<AnnotationsContextMenuComponent> {
    const portalInjector = new PortalInjector(this.injector, tokens);
    return new ComponentPortal(
      AnnotationsContextMenuComponent,
      null,
      portalInjector,
    );
  }

  // Insert the anchor node at the beginning or end of the selection
  private insertAnchorNode(
    range: Range,
    anchorNode: HTMLElement,
    toStart: boolean,
  ): void {
    const rangeCopy = range.cloneRange();
    rangeCopy.collapse(toStart);
    rangeCopy.insertNode(anchorNode);
  }

  getOrigin(
    anchorNode: HTMLElement,
    anchorHeight: number,
  ): HTMLElement | FlexibleConnectedPositionStrategyOrigin {
    if (this.deviceService.browserName === 'Firefox') {
      return {
        x: anchorNode.getBoundingClientRect().x,
        y: anchorNode.getBoundingClientRect().y,
        width: 0,
        height: anchorHeight,
      };
    }

    return anchorNode;
  }
}
