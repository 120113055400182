import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { AllowedInterfaceMode, AllowedNavigationType } from '../types';

@Component({
  selector: 'reader-core-previous-button',
  templateUrl: './previous-button.component.html',
  styleUrls: ['./previous-button.component.scss'],
})
export class PreviousButtonComponent {
  @Input() navigationType: AllowedNavigationType = 'paginate';
  @Input() disabled = false;
  @Input() interfaceMode: AllowedInterfaceMode | null = null;
  @Input() chapterLabel: string;

  @Output() reverse = new EventEmitter();

  // expose import ref
  ButtonPurpose = ButtonPurpose;

  internalReverse(): void {
    this.reverse.emit();
  }
}
