import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

import { ButtonPurpose } from '@mhe/ngx-shared';

import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { ReferenceModalData } from './reference-modal.model';
import { ReferenceModalService } from './reference-modal.service';

@Component({
  selector: 'rdrx-reference-modal',
  templateUrl: './reference-modal.component.html',
  styleUrls: ['./reference-modal.component.scss'],
  providers: [
    TransformStore,
    ReferenceModalService,
  ],
})
export class ReferenceModalComponent implements OnInit {
  readonly title: string;
  readonly hrefUrl: string;
  readonly href: string;
  safeHrefUrl: SafeResourceUrl;

  readonly ButtonPurpose = ButtonPurpose;

  constructor(
  @Inject(MAT_DIALOG_DATA) { title, href, hrefUrl }: ReferenceModalData,
    public readonly matDialogRef: MatDialogRef<ReferenceModalComponent>,
    public readonly transformStore: TransformStore,
    public readonly referenceModalService: ReferenceModalService,
  ) {
    this.title = title;
    this.href = href;
    this.hrefUrl = hrefUrl;
  }

  ngOnInit(): void {
    this.safeHrefUrl = this.getSafeHrefUrl();
  }

  getSafeHrefUrl(): SafeResourceUrl {
    return this.referenceModalService.getSafeResourceUrl(this.hrefUrl);
  }

  handleClick(anchor: HTMLAnchorElement): void {
    this.matDialogRef.close(transformActions.anchorClick({ anchor }));
  }
}
