import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[rdrxTruncate]',
})
export class TruncateDirective implements AfterViewInit, OnDestroy {
  @Input('rdrxTruncate') truncateLimit;
  // TODO check this after ng15 migration
  /* eslint-disable @angular-eslint/no-output-rename */
  @Output('text') text: EventEmitter<string> = new EventEmitter<string>();

  private originalInput: string;
  private truncatedInput: string;
  private truncated = false;
  private showMoreLink: HTMLAnchorElement;

  private instanceId: string;

  private readonly unlisteners: Array<() => void> = [];

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    const element: HTMLElement = this.el.nativeElement;
    this.instanceId = element.id;

    this.originalInput = element.innerHTML;
    this.truncatedInput = this.originalInput.slice(
      0,
      this.truncateLimit || 100,
    );

    if (this.originalInput !== this.truncatedInput) {
      this.setupShowMoreLink();
      this.toggleTruncated();
      element.insertAdjacentElement('afterend', this.showMoreLink);
    } else {
      this.text.emit(this.originalInput);
    }
  }

  ngOnDestroy(): void {
    this.unlisteners.forEach((u) => u());
  }

  setupShowMoreLink(): void {
    this.showMoreLink = this.renderer.createElement('a');
    this.renderer.setAttribute(
      this.showMoreLink,
      'href',
      'javascript:void(0);',
    );
    this.renderer.setAttribute(
      this.showMoreLink,
      'aria-expanded',
      this.truncated ? 'true' : 'false',
    );
    if (this.instanceId) {
      this.renderer.setAttribute(
        this.showMoreLink,
        'aria-controls',
        this.instanceId,
      );
    }

    this.renderer.setStyle(this.showMoreLink, 'display', 'block');
    this.renderer.addClass(this.showMoreLink, 'ahe-ui-link');
    this.renderer.addClass(this.showMoreLink, 'rdrx-truncate-toggle');

    ['click', 'keydown.Enter', 'keydown.Space'].forEach((keyboardEvent) => {
      this.unlisteners.push(
        this.renderer.listen(
          this.showMoreLink,
          keyboardEvent,
          (event: Event) => {
            event.stopPropagation();
            this.ngZone.run(() => this.toggleTruncated());
          },
        ),
      );
    });
  }

  toggleTruncated(): void {
    const element: HTMLElement = this.el.nativeElement;
    this.truncated = !this.truncated;
    if (this.truncated) {
      element.innerText = `${this.truncatedInput}...`;
      this.showMoreLink.innerText = 'Show More';
      this.text.emit(`${this.truncatedInput}...`);
      this.renderer.setAttribute(this.showMoreLink, 'aria-expanded', 'false');
    } else {
      element.innerText = this.originalInput;
      this.showMoreLink.innerText = 'Show Less';
      this.text.emit(this.originalInput);
      this.renderer.setAttribute(this.showMoreLink, 'aria-expanded', 'true');
    }
  }
}
