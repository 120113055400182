import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reader-ui-internal-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  template: `
    <div class="error-page-container">
      <div>
        <div class="error-code">
          <span>500</span>
        </div>
        <div>
          <section>
            <h1>{{ 'errorPage.whoops' | translate }}</h1>
            <h3>{{ 'errorPage.500.title' | translate }}</h3>
          </section>
          <section>
            <h3>{{ 'errorPage.reason' | translate }}</h3>
            <ul [innerHTML]="'errorPage.500.reason_list' | translate"></ul>
          </section>
          <section>
            <h3>{{ 'errorPage.try' | translate }}</h3>
            <ul [innerHTML]="'errorPage.500.try_list' | translate"></ul>
          </section>
          <ng-container *ngIf="queryParams$ | async as queryParams">
            <section class="metadata">
              <!-- Metadata -->
              <ul *ngFor="let param of queryParams | keyvalue">
                <li>{{ param.key }}: {{ param.value }}</li>
              </ul>
            </section>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent {
  private readonly activatedRoute = inject(ActivatedRoute);

  queryParams$ = this.activatedRoute.queryParams;
}
