import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '@mhe/reader/core/reader-api/log.service';

import { ReaderStore } from '../components/reader/state';
import { log } from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';

@Injectable()
export class LoggingMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly mediatorUtils: MediatorUtils,
    private readonly logService: LogService,
  ) {
    super();
  }

  /** action effects */
  private readonly log$ = this.effect(() => {
    return this.readerActions$.pipe(
      ofType(log),
      map(({ payload }) => payload),
      withLatestFrom(this.mediatorUtils.readerApi$, this.mediatorUtils.requestContext$),
      mergeMap(([payload, readerApi, requestContext]) =>
        this.logService.log(readerApi, {
          ...payload,
          ...requestContext,
        }),
      ),
      logCatchError('log$'),
    );
  });
}
