import { Injectable, Renderer2 } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class AriaTransformer implements Transformer {
  constructor(private readonly renderer: Renderer2) {}

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    return await next(content);
  }
}
