import { Injectable } from '@angular/core';

interface MockAuthServiceLtiContext {
  custom_preview_mode: boolean | string
  roles: string
  custom_assessment_lti_post: string
  custom_assessment_lti_post_launch_params: string
  custom_assignment: any
}

/**
 * Mocking out the AUTH service until it gets built.
 * The interface will probably change once implemented.
 */
@Injectable()
export class MockAuthService {
  public ltiContext: MockAuthServiceLtiContext = {
    custom_preview_mode: false,
    roles: 'Student',
    custom_assessment_lti_post: '',
    custom_assessment_lti_post_launch_params: '',
    custom_assignment: null,
  };

  isAuthoring(): boolean {
    return false;
  }
}
