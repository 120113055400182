import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

// eslint-disable-next-line
@Directive({ selector: '[mheStopPropagation]' })
export class StopPropagationDirective implements OnInit, OnDestroy {
  @Input('mheStopPropagation') on: string | string[];
  private listeners: Array<() => void>;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    const { nativeElement } = this.el;
    this.on = this.on || ['click'];

    const events = this.mapEvents(this.on);

    this.listeners = events.map((event) =>
      this.renderer.listen(nativeElement, event, (e) => e.stopPropagation()),
    );
  }

  ngOnDestroy(): void {
    this.listeners.forEach((unlisten) => unlisten());
  }

  private mapEvents(events: string | string[]): string[] {
    const isArray = Array.isArray(events);

    if (isArray) return events as string[];

    events = this.mapCsv(events as string);
    return events;
  }

  private mapCsv(events: string): string[] {
    let arrEvents: string[];

    if (events.includes(',')) {
      const arr = events.split(',');
      arrEvents = arr.map((e) => e.trim());
    } else {
      arrEvents = [events];
    }

    return arrEvents;
  }
}
