import { Component, Input } from '@angular/core';
import { HighlightColor } from '../annotations-context-menu.model';

type IColorsIcon = {
  [color in HighlightColor]: {
    fill: string
    border: string
  };
};

// Duplicated Component in Highlight Icon
// TODO: We need a generic icon component to evoid duplication
@Component({
  selector: 'rdrx-color-icon',
  templateUrl: './color-icon.component.html',
  styleUrls: ['./color-icon.component.scss'],
})
export class ColorIconComponent {
  @Input() color: HighlightColor;
  @Input() selected = false;

  get title(): string {
    return `${this.color} highlight`;
  }

  get border(): string {
    return this.colors[this.color]?.border;
  }

  get fill(): string {
    return this.colors[this.color]?.fill;
  }

  private readonly colors: IColorsIcon = {
    default: {
      fill: '#FFF19A',
      border: '#C5871B',
    },
    yellow: {
      fill: '#FFF19A',
      border: '#C5871B',
    },
    green: {
      fill: '#CFFB54',
      border: '#729F41',
    },
    blue: {
      fill: '#BDD7FC',
      border: '#109BE0',
    },
    pink: {
      fill: '#FFBEDC',
      border: '#FF52A3',
    },
    none: {
      fill: '#FFFFFF',
      border: '#AEAEAE',
    },
  };
}
