export enum AnchorType {
  GLOSSARY = 'glossref',
  FOOTNOTE = 'noteref',
  MAILTO_LINK = 'mailto',
  TEL_LINK = 'tel',
  INTERNAL_LINK = 'internal_link',
  EXTERNAL_LINK = 'external_link',
  LARGE_IMAGE = 'large_image',
  LONG_DESCRIPTION = 'long_description',
  REFERENCE_LINK = 'reference_link',
  CREDIT_LINK = 'credit_link',
}

export function isAnchorType(subject: any): subject is AnchorType {
  return Object.values(AnchorType).includes(subject);
}
