import { NgModule } from '@angular/core';
import { ElasticSearchComponent } from './elastic-search.component';
import { BreadcrumbsDescriptionPipe } from './pipes/breadcrumbs-description.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { ButtonModule } from '@mhe/ngx-shared';
import { NgxBonsaiModule } from '@mhe/ngx-bonsai-reader';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
  ],
  declarations: [ElasticSearchComponent, BreadcrumbsDescriptionPipe],
  exports: [ElasticSearchComponent],
})
export class ElasticSearchModule {
}
