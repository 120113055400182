import { Injectable, OnDestroy, Renderer2 } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GifTransformer implements Transformer, OnDestroy {
  constructor(
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService,
  ) { }

  unlisteners: Array<() => void> = [];

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: Document,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<any> {
    this.resetUnlisteners();

    const gifs = this.getGifs(content);

    if (gifs.length === 0) {
      return await next(content);
    }

    this.adjustContentZindex(content);

    gifs.forEach((gif) => {
      this.setUpGifPlayer(gif);
    });

    return await next(content);
  }

  resetUnlisteners(): void {
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unlisteners = [];
  }

  getGifs(content: Document): HTMLImageElement[] {
    const images: HTMLImageElement[] = Array.from(
      content.querySelectorAll('img'),
    );

    return images.filter((image) => image.src?.endsWith('.gif'));
  }

  /*
    For chapter opener GIFs, the surrounding content has z-indexes that intefere with the
    ability to hover and select the GIF. This method adjusts those z-indexes with no apparent
    impact on the UI.
  */
  adjustContentZindex(content: Document): void {
    const wrapperGif = content.querySelector('.wrapper-gif');
    const chapterHeading = content.querySelector('.ch_h1');

    if (wrapperGif) {
      this.renderer.addClass(wrapperGif, 'no-z-index');
    }

    if (chapterHeading) {
      this.renderer.addClass(chapterHeading, 'no-z-index');
    }
  }

  setUpGifPlayer(gif: HTMLImageElement): any {
    const figure = gif?.parentNode as HTMLImageElement;
    const wrapper = this.createWrapper(gif);
    const pauseButton = this.createPauseButton(gif);
    const playButton = this.createPlayButton(gif);
    const still = this.createStill(gif);

    this.renderer.setAttribute(gif, 'data-automation-id', 'gif');

    this.unlisteners.push(
      this.renderer.listen(pauseButton, 'click', () => {
        this.pauseGif(gif, still, pauseButton, playButton);
      }),
      this.renderer.listen(gif, 'click', () => {
        this.pauseGif(gif, still, pauseButton, playButton);
      }),
      this.renderer.listen(playButton, 'click', () => {
        this.playGif(gif, still, pauseButton, playButton);
      }),
      this.renderer.listen(still, 'click', () => {
        this.playGif(gif, still, pauseButton, playButton);
      }),
    );

    this.renderer.appendChild(wrapper, pauseButton);
    this.renderer.appendChild(wrapper, playButton);
    this.renderer.appendChild(wrapper, gif);
    this.renderer.appendChild(wrapper, still);

    figure.appendChild(wrapper);
  }

  createWrapper(gif: HTMLImageElement): HTMLDivElement {
    const wrapper = this.renderer.createElement('div');

    if (gif.classList.contains('fb-sci')) {
      // This corrects styles from the class above that distorted the GIF and its still image
      this.renderer.addClass(gif, 'fb-sci-gif');
    }

    this.inheritClasses(gif, wrapper);

    this.renderer.addClass(wrapper, 'gif-wrapper');
    this.renderer.setAttribute(wrapper, 'data-automation-id', 'gif-wrapper');

    return wrapper;
  }

  createPauseButton({ id }: HTMLImageElement): HTMLDivElement {
    const pauseButton = this.renderer.createElement('button');

    this.renderer.setAttribute(pauseButton, 'role', 'button');
    this.renderer.setAttribute(pauseButton, 'tabindex', '0');
    this.renderer.addClass(pauseButton, 'gif-control-button');
    this.renderer.addClass(pauseButton, 'gif-pause-button');
    this.renderer.setAttribute(
      pauseButton,
      'aria-label',
      `${this.translateService.instant('gif.pause')}`,
    );
    this.renderer.setAttribute(
      pauseButton,
      'aria-describedby',
      `${id}`,
    );
    this.renderer.setAttribute(pauseButton, 'data-automation-id', 'gif-pause-button');

    return pauseButton;
  }

  pauseGif(
    gif: HTMLImageElement,
    still: HTMLCanvasElement,
    pauseButton: HTMLDivElement,
    playButton: HTMLDivElement,
  ): void {
    this.renderer.addClass(pauseButton, 'hidden');
    this.renderer.addClass(gif, 'hidden');
    this.renderer.removeClass(playButton, 'hidden');
    this.renderer.removeClass(still, 'hidden');

    playButton.focus();
  }

  createStill(gif: HTMLImageElement): HTMLCanvasElement {
    const still = this.renderer.createElement('canvas');
    const { width, height, alt, id } = gif;

    this.inheritClasses(gif, still);
    this.renderer.addClass(still, 'hidden');
    still.setAttribute('role', 'image');
    still.setAttribute('alt', alt);
    still.setAttribute('id', id);
    still.setAttribute('data-automation-id', 'still');
    still.width = width;
    still.height = height;
    still.getContext('2d')?.drawImage(gif, 0, 0, width, height);

    return still;
  }

  inheritClasses(originator: HTMLElement, inheritor: HTMLElement): void {
    const classes = originator.getAttribute('class') ?? '';

    inheritor.setAttribute('class', classes);
  }

  createPlayButton({ id }: HTMLImageElement): HTMLDivElement {
    const playButton = this.renderer.createElement('button');

    this.renderer.addClass(playButton, 'hidden');
    this.renderer.addClass(playButton, 'gif-control-button');
    this.renderer.addClass(playButton, 'gif-play-button');
    this.renderer.setAttribute(playButton, 'role', 'button');
    this.renderer.setAttribute(playButton, 'tabindex', '0');
    this.renderer.setAttribute(
      playButton,
      'aria-label',
      `${this.translateService.instant('gif.play')}`,
    );
    this.renderer.setAttribute(
      playButton,
      'aria-describedby',
      `${id}`,
    );
    this.renderer.setAttribute(playButton, 'data-automation-id', 'gif-play-button');

    return playButton;
  }

  playGif(
    gif: HTMLImageElement,
    still: HTMLCanvasElement,
    pauseButton: HTMLDivElement,
    playButton: HTMLDivElement,
  ): void {
    this.renderer.addClass(playButton, 'hidden');
    this.renderer.addClass(still, 'hidden');
    this.renderer.removeClass(gif, 'hidden');
    this.renderer.removeClass(pauseButton, 'hidden');

    pauseButton.focus();
  }

  ngOnDestroy(): void {
    this.resetUnlisteners();
  }
}
