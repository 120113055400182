import { Injectable, Renderer2 } from '@angular/core';
import { Next, Transformer } from './transformer';
import { Book, SpineItem } from '@mhe/reader/models';
import { AnnotationCssService } from './annotation-css.service';

@Injectable()
export class AnnotationCssTransformer implements Transformer {
  constructor(
    private readonly renderer: Renderer2,
    private readonly annotationCss: AnnotationCssService,
  ) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const css = this.annotationCss.data.annotationCss;
    const style = content.createElement('style');
    style.id = 'annotation-css';
    style.textContent = css;
    const head = content.querySelector('head');
    this.renderer.appendChild(head, style);

    return await next(content);
  }
}
