import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { EpubControlsComponent } from './epub-controls.component';
import { TTSModule } from '@mhe/reader/components/text-to-speech';

@NgModule({
  imports: [CommonModule, ButtonModule, TranslateModule, TooltipModule, TTSModule],
  declarations: [EpubControlsComponent],
  exports: [EpubControlsComponent],
})
export class EpubControlsModule {}
