import { Pipe, PipeTransform } from '@angular/core';
import { SearchResult } from '@mhe/reader/models';

@Pipe({
  name: 'searchPageNumberResult',
})
export class SearchPageNumberResultPipe implements PipeTransform {
  transform(results: SearchResult[]): SearchResult {
    return results.filter((result) => result.isPageNumber)[0];
  }
}
