export enum K5_Icons {
  Bookmark = 'bookmark',
  Checkmark = 'checkmark',
  Highlighter = 'highlighter',
  Listview = 'listview',
  Notepad = 'notepad',
  SystemClose = 'system-close',
  SystemSearch = 'system-search',
  Topics = 'topics',
}
