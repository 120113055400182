import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DoubleSpineItem, SpineItem } from '@mhe/reader/models';
import { NavigationState, initialNavigationState } from './navigation.state';
import * as actions from './navigation.actions';

@Injectable()
export class NavigationStore extends ExtendedComponentStore<
NavigationState,
actions.NavigationActions
> {
  constructor() {
    super(initialNavigationState);
  }

  /** selectors */
  readonly index$ = this.select(({ index }) => index);
  readonly backToAssignmentButton$ = this.select(
    ({ backToAssignmentButton }) => backToAssignmentButton,
  );

  readonly rangeStartIndex$ = this.select(
    ({ rangeStartIndex }) => rangeStartIndex,
  );

  readonly rangeEndIndex$ = this.select(({ rangeEndIndex }) => rangeEndIndex);
  readonly isLastPage$ = this.select(({ isLastPage }) => isLastPage);
  readonly map$ = this.select(({ spineHashMap }) => spineHashMap);
  readonly maxIndex$ = this.select(({ maxIndex }) => maxIndex);

  // labels
  readonly previousChapterLabel$ = this.select(
    ({ previousChapterLabel }) => previousChapterLabel,
  );

  readonly nextChapterLabel$ = this.select(
    ({ nextChapterLabel }) => nextChapterLabel,
  );

  /** updaters */
  // index
  readonly setIndex = this.updater((state, index: number) => {
    if (!index || index < 0) {
      index = 0;
    }
    return { ...state, index };
  });

  readonly setBackToAssignmentButton = this.updater(
    (state, backToAssignmentButton: boolean) => {
      return { ...state, backToAssignmentButton };
    },
  );

  readonly setRangeStartIndex = this.updater(
    (state, rangeStartIndex: number) => {
      return { ...state, rangeStartIndex };
    },
  );

  readonly setRangeEndIndex = this.updater((state, rangeEndIndex: number) => {
    return { ...state, rangeEndIndex };
  });

  readonly setIsLastPage = this.updater((state, isLastPage: boolean) => {
    return { ...state, isLastPage };
  });

  readonly setMaxIndex = this.updater((state, maxIndex: number) => ({
    ...state,
    maxIndex,
  }));

  readonly setSpineHashMap = this.updater(
    (state, spineHashMap: Record<number, number>) => ({
      ...state,
      spineHashMap,
    }),
  );

  readonly setLabels = this.updater(
    (state, labels: { prevChapter: string, nextChapter: string }) => {
      const { prevChapter, nextChapter } = labels;
      return {
        ...state,
        previousChapterLabel: prevChapter,
        nextChapterLabel: nextChapter,
      };
    },
  );

  /** effects */
  private readonly init$ = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      tap(({ linearSpine, doubleSpine }) => {
        (doubleSpine as DoubleSpineItem[])?.length > 0
          ? this.initDoublePage$(doubleSpine as DoubleSpineItem[])
          : this.initSinglePage$(linearSpine as SpineItem[]);
      }),
      catchError(() => EMPTY),
    ),
  );

  private readonly initSinglePage$ = this.effect(
    (linearSpine$: Observable<SpineItem[]>) =>
      linearSpine$.pipe(
        tap((linearSpine) => this.setMaxIndex(linearSpine?.length - 1)),
        map((linearSpine) => this.mapLinearSpineToHashMap(linearSpine)),
        tap((hashMap) => this.setSpineHashMap(hashMap)),
        catchError(() => EMPTY),
      ),
  );

  private readonly initDoublePage$ = this.effect(
    (doubleSpine$: Observable<DoubleSpineItem[]>) =>
      doubleSpine$.pipe(
        tap((doubleSpine) => this.setMaxIndex(doubleSpine?.length - 1)),
        map((doubleSpine) => this.mapDoubleSpineToHashMap(doubleSpine)),
        tap((hashMap) => this.setSpineHashMap(hashMap)),
        catchError(() => EMPTY),
      ),
  );

  /** utils */
  private readonly mapLinearSpineToHashMap = (
    linearSpine: SpineItem[],
  ): Record<number, number> => {
    const spineHashMap = linearSpine?.reduce<Record<number, number>>(
      (acc, spine, i) => {
        const k = spine.index;
        acc = { ...acc, [k]: i };
        return acc;
      },
      {},
    );

    return spineHashMap;
  };

  private readonly mapDoubleSpineToHashMap = (
    doubleSpine: DoubleSpineItem[],
  ): Record<number, number> => {
    const spineHashMap = doubleSpine?.reduce<Record<number, number>>(
      (acc, spine, i) => {
        const kl = spine.left?.index;
        const kr = spine.right?.index;

        if (kl) acc = { ...acc, [kl]: kl };
        if (kr) acc = { ...acc, [kr]: kr };

        return acc;
      },
      {},
    );

    return spineHashMap;
  };
}
