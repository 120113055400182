import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { K5_Icons } from './k5-icons.enum';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
  ) {}

  registerK5Icons(): void {
    this.loadIcons(Object.values(K5_Icons), 'k5', './assets/icons/k5');
  }

  private loadIcons(
    iconKeys: string[],
    themeKey: string,
    iconUrl: string,
  ): void {
    iconKeys.forEach((iconKey) => {
      const sanitizedIconUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${iconUrl}/${iconKey}.svg?v=${environment.buildTimestamp}`,
      );
      this.matIconRegistry.addSvgIconInNamespace(
        themeKey,
        iconKey,
        sanitizedIconUrl,
      );
    });
  }
}
