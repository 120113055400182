import { createAction, props } from '@ngrx/store';
import { TTSAudioContext, TTSRawTextOptions } from '@mhe/reader/models';

export const play = createAction(
  'TTS: Play',
  props<{ playbackPosition?: number, context?: TTSAudioContext }>(),
);
export const resetPageAudio = createAction('TTS: Reset Page Audio');
export const pause = createAction(
  'TTS: Pause',
  props<{ silenceBeepSound?: boolean }>(),
);
export const togglePageTTSControls = createAction(
  'TTS: Toggle Doc TTS Controls',
  props<{ val: boolean }>(),
);
export const updateVolume = createAction(
  'TTS: Update Volume',
  props<{ val: number }>(),
);
export const updatePlaybackRate = createAction(
  'TTS: Update Playback Rate',
  props<{ val: number }>(),
);
export const disableScrollPauser = createAction('TTS: Disabling Scroll Pauser');
export const stopProgressReporting = createAction(
  'TTS: Stop Progress Reporting Periodically',
);

export const loadRawTextAudio = createAction(
  'Floating TTS Controls: Load Raw Text Audio',
  props<{ options: TTSRawTextOptions }>(),
);

export const resetVoice = createAction('TTS: Reset Voice');

export type TTSActions = ReturnType<
| typeof play
| typeof resetPageAudio
| typeof pause
| typeof togglePageTTSControls
| typeof updateVolume
| typeof updatePlaybackRate
| typeof disableScrollPauser
| typeof stopProgressReporting
| typeof loadRawTextAudio
| typeof resetVoice
>;
