/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-prototype-builtins */
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AssessmentParams, IFrameClientPosition } from './iframe.model';
import { MockAppConfigService } from './mockAppConfig.service';
import { MockAuthService } from './mockAuth.service';

interface IframeCoreServiceData {
  activeStylesheets: Map<string, string>
  activeStyles: Map<string, string>
  mathJaxOffset: number
  deferredIframes: any[]
  widgetsCollection: Record<string, string>
  linkType: string
  ready: boolean
  placeholderRemoved: boolean
}

@Injectable()
export class IframeCoreService {
  protected data: IframeCoreServiceData = {
    activeStylesheets: new Map<string, string>(),
    activeStyles: new Map<string, string>(),
    mathJaxOffset: 0,
    deferredIframes: [],
    widgetsCollection: {},
    linkType: 'follow',
    ready: false,
    placeholderRemoved: false,
  };

  private readonly document: Document;

  constructor(
  @Inject(DOCUMENT) document: any,
    private readonly mockAuthService: MockAuthService,
    private readonly mockAppConfig: MockAppConfigService,
    private readonly httpClient: HttpClient,
  ) {
    this.document = document;
  }

  /**
   * Remove the content from ePub iFrame.
   */
  async clearFrame(doc: HTMLDocument): Promise<string> {
    doc.documentElement.innerHTML = '';
    return await new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve('success');
      }, 100);
    });
  }

  /**
   * Given a HTMLDocument return the corresponding IFrame element
   */
  findIframeByDocument(doc: HTMLDocument): HTMLIFrameElement | null {
    // usually all body of fixed epubs have ids... temp solution
    const docBody = doc.querySelector('body') as HTMLBodyElement;
    const docBodyId = docBody.getAttribute('id') as string;
    const leftIframe = this.getIframe('left');
    const rightIframe = this.getIframe('right');
    const leftIframeDoc = this.getDocument('left');
    const rightIframeDoc = this.getDocument('right');

    if (
      this.getElementsByQuery('#' + docBodyId, leftIframeDoc as HTMLDocument)
        .length > 0
    ) {
      return leftIframe;
    }
    if (
      this.getElementsByQuery('#' + docBodyId, rightIframeDoc as HTMLDocument)
        .length > 0
    ) {
      return rightIframe;
    }
    return null;
  }

  /**
   * Given a HtmlDocument object find the corresponding side (left or right).
   */
  findIframeSide(doc: HTMLDocument, target: HTMLElement): string | null {
    if (!doc) {
      return null;
    }
    // usually all body of fixed epubs have ids... temp solution
    const docBody: HTMLElement = doc.querySelector('body') as HTMLBodyElement;
    let docBodyId: string | null = null;

    // in case if target was body instead of document
    if (doc.nodeName.toLowerCase().includes('body')) {
      doc = doc.ownerDocument as any;
    }

    if (doc.nodeName.toLowerCase().includes('document')) {
      docBodyId = docBody.getAttribute('id') as string;
    }

    // faster way to find frame side
    if (docBody.hasAttribute('data-iframe-side')) {
      return docBody.getAttribute('data-iframe-side');
    }

    // if body has no Id, find by target id
    if (!docBodyId && target) {
      docBodyId = target.id;
    }

    const leftIframeDoc = this.getDocument('left');
    if (
      leftIframeDoc &&
      docBodyId &&
      this.getElementsByQuery('#' + docBodyId, leftIframeDoc).length > 0
    ) {
      return 'left';
    }

    const rightIframeDoc = this.getDocument('right');
    if (
      rightIframeDoc &&
      docBodyId &&
      this.getElementsByQuery('#' + docBodyId, rightIframeDoc).length > 0
    ) {
      return 'right';
    }
    return null;
  }

  /**
   * Find the iFrame given the Window element
   * @todo - Can we refactor to remove this window element? - K.S.
   */
  findSourceIframe(source: Window, iframes: HTMLIFrameElement[]): void {
    // @todo Implement
  }

  /**
   * Apply a callback to all iFrames.
   */
  forAllIframes(
    callback: (iFrame: HTMLIFrameElement) => void,
  ): boolean | undefined {
    if (!this.isReady()) {
      return false;
    }

    this.getAllIframes().forEach((elmt) => {
      callback.call(elmt);
    });
  }

  /**
   * List of active inline styles (that will be injected)
   */
  getActiveStyles(): Map<string, string> {
    return this.data.activeStyles;
  }

  /**
   * List of active external stylesheets (that will be injected).
   */
  getActiveStylesheets(): Map<string, string> {
    return this.data.activeStylesheets;
  }

  /**
   * Gather all iFrames both in and out of the ePub.
   * @todo - This method is confusing and could use a refactor - K.S.
   */
  getAllIframes(): HTMLIFrameElement[] {
    const ePubDoc = this.getDocument() as HTMLDocument;
    const iframesInEpub = Array.prototype.slice.call(
      ePubDoc.getElementsByTagName('iframe'),
    );
    const iFramesOnPage = Array.prototype.slice.call(
      document.getElementsByTagName('iframe'),
    );
    iFramesOnPage.unshift(0, 0);
    Array.prototype.splice.apply(iframesInEpub, iFramesOnPage);

    return iframesInEpub;
  }

  /**
   * Returns the client position of the iFrame.
   */
  getClientPosition(
    iframeX: number,
    iframeY: number,
    target: HTMLElement,
    iframeDoc: HTMLDocument,
  ): IFrameClientPosition {
    let iframe = this.getIframe();
    let doc = this.getDocument() as HTMLDocument;
    if (!doc) {
      doc = iframeDoc;
      // in case if target was body instead of document
      if (doc.nodeName.toLowerCase().includes('body')) {
        doc = doc.ownerDocument as any;
      }
    }

    // in case of double spread
    if (!iframe) {
      iframe = this.findIframeByDocument(doc);
      if (!iframe) {
        const side = this.findIframeSide(doc, target);
        iframe = document.querySelector<HTMLIFrameElement>(
          '#' + side + '-iframe',
        );
      }
    }

    if (!target || doc.body.contains(target)) {
      const rect = iframe?.getBoundingClientRect() as DOMRect;
      return {
        x: iframeX + rect.left,
        y: iframeY + rect.top,
      };
    }

    return {
      x: iframeX,
      y: iframeY,
    };
  }

  /**
   * Returns the HtmlDocument for a given side ('left' or 'right')
   */
  getDocument(side?: string): HTMLDocument | null {
    let iframe!: HTMLIFrameElement;
    if (side === 'left') {
      iframe = this.getLeftIframe();
    } else if (side === 'right') {
      iframe = this.getRightIframe();
    } else {
      iframe = this.getIframe() as HTMLIFrameElement;
    }
    if (!iframe) {
      return null;
    }
    return iframe.contentDocument;
  }

  /**
   * Helper function to grab an array of elements by class name
   */
  getElementsByClass(className: string, doc?: HTMLDocument): Element[] {
    doc = doc ?? (this.getDocument() as HTMLDocument);
    return Array.from(doc.getElementsByClassName(className));
  }

  /**
   * Helper function to grab an element by its id
   */
  getElementById(id: string, doc?: HTMLDocument): Element | null {
    doc = doc ?? (this.getDocument() as HTMLDocument);
    return doc.getElementById(id);
  }

  /**
   * Helper function to grab all the elements by query selector
   */
  getElementsByQuery(selector: string, doc?: HTMLDocument): Element[] {
    doc = doc ?? (this.getDocument() as HTMLDocument);
    return Array.from(doc.querySelectorAll(selector));
  }

  /**
   * Helper function to grab the first element by its tag
   */
  getElementByTagName(tagName: string, doc: HTMLDocument): Element | null {
    doc = doc || this.getDocument();
    if (doc) {
      return doc.getElementsByTagName(tagName)[0] || null;
    }
    return null;
  }

  /**
   * Helper function to grab an array of elements by tag
   */
  getElementsByTagName(selector: string, doc?: HTMLDocument): Element[] {
    doc = doc ?? (this.getDocument() as HTMLDocument);
    return Array.from(doc.getElementsByTagName(selector));
  }

  /**
   * Returns the iFrame element based on the id.
   */
  getIframe(id?: string): HTMLIFrameElement | null {
    if (id && id !== 'clo-iframe') {
      return this.document.querySelector<HTMLIFrameElement>('#' + id);
    }

    return this.document.querySelector<HTMLIFrameElement>('#clo-iframe');
  }

  /**
   * Returns the LEFT iFrame element.
   */
  getLeftIframe(): HTMLIFrameElement {
    return this.getIframe('#left-iframe') as HTMLIFrameElement;
  }

  /**
   * Returns the RIGHT iFrame element.
   */
  getRightIframe(): HTMLIFrameElement {
    return this.getIframe('#right-iframe') as HTMLIFrameElement;
  }

  /**
   * Returns back both iFrame elements.
   */
  getSideFrames(): HTMLIFrameElement[] {
    return Array.from(document.querySelectorAll('#left-iframe, #right-iframe'));
  }

  /**
   * Inject MathJax script into ePub iFrame.
   */
  injectMathJaxIntoIframe(doc: HTMLDocument): HTMLDocument | undefined {
    // Only Inject MathJax Once
    if (doc.getElementById('reader-mathjax-loader')) {
      return;
    }

    const body = doc.querySelector('body') as HTMLBodyElement;
    let mathTags: any[] = [];
    let mathTagsNS: any[] = [];
    // plan B if appConfig will not supply mathjax CDN
    const MATHJAX_CDN = [
      'https://dle-cdn.mheducation.com/3rdparty/mathjax/',
      'latest-2.7.X',
      '/MathJax.js',
      '?config=MML_SVG',
    ].join('');

    if (doc.getElementsByTagName && doc.getElementsByTagNameNS) {
      mathTags = Array.from(doc.getElementsByTagName('math'));
      mathTagsNS = Array.from(
        doc.getElementsByTagNameNS('http://www.w3.org/1998/Math/MathML', 'math'),
      );
    }

    if (mathTags.length > 0 || mathTagsNS.length > 0) {
      // inject MathJaxConf
      const config = {
        displayAlign: 'inherit',
        showProcessingMessages: false,
        messageStyle: 'none',
        showMathMenu: false,
        showMathMenuMSIE: false,
        SVG: {
          useFontCache: false,
          useGlobalCache: true,
          font: 'STIX-Web',
        },
      };
      const mathJaxConf = doc.createElement('script');
      mathJaxConf.type = 'text/x-mathjax-config';
      mathJaxConf.textContent = [
        'MathJax.Hub.Config(' + JSON.stringify(config) + ');',
      ].join('');
      body.appendChild(mathJaxConf);

      const script = doc.createElement('script');
      script.type = 'text/javascript';
      // MHE CDN url for MathJax
      script.src = this.mockAppConfig.mathJaxCDN || MATHJAX_CDN;
      body.appendChild(script);

      // Inject script that looks for MathJax.
      // Once it is loaded, call MathJax.Hub.Configured() to process
      const mathJaxInit = doc.createElement('script');
      mathJaxConf.setAttribute('id', 'reader-mathjax-loader');
      mathJaxInit.type = 'text/javascript';
      mathJaxInit.textContent = [
        'var mathJaxInterval = setInterval(function(){',
        'if(window.MathJax){',
        'window.MathJax.Hub.Configured();',
        'clearInterval(mathJaxInterval);',
        '}',
        '},200);',
      ].join('');
      body.appendChild(mathJaxInit);
      // @todo there is a pending PR - https://github.mheducation.com/MHEducation/DLE-Player-Application/pull/1439
    } else {
      // put in props for MathJax
      const mathJaxDiv = doc.createElement('div');
      mathJaxDiv.setAttribute('id', 'MathJax_Message');
      mathJaxDiv.setAttribute('style', 'display: none;');
      body.insertBefore(mathJaxDiv, body.firstChild);
    }

    return doc;
  }

  /**
   * Inject MathJax into all the ePub iFrames.
   */
  injectMathJax(): void {
    // reflowable
    const documentNode = this.getDocument();
    if (documentNode) {
      this.injectMathJaxIntoIframe(documentNode);
    }
    // fixed page layout
    const leftFrame = this.getDocument('left');
    if (leftFrame) {
      this.injectMathJaxIntoIframe(leftFrame);
    }
    const rightFrame = this.getDocument('right');
    if (rightFrame) {
      this.injectMathJaxIntoIframe(rightFrame);
    }
  }

  /**
   * Returns TRUE if element is an assess widget and FALSE otherwise.
   */
  isAssessmentWidget(element: HTMLElement): boolean {
    if (element.classList.contains('widget-engrade-assessment')) {
      return true;
    } else if (element.classList.contains('widget-mhe-assessment')) {
      return true;
    }
    return false;
  }

  /**
   * Returns TRUE if the ePub iFrame is loaded and FALSE otherwise.
   */
  isLoaded(): boolean {
    const iframe = this.getIframe() as HTMLIFrameElement;
    const iframeDocument = this.getDocument() as HTMLDocument;

    return (
      iframe &&
      (iframeDocument.body as HTMLBodyElement) &&
      iframeDocument.body.childNodes.length > 0
    );
  }

  /**
   * Returns TRUE if timestamp is expired and FALSE otherwise.
   */
  isOauthTimestampExpired(oathTimestamp: number): boolean {
    // originally its 1800, but in order to avoid delay in communication
    const expirationSeconds = 1770;
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > oathTimestamp + expirationSeconds;
  }

  /**
   * @todo this logic copied over, and should be double checked - K.S.
   */
  isReady(): boolean {
    let ready = false;
    if (this.data.ready) {
      return true;
    }

    const iframe = this.getIframe();
    // cf http://bit.ly/2afpTIt
    const iframeWin = iframe && (iframe.contentWindow ?? iframe);
    const iframeMathJax = (iframeWin as any)?.MathJax;

    // No mathjax case - roll our own document.ready()
    if (iframe) {
      const iframeDoc = iframe.contentDocument ?? (iframeWin as any)?.document;

      if (iframeDoc.readyState === 'complete') {
        ready = true;
      } else {
        iframeDoc.addEventListener('DOMContentLoaded', () => {
          ready = true;
        });
      }
    }

    // MathJax case:
    // Strategy is to start with a private `ready` var that is false, and
    // queue a mathjax task in the iframe to set it to true.  This is
    // guaranteed to execute after the typesetting is complete
    // This is one of the 2 ways MathJax documentation recommends synchronizing
    // your code with it.
    if (iframeMathJax && !this.data.ready) {
      iframeMathJax.Hub.Queue(() => {
        ready = true;
      });
    }

    if (this.data.ready !== ready) {
      this.data.ready = ready;
    }

    return ready;
  }

  /**
   * Create LTI Launch Form Post within the assessment iFrame.
   */
  launchAssessmentLTI(
    parentNode: HTMLElement,
    id: string,
    launchUrl: string,
    launchParams: object,
  ): void {
    const iframePlaceholder = parentNode.querySelector(
      '.iframe-place-holder-' + id,
    ) as Element;
    iframePlaceholder.parentElement?.replaceChild(
      document.createElement('iframe'),
      iframePlaceholder,
    );

    const launchFrame = parentNode.querySelector('iframe') as HTMLIFrameElement;
    launchFrame.height = '700';
    launchFrame.id = id;

    launchFrame.contentDocument?.write(
      '<form action="' + launchUrl + '" method="post">',
    );
    if (launchParams) {
      for (const param in launchParams) {
        if (launchParams.hasOwnProperty(param)) {
          const paramValue = launchParams[param];
          launchFrame.contentDocument?.write(
            '<input type="hidden" name="' +
              param +
              '" value="' +
              paramValue +
              '"/>',
          );
        }
      }
    }
    launchFrame.contentDocument?.write(
      '<button type="submit" style="display:none;"></button>',
    );
    launchFrame.contentDocument?.write('</form>');
    launchFrame.contentDocument?.close();

    parentNode
      ?.querySelector('iframe#' + id)
      ?.ownerDocument.querySelector('form')
      ?.submit();
  }

  /**
   * Attach a callback function to an iFrame::onload event.
   */
  onLoad(func: (event: Event) => void, iframe: HTMLIFrameElement): void {
    iframe = iframe || this.getIframe();
    iframe.removeEventListener('load', func);
    iframe.addEventListener('load', func);
  }

  /**
   * Adds an active inline style (that will be injected).
   */
  setActiveStyle(id: string, content: string): void {
    this.data.activeStyles.set(id, content);
  }

  /**
   * Adds an active external stylesheet (that will be injected).
   */
  setActiveStyleSheet(id: string, url: string): void {
    this.data.activeStylesheets.set(id, url);
  }

  /**
   * Scroll to a element given its ID.
   */
  scrollToElementById(elementId: string, speed: number, offset: number): void {
    // @todo we need ScrollSpy solution before implementing - K.S.
  }

  /**
   * Scroll to the top of the page.
   */
  scrollToTop(speed: number): void {
    // @todo we need ScrollSpy solution before implementing - K.S.
  }

  /**
   * Allows the behavior of links to be changed.
   */
  setLinkHandlingType(type: string): void {
    if (['notify', 'follow'].includes(type)) {
      this.data.linkType = type;
    }
  }

  /**
   * Replace assessment iFrame within ePub iFrame.
   */
  replaceWithAssessmentLtiFrame(parent: HTMLElement, id: string): void {
    const launchUrl =
      this.mockAuthService.ltiContext.custom_assessment_lti_post;
    const postParams =
      this.mockAuthService.ltiContext.custom_assessment_lti_post_launch_params;
    let launchParams: any = {};

    if (postParams) {
      launchParams = JSON.parse(postParams);
    }

    const oauthTimestamp = launchParams?.oauth_timestamp;
    if (!oauthTimestamp) {
      return;
    }

    if (this.isOauthTimestampExpired(Number(oauthTimestamp))) {
      this.httpClient
        .get<AssessmentParams>(this.mockAppConfig.apiEndpoint)
        .subscribe((res) => {
          this.launchAssessmentLTI(parent, id, launchUrl, res.launchParams);
        });
      this.launchAssessmentLTI(parent, id, launchUrl, launchParams);
    }
  }

  /**
   * Write the content in a HtmlDocument to a given ePub iFrame element.
   */
  writeDocument(doc: HTMLDocument, iFrame: HTMLIFrameElement): void {
    // Cancel unfinished downloading
    if (this.isLoaded()) {
      this.stopDownloads();
    }

    this.data.deferredIframes = [];
    if (this.data.placeholderRemoved) {
      this.injectMathJaxIntoIframe(doc);
    }

    const iframes = Array.from(
      doc.querySelectorAll<HTMLIFrameElement>('iframe'),
    );

    // Replace iFrames in the content with placeholders
    iframes.forEach((item) => {
      const parent = item.parentElement;
      if (!parent?.id) {
        return;
      }

      this.data.deferredIframes.push({
        attributes: item.attributes,
        originalSrc: item.outerHTML,
        parentId: parent.id,
        iframeId: item.id,
      });

      if (item.id) {
        const placeholder = doc.createElement('span');
        placeholder.classList.add('iframe-place-holder-' + item.id);
        parent.replaceChild(placeholder, item);
      } else {
        parent.removeChild(item);
      }
    });

    // This needs to be internal (closures and stuff)
    const that = this;
    const onMainIframeLoad = function($event): void {
      iFrame.removeEventListener('load', onMainIframeLoad);
      const loadedIframe = $event.target;
      if (loadedIframe.classList.contains('widget')) {
        if (loadedIframe.id) {
          that.data.widgetsCollection[loadedIframe.id] = loadedIframe.className;
        }
      }

      const injectIframe = that.data.deferredIframes.shift();
      if (injectIframe) {
        const curDoc = iFrame.contentDocument as Document;
        const parentNode = curDoc.getElementById(injectIframe.parentId);
        const newIframe = curDoc.createElement(injectIframe.originalSrc);
        const isPreviewMode =
          that.mockAuthService.ltiContext.custom_preview_mode === 'true';
        const isStudent = ['Learner', 'Student'].includes(
          that.mockAuthService.ltiContext.roles,
        );

        newIframe.addEventListener('load', onMainIframeLoad);
        if (injectIframe.iframeId) {
          const isAssessWidget = that.isAssessmentWidget(newIframe[0]);
          if (isAssessWidget && isPreviewMode && isStudent) {
            const messageDiv = curDoc.createElement('div');
            messageDiv.style.background = '#000';
            messageDiv.style.color = '#fff';
            messageDiv.style.padding = '0.5em';
            messageDiv.style.fontSize = '1em';
            messageDiv.style.fontWeight = 'bolder';
            messageDiv.style.textAlign = 'center';
            messageDiv.innerHTML = 'Assessment viewable when assigned';

            parentNode?.appendChild(messageDiv);
          } else if (
            isAssessWidget &&
            that.mockAuthService.ltiContext
              .custom_assessment_lti_post_launch_params
          ) {
            that.replaceWithAssessmentLtiFrame(
              parentNode as HTMLElement,
              injectIframe.iframeId,
            );
          } else {
            const placeholder = parentNode?.querySelector(
              '.iframe-place-holder-' + injectIframe.iframeId,
            ) as Element;
            parentNode?.replaceChild(newIframe, placeholder);
          }
        } else {
          parentNode?.prepend(newIframe);
        }
      }
    };

    iFrame.addEventListener('load', onMainIframeLoad);

    const iFrameDocument = iFrame.contentDocument;
    const iFrameWindow = iFrame.contentWindow;
    const contentDocument = doc.documentElement;
    const contentString = contentDocument.outerHTML;

    this.clearFrame(iFrameDocument as unknown as Document).then(function() {
      // Remove injected MathJax
      if (typeof (iFrameWindow as any)?.MathJax !== 'undefined') {
        delete (iFrameWindow as any)?.MathJax;
      }

      // @todo use browser sniffer library when ready
      // Workaround on Safari, Referrer not sent for Viddler videos
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );
      if (isSafari) {
        iFrame.srcdoc = contentString;
      } else if (
        iFrameDocument?.body?.childNodes &&
        iFrameDocument?.body.childNodes.length === 0
      ) {
        // Quick navigation bug in Firefox EPR-2175
        iFrameDocument.documentElement.innerHTML = contentDocument.innerHTML;
      } else {
        iFrameDocument?.open();
        iFrameDocument?.write(contentString);
        iFrameDocument?.close();
      }
    });
  }

  /**
   * Handles the event placeholder.removed
   */
  protected onPlaceholderRemoved(): void {
    this.data.placeholderRemoved = true;
    this.injectMathJax();
  }

  /**
   * Force the ePub iFrames to stop downloading.
   */
  protected stopDownloads(): void {
    if (typeof window.stop !== 'undefined') {
      window.stop();
    } else if (typeof document.execCommand !== 'undefined') {
      document.execCommand('Stop', false);
    }
  }
}
