<!-- header -->
<rdrx-modal-header (closeEvent)="dialogRef.close()">
  <h2 class="dialog-heading" data-automation-id="dialog-heading" id="confirmation-modal-h2">
    {{ data.title }}
  </h2>
</rdrx-modal-header>

<!-- content -->
<div class="content" data-automation-id="modal-content" [innerHTML]="data.content"></div>

<!-- footer -->
<rdrx-modal-footer class="modal-footer" data-automation-id="modal-footer">
  <button
    data-automation-id="cancel-button"
    class="btn btn-secondary"
    (click)="dialogRef.close()"
  >
    {{ data.closeText }}
  </button>

  <button
    data-automation-id="ok-button"
    *ngIf="data.confirmText"
    mhe-button
    [purpose]="ButtonPurpose.Primary"
    (click)="dialogRef.close(true)"
  >
    {{ data.confirmText }}
  </button>
</rdrx-modal-footer>
