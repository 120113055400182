/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Renderer2 } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, asyncScheduler, combineLatest, forkJoin } from 'rxjs';
import {
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { MediatorUtils } from './mediator-utils';
import { MobileLaunchService } from '../features/mobile-launch';

import { ReadiatorBridgeService } from '@mhe/reader/features/readiator-bridge';
import { AleksLogoutService } from '@mhe/reader/features/readiator-bridge/aleks-logout-service';
import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as readerActions from '@mhe/reader/components/reader/state/reader.actions';
import { TopicsMediator } from './topics.mediator';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import * as topicsActions from '@mhe/reader/components/topics/state/topics.actions';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { UserSettingsStore } from '@mhe/reader/features/user-settings';
import * as userSettingsActions from '@mhe/reader/features/user-settings/state/user-settings.actions';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { HighlighterService } from '@mhe/reader/features/annotation';
import {
  LastLocation,
  LastLocationStore,
} from '@mhe/reader/features/last-location';
import * as lastLocationActions from '@mhe/reader/features/last-location/last-location.actions';
import { mapParserOptionToLeveledKey } from '@mhe/reader/utils';
import { TocStore } from '@mhe/reader/components/toc';
import * as epubActions from '@mhe/reader/global-store/epub/epub.actions';
import { DFASearchParams, DoubleSpineItem, SpineItem } from '../models';

@Injectable()
export class InitMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly transformActions$ = this.transformStore.actions$;

  // init params
  private readonly initCfi$ = this.readerConfigStore.cfi$.pipe(
    this.util.coerceInvalidCfi,
  );

  // init data
  private readonly initBook$ = this.readerStore.book$.pipe(first());
  private readonly initLocation$ = this.lastLocationStore.lastLocation$.pipe(
    filter((ll) => Boolean(ll)),
    map(({ location }: LastLocation) => location),
    first(),
  );

  // init main
  private readonly init$ = forkJoin({
    book: this.initBook$,
    lastLocation: this.initLocation$,
  });

  constructor(
    private readonly aleksLogoutService: AleksLogoutService,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly highlighterService: HighlighterService,
    private readonly lastLocationStore: LastLocationStore,
    private readonly mobileLaunch: MobileLaunchService,
    private readonly navigationStore: NavigationStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly readiatorBridge: ReadiatorBridgeService,
    private readonly renderer: Renderer2,
    private readonly store: Store,
    private readonly tocStore: TocStore,
    private readonly transformStore: TransformStore,
    private readonly userSettingsStore: UserSettingsStore,
    private readonly util: MediatorUtils,
    private readonly topicsMediator: TopicsMediator,
    private readonly topicsStore: TopicsStore,
  ) {
    super();
  }

  // init
  private readonly readerInit$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.init),
      withLatestFrom(
        this.readerStore.parserOptions$,
        this.readerConfigStore.runtime$,
      ),
      tap(([{ url }, parserOptions, readerConfig]) => {
        const url_leveled = mapParserOptionToLeveledKey(url, parserOptions);
        this.store.dispatch(
          epubActions.fetchEpub({
            url,
            url_leveled,
            readerConfig,
            parserOptions,
          }),
        );
        this.lastLocationStore.dispatch(lastLocationActions.init());
        this.userSettingsStore.dispatch(userSettingsActions.init());
        this.topicsStore.dispatch(topicsActions.init);
      }),
      tap(([{ url }]) => this.readerStore.setEpubUrl(url)),
      switchMap(([{ spineId, hash }]) => {
        const { albumMode$ } = this.epubViewerStore;
        const { linearSpine$, doubleSpine$ } = this.readerStore;
        const {
          isScoresheetMode$,
          pageStartCfi$,
          pageEndCfi$,
          assignment$,
          topicsEndpoint$,
          dfaTopicUUID$,
          dfaCategoryUUID$,
          dfaSearchLaunchParams$,
        } = this.readerConfigStore;
        const { initCfi$ } = this;
        // don't dispatch init actions until init data { book, lastLocation } is loaded
        return this.init$.pipe(
          withLatestFrom(
            linearSpine$,
            doubleSpine$,
            isScoresheetMode$,
            initCfi$,
            pageStartCfi$,
            pageEndCfi$,
            assignment$,
            topicsEndpoint$,
            albumMode$,
            dfaTopicUUID$,
            dfaCategoryUUID$,
            dfaSearchLaunchParams$,
          ),
          tap(
            ([
              { book, lastLocation },
              linearSpine,
              doubleSpine,
              scoresheet,
              cfi,
              pageStartCfi,
              pageEndCfi,
              assignment,
              topicsEndpoint,
              albumMode,
              dfaTopicUUID,
              dfaCategoryUUID,
              dfaSearchLaunchParams$,
            ]) => {
              // init navigation
              this.navigationStore.dispatch(
                navigationActions.init({ linearSpine, doubleSpine }),
              );
              // init toc
              this.tocStore.setTree(book.toc);
              // set DFA subjects if necessary
              if (topicsEndpoint) {
                this.topicsMediator.setSubjects.pipe(take(1)).subscribe();
              }
              // initial navigation
              asyncScheduler.schedule(() => {
                const pangeRangeCfi = pageStartCfi || pageEndCfi;
                const isMatchingCfi = this.util.findMatchingPageRangeIndex(
                  pangeRangeCfi as string,
                  albumMode,
                  linearSpine as SpineItem[],
                  doubleSpine as DoubleSpineItem[],
                );
                if ((pageStartCfi || pageEndCfi) && isMatchingCfi) {
                  this.navigationStore.dispatch(
                    navigationActions.navigateByCfi({
                      cfi: pangeRangeCfi as string,
                      setFocus: true,
                    }),
                  );
                } else if (
                  dfaSearchLaunchParams$.spineID &&
                  dfaSearchLaunchParams$.text &&
                  (dfaSearchLaunchParams$.index as number) > -1
                ) {
                  this.topicsStore.dispatch(
                    topicsActions.dfaSearchLaunch({
                      params: {
                        ...(dfaSearchLaunchParams$ as DFASearchParams),
                      },
                    }),
                  );
                } else if (scoresheet) {
                  this.navigateToLastPage$();
                } else if (spineId) {
                  this.navigationStore.dispatch(
                    navigationActions.navigateBySpineId({ id: spineId, hash }),
                  );
                } else if (cfi) {
                  this.navigationStore.dispatch(
                    navigationActions.navigateByCfi({ cfi, setFocus: true }),
                  );

                  // the API is currently returning the string 'null' for lastLocation due to a Firebase migration bug
                } else if (lastLocation && lastLocation !== 'null') {
                  this.navigationStore.dispatch(
                    navigationActions.navigateByCfi({
                      cfi: lastLocation,
                      setFocus: true,
                    }),
                  );
                } else {
                  (doubleSpine as DoubleSpineItem[])?.length > 0
                    ? this.util.navDoubleSpread(0)
                    : this.util.navigate(0);
                }
              });
            },
          ),
          tap(() => this.readerStore.dispatch(readerActions.initComplete)),
        );
      }),
      logCatchError('readerInit$'),
    ),
  );

  // mobile banner
  private readonly mobileLaunchBanner$ = this.effect(() => {
    const { runtime$, environment$ } = this.readerConfigStore;

    return this.readerActions$.pipe(
      ofType(readerActions.init),
      switchMap(() => {
        return this.init$.pipe(
          withLatestFrom(runtime$, environment$),
          tap(([_, runtime, environment]) => {
            this.mobileLaunch.init(runtime, environment);
          }),
        );
      }),
      logCatchError('mobileLaunchBanner$'),
    );
  });

  // leveled content
  private readonly changeContentLevel$ = this.effect(() => {
    return this.readerStore.actions$.pipe(
      ofType(readerActions.changeContentLevel),
      withLatestFrom(this.readerStore.epubUrl$),
      tap(([{ level }, url]) => {
        this.readerStore.setSelectedLevel(level);
        this.readerStore.dispatch(readerActions.init({ url: url as string }));
      }),
      logCatchError('changeContentLevel$'),
    );
  });

  private readonly themePark$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.themeParkInit),
      withLatestFrom(this.readerConfigStore.launchPresentationCssUrl$),
      tap(([{ content }, launchPresentationCssUrl]) => {
        const linkId = 'lti-launch-stylesheet';

        if (
          !content.getElementById(linkId) &&
          launchPresentationCssUrl !== undefined
        ) {
          const linkTag = this.renderer.createElement('link');
          this.renderer.setAttribute(linkTag, 'rel', 'stylesheet');
          this.renderer.setAttribute(linkTag, 'href', launchPresentationCssUrl);
          this.renderer.setAttribute(linkTag, 'id', linkId);
          this.renderer.setAttribute(linkTag, 'type', 'text/css');
          this.renderer.appendChild(content.head, linkTag);
        }
      }),
      logCatchError('themePark$'),
    ),
  );

  // epub lib
  private readonly libconfig$ = this.effect(() =>
    combineLatest([
      this.readerConfigStore.readiator$,
      this.readerConfigStore.interfaceMode$,
      this.readerConfigStore.oauthConsumerKey$,
    ]).pipe(
      tap(([readiator, interfaceMode, oauthConsumerKey]) => {
        // @todo combineLatests emits 3 messages - one for each LTI parameter
        // There must be a better way to grab all the values
        // The oauthConsumerKey will be last one resolved
        if (!oauthConsumerKey) {
          return;
        }

        // Configure the CFI library version
        if (readiator && interfaceMode !== 'k5' && interfaceMode !== 'dfa') {
          this.highlighterService.setCfiLibraryVersion('v6');
        }

        // Initialize Readiator
        if (readiator) {
          this.readiatorBridge.init(oauthConsumerKey);
        } else if (oauthConsumerKey === 'aleks') {
          this.aleksLogoutService.init();
        }
      }),
    ),
  );

  // helpers
  private readonly navigateToLastPage$ = this.effect(
    (nav$: Observable<void>) => {
      return nav$.pipe(
        withLatestFrom(this.navigationStore.maxIndex$),
        map(([, max]) => max),
        this.util.tapDoubleSpread(
          (max) => this.util.navigate(max as number),
          (max) => this.util.navDoubleSpread(max as number),
        ),
        logCatchError('navigateToLastPage$'),
      );
    },
  );

  // additional loading when AI Reader is enabled
  private readonly aiAssistInit$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.initComplete),
      withLatestFrom(
        this.readerStore.epubUrl$,
        this.readerConfigStore.isAiAssistEnabled$,
      ),
      filter(([, , isEnabled]) => isEnabled),
      tap(([, url]) => {
        this.store.dispatch(epubActions.loadFactoryMetadata({ url }));
      }),
    ),
  );
}
