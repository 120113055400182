import { createSelector } from '@ngrx/store';

import { getGlobalState } from '../global-store.selectors';
import {
  FootnoteDictionaryState,
  FootnoteState,
  FOOTNOTE_STATE_KEY,
} from './footnote.state';
import { Footnote, FootnoteItem } from '@mhe/reader/models';

// TODO: move with other types?
export type FootnoteLoadingState =
  | 'not requested'
  | 'requested'
  | 'loaded'
  | 'error';

export interface FootnoteStateByUrlProps {
  url: string
}
export interface FootnoteItemByIdProps extends FootnoteStateByUrlProps {
  id: string
}

const getState = createSelector(
  getGlobalState,
  (state) => state[FOOTNOTE_STATE_KEY],
);

const getFootnoteStateByUrl = createSelector(
  getState,
  (
    s: FootnoteDictionaryState,
    { url }: FootnoteStateByUrlProps | FootnoteItemByIdProps,
  ): FootnoteState => s[url],
);

/** loading */
export const getFootnoteLoadingState = createSelector(
  getFootnoteStateByUrl,
  (state): FootnoteLoadingState | undefined => {
    if (state === undefined) {
      return 'not requested';
    }
    if (state.loading) {
      return 'requested';
    }
    if (state.error) {
      return 'error';
    }
    if (state.footnote) {
      return 'loaded';
    }
  },
);

export const isFootnoteLoading = createSelector(
  getFootnoteLoadingState,
  (loadingState) => loadingState === 'requested',
);

export const getFootnoteLoadingError = createSelector(
  getFootnoteStateByUrl,
  (state): Error | undefined => state?.error,
);

/** footnote */
export const getFootnote = createSelector(
  getFootnoteStateByUrl,
  (state): Footnote => state?.footnote,
);

export const getFootnoteItemById = createSelector(
  getFootnote,
  (footnote, { url, id }: FootnoteItemByIdProps): FootnoteItem | undefined =>
    footnote?.[id],
);
