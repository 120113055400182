import { InjectionToken, Provider } from '@angular/core';

import { AiAssistMediator } from './ai-assist.mediator';
import { AnnotationListsMediator } from './annotation-lists.mediator';
import { AnnotationsMediator } from './annotations.mediator';
import { AssessmentsMediator } from './assessment.mediator';
import { AssignmentsMediator } from './assignments.mediator';
import { AuthoringMediator } from './authoring.mediator';
import { BentoDigitalTaggingMediator } from './bento-digital-tagging.mediator';
import { DrawerMediator } from './drawer.mediator';
import { EBookshelfMediator } from './ebookshelf.mediator';
import { EpubControlsMediator } from './epub-controls.mediator';
import { GoogleAnalyticsMediator } from './google-analytics.mediator';
import { IframeActivityMediator } from './iframe-activity.mediator';
import { InitMediator } from './init.mediator';
import { LargeImageNavigatorMediator } from './large-image-navigator.mediator';
import { LastLocationMediator } from './last-location.mediator';
import { UserSettingsMediator } from './user-settings.mediator';
import { LexileLevelMediator } from './lexile-level.mediator';
import { LoggingMediator } from './logging.mediator';
import { MediatorUtils } from './mediator-utils';
import { NavigationRoutingMediator } from './navigation-routing.mediator';
import { NavigationMediator } from './navigation.mediator';
import { PrintMediator } from './print.mediator';
import { ReaderRolesMediator } from './reader-roles.mediator';
import { RenderEnhancementMediator } from './render-enhancements.mediator';
import { SearchMediator } from './search.mediator';
import { SpineItemMediator } from './spine-item.mediator';
import { TocMediator } from './toc.mediator';
import { TopicsMediator } from './topics.mediator';
import { TTSMediator } from './tts.mediator';
import { WidgetsMediator } from './widgets.mediator';

export const MEDIATOR_PROVIDERS = new InjectionToken(
  'Multi InjectionToken to make sure these providers are not treeshaken',
);

const mediatorProviders = [
  AiAssistMediator,
  AnnotationListsMediator,
  AnnotationsMediator,
  AssessmentsMediator,
  AssignmentsMediator,
  AuthoringMediator,
  BentoDigitalTaggingMediator,
  DrawerMediator,
  EBookshelfMediator,
  EpubControlsMediator,
  GoogleAnalyticsMediator,
  IframeActivityMediator,
  InitMediator,
  LargeImageNavigatorMediator,
  LastLocationMediator,
  UserSettingsMediator,
  LexileLevelMediator,
  LoggingMediator,
  NavigationMediator,
  NavigationRoutingMediator,
  PrintMediator,
  ReaderRolesMediator,
  RenderEnhancementMediator,
  SearchMediator,
  SpineItemMediator,
  TocMediator,
  TTSMediator,
  WidgetsMediator,
];

export function getMediatorProviders(): Provider[] {
  return [
    ...mediatorProviders.map((provider) => ({
      provide: MEDIATOR_PROVIDERS,
      useClass: provider,
      multi: true,
    })),
    // This actually needs injected by the mediators, so must remain a normal provider
    MediatorUtils,
    TopicsMediator,
  ];
}
