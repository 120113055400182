import {
  AnnotationListState,
  initialAnnotationListState,
} from '../../utils/annotation-list.state.abstract';

export interface HighlightListState extends AnnotationListState {}

export const initialHighlightListState: HighlightListState = {
  ...initialAnnotationListState,
};
