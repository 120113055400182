import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import {
  UserSettings,
  UserSettingsAPIResponse,
} from '@mhe/reader/features/user-settings';
import { Observable } from 'rxjs';

@Injectable()
export class UserSettingsService {
  private readonly baseurl = '/v1/settings';

  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  getUserSettings(
    api: string,
    platform: string,
    userID: string,
  ): Observable<UserSettingsAPIResponse> {
    const url = `${api}${this.baseurl}/search`;
    const payload = {
      platform,
      userID,
    };

    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<UserSettingsAPIResponse>(url, payload),
    );
  }

  setUserSettings(
    api: string,
    platform: string,
    userID: string,
    userSettings: UserSettings,
  ): Observable<UserSettingsAPIResponse> {
    const url = `${api}${this.baseurl}`;
    const payload = {
      data: JSON.stringify(userSettings),
      platform,
      userID,
    };

    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<UserSettingsAPIResponse>(url, payload),
    );
  }
}
