import { createAction, props } from '@ngrx/store';

/** loading footnotes */
// check if footnotes are in store - if not, dispatch laod
export const fetchFootnote = createAction('[Reader] Fetch Footnote', props<{ url: URL }>());
// loadFootnote - typical http load pattern | init, success, error
export const loadFootnote = createAction('[Reader] Load Footnote', props<{ url: string }>());
export const loadFootnoteSuccess = createAction(
  '[Reader] Load Footnote Success',
  props<{ url: string, footnoteDocument: XMLDocument }>(),
);
export const loadFootnoteError = createAction(
  '[Reader] Load Footnote Error',
  props<{ url: string, error: Error }>(),
);
