/* eslint-disable max-len */
import { Injectable, Renderer2 } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { map, tap, withLatestFrom, filter, switchMap } from 'rxjs/operators';

import { ReaderConfigStore, ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { combineLatest } from 'rxjs';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { UserSettingsStore } from '@mhe/reader/features/user-settings';
import * as userSettingsActions from '@mhe/reader/features/user-settings/state/user-settings.actions';

@Injectable()
export class ReaderRolesMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly transformActions$ = this.transformStore.actions$;

  private readonly INSTRUCTOR_CSS = 'mhe-context-view-instructor';
  private readonly LEARNER_CSS = 'mhe-context-view-learner';

  constructor(
    private readonly configStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly renderer: Renderer2,
    private readonly transformStore: TransformStore,
    private readonly userSettingsStore: UserSettingsStore,
  ) {
    super();
  }

  private readonly rolesInit$ = this.effect(() => {
    const { isTeacher$ } = this.configStore;
    const { teacherContentEnbled$ } = this.readerStore;

    return this.readerActions$.pipe(
      ofType(readerActions.init),
      withLatestFrom(teacherContentEnbled$),
      filter(([, teacherContent]) => teacherContent === undefined),
      withLatestFrom(isTeacher$),
      tap(([_, isTeacher]) => {
        this.readerStore.setTeacherContentEnabled(isTeacher);
      }),
      logCatchError('rolesInit$'),
    );
  });

  private readonly readerRoles$ = this.effect(() => {
    const { isTeacher$, isStudent$ } = this.configStore;
    const { teacherContentEnbled$ } = this.readerStore;
    const showTeacherContent$ = combineLatest([
      isTeacher$,
      teacherContentEnbled$,
    ]).pipe(
      map(
        ([isTeacher, teacherContent]) => isTeacher && teacherContent !== false,
      ),
    );

    return this.transformActions$.pipe(
      ofType(transformActions.userRole),
      withLatestFrom(isStudent$, showTeacherContent$),
      tap(([{ content }, isStudent, showTeacherContent]) => {
        const body = content.querySelector('body');

        if (isStudent) {
          const teacherNodes = body?.querySelectorAll(
            '.teacher, .acme-teacher',
          );
          teacherNodes?.forEach((node) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const removed_html_comment = `<!-- Teacher node removed in learner view. (#${node.id}) -->`;
            node.innerHTML = removed_html_comment;
          });
        }

        this.applyRoleCss(body as HTMLBodyElement, showTeacherContent);
      }),
      logCatchError('readerRoles$'),
    );
  });

  private readonly viewMode$ = this.effect(() => {
    const { viewMode$, authoring$ } = this.configStore;

    return this.transformActions$.pipe(
      ofType(transformActions.userRole),
      withLatestFrom(viewMode$, authoring$),
      tap(([{ content }, viewMode, authoring]) => {
        const body = content.querySelector('body');

        if (viewMode === 'digital') {
          this.renderer.addClass(body, 'mhe-context-view-digital');
        } else if (viewMode === 'print') {
          this.renderer.addClass(body, 'mhe-context-view-print');
        }

        this.applyAvaStyling(body as HTMLBodyElement, authoring);
      }),
      logCatchError('viewMode$'),
    );
  });

  private readonly changeTeacherContent$ = this.effect(() => {
    const { isTeacher$ } = this.configStore;
    const { teacherContentEnbled$ } = this.readerStore;

    return this.transformActions$.pipe(
      ofType(transformActions.subscribeTeacherContentChange),
      withLatestFrom(isTeacher$),
      switchMap(([{ content }, isTeacher]) => {
        const body = content.querySelector('body');

        return teacherContentEnbled$.pipe(
          map((teacherContent) => isTeacher && teacherContent !== false),
          tap((showTeacherContent) =>
            this.applyRoleCss(body as HTMLBodyElement, showTeacherContent),
          ),
          tap((showTeacherContent) => {
            return this.userSettingsStore.dispatch(
              userSettingsActions.setUserSettings({
                userSettings: {
                  teacherContentEnabled: Boolean(showTeacherContent),
                },
              }),
            );
          }),
        );
      }),
      logCatchError('changeTeacherContent$'),
    );
  });

  /** helpers */
  private applyRoleCss(
    body: HTMLBodyElement,
    showTeacherContent: boolean,
  ): void {
    const { LEARNER_CSS, INSTRUCTOR_CSS } = this;

    if (showTeacherContent) {
      this.renderer.removeClass(body, LEARNER_CSS);
      this.renderer.addClass(body, INSTRUCTOR_CSS);
    } else {
      this.renderer.removeClass(body, INSTRUCTOR_CSS);
      this.renderer.addClass(body, LEARNER_CSS);
    }
  }

  private applyAvaStyling(body: HTMLBodyElement, authoring: boolean): void {
    if (authoring) {
      this.renderer.addClass(body, 'mhe-context-view-authoring');
    } else {
      this.renderer.removeClass(body, 'mhe-context-view-authoring');
    }
  }
}
