export interface ReaderEnvironmentConfig {
  sdlc: string
  services: Services
  branchkeys: Branchkeys
}

export interface Services {
  PlayerAPI: string
  MHEReaderAPI: string
  MHEDarkReaderAPI: string
}
export interface Branchkeys {
  con: string
  atd: string
}

export const defaultReaderEnvironmentConfig: ReaderEnvironmentConfig = {
  sdlc: '',
  services: {
    PlayerAPI: '',
    MHEReaderAPI: '',
    MHEDarkReaderAPI: '',
  },
  branchkeys: {
    con: '',
    atd: '',
  },
};
