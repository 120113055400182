import { Injectable } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class MathjaxTransformer implements Transformer {
  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    this.injectMathJaxIntoIframe(content);

    return await next(content);
  }

  injectMathJaxIntoIframe(doc: HTMLDocument): HTMLDocument | undefined {
    // Only Inject MathJax Once
    if (doc.getElementById('reader-mathjax-loader')) {
      return;
    }

    const body: HTMLBodyElement = doc.querySelector('body') as HTMLBodyElement;
    let mathTags: Element[] = [];
    let mathTagsNS: Element[] = [];

    // MHE CDN url for MathJax
    const MATHJAX_CDN = [
      'https://dle-cdn.mheducation.com/3rdparty/mathjax/',
      'latest-2.7.X',
      '/MathJax.js',
      '?config=MML_SVG',
      '&delayStartupUntil=configured',
    ].join('');

    if (doc.getElementsByTagName && doc.getElementsByTagNameNS) {
      mathTags = Array.from(doc.getElementsByTagName('math'));
      mathTagsNS = Array.from(
        doc.getElementsByTagNameNS('http://www.w3.org/1998/Math/MathML', 'math'),
      );
    }

    if (mathTags.length > 0 || mathTagsNS.length > 0) {
      // inject MathJaxConf
      const config = {
        displayAlign: 'inherit',
        showProcessingMessages: false,
        messageStyle: 'none',
        showMathMenu: false,
        showMathMenuMSIE: false,
        SVG: {
          useFontCache: false,
          useGlobalCache: true,
          font: 'STIX-Web',
        },
      };
      const mathJaxConf = doc.createElement('script');
      mathJaxConf.type = 'text/x-mathjax-config';
      mathJaxConf.textContent = [
        'MathJax.Hub.Config(' + JSON.stringify(config) + ');',
      ].join('');
      body.appendChild(mathJaxConf);

      const script = doc.createElement('script');
      script.type = 'text/javascript';
      script.src = MATHJAX_CDN;
      body.appendChild(script);

      // Inject script that looks for MathJax.
      // Once it is loaded, call MathJax.Hub.Configured() to process
      const mathJaxInit = doc.createElement('script');
      mathJaxConf.setAttribute('id', 'reader-mathjax-loader');
      mathJaxInit.type = 'text/javascript';
      mathJaxInit.textContent = [
        'var mathJaxInterval = setInterval(function(){',
        'if(window.MathJax){',
        'window.MathJax.Hub.Configured();',
        'clearInterval(mathJaxInterval);',
        '}',
        '},200);',
      ].join('');
      body.appendChild(mathJaxInit);
    } else {
      // put in props for MathJax
      // this ensures the DOM will be consistent for annotations
      const mathJaxDiv = doc.createElement('div');
      mathJaxDiv.setAttribute('id', 'MathJax_Message');
      mathJaxDiv.setAttribute('style', 'display: none;');
      body.insertBefore(mathJaxDiv, body.firstChild);
    }
  }
}
