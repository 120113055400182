import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertModule, ButtonModule } from '@mhe/ngx-shared';
import { ReaderModalModule } from '../modal.module';
import { TranslateModule } from '@ngx-translate/core';

import { SimpleMessageModalComponent } from './simple-message-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    AlertModule,
    ReaderModalModule,
    TranslateModule,
  ],
  declarations: [SimpleMessageModalComponent],
  exports: [SimpleMessageModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SimpleMessageModalModule {}
