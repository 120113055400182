export interface LastLocation {
  contextID: string
  location: string | null
  platform: string
  resourceUUID: string
  userID: string
  // timestamps
  createdAt?: string
  updatedAt?: string
}

export const defaultLastLocation: LastLocation = {
  contextID: '',
  location: '',
  platform: '',
  resourceUUID: '',
  userID: '',
};
