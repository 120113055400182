import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { ExtendedComponentStore, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import {
  ToggleBoxActions,
  handleToggleBoxOnParent,
} from '@mhe/reader/components/epub-viewer/state/utils';
import { SearchResult } from '@mhe/reader/models';

import { EpubViewerStore } from '../epub-viewer.store';
import { EpubViewerSearchHighlightUtils } from './search-highlight.utils';
import * as actions from '../epub-viewer.actions';

export interface NarrowRange {
  textNode: Node
  rstart: number
  rend: number
}

@Injectable()
export class EpubViewerSearchHighlightStore
  extends ExtendedComponentStore<
  { result: SearchResult | undefined },
  actions.EpubViewerActions
  >
  implements OnDestroy {
  private docListeners: Array<() => void> = [];

  private readonly epubViewerActions$ = this.epubViewerStore.actions$;

  constructor(
    private readonly epubViewerStore: EpubViewerStore,
    private readonly renderer: Renderer2,
    private readonly util: EpubViewerSearchHighlightUtils,
  ) {
    super({ result: undefined });
  }

  ngOnDestroy(): void {
    this.unsetListeners();
  }

  /** selectors */
  readonly result$ = this.select(({ result }) => result);

  /** updaters */
  readonly setHighlightResult = this.updater((state, result: SearchResult) => ({
    ...state,
    result,
  }));

  readonly resetHighlightResult = this.updater((state) => ({
    ...state,
    result: undefined,
  }));

  /** action effects */
  private readonly _renderSearchHighlight$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(actions.renderComplete),
      withLatestFrom(this.result$),
      filter(([, result]) => Boolean(result)),
      map(([, result]) => result),
      this.util.mapWithIframe,
      tap((highlight) => this.highlightSearchResult$(highlight)),
      logCatchError('_renderSearchHighlight$'),
    ),
  );

  private readonly _applySearchHighlight$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(actions.highlightSearchResult),
      map(({ result }) => result),
      this.util.mapWithIframe,
      tap((highlight) => this.highlightSearchResult$(highlight)),
      logCatchError('_applySearchHighlight$'),
    ),
  );

  /** effects */
  private readonly highlightSearchResult$ = this.effect(
    (
      highlight$: Observable<{
        result: SearchResult
        iframe: HTMLIFrameElement
      }>,
    ) =>
      highlight$.pipe(
        debounceTime(300),
        tap(() => this.unsetListeners()),
        tap(({ result, iframe }) => {
          const doc = iframe.contentDocument as Document;
          let el = doc?.getElementById(result.id);
          let range = doc.createRange();

          if (el) {
            // Validations
            handleToggleBoxOnParent(el, ToggleBoxActions.OPEN_TOGGLEBOX);
            range = this.getRange(doc, el, result) as Range;
          } else {
            // try to find the element by the query
            const targetText = result.query;
            const elements = Array.from<HTMLElement>(
              doc.querySelectorAll('[id^="data-uuid"]'),
            ).filter((node) =>
              node?.innerHTML
                ?.replace(/<[^>]+>/g, '')
                .trim()
                .includes(targetText),
            );
            if (elements.length > 0) {
              el = elements.reduce((a, b) =>
                a.innerText.length < b.innerText.length ? a : b,
              );
              // Validations
              handleToggleBoxOnParent(el, ToggleBoxActions.OPEN_TOGGLEBOX);
              range.selectNode(el);
              el.focus();
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }
          }

          const selection = doc.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);

          this.addSelectionStyle(doc);
          this.setDocumentListeners$(doc);
        }),
        logCatchError('highlightSearchResult$'),
      ),
  );

  private readonly setDocumentListeners$ = this.effect(
    (highlightDoc$: Observable<Document>) =>
      highlightDoc$.pipe(
        this.util.withLatestEpubDocuments,
        tap(([highlightDoc, ...frameDocs]) => {
          frameDocs.forEach((doc) =>
            this.setDocumentListener(doc, highlightDoc),
          );
        }),
        logCatchError('setDocumentListeners$'),
      ),
  );

  private readonly clearDocumentListeners$ = this.effect(() =>
    this.epubViewerActions$.pipe(
      ofType(actions.highlightSearchResultReset),
      this.util.withLatestEpubDocuments,
      tap(([, ...frameDocs]) => {
        this.resetHighlightResult();
        this.unsetListeners();
        frameDocs.forEach((doc) => this.removeSelectionStyle(doc));
      }),
      logCatchError('clearDocumentListeners$'),
    ),
  );

  /** helpers */
  private getRange(
    doc: Document,
    el: HTMLElement,
    result: SearchResult,
  ): Range | null {
    const { query, occurrence } = result;

    const range = doc.createRange();
    if (el) range.selectNode(el);

    if (el.childNodes.length) {
      const childNodes = Array.from(el.childNodes);
      const { textNode, rstart, rend } = this.narrowRange(
        childNodes,
        query,
        occurrence,
      ) as NarrowRange;

      if (!textNode) return null;

      range.setStart(textNode, rstart);
      range.setEnd(textNode, rend);
    }

    return range;
  }

  private narrowRange(
    nodes: ChildNode[],
    query: string,
    occurrence: number,
  ): NarrowRange | null {
    const [textNode, n] = this.findTextNode(nodes, query, occurrence);

    if (textNode) {
      const rstart = this.getOccurenceIndex(
        textNode.textContent as string,
        query,
        n as number,
      );
      const rend = rstart + query.length;
      return { textNode, rstart, rend };
    }

    return null;
  }

  private findTextNode(
    childnodes: Node[],
    query: string,
    occurence: number,
  ): [Node, number] | [] {
    let lookup: [Node, number] | undefined;
    let searchOccurences = 0;

    /** recursive lookup */
    const traverseTextNodes = (nodes: Node[]): [Node, number] | undefined => {
      for (const node of nodes) {
        if (!node?.nodeName) continue;

        if (this.isTextNode(node)) {
          const nodeOccurences = this.getOccurrences(node, query);
          searchOccurences += nodeOccurences;

          if (occurence <= searchOccurences) {
            const nodeOccurence =
              occurence - (searchOccurences - nodeOccurences);
            return [node, nodeOccurence] as [Node, number];
          }
        }

        const children = Array.from(node.childNodes);

        if (children.length > 0) {
          const childLookup = traverseTextNodes(children);
          if (childLookup) return childLookup;
        }
      }
    };

    // eslint-disable-next-line prefer-const
    lookup = traverseTextNodes(childnodes);
    return lookup ?? [];
  }

  private isTextNode(node: Node): boolean {
    return node.nodeType === Node.TEXT_NODE;
  }

  private getOccurenceIndex(
    text: string,
    query: string,
    occurence: number,
  ): number {
    text = text.toLowerCase();
    query = query.toLowerCase();

    const l = text.length;
    let i = -1;

    while (occurence-- && i++ < l) {
      i = text.indexOf(query, i);
      if (i < 0) break;
    }
    return i;
  }

  private getOccurrences(node: Node, query: string): number {
    const regex = new RegExp(`(${query})`, 'gi');
    const matches = node.textContent?.match(regex);
    const count = (matches ?? []).length;

    return count;
  }

  // style class
  private addSelectionStyle(doc: Document): void {
    const body = doc.querySelector('body');
    this.renderer.addClass(body, 'rdrx-search-selection');
  }

  private removeSelectionStyle(doc: Document): void {
    const body = doc.querySelector('body');
    if (body) {
      this.renderer.removeClass(body, 'rdrx-search-selection');
      doc.getSelection()?.removeAllRanges();
    }
  }

  // event listerners
  private setDocumentListener(doc: Document, highlightDoc: Document): void {
    const listener = this.renderer.listen(doc, 'mousedown', () => {
      this.removeSelectionStyle(highlightDoc);
      this.resetHighlightResult();
      this.unsetListeners();
    });

    this.docListeners = [...this.docListeners, listener];
  }

  private unsetListeners(): void {
    this.docListeners?.forEach((unlisten) => unlisten());
  }
}
