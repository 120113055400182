import { DoubleSpineItem, SpineItem } from '@mhe/reader/models';
import { createAction, props } from '@ngrx/store';

import { AnalyticsActions } from './analytics.actions';
import { AssignmentActions } from './assignment.actions';
import { EpubControlsActions } from './epub-controls.actions';
import { ToolbarActions } from './toolbar.actions';
import { AllowedLevel } from '@mhe/reader/types';
import { PrintActions } from './print.actions';

export const init = createAction(
  'Initialize',
  props<{ url: string, spineId?: string, hash?: string }>(),
);
export const initComplete = createAction('Initialize Complete');

export const changeContentLevel = createAction(
  'Change Content Level',
  props<{ level: AllowedLevel }>(),
);

export const setSpineItem = createAction(
  'Set Active Spine Item',
  props<{ spineItem: SpineItem, hash?: string, setFocus?: boolean }>(),
);
export const setDoubleSpineItem = createAction(
  'Set Active DoubleSpine Item',
  props<{ spineItem: DoubleSpineItem }>(),
);

export const log = createAction(
  'Log data to new relic',
  props<{ payload: object }>(),
);

// drawers
export const setLeftDrawer = createAction(
  'Set Left Drawer Open State',
  props<{ open: boolean }>(),
);
export const setRightDrawer = createAction(
  'Set Right Drawer Open State',
  props<{ open: boolean }>(),
);
export const toggleLeftDrawer = createAction('Toggle Left Drawer Open State');
export const toggleRightDrawer = createAction('Toggle Right Drawer Open State');
export const leftDrawerClosed = createAction('Left Drawer Closed');
export const rightDrawerClosed = createAction('Right Drawer Closed');
export const toggleAlbumMode = createAction(
  'Toggle Album Mode State',
  props<{ userRequested: boolean }>(),
);
export const increaseEpubZoomLevel = createAction(
  'Increase Epub Zoom Level State',
);
export const decreaseEpubZoomLevel = createAction(
  'Decrease Epub Zoom Level State',
);

export const toggleAiAssistPanel = createAction('Toggle AI Reader UI Open State');
export const startAiAssistCitation = createAction(
  'Start AI Reader Citation',
  props<{ cfi: string, nodeUuids: string[] }>(),
);
export const unpaintAiAssistCitation = createAction('Unpaint AI Reader Citation');
export const paintAiAssistCitation = createAction('Paint AI Reader Citation');

export const preRender = createAction('ePub Content preRender Hook');
export const postRender = createAction('ePub Content postRender Hook');

export type ReaderActions =
  | AnalyticsActions
  | AssignmentActions
  | ToolbarActions
  | EpubControlsActions
  | PrintActions
  | ReturnType<
  | typeof init
  | typeof initComplete
  | typeof preRender
  | typeof postRender
  | typeof changeContentLevel
  | typeof setLeftDrawer
  | typeof setRightDrawer
  | typeof leftDrawerClosed
  | typeof rightDrawerClosed
  | typeof setSpineItem
  | typeof setDoubleSpineItem
  | typeof toggleLeftDrawer
  | typeof toggleRightDrawer
  | typeof toggleAlbumMode
  | typeof increaseEpubZoomLevel
  | typeof decreaseEpubZoomLevel
  | typeof toggleAiAssistPanel
  | typeof startAiAssistCitation
  | typeof unpaintAiAssistCitation
  | typeof paintAiAssistCitation
  | typeof log
  >;
