import { createReducer, on } from '@ngrx/store';

import { SessionStart, SessionStarted } from './session.actions';
import { initialSessionState } from './session.state';

export const SESSION_FEATURE_KEY = 'session';

export const sessionReducer = createReducer(
  initialSessionState,
  on(SessionStart, (state, { session }) => ({
    ...state,
    session: { ...state.session, ...session },
  })),
  on(SessionStarted, (state, { session }) => ({
    ...state,
    session,
  })),
);
