/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { LtiParams } from '@mhe/reader/models';
import { Xid } from '@dle/common-urn';

import { environment } from 'src/environments/environment';
import { getIntegratorURL } from '@mhe/reader/core/utils/get-integrator-url.function';

export interface PendoConfig {
  visitor: {
    id: string
    roles: string
    epubId: string
    client: string
    environment: string
    readerIntegratorURL: string | null
  }
  account?: {
    id: string
  }
  additionalApiKeys?: string[]
}

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  readerIntegratorURL: string | null;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) readonly documentRef: Document,
  ) {
    this.readerIntegratorURL = getIntegratorURL(windowRef, documentRef);
  }

  private generateSnippet(apiKey: string): string {
    return `
      (function (apiKey) {
        (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                        function () {
                            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                        };
                })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = 'https://content.data.mheducation.com/agent/static/' + '${apiKey}' + '/pendo.js';
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })('${apiKey}');
    `;
  }

  injectSnippet(params: LtiParams, callback?): void {
    if (
      !params.custom_client_pendo_api_key &&
      !params.custom_reader_pendo_api_key
    ) {
      console.warn('Pendo could not start');
      return;
    }

    if (!params.custom_reader_pendo_client_list) {
      console.warn('Pendo not initiated due to invalid client list');
      return;
    }

    const cleanedList = params.custom_reader_pendo_client_list.replace(
      /\s/g,
      '',
    );
    const clientList = cleanedList.split(',');
    // Validate the oauth_consumer_key is in the Pendo whitelist from AWS Param Store
    if (!clientList.includes(params.oauth_consumer_key)) {
      console.warn('Pendo not initiated due to invalid oauth_consumer_key');
      return;
    }

    // Use personXID if available or fallback to userID
    try {
      this.ensurePersonXIDOrUserIDIsAXID(params.custom_person_xid, params.user_id);
    } catch (e) {
      console.warn('Pendo not initiated as neither the personXID nor the userID is a valid XID', e);
      return;
    }

    const primaryApiKey =
      params.custom_client_pendo_api_key || params.custom_reader_pendo_api_key;
    const pendoSnippet = this.generateSnippet(primaryApiKey);

    const pendoScript = this.documentRef.createElement('script');
    pendoScript.id = 'pendo-analytics';
    pendoScript.text = pendoSnippet;
    this.documentRef.body.appendChild(pendoScript);

    if (callback) {
      callback();
    }
  }

  initialize(params: LtiParams): void {
    // Bail if no pendo or pendo has already been initialized
    if (
      !(this.windowRef as any).pendo ||
      !!(this.windowRef as any).pendo.accountId
    ) {
      return;
    }

    // determine a XID to use for Pendo integration
    let idToUse;
    try {
      idToUse = this.determinePersonXIDOrUserIDToUse(params.custom_person_xid, params.user_id);
      if (!idToUse) {
        console.warn('Pendo config error');
        return;
      }
    } catch (e) {
      // if there is an exception, we want Pendo to not initialize but the app should still work
      console.warn('Pendo config error');
      return;
    }

    let pendoInitializeConfig: PendoConfig = {
      visitor: {
        id: idToUse,
        roles: params.roles,
        epubId: params.custom_epub_url,
        client: params.custom_partner_id,
        environment: environment.production ? 'prod' : 'non-prod',
        readerIntegratorURL: this.readerIntegratorURL,
      },
    };

    if (params.custom_org_xid) {
      pendoInitializeConfig = {
        ...pendoInitializeConfig,
        account: { id: params.custom_org_xid },
      };
    }

    if (
      params.custom_client_pendo_api_key &&
      params.custom_reader_pendo_api_key
    ) {
      pendoInitializeConfig = {
        ...pendoInitializeConfig,
        additionalApiKeys: [params.custom_reader_pendo_api_key],
      };
    }

    if (
      params.custom_client_pendo_api_key &&
      !params.custom_reader_pendo_api_key
    ) {
      console.warn('Pendo secondary API key was not provided');
    }

    (this.windowRef as any).pendo.initialize(pendoInitializeConfig);
    (this.windowRef as any).pendo.disableGuides = true;
  }

  /**
   * This function ensures that either personXID or the userID is a valid XID.
   * Result in an error otherwise.
   *
   * @param personXID
   * @param userID
   */
  ensurePersonXIDOrUserIDIsAXID(personXID: string, userID: string): boolean {
    const idToUse = this.determinePersonXIDOrUserIDToUse(personXID, userID);
    if (idToUse) {
      return true;
    }
    return false;
  }

  /**
   * This function does 3 things.
   * 1. Checks that either the personXID or userID exists and is a valid XID.
   * 2. Return a valid xid among the two
   * 3. Result in an error if either of them is not a valid XID
   *
   * @param personXID
   * @param userID
   */
  determinePersonXIDOrUserIDToUse(personXID: string, userID: string): string {
    // Check if personXID exists and a valid XID, if not, fallback to userID
    if (personXID) {
      try {
        Xid.parse(personXID);
        return personXID;
      } catch (e) {
        // ignore this exception as we still want to check is userID is a valid XID
      }
    }

    // validate userID for valid XID
    Xid.parse(userID);
    return userID;
  }
}
