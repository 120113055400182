/* eslint-disable @typescript-eslint/quotes */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule, ButtonPurpose } from '@mhe/ngx-shared';

@Component({
  selector: 'rdrx-annotation-list-footer',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
  ],
  template: `
    <div
      id="annotation-list-footer"
    >
      <button
        mhe-button
        id="delete-button"
        class="btn"
        [purpose]="ButtonPurpose.Primary"
        [disabled]="disableDeleteBtn"
        (click)="deleteClick.emit()"
      >
        Delete
      </button>
      <button
        id="cancel-button"
        class="btn btn-secondary"
        mhe-button
        (click)="cancelClick.emit()"
      >
        Cancel
      </button>
    </div>
  `,
  styleUrls: ['./annotation-list-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationListFooterComponent {
  @Input({ required: true }) disableDeleteBtn!: boolean;
  @Output() deleteClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  ButtonPurpose = ButtonPurpose;
}
