import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import {
  EBookshelfConcurrencyResponse,
  isLtiParams,
  LogoutResponse,
  LtiParams,
  PlayerApiClientError,
  TTSChunk,
  TTSChunkCollection,
  TTSRawTextRequest,
  TTSRawTextTranslationResponse,
  TTSRequest,
} from '@mhe/reader/models';
import * as readerConfigQuery from '../state/configuration/configuration.selectors';

@Injectable({ providedIn: 'root' })
export class PlayerApiClient {
  private readonly baseUrl$: Observable<string | undefined>;

  constructor(store: Store, private readonly http: HttpClient) {
    this.baseUrl$ = store.pipe(
      select(readerConfigQuery.getPlayerApi),
      filter((s: string | undefined) => typeof s === 'string' && s.length > 0),
      first(),
    );
  }

  fetchLTIParams(): Observable<LtiParams> {
    return this.baseUrl$.pipe(
      mergeMap((baseUrl) =>
        this.http.get<LtiParams>(`${baseUrl}/lti`, {
          withCredentials: true,
          headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
        }),
      ),
      first(),
      map((rsp) => {
        if (!isLtiParams(rsp)) {
          throw new PlayerApiClientError(
            'get',
            '/lti',
            'Response is not a valid set of LTI parameters',
          );
        }
        return rsp;
      }),
    );
  }

  checkEbookshelfConcurrency(): Observable<EBookshelfConcurrencyResponse> {
    return this.baseUrl$.pipe(
      mergeMap((baseUrl) => {
        return this.http.get<EBookshelfConcurrencyResponse>(
          `${baseUrl}/concurrency`,
          {
            withCredentials: true,
            headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
          },
        );
      }),
    );
  }

  /**
   * Sends content to be translated for text-to-speech. In
   * the response, the first chunk mp3 url is provided and
   * the metadata.requestCacheKey is used to poll an endpoint
   * for completion of the remaining chunks.
   */
  createTextToSpeechJob(params: TTSRequest): Observable<TTSChunk | null> {
    return this.baseUrl$.pipe(
      mergeMap((playerApiBaseUrl) => {
        const url = `${playerApiBaseUrl}/text-to-speech`;
        const formData = new FormData();
        Object.keys(params).forEach((key) => formData.append(key, params[key]));

        return this.http.post<TTSChunk>(url, formData, {
          withCredentials: true,
          headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
        });
      }),
    );
  }

  /**
   * Obtains status on an existing text-to-speech job. The resonse contains
   * multiple ordered chunks
   */
  fetchTextToSpeechJob(
    requestCacheKey: string,
  ): Observable<TTSChunkCollection> {
    return this.baseUrl$.pipe(
      mergeMap((playerApiBaseUrl) => {
        const url = `${playerApiBaseUrl}/text-to-speech/status`;
        return this.http.get<TTSChunkCollection>(url, {
          params: {
            requestCacheKey,
          },
          withCredentials: true,
          headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
        });
      }),
    );
  }

  translateRawTextToSpeech(
    params: TTSRawTextRequest,
  ): Observable<TTSRawTextTranslationResponse> {
    return this.baseUrl$.pipe(
      mergeMap((playerApiBaseUrl) => {
        const url = `${playerApiBaseUrl}/readspeaker`;
        const formData = new FormData();
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        return this.http.post<TTSRawTextTranslationResponse>(url, formData, {
          withCredentials: true,
          headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
        });
      }),
    );
  }

  logout(): Observable<LogoutResponse> {
    return this.baseUrl$.pipe(
      mergeMap((baseUrl) => {
        return this.http.get<LogoutResponse>(`${baseUrl}/logout`, {
          withCredentials: true,
          headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
        });
      }),
    );
  }
}
