import { createSelector } from '@ngrx/store';
import { coreState } from '../core.selectors';
import { CONFIGURATION_FEATURE_KEY } from './configuration.reducer';

export const configState = createSelector(coreState, (s) => s[CONFIGURATION_FEATURE_KEY]);

export const runtime = createSelector(configState, (s) => s.runtime);
export const features = createSelector(configState, (s) => s.features);
export const environment = createSelector(configState, (s) => s.environment);

export const getCustomTOC = createSelector(runtime, (r) => r.toc);
export const getCustomLocation = createSelector(runtime, (r) => r.location);
export const getCustomNavigation = createSelector(runtime, (r) => r.navigation);
export const getCustomSearch = createSelector(runtime, (r) => r.search);
export const getCustomOverflow = createSelector(runtime, (r) => r.overflow);
export const getLinks = createSelector(runtime, (r) => r.links);
export const getFontResizer = createSelector(runtime, (r) => r.fontResizer);
export const getBackButton = createSelector(runtime, (r) => r.backButton);
export const getCustomHighlights = createSelector(runtime, (r) => r.highlights);
export const getCustomNotes = createSelector(runtime, (r) => r.notes);
export const getCustomPlacemarks = createSelector(runtime, (r) => r.placemarks);
export const getContentWidth = createSelector(runtime, (r) => r.contentWidth);
export const getDefaultLevel = createSelector(runtime, (r) => r.levelDefault);
export const getAllLevels = createSelector(runtime, (r) => r.displayLevels);
export const getCustomScrubber = createSelector(runtime, (r) => r.scrubber);
export const getCustomAssignment = createSelector(runtime, (r) => r.assignment);
export const getCustomLastReopen = createSelector(runtime, (r) => r.lastReopen);
export const isNavbarFixed = createSelector(runtime, (r) => r.navbarFixed);
export const getContentType = createSelector(runtime, (r) => r.contentType);
export const getCustomCfi = createSelector(runtime, (r) => r.cfi);
export const getNavigationType = createSelector(runtime, (r) => r.navigationType);
export const getCustomInterfaceMode = createSelector(runtime, (r) => r.interfaceMode);
export const isLastLocationEnabled = createSelector(runtime, (r) => r.lastLocation);
export const isContextMenu2Enabled = createSelector(runtime, (r) => r.contextMenu2);
export const getUserId = createSelector(runtime, (r) => r.userId);
export const getViewMode = createSelector(runtime, (r) => r.viewMode);
export const getCustomDigitalSample = createSelector(runtime, (r) => r.digitalSample);

export const getWidgetExpandFlag = createSelector(runtime, (r) => r.widgetExpand);

export const getCustomReadspeaker = createSelector(runtime, features, (r, f) => {
  return f.readspeaker && r.readspeaker;
});

export const isNavbarEnabled = createSelector(
  getCustomTOC,
  getCustomNavigation,
  getCustomLocation,
  getCustomSearch,
  getFontResizer,
  getBackButton,
  runtime,
  (isToc, isNavigation, isLocation, isSearch, isFontresizer, isBackButton, r) => {
    // I think this logic is the same as
    // (isToc || isNavigation || isLocation || isSearch || isFontresizer || isBackButton) && r.navbar
    let result = true;
    if (!isToc && !isNavigation && !isLocation && !isSearch && !isFontresizer && !isBackButton) {
      result = false;
    }
    if (!r.navbar) {
      result = false;
    }
    return result;
  },
);

export const isTTSHighlightingEnabled = createSelector(runtime, (r) => r.ttsHighlighting);

export const getLocale = createSelector(runtime, (r) => r.locale);

export const getServices = createSelector(environment, (e) => e.services);
export const getSdlc = createSelector(environment, (e) => e.sdlc);
export const getPlayerApi = createSelector(getServices, (s) => s.PlayerAPI);
export const getReaderApi = createSelector(runtime, getServices,
  (r, s) => r.darkVersion ? s.MHEDarkReaderAPI : s.MHEReaderAPI);
