export enum DropdownState {
  ALL,
  DETACHED,
}

export enum AnnotationType {
  HIGHLIGHT,
  NOTE,
  PLACEMARK,
}
