import { Injectable } from '@angular/core';
import { TranslateAdapter } from '@mhe/ngx-shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReaderTranslateAdapter extends TranslateAdapter {
  constructor(private readonly translateService: TranslateService) {
    super();
  }

  translate(key: string, context: any, language?: string): Observable<string> {
    return this.translateService.get(key, context);
  }
}
