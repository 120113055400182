import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mapTo, tap, withLatestFrom } from 'rxjs/operators';

import { MediatorUtils } from './mediator-utils';
import { ReaderStore } from '@mhe/reader/components/reader/state';
import * as readerActions from '@mhe/reader/components/reader/state/reader.actions';

@Injectable()
export class DrawerMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;

  constructor(
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly readerStore: ReaderStore,
    private readonly translate: TranslateService,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  // set
  private readonly setLeftDrawer$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setLeftDrawer),
      tap(({ open }) => this.readerStore.setLeftDrawerOpen(open)),
      logCatchError('setLeftDrawer$'),
    ),
  );

  private readonly setRightDrawer$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setRightDrawer),
      map(({ open }) => this.readerStore.setRightDrawerOpen(open)),
      logCatchError('setRightDrawer$'),
    ),
  );

  // toggle
  private readonly toggleLeftDrawer$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.toggleLeftDrawer),
      withLatestFrom(this.readerStore.leftDrawerOpen$),
      map(([, open]) => !open),
      tap((open) => this.util.leftDrawer(open)),
      tap(() => {
        this.readerStore.dispatch(readerActions.setRightDrawer({ open: false }));
      }),
      logCatchError('toggleLeftDrawer$'),
    ),
  );

  private readonly toggleRightDrawer$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.toggleRightDrawer),
      withLatestFrom(this.readerStore.rightDrawerOpen$),
      map(([, open]) => !open),
      tap((open) => this.util.rightDrawer(open)),
      tap(() => {
        this.readerStore.dispatch(readerActions.setLeftDrawer({ open: false }));
      }),
      logCatchError('toggleRightDrawer$'),
    ),
  );

  // a11y
  private readonly _announceLeftDrawer$ = this.effect(() =>
    this.readerStore.leftDrawerOpen$.pipe(
      filter((open) => open),
      mapTo(this.translate.instant('contents.contents_dialog')),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/promise-function-async
      tap((words) => this.liveAnnouncer.announce(words)),
    ),
  );

  private readonly _announceRightDrawer$ = this.effect(() =>
    this.readerStore.rightDrawerOpen$.pipe(
      filter((open) => open),
      mapTo(this.translate.instant('search.search_dialog')),
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/promise-function-async
      tap((words) => this.liveAnnouncer.announce(words)),
    ),
  );
}
