import { createReducer, on } from '@ngrx/store';

import {
  SetAllEnvironmentConfig,
  SetAllFeatureFlags,
  SetAllRuntimeFeatures,
  SetPartialEnvironmentConfig,
  SetPartialFeatureFlags,
  SetPartialRuntimeFeatures,
} from './configuration.actions';
import { initialConfigurationState } from './configuration.state';

export const CONFIGURATION_FEATURE_KEY = 'configuration';

export const configurationReducer = createReducer(
  initialConfigurationState,
  on(SetAllRuntimeFeatures, (state, { config }) => ({
    ...state,
    runtime: config,
  })),
  on(SetPartialRuntimeFeatures, (state, { config }) => ({
    ...state,
    runtime: { ...state.runtime, ...config },
  })),
  on(SetAllFeatureFlags, (state, { features }) => ({ ...state, features })),
  on(SetPartialFeatureFlags, (state, { features }) => {
    return {
      ...state,
      features: { ...state.features, ...features },
    };
  }),
  on(SetAllEnvironmentConfig, (state, { environment }) => ({ ...state, environment })),
  on(SetPartialEnvironmentConfig, (state, { environment }) => {
    return {
      ...state,
      environment: { ...state.environment, ...environment },
    };
  }),
);
