import { NgModule } from '@angular/core';

import { BonsaiNodeSelectDirective } from './bonsai/bonsai-node-select.directive';
import { BonsaiTabIndexDirective } from './bonsai/bonsai-tabindex.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { TruncateDirective } from './truncate/truncate.directive';

@NgModule({
  imports: [],
  declarations: [
    BonsaiNodeSelectDirective,
    BonsaiTabIndexDirective,
    StopPropagationDirective,
    TruncateDirective,
  ],
  exports: [
    BonsaiNodeSelectDirective,
    BonsaiTabIndexDirective,
    StopPropagationDirective,
    TruncateDirective,
  ],
})
export class CommonDirectivesModule {}
