import { Injectable } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class EnsureLanguageAttributeTransformer implements Transformer {
  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const htmlElement = content.querySelector('html');
    if (!htmlElement) {
      return await next(content);
    }
    const lang = htmlElement.getAttribute('lang');
    if (!lang) {
      const bookLang = (book.metadata?.language) || 'en';
      htmlElement.setAttribute('lang', bookLang);
    }
    return await next(content);
  }
}
