import { NgModule } from '@angular/core';
import { ReaderModalFooterModule } from './modal-footer/modal-footer.module';
import { ReaderModalHeaderModule } from './modal-header/modal-header.module';

// TODO reorganize this module after migration
@NgModule({
  imports: [ReaderModalHeaderModule, ReaderModalFooterModule],
  declarations: [],
  exports: [ReaderModalHeaderModule, ReaderModalFooterModule],
})
export class ReaderModalModule {}
