import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@mhe/ngx-shared';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationModalComponent } from './confirmation-modal.component';
import { ReaderModalModule } from '../modal.module';

@NgModule({
  imports: [CommonModule, MatDialogModule, TranslateModule, ButtonModule, ReaderModalModule],
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmationModalModule {}
