/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Injectable } from '@angular/core';

import { ExtendedComponentStore } from '@mhe/reader/common';
import {
  BonsaiTree,
  Bonsai,
  Tag,
  TagNode,
  TagType,
  TagLocation,
  HeaderId,
} from '@mhe/reader/models';
import { Observable } from 'rxjs';
import { TopicsActions } from './topics.actions';
import {
  TopicsState,
  initialState,
  locationsViewInitialState,
} from './topics.state';

@Injectable()
export class TopicsStore extends ExtendedComponentStore<
TopicsState,
TopicsActions
> {
  readonly topicsTree$ = this.select((state) => state.topicsTree);
  readonly currentSpineId$: Observable<string> = this.select(
    (state) => state.currentSpineId,
  );

  readonly selectedLocation$: Observable<TagLocation | null> = this.select(
    (state) => state.selectedLocation,
  );

  readonly selectedTopic$ = this.select((state) => state.selectedTopic);
  readonly expandedSubjects$ = this.select((state) => state.expandedSubjects);
  readonly locationsView$ = this.select((state) => state.locationsView);
  readonly isLocationsView$ = this.select((state) => state.isLocationsView);
  readonly errorLoadingSubjects$ = this.select((state) => state.errorLoading);

  constructor() {
    super(initialState);
  }

  setErrorLoadingSubjects = this.updater((state) => ({
    ...state,
    errorLoading: true,
  }));

  readonly setCurrentSpineId = this.updater(
    (state, spineId: string): TopicsState => {
      return { ...state, currentSpineId: spineId };
    },
  );

  readonly activeBonsaiNodeId$ = this.select(
    this.currentSpineId$,
    this.selectedLocation$,
    (currentSpineId, selectedLocation) => {
      return selectedLocation?.cfi.includes(currentSpineId)
        ? currentSpineId
        : '';
    },
  );

  readonly setSelectedLocation = this.updater(
    (state, selectedLocation: TagLocation | null): TopicsState => {
      return { ...state, selectedLocation };
    },
  );

  readonly setSelectedTopic = this.updater(
    (state, selectedTopic: string): TopicsState => {
      return { ...state, selectedTopic };
    },
  );

  readonly setSubjects = this.updater((state, subjects: Tag[]): TopicsState => {
    const subjectUuids = subjects.map((subject) => {
      return subject.uuid;
    });

    const rootNode = {
      id: Bonsai.RootNode,
      childIds: [...subjectUuids],
    };

    const subjectsWithIds = this.addIds(subjects);

    const subjectsWithTitles = this.addTitles(subjectsWithIds);

    const subjectsWithEmptyChildIds =
      this.addEmptyChildIdsTo(subjectsWithTitles);

    const subjectBonsaiNodes = this.createBonsaiNodes(
      subjectsWithEmptyChildIds,
    );

    const newTopicsTree = {
      [Bonsai.RootNode]: rootNode,
      ...subjectBonsaiNodes,
    };

    const newState = { ...state, topicsTree: newTopicsTree };

    return newState;
  });

  readonly setTopics = this.updater((state, subjectIdAndResponse: any) => {
    const { subjectId } = subjectIdAndResponse;

    let { tags } = subjectIdAndResponse;

    tags = this.addIds(tags);

    tags = this.addTitles(tags);

    tags = tags.map((tag) => {
      tag.collapsible = false;

      return tag;
    });

    const matchingTagIds = tags
      .filter((tag) => {
        return subjectId === tag.parent && tag.type === TagType.TOPIC;
      })
      .map((tag) => {
        return tag.id;
      });

    let childIds = state.topicsTree[subjectId].childIds;

    childIds = childIds?.concat(
      matchingTagIds.reduce((acc, tagId) => {
        if (!childIds?.includes(tagId)) {
          acc.push(tagId);

          return acc;
        }

        return acc;
      }, []),
    );

    state.topicsTree[subjectId].childIds = childIds;

    const topicsBonsaiNodes = this.createBonsaiNodes(tags);

    const topicsTree = {
      ...state.topicsTree,
      ...topicsBonsaiNodes,
    };

    this.setSubjectLoadingState({ subjectId, loading: false });

    const newState = { ...state, topicsTree };
    return newState;
  });

  readonly setSubjectLoadingState = this.updater(
    (state, value: { subjectId: string, loading: boolean }) => {
      return {
        ...state,
        topicsTree: {
          ...state.topicsTree,
          [value.subjectId]: {
            ...state.topicsTree[value.subjectId],
            loading: value.loading,
          },
        },
      };
    },
  );

  readonly setTopicLocations = this.updater(
    (state, { locationsWithLabels }: any) => {
      const currentTopicNode = {
        ...state.currentTopicNode,
        locations: locationsWithLabels,
      };

      const newState = { ...state, currentTopicNode };

      return newState;
    },
  );

  readonly setInitialSubjectId = this.updater(
    (state, initialSubjectId: string | undefined): TopicsState => {
      return { ...state, initialSubjectId };
    },
  );

  readonly initialSubjectId$ = this.select((state) => state.initialSubjectId);

  readonly updateExpandedSubjects = this.updater(
    (state, { id }: { id: string }) => ({
      ...state,
      expandedSubjects: {
        ...state.expandedSubjects,
        [id]: !state.expandedSubjects[id],
      },
    }),
  );

  readonly setLocationsView = this.updater(
    (state, { isLocationsView }: { isLocationsView: boolean }) => ({
      ...state,
      isLocationsView,
    }),
  );

  readonly setCurrentSubjectNode = this.updater(
    (state, { node }: { node: any }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        currentSubjectNode: node,
      },
    }),
  );

  readonly setCurrentTopicNode = this.updater(
    (state, { node }: { node: any }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        currentTopicNode: node,
      },
    }),
  );

  readonly setLocationsTree = this.updater(
    (state, { locationsTree: newLocationsTree }: { locationsTree: any }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        locationsTree: {
          ...state.locationsView.locationsTree,
          ...newLocationsTree,
        },
      },
    }),
  );

  readonly setChildsLocationsTree = this.updater(
    (state, { treeRoot, childIds }: { treeRoot: any, childIds: string[] }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        locationsTree: {
          ...state.locationsView.locationsTree,
          [treeRoot]: {
            ...state.locationsView.locationsTree[treeRoot],
            childIds: [...childIds],
          },
        },
      },
    }),
  );

  readonly setCasesPatternsAndAlgorithmsTree = this.updater(
    (
      state,
      { casesPatternsTree: newCasesPatternsTree }: { casesPatternsTree: any },
    ) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        casesPatternsTree: {
          ...state.locationsView.casesPatternsTree,
          ...newCasesPatternsTree,
        },
      },
    }),
  );

  readonly setChildsCasesPatternsAndAlgorithmsTree = this.updater(
    (state, { treeRoot, childIds }: { treeRoot: any, childIds: string[] }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        casesPatternsTree: {
          ...state.locationsView.casesPatternsTree,
          [treeRoot]: {
            ...state.locationsView.casesPatternsTree[treeRoot],
            childIds: [...childIds],
          },
        },
      },
    }),
  );

  readonly updateExpandedCasesPatterns = this.updater(
    (state, { expandedCasesPatternsId }: { expandedCasesPatternsId: any }) => ({
      ...state,
      locationsView: {
        ...state.locationsView,
        expandedCasesPatterns: {
          ...state.locationsView.expandedCasesPatterns,
          [expandedCasesPatternsId]:
            !state.locationsView.expandedCasesPatterns[expandedCasesPatternsId],
        },
      },
    }),
  );

  readonly resetCurrentLocationsView = this.updater((state) => ({
    ...state,
    locationsView: locationsViewInitialState,
  }));

  readonly removeEmptyCasesAndPatterns = this.updater((state) => {
    const { locationsView: newLocationView, ...currentState } = state;

    if (
      state.locationsView?.casesPatternsTree[HeaderId.CASES]?.childIds
        ?.length === 0
    ) {
      delete newLocationView.casesPatternsTree[HeaderId.CASES];
    }

    if (
      state.locationsView?.casesPatternsTree[HeaderId.PATTERNS]?.childIds
        ?.length === 0
    ) {
      delete newLocationView.casesPatternsTree[HeaderId.PATTERNS];
    }

    if (
      state.locationsView?.casesPatternsTree[HeaderId.ALGORITHMS]?.childIds
        ?.length === 0
    ) {
      delete newLocationView.casesPatternsTree[HeaderId.ALGORITHMS];
    }

    return {
      ...currentState,
      locationsView: {
        ...newLocationView,
      },
    };
  });

  readonly addIds = (tags: Tag[]): Tag[] => {
    const tagsWithIds = tags.map((tag) => {
      tag.id = tag.uuid;
      tag.collapsible = true;
      tag.loading = false;
      return tag;
    });

    return tagsWithIds;
  };

  readonly addTitles = (tags: Tag[]): Tag[] => {
    const tagsWithTitles = tags.map((tag) => {
      tag.title = tag.name;

      return tag;
    });

    return tagsWithTitles;
  };

  readonly addEmptyChildIdsTo = (subjects: Tag[]): Tag[] => {
    const subjectsWithEmptyChildIds = subjects.map((subject) => {
      subject.childIds = [];

      return subject;
    });

    return subjectsWithEmptyChildIds;
  };

  createBonsaiNodes(tags: Tag[]): BonsaiTree<TagNode> {
    const nodes = tags.reduce((acc, tag) => {
      acc[tag.uuid] = tag;

      return acc;
    }, {});

    return nodes;
  }
}
