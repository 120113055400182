import { Injectable } from '@angular/core';
import { IframeBuilderConfiguration } from './iframe.model';
import { IframeInjectorService } from './iframe-injector.service';
import { IframeCoreService } from './iframe-core.service';
import { MockLocalizedCssService } from './mockLocalizedCss.service';

@Injectable()
export class IframeBuilderService {
  protected helperLibraries: string[] = []; // @todo need to discuss with team
  protected helperCss: string[] = []; // @todo need to discuss with team

  constructor(
    private readonly iframeInjector: IframeInjectorService,
    private readonly iframeCore: IframeCoreService,
    private readonly mockLocalizedCssService: MockLocalizedCssService,
  ) {}

  /**
   * This is the main entry point for transforming the markup for ePub iFrames.
   * Changes made to markup:
   * (1) Converting to HTML document
   * (2) Injecting javascript helper libraries
   * (3) Appending the <base> for relative URLs
   * (4) Enhancing images, links, and audio elements
   * (5) Injecting custom CSS stying
   */
  buildByDocumentNode(
    doc: HTMLDocument,
    opt: IframeBuilderConfiguration,
  ): HTMLDocument {
    // clear any active user styles
    this.iframeInjector.removeStylesheet('user-context-style', doc);

    // inject CSS styles
    this.injectStyles(doc, opt);

    // add default image if not found, add keyboard interaction
    this.iframeInjector.enhanceImage(doc);

    // add 'enhanced' class to MHE pre-recorded inline audio
    this.iframeInjector.enhanceAudio(doc);

    // inject custom scripts
    this.injectScripts(doc, opt);

    return doc;
  }

  /**
   * Converts possible XmlDocument object to be a HtmlDocument.
   */
  convertToHtmlDocument(docObj: XMLDocument | HTMLDocument): HTMLDocument {
    // Check if object is xmlDocument
    if (docObj.constructor === window.XMLDocument) {
      // Convert to HTMLDocument
      // make a dummy HTML and replace all child nodes (<head> and <body>) with
      // the child nodes of the XMLDocument, and copy over the lang attribute.
      const parser = new DOMParser();
      const htmlElmt = docObj.getElementsByTagName('html')[0];
      const lang =
        htmlElmt && htmlElmt.hasAttribute('lang')
          ? htmlElmt.getAttribute('lang')
          : 'en';
      const template = '<html lang="' + lang + '"></html>';
      const htmlDoc = parser.parseFromString(template, 'text/html');
      if (htmlDoc?.documentElement) {
        // The only reason for this condition is to pass unit tests. All modern
        // browsers (>= IE10) are able to parse 'text/html', but not for PhantomJS.
        while (htmlDoc.documentElement.hasChildNodes()) {
          htmlDoc.documentElement.removeChild(
            htmlDoc.documentElement.firstChild as ChildNode,
          );
        }
        while (docObj.documentElement.hasChildNodes()) {
          htmlDoc.documentElement.appendChild(
            docObj.documentElement.firstChild as ChildNode,
          );
        }
        return htmlDoc;
      } else {
        // This does not work with self-closing tags, e.g. <title />
        const tDomString = docObj.documentElement.outerHTML;
        return parser.parseFromString(tDomString, 'text/html') || docObj;
      }
    }
    return docObj;
  }

  // Check if XML failed to parse
  checkForEmptyXML(doc: Document): boolean {
    return !doc || doc.constructor === window.XMLDocument ? !doc.body : false;
  }

  /**
   * Styling to teacher notes.
   *
   * (1) Make all the teacher notes collapsed by adding class 'collapse'
   * (2) Inject style for the teacher notes
   */
  injectTeachingNoteStyle(doc: HTMLDocument): HTMLDocument {
    const elmts = this.iframeCore.getElementsByClass('teacher');
    elmts.forEach((elmt) => {
      elmt.classList.add('collapse');
    });

    this.iframeInjector.appendStyle(
      [
        '.full-bleed.teacher.collapse{max-height:55px;',
        'min-height:55px;min-width:55px;padding:0;overflow:hidden;}',
      ].join(''),
      'teacher-note-style',
      doc,
    );
    return doc;
  }

  /**
   * Injects custom styling to the ePub iFrame.
   */
  injectStyles(doc: HTMLDocument, opt: IframeBuilderConfiguration): void {
    // custom logic for teacher notes
    this.injectTeachingNoteStyle(doc);

    // should CSS be appended?
    if (opt.stylesheetUrl) {
      this.iframeInjector.appendStylesheet(
        opt.stylesheetUrl,
        'lti-launch-stylesheet',
        doc,
      );
    }

    // // Append active stylesheets
    this.iframeCore
      .getActiveStylesheets()
      .forEach((url: string, id: string) => {
        this.iframeInjector.appendStylesheet(url, id, doc);
      });
    // // Append active styles
    this.iframeCore.getActiveStyles().forEach((content: string, id: string) => {
      this.iframeInjector.appendStyle(content, id, doc);
    });

    // Check if helper libraries should be injected
    if (opt.injectHelpers) {
      this.helperCss.forEach((item) => {
        this.iframeInjector.appendStyle(item, 'player-helper-css', doc);
      });

      // Inject localized annotation and teacher css
      this.iframeInjector.appendStyle(
        this.mockLocalizedCssService.data.annotationCss,
        'annotation-css',
        doc,
      );
      this.iframeInjector.appendStyle(
        this.mockLocalizedCssService.data.teacherCss,
        'teacher-css',
        doc,
      );
    }
  }

  /**
   * Injects custom javscripts into ePub iFrame.
   */
  injectScripts(doc: HTMLDocument, opt: IframeBuilderConfiguration): void {
    if (opt.injectHelpers) {
      this.helperLibraries.forEach((item) => {
        this.iframeInjector.appendScript(item, doc);
      });

      // inject nonclick script
      const nonclick = doc.createElement('script');
      nonclick.id = 'nonclick-script';
      nonclick.innerHTML =
        "if(window.attachEvent){document.attachEvent('onclick'," +
        'function(e){e.preventDefault();});} else {' +
        "document.addEventListener('click',function(e){e.preventDefault();});}";
      const body = this.iframeCore.getElementByTagName('body', doc);
      if (body) {
        body.appendChild(nonclick);
      }
    }
  }
}
