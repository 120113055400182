import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import {
  Book,
  FlatTocItem,
  LexileLevelsOption,
  Package,
  ParserOptions,
  Spine,
  SpineItem,
  TocItem,
} from '../../models/epub';
import { RuntimeConfiguration, SearchSubject } from '@mhe/reader/models';
import { spineItemsByHref, spineItemsById } from '@mhe/reader/utils';
import { EPubParser } from './epub-parser.service';
import { SupportedType } from '../../types/supported-types.type';
import { Store } from '@ngrx/store';
import { handleExplorationResource } from '@mhe/reader/common';

@Injectable({ providedIn: 'root' })
export class EPubLoaderService {
  private readonly containerPath = 'META-INF/container.xml';
  private readonly lexilePath = 'META-INF/lexile_levels.xml';
  private readonly factoryMetadataPath = 'metadata.json';

  private readonly defaultOptions = {
    withCredentials: true as const,
    responseType: 'text' as const,
    headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }), // skip the token interceptor
  };

  private readonly domp = new DOMParser();

  readonly getWithOptions$ = (url: string): Observable<any> =>
    this.http.get(url, { ...this.defaultOptions });

  readonly getContent$ = (
    url: string,
    type: SupportedType = 'text/xml',
  ): Observable<XMLDocument> =>
    this.getWithOptions$(url).pipe(
      map((d) => this.domp.parseFromString(d, type)),
    );

  readonly getJsonContent$ = (
    url: string,
  ): Observable<JSON> =>
    this.getWithOptions$(url).pipe(
      map((d) => JSON.parse(d)),
    );

  constructor(
    private readonly parser: EPubParser,
    private readonly http: HttpClient,
    private readonly store: Store,
  ) {}

  buildFromUrl(
    url: string,
    parserOptions: ParserOptions,
    readerConfig: RuntimeConfiguration,
  ): Observable<{ toc: TocItem[], pages: Record<string, string> | null }> {
    let response!: Observable<{
      toc: TocItem[]
      pages: Record<string, string> | null
    }>;
    const uri = this.parser.parseUri(url);
    const { href, base, extension } = uri;

    if (extension === 'opf') {
      response = this.getContent$(href).pipe(
        mergeMap((doc) =>
          this.buildBookFromPackage(this.parser.parsePackage(doc, base)),
        ),
        mergeMap((book) =>
          this.getLexileLevels$(href).pipe(
            map((lexileLevels) => ({ ...book, lexileLevels })),
          ),
        ),
        tap((book: Book) => handleExplorationResource(this.store, book)),
      );
    }

    if (href.endsWith('/')) {
      const contentUrl = `${href}${this.containerPath}`;

      response = this.getContent$(contentUrl).pipe(
        mergeMap((doc) => {
          const container = this.parser.parseContainer(doc, parserOptions);
          const containerUrl = `${base}${container.fullPath}`;

          if (readerConfig.interfaceMode === 'dfa') {
            return this.getContent$(containerUrl).pipe(
              mergeMap((d) =>
                this.buildBookFromPackage(
                  this.parser.parsePackage(d, `${href}${container.basePath}`),
                  container.selectionTags,
                ),
              ),
            );
          }

          return this.getContent$(containerUrl).pipe(
            mergeMap((d) =>
              this.buildBookFromPackage(
                this.parser.parsePackage(d, `${href}${container.basePath}`),
                container.selectionTags,
              ),
            ),
            mergeMap((book) =>
              this.getLexileLevels$(href).pipe(
                map(
                  (lexileLevels) =>
                    ({ ...book, lexileLevels } as unknown as Book),
                ),
              ),
            ),
            tap((book: Book) => handleExplorationResource(this.store, book)),
          );
        }),
      );
    }
    return response;
  }

  loadSearchContent(
    spineItems: SpineItem[],
    flatToc: Record<number, FlatTocItem>,
  ): Observable<SearchSubject[]> {
    const getSpineItemContent = (
      doc: SearchSubject,
    ): Observable<SearchSubject> =>
      this.getWithOptions$(doc.url as string).pipe(
        map((html): SearchSubject => ({ ...doc, html })),
      );

    const searchableSpineItems = spineItems.map((si) => {
      const { index } = si;
      const tocItem = flatToc[index];
      const sectionName = tocItem?.label;

      const searchSubject: SearchSubject = { ...si, sectionName };
      return searchSubject;
    });

    const searchableSpineItems$ = from(searchableSpineItems);

    const searchDocs$ = (docs: SearchSubject[]): Observable<SearchSubject[]> =>
      searchableSpineItems$.pipe(
        mergeMap((si) => getSpineItemContent(si), 25),
        map((doc) => (docs = [...docs, doc])),
      );

    return searchDocs$([]);
  }

  loadFactoryMetadata(
    url: string,
  ): Observable<JSON | undefined> {
    const uri = this.parser.parseUri(url);
    const { href } = uri;

    return this.getFactoryMetadata$(href);
  }

  private buildBookFromPackage(
    packageObj: Package,
    selectionTags?: Array<Record<string, string>>,
  ): Observable<Book> {
    const {
      spine: pkgSpine,
      tocPath,
      baseUrl,
      ncxPath,
      manifest,
      metadata,
    } = packageObj;

    const spine: Spine = {
      spineItems: pkgSpine,
      spineItemsByHref: spineItemsByHref(pkgSpine),
      spineItemsById: spineItemsById(pkgSpine),
    };

    const tocUrl = `${baseUrl}${tocPath}`;
    const ncxUrl = `${baseUrl}${ncxPath}`;

    const xhtmlContent$ = this.http
      .get(tocUrl, { ...this.defaultOptions })
      .pipe(
        map((doc) => this.domp.parseFromString(doc, 'text/xml')),
        map((doc) => ({
          toc: this.parser.parseTocXHTMLContents(doc, spine),
          pages: this.parser.parsePages(doc),
        })),
      );

    const ncxContent$ = this.http.get(ncxUrl, { ...this.defaultOptions }).pipe(
      map((doc) => this.domp.parseFromString(doc, 'text/xml')),
      map((doc) => ({
        toc: this.parser.parseTocNCXContents(doc, spine),
        pages: {},
      })),
    );

    const content$: Observable<{
      toc: TocItem[]
      pages: Record<string, string> | null
    }> = tocPath ? xhtmlContent$ : ncxContent$;

    return content$.pipe(
      map(
        ({ toc, pages }): Book =>
          ({
            manifest,
            metadata,
            spine,
            pages,
            toc,
            selectionTags,
            package: packageObj,
          } as unknown as Book),
      ),
    );
  }

  private getLexileLevels$(
    href: string,
  ): Observable<LexileLevelsOption[] | undefined> {
    const lexileUrl = `${href}${this.lexilePath}`;

    const lexile$ = this.getContent$(lexileUrl).pipe(
      map((lexiledoc: XMLDocument) => this.parser.parseLexileLevels(lexiledoc)),
      catchError(() => of(undefined)),
    );

    return lexile$;
  }

  private getFactoryMetadata$(
    href: string,
  ): Observable<JSON | undefined> {
    const factoryMetadataUrl = `${href}${this.factoryMetadataPath}`;

    const factoryMetadata$ = this.getJsonContent$(factoryMetadataUrl)
      .pipe(
        map((metadataJson: JSON) => metadataJson),
        catchError(() => of(undefined)),
      );

    return factoryMetadata$;
  }
}
