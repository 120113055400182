import { Injectable } from '@angular/core';
import { TocBonsaiNode } from '@mhe/reader/models';

@Injectable({
  providedIn: 'root',
})
export class TocService {
  findRootNode(nodes: TocBonsaiNode[], tocId: string): TocBonsaiNode {
    let node = nodes.find((n) => n.tocItem?.id === tocId);
    const parent = nodes.find((n) => n.childIds?.some((cn) => cn === node?.id));

    if (parent) {
      const pid = parent.tocItem?.id;
      node = this.findRootNode(nodes, pid as string);
    }

    return node as TocBonsaiNode;
  }
}
