/* eslint-disable no-multi-spaces */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiAnnotation, SearchResponse } from '@mhe/reader/models';
import { Observable } from 'rxjs';

import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { Annotations } from '@mhe/reader/global-store/annotations/annotations.actions';

interface ISearchBody {
  userID: string
  contextID: string
  platform: string
  spineId?: string
  type?: Annotations
}

interface ApiBatchAnnotation {
  code: number
  op: string                // replace, add, remove
  path: string              // resourceUUID
  status?: string
  value: ApiAnnotation
}

@Injectable()
export class AnnotationsService {
  private readonly baseurl = '/v1/annotations';

  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  getAnnotationsForEpub(
    api: string,
    context: ISearchBody,
    annotationType?: Annotations,
  ): Observable<SearchResponse<ApiAnnotation>> {
    let url = `${api}${this.baseurl}/search`;
    let body = { ...context };
    if (annotationType) {
      url += `?type=${annotationType}`;
      body = { ...body, type: annotationType };
    }
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<SearchResponse<ApiAnnotation>>(url, body),
    );
  }

  saveAnnotation(
    api: string,
    annotation: ApiAnnotation,
  ): Observable<ApiAnnotation> {
    const url = `${api}${this.baseurl}`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<ApiAnnotation>(url, annotation),
    );
  }

  deleteAnnotation(api: string, uuid: string): Observable<any> {
    const url = `${api}${this.baseurl}/${uuid}`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.delete(url),
    );
  }

  deleteAnnotations(
    api: string,
    context: { userID: string, contextID: string, platform: string },
  ): Observable<any> {
    const url = `${api}${this.baseurl}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers, body: { ...context } };

    const httpreq = this.http.delete(url, options);
    return this.reducedModeUtils.monitorSessionEnded(() => httpreq);
  }

  deleteBatchAnnotations(
    api: string,
    context: { userID: string, contextID: string, platform: string },
    annotations: ApiAnnotation[],
  ): Observable<any> {
    const url = `${api}${this.baseurl}/batches`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      ...context,
      ops: annotations.map(this.parseBatchAnnotations),
    };
    const options = {
      headers,
    };

    const httpreq = this.http.patch(url, body, options);
    return this.reducedModeUtils.monitorSessionEnded(() => httpreq);
  }

  private parseBatchAnnotations(apiAnnotation: ApiAnnotation): ApiBatchAnnotation {
    return {
      code: 0,
      op: 'remove',
      path: apiAnnotation.resourceUUID,
      value: apiAnnotation,
    };
  }
}
