import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NEVER, Subject, animationFrameScheduler } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'reader-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly bpo: BreakpointObserver,
    private readonly ga: GoogleAnalyticsService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter(({ urlAfterRedirects }) => !urlAfterRedirects.startsWith('/epub')), // captured in ReaderComponent
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        this.ga.pageview({ page: event.urlAfterRedirects });
      });

    this.deviceOrientationFill();
    this.loadEpubIframeStyles();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * minor hack to ensure content fills vertical space on mobile devices.
   * specifically known to occur on iOS, handset devices
   */
  private deviceOrientationFill(): void {
    const { HandsetPortrait, HandsetLandscape } = Breakpoints;

    const responsive$ = this.bpo.observe([HandsetPortrait, HandsetLandscape]);
    const orientation$ = this.bpo.observe(['(orientation: portrait)', '(orientation: landscape)']);
    const html = this.document.querySelector('html');

    responsive$
      .pipe(
        switchMap(({ matches }) => (matches ? orientation$ : NEVER)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.renderer.setStyle(html, 'height', '100vh');

        animationFrameScheduler.schedule(() => {
          this.renderer.setStyle(html, 'height', '100%');
        }, 250);
      });
  }

  loadEpubIframeStyles(): void {
    const stylesheet = `epub-iframe-styles.css?v=${environment.buildTimestamp}`;
    const head = this.document.getElementsByTagName('head')[0];

    const styleLink = this.document.getElementById(
      'epub-iframe-styles',
    ) as HTMLLinkElement;
    if (styleLink) {
      styleLink.href = stylesheet;
    } else {
      const style = this.document.createElement('link');
      style.id = 'epub-iframe-styles';
      style.rel = 'preload';
      style.as = 'style';
      style.href = `${stylesheet}`;
      head.appendChild(style);
    }
  }
}
