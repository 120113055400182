import {
  defaultFeatureFlags,
  defaultReaderEnvironmentConfig, defaultRuntimeConfiguration,
  FeatureFlags,
  ReaderEnvironmentConfig, RuntimeConfiguration,
} from '@mhe/reader/models';

export interface ConfigurationState {
  readonly features: FeatureFlags
  readonly runtime: RuntimeConfiguration
  readonly environment: ReaderEnvironmentConfig
}

export const initialConfigurationState: ConfigurationState = {
  features: defaultFeatureFlags,
  runtime: defaultRuntimeConfiguration,
  environment: defaultReaderEnvironmentConfig,
};
