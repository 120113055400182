import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EpubViewerStore } from './state/epub-viewer.store';

@Component({
  selector: 'reader-core-epub-ui-viewer',
  templateUrl: './epub-viewer.component.html',
  styleUrls: ['./epub-viewer.component.scss'],
})
export class EpubViewerComponent implements AfterViewInit {
  @Input() readonly isDoubleSpread: boolean;
  @Input() readonly albumMode: boolean;

  @ViewChild('leftPageIframe') leftPageIframeRef: ElementRef;
  @ViewChild('rightPageIframe') rightPageIframeRef: ElementRef;
  @ViewChild('cloIframe') cloIframeRef: ElementRef;

  get cloIframeDocument(): HTMLDocument {
    const iframe = this.cloIframe;
    return iframe?.contentDocument as HTMLDocument;
  }

  get cloIframe(): HTMLIFrameElement {
    return this.cloIframeRef && (this.cloIframeRef.nativeElement as HTMLIFrameElement);
  }

  get leftPageIframe(): HTMLIFrameElement {
    return this.leftPageIframeRef && (this.leftPageIframeRef.nativeElement as HTMLIFrameElement);
  }

  get rightPageIframe(): HTMLIFrameElement {
    return this.rightPageIframeRef && (this.rightPageIframeRef.nativeElement as HTMLIFrameElement);
  }

  constructor(private readonly epubViewerStore: EpubViewerStore) {}

  ngAfterViewInit(): void {
    if (!(this.cloIframeRef || this.leftPageIframeRef || this.rightPageIframeRef)) {
      throw new Error('Unable to register iFrame(s)');
    }

    this.epubViewerStore.setCloIframe(this.cloIframe);

    this.epubViewerStore.setLeftIframe(this.leftPageIframe);
    this.epubViewerStore.setRightIframe(this.rightPageIframe);
  }

  // TODO delete this function once iframe service is ready
  scrollToElementById(elementId, speed?, offset?): null {
    const doc = this.cloIframeDocument;
    const element = doc?.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
    return null;
  }
}
