import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReducedModeUtils } from '../utils/reduced-mode-utils';
import { PdfResponse, PdfRequest } from '@mhe/reader/models';
import { Observable } from 'rxjs';

@Injectable()
export class PrintService {
  private readonly baseurl = '/v1/pdf';
  constructor(
    private readonly http: HttpClient,
    private readonly reducedModeUtils: ReducedModeUtils,
  ) {}

  getPdf(api: string, body: PdfRequest | Partial<PdfRequest>): Observable<PdfResponse> {
    const url = `${api}${this.baseurl}`;
    return this.reducedModeUtils.monitorSessionEnded(() =>
      this.http.post<PdfResponse>(url, body),
    );
  }
}
