import { Injectable, NgZone, OnDestroy, Renderer2 } from '@angular/core';

import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';
import { DevicePlatform, DeviceService } from '@mhe/reader/common';

@Injectable()
export class SelectionTransformer implements Transformer, OnDestroy {
  private unlistenerRegistry: Record<string, () => void> = {};

  private activeSelection: Selection | undefined;

  constructor(
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly transformStore: TransformStore,
    private readonly deviceService: DeviceService,
  ) {}

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    const devicePlatform = this.deviceService.platformType;

    // do any cleanup from previous renders first
    this.unlistenerRegistry[iframe.id]?.();

    const selectionEndFn = (e): void => {
      this.zone.run(() => {
        const selection = content.getSelection();
        const details: {
          nonTextFlag?: boolean
          type: 'math' | 'media' | 'widget' | 'image'
        } = e?.detail || null;

        if (selection?.type?.toLowerCase() !== 'range') {
          this.activeSelection?.removeAllRanges();
          this.activeSelection = undefined;
          this.transformStore.dispatch(transformActions.rangeRemoved());
        }

        if (selection?.type?.toLowerCase() === 'range') {
          if (this.activeSelection && this.activeSelection !== selection) {
            this.activeSelection?.removeAllRanges();
          }

          this.activeSelection = selection;
          this.transformStore.dispatch(
            transformActions.rangeSelected({
              selection,
              devicePlatform,
              details,
            }),
          );
        }
      });
    };
    if (devicePlatform !== DevicePlatform.Desktop) {
      this.unlistenerRegistry[iframe.id] = this.renderer.listen(
        content,
        'selectionchange',
        selectionEndFn,
      );
    } else {
      this.unlistenerRegistry[iframe.id] = this.renderer.listen(
        content,
        'mouseup',
        selectionEndFn,
      );
    }

    return await next(content);
  }

  ngOnDestroy(): void {
    Object.values(this.unlistenerRegistry).forEach((unlistener) =>
      unlistener(),
    );
  }
}
