import { AnchorType, isAnchorType } from '@mhe/reader/models';

export function getAnchorType(elem: HTMLAnchorElement): AnchorType | null {
  const elemHref = elem.getAttribute('href');
  const anchorTypeAttr = elem.getAttribute('epub:type');
  const hasExpandingContent = elem.getAttribute('data-expanding-content');

  if (!elemHref) {
    return null;
  }

  // TODO: Refactor in a more functional way
  if (isAnchorType(anchorTypeAttr)) {
    return anchorTypeAttr;
  } else if (elemHref.match(/^https?:\/\/|^\/\//)) {
    return AnchorType.EXTERNAL_LINK;
  } else if (elemHref.match(/^mailto:(\/\/)?/)) {
    return AnchorType.MAILTO_LINK;
  } else if (elemHref.match(/^tel:(\/\/)?/)) {
    return AnchorType.TEL_LINK;
  } else if (elem.className?.match(/^mhe..*long-description?(s|.)-link$/gi)) {
    return AnchorType.LONG_DESCRIPTION;
  } else if (elem.className?.match(/^mhe..*credit?(s|.)-link$/gi)) {
    return AnchorType.CREDIT_LINK;
  } else if (hasExpandingContent) {
    return AnchorType.REFERENCE_LINK;
  }

  return AnchorType.INTERNAL_LINK;
}
