import { NgModule } from '@angular/core';
import { OrphanAnnotationsFilterPipe } from './orphan-annotations-filter.pipe';

@NgModule({
  imports: [
  ],
  declarations: [OrphanAnnotationsFilterPipe],
  exports: [OrphanAnnotationsFilterPipe],
})
export class AnnotationListModule { }
