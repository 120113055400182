<!-- paginate -->
<ng-container *ngIf="navigationType === 'paginate'">
  <button
    id="next-button"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    class="page-nav next menuBtn paginate"
    placement="bottom"
    panelClass="navigation-button-tooltip"
    [mheTooltip]="(disabled ? '' : 'navigation.next') | translate"
    [disabled]="disabled"
    (click)="internalForward()"
  >
    <i class="dpg-icon dpg-icon-right-arrow"></i>
  </button>
</ng-container>

<!-- presentation mode (not k5) -->
<ng-container *ngIf="navigationType === 'presentation'">
  <button
    id="next-button"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    class="page-nav next menuBtn presentation"
    [attr.aria-label]="'navigation.next' | translate"
    [disabled]="disabled"
    (click)="internalForward()"
  >
    <i class="dpg-icon dpg-icon-right-arrow-white"></i>
  </button>

  <div *ngIf="chapterLabel" class="show-chapter">{{ chapterLabel }}</div>
</ng-container>
