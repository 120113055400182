import { Injectable, NgZone } from '@angular/core';

import { Book, SpineItem } from '../models/epub';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { Next, Transformer } from './transformer';

@Injectable()
export class AssessmentWidgetsTransformer implements Transformer {
  private readonly selectors = [
    'iframe.widget.widget-mhe-assessment',
    'iframe.widget.widget-engrade-assessment',
  ];

  constructor(private readonly transformStore: TransformStore, private readonly zone: NgZone) {}

  async postRender(
    _book: Book,
    _spineItem: SpineItem,
    content: HTMLDocument,
    _iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    let assessmentWidgetNodes = this.selectors.reduce<HTMLIFrameElement[]>((acc, selector) => {
      const nodes = content.querySelectorAll<HTMLIFrameElement>(selector);
      const arr = Array.from(nodes);

      acc = [...acc, ...arr];
      return acc;
    }, []);

    assessmentWidgetNodes = [...new Set(assessmentWidgetNodes)]; // distinct

    assessmentWidgetNodes.forEach((widgetFrame) => {
      const launch = transformActions.launchAssessment({ widgetFrame });
      this.zone.run(() => this.transformStore.dispatch(launch));
    });

    return await next(content);
  }
}
