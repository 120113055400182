/**
 * Maps slider control values to font size magnification
 * Maps the interval (-5,5) -> (0.5,2)
 */
export function mapFontSize(value: number): number {
  if (value <= -5) {
    return 0.5;
  } else if (value >= 5) {
    return 2.0;
  }

  const changeStep = value < 1 ? 10 : 5;
  const fontsize = (1 + value / changeStep).toFixed(1);
  return Number(fontsize);
}

/**
 * Maps font size magnification to form slider control values
 * Maps the interval (0.5,2) -> (-5,5)
 */
export function mapFontSizeValue(fontsize: number): number {
  if (fontsize <= 0.5) {
    return -5;
  } else if (fontsize >= 2) {
    return 5;
  }

  const changeStep = fontsize < 1 ? 0.1 : 0.2;
  const fontSizeValue = ((fontsize - 1) / changeStep).toFixed(0);

  return Number(fontSizeValue);
}
