import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { ReadiatorBridgeService } from './readiator-bridge.service';
import { asyncScheduler } from 'rxjs';
import { ReadiatorMessageTypes, SeamlessDataQueued, SeamlessMessage } from '@mhe/reader/models';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { take } from 'rxjs/operators';

/**
 * Readiator leverages the seamless.js library in both parent/child iframes.
 * Rather than pulling in seamless.js as a dependency, this service
 * mimics the postMessages sent back and forth between the iFrames.
 */

@Injectable()
export class SeamlessBridgeService implements OnDestroy {
  protected initialized = false;
  protected connected = false;
  protected seamlessId: string | number = 0;
  protected unlistener = (): void => {};
  protected pingParentCnt = 0;
  protected readiatorBridge;
  protected msgQueue: SeamlessDataQueued[] = [];
  protected isDebugMode = false;

  constructor(
    protected renderer: Renderer2,
    private readonly readerConfigStore: ReaderConfigStore,
  ) {}

  public setReadiatorBridge(readiatorBridge: ReadiatorBridgeService): void {
    this.readiatorBridge = readiatorBridge;
    if (localStorage.getItem('readerx_debug') === 'true') {
      this.isDebugMode = true;
      this.readiatorBridge.enableDebug();
    }
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.pingParentOnInit();
    this.unlistener = this.renderer.listen('window', 'message', (event) => {
      this.onReceiveMessage(event);
    });
    this.initialized = true;
  }

  private pingParentOnInit(): void {
    this.pingParentCnt = this.pingParentCnt + 1;
    if (this.connected) {
      return;
    }
    this.sendSeamlessReady();

    // try again up to 25x (~5seconds)
    if (this.pingParentCnt <= 25) {
      asyncScheduler.schedule(() => this.pingParentOnInit(), 200);
    }
  }

  private onReceiveMessage(event): void {
    // seamless only sends string messages
    if (typeof event.data !== 'string') {
      return;
    }

    try {
      const message = JSON.parse(event.data);
      if (message.type !== undefined && this.isDebugMode) {
        console.error('RECEIVED - ReaderX event:', event.data);
      }
      switch (message.type) {
        case 'seamless_connect':
          this.connected = true;
          this.seamlessId = message.data.id;
          this.sendSeamlessUpdate();
          this.sendSeamlessConnect(message.data.styles, message.callback);
          this.sendQueuedSeamlessData();
          break;
        case 'seamless_data':
          this.handleSeamlessData(message);
          break;
      }
    } catch (e) {
      // do nothing...string message not sent from seamless
    }
  }

  protected handleSeamlessData(msg: SeamlessMessage): void {
    switch (msg.data.type) {
      case ReadiatorMessageTypes.DEBUG_ENABLED:
        this.isDebugMode = true;
        this.readiatorBridge.enableDebug();
        break;
      case ReadiatorMessageTypes.TOC_TOGGLE:
        this.readiatorBridge.toggleTOC();
        break;
      case ReadiatorMessageTypes.SEARCH_TOGGLE:
        this.readiatorBridge.toggleSearch();
        break;
      case ReadiatorMessageTypes.NAVIGATION_FORWARD:
        this.readiatorBridge.navForward();
        break;
      case ReadiatorMessageTypes.NAVIGATION_BACK:
        this.readiatorBridge.navBack();
        break;
      case ReadiatorMessageTypes.BACKBUTTON_TOGGLE:
        this.readiatorBridge.toggleBackButton();
        break;
      case ReadiatorMessageTypes.OVERFLOW_TOGGLE:
        this.readiatorBridge.toggleOverflow();
        break;
      case ReadiatorMessageTypes.TOC_GET:
        this.readiatorBridge.getTOC();
        break;
      case ReadiatorMessageTypes.LOCATION_CURRENT:
        this.readiatorBridge.getLocation();
        break;
      case ReadiatorMessageTypes.SPINE_CURRENT:
        this.readiatorBridge.onGetSpinePosition();
        break;
      case ReadiatorMessageTypes.LOCATION_SET:
        this.readiatorBridge.setLocation(msg.data.payload);
        break;
      case ReadiatorMessageTypes.LINK_NOTIFY:
        this.readiatorBridge.setLinkBehavior();
        break;
      case ReadiatorMessageTypes.LINK_BEHAVIOR:
        this.readiatorBridge.setLinkBehavior();
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_CLEAR:
        this.readiatorBridge.clearHighlight(msg.data.payload);
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_DESELECT:
        this.readiatorBridge.deselectHighlight();
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_KEYPRESS_DELETE:
        this.readiatorBridge.highlightKeypressDelete();
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_TRIGGER_SELECT:
        this.readiatorBridge.triggerHighlightSelect();
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_TRIGGER_DESELECT:
        this.readiatorBridge.triggerHighlightDeselect();
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_SCROLL_INTO_VIEW:
        this.readiatorBridge.scrollHighlightIntoView(msg.data.payload);
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_SET:
        this.readiatorBridge.setHighlight(msg.data.payload);
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_GET:
        this.readiatorBridge.getHighlight(msg.data.payload);
        break;
      case ReadiatorMessageTypes.RANGE_SELECT:
        this.readiatorBridge.rangeSelect();
        break;
      case ReadiatorMessageTypes.CONCEPT_NAV_SET_STATE:
        this.readiatorBridge.setConceptNavState(msg.data.payload);
        break;
      case ReadiatorMessageTypes.CONCEPT_NAV_GET_STATE:
        this.sendSeamlessDataMsg(
          ReadiatorMessageTypes.CONCEPT_NAV_GET_STATE,
          this.readiatorBridge.getConceptNavSate(),
        );
        break;
      case ReadiatorMessageTypes.HIGHLIGHT_ICON_SET_FOCUS:
        this.readiatorBridge.setFocusOnHighlightIcon(msg.data.payload);
        break;
      case ReadiatorMessageTypes.LR_ICON_SET_FOCUS:
        this.readiatorBridge.setFocusOnLRIcon(msg.data.payload);
        break;
      case ReadiatorMessageTypes.FONT_SIZE_SET:
        this.readiatorBridge.setFontSize(msg.data.payload);
        break;
      case ReadiatorMessageTypes.FORCE_REFRESH:
        this.readiatorBridge.forceRefresh();
        break;
      case ReadiatorMessageTypes.CUSTOM_BUTTON:
        this.readiatorBridge.setCustomButton(msg.data.payload);
        break;
      default:
        this.sendSeamlessError('SEAMLESS message type not wired up yet - ' + msg.data.type);
    }
    // @todo reply back to match the request/response signature of seamless
  }

  public sendSeamlessReady(): void {
    const msg = {
      data: { __id: 0 },
      type: 'seamless_ready',
    };
    this.sendMessage(msg);
  }

  public generateRandomCallback(): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

    const ret: any[] = [];
    for (let i = 0; i < 32; i++) {
      ret[i] = chars[Math.floor(Math.random() * chars.length)];
    }

    return ret.join('');
  }

  public sendSeamlessUpdate(): void {
    this.readerConfigStore.runtime$.pipe(take(1)).subscribe((runtime) => {
      const msg = {
        data: {
          height: runtime.readerHeightInSeamless,
          __id: this.seamlessId,
        },
        type: 'seamless_update',
        callback: this.generateRandomCallback(),
      };
      this.sendMessage(msg);
    });
  }

  public sendSeamlessConnect(styles: string[], callback: string): void {
    const msg = {
      data: {
        id: this.seamlessId,
        styles,
      },
      type: callback,
    };
    this.sendMessage(msg);
  }

  public sendMessage(msg): void {
    if (this.isDebugMode) {
      console.error('SENT - ReaderX event:', JSON.stringify(msg));
    }
    window.parent.postMessage(JSON.stringify(msg), '*');
  }

  public sendSeamlessError(msg: string): void {
    this.sendSeamlessDataMsg(ReadiatorMessageTypes.ERROR, msg);
  }

  public sendSeamlessDataMsg(type: string, payload: object | string): void {
    // not connected yet, place into msg queue
    if (!this.connected) {
      this.msgQueue.push({ type, payload });
      return;
    }
    this.sendMessage({
      data: {
        __id: this.seamlessId,
        __msgId: this.generateRandomCallback(),
        type,
        payload,
      },
      type: 'seamless_data',
    });
  }

  // send queued messages out
  protected sendQueuedSeamlessData(): void {
    this.msgQueue.forEach((item) => {
      this.sendSeamlessDataMsg(item.type, item.payload);
    });
    this.msgQueue = [];
  }

  ngOnDestroy(): void {
    this.unlistener();
  }
}
