import { NgModule } from '@angular/core';

import { EpubUiViewerComponent } from './epub-ui-viewer.component';
import { CommonModule } from '@angular/common';
import { TimeoutModalModule } from '@mhe/ngx-shared';
import { ReaderModule } from '@mhe/reader/components/reader/reader.module';

@NgModule({
  imports: [CommonModule, ReaderModule, TimeoutModalModule],
  declarations: [EpubUiViewerComponent],
  exports: [EpubUiViewerComponent],
})
export class EpubUiViewerModule {}
