import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Cell,
  Columns,
  ICreatePDF,
  IFonts,
  IStyleDefinition,
  ITable,
  PdfMakeWrapper,
  Table,
  Txt,
} from 'pdfmake-wrapper';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AnnotationGroup } from '@mhe/reader/models';
import { robotoFont } from '../utils/roboto-font';

@Injectable({ providedIn: 'root' })
export class ExportNotesService {
  constructor(private readonly translateService: TranslateService) {}

  export(
    annotationsByChapter: AnnotationGroup[],
    bookTitle: string,
    fonts: IFonts = robotoFont,
  ): Observable<ICreatePDF> {
    PdfMakeWrapper.setFonts(fonts);

    return of(new PdfMakeWrapper()).pipe(
      tap((pdf) => this.setupPage(pdf)),
      tap((pdf) => this.setupFooter(pdf)),
      tap((pdf) => this.setupHeaders(pdf, bookTitle)),
      tap((pdf) => pdf.add(this.createNoteTable(annotationsByChapter))),
      map((pdf) => pdf.create()),
    );
  }

  private setupPage(pdf: PdfMakeWrapper): void {
    pdf.pageSize('LETTER');
    pdf.pageMargins([40, 80, 40, 60]);
    pdf.styles({
      header: {
        bold: true,
        fontSize: 18,
      },
      subheader: {
        bold: true,
        fontSize: 14,
      },
      footer: {
        fontSize: 10,
        italics: true,
      },
      selectedText: {
        bold: true,
      },
    });
  }

  private setupFooter(pdf: PdfMakeWrapper): void {
    pdf.footer((page, pages) => {
      const footer = new Columns([
        new Txt('').alignment('left').margin([20, 0, 0, 0]).end,
        new Txt([
          new Txt(page.toString()).italics().end,
          new Txt(this.translateService.instant('export.of')).end,
          new Txt(pages.toString()).italics().end,
        ]).alignment('center').end,
        new Txt(formatDate(new Date(), 'shortDate', 'en_US'))
          .alignment('right')
          .margin([0, 0, 20, 0]).end,
      ])
        .style('footer')
        .margin([20, 30, 20, 40]).end;
      return footer;
    });
  }

  private setupHeaders(pdf: PdfMakeWrapper, bookTitle: string): void {
    pdf.add(
      new Txt(this.translateService.instant('export.title_notes')).style(
        'subheader',
      ).end,
    );
    pdf.add(new Txt(bookTitle).style('header').margin([0, 0, 20, 20]).end);
  }

  private createNoteTable(annotationsByChapter: AnnotationGroup[]): ITable {
    const rows: IStyleDefinition[][] = annotationsByChapter.reduce(
      (abcAcc, chapter): IStyleDefinition[][] => [
        ...abcAcc,
        ...chapter.annotations
          .filter((a) => !!a.note)
          .reduce(
            (aAcc, annotation) => [
              ...aAcc,
              [
                new Cell({ text: chapter.groupLabel })
                  .background('lightgray')
                  .decoration('underline')
                  .decorationStyle('dashed')
                  .decorationColor('#C62828').end,
                new Txt('').end,
                new Txt(
                  formatDate(
                    annotation.updatedAt as string,
                    'shortDate',
                    'en-US',
                  ),
                )
                  .alignment('right')
                  .margin([20, 0, 0, 0]).end,
              ],
              [
                new Cell({ text: annotation.text })
                  .colSpan(3)
                  .margin([0, 0, 10, 0])
                  .style('selectedText').end,
              ],
              [
                new Cell({ text: annotation.note })
                  .colSpan(3)
                  .margin([0, 0, 10, 10]).end,
              ],
            ],
            [],
          ),
      ],
      [],
    );

    return new Table(rows).layout('noBorders').widths(['*', 0, 80]).end;
  }
}
