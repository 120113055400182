/* eslint-disable max-len */
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { ReaderStore } from '../../components/reader/state';
import * as readerActions from '../../components/reader/state/reader.actions';
import {
  SpineItem,
  HighlightColorClass,
  AnnotationData,
  HighlightShapeClass,
  ReadiatorAnnotationData,
  ReadiatorBentoDigitalTaggingData,
  ReadiatorFontSizeData,
  ReadiatorHighlightId,
  ReadiatorLocationData,
  ReadiatorPendingFocus,
  ReadiatorMessageTypes,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { merge, Subject, asyncScheduler } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SeamlessBridgeService } from './seamless-bridge.service';
import { ReadiatorAwdIconService } from './readiator-awd-icon-service';
import { getTimeNeededToSmoothScroll } from '@mhe/reader/core/utils';
import * as toolbarActions from '@mhe/reader/components/reader/state/toolbar.actions';
import { CustomButton } from '@mhe/reader/types';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import * as topicsActions from '@mhe/reader/components/topics/state/topics.actions';
import { SearchStore } from '@mhe/reader/components/search';
import * as searchActions from '@mhe/reader/components/search/state/search.actions';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import {
  EpubLibCFIService,
  HighlighterService,
} from '@mhe/reader/features/annotation';
import { FontResizerStore } from '@mhe/reader/components/font-resizer';
import * as fontResizerActions from '@mhe/reader/components/font-resizer/state/font-resizer.actions';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import {
  ConceptNavigatorState,
  ConceptNavigatorStore,
} from '@mhe/reader/components/concept-navigator';
import * as conceptNavigatorActions from '@mhe/reader/components/concept-navigator/state/concept-navigator.actions';
import { AnnotationsOverlayService } from '@mhe/reader/components/annotations-context-menu';
import { mapFontSizeValue } from '@mhe/reader/utils';
import { TTSStore } from '@mhe/reader/components/text-to-speech';
import * as ttsActions from '@mhe/reader/components/text-to-speech/state/tts.actions';

@Injectable()
export class ReadiatorBridgeService implements OnDestroy {
  // local variables mirroring values in stores so they can be retrieved
  private spineItem: SpineItem;
  private pageNumber: number;
  private pageMax: number;
  private conceptNavigatorEnabled: boolean;
  private prevConceptButtonEnabled: boolean;
  private nextConceptButtonEnabled: boolean;
  private currentConceptButtonEnabled: boolean;
  private origHighlightPayloads: Record<string, ReadiatorAnnotationData> = {};

  private annotationUnlistenerRegistry: Record<string, () => void> = {};

  private readonly destroy$ = new Subject<void>();
  private cloIframe: HTMLIFrameElement;
  protected initialized = false;
  protected oauthConsumerKey: string;
  protected isTransitioning = false;
  protected pendingSetFocus: ReadiatorPendingFocus | null;
  protected isConceptNavEnabled = false;
  protected isDebugMode = false;
  protected shouldSendCurrentSpine = false;

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly navigationStore: NavigationStore,
    private readonly epubViewerStore: EpubViewerStore,
    private readonly highlighter: HighlighterService,
    private readonly seamlessBridge: SeamlessBridgeService,
    private readonly conceptNavStore: ConceptNavigatorStore,
    private readonly epubCfiLib: EpubLibCFIService,
    private readonly readiatorAwdIconService: ReadiatorAwdIconService,
    private readonly fontResizerStore: FontResizerStore,
    private readonly ttsStore: TTSStore,
    private readonly topicsStore: TopicsStore,
    private readonly searchStore: SearchStore,
    private readonly annotationsOverlayService: AnnotationsOverlayService,
    private readonly renderer: Renderer2,
  ) {
    seamlessBridge.setReadiatorBridge(this);
    readiatorAwdIconService.setReadiatorBridge(this);

    // store observable value locally so it can be returned anytime
    merge(
      this.topicsStore.actions$.pipe(
        ofType(topicsActions.emitReadiatorEvent),
        tap(({ payload }) => {
          this.seamlessBridge.sendSeamlessDataMsg(payload.event, payload);
          console.log('Readiator Event Payload: ', payload);
        }),
      ),
      this.searchStore.actions$.pipe(
        ofType(searchActions.emitReadiatorEvent),
        tap(({ payload }) => {
          this.seamlessBridge.sendSeamlessDataMsg(payload.event, payload);
        }),
      ),
      this.readerStore.actions$.pipe(
        ofType(readerActions.init),
        tap(() => this.triggerOnEpubLoadedCallback()),
      ),
      this.readerStore.actions$.pipe(
        ofType(readerActions.leftDrawerClosed),
        tap(() => this.leftDrawerClose()),
      ),
      this.readerStore.actions$.pipe(
        ofType(readerActions.rightDrawerClosed),
        tap(() => this.rightDrawerClose()),
      ),
      this.epubViewerStore.actions$.pipe(
        ofType(epubViewerActions.renderComplete),
        tap(() => this.onPageRenderComplete()),
      ),
      this.epubViewerStore.actions$.pipe(
        ofType(epubViewerActions.preRenderComplete),
        tap(() => this.onPagePreRenderComplete()),
      ),
      this.epubViewerStore.cloIframe$.pipe(
        tap((iframe: HTMLIFrameElement) => (this.cloIframe = iframe)),
      ),
      this.readerStore.spineItem$.pipe(
        tap((spineItem: SpineItem) => (this.spineItem = spineItem)),
      ),
      this.fontResizerStore.actions$.pipe(
        ofType(fontResizerActions.fontsizeChanged),
        tap((action: ReturnType<typeof fontResizerActions.fontsizeChanged>) =>
          this.seamlessBridge.sendSeamlessDataMsg(
            ReadiatorMessageTypes.FONT_SIZE_CHANGED,
            {
              fontSize: action.fontsize,
            },
          ),
        ),
      ),
      this.navigationStore.maxIndex$.pipe(
        tap((maxSpineIndex: number) => (this.pageMax = maxSpineIndex + 1)),
      ),
      this.navigationStore.index$.pipe(
        tap((pageNumber: number) => (this.pageNumber = pageNumber + 1)),
      ),
      this.conceptNavStore.actions$.pipe(
        ofType(conceptNavigatorActions.conceptNavPrevBtnTriggered),
        tap(() => this.triggerOnConceptNavPrev()),
      ),
      this.conceptNavStore.actions$.pipe(
        ofType(conceptNavigatorActions.conceptNavCurBtnTriggered),
        tap(() => this.triggerOnConceptNavCurrent()),
      ),
      this.conceptNavStore.actions$.pipe(
        ofType(conceptNavigatorActions.conceptNavNextBtnTriggered),
        tap((action) => this.triggerOnConceptNavNext()),
      ),
      this.conceptNavStore.conceptNavigatorEnabled$.pipe(
        tap(
          (conceptNavigatorEnabled: boolean) =>
            (this.conceptNavigatorEnabled = conceptNavigatorEnabled),
        ),
      ),
      this.conceptNavStore.prevConceptButtonEnabled$.pipe(
        tap(
          (prevConceptButtonEnabled: boolean) =>
            (this.prevConceptButtonEnabled = prevConceptButtonEnabled),
        ),
      ),
      this.conceptNavStore.currentConceptButtonEnabled$.pipe(
        tap(
          (currentConceptButtonEnabled: boolean) =>
            (this.currentConceptButtonEnabled = currentConceptButtonEnabled),
        ),
      ),
      this.conceptNavStore.nextConceptButtonEnabled$.pipe(
        tap(
          (nextConceptButtonEnabled: boolean) =>
            (this.nextConceptButtonEnabled = nextConceptButtonEnabled),
        ),
      ),
      this.readerStore.actions$.pipe(
        ofType(toolbarActions.customButtonClicked),
        withLatestFrom(this.readerStore.customButton$),
        tap(
          ([, customButton]: [
            ReturnType<typeof toolbarActions.customButtonClicked>,
            CustomButton,
          ]) => this.triggerCustomButtonClicked(customButton.eventOnClick),
        ),
      ),
      this.epubViewerStore.actions$.pipe(
        ofType(epubViewerActions.preRenderComplete),
        tap(() => this.teardownHighlightClickEvents()),
      ),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public init(oauthConsumerKey: string): void {
    if (!this.initialized) {
      this.initialized = true;
      this.seamlessBridge.init();
      this.oauthConsumerKey = oauthConsumerKey;
    }
  }

  private static notImplmentedYet(): void {
    throw new Error(
      'This READIATOR functionality is not implemented yet in ReaderX.',
    );
  }

  private static deprecated(): void {
    throw new Error('This READIATOR functionality is deprecated ReaderX.');
  }

  public triggerOnEpubLoadedCallback(): void {
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.EPUB_LOADED,
      {},
    );
  }

  public setCustomButton(customButton: CustomButton): void {
    this.readerStore.setCustomButton(customButton);
  }

  public triggerCustomButtonClicked(messageType: string): void {
    this.seamlessBridge.sendSeamlessDataMsg(messageType, {});
  }

  public leftDrawerClose(): void {
    this.seamlessBridge.sendSeamlessDataMsg(ReadiatorMessageTypes.LEFT_DRAWER_CLOSE, {});
  }

  public rightDrawerClose(): void {
    this.seamlessBridge.sendSeamlessDataMsg(ReadiatorMessageTypes.RIGHT_DRAWER_CLOSE, {});
  }

  public onPageRenderComplete(): void {
    this.isTransitioning = false;
    this.handleConceptNavBodyClass();
    this.executePendingFocus();
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.PAGE_LOADED,
      {},
    );
  }

  public onPagePreRenderComplete(): void {
    // send the new page range to the integrator
    this.sendSpineCurrent();
  }

  protected handleConceptNavBodyClass(): void {
    if (this.isConceptNavEnabled) {
      const iframeBody = this.cloIframe.contentDocument?.querySelector('body');
      iframeBody?.classList.add('readiator-concept-navigator');
    } else {
      const iframeBody = this.cloIframe.contentDocument?.querySelector('body');
      iframeBody?.classList.remove('readiator-concept-navigator');
    }
  }

  public navForward(): void {
    this.navigateByStep(1);
  }

  public navBack(): void {
    this.navigateByStep(-1);
  }

  public getTOC(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public getLocation(): void {
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.LOCATION_CURRENT,
      {
        tocItem: {
          index: this.spineItem.index,
          idref: this.spineItem.idref,
          id: this.spineItem.id,
          linear: this.spineItem.linear,
          properties: this.spineItem.properties,
          href: this.spineItem.href,
          url: this.spineItem.url,
          active: true,
        },
        cfi:
          'epubcfi(' +
          this.epubCfiLib.generateBasePath(
            this.spineItem.index,
            this.spineItem.id,
          ) +
          '!/4/1:0)',
      },
    );
  }

  public setFontSize(data: ReadiatorFontSizeData): void {
    this.fontResizerStore.valueChange$(mapFontSizeValue(data.fontsize));
  }

  public onFontResizerDisabled(disabled = true): void {
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.FONT_RESIZER_DISABLED,
      { disabled },
    );
  }

  public setLocation(data: ReadiatorLocationData): void {
    if (data.setFocus === undefined) {
      data.setFocus = true;
    }

    // check if currently on the page - which would result in no-op
    if (this.epubCfiLib.getSpineId(data.cfi) === this.spineItem.id) {
      if (data.setFocus) {
        this.pendingSetFocus = { cfi: data.cfi };
        this.executePendingFocus();
      }
    } else {
      this.isTransitioning = true;
      this.navigationStore.dispatch(
        navigationActions.navigateByCfi({
          cfi: data.cfi,
          setFocus: data.setFocus,
        }),
      );
    }
  }

  public setHighlight(data: ReadiatorAnnotationData): void {
    const doc = this.cloIframe.contentDocument as Document;
    const annotation = this.convertReadiatorPayload(data);

    // remove existing highlight with same id
    if (this.origHighlightPayloads[annotation.id] !== undefined) {
      this.clearHighlight(this.origHighlightPayloads[annotation.id]);
    }

    if (!data.id && data.$id !== undefined) {
      data.id = data.$id;
    } else if (!data.$id !== undefined) {
      data.$id = data.id;
    }

    /**
     * @todo prefilling the new parameter hasHighlightIcon for AWD till they add it themselves
     */
    if (this.oauthConsumerKey === 'avalon_web_delivery') {
      data.hasHighlightIcon = true;
    }

    this.readiatorAwdIconService.removeAllIcons(doc);
    this.highlighter.addAnnotation(annotation, doc);
    this.setupHighlightClickEvents(annotation, doc);
    data.text = annotation.text;

    this.origHighlightPayloads[annotation.id] = data;

    /**
     * AWD Specific logic
     * AWD needs the text of the highlight for accessibility purposes
     * They also need icons inserted into the markup
     */
    this.readiatorAwdIconService.addAllIcons(this.origHighlightPayloads, doc);

    // Tell Avalon that a highlight was selected
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.HIGHLIGHT_SELECT,
      this.origHighlightPayloads[annotation.id],
    );

    this.handleAvalonAuthoringPinkHighlights(doc, annotation);
  }

  public deselectHighlight(): void {
    const doc = this.cloIframe.contentDocument as Document;
    this.removePinkClass(doc);

    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.HIGHLIGHT_DESELECT,
      {},
    );
  }

  public getHighlight(data: ReadiatorHighlightId): void {
    if (this.origHighlightPayloads[data.$id]) {
      const highlightPayload = this.origHighlightPayloads[data.$id];
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.HIGHLIGHT_GET,
        highlightPayload,
      );
    }
  }

  public clearHighlight(data: ReadiatorAnnotationData): void {
    const doc = this.cloIframe.contentDocument as Document;

    if (this.origHighlightPayloads[data.$id as string] !== undefined) {
      const originalPayload = this.origHighlightPayloads[data.$id as string];
      const originalAnnotation = this.convertReadiatorPayload(originalPayload);

      this.highlighter.removeAnnotation(originalAnnotation, doc);
      this.readiatorAwdIconService.removeIcons(originalPayload, doc);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.origHighlightPayloads[originalAnnotation.id];
    }
  }

  private setupHighlightClickEvents(
    annotation: AnnotationData,
    doc: Document,
  ): void {
    const marks = doc.querySelectorAll(
      `[data-highlight-id='${annotation.id}']`,
    ) as any as HTMLScriptElement[];
    marks.forEach((mark) => {
      this.annotationUnlistenerRegistry[annotation.id] = this.renderer.listen(
        mark,
        'click',
        (event: Event) => {
          const markAnnotationID = mark.getAttribute('data-highlight-id');
          const markGroup = doc.querySelectorAll(
            `[data-highlight-id='${markAnnotationID}']`,
          );
          markGroup.forEach((markGroupItem) => {
            this.renderer.addClass(markGroupItem, 'authoring-active');
          });
          this.seamlessBridge.sendSeamlessDataMsg(
            ReadiatorMessageTypes.HIGHLIGHT_SELECT,
            this.origHighlightPayloads[annotation.id],
          );

          const keydownUnlistener = this.renderer.listen(
            doc,
            'keydown',
            (keyboardEvent: KeyboardEvent) => {
              if (
                keyboardEvent.key === 'Delete' ||
                keyboardEvent.key === 'Backspace'
              ) {
                this.seamlessBridge.sendSeamlessDataMsg(
                  ReadiatorMessageTypes.HIGHLIGHT_KEYPRESS_DELETE,
                  {},
                );
              }
            },
          );

          const mousedownUnlistener = this.renderer.listen(
            doc,
            'mousedown',
            () => {
              this.removePinkClass(doc);

              this.seamlessBridge.sendSeamlessDataMsg(
                ReadiatorMessageTypes.HIGHLIGHT_DESELECT,
                {},
              );
              mousedownUnlistener();
              keydownUnlistener();
            },
          );
        },
      );
    });
  }

  private handleAvalonAuthoringPinkHighlights(
    doc: Document,
    annotation: AnnotationData,
  ): void {
    this.removePinkClass(doc);

    // add pink color to new active highlight
    const marks = doc.querySelectorAll(
      `[data-highlight-id='${annotation.id}']`,
    ) as any as HTMLScriptElement[];
    marks.forEach((mark) => {
      this.renderer.addClass(mark, 'authoring-active');
    });

    // remove the user cursor selection.  Pink on blue doesn't look good.
    doc.getSelection()?.removeAllRanges();

    // remove the pink color when the page is clicked anywhere
    const mousedownUnlistener = this.renderer.listen(doc, 'mousedown', () => {
      this.removePinkClass(doc);
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.HIGHLIGHT_DESELECT,
        {},
      );
      mousedownUnlistener();
    });
  }

  private removePinkClass(doc: Document): void {
    doc.querySelectorAll('.authoring-active').forEach((activeElement) => {
      this.renderer.removeClass(activeElement, 'authoring-active');
    });
  }

  private teardownHighlightClickEvents(): void {
    Object.values(this.annotationUnlistenerRegistry).forEach((ul) => ul());
    this.annotationUnlistenerRegistry = {};
  }

  public updateCustomButtonCounter(count: number): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public setLinkBehavior(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public toggleTOC(): void {
    this.readerStore.dispatch(readerActions.toggleLeftDrawer);
  }

  public toggleSearch(): void {
    this.readerStore.dispatch(readerActions.toggleRightDrawer);
  }

  public onGetSpinePosition(): void {
    this.shouldSendCurrentSpine = true;
    this.sendSpineCurrent();
  }

  private sendSpineCurrent(): void {
    // legacy required an event READER.SPINE.CURRENT to be sent to Reader before
    // the current spine would get emitted out on navigation events
    if (!this.shouldSendCurrentSpine) {
      return;
    }
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.SPINE_CURRENT,
      {
        text: this.pageNumber + ' of ' + this.pageMax,
        current: this.pageNumber,
        total: this.pageMax,
      },
    );
  }

  public toggleOverflow(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public toggleBackButton(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public highlightKeypressDelete(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public triggerHighlightSelect(): void {
    ReadiatorBridgeService.deprecated();
  }

  public triggerHighlightDeselect(): void {
    ReadiatorBridgeService.deprecated();
  }

  public rangeSelect(selectionPayload, selectionType): void {
    if (
      selectionType === 'image' ||
      selectionType === 'figure' ||
      selectionType === 'svg' ||
      selectionType === 'widget'
    ) {
      delete selectionPayload.text;
      const payload = {
        ...selectionPayload,
        type: selectionType,
      };
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.RANGE_SELECT,
        payload,
      );
    } else {
      // text selection
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.RANGE_SELECT,
        selectionPayload,
      );
    }
  }

  public onHighlightIconTriggered(id: string): void {
    if (this.origHighlightPayloads[id]) {
      const highlightPayload = this.origHighlightPayloads[id];
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.HIGHLIGHT_ICON_CLICK,
        highlightPayload,
      );
    }
  }

  public onLRIconTriggered(id: string): void {
    if (this.origHighlightPayloads[id]) {
      const highlightPayload = this.origHighlightPayloads[id];
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.LR_ICON_CLICK,
        highlightPayload,
      );
    }
  }

  public scrollHighlightIntoView(data: ReadiatorAnnotationData): void {
    const annotation = this.convertReadiatorPayload(data);
    const annotationEl = this.highlighter.getAnnotationEl(
      annotation,
      this.cloIframe.contentDocument as Document,
    );

    if (annotationEl) {
      // An ePub scroll will move focus to the <iframe>.
      // We need to force reader buttons back into focus
      // once the scrolling is done otherwise, things like
      // concept nav will lose focus.
      //
      // But, we need to move the focus back to reader only
      // when the scroll is complete, otherwise the scroll
      // will be interrupted.
      this.isTransitioning = true;

      if (
        document.activeElement instanceof HTMLElement &&
        document.activeElement !== document.body
      ) {
        this.pendingSetFocus = { el: document.activeElement };
      }

      // wait for scroll to complete, then move focus to reader
      const estimatedTimeToSmoothScroll =
        getTimeNeededToSmoothScroll(annotationEl);
      asyncScheduler.schedule(() => {
        this.isTransitioning = false;
        this.executePendingFocus();
      }, estimatedTimeToSmoothScroll);

      annotationEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }

    if (this.origHighlightPayloads[annotation.id]) {
      const highlightPayload = this.origHighlightPayloads[annotation.id];
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.HIGHLIGHT_SCROLL_INTO_VIEW,
        highlightPayload,
      );
      // @deprecated but kept for backwards compatibility
      this.seamlessBridge.sendSeamlessDataMsg(
        ReadiatorMessageTypes.HIGHLIGHT_SELECT,
        highlightPayload,
      );
    }
    // @todo send Readiator ERROR if unable to scroll to highlight
  }

  public setConceptNavState(state: Partial<ConceptNavigatorState>): void {
    if (state.conceptNavEnabled === true) {
      this.conceptNavStore.setConceptNavEnabled(true);
      this.isConceptNavEnabled = true;
    } else if (state.conceptNavEnabled === false) {
      this.conceptNavStore.setConceptNavEnabled(false);
      this.isConceptNavEnabled = false;
    }
    this.handleConceptNavBodyClass();

    if (state.prevButtonEnabled === true) {
      this.conceptNavStore.setPrevButtonEnabled(true);
    } else if (state.prevButtonEnabled === false) {
      this.conceptNavStore.setPrevButtonEnabled(false);
    }

    if (state.currentButtonEnabled === true) {
      this.conceptNavStore.setCurrentButtonEnabled(true);
    } else if (state.currentButtonEnabled === false) {
      this.conceptNavStore.setCurrentButtonEnabled(false);
    }

    if (state.nextButtonEnabled === true) {
      this.conceptNavStore.setNextButtonEnabled(true);
    } else if (state.nextButtonEnabled === false) {
      this.conceptNavStore.setNextButtonEnabled(false);
    }
    // @todo send Readiator ERROR if unable to set concept nav state
  }

  public getConceptNavSate(): Partial<ConceptNavigatorState> {
    return {
      conceptNavEnabled: this.conceptNavigatorEnabled,
      prevButtonEnabled: this.prevConceptButtonEnabled,
      nextButtonEnabled: this.nextConceptButtonEnabled,
      currentButtonEnabled: this.currentConceptButtonEnabled,
    };
  }

  public triggerOnConceptNavPrev(): void {
    this.ttsStore.dispatch(ttsActions.pause({}));
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.CONCEPT_NAV_PREV,
      {},
    );
  }

  public triggerOnConceptNavNext(): void {
    this.ttsStore.dispatch(ttsActions.pause({}));
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.CONCEPT_NAV_NEXT,
      {},
    );
  }

  public triggerOnConceptNavCurrent(): void {
    this.ttsStore.dispatch(ttsActions.pause({}));
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.CONCEPT_NAV_CURRENT,
      {},
    );
  }

  public setFocusOnHighlightIcon(data: ReadiatorHighlightId): void {
    if (this.isTransitioning) {
      this.pendingSetFocus = { highlightIcon: data };
    }
    this.readiatorAwdIconService.setFocusOnHiglightIcon(
      data,
      this.cloIframe.contentDocument as Document,
    );
  }

  public setFocusOnLRIcon(data: ReadiatorHighlightId): void {
    if (this.isTransitioning) {
      this.pendingSetFocus = { lrIcon: data };
    }
    this.readiatorAwdIconService.setFocusOnLrIcon(
      data,
      this.cloIframe.contentDocument as Document,
    );
  }

  public forceRefresh(): void {
    this.navigationStore.dispatch(navigationActions.forceRefreshSpineItem());
    this.ttsStore.dispatch(ttsActions.resetPageAudio());
    this.annotationsOverlayService.close();
  }

  public subscribe(): void {
    ReadiatorBridgeService.notImplmentedYet();
  }

  public enableDebug(): void {
    this.isDebugMode = true;
  }

  public log(msg): void {
    if (this.isDebugMode) {
      console.error(msg);
    }
  }

  /** assignments */
  assignmentSubmit(): void {
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.ASSIGNMENT_SUBMIT,
      {},
    );
  }

  private executePendingFocus(): void {
    if (!this.pendingSetFocus) {
      return;
    }
    if (this.pendingSetFocus.cfi) {
      this.epubCfiLib.setFocusOnFirstElementInCfi(
        this.pendingSetFocus.cfi,
        this.cloIframe.contentDocument as Document,
      );
    } else if (this.pendingSetFocus.highlightIcon) {
      this.setFocusOnHighlightIcon(this.pendingSetFocus.highlightIcon);
    } else if (this.pendingSetFocus.lrIcon) {
      this.setFocusOnLRIcon(this.pendingSetFocus.lrIcon);
    } else if (this.pendingSetFocus.el) {
      if (typeof this.pendingSetFocus.el.focus === 'function') {
        this.pendingSetFocus.el.focus();
      }
    }
    this.pendingSetFocus = null;
  }

  // eslint-disable-next-line n/handle-callback-err
  private onError(error): void {
    // @todo wire up all calls with try/catch and handle them by
    // sending ERRROR Readiator message
  }

  /**
   * The readiator payloads are slightly different from the payloads
   * that are passed to the annotation highlighter.  This method exists
   * for backwards compatibility.
   */
  private convertReadiatorPayload(
    data: ReadiatorAnnotationData,
  ): AnnotationData {
    let annotationColor = 'yellow-highlight' as HighlightColorClass;
    if (data.colorClass) {
      annotationColor = (data.colorClass + '-highlight') as HighlightColorClass;
    }

    let annotationShape = 'epr-shape-square-filled' as HighlightShapeClass;
    if (data.shape) {
      annotationShape = data.shape;
    }

    const convertedData: AnnotationData = {
      id: data.$id as string,
      cfi: data.cfi,
      text: data.text ? data.text : '',
      highlight: true,
      focusable: data.focusable ? data.focusable : false,
      color: annotationColor,
      shape: annotationShape,
      hasLR: data.hasLR ? data.hasLR : false,
    };

    if (data.commentColor) {
      convertedData.commentColor = data.commentColor;
    }

    if (data.comments) {
      convertedData.comments = data.comments;
    }

    return convertedData;
  }

  private navigateByStep(step: number): void {
    this.navigationStore.dispatch(navigationActions.navigateByStep({ step }));
  }

  public onBentoDigtalTaggableSelect(
    data: ReadiatorBentoDigitalTaggingData,
  ): void {
    this.seamlessBridge.sendSeamlessDataMsg(
      ReadiatorMessageTypes.TAGGABLE_SELECT,
      data,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
