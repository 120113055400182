/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  exhaustMap,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  AnnotationGroup,
  ApiAnnotation,
  FlatTocItem,
  HighlightColorClass,
  ReaderAlert,
  isValidAnnotation,
  ApiSpine,
  SpineItem,
} from '@mhe/reader/models';

import * as annotationsQuery from '@mhe/reader/global-store/annotations/annotations.selectors';
import * as annotationsActions from '@mhe/reader/global-store/annotations/annotations.actions';
import { AnnotationsService } from '@mhe/reader/core/reader-api/annotations.service';
import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import { MediatorUtils } from './mediator-utils';
import {
  getAnnotations,
  getSpines,
  isSpineLoaded,
  haveSpineItems,
} from '@mhe/reader/global-store/annotations/annotations.selectors';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { ConfirmationModalComponent } from '@mhe/reader/components/modals/confirmation-modal';
import {
  HighlightListStore,
  NoteListStore,
  PlacemarkListStore,
} from '@mhe/reader/components/annotation-lists';
import * as highlightListActions from '@mhe/reader/components/annotation-lists/highlight-list/state/highlight-list.actions';
import * as noteListActions from '@mhe/reader/components/annotation-lists/note-list/state/note-list.actions';
import * as placemarkListActions from '@mhe/reader/components/annotation-lists/placemark-list/state/placemark-list.actions';

import {
  ExportHighlightsService,
  ExportNotesService,
} from '@mhe/reader/features/pdf-export';
import { OrphanAnnotationsModalComponent } from '@mhe/reader/components/modals';
import { AnnotationType } from '../components/annotation-lists/annotation.data';

@Injectable()
export class AnnotationListsMediator extends ComponentEffects {
  private readonly highlightListActions$ = this.highlightListStore.actions$;
  private readonly placemarkListActions$ = this.placemarkListStore.actions$;
  private readonly noteListActions$ = this.noteListStore.actions$;

  constructor(
    private readonly highlightListStore: HighlightListStore,
    private readonly placemarkListStore: PlacemarkListStore,
    private readonly noteListStore: NoteListStore,
    private readonly readerStore: ReaderStore,
    private readonly navigationStore: NavigationStore,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly exportHighlightsService: ExportHighlightsService,
    private readonly exportNotesService: ExportNotesService,
    private readonly mediatorUtils: MediatorUtils,
    private readonly annotationsService: AnnotationsService,
    private readonly configStore: ReaderConfigStore,
  ) {
    super();
  }

  askForAnnotations$ = this.effect(() => {
    return merge(
      this.highlightListActions$,
      this.placemarkListActions$,
      this.noteListActions$,
    ).pipe(
      ofType(
        highlightListActions.askForAnnotations,
        placemarkListActions.askForAnnotations,
        noteListActions.askForAnnotations,
      ),
      map((action) => action.spineID),
      withLatestFrom(
        this.store.select(isSpineLoaded),
        this.mediatorUtils.readerApi$,
        this.mediatorUtils.requestContext$,
      ),
      distinctUntilChanged(
        ([previousSpineId], [currentSpineId, isSpineLoadedOnCurrentState]) =>
          previousSpineId === currentSpineId ||
          isSpineLoadedOnCurrentState(currentSpineId),
      ),
      mergeMap(([spineID, , readerApi, requestContext]) => {
        return this.annotationsService
          .getAnnotationsForEpub(readerApi, {
            ...requestContext,
            spineId: spineID,
          })
          .pipe(
            tap(({ items: annotations }) => {
              this.store.dispatch(
                annotationsActions.addAnnotationsBySpineId({
                  spineId: spineID,
                  annotations,
                  loaded: true,
                }),
              );
            }),
          );
      }),
      map((t) => t),
      logCatchError('askForAnnotations$'),
    );
  });

  getHighlightsForLabel$ = this.effect(() => {
    return this.highlightListActions$.pipe(
      ofType(highlightListActions.getAnnotationsForLabel),
      map((action) => action.spineID),
      this.mediatorUtils.getSpinesByLabel,
      tap((spines: ApiSpine[]) => {
        spines.forEach((spine) =>
          this.highlightListStore.dispatch(
            highlightListActions.askForAnnotations({ spineID: spine.spineID }),
          ),
        );
      }),
      logCatchError('getHighlightsForLabel$'),
    );
  });

  getNotesForLabel$ = this.effect(() => {
    return this.noteListActions$.pipe(
      ofType(noteListActions.getAnnotationsForLabel),
      map((action) => action.spineID),
      this.mediatorUtils.getSpinesByLabel,
      tap((spines: ApiSpine[]) => {
        spines.forEach((spine) =>
          this.noteListStore.dispatch(
            noteListActions.askForAnnotations({ spineID: spine.spineID }),
          ),
        );
      }),
      logCatchError('getNotesForLabel$'),
    );
  });

  getPlacemarksForLabel$ = this.effect(() => {
    return this.placemarkListActions$.pipe(
      ofType(placemarkListActions.getAnnotationsForLabel),
      map((action) => action.spineID),
      this.mediatorUtils.getSpinesByLabel,
      tap((spines: ApiSpine[]) => {
        spines.forEach((spine) =>
          this.placemarkListStore.dispatch(
            placemarkListActions.askForAnnotations({ spineID: spine.spineID }),
          ),
        );
      }),
      logCatchError('getPlacemarksForLabel$'),
    );
  });

  sendFromStoreToTrees$ = this.effect(() =>
    combineLatest([
      this.store.select(getAnnotations),
      this.store.select(getSpines),
      this.readerStore.flatToc$.pipe(filter((x) => x !== undefined)),
      this.readerStore.spine$,
      this.store.select(haveSpineItems),
    ]).pipe(
      map(
        ([annotations, spines, flatTocDict, bookSpines, SI]: [
          ApiAnnotation[],
          ApiSpine[],
          Record<number, FlatTocItem>,
          SpineItem[],
          ApiSpine,
        ]) => {
          const highlights: AnnotationGroup[] = [];
          const placemarks: AnnotationGroup[] = [];
          const notes: AnnotationGroup[] = [];
          const flatToc = Object.values(flatTocDict);

          const extractCifsRegex: RegExp = /\[data-uuid-(.+?)\]/gm;

          const spineItems = SI?.spineItems as string[];

          spines.forEach((spine) => {
            const spineIndex = this.getSpineIndexFromSpineId(flatToc, spine.spineID, bookSpines);
            if (!spineIndex) return;

            const groupLabel = spineIndex.label;
            const spinePos = spineIndex.spinePos;
            if (spine.hasHighlight) {
              if (
                highlights.filter((h) => h.groupLabel === groupLabel).length ===
                0
              ) {
                highlights.push({
                  ...spine,
                  groupId: spine.spineID,
                  groupLabel,
                  spinePos,
                  annotations: [],
                });
              }
            }
            if (spine.hasPlacemark) {
              if (
                placemarks.filter((h) => h.groupLabel === groupLabel).length ===
                0
              ) {
                placemarks.push({
                  ...spine,
                  groupId: spine.spineID,
                  groupLabel,
                  spinePos,
                  annotations: [],
                });
              }
            }
            if (spine.hasNote) {
              if (
                notes.filter((h) => h.groupLabel === groupLabel).length === 0
              ) {
                notes.push({
                  ...spine,
                  groupId: spine.spineID,
                  groupLabel,
                  spinePos,
                  annotations: [],
                });
              }
            }
          });

          annotations.forEach((annotation) => {
            const flatTocItem = this.getSpineIndexFromSpineId(
              flatToc,
              annotation.spineID as string,
              bookSpines,
            );

            if (!flatTocItem) return;

            if (annotation.spineID === SI?.spineID) {
              const [, ...annotationCfis] = annotation.cfi
                .match(extractCifsRegex)?.map((cfi: string) => cfi.replace('[', '').replace(']', '')) as string[];

              if (annotationCfis?.every(item => {
                const mapped = spineItems?.includes(item);
                return mapped;
              })) {
                annotation = {
                  ...annotation,
                  orphan: false,
                };
              } else {
                annotation = {
                  ...annotation,
                  orphan: true,
                };
              }
            }

            if (annotation.highlight) {
              const group = highlights.find(
                (g) => g.groupLabel === flatTocItem?.label,
              );
              if (group) {
                group.annotations.push(annotation);
              } else {
                const { label: groupLabel, spinePos } = flatTocItem;
                highlights.push({
                  groupId: flatTocItem.id as string,
                  groupLabel,
                  spinePos,
                  annotations: [annotation],
                });
              }
            }

            if (annotation.placemarkText) {
              const group = placemarks.find(
                (g) => g.groupLabel === flatTocItem?.label,
              );
              if (group) {
                group.annotations.push(annotation);
              } else {
                const { label: groupLabel, spinePos } = flatTocItem;
                placemarks.push({
                  groupId: flatTocItem.id as string,
                  groupLabel,
                  spinePos,
                  annotations: [annotation],
                });
              }
            }

            if (annotation.note) {
              const group = notes.find(
                (g) => g.groupLabel === flatTocItem?.label,
              );
              if (group) {
                group.annotations.push(annotation);
              } else {
                const { label: groupLabel, spinePos } = flatTocItem;
                notes.push({
                  groupId: flatTocItem.id as string,
                  groupLabel,
                  spinePos,
                  annotations: [annotation],
                });
              }
            }
          });

          this.sortAnnotationsByCreatedDate(highlights);
          this.sortAnnotationsByCreatedDate(placemarks);
          this.sortAnnotationsByCreatedDate(notes);

          highlights.sort(
            (a, b) => (a.spinePos as number) - (b.spinePos as number),
          );
          placemarks.sort(
            (a, b) => (a.spinePos as number) - (b.spinePos as number),
          );
          notes.sort((a, b) => (a.spinePos as number) - (b.spinePos as number));

          this.highlightListStore.setGroups(highlights);
          this.placemarkListStore.setGroups(placemarks);
          this.noteListStore.setGroups(notes);

          const haveOrphanAnnotations = [
            ...highlights.map(h => h.annotations),
            ...placemarks.map(p => p.annotations),
            ...notes.map(n => n.annotations),
          ]
            .flat()
            .some(annotation => annotation.orphan);
          return haveOrphanAnnotations;
        },
      ),
      filter(orphans => Boolean(orphans)),
      filter(() => {
        const orphanNotification = localStorage.getItem('orphan-annotations-notification');
        if (!orphanNotification || orphanNotification === 'false') {
          return true;
        }
        return false;
      }),
      distinctUntilChanged(),
      switchMap(() =>
        this.showOrphanAnnotationsNotificationModal$('orphan_annotations.title').pipe(
          tap((val: boolean) => {
            localStorage.setItem('orphan-annotations-notification', val === undefined ? 'false' : val.toString());
          }),
        ),
      ),
    ),
  );

  annotationListsReadonly$ = this.effect(() =>
    this.configStore.assignment$.pipe(
      map((assignment) => assignment === 'review'),
      tap((readonly) => this.highlightListStore.setReadonly(readonly)),
      tap((readonly) => this.placemarkListStore.setReadonly(readonly)),
      tap((readonly) => this.noteListStore.setReadonly(readonly)),
      logCatchError('annotationListsReadonly$'),
    ),
  );

  annotationSelected$ = this.effect(() =>
    merge(
      this.highlightListActions$,
      this.placemarkListActions$,
      this.noteListActions$,
    ).pipe(
      ofType(
        highlightListActions.annotationSelected,
        placemarkListActions.annotationSelected,
        noteListActions.annotationSelected,
      ),
      map((action) => action.annotation),
      tap((annotation) =>
        this.navigationStore.dispatch(
          navigationActions.navigateByCfi({
            cfi: annotation.cfi,
            setFocus: true,
          }),
        ),
      ),
      tap(() => this.readerStore.setLeftDrawerOpen(false)),
      logCatchError('annotationSelected$'),
    ),
  );

  deleteBatchHighlight$ = this.effect(() =>
    merge(
      this.highlightListActions$,
      this.placemarkListActions$,
      this.noteListActions$,
    ).pipe(
      ofType(
        highlightListActions.highlightBatchDelete,
        noteListActions.notesBatchDelete,
        placemarkListActions.placemarksBatchDelete,
      ),
      exhaustMap(({ data: { annotations, type } }) => {
        let translationBatchKey: string;
        let translationBatchBodyKey: string;
        switch (type) {
          case AnnotationType.HIGHLIGHT:
            translationBatchKey = 'dialog.are_you_sure.highlight_batch_title';
            translationBatchBodyKey = 'dialog.are_you_sure.highlight_batch';
            break;
          case AnnotationType.NOTE:
            translationBatchKey = 'dialog.are_you_sure.note_batch_title';
            translationBatchBodyKey = 'dialog.are_you_sure.notes_batch';
            break;
          case AnnotationType.PLACEMARK:
            translationBatchKey = 'dialog.are_you_sure.placemark_batch_title';
            translationBatchBodyKey = 'dialog.are_you_sure.placemarks_batch';
            break;
          default:
            translationBatchKey = 'dialog.are_you_sure.highlight_batch_title';
            translationBatchBodyKey = 'dialog.are_you_sure.highlight_batch';
            break;
        }
        const body = this.deleteModalBody(
          null,
          translationBatchBodyKey,
        );
        return this.showDeleteModal$(
          body,
          translationBatchKey,
        ).pipe(
          tap((returnValue: boolean | undefined) => {
            if (!returnValue) {
              this.highlightListStore.batchDeleteCanceled();
              this.noteListStore.batchDeleteCanceled();
              this.placemarkListStore.batchDeleteCanceled();
            }
          }),
          filter((returnValue) => Boolean(returnValue)),
          mapTo({
            annotations,
          }),
          switchMap(({ annotations }): any => {
            return this.deleteBatchAnnotations(annotations);
          }),
        );
      }),
    ),
  );

  deleteHighlight$ = this.effect(() =>
    this.highlightListActions$.pipe(
      ofType(highlightListActions.highlightDelete),
      exhaustMap(({ annotation }) => {
        const body = this.deleteModalBody(
          annotation.text,
          'dialog.are_you_sure.highlight',
        );
        return this.showDeleteModal$(
          body,
          'dialog.are_you_sure.highlight_title',
        ).pipe(
          filter((returnValue) => returnValue),
          mapTo({
            ...annotation,
            color: 'yellow-highlight' as HighlightColorClass, // yellow because all annotations expect a color
            shape: undefined,
            highlight: false,
          }),
          switchMap((modifiedAnnotation) =>
            isValidAnnotation(modifiedAnnotation)
              ? this.saveAnnotation(modifiedAnnotation)
              : this.deleteAnnotation(annotation),
          ),
        );
      }),
      logCatchError('deleteHighlight$'),
    ),
  );

  deletePlacemark$ = this.effect(() =>
    this.placemarkListActions$.pipe(
      ofType(placemarkListActions.placemarkDelete),
      exhaustMap(({ annotation }) => {
        const body = this.deleteModalBody(
          annotation.text,
          'dialog.are_you_sure.placemark',
        );
        return this.showDeleteModal$(
          body,
          'dialog.are_you_sure.placemark_title',
        ).pipe(
          filter((returnValue) => returnValue),
          mapTo({ ...annotation, placemarkText: undefined }),
          switchMap((modifiedAnnotation) =>
            isValidAnnotation(modifiedAnnotation)
              ? this.saveAnnotation(modifiedAnnotation)
              : this.deleteAnnotation(annotation),
          ),
        );
      }),
      logCatchError('deletePlacemark$'),
    ),
  );

  deleteNote$ = this.effect(() =>
    this.noteListActions$.pipe(
      ofType(noteListActions.noteDelete),
      exhaustMap(({ annotation }) => {
        const body = this.deleteModalBody(
          annotation.note as string,
          'dialog.are_you_sure.note',
        );
        return this.showDeleteModal$(
          body,
          'dialog.are_you_sure.note_title',
        ).pipe(
          filter((returnValue) => returnValue),
          mapTo({ ...annotation, note: undefined }),
          switchMap((modifiedAnnotation) =>
            isValidAnnotation(modifiedAnnotation)
              ? this.saveAnnotation(modifiedAnnotation)
              : this.deleteAnnotation(annotation),
          ),
        );
      }),
      logCatchError('deleteNote$'),
    ),
  );

  private deleteModalBody(
    text: string | null,
    areYouSureTranslationKey: string,
    characterLimit = 50,
  ): string {
    if (!text) {
      return `<div>${this.translate.instant(areYouSureTranslationKey)}</div>`;
    }
    const annotationText =
      text.length > characterLimit
        ? `${text.slice(0, characterLimit)}...`
        : text;
    const areYouSureText = this.translate.instant(areYouSureTranslationKey);
    return `<div>${areYouSureText}</div><blockquote>${annotationText}</blockquote>`;
  }

  private showDeleteModal$(
    content: string,
    titleTranslationKey: string,
  ): Observable<any> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          closeText: this.translate.instant('shared.cancel'),
          confirmText: this.translate.instant('shared.ok'),
          content,
          title: this.translate.instant(titleTranslationKey),
        },
        ariaLabelledBy: 'confirmation-modal-h2',
      })
      .afterClosed();
  }

  private showOrphanAnnotationsNotificationModal$(
    titleTranslationKey: string,
  ): Observable<any> {
    return this.dialog
      .open(OrphanAnnotationsModalComponent, {
        data: {
          title: this.translate.instant(titleTranslationKey),
          // title: titleTranslationKey,
        },
        ariaLabelledBy: 'orphan-annotations-modal-h2',
      })
      .afterClosed();
  }

  exportHighlights$ = this.effect(() =>
    this.highlightListActions$.pipe(
      ofType(highlightListActions.exportHighlights),
      withLatestFrom(
        this.readerStore.epubKey$,
        this.readerStore.flatToc$,
        this.readerStore.title$,
        this.readerStore.spine$,
      ),
      switchMap(([, epubUrl, flatToc, title, bookSpines]) =>
        this.store.pipe(
          select(annotationsQuery.getAnnotations, { epubUrl }),
          first(),
          map((annotations) =>
            this.groupAnnotationsBySpine(
              annotations,
              flatToc,
              bookSpines as SpineItem[],
            ),
          ),
          exhaustMap((annotationGroups) =>
            this.exportHighlightsService.export(annotationGroups, title),
          ),
          tap((pdf) => pdf.download(`${title}-highlights.pdf`)),
        ),
      ),
      logCatchError('exportHighlights$'),
    ),
  );

  exportNotes$ = this.effect(() =>
    this.noteListActions$.pipe(
      ofType(noteListActions.exportNotes),
      withLatestFrom(
        this.readerStore.epubKey$,
        this.readerStore.flatToc$,
        this.readerStore.title$,
        this.readerStore.spine$,
      ),
      switchMap(([, epubUrl, flatToc, title, bookSpines]) =>
        this.store.pipe(
          select(annotationsQuery.getAnnotations, { epubUrl }),
          first(),
          map((annotations) =>
            this.groupAnnotationsBySpine(
              annotations,
              flatToc,
              bookSpines as SpineItem[],
            ),
          ),
          exhaustMap((annotationGroups) =>
            this.exportNotesService.export(annotationGroups, title),
          ),
          tap((pdf) => pdf.download(`${title}-notes.pdf`)),
        ),
      ),
      logCatchError('exportNotes$'),
    ),
  );

  private saveAnnotation(annotation: ApiAnnotation): Observable<ApiAnnotation> {
    return of(annotation).pipe(
      withLatestFrom(this.mediatorUtils.readerApi$),
      mergeMap(([_, readerApi]) => {
        const alert: ReaderAlert = {
          alertType: 'alert-error',
          translateKey: 'shared.generic_save_error',
        };

        return this.annotationsService
          .saveAnnotation(readerApi, annotation)
          .pipe(
            tap(() => this.readerStore.removeAlert(alert.translateKey)),
            this.mediatorUtils.catchErrorAlert(alert),
          );
      }),
      tap((upsertedAnnotation) => {
        this.store.dispatch(
          annotationsActions.addAnnotation({ annotation: upsertedAnnotation }),
        );
      }),
      map((upsertedAnnotation) => upsertedAnnotation),
    );
  }

  private getFlatTocItemBySpineId(
    flatToc: FlatTocItem[],
    spineId: string,
  ): FlatTocItem | undefined {
    for (const flatTocItem of flatToc) {
      if (flatTocItem.spineItem.id === spineId) {
        return flatTocItem;
      }
    }
    for (const parent of flatToc) {
      const found = this.getFlatTocItemBySpineId(parent.subItems, spineId);
      if (found) {
        return found;
      }
    }
  }

  private deleteBatchAnnotations(
    annotations: ApiAnnotation[],
  ): Observable<ApiAnnotation[]> {
    return of(annotations).pipe(
      withLatestFrom(this.mediatorUtils.readerApi$, this.mediatorUtils.requestContext$),
      mergeMap(([_, readerApi, context]) => {
        const alert: ReaderAlert = {
          alertType: 'alert-error',
          translateKey: 'shared.generic_save_error',
        };

        return this.annotationsService
          .deleteBatchAnnotations(readerApi, context, annotations)
          .pipe(
            tap(() => this.readerStore.removeAlert(alert.translateKey)),
            this.mediatorUtils.catchErrorAlert(alert),
          );
      }),
      tap(() =>
        this.store.dispatch(annotationsActions.removeBatchAnnotations({ annotations })),
      ),
      tap(() => {
        this.highlightListStore.batchDeleteSuccess();
        this.noteListStore.batchDeleteSuccess();
        this.placemarkListStore.batchDeleteSuccess();
      }),
      mapTo(annotations),
      tap(() => {
        this.highlightListStore.batchDeleteIdle();
        this.noteListStore.batchDeleteIdle();
        this.placemarkListStore.batchDeleteIdle();
      }),
    );
  }

  private deleteAnnotation(
    annotation: ApiAnnotation,
  ): Observable<ApiAnnotation> {
    return of(annotation).pipe(
      withLatestFrom(this.mediatorUtils.readerApi$),
      mergeMap(([_, readerApi]) => {
        const alert: ReaderAlert = {
          alertType: 'alert-error',
          translateKey: 'shared.generic_save_error',
        };

        return this.annotationsService
          .deleteAnnotation(readerApi, annotation.resourceUUID)
          .pipe(
            tap(() => this.readerStore.removeAlert(alert.translateKey)),
            this.mediatorUtils.catchErrorAlert(alert),
          );
      }),
      tap(() =>
        this.store.dispatch(annotationsActions.removeAnnotation({ annotation })),
      ),
      mapTo(annotation),
    );
  }

  private findClosestTocItem(
    flatToc: FlatTocItem[],
    spineIndex: number,
  ): FlatTocItem {
    let maxSoFar = 0;

    flatToc.forEach((toc) => {
      if (toc.spinePos > maxSoFar && toc.spinePos < spineIndex) {
        maxSoFar = toc.spinePos;
      }
    });

    return flatToc.filter((toc) => toc.spinePos === maxSoFar)[0];
  }

  private yetAnotherMethod(
    flatToc: FlatTocItem[],
    flatTocItem: FlatTocItem,
  ): { start: number, end: number } {
    let matchingTocItem!: FlatTocItem;

    for (const [i, f] of flatToc.entries()) {
      if (f.id === flatTocItem.id) {
        matchingTocItem = flatToc[i + 1];
        break;
      }
    }

    return { start: flatTocItem.spinePos, end: matchingTocItem.spinePos };
  }

  private getSpineIndexFromSpineId(
    flatToc: FlatTocItem[],
    spineId: string,
    bookSpines: SpineItem[],
  ): FlatTocItem | null {
    const spineIndex = bookSpines.filter((spine) => spine.id === spineId)[0]?.index;
    if (!spineIndex) {
      return null;
    }
    const flatTocItems = flatToc.filter((toc) => toc.spinePos === spineIndex);
    if (flatTocItems && flatTocItems.length === 1) {
      return flatTocItems[0];
    }

    return this.findClosestTocItem(flatToc, spineIndex);
  }

  private groupAnnotationsBySpine(
    annotations: ApiAnnotation[],
    flatToc: Record<number, FlatTocItem>,
    bookSpines: SpineItem[],
  ): AnnotationGroup[] {
    return [...annotations]
      .sort((a, b) => (a.spineLocation as number) - (b.spineLocation as number))
      .reduce((acc: any[], annotation) => {
        const flatTocItem = this.getSpineIndexFromSpineId(
          Object.values(flatToc),
          annotation.spineID as string,
          bookSpines,
        );
        if (!flatTocItem) return [...acc];

        const existingGroup = acc.find(
          (group) => group.groupId === flatTocItem?.id,
        );
        if (existingGroup) {
          existingGroup.annotations.push(annotation);
          return acc;
        } else {
          return [
            ...acc,
            {
              groupId: flatTocItem?.id,
              groupLabel: flatTocItem?.label || 'Orphaned Page',
              annotations: [annotation],
            },
          ];
        }
      }, []);
  }

  private sortAnnotationsByCreatedDate(groups: AnnotationGroup[]): void {
    groups.forEach((group) =>
      group.annotations.sort((a: ApiAnnotation, b: ApiAnnotation) =>
        this.sortByDateString(a.updatedAt as string, b.updatedAt as string),
      ),
    );
  }

  private sortByDateString(a: string, b: string): 1 | -1 {
    return new Date(a) > new Date(b) ? 1 : -1;
  }
}
