import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'breadcrumbsDescription',
})
export class BreadcrumbsDescriptionPipe implements PipeTransform {
  constructor(private readonly translate: TranslatePipe) {}

  transform(node: { breadcrumbs: string[], title: string }): string {
    return [...node.breadcrumbs, node.title]
      .reverse()
      .join(this.translate.transform('elastic_search.breadcrumbs_separator'));
  }
}
