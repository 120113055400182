import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';

import { defaultUserSettings, UserSettings } from '../models';
import * as actions from './user-settings.actions';

@Injectable()
export class UserSettingsStore extends ExtendedComponentStore<
{ fontSize?: number, teacherContentEnabled?: boolean },
actions.UserSettingsActions
> {
  constructor() {
    super(defaultUserSettings);
  }

  /** selectors */
  readonly userSettings$ = this.select(({ fontSize, teacherContentEnabled }) => {
    return { fontSize, teacherContentEnabled };
  });

  readonly setUserSettings = this.updater((state, userSettings: UserSettings) => {
    return { ...state, ...userSettings };
  });
}
