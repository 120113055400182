import {
  TTSChunkCollection,
  TTSGender,
  TTSJobStatus,
  TTSUtteranceTiming,
  TTSVoice,
  TTSVoiceName,
} from '../player-api.model';

/**
 * Playback modes for audio.
 * DEFAULT will stop playback when it gets to
 * the end of the track.
 * AUTO_FLIP will skip to the next page when
 * it gets to the end of the track.
 */
export enum TTSPlaybackMode {
  AUTO_FLIP = 'auto-flip',
  DEFAULT = 'default',
}

export enum TTSAudioContext {
  PAGE = 'page',
  HIGHLIGHT = 'highlight',
}

export interface PlaybackProgress {
  /**
   * The current track position, in miliseconds.
   */
  readonly playbackPosition: number

  /**
   * Identifiers that indicate which words or
   * groups of words are currently being spoken.
   */
  readonly utteranceIds?: Array<string | number>

  /**
   * Indicates whether the audio is currently being played.
   */
  readonly isPlaying: boolean

  /**
   * The HTMLMediaElement.readyState property of the audio.
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
   */
  readonly readyState?: number

  /**
   * Indicates there is an error with the playback,
   * so it's not possible to play audio.
   */
  readonly error?: MediaError

  /**
   * Any other implementation-specific data related
   * to a progress event.
   */
  readonly metadata?: object
}

export interface ChunkedAudioProgress extends PlaybackProgress {
  metadata?: {
    activeUtterances: Utterance[]
    activeChunk: AudioChunk
  }
}

export interface AnnotationUtterance {
  text: string
  html: string
  cfi: string
}

export interface Utterance extends AnnotationUtterance {
  id: number
}

export interface AudioTiming {
  start: number
  end: number
  utterances: Utterance[]
}

export interface AudioChunk {
  cfiPath: string // the cfiPath + index uniquely identifies this chunk
  index: number
  audio: HTMLAudioElement | undefined
  mp3Url: string
  context: TTSAudioContext
  timing: TTSUtteranceTiming[]
  startTime: number | undefined
  endTime: number | undefined
}

export interface AudioChunkCollection {
  cfiPath: string | undefined
  chunks: AudioChunk[]
  status: TTSJobStatus | undefined
  voice?: TTSVoiceName
}

export type PartialTTSChunkCollection = Pick<
TTSChunkCollection,
'chunks' | 'status'
> &
Partial<TTSChunkCollection>;

export interface TTSOptions {
  gender?: TTSGender
  language?: string
  voice?: TTSVoice | null
}

export interface TTSRawTextOptions {
  text: string
  cfi: string
  pageLanguage: string | undefined
  autoplay: boolean
}

export interface TTSAudioSettings {
  volume: number // in [0,1]
  playbackRate: number // a value of 1.0 is normal playback speed
}

export interface TTSFloatingTextRequest {
  /**
   * This is used in place of the CFI as the key in the TTS store.
   */
  id: string

  /**
   * Plain text or HTML for Readspeaker to translate and return as audio.
   */
  text: string

  /**
   * The computed x and y position where the TTS controls should be positioned.
   */
  location: { x: number, y: number }

  /**
   * Whether to start playing automatically, or require the user to initiate playback.
   */
  autoplay: boolean
}
