import { Injectable } from '@angular/core';

import { of, take } from 'rxjs';
import Bowser from 'bowser';

import { DevicePlatform } from '@mhe/reader/common';
import { ReaderConfigStore } from '@mhe/reader/components/reader/state';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  parser: any;
  private _browserName: string;
  private _platformType: DevicePlatform;

  constructor(
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly window: Window,
  ) {
    this.getParser();
  }

  get isTouchScreen(): boolean {
    return this.window.navigator.maxTouchPoints > 0;
  }

  get browserName(): string {
    return this._browserName;
  }

  get platformType(): string {
    return this._platformType;
  }

  getParser(): void {
    try {
      this.parser = Bowser.getParser(this.window.navigator.userAgent);
    } catch (error) {
      console.error(error);
    }
  }

  init(): void {
    this.setBrowserName();
    this.setPlatformType();
  }

  setBrowserName(): void {
    try {
      this._browserName = this.parser?.getBrowserName();
    } catch (error) {
      console.error(error);
    }
  }

  setPlatformType(): void {
    if (!this.parser) {
      console.error('Failed to parse user agent string');

      this._platformType = DevicePlatform.Desktop;

      return;
    }

    const authoringObservable = this.readerConfigStore.authoring$ || of(false);

    authoringObservable.pipe(take(1)).subscribe((isAuthoring) => {
      const hasTouchPoints = this.window.navigator.maxTouchPoints > 0;
      const isWindows = this.parser.getOSName() === 'Windows';
      const isFirefox = this._browserName === 'Firefox';

      const userAgentPlatformType = this.parser.getPlatformType() as DevicePlatform;

      // For Avalon Authoring we support only mouse and trackpad interactions on touchscreen desktop devices due to the constraints of the quick highlighting flow
      if (!userAgentPlatformType || isAuthoring) {
        this._platformType = DevicePlatform.Desktop;

        // iPads are treated as desktops and Windows 2-in-1 devices support touch
        // So, we must check for touchpoints on desktops
      } else if (userAgentPlatformType === DevicePlatform.Desktop && hasTouchPoints) {
        // This workaround allows Firefox users on Windows 2-in-1 devices to annotate with mouse at least
        this._platformType =
          isWindows && isFirefox ? DevicePlatform.Desktop : DevicePlatform.Tablet;
      } else {
        this._platformType = userAgentPlatformType;
      }
    });
  }
}
