import { Injectable } from '@angular/core';

@Injectable()
export class EpubLibService {
  /**
   * The GLOBAL Annotation Library
   */
  protected epubLib;

  constructor(window: Window) {
    // check if ePubAnnotation global exists
    if ((window as any).ePubAnnotation === undefined) {
      // @todo how to handle this case?
    } else {
      this.epubLib = (window as any).ePubAnnotation;
    }
  }

  public createFromSelection(
    doc: Document,
    cfiBase: string,
    optionalLegacyVersion: string = 'v5',
    nonTextFlag: boolean = false,
  ): { cfi: string, text: string } {
    return this.epubLib.createFromSelection(doc, cfiBase, optionalLegacyVersion, nonTextFlag);
  }
}
