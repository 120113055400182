/* eslint-disable max-len */
import { Route } from '@angular/router';

import { EpubUiViewerComponent } from '@mhe/reader/components/epub-ui-viewer/epub-ui-viewer.component';
import { NotFoundComponent } from '@mhe/reader/components/not-found/not-found.component';
import { TimeoutComponent } from '@mhe/reader/components/timeout/timeout.component';
import { AccessDeniedComponent } from '@mhe/reader/components/access-denied/access-denied.component';
import { InternalServerErrorComponent } from '@mhe/reader/components/internal-error/internal-server-error.component';

export const routes: Route[] = [
  {
    path: 'epub/:id',
    component: EpubUiViewerComponent,
  },
  {
    // TODO: { :spine + #hash } will probably need reworked in favor of CFI fragment
    path: 'epub/:id/:spine',
    component: EpubUiViewerComponent,
  },
  {
    path: '0',
    component: AccessDeniedComponent,
  },
  {
    path: '401',
    component: TimeoutComponent,
  },
  {
    path: '403',
    component: AccessDeniedComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '500',
    component: InternalServerErrorComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
