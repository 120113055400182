/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { EPubLoaderService } from '@mhe/reader/features/epub-loader';
import * as actions from './epub.actions';
import * as query from './epub.selectors';
import { Book, ParserOptions } from '@mhe/reader/models';
import { ReaderStore } from '@mhe/reader/components/reader/state';

@Injectable()
export class EpubEffects {
  readonly fetchEpub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchEpub),
      concatMap(({ url, url_leveled, readerConfig, parserOptions }) => {
        return of({ url, url_leveled, readerConfig, parserOptions }).pipe(
          withLatestFrom(
            this.store.pipe(select(query.getEpubBook, { url: url_leveled })),
          ),
        );
      }),
      filter(([_, book]) => !book),
      map(([params]) => actions.loadEpub({ ...params })),
    ),
  );

  readonly loadEpub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadEpub),
      mergeMap(({ url, url_leveled, readerConfig, parserOptions }) => {
        return this.epubLoaderService
          .buildFromUrl(url, parserOptions as ParserOptions, readerConfig)
          .pipe(
            map((book): any =>
              actions.loadEpubSuccess({ url, url_leveled, book: book as Book }),
            ),
            catchError((error) =>
              of(actions.loadEpubError({ url, url_leveled, error })),
            ),
          );
      }),
    ),
  );

  readonly loadFactoryMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadFactoryMetadata),
      mergeMap(({ url }) => {
        return this.epubLoaderService
          .loadFactoryMetadata(url)
          .pipe(
            map((metadataJson) => {
              if (metadataJson) {
                this.readerStore.setFactoryMetadata(metadataJson);
              }
              return actions.loadFactoryMetadataSuccess({ url, metadataJson });
            }),
            catchError((error) =>
              of(actions.loadFactoryMetadataError({ url, error })),
            ),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly epubLoaderService: EPubLoaderService,
    private readonly store: Store,
    private readonly readerStore: ReaderStore,
  ) {}
}
