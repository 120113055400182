/* eslint-disable @typescript-eslint/quotes */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AnnotationLisDropdownComponent } from './dropdown/dropdown.component';
import { AnnotationListMenuComponent } from './menu/menu.component';
import { AnnotationType, DropdownState } from '../annotation.data';

@Component({
  selector: 'rdrx-annotation-list-header',
  standalone: true,
  imports: [
    CommonModule,
    AnnotationLisDropdownComponent,
    AnnotationListMenuComponent,
  ],
  template: `
    <div
      class="header go-to-active"
      [ngClass]="{
        'annotation-list-empty': headerClass
      }"
    >
      <rdrx-annotations-dropdown
        [type]="type"
        [isEditMode]="isEditMode"
        [areAllAnnotationsSelected]="areAllAnnotationsSelected"
        (annotationsDropdownState)="dropdownOptionSelected.emit($event)"
        (allAnnotationsSelected)="allAnnotationsSelected.emit($event)"
      ></rdrx-annotations-dropdown>

      <rdrx-annotations-menu
        [type]="type"
        (editModeMenuSelected)="editModeSelected.emit($event)"
        (exportAnnotationsSelected)="exportAnnotationsSelected.emit()"
      ></rdrx-annotations-menu>
    </div>
  `,
  styleUrls: ['./annotation-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationListHeaderComponent {
  @Input({ required: true }) type!: AnnotationType;
  @Input({ required: true }) headerClass!: boolean;
  @Input({ required: true }) isEditMode!: boolean;
  @Input({ required: true }) areAllAnnotationsSelected!: boolean;
  @Output() editModeSelected = new EventEmitter<boolean>();
  @Output() dropdownOptionSelected = new EventEmitter<DropdownState>();
  @Output() allAnnotationsSelected = new EventEmitter();
  @Output() exportAnnotationsSelected = new EventEmitter();
}
