import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { AnchorType } from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { LargeImageNavigatorComponent } from '@mhe/reader/components/large-image-navigator';
import { GlossaryModalStore } from '@mhe/reader/components/modals/glossary-modal';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { getAnchorType } from '@mhe/reader/utils';
import { MediatorUtils } from './mediator-utils';

@Injectable()
export class LargeImageNavigatorMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;
  private readonly glossaryModalActions$ = this.glossaryStore.actions$;

  private readonly filterAuthoring = <T>(
    source$: Observable<T>,
  ): Observable<T> =>
    source$.pipe(
      withLatestFrom(this.readerConfigStore.authoring$),
      filter(([, authoring]) => !authoring),
      map(([source]) => source),
    );

  constructor(
    private readonly dialog: MatDialog,
    private readonly transformStore: TransformStore,
    private readonly glossaryStore: GlossaryModalStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly mediatorUtils: MediatorUtils,
  ) {
    super();
  }

  private readonly largeImageNavigator$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.anchorClick),
      map(({ anchor }) => anchor),
      this.filterAuthoring,
      this.mediatorUtils.filterLinks,
      filter((anchor) => this.isLargeImageAnchor(anchor)),
      exhaustMap((anchor) => this.handleLargeImageAnchor(anchor)),
      logCatchError('largeImageNavigator$'),
    ),
  );

  isLargeImageAnchor(anchor: HTMLAnchorElement): boolean {
    const anchorType = getAnchorType(anchor);

    return anchorType === AnchorType.LARGE_IMAGE;
  }

  handleLargeImageAnchor(anchor: HTMLAnchorElement): Observable<any> {
    const img = anchor.querySelector('img');
    const src = img?.src ?? '';
    const alt = this.cleanAlt(img?.alt ?? '');
    const credit = anchor.parentElement?.querySelector<HTMLElement>(
      '.mhe-inline-credit',
    )?.innerText ?? '';
    const dialog$ = this.getDialogRef(src, alt, credit);

    return dialog$.pipe(tap((_) => anchor.focus()));
  }

  cleanAlt(alt: string): string {
    const cleanedAlt = alt.replace(' large image navigator opens in a modal', '');

    return cleanedAlt;
  }

  getDialogRef(
    src: string,
    alt: string,
    credit: string,
  ): Observable<LargeImageNavigatorComponent> {
    return this.dialog
      .open(LargeImageNavigatorComponent, {
        data: { src, alt, credit },
        height: '90%',
        /*
          This ensures the LIN fills the content iframe and the image displays
          when Reader is opened in an OLP modal on small screens (EPR-10101)
        */
        minHeight: '219px',
        /*
          This ensures the LIN fills the content iframe and the image displays
          when Reader is opened in an OLP modal on small screens (320px x 256px) (EPR-10270)
        */
        minWidth: '300px',
        width: '85%',
        panelClass: 'rdrx-large-img-dialog',
        maxWidth: '97%',
        ariaLabelledBy: 'large-image-navigator-h2',
        disableClose: true,
      })
      .afterClosed();
  }
}
