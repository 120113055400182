export interface UserSettingsAPIResponse {
  platform: string
  resourceUUID: string
  userID: string

  // timestamps
  createdAt?: string
  updatedAt?: string

  // user setting JSON string data
  data: string
}

export interface UserSettings {
  teacherContentEnabled?: boolean
  fontSize?: number
}

export const defaultUserSettings: UserSettings = {
  teacherContentEnabled: false,
  fontSize: 1,
};
