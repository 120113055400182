import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { NgxBonsaiModule } from '@mhe/ngx-bonsai-reader';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TopicsActiveNodeDirective } from './topics-active-node.directive';
import { TopicsComponent } from './topics.component';
import { AlertModule, ButtonModule } from '@mhe/ngx-shared';

@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    TranslateModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
    MatProgressSpinnerModule,
    ButtonModule,
  ],
  declarations: [TopicsComponent, TopicsActiveNodeDirective],
  exports: [TopicsComponent],
})
export class TopicsModule {}
