import { createAction, props } from '@ngrx/store';

import {
  Book,
  DoubleSpineItem,
  EpubMoveArrowStates,
  EpubMoveDirection,
  SearchResult,
  SpineItem,
  DocTransform,
} from '@mhe/reader/models';

/** render stages */
export const renderSinglePane = createAction(
  'Render Single Spine Item',
  props<{
    book: Book
    spineItem: SpineItem
    hash?: string
    setFocus?: boolean
  }>(),
);
export const renderDoublePane = createAction(
  'Render Double Spine Items',
  props<{ book: Book, doubleSpineItem: DoubleSpineItem }>(),
);
export const render = createAction(
  'Render Spine Item',
  props<{
    book: Book
    spineItem: SpineItem
    iframe: HTMLIFrameElement
    hash?: string
  }>(),
);
export const preRenderComplete = createAction(
  'iFrame Pre-Render Complete',
  props<{ document: HTMLDocument }>(),
);
export const renderComplete = createAction(
  'iFrame Post-Render Complete',
  props<{ dt: DocTransform }>(),
);

export const renderError = createAction('iFrame Render Error');

/** font-size */
export const applyFontSize = createAction(
  'Set FontSize on Document',
  props<{ fontsize: number }>(),
);

/** search */
export const highlightSearchResult = createAction(
  'Highlight selected search result',
  props<{ result: SearchResult }>(),
);
export const highlightSearchResultReset = createAction(
  'Reset search result highlight',
);

/** zoom */
export const moveZoomedEpub = createAction(
  'Move Zoomed Epub Position',
  props<{ direction: EpubMoveDirection }>(),
);

export const moveZoomedEpubComplete = createAction(
  'Move Zoomed Epub Position Complete',
  props<EpubMoveArrowStates>(),
);

export type EpubViewerActions = ReturnType<
// render stages
| typeof renderSinglePane
| typeof renderDoublePane
| typeof render
| typeof preRenderComplete
| typeof renderComplete
| typeof renderError
// miscl.
| typeof applyFontSize
| typeof highlightSearchResult
| typeof highlightSearchResultReset
| typeof moveZoomedEpub
| typeof moveZoomedEpubComplete
>;
