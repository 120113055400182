export interface NavigationState {
  index: number
  previousChapterLabel: string
  nextChapterLabel: string
  maxIndex: number
  spineHashMap: Record<number, number>
  backToAssignmentButton: boolean
  rangeStartIndex: number | undefined
  rangeEndIndex: number | undefined
  isLastPage: boolean
}

export const initialNavigationState: NavigationState = {
  index: 0,
  previousChapterLabel: '',
  nextChapterLabel: '',
  maxIndex: 0,
  spineHashMap: {},
  backToAssignmentButton: false,
  rangeStartIndex: undefined,
  rangeEndIndex: undefined,
  isLastPage: false,
};
