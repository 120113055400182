import { EpubMoveArrowStates } from '@mhe/reader/models';

export interface EpubViewerState {
  readonly cloIframe: HTMLIFrameElement | undefined
  readonly leftIframe: HTMLIFrameElement | undefined
  readonly rightIframe: HTMLIFrameElement | undefined
  readonly isDoubleSpread: boolean | undefined
  readonly albumMode: boolean

  /**
   * The user requested album mode.
   * When the window narrows, album mode needs
   * to be forced, but we still need to remember
   * what the user requested so we can apply it
   * when the window widens.
   */
  readonly albumModeUserReq: boolean

  readonly zoomLevel: number
  readonly directionArrows: EpubMoveArrowStates
  readonly isFplSpine: boolean
  readonly documentRequestError: boolean
}

export const initialEpubViewerState: EpubViewerState = {
  cloIframe: undefined,
  leftIframe: undefined,
  rightIframe: undefined,
  isDoubleSpread: undefined,
  albumMode: false,
  albumModeUserReq: false,
  zoomLevel: 1,
  directionArrows: {
    upEnabled: true,
    downEnabled: true,
    leftEnabled: true,
    rightEnabled: true,
  },
  isFplSpine: false,
  documentRequestError: false,
};
