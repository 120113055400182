import { createAction, props } from '@ngrx/store';

import { DoubleSpineItem, SpineItem } from '@mhe/reader/models';

export const init = createAction(
  'Init Navigation',
  props<{
    linearSpine: SpineItem[] | undefined
    doubleSpine: DoubleSpineItem[] | undefined
  }>(),
);

// Hooks
export const afterNavigation = createAction('After navigation');

// Single Pane
export const forceRefreshSpineItem = createAction(
  'Force Refresh of Single Spine Item',
);
export const navigateTo = createAction(
  'Navigate To Linear Index',
  props<{ index: number, hash?: string, setFocus?: boolean }>(),
);
export const navigateByStep = createAction(
  'Navigate By Step',
  props<{ step: number }>(),
);
export const navigateBySpineIndex = createAction(
  'Navigate By Spine Index',
  props<{ index: number, hash?: string }>(),
);
export const navigateBySpineUrl = createAction(
  'Navigate By Spine Url',
  props<{ url: string, hash?: string }>(),
);
export const navigateByCfi = createAction(
  'Navigate by CFI',
  props<{ cfi: string, setFocus: boolean }>(),
);
export const navigateBySpineId = createAction(
  'Navigate By Spine Id',
  props<{ id: string, hash?: string }>(),
);

// double Pane
export const navigateDoubleSpreadTo = createAction(
  'Navigate To DoubleSpread Index',
  props<{ index: number, setFocus?: boolean }>(),
);

export type NavigationActions = ReturnType<
| typeof init
| typeof afterNavigation
| typeof navigateBySpineId
| typeof navigateBySpineIndex
| typeof navigateBySpineUrl
| typeof navigateByStep
| typeof navigateTo
| typeof navigateDoubleSpreadTo
| typeof navigateByCfi
| typeof forceRefreshSpineItem
>;
