import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconRegistryService } from './icon-registry.service';

@NgModule({
  imports: [MatIconModule],
  providers: [IconRegistryService],
  exports: [MatIconModule],
})
export class IconRegistryModule {}
