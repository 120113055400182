import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonPurpose } from '@mhe/ngx-shared';

import { AllowedInterfaceMode, AllowedNavigationType } from '../types';

@Component({
  selector: 'reader-core-forward-button',
  templateUrl: './forward-button.component.html',
  styleUrls: ['./forward-button.component.scss'],
})
export class ForwardButtonComponent {
  @Input() navigationType: AllowedNavigationType = 'paginate';
  @Input() disabled = false;
  @Input() interfaceMode: AllowedInterfaceMode | null = null;
  @Input() chapterLabel: string;

  @Output() forward = new EventEmitter();

  // expose import ref
  ButtonPurpose = ButtonPurpose;

  internalForward(): void {
    this.forward.emit();
  }
}
