import { Injectable } from '@angular/core';
import { ParsedCfi } from '../annotation.model';
import { asyncScheduler } from 'rxjs';

/**
 * This service wraps around the epubAnnotation.cfi sub library
 * https://github.mheducation.com/MHEducation/DLE-Player-Annotations
 */

// v5 for highlights created by users, v6 for highlights created by readiator
type CFILibVersion = 'v5' | 'v6';

@Injectable()
export class EpubLibCFIService {
  /**
   * The CFI library from the epubAnnotation GLOBAL
   */
  protected cfiLib;
  protected epubAnnotation;
  // TODO: Remove this property, getter, setter and all the instances of `const v = ...`
  //   There's not a great reason for version to be stateful.  We should either unify to one library and remove version all together here
  //   or finish the refactor I've started here (supply the version to functions, maybe default to a version in the params list)
  protected cfiLibraryVersion?: CFILibVersion = 'v5'; // v5 for highlights created by users, v6 for highlights created by readiator

  constructor(window: Window) {
    // check if ePubAnnotation global exists
    if ((window as any).ePubAnnotation === undefined) {
      console.error('ePubAnnotation library does not exist');
      // @todo how to handle this case?
    } else {
      this.epubAnnotation = (window as any).ePubAnnotation;
      this.cfiLib = (window as any).ePubAnnotation.cfi;
    }
  }

  public getSpineId(cfi: string): string {
    const regex = /epubcfi\(\/(\d+)\/(\d+)\[(.*)\]!/;
    const match = regex.exec(cfi);

    if (!match) {
      return '';
    } else {
      return match[3];
    }
  }

  public getBasePath(cfi: string, version?: CFILibVersion): string {
    const v = version ?? this.getCfiLibraryVersion();
    const regex = /epubcfi\(\/(\d+)\/(\d+)\[(.*)\]!/;
    const match = regex.exec(cfi) as RegExpExecArray;

    return this.cfiLib
      .getVersion(v)
      .cfiStringFromSpineIndex(match[2], match[3]);
  }

  public generateBasePath(
    spineIndex: number,
    spineId: string,
    version?: CFILibVersion,
  ): string {
    const v = version ?? this.getCfiLibraryVersion();
    // @todo get the actual 0-based index of the spine in the manifest... hard code to 2 (maps to 6) for now
    return this.cfiLib.getVersion(v).generateBasePath(2, spineIndex, spineId);
  }

  public getCfiLibraryVersion(): CFILibVersion | undefined {
    return this.cfiLibraryVersion;
  }

  public setCfiLibraryVersion(version): void {
    this.cfiLibraryVersion = version;
  }

  public parseCFI(
    cfi: string,
    version?: CFILibVersion,
    setFocus?: boolean,
  ): ParsedCfi {
    const v = version ?? this.getCfiLibraryVersion();
    return {
      spineIndex: this.cfiLib.getVersion(v).spineIndexFromCFIString(cfi),
      spineId: this.cfiLib.getVersion(v).spineIdFromCFIString?.(cfi),
      elementId: this.cfiLib.getVersion(v).lastParentIdFromCFIString(cfi),
      setFocus: setFocus ?? false,
    };
  }

  /**
   * Returns back the text in the document associated with a given CFI boundaries [ ----- ]
   */
  public getTextFromCFI(
    cfi: string,
    doc: Document,
    version?: CFILibVersion,
  ): string {
    const v = version ?? this.getCfiLibraryVersion();
    const range = this.getRangeFromCFI(cfi, doc, v);
    return this.getTextFromRange(range, doc);
  }

  /**
   * Returns back a Range DOM object corresponding to CFI boundaries [ ---- ]
   */
  public getRangeFromCFI(
    cfi: string,
    doc: Document,
    version?: CFILibVersion,
  ): Range {
    const v = version ?? this.getCfiLibraryVersion();
    return this.cfiLib.getVersion(v).rangeFromCFIString(cfi, doc);
  }

  public getCfiFromNode(node: HTMLElement, base: string): string {
    return this.cfiLib
      .getVersion(this.getCfiLibraryVersion())
      .cfiStringFromNode(node, base);
  }

  /**
   * Returns back the string corresponding to a given Range DOM object
   */
  public getTextFromRange(range: Range, doc: Document): string {
    return this.epubAnnotation.textForRange(range, doc);
  }

  public setFocusOnFirstElementInCfi(
    cfi: string,
    doc: Document,
    version?: CFILibVersion,
  ): void {
    const v = version ?? this.getCfiLibraryVersion();
    const targetElement = this.getFirstElementInCFI(cfi, doc, v);
    if (targetElement) {
      if (!targetElement.hasAttribute('tabindex')) {
        targetElement.setAttribute('tabindex', '-1');
        // schedule removing the tabindex
        asyncScheduler.schedule(() => {
          targetElement.removeAttribute('tabindex');
        }, 10000);
      }
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      asyncScheduler.schedule(() => targetElement.focus(), 300);
    }
  }

  public getFirstElementInCFI(
    cfi: string,
    doc: Document,
    version?: CFILibVersion,
  ): HTMLElement | null {
    const v = version ?? this.getCfiLibraryVersion();
    const nearestIDFromCFI = this.cfiLib
      .getVersion(v)
      .lastParentIdFromCFIString(cfi);

    if (nearestIDFromCFI) {
      return doc.getElementById(nearestIDFromCFI);
    } else {
      const targetElement = doc.body?.firstElementChild;
      if (targetElement && targetElement.id === 'MathJax_Message') {
        return this.getFirstNonEmptyElement(targetElement) as HTMLElement;
      }
    }

    return null;
  }

  protected getFirstNonEmptyElement(element): HTMLElement | undefined {
    if (!element) {
      return;
    }
    while (element.nextSibling) {
      if (
        element.nextSibling.nodeType !== 1 ||
        element.nextSibling.textContent.replace(/^\s+|\s+$/g, '') === ''
      ) {
        element = element.nextSibling;
      } else {
        return element.nextSibling;
      }
    }
  }
}
