import { createAction, props } from '@ngrx/store';
import { LastLocation } from './models';

export const init = createAction('[LastLocation] Init');

export const searchLastLocation = createAction('[LastLocation] Search for last location data');
export const saveLastLocation = createAction(
  '[LastLocation] Save last location data',
  props<{ location: LastLocation }>(),
);
export const saveLastLocationSuccess = createAction('[LastLocation] Save last location - success');
export const saveLastLocationError = createAction('[LastLocation] Save last location - error');

export type LastLocationActions = ReturnType<
| typeof init
| typeof searchLastLocation
| typeof saveLastLocation
| typeof saveLastLocationSuccess
| typeof saveLastLocationError
>;
