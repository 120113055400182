import { createAction } from '@ngrx/store';

// TODO: Move other toolbar actions here & do the same concept for epub-controls
export const navigationHistoryBack = createAction('Navigation History Back');

/** overflow menu */
// annotations
export const confirmDeletePageAnnotations = createAction(
  '[RDRX Annotations] Confirm delete page annotations',
);
export const confirmDeleteAllAnnotations = createAction(
  '[RDRX Annotations] Confirm delete all annotations',
);
export const deletePageAnnotations = createAction(
  '[RDRX Annotations] Delete page annotations',
);
export const deleteAllAnnotations = createAction(
  '[RDRX Annotations] Delete all annotations',
);

export const customButtonClicked = createAction(
  '[RDRX Toolbar] Custom Button Clicked',
);

export type ToolbarActions = ReturnType<
| typeof navigationHistoryBack
| typeof confirmDeletePageAnnotations
| typeof confirmDeleteAllAnnotations
| typeof deletePageAnnotations
| typeof deleteAllAnnotations
| typeof customButtonClicked
>;
