import { A11yModule, LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule, MatSliderThumb } from '@angular/material/slider';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { FontResizerStore } from './state';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'reader-core-font-resizer-dialog',
  templateUrl: 'font-resizer-dialog.component.html',
  styleUrls: ['./font-resizer-dialog.component.scss'],
  standalone: true,
  imports: [
    // angular
    A11yModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // material
    MatDialogModule,
    MatSliderModule,
    TranslateModule,
  ],
  providers: [FontResizerStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontResizerDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ngSliderThumb') ngSliderThumb!: MatSliderThumb;

  readonly sliderControl = new FormControl(0);
  readonly max = 5;
  readonly min = -5;

  readonly val$ = this.sliderControl.valueChanges.pipe(
    map((val) => Number(val)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly increaseDisabled$ = this.val$.pipe(map((val) => val >= this.max));
  readonly decreaseDisabled$ = this.val$.pipe(map((val) => val <= this.min));

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { store: FontResizerStore },
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translate: TranslateService,
    private readonly ga: GoogleAnalyticsService,
  ) { }

  ngOnInit(): void {
    // when a user makes changes:
    //   live change the font size in the ui
    //   persist their setting in the API for recall later
    this.val$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.data.store.valueChange$(val);
    });

    // get the current value from the store to set
    // the slider position on dialog opening
    this.data.store.initialSliderValue$.subscribe((value) => {
      if (value !== null) {
        this.sliderControl.setValue(Number(value));
      } else {
        this.sliderControl.setValue(0);
      }
    });

    this.analyticsEmitChanges();
  }

  ngAfterViewInit(): void {
    // using CDK focus helpers resulted in a strange CSS quirk
    // when the slider thumb was focused ¯\_(ツ)_/¯
    // this is a workaround; Angular 18 does not have the quirk
    setTimeout(() => this.ngSliderThumb.focus(), 100);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fontsizeValueChanged(event): void {
    this.sliderControl.setValue(event.value);
  }

  increase(): void {
    let val = this.sliderControl.value as number;
    val += 1;

    this.sliderControl.setValue(val);
    this.announceValueChange(val);
  }

  decrease(): void {
    let val = this.sliderControl.value as number;
    val -= 1;

    this.sliderControl.setValue(val);
    this.announceValueChange(val);
  }

  /** a11y */
  private announceValueChange(val: any): void {
    const currentVal = this.translate.instant('font-resizer.current_value', {
      val,
    });
    void this.liveAnnouncer.announce(currentVal);
  }

  /** analytics */
  private analyticsEmitChanges(): void {
    const valHistory$ = this.val$.pipe(
      startWith(false),
      pairwise(),
      filter(([p, _c]) => Boolean(p)),
    );

    const eventAction$ = valHistory$.pipe(
      map(([p, c]) => ({
        eventAction: p < c ? 'Increase' : 'Decrease',
        eventValue: Number(c),
      })),
    );

    eventAction$.pipe(takeUntil(this.destroy$)).subscribe((eventParams) => {
      this.ga.event({ eventCategory: 'Font Resizer', ...eventParams });
    });
  }
}
