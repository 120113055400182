import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of, forkJoin, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { MediatorUtils } from './mediator-utils';
import { WidgetData } from '@mhe/reader/models';
import { ReaderStore } from '@mhe/reader/components/reader/state';
import { WidgetsApiService } from '@mhe/reader/core/reader-api/widgets-api.service';
import {
  SessionDisabledError,
  SessionEndedError,
} from '@mhe/reader/core/utils/reduced-mode-utils';
import { FloatingTtsControlsService } from '@mhe/reader/components/text-to-speech';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import {
  ConfirmationModalComponent,
  ConfirmationModalData,
} from '@mhe/reader/components/modals/confirmation-modal';
import {
  getAllWidgetInstanceIDs,
  getWidgetsStateByWidgetUuid,
} from '@mhe/reader/global-store/widgets';
import * as widgetsActions from '@mhe/reader/global-store/widgets/widgets.actions';

@Injectable()
export class WidgetsMediator extends ComponentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly readerStore: ReaderStore,
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly util: MediatorUtils,
    private readonly widgetsApiService: WidgetsApiService,
    private readonly floatingTtsOverlayService: FloatingTtsControlsService,
    private readonly navigationStore: NavigationStore,
  ) {
    super();
  }

  private readonly _searchWidgetData$ = this.effect(() =>
    this.actions$.pipe(
      ofType(widgetsActions.widgetDataSearch),
      withLatestFrom(this.util.readerApi$, this.util.requestContext$),
      mergeMap(([{ widgetUuid }, api, userdata]) => {
        // TODO remove these to allow config values to pass through
        // userdata.platform = 'testing';
        // userdata.contextID = 'testing';

        // flag that the request has started (false means requested, but not finished)
        this.store.dispatch(
          widgetsActions.widgetInstanceSetWidgetSearch({
            widgetUuid,
            isRequested: false,
          }),
        );

        // make the api request for an possible existing data
        return this.widgetsApiService
          .searchWidgetData(api, { ...userdata, instanceID: widgetUuid })
          .pipe(
            catchError(() => {
              // when session is disabled or session ends, we still want to complete
              // the search so that the widget iframe loads
              return of(undefined);
            }),
            map((widget) => widget?.[0]),
            tap((widget) => {
              if (widget) {
                // data was returned, update the widget instance
                this.store.dispatch(
                  widgetsActions.widgetInstanceUpdateWidgetData({
                    widgetUuid,
                    widgetData: widget,
                  }),
                );
              }

              // flag that the request has completed (false means requested, but not finished)
              this.store.dispatch(
                widgetsActions.widgetInstanceSetWidgetSearch({
                  widgetUuid,
                  isRequested: true,
                }),
              );
            }),
            logCatchError('_searchWidgetData$'),
          );
      }),
    ),
  );

  private readonly _saveWidgetData$ = this.effect(() =>
    this.actions$.pipe(
      ofType(widgetsActions.widgetDataSave),

      this.util.filterAssignmentReview,

      // not more often than half a second
      debounceTime(500),

      // select the associated widget instance
      mergeMap(({ widgetUuid, dataStringified }) =>
        this.store.pipe(
          select(getWidgetsStateByWidgetUuid, { widgetUuid }),
          take(1),
          map((widgetInstance) => ({
            widgetUuid,
            dataStringified,
            widgetInstance,
          })),
        ),
      ),

      // only when there is a widget instance
      filter(({ widgetUuid, dataStringified, widgetInstance }) =>
        Boolean(widgetInstance),
      ),

      // include api and user data
      withLatestFrom(
        this.util.readerApi$,
        this.util.requestContext$,
        this.util.sessionDisabledConfig$,
      ),

      // do a back end save
      switchMap(
        ([
          { widgetUuid, dataStringified, widgetInstance },
          api,
          userData,
          sessionDisabled,
        ]) => {
          const dataToSave: WidgetData = {
            ...widgetInstance.widgetData,
            ...userData,
          };

          // TODO remove these to allow config values to pass through
          // dataToSave.contextID = 'testing';
          // dataToSave.platform = 'testing';

          // apply the new data
          dataToSave.data = dataStringified;

          // update the timestamp
          const modifiedNow = new Date();
          dataToSave.clientModifiedAt = modifiedNow.toISOString();

          if (!sessionDisabled) {
            // flag to display UI saving chip
            this.store.dispatch(
              widgetsActions.widgetInstanceIsSaving({
                widgetUuid,
                isSaving: 'saving',
              }),
            );
          }

          // make the api request to save the data externally
          return this.widgetsApiService
            .upsertWidgetData(api, { ...dataToSave })
            .pipe(
              catchError(() => {
                // if session is not disabled, show the not-saved UI chip
                if (!sessionDisabled) {
                  this.store.dispatch(
                    widgetsActions.widgetInstanceIsSaving({
                      widgetUuid,
                      isSaving: 'not-saved',
                    }),
                  );
                }

                return EMPTY;
              }),
              tap((widgetDataResponse) => {
                // udpate widget data in the store
                this.store.dispatch(
                  widgetsActions.widgetInstanceUpdateWidgetData({
                    widgetUuid,
                    widgetData: widgetDataResponse,
                  }),
                );

                if (!sessionDisabled) {
                  // flag to dismiss UI saving chip
                  this.store.dispatch(
                    widgetsActions.widgetInstanceIsSaving({
                      widgetUuid,
                      isSaving: 'saved',
                    }),
                  );
                }
              }),
              logCatchError('_saveWidgetData$'),
            );
        },
      ),
    ),
  );

  private confirmationDialog$(
    action,
  ): MatDialogRef<ConfirmationModalComponent, any> {
    const title = this.translate.instant('reset_activities.title');

    let content = this.translate.instant('reset_activities.message_all');

    // change text based on scope value
    if (action.scope === 'exhibit') {
      content = this.translate.instant('reset_activities.message_exhibit');
    }

    const closeText = this.translate.instant('shared.cancel');
    const confirmText = this.translate.instant('shared.ok');

    const data: ConfirmationModalData = {
      title,
      content,
      closeText,
      confirmText,
    };
    return this.dialog.open(ConfirmationModalComponent, {
      data,
      ariaLabelledBy: 'confirmation-modal-h2',
    });
  }

  private readonly _confirmDeleteWidgetData$ = this.effect(() => {
    let scope: 'exhibit' | 'all';
    return this.actions$.pipe(
      ofType(widgetsActions.resetActivitiesConfirm),
      exhaustMap((action) => {
        scope = action.scope;
        return this.confirmationDialog$(action).afterClosed();
      }),
      filter((rsp) => Boolean(rsp)),
      tap(() => this.store.dispatch(widgetsActions.resetActivities({ scope }))),
      logCatchError('_confirmDeleteWidgetData$'),
    );
  });

  // TODO UX for when session is disabled?
  private readonly _deleteWidgetData$ = this.effect(() =>
    this.actions$.pipe(
      ofType(widgetsActions.resetActivities),
      withLatestFrom(this.util.readerApi$, this.util.requestContext$),
      mergeMap(([{ scope }, api, userdata]) => {
        // ga event args
        const eventCategory = 'Overflow Menu';
        let eventAction: string;

        switch (scope) {
          case 'exhibit':
            eventAction = 'Reset Page Activities';

            // need to call the delete by instanceID for each widget currently in the store
            return this.store.pipe(
              select(getAllWidgetInstanceIDs),
              take(1),
              mergeMap((allWidgets) => {
                if (allWidgets.length === 0) {
                  return of(1);
                }
                return forkJoin(
                  allWidgets.map((widget) =>
                    this.widgetsApiService.deleteWidgetData(api, {
                      ...userdata,
                      instanceID: widget,
                    }),
                  ),
                );
              }),
              this.util.tapGaEvent({ eventCategory, eventAction }),
              tap(() =>
                this.navigationStore.dispatch(
                  navigationActions.forceRefreshSpineItem(),
                ),
              ),
              catchError(() => {
                this.readerStore.addAlert({
                  alertType: 'alert-warning',
                  translateKey: 'reset_activities.delete_error',
                });
                return EMPTY;
              }),
              logCatchError('_deleteWidgetData$'),
            );

          case 'all':
            eventAction = 'Reset All Activities';

            // just call a single delete api endpiont for everything, does not matter what is in the store
            return this.widgetsApiService
              .deleteWidgetData(api, { ...userdata })
              .pipe(
                catchError((e) =>
                  e instanceof SessionDisabledError ||
                  e instanceof SessionEndedError
                    ? of(1)
                    : throwError(e),
                ),
                this.util.tapGaEvent({ eventCategory, eventAction }),
                tap(() =>
                  this.navigationStore.dispatch(
                    navigationActions.forceRefreshSpineItem(),
                  ),
                ),
                catchError(() => {
                  this.readerStore.addAlert({
                    alertType: 'alert-warning',
                    translateKey: 'reset_activities.delete_error',
                  });
                  return EMPTY;
                }),
                logCatchError('_deleteWidgetData$'),
              );

          default:
          // Unexpected scope provided for resetting activities
        }

        return EMPTY;
      }),
    ),
  );

  private readonly _resetWidgetIframes$ = this.effect(() =>
    this.actions$.pipe(
      ofType(widgetsActions.resetIframes),
      withLatestFrom(this.store.pipe(select(getAllWidgetInstanceIDs))),
      tap((action: any) => {
        action[1].forEach((instanceID) => {
          this.store.dispatch(
            // with each widget in the store, flag that it needs a reset
            // the WidgetManager will detect this and handle the iframe reset
            widgetsActions.widgetInstanceResetIframe({
              widgetUuid: instanceID,
              widgetIframeReset: 'pending',
            }),
          );
        });
      }),
    ),
  );

  private readonly _textToSpeechRequest$ = this.effect(() =>
    this.actions$.pipe(
      ofType(widgetsActions.widgetTextToSpeech),
      exhaustMap((action) => {
        return this.floatingTtsOverlayService.openContextReadspeaker(
          false,
          action.ePubIframe,
          action.textToSpeechRequest,
        );
      }),
      logCatchError('_textToSpeechRequest$'),
    ),
  );
}
