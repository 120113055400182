import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONFIG_FEATURE_KEY, ConfigState } from './config.state';

const configState = createFeatureSelector<ConfigState>(CONFIG_FEATURE_KEY);

export const getEpubs = createSelector(configState, ({ epub }) => epub);
export const getEpubUrlById = createSelector(
  getEpubs,
  (epubs: Record<string, string>, { id }: { id: string }) => epubs[id],
);
