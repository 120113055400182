import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EPUB_STATE_KEY, EpubEffects, epubReducer } from './epub';
import { READER_STATE_KEY } from './global-store.selectors';
import { FootnoteEffects, footnoteReducer, FOOTNOTE_STATE_KEY } from './footnote';
import { GlossaryEffects, glossaryReducer, GLOSSARY_STATE_KEY } from './glossary';
import { annotationsReducer, ANNOTATIONS_STATE_KEY } from './annotations';
import { widgetsReducer, WIDGETS_STATE_KEY } from './widgets';

import { ERRORS_STATE_KEY } from './errors/errors.state';
import { errorsReducer } from './errors/errors.reducer';
@NgModule({
  imports: [
    StoreModule.forFeature(READER_STATE_KEY, {
      [EPUB_STATE_KEY]: epubReducer,
      [FOOTNOTE_STATE_KEY]: footnoteReducer,
      [GLOSSARY_STATE_KEY]: glossaryReducer,
      [ANNOTATIONS_STATE_KEY]: annotationsReducer,
      [WIDGETS_STATE_KEY]: widgetsReducer,
      [ERRORS_STATE_KEY]: errorsReducer,
    }),
    EffectsModule.forFeature([EpubEffects, FootnoteEffects, GlossaryEffects]),
  ],
})
export class GlobalStoreModule {}
