<div
  class="header go-to-active"
  (click)="focusActiveNode($event)"
  (keydown.Enter)="focusActiveNode($event)"
  (keydown.Space)="focusActiveNode($event)"
>
  <h3 style="padding: 0 8px;" translate>contents.toc</h3>
  <a href="javascript:void(0)" data-automation-id="go-to-current-location" class="ahe-ui-link" >
    {{ 'contents.toc_go_current_location' | translate }}
  </a>
</div>

<ngx-bonsai
  class="rdrx"
  rdrxBonsaiTabIndex
  data-automation-id="toc-tree-group"
  [nodesById]="tree$ | async"
  [rootNodeId]="rootNodeId"
  [expandedNodes]="expandedNodes$ | async"
  [showToggleFn]="isCollapsible"
  (expanderClick)="toggleNodeExpanded($event)"
>
  <ng-template #treeNodeTemplate let-node>
    <a
      attr.aria-haspopup
      mheStopPropagation
      rdrxBonsaiNodeSelect
      href="javascript:void(0)"
      class="rdrx-node-content ahe-ui-link"
      data-automation-id="toc-tree-children"
      tabindex="-1"
      [rdrxTocActiveNode]="node.id === (activeId$ | async)"
      [rdrxBonsaiNodeSelectKeyboard]="!node.collapsible"
      (click)="handleNodeClick(node)"
    >
      {{ node.title }}
    </a>
  </ng-template>
</ngx-bonsai>
