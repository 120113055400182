/* eslint-disable max-len */
import { Injectable, Renderer2 } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { asyncScheduler, Observable } from 'rxjs';

import {
  ComponentEffects,
  DevicePlatform,
  DeviceService,
} from '@mhe/reader/common';
import { DocTransform } from '@mhe/reader/models';

import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { MediatorUtils } from './mediator-utils';
import { ReadiatorBridgeService } from '@mhe/reader/features/readiator-bridge/readiator-bridge.service';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import {
  EpubLibCFIService,
  HighlighterService,
} from '@mhe/reader/features/annotation';

@Injectable()
export class AuthoringMediator extends ComponentEffects {
  private readonly transformActions$ = this.transformStore.actions$;

  private readonly mathjaxSpanSelector = 'span.MathJax_SVG';
  private readonly widgetFigureSelector =
    'figure.digital, figure.figure-widget:not(.digital)';

  private readonly mediaSelector =
    'figure.video,figure.audio, .mhe-audio--wrapper, .mhe-audio--inline';

  private readonly imageSelector = 'img';

  private unlistenerRegistry: Record<string, Array<() => void>> = {};

  // Utilities
  private readonly filterAuthoring = <T>(
    source$: Observable<T>,
  ): Observable<T> => {
    return source$.pipe(
      withLatestFrom(this.readerConfigStore.authoring$),
      filter(([_source, authoring]) => !!authoring),
      map(([source]) => source),
    );
  };

  constructor(
    private readonly transformStore: TransformStore,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readiatorBridgeService: ReadiatorBridgeService,
    private readonly highlighterService: HighlighterService,
    private readonly epubLibCfiService: EpubLibCFIService,
    private readonly mediatorUtils: MediatorUtils,
    private readonly renderer: Renderer2,
    private readonly win: Window,
    private readonly deviceService: DeviceService,
  ) {
    super();
  }

  private readonly prerender$ = this.effect(() =>
    this.transformStore.actions$.pipe(
      ofType(transformActions.authoringPrerenderStart),
      withLatestFrom(this.readerConfigStore.authoring$),
      tap(([{ dt }, authoring]) => {
        if (authoring) {
          const selectors = [
            this.mathjaxSpanSelector,
            this.widgetFigureSelector,
            this.mediaSelector,
          ].join(', ');
          dt.doc
            .querySelectorAll(selectors)
            .forEach((el) => this.renderer.addClass(el, 'authoring-mode'));
        }

        this.transformStore.dispatch(transformActions.authoringPrerenderDone());
      }),
    ),
  );

  private readonly postrender$ = this.effect(() =>
    this.transformStore.actions$.pipe(
      ofType(transformActions.authoringPostrenderStart),
      withLatestFrom(
        this.readerConfigStore.authoring$,
        this.readerConfigStore.tagging$,
      ),
      tap(([{ dt }, authoring, tagging]) => {
        this.unlisten(dt.iframe.id);
        if (authoring && !tagging) {
          // Mathjax generates the span/svg after a bit
          asyncScheduler.schedule(() => this.mathJaxClicks(dt), 500);
          this.imageClicks(dt);
          this.widgetClicks(dt);
          this.mediaClicks(dt);
        }
        this.transformStore.dispatch(
          transformActions.authoringPostrenderDone(),
        );
      }),
    ),
  );

  private readonly authoringSelection$ = this.effect(() =>
    this.transformActions$.pipe(
      ofType(transformActions.rangeSelected),
      this.filterAuthoring,
      this.mediatorUtils.withRelevantSpineItemAndIFrameFromSelection,
      tap(
        ([
          {
            details: { nonTextFlag = false, type },
          },
          iframe,
          spineItem,
        ]) => {
          const doc = iframe.contentDocument;
          const baseCFI = this.epubLibCfiService.generateBasePath(
            spineItem.index,
            spineItem.id,
          );
          let annotation = this.highlighterService.createFromSelection(
            doc,
            baseCFI,
            'v6',
            nonTextFlag,
          );
          if (!type) {
            type = 'text';
          }
          if (!annotation) {
            annotation = this.highlighterService.createFromSelection(
              doc,
              baseCFI,
              'v6',
              true,
            );
          }
          if (annotation) {
            this.readiatorBridgeService.rangeSelect(annotation, type);
          }
        },
      ),
    ),
  );

  private mathJaxClicks(dt: DocTransform): void {
    dt.doc
      .querySelectorAll(this.mathjaxSpanSelector)
      .forEach((span: HTMLElement) => {
        const mathjaxClick = (event: Event): boolean => {
          // event.stopPropagation();

          const svg = span.querySelector<HTMLElement>('svg') as HTMLElement;
          const clickable = !svg.classList.contains('not-clickable');

          if (clickable) {
            this.renderer.addClass(span, 'pre-selected-math');
          }

          this.selectTarget(dt, svg, 'math');

          // The selectionchange event occurs after listening so push the setup of the listener to the end of the callstack
          asyncScheduler.schedule(() => {
            const selectionChangeUnlistener = this.renderer.listen(
              dt.doc,
              'selectionchange',
              () => {
                this.renderer.removeClass(span, 'pre-selected-math');
                selectionChangeUnlistener();
              },
            );
          });

          return false;
        };

        this.unlistenerRegistry[dt.iframe.id].push(
          this.renderer.listen(span, 'click', mathjaxClick),
        );
      });
  }

  private mediaClicks(dt: DocTransform): void {
    dt.doc
      .querySelectorAll<HTMLElement>(this.mediaSelector)
      .forEach((mediaFigure) => {
        const mediaClick = (event: Event): boolean => {
          event.stopPropagation();

          const clickable = !mediaFigure.classList.contains('not-clickable');

          if (clickable) {
            this.renderer.addClass(mediaFigure, 'pre-selected-media');
          }

          this.selectTarget(dt, mediaFigure, 'media');

          // The selectionchange event occurs after listening so push the setup of the listener to the end of the callstack
          asyncScheduler.schedule(() => {
            const selectionChangeUnlistener = this.renderer.listen(
              dt.doc,
              'selectionchange',
              () => {
                this.renderer.removeClass(mediaFigure, 'pre-selected-media');
                selectionChangeUnlistener();
              },
            );
          });

          return false;
        };

        this.unlistenerRegistry[dt.iframe.id].push(
          this.renderer.listen(mediaFigure, 'click', mediaClick),
        );
      });
  }

  private widgetClicks(dt: DocTransform): void {
    dt.doc
      .querySelectorAll<HTMLElement>(this.widgetFigureSelector)
      .forEach((widgetFigure) => {
        const widgetClick = (event: Event): boolean => {
          event.stopPropagation();

          const clickable = !widgetFigure.classList.contains('not-clickable');

          if (clickable) {
            this.renderer.addClass(widgetFigure, 'pre-selected-widget');
          }

          this.selectTarget(dt, widgetFigure, 'widget');

          // The selectionchange event occurs after listening so push the setup of the listener to the end of the callstack
          asyncScheduler.schedule(() => {
            const selectionChangeUnlistener = this.renderer.listen(
              dt.doc,
              'selectionchange',
              () => {
                this.renderer.removeClass(widgetFigure, 'pre-selected-widget');
                selectionChangeUnlistener();
              },
            );
          });

          return false;
        };

        this.unlistenerRegistry[dt.iframe.id].push(
          this.renderer.listen(widgetFigure, 'click', widgetClick),
        );
      });
  }

  private imageClicks(dt: DocTransform): void {
    dt.doc
      .querySelectorAll(this.imageSelector)
      .forEach((image: HTMLImageElement) => {
        const imageClick = (event: Event): boolean => {
          event.stopPropagation();

          const clickable = !image.classList.contains('not-clickable');

          if (clickable) {
            this.renderer.addClass(image, 'pre-selected-image');
          }

          this.selectTarget(dt, image.parentElement as HTMLElement, 'image');

          // The selectionchange event occurs after listening so push the setup of the listener to the end of the callstack
          asyncScheduler.schedule(() => {
            const selectionChangeUnlistener = this.renderer.listen(
              dt.doc,
              'selectionchange',
              () => {
                this.renderer.removeClass(image, 'pre-selected-image');
                selectionChangeUnlistener();
              },
            );
          });

          return false;
        };

        this.unlistenerRegistry[dt.iframe.id].push(
          this.renderer.listen(image, 'click', imageClick),
        );
      });
  }

  private selectTarget(
    { doc }: DocTransform,
    target: HTMLElement,
    type: 'image' | 'math' | 'media' | 'widget',
  ): void {
    const range = document.createRange();
    range.selectNode(target);
    range.setStart(target, 0);
    range.setEnd(target, 1);

    const selection = doc.getSelection() as Selection;
    selection.removeAllRanges();
    selection.addRange(range);

    const devicePlatform = this.deviceService.platformType;
    const eventType =
      devicePlatform !== DevicePlatform.Desktop ? 'selectionchange' : 'mouseup';
    const event = new CustomEvent(eventType, {
      detail: { nonTextFlag: true, type },
    });
    doc.dispatchEvent(event);
  }

  private unlisten(iframeId): void {
    const unlisteners = this.unlistenerRegistry?.[iframeId] || [];
    unlisteners.forEach((ul) => ul());
    this.unlistenerRegistry[iframeId] = [];
  }
}
