import { Component, ViewChild, ElementRef, signal, OnDestroy } from '@angular/core';
import { AlertType, ButtonPurpose } from '@mhe/ngx-shared';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as errorsQuery from '@mhe/reader/global-store/errors/errors.selectors';
import { setAnnotationAriaLabel } from '../utils/set-annotation-aria-label';
import { BonsaiTreeComponent } from '@mhe/ngx-bonsai-reader';

import {
  AnnotationListBonsaiNode,
  ApiAnnotation,
  ROOT_BONSAI_NODE,
} from '@mhe/reader/models';
import {
  annotationSelected,
  getAnnotationsForLabel,
  placemarkDelete,
  placemarksBatchDelete,
} from './state/placemark-list.actions';
import { PlacemarkListStore } from './state/placemark-list.store';
import { DropdownState, AnnotationType } from '../annotation.data';
import { BatchDeleteState } from '../utils/annotation-list.state.abstract';

@Component({
  selector: 'rdrx-placemark-list',
  templateUrl: './placemark-list.component.html',
  styleUrls: ['./placemark-list.component.scss'],
})
export class PlacemarkListComponent implements OnDestroy {
  @ViewChild(BonsaiTreeComponent, { read: ElementRef }) tree: ElementRef;
  readonly rootNodeId = ROOT_BONSAI_NODE;
  readonly truncateLimit = 100;
  ButtonPurpose = ButtonPurpose;
  AlertType = AlertType;
  readonly destroy$ = new Subject<boolean>();
  readonly sessionEnded$ = this.store.pipe(select(errorsQuery.hasSessionEnded));
  readonly emptyTree$ = combineLatest([
    this.placemarkListStore.tree$,
    this.sessionEnded$,
  ]).pipe(
    map(
      ([tree, hasSessionEnded]) =>
        !hasSessionEnded && Object.keys(tree).length === 1,
    ), // one because the root node
  );

  readonly userActionBatchDelete$ = this.placemarkListStore.batchDeleteState$.pipe(
    takeUntil(this.destroy$),
    tap(state => {
      if (state === BatchDeleteState.SUCCESS) {
        this.handleCancelClick();
      }
    }),
  ).subscribe();

  nodeTree$ = this.placemarkListStore.tree$.pipe(
    tap(treeData => (this.treeDataCopy = treeData)),
    tap(() => {
      if (this.allAnnotationsSelected) {
        this.placemarksSelected = [];
        this.handleAllAnnotationsSelected(true);
      }
    }),
  );

  treeDataCopy = {};
  placemarkDropDownState = signal<DropdownState>(DropdownState.ALL);
  isEditMode = false;
  placemarksSelected: string[] = [];
  headerType = AnnotationType.PLACEMARK;
  allAnnotationsSelected = false;

  get isFooterBtnDisabled(): boolean {
    return !!this.placemarksSelected.length;
  }

  constructor(
    readonly placemarkListStore: PlacemarkListStore,
    readonly store: Store,
  ) {}

  handleNodeClick(node: AnnotationListBonsaiNode): void {
    if (this.isEditMode) {
      return;
    }
    if (node.annotation) {
      this.placemarkListStore.dispatch(
        annotationSelected({ annotation: node.annotation }),
      );
    } else {
      this.placemarkListStore.dispatch(
        getAnnotationsForLabel({ spineID: node.id }),
      );
      this.placemarkListStore.toggleExpandedNode(node.id);
    }
  }

  delete(annotation: ApiAnnotation): void {
    if (annotation) {
      this.placemarkListStore.dispatch(placemarkDelete({ annotation }));
    }
  }

  toggleNodeExpanded(nodeId: string): void {
    this.placemarkListStore.toggleExpandedNode(nodeId);
    this.placemarkListStore.dispatch(
      getAnnotationsForLabel({ spineID: nodeId }),
    );
  }

  isCollapsible(node: AnnotationListBonsaiNode): boolean {
    return node.collapsible ?? true;
  }

  isLoading(node: AnnotationListBonsaiNode): boolean {
    return node.childIds?.length === 0;
  }

  // Set aria label on bonsai treeitem tag
  handleTextChange(text: string, node: AnnotationListBonsaiNode): void {
    if (node.annotation) {
      setAnnotationAriaLabel(this.tree, text, node);
    }
  }

  isNodeChecked({ id }: AnnotationListBonsaiNode): boolean {
    return !!this.placemarksSelected.find(hlId => hlId === id);
  }

  handleDropdownState(state: DropdownState): void {
    this.placemarkDropDownState.set(state);
  }

  handleEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
  }

  handleAllAnnotationsSelected(isChecked: boolean): void {
    this.allAnnotationsSelected = true;
    this.placemarkDropDownState() === DropdownState.ALL
      ? this.highlightSelectAll(isChecked)
      : this.highlightSelectDetached(isChecked);
  }

  highlightSelectAll(isChecked: boolean): void {
    if (!isChecked) {
      this.placemarksSelected = [];
      return;
    }
    this.placemarksSelected = Object.keys(this.treeDataCopy)
      .map(key => this.treeDataCopy[key].id)
      .filter(Boolean);
  }

  highlightSelectDetached(isChecked: boolean): void {
    if (!isChecked) {
      this.placemarksSelected = [];
      return;
    }
    this.placemarksSelected = Object
      .keys(this.treeDataCopy)
      .filter(key => Boolean(this.treeDataCopy[key].annotation?.orphan))
      .map(key => this.treeDataCopy[key].id);
  }

  handleCancelClick(): void {
    this.isEditMode = false;
    this.allAnnotationsSelected = false;
    this.placemarksSelected = [];
  }

  handleDeleteClick(): void {
    if (this.placemarksSelected.length > 0) {
      this.placemarkListStore.batchDeleteStart();

      this.placemarkListStore.dispatch(
        placemarksBatchDelete({
          data: {
            annotations: this.placemarksSelected
              .map(id => this.treeDataCopy[id].annotation)
              .filter(Boolean),
            type: AnnotationType.PLACEMARK,
          },
        }),
      );
    }
  }

  handleCheckAnnotation({ id, collapsible, childIds }: AnnotationListBonsaiNode): void {
    // Handle Chapter Level
    if (collapsible && childIds) {
      if (this.placemarksSelected.some(annotationId => annotationId === id)) {
        this.placemarksSelected = this.placemarksSelected.filter(hlId => !childIds.includes(hlId));
        this.placemarksSelected = this.placemarksSelected.filter(hlId => hlId !== id);
        return;
      }
      this.placemarksSelected = [
        ...this.placemarksSelected,
        ...childIds.map(childId => this.treeDataCopy[childId].id),
        id,
      ];
      return;
    }
    // Handle individuals
    if (this.placemarksSelected.find(hlId => hlId === id)) {
      this.placemarksSelected = this.placemarksSelected.filter(hlId => hlId !== id);
      return;
    }
    this.placemarksSelected = [
      ...this.placemarksSelected,
      id,
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
