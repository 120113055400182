import { Store } from '@ngrx/store';
import { ConfigService } from '@mhe/reader/config';

import * as readerConfigActions from '@mhe/reader/core/state/configuration/configuration.actions';
import { ReaderEnvironmentConfig } from '../models';

export function loadConfigFactory(
  store: Store,
  configService: ConfigService,
): () => Promise<void> {
  return async() => {
    store.dispatch(
      readerConfigActions.SetPartialEnvironmentConfig(
        (await configService.config$.toPromise()) as ReaderEnvironmentConfig,
      ),
    );
  };
}
