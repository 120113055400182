import { ApiAnnotation, ApiSpine } from '@mhe/reader/models';
import { createAction, props } from '@ngrx/store';

export type Annotations = 'highlight' | 'note' | 'placemark';

// Deprecated (?)
export const loadAnnotations = createAction(
  '[Reader] Load Annotations Bulk',
  props<{ annotations: ApiAnnotation[], spineId?: string }>(),
);

export const addAnnotation = createAction(
  '[Reader] Add Annotation',
  props<{ annotation: ApiAnnotation }>(),
);

export const addAnnotations = createAction(
  '[Reader] Add Annotations Bulk',
  props<{ annotations: ApiAnnotation[], loaded: boolean }>(),
);

export const addAnnotationsBySpineId = createAction(
  '[Reader] Add Annotations By Spine Id',
  props<{ annotations: ApiAnnotation[], spineId: string, loaded: boolean }>(),
);

export const fetchAnnotationBySpineId = createAction(
  '[Reader] Fetch Annotation By Spine Id',
  props<{ spineId: string }>(),
);

export const fetchAnnotationsAllSpines = createAction(
  '[Reader] Fetch Annotations All Spines',
);

export const fetchAnnotationsForAllSpinesByType = createAction(
  '[Reader] Fetch Annotations All Spines By Type',
  props<{ annotationType: Annotations }>(),
);

export const fetchDistinctSpineIds = createAction(
  '[Reader] Fetch Distinct Spine Ids',
);

export const fetchDistinctSpineIdsSuccess = createAction(
  '[Reader] Fetch Distinct Spine Ids Success',
  props<{ spines: ApiSpine[] }>(),
);

export const removeAnnotation = createAction(
  '[Reader] Remove Annotation',
  props<{ annotation: ApiAnnotation }>(),
);

export const removeBatchAnnotations = createAction(
  '[Reader] Remove Batch Annotations',
  props<{ annotations: ApiAnnotation[] }>(),
);

export const removeHighlight = createAction(
  '[Reader] Remove Highlight',
  props<{ id: string }>(),
);

export const removeNote = createAction(
  '[Reader] Remove Note',
  props<{ id: string }>(),
);

export const clearAllAnnotations = createAction(
  '[Reader] Clear all annotations',
);

export const updateSpinesStatus = createAction(
  '[Reader][Annotations] Update Spines Status',
  props<{ spineId: string }>(),
);

export const setSpineItemsToSpineId = createAction(
  '[Reader][Annotations] Set Spine Items to Spine ID',
  props<{ spineId: string, spineItems: string[] }>(),
);

export const startExport = createAction('[Reader] Start Export');

export const exportSuccess = createAction('[Reader] Export Success');
