import { Injectable } from '@angular/core';
import { Observable, asyncScheduler, forkJoin, from, of } from 'rxjs';
import { map, mergeMap, observeOn, scan, takeWhile } from 'rxjs/operators';

import {
  CSS_LEXILE_BASE,
  CSS_LEXILE_CONTAINER,
  DoubleSpineItem,
  LexileLevel,
  FrameSide,
  SearchParams,
  SearchResult,
  SearchSubject,
} from '@mhe/reader/models';

type SubSearchParams = Omit<SearchParams, 'subjects'>;

@Injectable()
export class SearchService {
  private readonly domp = new DOMParser();

  searchEpubFiles(params: SearchParams): Observable<SearchResult[]> {
    const { subjects, ...subParams } = params;
    const linearSubjects = subjects.filter(({ linear }) => linear === 'yes');
    const searchSubjects$ = from(linearSubjects);

    const searchResults$ = searchSubjects$.pipe(
      observeOn(asyncScheduler),
      mergeMap((subject) => this.searchDocContent(subject, subParams), 100),
      scan(
        (searchResults, docResults) => [...searchResults, ...docResults],
        [] as SearchResult[],
      ),
      takeWhile((results) => results?.length <= 1000, true),
    );

    // forkJoin used to wait for processing of entire searchSubjects$ array before emitting results
    return forkJoin([searchResults$]).pipe(map(([results]) => results));
  }

  private searchDocContent(
    subject: SearchSubject,
    { query, doubleSpine, isTeacher, lexileLevel }: SubSearchParams,
  ): Observable<SearchResult[]> {
    const doc = this.domp.parseFromString(subject.html as string, 'text/xml');

    /** clean document */
    Array.from(doc.getElementsByClassName('mhe-inline-credit')).forEach(empty);

    if (!isTeacher) {
      this.cleanTeacherContent(doc);
    }

    if (lexileLevel) {
      this.cleanLexileContent(doc, lexileLevel);
    }

    /** query doc results */
    const allnodes = Array.from(doc.querySelectorAll('*'));
    const allnodes$ = from(allnodes);

    let docresults: SearchResult[] = [];
    const docresults$ = allnodes$.pipe(
      mergeMap((docnode) => {
        const { childNodes } = docnode;
        const textNodes = Array.from(childNodes).filter(
          ({ nodeType }) => nodeType === Node.TEXT_NODE,
        );

        const queryElements = textNodes
          .filter(({ textContent }) => {
            const txt = textContent?.toLowerCase();
            const q = query.toLowerCase();
            return txt?.includes(q);
          })
          .map(({ parentElement }) => parentElement as HTMLElement)
          .filter(({ nodeName }) => {
            const nn = nodeName.toLowerCase();
            return nn !== 'title' && nn !== 'mtext';
          });

        const rawResults = queryElements.map((el) =>
          this.mapElementToSearchResult(el, subject, { query, doubleSpine }),
        );

        // set occurences
        const results = rawResults.reduce<SearchResult[]>(
          (previous, current) => {
            let countOccurence!: number;
            try {
              countOccurence = findOccurrences(
                current.text.toLowerCase(),
                query.toLowerCase(),
              );
            } catch (e) {}

            if (countOccurence && countOccurence > 1) {
              for (let i = 1; i <= countOccurence; i++) {
                previous.push({ ...current, occurrence: i });
              }
              return [...previous];
            } else {
              return [...previous, { ...current, occurrence: 1 }];
            }
          },
          [],
        );

        return of(results);
      }, 50),
      map((r) => (docresults = [...docresults, ...r])),
    );

    return forkJoin([docresults$]).pipe(map(([results]) => results));
  }

  private mapElementToSearchResult(
    element: HTMLElement,
    subject: SearchSubject,
    { query, doubleSpine }: SubSearchParams,
  ): Omit<SearchResult, 'occurrence'> {
    const id = element.id || (findElementId(element) as string);
    const text = element.innerText || (element.textContent as string);
    const spineIndex = subject.index;
    const href = subject.href as string;
    const sectionName = subject.sectionName as string;

    let frameSide: FrameSide;
    if (doubleSpine?.length) {
      const si = doubleSpine.find(
        ({ left, right }) =>
          left?.index === spineIndex || right?.index === spineIndex,
      );
      frameSide = this.getFrameSide(si as DoubleSpineItem, spineIndex);
    }

    const isPageNumber =
      element.className.includes('page-number') ||
      (element.parentElement?.className.includes('page-number') as boolean);

    // for links
    const isLink = element.tagName === 'a';
    const parentId = isLink ? element.parentElement?.id : undefined;
    const indexRelativeToParent = isLink
      ? Array.from(
        element.parentNode?.childNodes as NodeListOf<ChildNode>,
      ).indexOf(element)
      : undefined;

    const result: Omit<SearchResult, 'occurrence'> = {
      id,
      frameSide,
      href,
      indexRelativeToParent,
      isLink,
      isPageNumber,
      parentId,
      query,
      sectionName,
      spineIndex,
      text,
    };

    return result;
  }

  private getFrameSide(
    { left, right }: DoubleSpineItem,
    index: number,
  ): FrameSide {
    if (left?.index === index) return 'left';
    if (right?.index === index) return 'right';
  }

  private cleanTeacherContent(doc: Document): void {
    const teacherCssClasses = ['teacher', 'acme-teacher'];

    const teacherElements = teacherCssClasses.reduce<Element[]>(
      (acc, className) => {
        const elements = Array.from(doc.getElementsByClassName(className));
        return [...acc, ...elements];
      },
      [],
    );

    teacherElements.forEach(empty);
  }

  private cleanLexileContent(doc: Document, lexileLevel: LexileLevel): void {
    const lexileElements = doc.querySelectorAll(`.${CSS_LEXILE_CONTAINER}`);
    const activeLevelCssClass = `${CSS_LEXILE_BASE}${lexileLevel}`;

    const inactiveLexileElements = Array.from(lexileElements).filter(
      (el) => !el.classList.contains(activeLevelCssClass),
    );

    inactiveLexileElements.forEach(empty);
  }
}

function findElementId(node: HTMLElement): string | undefined {
  if (node.id) {
    return node.id;
  }

  // eslint-disable-next-line no-unreachable-loop
  while (!node.id && node.parentElement?.tagName.toUpperCase() !== 'BODY') {
    node = node.parentElement as HTMLElement;
    return findElementId(node);
  }
}

function findOccurrences(
  str: string,
  subString: string,
  allowOverlapping = false,
): number {
  str += '';
  subString += '';
  if (subString.length <= 0) {
    return str.length + 1;
  }

  let n = 0;
  let pos = 0;
  const step = allowOverlapping ? 1 : subString.length;

  while (true) {
    pos = str.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else {
      break;
    }
  }
  return n;
}

// Because no jquery
const empty = (e: Element): void => {
  while (e.firstChild) {
    e.removeChild(e.firstChild);
  }
};
