import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';

import { ForwardButtonComponent } from './forward-button/forward-button.component';
import { NavigationComponent } from './navigation.component';
import { PositionIndicatorComponent } from './position-indicator/position-indicator.component';
import { PreviousButtonComponent } from './previous-button/previous-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonModule, TooltipModule],
  declarations: [
    NavigationComponent,
    ForwardButtonComponent,
    PreviousButtonComponent,
    PositionIndicatorComponent,
  ],
  exports: [
    NavigationComponent,
    ForwardButtonComponent,
    PreviousButtonComponent,
    PositionIndicatorComponent,
  ],
})
export class NavigationModule {}
