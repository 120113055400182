import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reader-ui-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  private readonly activatedRoute = inject(ActivatedRoute);

  queryParams$ = this.activatedRoute.queryParams;
}
