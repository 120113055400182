import { Injectable } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';

import {
  DoubleSpineItem,
  FlatTocItem,
  SpineItem,
  TocItem,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { MediatorUtils } from './mediator-utils';
import { findClosestTocItem, flattenToc } from '@mhe/reader/utils';
import { TocStore } from '@mhe/reader/components/toc';
import * as tocActions from '@mhe/reader/components/toc';

@Injectable()
export class TocMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;
  private readonly tocActions$ = this.tocStore.actions$;
  private readonly flatToc$ = this.readerStore.toc$.pipe(
    map((toc) => flattenToc(toc)),
    shareReplay(),
  );

  constructor(
    private readonly readerStore: ReaderStore,
    private readonly tocStore: TocStore,
    private readonly util: MediatorUtils,
  ) {
    super();
  }

  /** navigation */
  private readonly navigateFromToc$ = this.effect(() =>
    this.tocActions$.pipe(
      ofType(tocActions.tocItemSelected),
      this.util.tapDoubleSpread<{ tocItem: TocItem }>(
        ({ tocItem }: { tocItem: TocItem }) => this.navToc$(tocItem),
        ({ tocItem }: { tocItem: TocItem }) => this.navTocDouble$(tocItem),
      ),
      tap(() => this.util.leftDrawer(false)),
      logCatchError('navigateFromToc$'),
    ),
  );

  // nav single
  private readonly navToc$ = this.effect((nav$: Observable<TocItem>) =>
    nav$.pipe(
      map((tocItem) => {
        const index = tocItem.spinePos;
        const hash =
          tocItem.id !== tocItem.spineItem.id ? `#${tocItem.id}` : undefined;

        return { index, hash };
      }),
      tap(({ index, hash }) => this.util.navigateToSpineIndex(index, hash)),
      logCatchError('navToc$'),
    ),
  );

  // nav double
  private readonly navTocDouble$ = this.effect((nav$: Observable<TocItem>) =>
    nav$.pipe(
      withLatestFrom(this.readerStore.doubleSpine$),
      map(([{ spineItem }, spine]) =>
        this.util.getDoubleSpineIndexFromSpineItem(
          spineItem,
          spine as DoubleSpineItem[],
        ),
      ),
      tap((index) => this.util.navDoubleSpread(index)),
      logCatchError('navTocDouble$'),
    ),
  );

  /** selected */
  private readonly selectedToc$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setSpineItem),
      switchMap(({ spineItem, hash }) =>
        hash
          ? this.mapTocItemFromHash(hash)
          : this.mapTocItemFromSpineItem(spineItem),
      ),
      filter((tocItem) => Boolean(tocItem)),
      tap((tocItem: TocItem) =>
        this.tocStore.dispatch(tocActions.setActiveTocItem({ tocItem })),
      ),
      logCatchError('selectedToc$'),
    ),
  );

  private readonly selectedDoubleToc$ = this.effect(() =>
    this.readerActions$.pipe(
      ofType(readerActions.setDoubleSpineItem),
      map(({ spineItem }) => spineItem),
      withLatestFrom(this.readerStore.flatToc$),
      map(([{ left, right }, flatToc]) => {
        const indexes = [left?.index, right?.index];
        return findClosestTocItem(flatToc, indexes);
      }),
      filter((tocItem) => Boolean(tocItem)),
      tap((tocItem) =>
        this.tocStore.dispatch(tocActions.setActiveTocItem({ tocItem })),
      ),
      logCatchError('selectedDoubleToc$'),
    ),
  );

  // helpers
  private mapTocItemFromSpineItem(
    spineItem: SpineItem,
  ): Observable<FlatTocItem> {
    return of(spineItem).pipe(
      withLatestFrom(this.readerStore.flatToc$),
      map(([{ index }, flatToc]) => findClosestTocItem(flatToc, [index])),
    );
  }

  private mapTocItemFromHash(hash: string): Observable<TocItem | undefined> {
    const hashId = hash?.replace(/#/i, '');

    return of(hashId).pipe(
      withLatestFrom(this.flatToc$),
      map(([hid, flatToc]) => flatToc.find(({ id }) => id === hid)),
    );
  }
}
