import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { ApiAnnotation } from '@mhe/reader/models';
import { AnnotationsContextMenuConfig } from './annotations-context-menu.model';

// These InjectionTokens should be provided via the injector through the cdk ComponentPortal
export const OVERLAY_REF = new InjectionToken<OverlayRef>(
  'Reader Annotations Context Menu Overlay Ref',
);
export const ANNOTATION = new InjectionToken<ApiAnnotation>(
  'Reader Annotation Context Menu Annotation',
);
export const ANNOTATIONS_CONTEXT_MENU_CONFIG =
  new InjectionToken<AnnotationsContextMenuConfig>(
    'Reader Annotation Context Menu Config',
  );
