<div class="viewer-container">
  <rdrx-modal-header (closeEvent)="dialogRef.close()">
    <h2 class="modal-header" id="simple-message-modal-h2">{{ title }}</h2>
  </rdrx-modal-header>

  <div class="body">
    <div [innerHTML]="messageText"></div>
  </div>
  <div class="message-loading" *ngIf="isLoading$ | async">
    {{ 'dialog.loading_message' | translate }}
  </div>
  <div class="message-error" *ngIf="error$ | async">
    <mhe-alert [type]="alertType.Error" [closable]="false">
      {{ 'dialog.failed_message' | translate }}
    </mhe-alert>
  </div>
</div>
