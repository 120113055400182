import { createAction, props } from '@ngrx/store';
import { UserSettings } from '../models';

export const init = createAction('[UserSettings] Init');

export const getUserSettings = createAction(
  '[UserSettings] Retrieve user settings',
);
export const setUserSettings = createAction(
  '[UserSettings] Save user settings',
  props<{ userSettings: UserSettings }>(),
);

export type UserSettingsActions = ReturnType<
  typeof init | typeof getUserSettings | typeof setUserSettings
>;
