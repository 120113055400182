import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssessmentApiResponse, AssessmentLaunch } from '@mhe/reader/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AssessmentService {
  private readonly baseUrl = '/assessment';
  private readonly defaultHeader = new HttpHeaders({ 'X-Skip-Interceptor': '' });

  constructor(private readonly http: HttpClient) {}

  refreshParams(api: string): Observable<AssessmentLaunch> {
    const url = `${api}${this.baseUrl}`;
    const headers = this.defaultHeader;

    return this.http
      .get<AssessmentApiResponse>(url, { headers, withCredentials: true })
      .pipe(map((rsp) => rsp?.launchParams));
  }
}
