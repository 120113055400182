import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';
import {
  ReaderAlert,
  SpineItem,
  DoubleSpineItem,
  LexileLevel,
  PARSER_SELECTED_LEVEL_KEY,
  ParserOptions,
} from '@mhe/reader/models';
import * as errorsQuery from '@mhe/reader/global-store/errors/errors.selectors';
import * as epubQuery from '@mhe/reader/global-store/epub/epub.selectors';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ReaderConfigStore } from './reader-config.store';
import { ReaderActions } from './reader.actions';
import { AiAssistUserSelection, ReaderState, initialReaderState } from './reader.state';
import { mapParserOptionToLeveledKey } from '@mhe/reader/utils';
import { AllowedLevel, CustomButton, PdfUrl } from '@mhe/reader/types';
import { AnnotationsContextMenuConfig } from '@mhe/reader/components/annotations-context-menu';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';

@Injectable()
export class ReaderStore extends ExtendedComponentStore<
ReaderState,
ReaderActions
> {
  private readonly contentsMenuFlag$ = this.config.contentsMenuFlag5of5$;
  private readonly defaultLevel$ = this.config.levelDefault$;
  private readonly k5$ = this.config.k5$;
  private readonly dfa$ = this.config.dfa$;
  private readonly readiator$ = this.config.readiator$;
  private readonly readspeaker$ = this.config.readspeaker$;
  private readonly sessionDisabled$ = this.config.sessionDisabled$;

  constructor(
    private readonly config: ReaderConfigStore,
    private readonly store: Store,
    private readonly ga: GoogleAnalyticsService,
  ) {
    super(initialReaderState);
  }

  /**
   * selectors
   */

  // leveled content
  readonly selectedLevel$ = this.select(({ selectedLevel }) => selectedLevel);
  readonly parserOptions$ = combineLatest([
    this.defaultLevel$,
    this.selectedLevel$,
  ]).pipe(
    map(
      ([defaultLevel, selectedLevel]): ParserOptions => ({
        defaultLevel,
        [PARSER_SELECTED_LEVEL_KEY]: selectedLevel,
      }),
    ),
  );

  // epub base
  readonly epubUrl$ = this.select(({ epubUrl }) => epubUrl).pipe(
    filter((url) => url !== undefined),
  );

  readonly epubKey$ = combineLatest([this.epubUrl$, this.parserOptions$]).pipe(
    map(([url, parserOptions]) =>
      mapParserOptionToLeveledKey(url as string, parserOptions),
    ),
  );

  // lexile levels
  readonly selectedLexileLevel$ = this.select(
    ({ selectedLexileLevel }) => selectedLexileLevel,
  );

  readonly pgLexileLevels$ = this.select(
    ({ pgLexileLevels }) => pgLexileLevels,
  );

  readonly renderedLexileLevel$ = this.select(
    ({ renderedLexileLevel }) => renderedLexileLevel,
  );

  // miscl.
  readonly spineItem$ = this.select(({ spineItem }) => spineItem);
  readonly doubleSpineItem$ = this.select(
    ({ doubleSpineItem }) => doubleSpineItem,
  );

  readonly leftDrawerOpen$ = this.select(
    ({ leftDrawerOpen }) => leftDrawerOpen,
  );

  readonly rightDrawerOpen$ = this.select(
    ({ rightDrawerOpen }) => rightDrawerOpen,
  );

  readonly integrateRouting$ = this.select(
    ({ integrateRouting }) => integrateRouting,
  );

  readonly navigationHistory$ = this.select(
    ({ navigationHistory }) => navigationHistory,
  );

  readonly canNavigateBack$ = this.select(
    ({ navigationHistory }) => navigationHistory && navigationHistory.length > 1,
  );

  readonly alerts$ = this.select(({ alerts }) => alerts);
  readonly customButton$ = this.select(({ customButton }) => customButton);

  // external store selectors
  readonly book$ = this.epubKey$.pipe(
    switchMap((url) => this.store.pipe(select(epubQuery.getEpubBook, { url }))),
    filter((book) => Boolean(book)),
  );

  readonly spine$ = this.epubKey$.pipe(
    switchMap((url) => this.store.pipe(select(epubQuery.getSpine, { url }))),
    filter((spine) => !!spine),
  );

  readonly linearSpine$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.getLinearSpine, { url })),
    ),
    filter((spine) => !!spine),
  );

  readonly isDoubleSpread$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.isDoubleSpread, { url })),
    ),
  );

  readonly isFixedLayout$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.isFixedLayout, { url })),
    ),
  );

  readonly doubleSpine$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.getDoubleSpine, { url })),
    ),
    filter((spine) => !!spine),
  );

  readonly flatToc$ = this.epubKey$.pipe(
    switchMap((url) => this.store.pipe(select(epubQuery.getFlatToc, { url }))),
  );

  readonly hasSessionEnded$ = this.epubKey$.pipe(
    switchMap(() => this.store.pipe(select(errorsQuery.hasSessionEnded))),
  );

  // epub status
  readonly loading$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.isEpubLoading, { url })),
    ),
  );

  readonly error$ = this.epubKey$.pipe(
    switchMap((url) =>
      this.store.pipe(select(epubQuery.getEpubLoadingError, { url })),
    ),
  );

  // session
  readonly sessionDetails$ = combineLatest([
    this.sessionDisabled$,
    this.hasSessionEnded$,
  ]).pipe(
    map(([sessionDisabled, hasSessionEnded]) => ({
      sessionDisabled,
      hasSessionEnded,
    })),
  );

  // book properties
  readonly toc$ = this.book$.pipe(map((book) => book?.toc));
  readonly title$ = this.book$.pipe(map((book) => book?.metadata?.title));
  readonly lexileLevels$ = this.book$.pipe(map((book) => book?.lexileLevels));
  readonly renditions$ = this.book$.pipe(
    map((book) => book?.selectionTags),
    map((tags) => tags.map((tag) => Object.keys(tag)[0])),
  );

  // assessments | assignments
  readonly assessmentCompleted$ = this.select(
    ({ assessmentCompleted }) => assessmentCompleted,
  );

  readonly assignmentSubmitted$ = this.select(
    ({ assignmentSubmitted }) => assignmentSubmitted,
  );

  readonly assignmentSubmitting$ = this.select(
    ({ assignmentSubmitting }) => assignmentSubmitting,
  );

  readonly lastPageViewed$ = this.select(
    ({ lastPageViewed }) => lastPageViewed,
  );

  // eBookshelf concurrency paywall
  readonly hideReader$ = this.select(({ hideReader }) => hideReader);

  // annotations
  readonly showAnnotateButton$ = this.select(
    ({ showAnnotateButton }) => showAnnotateButton,
  );

  // factory metadata
  readonly isFactoryMetadataAiAssistEnabled$ = this.select(
    ({ factoryMetadata }) => factoryMetadata.readerAiAssistEnabled,
  );

  // aiAssist
  readonly isAiAssistOpen$ = this.select(
    ({ aiAssistIsOpen }) => aiAssistIsOpen,
  );

  readonly isAiAssistReady$ = this.select(
    ({ aiAssistIsReady }) => aiAssistIsReady,
  );

  readonly showAiAssist$ = combineLatest([
    this.isFactoryMetadataAiAssistEnabled$,
    this.config.isAiAssistEnabled$,
    this.isAiAssistOpen$,
  ]).pipe(
    map(
      ([isFactoryEnabled, isEnabled, isOpen]): boolean =>
        isFactoryEnabled && isEnabled && isOpen,
    ),
  );

  readonly annotationForAiAssist$ = this.select(
    ({ aiAssistAnnotationData }) => aiAssistAnnotationData,
  );

  private readonly contextMenuConfigDetails$ = combineLatest([
    this.k5$,
    this.config.assignment$,
    this.readspeaker$,
    this.readiator$,
    this.config.authoring$,
    this.dfa$,
  ]).pipe(
    map(([k5, assignment, readspeaker, readiator, authoring, dfa]) => ({
      k5,
      assignment,
      readspeaker,
      readiator,
      authoring,
      dfa,
    })),
  );

  /**
   * Options for the selected text menu.
   *
   * In the case where a session ends, an error
   * will be displayed above the ePub, so we want
   * to remove the buttons from this menu.
   */
  readonly annotationsContextMenuConfig$ = combineLatest([
    this.contentsMenuFlag$,
    this.sessionDetails$,
    this.contextMenuConfigDetails$,
  ]).pipe(
    map(
      ([
        { highlights, notes, placemarks, isAiAssistOffered },
        sessionDetails,
        { k5, assignment, readspeaker, readiator, authoring, dfa },
      ]): AnnotationsContextMenuConfig => {
        const noFeatures = {
          placemarks: false,
          highlights: false,
          notes: false,
          readspeaker: false,
          isAiAssistOffered: false,
        };

        if (readiator && authoring) {
          return { ...noFeatures };
        } else if (
          (readiator && !k5 && !dfa) ||
          sessionDetails.sessionDisabled ||
          sessionDetails.hasSessionEnded ||
          assignment === 'review'
        ) {
          return { ...noFeatures, readspeaker };
        }
        return { highlights, notes, placemarks, readspeaker, isAiAssistOffered };
      },
    ),
  );

  // teacher toggles
  readonly quickAnnotationEnabled$ = this.select(
    ({ quickAnnotationEnabled }) => quickAnnotationEnabled,
  );

  readonly teacherContentEnbled$ = this.select(
    ({ teacherContentEnabled }) => teacherContentEnabled,
  );

  /**
   * derrived selectors
   * (combines external (i.e. config) with internal selectors)
   */

  // lexile levels
  readonly defaultLexileLevel$ = combineLatest([
    this.config.lexileLevelCourse$,
    this.config.lexileLevelUser$,
    this.lexileLevels$,
  ]).pipe(map(([course, user, levels]) => user || course || levels?.[0]));

  readonly activeLexileLevel$ = combineLatest([
    this.defaultLexileLevel$,
    this.selectedLexileLevel$,
  ]).pipe(map(([defaultLexile, selected]) => selected ?? defaultLexile));

  readonly pdfLoading$ = this.select(({ pdfLoading }) => pdfLoading);
  readonly pdfUrl$ = this.select(({ pdfUrl }) => pdfUrl);

  /**
   * updaters
   */
  readonly setEpubUrl = this.updater((state, epubUrl: string) => ({
    ...state,
    epubUrl,
  }));

  readonly setSpineItem = this.updater((state, spineItem: SpineItem) => ({
    ...state,
    spineItem,
  }));

  readonly setDoubleSpineItem = this.updater(
    (state, doubleSpineItem: DoubleSpineItem) => ({
      ...state,
      doubleSpineItem,
    }),
  );

  readonly setLeftDrawerOpen = this.updater(
    (state, leftDrawerOpen: boolean) => ({
      ...state,
      leftDrawerOpen,
    }),
  );

  readonly setRightDrawerOpen = this.updater(
    (state, rightDrawerOpen: boolean) => ({
      ...state,
      rightDrawerOpen,
    }),
  );

  readonly setIntegrateRouting = this.updater(
    (state, integrateRouting: boolean) => ({
      ...state,
      integrateRouting,
    }),
  );

  readonly setNavigationHistory = this.updater(
    (state, navigationHistory: navigationActions.NavigationActions[]) => ({
      ...state,
      navigationHistory: trimNavigationHistory(navigationHistory),
    }),
  );

  readonly pushNavigationHistory = this.updater(
    (state, historyItem: navigationActions.NavigationActions) => ({
      ...state,
      navigationHistory: trimNavigationHistory([
        ...state.navigationHistory,
        historyItem,
      ]),
    }),
  );

  readonly addAlert = this.updater((state, alertItem: ReaderAlert) => ({
    ...state,
    alerts: [...state.alerts, alertItem],
  }));

  readonly removeAlert = this.updater((state, translateKey: string) => {
    let { alerts } = state;

    if (alerts.find((a) => a.translateKey === translateKey)) {
      alerts = alerts.filter((a) => a.translateKey !== translateKey);
    }

    return { ...state, alerts };
  });

  // assessments | assignments
  readonly setAssessmentCompleted = this.updater(
    (state, assessmentCompleted: boolean) => ({
      ...state,
      assessmentCompleted,
    }),
  );

  readonly setAssignmentSubmitted = this.updater(
    (state, assignmentSubmitted: boolean) => ({
      ...state,
      assignmentSubmitted,
    }),
  );

  readonly setAssignmentSubmitting = this.updater(
    (state, assignmentSubmitting: boolean) => ({
      ...state,
      assignmentSubmitting,
    }),
  );

  readonly setLastPageViewed = this.updater(
    (state, lastPageViewed: boolean) => ({
      ...state,
      lastPageViewed,
    }),
  );

  // eBookshelf concurrency paywall
  readonly setHideReader = this.updater((state, hideReader: boolean) => ({
    ...state,
    hideReader,
  }));

  // annotations
  readonly setShowAnnotateButton = this.updater(
    (state, showAnnotateButton: boolean) => ({
      ...state,
      showAnnotateButton,
    }),
  );

  // factory metadata
  readonly setFactoryMetadata = this.updater(
    (state, factoryMetadata: any) => {
      return {
        ...state,
        factoryMetadata,
      };
    },
  );

  // ai reader
  readonly setAiAssistOpen = this.updater(
    (state, aiAssistIsOpen: boolean) => {
      return {
        ...state,
        aiAssistIsOpen,
      };
    },
  );

  readonly setAiAssistReady = this.updater(
    (state, aiAssistIsReady: boolean) => {
      return {
        ...state,
        aiAssistIsReady,
      };
    },
  );

  readonly setAnnotationDataForAiAssist = this.updater(
    (state, aiAssistAnnotationData: AiAssistUserSelection) => ({
      ...state,
      aiAssistAnnotationData,
    }),
  );

  // leveled content
  readonly setSelectedLevel = this.updater(
    (state, selectedLevel: AllowedLevel) => ({
      ...state,
      selectedLevel,
    }),
  );

  // lexile levels
  readonly setSelectedLexileLevel = this.updater(
    (state, selectedLexileLevel: LexileLevel) => ({
      ...state,
      selectedLexileLevel,
    }),
  );

  readonly setPageLexileLevels = this.updater(
    (state, pgLexileLevels: LexileLevel[]) => ({
      ...state,
      pgLexileLevels,
    }),
  );

  readonly setRenderedLexileLevels = this.updater(
    (state, renderedLexileLevel: LexileLevel) => ({
      ...state,
      renderedLexileLevel,
    }),
  );

  // teacher toggles
  readonly setQuickAnnotationEnabled = this.updater(
    (state, quickAnnotationEnabled: boolean) => ({
      ...state,
      quickAnnotationEnabled,
    }),
  );

  readonly toggleQuickAnnotationEnabled = this.updater((state) => {
    const eventAction = !state.quickAnnotationEnabled
      ? 'Quick Annotation Turn ON'
      : 'Quick Annotation Turn OFF';
    this.ga.event({
      eventCategory: 'Reader',
      eventAction,
    });
    return {
      ...state,
      quickAnnotationEnabled: !state.quickAnnotationEnabled,
    };
  });

  readonly setTeacherContentEnabled = this.updater(
    (state, teacherContentEnabled: boolean) => {
      return {
        ...state,
        teacherContentEnabled,
      };
    },
  );

  readonly toggleTeacherContentEnabled = this.updater((state) => {
    return {
      ...state,
      teacherContentEnabled: !state.teacherContentEnabled,
    };
  });

  readonly setCustomButton = this.updater(
    (state, customButton: CustomButton) => ({
      ...state,
      customButton,
    }),
  );

  readonly setPdfLoading = this.updater(
    (state, pdfLoading: boolean) => ({
      ...state,
      pdfLoading,
    }),
  );

  readonly setPdfUrl = this.updater(
    (state, pdfUrl: PdfUrl | undefined) => ({
      ...state,
      pdfUrl,
    }),
  );
}

function trimNavigationHistory(
  navigationHistory: navigationActions.NavigationActions[],
  limit = 100,
): navigationActions.NavigationActions[] {
  return navigationHistory.slice(limit * -1);
}
