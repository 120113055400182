import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

type functionOrNull = null | ((...args: any[]) => any);

export const logCatchError =
  (logKey: string = 'error', resubscribe = true, finallyOp: functionOrNull = null) =>
    <T>(source$: Observable<T>) =>
      source$.pipe(
        catchError((error, caught$) => {
          console.error(`${logKey}\n`, error);
          if (finallyOp) {
            finallyOp();
          }
          return resubscribe ? caught$ : EMPTY;
        }),
      );
