import { createFeatureSelector } from '@ngrx/store';
import { PartialEpubState } from './epub/epub.state';
import { PartialFootnoteState } from './footnote/footnote.state';
import { PartialGlossaryState } from './glossary/glossary.state';

export const READER_STATE_KEY = 'reader';

export interface ReaderState extends PartialEpubState, PartialFootnoteState, PartialGlossaryState {}

export const getGlobalState = createFeatureSelector<ReaderState>(READER_STATE_KEY);
