import { Injectable, OnDestroy, Renderer2 } from '@angular/core';

import { Book, SpineItem } from '@mhe/reader/models';
import { Next, Transformer } from './transformer';

@Injectable()
export class AudioTransformer implements Transformer, OnDestroy {
  private unlistenerRegistry: Record<
  string,
  Record<
  string,
  {
    click: () => any
    play: () => any
    pause: () => any
    ended: () => any
  }
  >
  > = {};

  constructor(private readonly renderer: Renderer2) {}

  async preRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    content
      .querySelectorAll<HTMLElement>('.mhe-audio--wrapper')
      .forEach((audioWrapper) =>
        this.renderer.addClass(audioWrapper, 'enhanced'),
      );

    return await next(content);
  }

  async postRender(
    book: Book,
    spineItem: SpineItem,
    content: HTMLDocument,
    iframe: HTMLIFrameElement,
    next: Next,
  ): Promise<HTMLDocument> {
    // do any cleanup from previous renders first
    this.clearUnlistenerRegistry(iframe.id);

    const audioWrappers = Array.from(
      content.querySelectorAll<HTMLElement>('.mhe-audio--wrapper'),
    );
    const buttonAndAudioControlPairs = audioWrappers.map((aw) => {
      const button = aw.querySelector<HTMLButtonElement>(
        'button.mhe-button-audio',
      ) as HTMLButtonElement;
      const audioControl = aw.querySelector<HTMLAudioElement>(
        'audio.mhe-audio--inline',
      ) as HTMLAudioElement;
      return { button, audioControl };
    });

    buttonAndAudioControlPairs.forEach(({ button, audioControl }) => {
      const togglePlayback = async(event: Event): Promise<void> => {
        if (!audioControl.paused) {
          audioControl.pause();
        } else {
          buttonAndAudioControlPairs.forEach((pair) => {
            if (pair.audioControl && pair.audioControl !== audioControl) {
              pair.audioControl.pause();
              pair.audioControl.currentTime = 0;
            }
          });
          await audioControl.play();
        }
      };

      const updateIcon = (event: Event): void => {
        if (audioControl.paused) {
          this.renderer.removeClass(button, 'icon-pause');
          this.renderer.addClass(button, 'icon-play');
        } else {
          this.renderer.removeClass(button, 'icon-play');
          this.renderer.addClass(button, 'icon-pause');
        }
      };

      if (button && audioControl) {
        const clickUnlisten = this.renderer.listen(
          button,
          'click',
          togglePlayback as any,
        );
        const playUnlisten = this.renderer.listen(
          audioControl,
          'play',
          updateIcon,
        );
        const pauseUnlisten = this.renderer.listen(
          audioControl,
          'pause',
          updateIcon,
        );
        const endedUnlisten = this.renderer.listen(
          audioControl,
          'ended',
          updateIcon,
        );
        this.unlistenerRegistry[iframe.id][button.id] = {
          click: clickUnlisten,
          play: playUnlisten,
          pause: pauseUnlisten,
          ended: endedUnlisten,
        };
      }
    });

    return await next(content);
  }

  ngOnDestroy(): void {
    Object.keys(this.unlistenerRegistry).forEach((iframeId) =>
      this.clearUnlistenerRegistry(iframeId),
    );
  }

  private clearUnlistenerRegistry(iframeId: string): void {
    const iframeUnlisteners = this.unlistenerRegistry[iframeId] || {};

    Object.values(iframeUnlisteners).forEach((ulObject) => {
      ulObject.click();
      ulObject.play();
      ulObject.pause();
      ulObject.ended();
    });
    this.unlistenerRegistry[iframeId] = {};
  }
}
