import { PARSER_SELECTED_LEVEL_KEY, ParserOptions } from '@mhe/reader/models';

export function mapParserOptionToLeveledKey(url: string, parserOptions: ParserOptions): string {
  const { defaultLevel, [PARSER_SELECTED_LEVEL_KEY]: selected } = parserOptions;

  const level = selected ?? defaultLevel;
  const levelKey = level ? `|${level}` : '';

  return `${url}${levelKey}`;
}
