import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MathjaxService {
  constructor(
    protected window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  handleMath(contentSelector: string): void {
    // we need to use a timeout to ensure the view has been updated before we check and handle math
    setTimeout(() => {
      const contentElement = this.document.querySelector(contentSelector);

      // only if there is a dialog
      if (!contentElement) {
        return;
      }

      // only if there is math content
      const hasMathContent = contentElement.querySelectorAll('math, .math-tex');
      if (!hasMathContent.length) {
        return;
      }

      // add MathJax, if needed
      const mjWasAdded = this.addMathJax();
      if (mjWasAdded) {
        // MathJax was added new; an initial typeset is included automatically
        return;
      }

      // MathJax was not added (it is already present)
      // so just typeset the dialog
      (this.window as any).MathJax.Hub.Queue([
        'Typeset',
        (this.window as any).MathJax.Hub,
        contentElement,
      ]);
    }, 0);
  }

  addMathJax(): boolean {
    // only if MathJax is not already present
    if (
      typeof (this.window as any).MathJax !== 'undefined' &&
      (this.window as any).MathJax.version
    ) {
      // signal that we do not need to load it new
      // MathJax is loaded and present, only a typeset is needed
      return false;
    }

    // new script element
    const mjScriptNew = this.document.createElement('script');
    mjScriptNew.type = 'text/javascript';

    // point to our MathJax CDN URL
    mjScriptNew.src =
      'https://dle-cdn.mheducation.com/3rdparty/mathjax/latest-2.7.X/' +
      // configure to support TeX and MathML inputs; output in SVG
      'MathJax.js?config=TeX-MML-AM_SVG';

    // listen for the script loaded event
    mjScriptNew.addEventListener('load', () => {
      // configure MathJax to create standalone SVG
      (this.window as any).MathJax.Hub.Config({
        displayAlign: 'inherit',
        showProcessingMessages: false,
        messageStyle: 'none',
        showMathMenu: false,
        showMathMenuMSIE: false,
        SVG: {
          useFontCache: false,
          useGlobalCache: false,
          font: 'STIX-Web',
        },
      });
    });

    // add the script so that we can render math content
    this.document.querySelector('body')?.appendChild(mjScriptNew);

    // signal that we need to load the library new
    // mathjax will do an automatic typeset on it's first load
    return true;
  }
}
