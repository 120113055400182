/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import {
  AnnotationUtterance,
  TTSGender,
  TTSChunkCollection,
  PartialTTSChunkCollection,
  TTSAudioSettings,
  TTSChunk,
  TTSJobStatus,
  TTSMathFlag,
  TTSOptions,
  TTSPlaybackMode,
  TTSRawTextTranslationResponse,
  TTSRawTextType,
  Utterance,
  AudioChunk,
  AudioChunkCollection,
  ChunkedAudioProgress,
  TTSAudioContext,
  TTSUtteranceTiming,
} from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  from,
  fromEvent,
  interval,
  merge,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  first,
  map,
  mergeMap,
  repeatWhen,
  scan,
  share,
  startWith,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PlayerApiClient } from '@mhe/reader/core/player-api/player-api-client.service';
import {
  getScrollDistance,
  getTimeNeededToSmoothScroll,
} from '@mhe/reader/core/utils/scroll-utils';
import {
  ReaderConfigStore,
  ReaderStore,
} from '@mhe/reader/components/reader/state';
import { MediatorUtils } from './mediator-utils';
import { NewRelicService } from '@mhe/ol-platform/instrumentation';
import { EpubViewerStore } from '@mhe/reader/components/epub-viewer';
import * as epubViewerActions from '@mhe/reader/components/epub-viewer/state/epub-viewer.actions';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import {
  AudioElementService,
  CurrentView,
  EPubAudio,
  EPubPage,
  TTSStore,
} from '@mhe/reader/components/text-to-speech';
import * as ttsActions from '@mhe/reader/components/text-to-speech/state/tts.actions';
import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { AnnotationsContextMenuStore } from '@mhe/reader/components/annotations-context-menu';
import * as annotationContextMenuActions from '@mhe/reader/components/annotations-context-menu/state/annotations-context-menu.actions';
import { environment } from 'src/environments/environment';
import {
  ToggleBoxActions,
  handleToggleBoxOnParent,
  DetailElementAncestor,
} from '../components/epub-viewer/state/utils';
import { FeatureTogglesService } from '@mhe/reader/features';

@Injectable()
export class TTSMediator extends ComponentEffects {
  // While the audio is playing the user cannot do anything
  public readonly PROGRESS_INTEVAL_MS = 1000;

  private readonly ePubAnnotation: any | undefined;
  private readonly ttsActions$ = this.ttsStore.actions$;

  /**
   * The amount of time inbetween http calls that
   * check on a tts job results.
   */
  private readonly SPEECH_JOB_POLLING_INTERVAL_MS = 2 * 1000;

  /**
   * The amount of time polling can continue for a
   * job that remains "pending" with no new results.
   */
  private readonly SPEECH_JOB_STALLED_TIMEOUT_MS = 30 * 1000;

  private readonly TTS_VOICE_DEFAULT_LANG = 'en_US';
  private readonly TTS_VOICE_DEFAULT_GENDER = TTSGender.MALE;
  private isMathInRSEnabled: boolean | null = null;

  constructor(
    protected window: Window,
    private readonly annotationContextMenuStore: AnnotationsContextMenuStore,
    private readonly epubLibCFIService: EpubLibCFIService,
    private readonly ePubViewerStore: EpubViewerStore,
    private readonly navigationStore: NavigationStore,
    private readonly ngZone: NgZone,
    private readonly playerApiClient: PlayerApiClient,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly translateService: TranslateService,
    private readonly ttsStore: TTSStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly newRelicService: NewRelicService,
    private readonly util: MediatorUtils,
    private readonly audioElementService: AudioElementService,
    private readonly featureTogglesService: FeatureTogglesService,
  ) {
    super();
    // check if ePubAnnotation global exists
    if ((this.window as any).ePubAnnotation === undefined) {
      // @todo how to handle this case?
    } else {
      this.ePubAnnotation = (this.window as any).ePubAnnotation;
    }
    this.featureTogglesService.isMathInRsEnabled$.subscribe((value: boolean | null): void => {
      this.isMathInRSEnabled = value;
    });
  }

  private readonly resetVoice$ = this.effect(() =>
    this.ttsStore.actions$.pipe(
      ofType(ttsActions.resetVoice),
      withLatestFrom(
        this.ttsStore.currentView$,
        this.ttsStore.docTTSControlReq$,
      ),
      tap(([, currentView, docTTSControlReq]) => {
        // Trigger playback from the beginning with new voice if the audio controls are open
        if (docTTSControlReq) {
          this.loadCurrentPageAudio$({ playbackPosition: 0 });
          // Clear cache for the current page so multiple audio files aren't stored for same page
          currentView.pages.forEach((page) =>
            this.ttsStore.setAudioChunks({
              key: page.baseCFIPath as string,
              chunkCollection: undefined,
            }),
          );
        }
      }),
      logCatchError('resetVoice$'),
    ),
  );

  private readonly resetAudio$ = this.effect(() =>
    this.navigationStore.actions$.pipe(
      ofType(
        navigationActions.navigateTo,
        navigationActions.navigateDoubleSpreadTo,
      ),
      tap(() => {
        // Turn off the audio controls.
        // Closing the audio controls will pause the audio, so
        // we should close them before the next page loads.
        this.ttsStore.setShowDocTTSControls(false);
        this.ttsStore.setCurrentView({ pages: [], isFixedLayout: undefined });
        this.ttsStore.setActiveAudio({
          keys: undefined,
          playbackMode: undefined,
        });
      }),
      logCatchError('resetAudio$'),
    ),
  );

  /**
   * TTS needs to know when the view has changed so it
   * can react (clear controls, resume playing, etc).
   * This effect will keep the TTSStore "currentView" state
   * always up-to-date with the latest view information.
   */
  private readonly setCurrentView$ = this.effect(() =>
    merge(
      this.ePubViewerStore.actions$.pipe(
        ofType(epubViewerActions.renderComplete),
      ),
      this.ePubViewerStore.albumMode$,
    ).pipe(
      withLatestFrom(
        this.readerStore.spineItem$,
        this.readerStore.doubleSpineItem$,
        this.ePubViewerStore.cloIframe$,
        this.ePubViewerStore.leftIframe$,
        this.ePubViewerStore.rightIframe$,
        this.ePubViewerStore.isDoubleSpread$,
        this.ePubViewerStore.albumMode$,
        this.readerStore.isFixedLayout$,
      ),
      map(
        ([
          _,
          singleSpineItem,
          doubleSpineItem,
          cloIframe,
          leftIframe,
          rightIframe,
          isDoubleSpread,
          albumMode,
          isFixedLayout,
        ]): CurrentView => {
          const singlePageVisible =
            !isDoubleSpread || (isDoubleSpread && albumMode);
          const doublePageVisible = !singlePageVisible;
          const pages: EPubPage[] = [];

          if (singlePageVisible && singleSpineItem) {
            // reflowable or album mode
            const baseCFIPath = this.epubLibCFIService.generateBasePath(
              singleSpineItem.index,
              singleSpineItem.id,
            );
            pages.push({
              baseCFIPath,
              iframe: cloIframe,
              language: cloIframe.contentDocument?.documentElement
                ?.lang as string,
            });
          } else if (doublePageVisible && doubleSpineItem) {
            // For a double spine, the left/right item might be null
            // (e.g. title only has right iframe).
            // Set pages active where the spineItems are defined.
            if (doubleSpineItem.left) {
              const baseCFIPath = this.epubLibCFIService.generateBasePath(
                doubleSpineItem.left.index,
                doubleSpineItem.left.id,
              );
              pages.push({
                baseCFIPath,
                iframe: leftIframe,
                language: leftIframe.contentDocument?.documentElement
                  ?.lang as string,
              });
            }
            if (doubleSpineItem.right) {
              const baseCFIPath = this.epubLibCFIService.generateBasePath(
                doubleSpineItem.right.index,
                doubleSpineItem.right.id,
              );
              pages.push({
                baseCFIPath,
                iframe: rightIframe,
                language: rightIframe.contentDocument?.documentElement
                  ?.lang as string,
              });
            }
          }
          return { pages, isFixedLayout };
        },
      ),
      tap(({ pages, isFixedLayout }) => {
        this.ttsStore.setCurrentView({ pages, isFixedLayout });
      }),
      logCatchError('setCurrentView$'),
    ),
  );

  /**
   * Logic to indicate when the ePub page audio controls should
   * be shown/hidden, and what happens to the playback.
   *
   * Toggling the TTS controls on should play the page and
   * toggling the TTS controls off should pause the playback.
   *
   * Navigating to a new page should hide the tts button
   * and pause the playback.
   */
  private readonly ePubTTSButtonShow$ = this.effect(() =>
    merge(
      // reset action
      this.ttsStore.actions$.pipe(ofType(ttsActions.resetPageAudio)),
      // page navigation "action"
      this.ttsStore.currentView$.pipe(
        filter((v) => v.pages[0]?.baseCFIPath !== undefined),
        distinctUntilChanged((prev, cur) => {
          const sameItemCount = prev.pages.length === cur.pages.length;
          const sameItems = prev.pages.every(
            (prevPage, i) => prevPage.baseCFIPath === cur.pages[i]?.baseCFIPath,
          );
          return sameItemCount && sameItems;
        }),
      ),
    ).pipe(
      switchMap(() => {
        return this.ttsActions$.pipe(
          ofType(ttsActions.togglePageTTSControls),
          scan(
            ({ numberOfPageToggles }, { val }) => {
              return {
                numberOfPageToggles: numberOfPageToggles + 1,
                val,
              };
            },
            { numberOfPageToggles: 0, val: undefined },
          ),
          startWith({ numberOfPageToggles: 0, val: false }),
          tap(({ val }) => this.ttsStore.setShowDocTTSControls(val)),
          tap(({ val, numberOfPageToggles }) => {
            if (val) {
              // show loading early to prevent rapid button clicks
              this.ttsStore.setIsLoading(true);

              if (numberOfPageToggles === 1) {
                // this is a new page, start the playback from the beginning
                this.loadCurrentPageAudio$({ playbackPosition: 0 });
              } else {
                // we are on the same page, resume playback
                this.loadCurrentPageAudio$({});
              }
            }
          }),
        );
      }),
      logCatchError('ePubTTSButtonShow$'),
    ),
  );

  /**
   * Logic to load in and play specified text as a single chunk
   */
  private readonly loadRawTextAudio$ = this.effect(() =>
    this.ttsStore.actions$.pipe(
      ofType(ttsActions.loadRawTextAudio),
      tap(({ options }) => {
        // hide the page audio controls
        this.ttsStore.dispatch(
          ttsActions.togglePageTTSControls({ val: false }),
        );
        // Set this audio as active for the player
        this.ttsStore.setActiveAudio({
          keys: [options.cfi],
          playbackMode: TTSPlaybackMode.DEFAULT,
        });
      }),
      withLatestFrom(
        this.ttsStore.activeAudioItem$,
        this.ttsStore.currentView$,
        this.readerConfigStore.readSpeakerVoicePick$,
        this.ttsStore.voiceName$,
      ),
      tap(
        ([
          { options },
          activeAudioItems,
          currentView,
          readSpeakerVoicePick,
          voiceName,
        ]) => {
          // If there was a voice change, clear the audio dictionary to fetch new audio from readspeaker
          if (
            readSpeakerVoicePick &&
            voiceName !== activeAudioItems[0]?.chunkCollection?.voice
          ) {
            this.ttsStore.setAudioChunks({
              key: options.cfi,
              chunkCollection: undefined,
            });
            activeAudioItems = [];
          }

          // see whether we need to fetch this audio
          if (!activeAudioItems[0]?.chunkCollection) {
            this.fetchRawTextAudio$({
              text: options.text,
              cfi: options.cfi,
              // The TTS_REQUEST is not expected to know the page language,
              // so we'll just use the language of the 0th page in the view
              // (this works, assuming every page has the same language)
              pageLanguage:
                options.pageLanguage ?? currentView.pages[0].language,
            });
          }

          if (options.autoplay) {
            this.ttsStore.dispatch(ttsActions.play({ playbackPosition: 0 }));
          } else {
            this.seekPlaybackPosition$({ playbackPosition: 0 });
          }
        },
      ),
      logCatchError('loadRawTextAudio$'),
    ),
  );

  /**
   * Logic to load in and play a chunk for AnnotationContextMenu
   */
  private readonly loadHighlightedTextAudio$ = this.effect(() =>
    this.annotationContextMenuStore.actions$.pipe(
      ofType(annotationContextMenuActions.loadTextAudio),
      tap(({ options }) => {
        // hide the page audio controls
        this.ttsStore.dispatch(
          ttsActions.togglePageTTSControls({ val: false }),
        );
        // Set this audio as active for the player
        this.ttsStore.setActiveAudio({
          keys: [options.cfi],
          playbackMode: TTSPlaybackMode.DEFAULT,
        });
      }),
      withLatestFrom(
        this.ttsStore.activeAudioItem$,
        this.ttsStore.currentView$,
        this.readerConfigStore.readSpeakerVoicePick$,
        this.ttsStore.voiceName$,
      ),
      tap(
        ([
          { options },
          activeAudioItems,
          currentView,
          readSpeakerVoicePick,
          voiceName,
        ]) => {
          // If there was a voice change, clear the audio dictionary to fetch new audio from readspeaker
          if (
            readSpeakerVoicePick &&
            voiceName !== activeAudioItems[0]?.chunkCollection?.voice
          ) {
            this.ttsStore.setAudioChunks({
              key: options.cfi,
              chunkCollection: undefined,
            });
            activeAudioItems = [];
          }

          // see whether we need to fetch this audio
          if (!activeAudioItems[0]?.chunkCollection) {
            this.fetchRawTextAudio$({
              text: options.text,
              cfi: options.cfi,
              // The highlighted text is not expected to know the page language,
              // so we'll just use the language of the 0th page in the view
              // (this works, assuming every page has the same language)
              pageLanguage:
                options.pageLanguage ?? currentView.pages[0].language,
            });
          }

          if (options.autoplay) {
            this.ttsStore.dispatch(ttsActions.play({ playbackPosition: 0 }));
          } else {
            this.seekPlaybackPosition$({ playbackPosition: 0 });
          }
        },
      ),
      logCatchError('loadHighlightedTextAudio$'),
    ),
  );

  /**
   * Logic to load in and play a chunks for an ePub page.
   */
  private readonly loadCurrentPageAudio$ = this.effect(
    (load$: Observable<{ playbackPosition?: number }>) =>
      load$.pipe(
        withLatestFrom(
          this.ttsStore.currentView$,
          this.readerConfigStore.interfaceMode$,
        ),
        tap(([{}, { pages, isFixedLayout }, interfaceMode]) => {
          // Set these audio keys as active for the player
          const keys = pages.map((page) => page.baseCFIPath) as string[];

          // determine playback mode
          // disable auto_flip for k5 or fixed layout
          const isK5 = interfaceMode === 'k5';
          const playbackMode =
            isFixedLayout ?? isK5
              ? TTSPlaybackMode.DEFAULT
              : TTSPlaybackMode.AUTO_FLIP;

          this.ttsStore.setActiveAudio({ keys, playbackMode });
        }),
        withLatestFrom(
          this.ttsStore.activeAudioItem$,
          this.ttsStore.voiceName$,
          this.readerConfigStore.readSpeakerVoicePick$,
        ),
        tap(
          ([
            [{ playbackPosition }, { pages, isFixedLayout }],
            activeAudioItems,
            voice,
            readSpeakerVoicePick,
          ]) => {
            // Ensure we have audio for each page that is
            // within the user's current view.
            pages.forEach((page, i) => {
              let annotationUtterances: AnnotationUtterance[] | undefined;

              // No utterance highlighting on fixed layout ePubs
              if (!isFixedLayout) {
                // Mutate the page by adding utterance markers.
                // We need to do this each time a new page is
                // loaded since the previous iframe gets destroyed.
                //
                // This utterance markup needs to happen before
                // fetchEpubChunk$ is called.
                annotationUtterances = this.ePubAnnotation?.processUtterances(
                  page.iframe?.contentDocument,
                  page.baseCFIPath,
                );
              }

              // check cache to detemine if we need to fetch
              let cachedAudio: Partial<EPubAudio> | undefined =
                activeAudioItems[i];
              const cachedAudioIsBad =
                cachedAudio &&
                (!cachedAudio.chunkCollection ||
                  cachedAudio.chunkCollection.chunks.length === 0);

              if (readSpeakerVoicePick) {
                // Clear audio cache if new voice
                const cachedAudioHasNewVoice =
                  cachedAudio?.chunkCollection &&
                  cachedAudio.chunkCollection.voice !== voice;
                if (cachedAudioHasNewVoice) {
                  this.ttsStore.setAudioChunks({
                    key: page.baseCFIPath as string,
                    chunkCollection: undefined,
                  });
                  cachedAudio = undefined;
                  playbackPosition = 0;
                }
              }

              // see whether we need to fetch this audio
              if (!cachedAudio || cachedAudioIsBad) {
                // indicate this is going to take a moment
                this.ttsStore.setIsLoading(true);

                if (annotationUtterances) {
                  // This is a page we haven't fetched yet.
                  const utterances: Utterance[] = annotationUtterances.map(
                    (utterance, j) => ({
                      ...utterance,
                      id: j,
                    }),
                  );
                  this.ttsStore.setAudioUtterances({
                    key: page.baseCFIPath as string,
                    utterances,
                  });
                }

                if (isFixedLayout) {
                  const text = this.getPageText(
                    page.iframe?.contentDocument as Document,
                  );
                  this.fetchEpubChunk$({
                    html:
                      text !== null
                        ? `<div data-tts-utterance="0">${text}</div>`
                        : null,
                    language: page.language,
                    baseCFIPath: page.baseCFIPath as string,
                  });
                } else {
                  this.fetchEpubChunk$({
                    html: page.iframe?.contentDocument?.body
                      .innerHTML as string,
                    language: page.language,
                    baseCFIPath: page.baseCFIPath as string,
                  });
                }
              }
            });
            this.ttsStore.dispatch(
              ttsActions.play({
                playbackPosition,
                context: TTSAudioContext.PAGE,
              }),
            );
          },
        ),
        logCatchError('loadCurrentPageAudio$'),
      ),
  );

  /**
   * Fetches audio for a string (raw text).
   * If the text is null, then this should raise
   * a playback error to the user for a failed
   * translation.
   */
  private readonly fetchRawTextAudio$ = this.effect(
    (
      options$: Observable<{
        text: string | null
        cfi: string
        pageLanguage: string
      }>,
    ) =>
      options$.pipe(
        withLatestFrom(
          this.readerConfigStore.readspeakerGender$,
          this.readerConfigStore.readSpeakerVoicePick$,
          this.ttsStore.voice$,
        ),
        concatMap(
          ([
            { text, cfi, pageLanguage },
            readspeakerGender,
            readSpeakerVoicePick,
            voice,
          ]) => {
            if (text === null) {
              // There was an error extracting the page text.
              // Respond as if the translation failed so an
              // error is surfaced in the UI.
              return of({ resp: { location: undefined }, cfi });
            }

            // else there is text to translate
            //
            // An empty string should respond with an
            // audio file stating "no audio" or something,
            // which is different than an error. So we want
            // to send empty strings to the backend for
            // translation.
            const request: any = {
              html: text,
              gender: readspeakerGender ?? this.TTS_VOICE_DEFAULT_GENDER,
              language: pageLanguage ?? this.TTS_VOICE_DEFAULT_LANG,
              type: TTSRawTextType.MP3,
            };
            if (readSpeakerVoicePick && voice) request.voice = voice.name;
            return this.playerApiClient.translateRawTextToSpeech(request).pipe(
              catchError(
                (
                  e: HttpErrorResponse,
                ): Observable<TTSRawTextTranslationResponse> => {
                  this.ga.event({
                    eventCategory: 'TTS',
                    eventAction: 'Readespeaker Error',
                    eventValue: e.status,
                  });

                  // there isn't any location
                  return of({ location: undefined });
                },
              ),
              map((resp) => ({ resp, cfi })),
              first(), // need this first here for the concatMap
            );
          },
        ),
        mergeMap(({ resp, cfi }) => {
          // In case the resp.location is undefined, the empty string will
          // trigger a MEDIA_ELEMENT_ERROR within the audio instance.
          const audio = this.audioElementService.create(resp.location ?? '');

          // Need to wait for metadata to be loaded so we can obtain the audio duration.
          // If the location isn't defined (or bad), then an error will get emitted and
          // set on audio.error.
          return merge(
            fromEvent(audio, 'loadedmetadata'),
            fromEvent(audio, 'error'),
          ).pipe(
            first(),
            map(() => ({ mp3Url: resp.location as string, audio, cfi })),
          );
        }),
        withLatestFrom(this.ttsStore.voiceName$),
        map(
          ([{ mp3Url, audio, cfi }, voiceName]): {
            chunkCollection: AudioChunkCollection
            cfi: string
          } => {
            // TODO: This audio.duration is not accurate
            // can be off by a factor 2-4 for small highlights
            const endTime = audio.duration * 1000;

            const audioCollectionChunks: AudioChunk[] = [];

            // If we have good audio then define the chunk,
            // else if the chunks array is empty, we can
            // possibly refetch and/or surface an error.
            if (!audio.error) {
              audioCollectionChunks.push({
                index: 0,
                context: TTSAudioContext.HIGHLIGHT,
                cfiPath: cfi,
                audio: undefined, // don't cache the audio file in the store, leverage the browser caching
                mp3Url,
                timing: [
                  {
                    utterances: [],
                    min: 0,
                    max: endTime,
                    dur: endTime,
                  },
                ],
                startTime: undefined,
                endTime: undefined,
              });
            }

            return {
              chunkCollection: {
                chunks: audioCollectionChunks,
                status: TTSJobStatus.COMPLETE,
                cfiPath: cfi,
                voice: voiceName,
              },
              cfi,
            };
          },
        ),
        tap(({ chunkCollection, cfi }) => {
          this.ttsStore.setAudioChunks({ chunkCollection, key: cfi });
        }),
        logCatchError('fetchRawTextAudio$'),
      ),
  );

  private readonly fetchEpubChunk$ = this.effect(
    (
      options$: Observable<{
        language: string
        html: string | null
        baseCFIPath: string
      }>,
    ) =>
      options$.pipe(
        withLatestFrom(
          this.readerConfigStore.readspeakerGender$,
          this.readerConfigStore.readSpeakerVoicePick$,
          this.ttsStore.voice$,
        ),
        concatMap(
          ([options, readspeakerGender, readSpeakerVoicePick, voice]) => {
            return this.chunkUtteranceHtmlIntoAudioLinks(options.html, {
              gender: readspeakerGender ?? this.TTS_VOICE_DEFAULT_GENDER,
              language: options.language ?? this.TTS_VOICE_DEFAULT_LANG,
              voice: readSpeakerVoicePick ? voice : null,
            }).pipe(
              catchError(
                (
                  e: HttpErrorResponse,
                ): Observable<PartialTTSChunkCollection> => {
                  // We'll just say there aren't any chunks, and that can be
                  // surfaced as an error elsewhere in the app.
                  this.ga.event({
                    eventCategory: 'TTS',
                    eventAction: 'Error',
                    eventValue: e.status,
                  });

                  return of({
                    chunks: [],
                    status: TTSJobStatus.NOT_FOUND,
                  });
                },
              ),
              map((ttsChunkCollection) => ({
                ttsChunkCollection,
                baseCFIPath: options.baseCFIPath,
                voice,
              })),
            );
          },
        ),
        map(
          ({
            ttsChunkCollection,
            baseCFIPath,
            voice,
          }): {
            chunkCollection: AudioChunkCollection
            baseCFIPath: string
          } => {
            const audioCollectionChunks: AudioChunk[] =
              ttsChunkCollection.chunks
                .sort((a: TTSChunk, b: TTSChunk) => a.chunkIndex - b.chunkIndex)
                .map((chunk: TTSChunk): AudioChunk => {
                  // The timing could come back empty if the
                  // result is a "could not find the text to read"
                  // response.
                  const timing =
                    chunk.timing.length === 0
                      ? [{ dur: 1, min: 0, max: 1, utterances: [] }]
                      : chunk.timing;
                  return {
                    context: TTSAudioContext.PAGE,
                    index: chunk.chunkIndex,
                    cfiPath: baseCFIPath,
                    mp3Url: chunk.mp3,
                    timing,
                    audio: undefined,
                    startTime: undefined,
                    endTime: undefined,
                  };
                });

            return {
              chunkCollection: {
                chunks: audioCollectionChunks,
                status: ttsChunkCollection.status,
                cfiPath: baseCFIPath,
                voice: voice?.name,
              },
              baseCFIPath,
            };
          },
        ),
        tap(({ chunkCollection, baseCFIPath }) => {
          this.ttsStore.setAudioChunks({ chunkCollection, key: baseCFIPath });
        }),
        logCatchError('fetchEpubChunk$'),
      ),
  );

  private readonly resumePlayback$ = this.effect(() =>
    this.ttsActions$.pipe(
      ofType(ttsActions.play),
      this.waitForPlaybackToPause({ silenceBeepSound: true }),
      withLatestFrom(this.ttsStore.currentView$),
      switchMap(([[action, currentProgress], currentView]) => {
        // resume from the page progress, if necessary
        const isPlayingPage = action.context === TTSAudioContext.PAGE;
        const progress = isPlayingPage ? currentView.progress : currentProgress;

        // wait for chunks to come in async
        return this.ttsStore.activeAudio$.pipe(
          filter((activeAudio) => activeAudio?.chunkCollection !== undefined),
          map((activeAudio) => {
            const activeChunk = progress?.metadata?.activeChunk;
            const noChunks = activeAudio?.chunkCollection.chunks.length === 0;
            const isJobComplete =
              activeAudio?.chunkCollection.status === TTSJobStatus.COMPLETE;
            const isJobStalled =
              activeAudio?.chunkCollection.status === TTSJobStatus.STALLED;
            const isJobPending =
              activeAudio?.chunkCollection.status === TTSJobStatus.PENDING;
            const isJobFailed = noChunks && !isJobPending;
            const isLastChunk = activeChunk
              ? activeChunk.index ===
                (activeAudio?.chunkCollection.chunks as AudioChunk[]).length - 1
              : false;

            let playbackPosition: number;
            if (isLastChunk && isJobComplete && activeChunk?.audio?.ended) {
              // We are at the end of the audio, so this is a
              // "replay" request to play from the beginning.
              playbackPosition = 0;
            } else {
              // Use the requested playback position to "skip", else use
              // the playback in the progress to "resume" playing.
              playbackPosition =
                action.playbackPosition ?? progress?.playbackPosition ?? 0;
            }

            const chunk = this.findChunk(
              playbackPosition,
              activeAudio?.chunkCollection.chunks as AudioChunk[],
            );
            return {
              chunk,
              activeAudio,
              playbackPosition,
              isJobFailed,
              isJobStalled,
            };
          }),
          filter(({ chunk, isJobFailed, isJobStalled }) => {
            const foundChunk = chunk !== undefined;
            return foundChunk || isJobFailed || isJobStalled;
          }),
          first(),
          tap(({ chunk, playbackPosition, isJobStalled }) => {
            // Either we have a chunk or the translation
            // failed and there are no chunks in the
            // active audio.
            if (chunk) {
              // Pause interactions before chunk is played
              // so any utterance scrolling will not get
              // interrupted.
              this.pauseOnPageInteraction$();
              this.playChunk$({ chunk, playbackPosition });
            } else {
              // we gave up loading the chunk
              this.ttsStore.setIsLoading(false);

              if (isJobStalled && progress?.metadata) {
                // Spew the chunks.
                // Forget the chunks so that
                // 1. an error is surfaced
                // 2. the next play triggers re-translation
                this.ttsStore.setAudioChunks({
                  chunkCollection: {
                    chunks: [],
                    status: TTSJobStatus.PENDING,
                    cfiPath: undefined,
                  },
                  key: progress.metadata.activeChunk.cfiPath,
                });

                // no more progress to report
                this.ttsStore.dispatch(ttsActions.stopProgressReporting());
              }
            }
          }),
        );
      }),
      logCatchError('resumePlayback$'),
    ),
  );

  /**
   * Seek to a position in the active audio playback without playing.
   */
  private readonly seekPlaybackPosition$ = this.effect(
    (seek$: Observable<{ playbackPosition: number }>) =>
      seek$.pipe(
        this.waitForPlaybackToPause({ silenceBeepSound: true }),
        tap(([{ playbackPosition }]) => {
          this.ttsStore.setAudioProgress({
            isPlaying: false,
            playbackPosition,
          });
        }),
        logCatchError('seekPlaybackPosition$'),
      ),
  );

  private readonly playChunk$ = this.effect(
    (play$: Observable<{ chunk: AudioChunk, playbackPosition: number }>) =>
      play$.pipe(
        withLatestFrom(this.ttsStore.audioSetting$),
        switchMap(([{ playbackPosition, chunk }, audioSettings]) => {
          // play the audio
          return this.playFrom(playbackPosition, chunk, audioSettings).pipe(
            catchError((e) => {
              // If there was a playback error, it will
              // eventually surface on a progress event.
              // So let's just keep things moving along,
              // nothing to see here.
              console.error(e);
              return of(chunk);
            }),
          );
        }),
        tap((chunk) => {
          // indicate chunk is playable
          this.ttsStore.setIsLoading(false);

          // Setup handler for when the chunk playback ends.
          // Store the end subscription handler so we can unsubscribe.
          this.chunkEnd$(chunk);
        }),
        switchMap((chunk) => {
          // Report playback progress periodically.
          // Cancel reporting if a pause action is heard.
          return interval(this.PROGRESS_INTEVAL_MS).pipe(
            takeUntil(
              this.ttsActions$.pipe(ofType(ttsActions.stopProgressReporting)),
            ),
            tap(() => this.updatePlaybackProgress$({ activeChunk: chunk })),
          );
        }),
        logCatchError('playChunk$'),
      ),
  );

  private readonly pausePlayback$ = this.effect(() =>
    this.ttsActions$.pipe(
      ofType(ttsActions.pause),
      withLatestFrom(
        this.ttsStore.activeAudioPlaybackProgress$,
        this.ttsStore.audioSetting$,
      ),
      // Pause actions need to be applied to the audio of
      // active chunks. Here we filter out pause actions
      // that occur when the audio is not defined.
      filter(([_, progress]) => !!progress?.metadata?.activeChunk),
      tap(([{ silenceBeepSound }, progress, audioSettings]) => {
        const activeChunk = progress?.metadata?.activeChunk as AudioChunk;

        // No need to perform the pause if the audio has ended
        // or is already paused
        if (
          !(activeChunk.audio as HTMLAudioElement).ended &&
          !(activeChunk.audio as HTMLAudioElement).paused
        ) {
          (activeChunk.audio as HTMLAudioElement).pause();

          if (!silenceBeepSound) {
            const beepSound = this.audioElementService.create(
              `assets/sounds/beep-down.mp3?v=${environment.buildTimestamp}`,
            );
            // this sound is pretty loud, let's scale it down
            beepSound.volume = audioSettings.volume * 0.5;
            beepSound.play();
          }
        }

        // update playback progress for accuracy
        this.updatePlaybackProgress$({ activeChunk });

        // no need to keep reporting progress
        this.ttsStore.dispatch(ttsActions.stopProgressReporting());

        // turn off scroll-pause listener
        this.ttsStore.dispatch(ttsActions.disableScrollPauser());
      }),
      logCatchError('pausePlayback$'),
    ),
  );

  private readonly pauseOnPageInteraction$ = this.effect(
    (enable$: Observable<void>) =>
      enable$.pipe(
        withLatestFrom(this.ttsStore.currentView$),
        switchMap(([_, currentView]) => {
          return fromEvent(
            currentView.pages[0].iframe?.contentDocument as Document,
            'scroll',
          ).pipe(
            first(),
            takeUntil(
              this.ttsActions$.pipe(ofType(ttsActions.disableScrollPauser)),
            ),
            tap((event) => {
              // Without some kind of manual change detection here (ngZone.run),
              // the pause/play button in the audio controls component will not
              // reflect the pause state until some kind of interaction happens.
              // Which is surprising since rxjs know about the event, but the
              // event is coming from within an iframe...
              this.ngZone.run(() =>
                this.ttsStore.dispatch(ttsActions.pause({})),
              );
            }),
          );
        }),
        logCatchError('pauseOnPageInteraction$'),
      ),
  );

  /**
   * Update progress so the audio control UI can refect playback state.
   */
  private readonly updatePlaybackProgress$ = this.effect(
    (update$: Observable<{ activeChunk: AudioChunk }>) =>
      update$.pipe(
        withLatestFrom(this.ttsStore.activeAudio$),
        tap(([{ activeChunk }, activeAudio]) => {
          const chunkElapsed =
            (activeChunk.audio as HTMLAudioElement).currentTime * 1000;
          const playbackPosition =
            (activeChunk.startTime as number) + chunkElapsed;
          // determine utterances
          const activeUtterances = this.findUtterances(
            playbackPosition,
            activeChunk,
            activeAudio?.utterances as Utterance[],
          ) as Utterance[];

          // update progess
          const progress: ChunkedAudioProgress = {
            playbackPosition,
            isPlaying: !(activeChunk.audio as HTMLAudioElement).paused,
            error: (activeChunk.audio as HTMLAudioElement).error as MediaError,
            readyState: (activeChunk.audio as HTMLAudioElement).readyState,
            utteranceIds: activeUtterances?.map((u) => u.id),
            metadata: { activeUtterances, activeChunk },
          };
          this.ttsStore.setAudioProgress(progress);
        }),
        logCatchError('updatePlaybackProgress$'),
      ),
  );

  private readonly highlightUtterance$ = this.effect(() =>
    this.ttsStore.activeAudioPlaybackProgress$.pipe(
      debounceTime(50),
      withLatestFrom(this.ttsStore.currentView$),
      filter(
        ([progress]) =>
          progress?.utteranceIds !== undefined &&
          progress.isPlaying &&
          progress.metadata?.activeChunk.context === TTSAudioContext.PAGE,
      ),
      // Add a new filter for each validation
      filter(([progress, currentView]: [ChunkedAudioProgress, CurrentView]) => {
        // Validate toggle box DFA when global readspeaker
        const isInvalid = this.isUtteranceContentInToggleBox(
          progress,
          currentView,
        );
        if (isInvalid) {
          this.goToNextUtterance$({ progress, currentView });
        }
        return !isInvalid;
      }),
      tap(([progress, currentView]) => {
        const activeUtteranceId = progress.utteranceIds?.[0];
        // highlightUtterance will mutate the DOM
        const el = this.highlightUtterance(
          activeUtteranceId,
          currentView.pages[0].iframe as HTMLIFrameElement,
        );
        if (el) {
          const scrollDistance = getScrollDistance(el);
          const canScrollUp =
            currentView.pages[0].iframe?.contentWindow?.scrollY !== 0;
          const needScrollUp = scrollDistance < 0;
          const needScrollDown = scrollDistance > 0;
          if ((needScrollUp && canScrollUp) || needScrollDown) {
            this.scrollWithoutPause$(el);
          }
        }
      }),
      logCatchError('highlightUtterance$'),
    ),
  );

  private readonly goToNextUtterance$ = this.effect(
    (
      progressCurrentView$: Observable<{
        progress: ChunkedAudioProgress
        currentView: CurrentView
      }>,
    ) =>
      progressCurrentView$.pipe(
        withLatestFrom(this.ttsStore.activeAudio$),
        tap(([{ progress }, activeAudio]) => {
          const audioChunks = activeAudio?.chunkCollection.chunks;

          const currentUtternaceId = Number(progress.utteranceIds?.[0]);
          const nextUtteranceId = currentUtternaceId + 1;

          // Validate chunks
          let currentUtteranceChunk: TTSUtteranceTiming;
          let nextUtteranceChunk!: TTSUtteranceTiming;
          const currentUtteranceAudioChunk = audioChunks?.find((audiochunk) => {
            const utteranceChunk = audiochunk.timing.find(
              (timing) => timing.utterances[0] === currentUtternaceId,
            );
            if (utteranceChunk) {
              currentUtteranceChunk = utteranceChunk;
            }
            return !!utteranceChunk;
          });
          const nextUtteranceAudioChunk = audioChunks?.find((audiochunk) => {
            const utteranceChunk = audiochunk.timing.find(
              (tim) => tim.utterances[0] === nextUtteranceId,
            );
            if (utteranceChunk) {
              nextUtteranceChunk = utteranceChunk;
            }
            return !!utteranceChunk;
          });
          const currentUtteranceChunkIndex = currentUtteranceAudioChunk?.index;

          if (!nextUtteranceAudioChunk || !currentUtteranceAudioChunk) {
            this.ttsStore.dispatch(
              ttsActions.pause({ silenceBeepSound: true }),
            );
            return;
          }

          if (
            currentUtteranceAudioChunk.index === nextUtteranceAudioChunk.index
          ) {
            const accPlayback =
              nextUtteranceChunk.min +
              (nextUtteranceAudioChunk.startTime as number);
            this.ttsStore.dispatch(
              ttsActions.play({ playbackPosition: accPlayback + 1 }),
            );
          } else {
            // Go to the end
            this.ttsStore.dispatch(
              ttsActions.play({
                playbackPosition: currentUtteranceAudioChunk.endTime,
              }),
            );
          }
        }),
        logCatchError('goToNextUtterance$'),
      ),
  );

  /**
   * Scroll without pausing the audio.
   * Unfortunately, there isn't a good way to tell if a scroll event is
   * programmatically or user generated.
   * https://stackoverflow.com/a/7210072
   * So here we "open a short window" by disabling the scroll listener, scroll,
   * wait for a bit, the re-enable it.
   */
  private readonly scrollWithoutPause$ = this.effect(
    (scroll$: Observable<HTMLElement>) =>
      scroll$.pipe(
        exhaustMap((el) => {
          // Scrolling the ePub will move focus.
          // Remember which element has focus so
          // we can return focus once the scrolling
          // is complete.
          let originallyFocusedEl: HTMLElement;
          if (
            document.activeElement instanceof HTMLElement &&
            document.activeElement !== document.body &&
            typeof document.activeElement.focus === 'function'
          ) {
            originallyFocusedEl = document.activeElement;
          }

          // turn off scroll-pause listener
          this.ttsStore.dispatch(ttsActions.disableScrollPauser());

          // initiate the scroll
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });

          // Wait until the scroll events happen.
          // NOTE: We want to give ample time for the scroll,
          // otherwise the last scroll event will pause the audio!
          return timer(getTimeNeededToSmoothScroll(el) + 250).pipe(
            map(() => ({ originallyFocusedEl })),
          );
        }),
        tap(({ originallyFocusedEl }) => {
          // return focus to the originally focused element
          if (originallyFocusedEl) {
            originallyFocusedEl.focus();
          }
          // turn the scroll-pause listener back on
          this.pauseOnPageInteraction$();
        }),
        logCatchError('scrollWithoutPause$'),
      ),
  );

  /**
   * Logic performed when a chunk playback comes to an end.
   */
  private readonly chunkEnd$ = this.effect((end$: Observable<AudioChunk>) =>
    end$.pipe(
      // use a switch map here so previous subscriptions get cancelled only when the current audio chunk finished
      switchMap((chunk) =>
        fromEvent(chunk.audio as HTMLAudioElement, 'ended').pipe(
          map(() => chunk),
        ),
      ),
      withLatestFrom(
        this.ttsStore.activeAudio$,
        this.ttsStore.playbackMode$,
        this.navigationStore.maxIndex$,
        this.navigationStore.index$,
      ),
      tap(
        ([
          chunk,
          currentAudio,
          playbackMode,
          lastPageIndex,
          currentPageIndex,
        ]) => {
          const chunks = currentAudio?.chunkCollection.chunks;
          const isJobComplete =
            currentAudio?.chunkCollection.status === TTSJobStatus.COMPLETE;
          const isLastChunk =
            chunk.index === (chunks as AudioChunk[]).length - 1;
          const isOnLastPage = currentPageIndex === lastPageIndex;
          if (!isLastChunk) {
            // Play at a position a tick ahead of the endTime to trigger the next chunk.
            this.ttsStore.dispatch(
              ttsActions.play({
                playbackPosition: (chunk.endTime as number) + 1,
              }),
            );
          } else if (isLastChunk && !isJobComplete) {
            // We still have audio to play, but the user will need to wait.
            // This case is likely readspeaker being bogged down.
            // indicate to the user that they need to wait
            this.ttsStore.setIsLoading(true);
            // there are more to be played
            this.ttsStore.dispatch(
              ttsActions.play({
                playbackPosition: (chunk.endTime as number) + 1,
              }),
            );
          } else if (isLastChunk && isJobComplete) {
            // We played the whole audio!
            // See if we need to move to the next page.
            if (playbackMode === TTSPlaybackMode.AUTO_FLIP && !isOnLastPage) {
              this.autoPlayNextPage$();
            } else {
              // we are at the end of the playback
              this.ttsStore.dispatch(ttsActions.stopProgressReporting());
              // reset the playback to the beginning
              this.seekPlaybackPosition$({ playbackPosition: 0 });
            }
          }
        },
      ),
      logCatchError('chunkEnd$'),
    ),
  );

  private readonly autoPlayNextPage$ = this.effect((play$: Observable<void>) =>
    play$.pipe(
      tap(() => {
        // Turn off the audio controls.
        // Closing the audio controls will pause the audio, so
        // we should close them before the next page loads.
        this.ttsStore.setShowDocTTSControls(false);
        // Go to the next page.
        this.navigationStore.dispatch(
          navigationActions.navigateByStep({ step: 1 }),
        );
        // Clear the active audio. We expect the selector currentPage$ will
        // emit with a truthy CFI value when the iframe has been rendered.
        this.ttsStore.setCurrentView({ pages: [], isFixedLayout: undefined });
        this.ttsStore.setActiveAudio({
          keys: undefined,
          playbackMode: undefined,
        });
      }),
      this.util.tapGaEvent({
        eventCategory: 'TTS',
        eventAction: 'Automatic Page Navigation',
      }),
      switchMap(() => {
        // wait for the page to load
        return this.ttsStore.currentView$.pipe(
          filter((v) => v.pages[0]?.baseCFIPath !== undefined),
          first(),
          withLatestFrom(this.ttsStore.disabled$),
          tap(([currentView, disabled]) => {
            if (disabled) {
              this.pageUnreadable$(currentView);
            } else {
              this.ttsStore.dispatch(
                ttsActions.togglePageTTSControls({ val: true }),
              );
            }
          }),
        );
      }),
      logCatchError('autoPlayNextPage$'),
    ),
  );

  private readonly pageUnreadable$ = this.effect(
    (notplayable$: Observable<CurrentView>) => {
      const { TTS_VOICE_DEFAULT_LANG, TTS_VOICE_DEFAULT_GENDER } = this;
      const gender$ = this.readerConfigStore.readspeakerGender$.pipe(
        map((gender) => gender ?? TTS_VOICE_DEFAULT_GENDER),
      );

      return notplayable$.pipe(
        map(({ pages }) => pages?.[0].language ?? TTS_VOICE_DEFAULT_LANG),
        withLatestFrom(gender$),
        switchMap(([language, gender]) => {
          const text = this.translateService.instant('readspeaker.cant_read');
          const type = TTSRawTextType.MP3;

          return this.playerApiClient.translateRawTextToSpeech({
            html: text,
            gender,
            language,
            type,
          });
        }),
        filter((response) => Boolean(response)),
        tap(({ location }) => {
          const audio = this.audioElementService.create(location as string);
          void audio.play();
        }),
        logCatchError('pageUnreadable$'),
      );
    },
  );

  private readonly updateVolume$ = this.effect(() =>
    this.ttsStore.actions$.pipe(
      ofType(ttsActions.updateVolume),
      withLatestFrom(this.ttsStore.activeAudioPlaybackProgress$),
      tap(([{ val }, progress]) => {
        const activeChunk = progress?.metadata?.activeChunk;
        if (activeChunk) {
          (activeChunk.audio as HTMLAudioElement).volume = val;
        }
        this.ttsStore.setVolume(val);
      }),
      logCatchError('updateVolume$'),
    ),
  );

  private readonly updatePlaybackRate$ = this.effect(() =>
    this.ttsStore.actions$.pipe(
      ofType(ttsActions.updatePlaybackRate),
      withLatestFrom(this.ttsStore.activeAudioPlaybackProgress$),
      tap(([{ val }, progress]) => {
        const activeChunk = progress?.metadata?.activeChunk;
        if (activeChunk) {
          (activeChunk.audio as HTMLAudioElement).playbackRate = val;
        }
        this.ttsStore.setPlaybackRate(val);
      }),
      logCatchError('updatePlaybackRate$'),
    ),
  );

  private readonly handlePlaybackError$ = this.effect(() =>
    this.ttsStore.activeAudioPlaybackProgress$.pipe(
      filter((progress) => progress !== undefined),
      tap((progress: ChunkedAudioProgress) => {
        if (progress.error) {
          console.error('Playback error!', progress.error);
          this.ttsStore.dispatch(ttsActions.stopProgressReporting());
          // Spew the chunks.
          // Forget this chunkCollection so the next play event
          // causes the text to be re-translated.
          this.ttsStore.setAudioChunks({
            chunkCollection: undefined,
            key: progress.metadata?.activeChunk.cfiPath as string,
          });
        }
      }),
      scan(
        ({ serverPlaybackErrors }, progress) => {
          let serverErrorsIncremented = false;
          if (progress.error) {
            // certain mediaError codes put the blame on the server
            // https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code
            if ([2, 3, 4].includes(progress.error.code)) {
              serverPlaybackErrors += 1;
              serverErrorsIncremented = true;
            }
          } else {
            // If we got here there is no playback error, but we need
            // to verify that the media is in a "good" state before
            // resetting the count so we avoid infinite retry loops.
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
            if ([2, 3, 4].includes(progress.readyState as number)) {
              serverPlaybackErrors = 0;
            }
          }
          return { serverPlaybackErrors, progress, serverErrorsIncremented };
        },
        {
          serverPlaybackErrors: 0,
          serverErrorsIncremented: false,
          progress: undefined,
        },
      ),
      // Only retranslate on the first playback error since the last time we had "good" media.
      // Note, we are only interested in retrying on the first error *increment*,
      // hence below we check the incrementing condition.
      filter(
        ({ serverPlaybackErrors, serverErrorsIncremented }) =>
          serverErrorsIncremented && serverPlaybackErrors === 1,
      ),
      // retranslate the chunkCollection we cleared above
      switchMap(({ progress }) => {
        // The setAudioChunks() called above won't happen synchronously,
        // so here we must wait until the chunkCollection is cleared
        // before triggering the audio page load.
        const cfiPath = progress.metadata?.activeChunk.cfiPath;
        return this.ttsStore.activeAudioItem$.pipe(
          map((activeAudioItems) =>
            activeAudioItems.find(
              (item) => item?.chunkCollection?.cfiPath === cfiPath,
            ),
          ),
          filter((audioItem) => audioItem?.chunkCollection === undefined),
          first(),
          this.waitForPlaybackToPause({ silenceBeepSound: true }),
          tap(() => {
            this.loadCurrentPageAudio$({});
          }),
          tap(() => this.ttsStore.setShowDocTTSControls(true)),
        );
      }),
      logCatchError('handlePlaybackError$'),
    ),
  );

  private chunkUtteranceHtmlIntoAudioLinks(
    htmlString: string | null,
    options: TTSOptions,
  ): Observable<PartialTTSChunkCollection> {
    if (htmlString === null) {
      return throwError('Cannot chunk null into audio');
    }
    // else
    const parsedContent = this.ttsCleanContent(htmlString);
    const ttsRequest: any = {
      content: parsedContent,
      language: options.language,
      gender: options?.gender,
      mathFlag: TTSMathFlag.FALSE,
    };
    if (options.voice) ttsRequest.voice = options.voice.name;
    const initialChunk$ = this.playerApiClient
      .createTextToSpeechJob(ttsRequest)
      .pipe(
        map((resp: TTSChunk | null) => ({
          chunks: resp === null ? [] : [resp],
          status: TTSJobStatus.PENDING,
        })),
        tap(({ chunks }) =>
          this.reportActionToNR$({
            name: 'tts-job-created',
            params: {
              chunkCount: chunks[0]?.metadata?.chunkCount,
            },
          }),
        ),
        // This pipe needs to be shared because
        // the output will be used as the source
        // for remainingChunk$ below and we don't
        // want the api to be called twice.
        share(),
        // Or kill requests if new voice is selected
        takeUntil(this.ttsActions$.pipe(ofType(ttsActions.resetVoice))),
      );

    const remainingChunk$ = initialChunk$.pipe(
      mergeMap((initialChunk: PartialTTSChunkCollection) => {
        const key = initialChunk.chunks[0]?.metadata.requestCacheKey;
        // The repeatWhen will keep polling the status endpoint,
        // and takeWhile will kill it when the status is no longer
        // "pending".
        // We want a delay between repeats to prevent hammering
        // the status endpoint. Typically the first chunk will buy
        // us 10s of seconds, so we can afford to wait.
        return this.playerApiClient.fetchTextToSpeechJob(key as string).pipe(
          first(),
          repeatWhen((completed) =>
            completed.pipe(delay(this.SPEECH_JOB_POLLING_INTERVAL_MS)),
          ),
          scan(
            (
              history: {
                collection: TTSChunkCollection
                sameResultsCount: number
              },
              collection,
            ) => {
              // Track the number of queries where no job progress has been made
              // so we can stop polling if the job potentially "stalls".
              const sameResults =
                history.collection?.processedCount ===
                collection.processedCount;
              const sameResultsCount = sameResults
                ? history.sameResultsCount + 1
                : 0;
              return {
                collection,
                sameResultsCount,
              };
            },
            { collection: undefined, sameResultsCount: 0 },
          ),
          map(({ collection, sameResultsCount }) => {
            const jobIsStalled =
              sameResultsCount * this.SPEECH_JOB_POLLING_INTERVAL_MS >=
              this.SPEECH_JOB_STALLED_TIMEOUT_MS;
            return {
              collection: {
                ...collection,
                status: jobIsStalled ? TTSJobStatus.STALLED : collection.status,
              },
              sameResultsCount,
            };
          }),
          takeWhile(({ collection, sameResultsCount }) => {
            const jobIsPending = collection.status === TTSJobStatus.PENDING;
            const jobIsStalled = collection.status === TTSJobStatus.STALLED;
            if (jobIsStalled) {
              console.error(
                'Giving up on obtaining more audio chunks, the job has been deemed stalled!',
              );
              this.reportErrorToNR$({
                exception: 'tts-job-stalled',
                params: {
                  chunkCount: collection?.chunkCount,
                  processedCount: collection?.processedCount,
                  sameResultsCount,
                  timeout: this.SPEECH_JOB_STALLED_TIMEOUT_MS,
                },
              });
            }
            return jobIsPending && !jobIsStalled;

            // NOTE: This "true" below allows takeWhile to emit first item that didn't pass
            // the predicate. We want this to allow emission of the last "completed" message.
          }, true),
          // Or kill requests if new voice is selected
          takeUntil(this.ttsActions$.pipe(ofType(ttsActions.resetVoice))),
          map(({ collection }) => collection),
        );
      }),
    );

    // rxjs merge and angular workaround
    // https://github.com/ReactiveX/rxjs/issues/5064
    return merge(initialChunk$, remainingChunk$);
  }

  private ttsCleanContent(content: string): string {
    // note that this function does NOT deal with the rendered DOM.
    const contentEl = document.createElement('div');
    contentEl.innerHTML = content;
    let blackListedSelectors = [
      '.teacher',
      '.mhe-inline-credit',
      '.mhe-tts-ignore',
    ];
    if (!this.isMathInRSEnabled) {
      blackListedSelectors = [
        ...blackListedSelectors,
        'script[type="math/mml"]',
        '.MathJax_Preview',
        '.MathJax_SVG',
      ];
    }
    const blackListedSelectorsList = blackListedSelectors.join(',');
    contentEl
      .querySelectorAll(blackListedSelectorsList)
      .forEach((el) => el.remove());
    contentEl
      .querySelectorAll('dfn')
      .forEach((el) => el.removeAttribute('title'));

    /**
     * https://jira.mheducation.com/browse/EPR-8118
     * When there are elements inside a <figure> tag other than a <figcaption> these get removed when passed
     * through the HTML purifier.  As a fix, convert:  <figure>,<figcaption> -> <span>.
     * This doesn't change the original DOM, only the markup sent to the TTS endpoint.
     */
    contentEl.querySelectorAll('figure').forEach((figureEl) => {
      const children = figureEl.children;

      // replace the <figure> with a <span>
      const replaceFigureEl = document.createElement('span');
      for (let i = 0; i < children.length; i++) {
        const child = children[i];

        // replace the <figcaption> with a <span>
        if (child.tagName === 'FIGCAPTION') {
          const newChild = document.createElement('SPAN');

          // we only care about the data-tts-utterance attribute
          if (child.hasAttribute('data-tts-utterance')) {
            newChild.setAttribute(
              'data-tts-utterance',
              child.getAttribute('data-tts-utterance') as string,
            );
          }

          // clone over the children of the <figcaption>
          const newChildren = newChild.children;
          for (let j = 0; j < newChildren.length; j++) {
            newChild.appendChild(newChildren[j].cloneNode(true));
          }
          replaceFigureEl.appendChild(newChild);
        } else {
          replaceFigureEl.appendChild(child.cloneNode(true));
        }
      }
      figureEl.parentNode?.replaceChild(replaceFigureEl, figureEl);
    });
    return contentEl.innerHTML;
  }

  private findUtterances(
    playbackPosition: number,
    chunk: AudioChunk,
    utterances: Utterance[],
  ): Utterance[] | undefined {
    const utteranceBounds = chunk.timing.find((bounds) => {
      const timingStart = (chunk.startTime as number) + bounds.min;
      const timingEnd = (chunk.startTime as number) + bounds.max;
      return playbackPosition >= timingStart && playbackPosition <= timingEnd;
    });

    if (utterances && utteranceBounds) {
      return utterances.filter((u, i) =>
        utteranceBounds.utterances.includes(i),
      );
    } else {
      return undefined;
    }
  }

  private findChunk(
    playbackPosition: number,
    chunks: AudioChunk[],
  ): AudioChunk | undefined {
    return chunks.find((chunk) => {
      return (
        playbackPosition >= (chunk.startTime as number) &&
        playbackPosition < (chunk.endTime as number)
      );
    });
  }

  /**
   * Plays audio from AudioChunk from playbackPosition. Will grab
   * the audio lazily, when it is need to be played.
   */
  private playFrom(
    playbackPosition: number,
    chunk: AudioChunk,
    audioSettings: TTSAudioSettings,
  ): Observable<AudioChunk> {
    const chunkTime = playbackPosition - (chunk.startTime as number);

    if (!chunk.audio) {
      // download the mp3
      chunk.audio = this.audioElementService.create(chunk.mp3Url);
      this.ttsStore.cacheAudio(chunk);
    }

    // skip to the right time within the chunk
    chunk.audio.currentTime = chunkTime / 1000;
    chunk.audio.volume = audioSettings.volume;
    chunk.audio.playbackRate = audioSettings.playbackRate;

    // play it
    const playPromise = chunk.audio.play();

    // Announce this is the active chunk immediately
    // after initiating the audio play.
    // This is important so that the next (play) action
    // that gets emitted can act on this chunk, and
    // possibly cancel the playback before it begins.
    // NOTE: This progress event could trigger an utterance
    // scroll event.
    this.updatePlaybackProgress$({ activeChunk: chunk });

    // Playing audio is async
    return from(playPromise).pipe(
      first(),
      map((evt) => chunk),
    );
  }

  public highlightUtterance(
    utteranceId: number | string | undefined,
    iframe: HTMLIFrameElement,
  ): HTMLElement | undefined {
    return this.ePubAnnotation?.setActiveUtterance(
      utteranceId,
      iframe.contentDocument,
    );
  }

  /**
   * Gets the string text from an html doc.
   * A null value means the parsing of the page
   * failed. An empty string means the page has
   * no text.
   */
  private getPageText(doc: Document): string | null {
    try {
      const range = document.createRange();
      range.selectNodeContents(doc.body);
      return this.ePubAnnotation.textForRange(range, doc);
    } catch (e) {
      // put something in the console for devs
      console.error(new Error('getPageText failed'));
      return null;
    }
  }

  /**
   * Dispatches a pause action, then wait for the pause
   * to be reflected in the progress before emitting.
   * This will include the source event as well as
   * the audio progress on the output.
   */
  private waitForPlaybackToPause<T>({
    silenceBeepSound,
  }: {
    silenceBeepSound: boolean
  }) {
    return (source$: Observable<T>): Observable<[T, ChunkedAudioProgress]> => {
      return source$.pipe(
        tap(() =>
          this.ttsStore.dispatch(ttsActions.pause({ silenceBeepSound })),
        ),
        switchMap((action) => {
          // Wait for the pause to get applied to progress.
          // There won't be progress if it's the first audio
          // played after reader is loaded.
          return this.ttsStore.activeAudioPlaybackProgress$.pipe(
            filter((progress) => !progress || !progress?.isPlaying),
            first(),
            map((progress: ChunkedAudioProgress): [T, ChunkedAudioProgress] => [
              action,
              progress,
            ]),
          );
        }),
      );
    };
  }

  private readonly reportErrorToNR$ = this.effect(
    (
      error$: Observable<{
        exception: string | Error
        params?: Record<string, string | number>
      }>,
    ) =>
      error$.pipe(
        withLatestFrom(
          this.readerConfigStore.contextId$,
          this.navigationStore.index$,
        ),
        tap(([{ exception, params }, contextId, pageIndex]) => {
          this.newRelicService.noticeError(exception, {
            ...params,
            contextId,
            pageIndex,
          });
        }),
      ),
  );

  private readonly reportActionToNR$ = this.effect(
    (
      action$: Observable<{
        name: string
        params?: Record<string, string | number>
      }>,
    ) =>
      action$.pipe(
        withLatestFrom(
          this.readerConfigStore.contextId$,
          this.navigationStore.index$,
        ),
        tap(([{ name, params }, contextId, pageIndex]) => {
          this.newRelicService.addPageAction(name, {
            ...params,
            contextId,
            pageIndex,
          });
        }),
      ),
  );

  private isUtteranceContentInToggleBox(
    progress: ChunkedAudioProgress,
    currentView: CurrentView,
  ): boolean {
    const activeUtteranceId = progress.utteranceIds?.[0];
    const currentUtteranceIframeElement =
      currentView.pages[0]?.iframe?.contentDocument?.querySelector(
        '[data-tts-utterance="' + activeUtteranceId + '"]',
      );

    const contentElement = handleToggleBoxOnParent(
      currentUtteranceIframeElement as HTMLElement,
      ToggleBoxActions.CHECK,
    ) as DetailElementAncestor;

    if (!contentElement) return false;

    return (
      contentElement.isInsideADetail &&
      !contentElement.detailElement?.attributes.getNamedItem('open') &&
      !(currentUtteranceIframeElement?.tagName.toLowerCase() === 'summary')
    );
  }
}
