import { Pipe, PipeTransform } from '@angular/core';
import { SearchResult } from '@mhe/reader/models';

@Pipe({
  name: 'searchResult',
})
export class SearchResultPipe implements PipeTransform {
  transform(searchResult: SearchResult, searchTerm: string): string {
    return highlightSearchResult(searchResult, searchTerm);
  }
}

function highlightSearchResult(searchResult: SearchResult, phrase: string): string {
  let text = searchResult.text;
  const occurrence = searchResult.occurrence;
  const prePostTextLength = 100;
  let preText = '';
  let highlightText = '';
  let postText = '';

  const index = findNthOccurrence(text.toLowerCase(), phrase.toLowerCase(), occurrence);

  if (index === -1) {
    return text;
  }

  preText = text.slice(0, index);
  postText = text.slice(index, text.length);
  highlightText = postText.slice(0, phrase.length);
  postText = postText.slice(phrase.length, text.length);

  highlightText = `<span class='highlighted'>${highlightText}</span>`;

  preText = preTextTrim(preText, prePostTextLength);
  postText = postTextTrim(postText, prePostTextLength);

  text = preText + highlightText + postText;
  return text;
}

function findNthOccurrence(text: string, phrase: string, nthOccurrence: number): number {
  const textLength = text.length;
  let i = -1;
  while (nthOccurrence-- && i++ < textLength) {
    i = text.indexOf(phrase, i);
    if (i < 0) {
      break;
    }
  }
  return i;
}

function preTextTrim(text: string, prePostTextLength: number): string {
  const punctuation = '. ';
  const preIndex = text.lastIndexOf(punctuation);

  if (preIndex !== -1) {
    text = text.substring(preIndex + 2, text.length);
  }

  if (text.length > prePostTextLength) {
    let currentIndexPosition = text.length - 1 - prePostTextLength;

    while (currentIndexPosition > 0 && !isSpace(text[currentIndexPosition])) {
      currentIndexPosition--;
    }
    text = text.substring(currentIndexPosition, text.length);
    text = '...' + text;
  }
  return text;
}

function postTextTrim(text: string, prePostTextLength: number): string {
  const punctuation = '. ';
  const postIndex = text.indexOf(punctuation);

  if (postIndex !== -1) {
    text = text.substring(0, postIndex + 1);
  }

  if (text.length > prePostTextLength) {
    let currentIndexPosition = prePostTextLength;
    while (currentIndexPosition < text.length && !isSpace(text[currentIndexPosition])) {
      currentIndexPosition++;
    }
    text = text.substring(0, currentIndexPosition);
    text = text + '...';
  }
  return text;
}

function isSpace(char: string): boolean {
  if (char === ' ' || char === '\n') {
    return true;
  }
  return false;
}
