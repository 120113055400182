import { Glossary } from '@mhe/reader/models';

export const GLOSSARY_STATE_KEY = 'glossary';

export interface GlossaryState {
  glossary: Glossary

  // loading
  loading: boolean
  error?: Error
}

export type GlossaryDictionaryState = Record<string, GlossaryState>;

export interface PartialGlossaryState {
  [GLOSSARY_STATE_KEY]: GlossaryDictionaryState
}

export const initialState: GlossaryDictionaryState = {};
