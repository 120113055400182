/* eslint-disable max-len */
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { ReaderStore } from '../components/reader/state';
import {
  ReadiatorBentoDigitalTaggingData,
  ReadiatorChildDetails,
  SpineItem,
} from '@mhe/reader/models';
import { ReadiatorBridgeService } from '@mhe/reader/features/readiator-bridge';
import { TransformStore } from '@mhe/reader/state/transform';
import * as transformActions from '@mhe/reader/state/transform/transform.actions';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';

@Injectable()
export class BentoDigitalTaggingMediator
  extends ComponentEffects
  implements OnDestroy {
  private readonly transformActions$ = this.transformStore.actions$;
  private readonly unlisteners: Array<() => void> = [];

  constructor(
    private readonly epubLibCfiService: EpubLibCFIService,
    private readonly readerConfigStore: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly readiatorBridge: ReadiatorBridgeService,
    private readonly renderer: Renderer2,
    private readonly transformStore: TransformStore,
  ) {
    super();
  }

  private readonly modifyTaggableElements$ = this.effect(() => {
    return this.transformActions$.pipe(
      ofType(transformActions.bentoDigitalTaggingInit),
      withLatestFrom(
        this.readerConfigStore.tagging$,
        this.readerConfigStore.taggingElements$,
        this.readerConfigStore.readiator$,
        this.readerStore.spineItem$,
        this.readerStore.doubleSpineItem$,
      ),
      map(
        ([
          { content },
          tagging,
          taggingElements,
          readiator,
          singleSpineItem,
          doubleSpineItem,
        ]) => {
          this.unlisten();

          if (tagging && readiator) {
            this.renderer.addClass(content.body, 'tagging');

            let tags =
              'table, blockquote, ul, ol, p, a, figure, iframe, img, audio, video, figure.digital, figure.figure-widget:not(.digital), .mhe-audio--wrapper';

            if (taggingElements) {
              tags += ', ' + taggingElements;
            }

            this.generateDynamicTaggingCss(tags, content);

            // Process each taggable element in document and register user event handlers
            content.querySelectorAll(tags).forEach((elem: HTMLElement) => {
              elem.tabIndex = 0; // make taggable elements focusable

              this.renderer.removeAttribute(elem, 'href');
              this.renderer.removeAttribute(elem, 'data-href');
              this.renderer.removeAttribute(elem, 'data-href-url');

              const elementSelectedCallback = (event: MouseEvent): void => {
                event.stopPropagation();
                event.preventDefault();

                let base = this.epubLibCfiService.generateBasePath(
                  (singleSpineItem as SpineItem).index,
                  (singleSpineItem as SpineItem).id,
                );
                if (doubleSpineItem?.left.url === content.baseURI) {
                  base = this.epubLibCfiService.generateBasePath(
                    doubleSpineItem.left.index,
                    doubleSpineItem.left.id,
                  );
                } else if (doubleSpineItem?.right.url === content.baseURI) {
                  base = this.epubLibCfiService.generateBasePath(
                    doubleSpineItem.right.index,
                    doubleSpineItem.right.id,
                  );
                }

                const data: ReadiatorBentoDigitalTaggingData = {
                  id: elem.id,
                  cfi: this.epubLibCfiService.getCfiFromNode(elem, base),
                  children: Array.from(elem.children).map((child) =>
                    child.nodeName.toLowerCase(),
                  ),
                  classList: elem.className.split(' '),
                  nodeName: elem.nodeName.toLowerCase(),
                };

                if (elem.nodeName.toLowerCase() === 'figure') {
                  data.childrenInDetails = this.getChildrenInDetails(elem);
                }

                this.readiatorBridge.onBentoDigtalTaggableSelect(data);
              };

              this.unlisteners.push(
                this.renderer.listen(elem, 'click', elementSelectedCallback),
              );
              this.unlisteners.push(
                this.renderer.listen(
                  elem,
                  'keydown.enter',
                  elementSelectedCallback,
                ),
              );
            });
          }
        },
      ),
      logCatchError('modifyTaggableElements$'),
    );
  });

  getChildrenInDetails(node: HTMLElement): ReadiatorChildDetails[] {
    return Array.from(node.children)
      .map((child) => {
        if (
          child.nodeName.toLowerCase() === 'a' &&
          child.classList.contains('mhe-annotations-ignorable')
        ) {
          return child.children[0];
        }
        return child;
      })
      .map((child) => {
        return {
          id: child.id,
          classList: child.className.split(' '),
          nodeName: child.nodeName.toLowerCase(),
        };
      });
  }

  generateDynamicTaggingCss(tags: string, content: HTMLDocument): void {
    let hoverQuery = '';
    let focusQuery = '';
    tags.split(',').forEach((tag) => {
      hoverQuery += `body.tagging ${tag}:hover, `;
      focusQuery += `body.tagging ${tag}:focus, `;
    });

    // removing trailing commas (and whitespace)
    hoverQuery = hoverQuery.slice(0, -2);
    focusQuery = focusQuery.slice(0, -2);

    const hoverCss = `${hoverQuery} {
      background-color: #a3c4db !important;
      cursor: pointer;
      outline: 1px dashed darkblue;
    }`;

    const focusCss = `${focusQuery} {
      background-color: #f9b9b2 !important;
      cursor: pointer;
      outline: 2px dashed red;
    }`;

    const styles = this.renderer.createText(hoverCss + focusCss);
    const styleTag = this.renderer.createElement('style');
    this.renderer.appendChild(styleTag, styles);
    this.renderer.appendChild(content.head, styleTag);
  }

  private unlisten(): void {
    this.unlisteners.forEach((unlistener) => unlistener());
  }

  ngOnDestroy(): void {
    this.unlisten();
  }
}
