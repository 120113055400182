import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SESSION_FEATURE_KEY } from './session.reducer';
import { addMilliseconds, parse } from 'date-fns';
import { SessionState } from './session.state';

export const sessionState =
  createFeatureSelector<SessionState>(SESSION_FEATURE_KEY);

export const getSession = createSelector(
  sessionState,
  ({ session }) => session,
);
export const hasSession = createSelector(
  sessionState,
  ({ session }) => !!session,
);
export const getWarningThreshold = createSelector(
  sessionState,
  ({ warningThreshold }) => warningThreshold,
);
export const getExpirationDate = createSelector(getSession, (session) => {
  if (!session?.issuedAt) {
    return undefined;
  }
  const issueDate = parse(session.issuedAt);
  return addMilliseconds(issueDate, (session.sessionTimeout as number) * 1000);
});
export const getWarningDate = createSelector(
  getSession,
  getWarningThreshold,
  (session, warningThreshold) => {
    if (!session?.issuedAt) {
      return undefined;
    }
    const issueDate = parse(session.issuedAt);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const msToWarningThreshold =
      (session.sessionTimeout as number) - warningThreshold;
    return addMilliseconds(issueDate, msToWarningThreshold);
  },
);
export const getToken = createSelector(
  getSession,
  (session) => session?.accessToken,
);
