export const LexileLevels = [
  '1386L_2000L', // HE
  '1185L_1385L', // 11-12
  '1080L_1335L', // 10
  '1050L_1260L', // 9
  '1010L_1185L', // 8
  '970L_1120L', // 7
  '925L_1070L', // 6
  '830L_1010L', // 5
  '740L_940L', // 4
  '520L_820L', // 3
  '420L_650L', // 2
  '190L_530L', // 1
  'U190L', // k
] as const;

export type LexileLevel = (typeof LexileLevels)[number];

export type LexileLevelsOption =
  | '1386L_2000L'
  | '1185L_1385L'
  | '1080L_1335L'
  | '1050L_1260L'
  | '1010L_1185L'
  | '970L_1120L'
  | '925L_1070L'
  | '830L_1010L'
  | '740L_940L'
  | '520L_820L'
  | '420L_650L'
  | '190L_530L'
  | 'U190L';

export function isLexileLevel(val: any): val is LexileLevel {
  return LexileLevels.includes(val);
}
