import { Injectable } from '@angular/core';
import { ExtendedComponentStore } from '@mhe/reader/common';

import * as actions from './transform.actions';

// This store is primarily for messaging from the transformers. Add state here with caution.
@Injectable()
export class TransformStore extends ExtendedComponentStore<any, actions.TransformActions> {
  constructor() {
    super(null);
  }
}
