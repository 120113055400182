import { EpubLibCFIService } from './epub-lib/epub-lib-cfi.service';
import { EpubLibHighlighterService } from './epub-lib/epub-lib-highlighter.service';
import { EpubLibService } from './epub-lib/epub-lib.service';
import { HighlighterService } from './highlighter.service';

export const ANNOTATION_PROVIDERS = [
  EpubLibCFIService,
  EpubLibHighlighterService,
  EpubLibService,
  HighlighterService,
];
