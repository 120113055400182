import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LtiParams } from '@mhe/reader/models';
import { PlayerApiClient } from '@mhe/reader/core/player-api';

@Injectable({ providedIn: 'root' })
export class LtiService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly playerAPIClient: PlayerApiClient,
  ) {}

  get ltiParameters$(): Observable<LtiParams> {
    const directLaunchParams = this.loadDirectLaunchParams();
    if (directLaunchParams !== undefined) {
      return of(directLaunchParams);
    }

    return this.playerAPIClient.fetchLTIParams();
  }

  private loadDirectLaunchParams(): LtiParams | undefined {
    if (window.location.hash.lastIndexOf('direct-launch') < 0) {
      return undefined;
    }
    const queryString = window.location.hash.replace('#', '?');
    const urlParams = new URLSearchParams(queryString);
    const ltiParams: LtiParams = {};
    urlParams.forEach(function(value, key) {
      ltiParams[key] = value;
    });

    return ltiParams;
  }
}
